
import agreementService from '../../services/agreement/agreementService';

export const FETCH_AGREEMENTS = 'FETCH_AGREEMENTS';
export const FETCH_AGREEMENTS_FULFILLED = 'FETCH_AGREEMENTS_FULFILLED';
export const FETCH_AGREEMENTS_REJECTED = 'FETCH_AGREEMENTS_REJECTED';
export const AGREEMENT_CHANGED = 'AGREEMENT_CHANGED';
export const AGREEMENT_TABLE_STATE_CHANGE = 'AGREEMENT_TABLE_STATE_CHANGE';

const requestAgreements = () => ({ type: FETCH_AGREEMENTS });
const requestAgreementsFulfilled = agreements => ({ type: FETCH_AGREEMENTS_FULFILLED, agreements });
const requestAgreementsRejected = error => ({ type: FETCH_AGREEMENTS_REJECTED, error });

export const agreementTableStateChange = state => ({ type: AGREEMENT_TABLE_STATE_CHANGE, state });

export const agreementChanged = agreement => ({
    type: AGREEMENT_CHANGED,
    agreement
});

export const fetchAgreements = () => async dispatch => {

    dispatch(requestAgreements());

    try {
        const agreements = await agreementService.fetch();
        dispatch(requestAgreementsFulfilled(agreements));
    } catch (err) {
        console.error(err);
        dispatch(requestAgreementsRejected(err));
    }
};