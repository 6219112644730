import * as qs from 'query-string';
import { newDate } from '../../../utils/DateUtils';
import { deletee, get, getFile, getPrintPreviewPdf, patch, post, put } from '../../../utils/http/HttpClient';
import HistoryRow from '../common/api/HistoryRow';
import WorkOrderGetResponse from './api/WorkOrderGetResponse';

export default {
    fetchDetails: async id => {
        const { workOrder, users } = await get(`/wo/${id}`);
        return {
            workOrder,
            users,
        };
    },
    fetchHistory: async workOrderId => {
        const { rows } = await get(`/wo/history/${workOrderId}`);

        return rows.map(r => new HistoryRow(r));
    },
    fetchMyWorkorders: async () => {
        const { workOrders, totalCount } = await get('/wo/my');

        return new WorkOrderGetResponse({ workOrders, totalCount });
    },
    copy: async workOrderId => {
        const { workOrder } = await post('/wo/copy', {
            workOrderId,
        });

        return workOrder;
    },
    lock: async workOrderId => {
        await post('/wo/lock', { workOrderId });
    },
    unlock: async workOrderId => {
        await post('/wo/unlock', { workOrderId });
    },
    reopen: async workOrderId => {
        await post('/wo/reopen', { workOrderId });
    },
    patchInternalInfo: async (workOrderId, intakeTs) => {
        await patch(`/wo/${workOrderId}`, {
            intakeTs,
        });
    },
    updateID06Code: async (workOrderId, id06Code) => {
        await put(`/wo/id06/${workOrderId}`, {
            id06Code,
        });
    },
    updateCustomer: async (workOrderId, customerId) => {
        await put(`/wo/${workOrderId}/customer`, {
            customerId,
        });
    },
    updateBusinessObject: async (workOrderId, bo) => {
        await put(`/wo/${workOrderId}/bo`, {
            bo
        });
    },
    exportID06XML: async id06Code => {
        const d = newDate();
        const timestamp = d.format('YYYY-MM-DDTHH:mm:ss');
        const fileName = `${id06Code}_${d.format('YYYY-MM-DD_HHmmss')}`;

        const res = await getFile(
            '/wo/id06',
            {
                id06Code,
                timestamp,
            },
            fileName
        );

        return res;
    },
    fetchChecklists: async workType => {
        if (!workType) {
            return Promise.resolve([]);
        }

        const params = {
            workType
        }

        const { checklists } = await get(`/wo/checklist?${qs.stringify(params)}`);

        return checklists;
    },
    updateChecklist: async (workOrderId, checklistId, values) => {
        await put('/wo/checklist', {
            workOrderId,
            checklistId,
            values,
        });
    },
    fetchChecklistValues: async (workOrderId, checklistId) => {
        const { values } = await get(
            `/wo/checklist/${workOrderId}?checklistId=${checklistId}`
        );

        return values;
    },
    fetchServiceReports: async workType => {
        const params = {
            workType
        }

        if (!workType) {
            return Promise.resolve([]);
        }

        const { serviceReports } = await get(
            `/wo/servicereport?${qs.stringify(params)}`
        );

        return serviceReports;
    },
    updateServiceReport: async (workOrderId, serviceReportId, page, data, doorautomaticId) => {
        await put('/wo/servicereport', {
            workOrderId,
            serviceReportId,
            page,
            data,
            doorautomaticId: doorautomaticId || ''
        });
    },
    closeServiceReport: async (
        workOrderId,
        serviceReportId,
        page,
        data,
        emailTargets,
        doorautomaticId,
    ) => {
        await post('/wo/servicereport', {
            workOrderId,
            serviceReportId,
            page,
            data,
            emailTargets,
            doorautomaticId: doorautomaticId || ''
        });
    },
    reopenServiceReport: async (workOrderId, serviceReportId) => {
        await post('/wo/servicereport/reopen', {
            workOrderId,
            serviceReportId,
        });
    },
    fetchServiceReportData: async (workOrderId, serviceReportId, page, doorautomaticId) => {
        const serviceReport = await get(
            `/wo/servicereport/${workOrderId}?serviceReportId=${serviceReportId}&page=${page}&doorautomaticId=${doorautomaticId || ''}`
        );

        return serviceReport;
    },
    fetchServiceReportLastData: async (
        workOrderId,
        serviceReportId,
        customerId,
        businessObjectId
    ) => {
        const serviceReport = await get(
            `/wo/servicereport/${workOrderId}/last?serviceReportId=${serviceReportId}&customerId=${customerId}&businessObjectId=${businessObjectId}`
        );

        return serviceReport;
    },
    fetchDoorautomatics: async (workOrderId) => {
        const { doorautomatics } = await get(
            `/wo/doorautomatics/${workOrderId}`
        );

        return doorautomatics;
    },
    putDoorautomatics: async (workOrderId, doorautomatics) => {
        await put(
            `/wo/doorautomatics/${workOrderId}`,
            { doorautomatics }
        );
    },
    generateServiceReportPdf: async (workOrderId, serviceReportId, email) => {
        await getFile(
            `/wo/${workOrderId}/servicereport/${serviceReportId}/generate${email ? '?email=true' : ''
            }`,
            {},
            'report',
            'pdf',
            'pdf'
        );
    },
    query: async q => {
        const { workOrders } = await get(`/wo/q?q=${q}`);

        return workOrders;
    },
    fetchByProposalId: async proposalId => {
        const { workOrder } = await get(`/wo/proposal/${proposalId}`);

        return workOrder;
    },
    pdf: async (workOrder, templateId) => {
        await getFile(
            `/wo/pdf`,
            {
                workOrder,
                templateId,
            },
            'report',
            'pdf',
            'pdf'
        );
    },
    pdfPreview: async (workOrder, templateId) => {
        await getPrintPreviewPdf(
            `/wo/pdf`,
            {
                workOrder,
                templateId,
            },
            'report',
            'pdf',
            'pdf'
        );
    },
    exportWord: async (workOrder, templateId) => {
        await getFile(
            `/wo/word`,
            {
                workOrder,
                templateId,
            },
            'report',
            '.docx',
            'word'
        );
    },
    emailPdf: async (workOrder, templateId, emailTarget) => {
        await post(`/wo/pdf/email`, {
            workOrder,
            templateId,
            emailTarget,
        });
    },
    broadcast: async (workOrder, event, status) => {
        await post('/wo/broadcast', {
            workOrder,
            event,
            status,
        });
    },
    updateProjectExtraWorkStatus: async (workOrderId, status) => {
        await post('/wo/projectextra', {
            workOrderId,
            status,
        });
    },
    updateTime: async (id, userId, startTs, endTs, hours, minutes) => {
        await put('/wo/time', {
            id,
            userId,
            startTs,
            endTs,
            hours,
            minutes
        });
    },
    createReminder: async (workOrderId, reminder) => {
        await post(`/wo/${workOrderId}/reminder`, reminder);
    },
    updateReminder: async (workOrderId, reminder) => {
        await put(`/wo/${workOrderId}/reminder`, reminder);
    },
    deleteReminder: async (workOrderId, reminderId) => {
        await deletee(`/wo/${workOrderId}/reminder/${reminderId}`);
    },
    fetchReminders: async workOrderId => {
        const { reminders } = await get(`/wo/${workOrderId}/reminder`);

        return reminders;
    }
};
