export const modalTypes = {
    INVOICE: 'INVOICE',
    WORK_ORDER_HISTORY: 'WORK_ORDER_HISTORY',
    INVOICE_HISTORY: 'INVOICE_HISTORY',
    PROPOSAL: 'PROPOSAL',
    MFA_REGISTERED: 'MFA_REGISTERED',
    WORKORDER_ORDER_FINISH_BROADCAST: 'WORKORDER_ORDER_FINISH_BROADCAST',    
    WORKORDER_CLOSE: 'WORKORDER_CLOSE',    
    MESSAGE: 'MESSAGE',
    BUSINESS_OBJECT_CODE_LOG: 'BUSINESS_OBJECT_CODE_LOG'
}