import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import LocalizedDropdown from '../localized-dropdown';
import './EditableDropdown.css';

class EditableDropdown extends React.Component {

    state = {
        isEditing: false,
        value: this.props.value
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ value: newProps.value });
    }

    UNSAFE_componentWillMount() {
        document.addEventListener('mousedown', this.handleClickEvent, false);

        if (this.props.defaultOpen) {
            this.handleStartEditing();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent, false);
    }

    handleClickEvent = (e) => {
        if (this.node && this.node.contains(e.target)) {
            return;
        }

        this.setState({
            isEditing: false
        })
    }

    handleFocus = () => {
        this.handleStartEditing();
    }

    handleBlur = () => {
        this.setState({
            isEditing: false
        })
    }

    handleStartEditing = () => {
        this.setState({
            isEditing: true
        }, () => {
            if (this.props.search) {
                const ref = this.node.querySelector('.ui.search input');

                if (ref != null) {
                    ref.focus();
                }
            }
        });
    }

    handleSave = async (e, { value }) => {
        this.setState({ isLoading: true })

        try {
            await this.props.onSave(this.props.name, value);
            this.setState({ isLoading: false, isEditing: false })
        } catch (err) {
            this.setState({ isLoading: false })
            console.error(err)
        }
    }

    render() {

        if (this.state.isLoading) {
            return (
                '...'
            )
        }

        if (this.props.isEditable && this.state.isEditing) {
            return (
                <span ref={node => this.node = node} className="man-editable-dropdown">
                    <LocalizedDropdown
                        fluid={this.props.fluid}
                        allowAdditions={this.props.allowAdditions}
                        direction={this.props.direction}
                        selection={this.props.selection}
                        search={this.props.search}
                        size='small'
                        onBlur={this.handleBlur}
                        options={this.props.options}
                        open={true}
                        value={this.props.value}
                        onChange={this.handleSave} />
                </span>
            )
        }

        return (
            <a tabIndex={0}
                onBlur={() => this.props.isEditable && this.handleBlur()}
                onFocus={() => this.props.isEditable && this.handleFocus()}
                onClick={() => this.props.isEditable && this.handleStartEditing()}
                style={{ width: this.props.fluid ? '100%' : 'auto' }}>
                <span style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}>

                    {this.props.text ? this.props.text : this.props.value} {this.props.isEditable && <i className="icon caret down"></i>}
                </span>
            </a>
        )
    }
}

EditableDropdown.propTypes = {
    value: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    name: PropTypes.string,
    text: PropTypes.string,
    allowAdditions: PropTypes.bool,
    search: PropTypes.bool,
    selection: PropTypes.bool,
    fluid: PropTypes.bool,
    defaultOpen: PropTypes.bool,
    direction: PropTypes.string,
    isEditable: PropTypes.bool
}

EditableDropdown.defaultProps = {
    allowAdditions: false,
    search: false,
    selection: false,
    fluid: false,
    defaultOpen: false,
    direction: 'right',
    isEditable: true
}

export default withTranslation('ui')(EditableDropdown);