export const permission = {
    NONE: 'none',
    SETTINGS_READ: 'settings:read',
    SETTINGS_IMPORT_EXPORT: 'settings:import_export',
    USERS_READ: 'users:read',
    USERS_EDIT: 'users:edit',
    INSTANCE_READ: 'instance:read',
    INSTANCE_EDIT: 'instance:edit',
    CUSTOMERS_CREATE: 'customers:create',
    CUSTOMERS_CREATE_POTENTIAL: 'customers:create_potential',
    CUSTOMERS_READ: 'customers:read',
    CUSTOMERS_EDIT: 'customers:edit',
    CUSTOMERS_DELETE: 'customers:delete',
    CUSTOMERS_FILES: 'customers:files',
    CUSTOMERS_FILES_READ: 'customers:files:read',
    BUSINESS_OBJECTS_READ: 'bo:read',
    BUSINESS_OBJECTS_EDIT: 'bo:edit',
    BUSINESS_OBJECTS_DELETE: 'bo:delete',
    BUSINESS_OBJECTS_SERVICE_LOG: 'bo:service_log',
    BUSINESS_OBJECTS_FILES: 'bo:files',
    BUSINESS_OBJECTS_FILES_READ: 'bo:files:read',
    BUSINESS_OBJECTS_AGREEMENT_EDIT: 'bo:agreements:edit',
    BUSINESS_OBJECTS_CODES_VIEW: 'bo:codes:view',
    BUSINESS_OBJECTS_CODES_EDIT: 'bo:codes:edit',
    BUSINESS_OBJECTS_CODES_VIEW_LOG: 'bo:codes:view_log',
    ARTICLES_CREATE: 'articles:create',
    ARTICLES_READ: 'articles:read',
    ARTICLES_PRICE: 'articles:price',
    ARTICLES_EDIT: 'articles:edit',
    ARTICLES_DELETE: 'articles:delete',
    ARTICLES_SETTINGS: 'articles:settings',
    ARTICLES_FILES_MANAGE: 'articles:files',
    ARTICLES_FILES_READ: 'articles:files:read',
    ACL_READ: 'accesslevels:read',
    ACL_EDIT: 'accesslevels:edit',
    WORKORDERS_READ: 'workorders:read',
    WORKORDERS_EDIT: 'workorders:edit',
    WORKORDERS_EDIT_INTERNAL_INFO: 'workorders:edit_internal_info',
    WORKORDERS_CREATE: 'workorders:create',
    WORKORDERS_CREATE_PROJECT_EXTRA_WORK:
        'workorders:create_project_extra_work',
    WORKORDERS_ACCEPT_PROJECT_EXTRA_WORK:
        'workorders:accept_project_extra_work',
    WORKORDERS_COPY: 'workorders:copy',
    WORKORDERS_CLOSE: 'workorders:close',
    WORKORDERS_REOPEN: 'workorders:reopen',
    WORKORDERS_DELETE: 'workorders:delete',
    WORKORDERS_REPORT: 'workorders:report',
    WORKORDERS_REPORT_ARTICLES: 'workorders:report_articles',
    WORKORDERS_EDIT_ARTICLE_DISTRIBUTIONS:
        'workorders:edit_article_distributions',
    WORKORDERS_ID06: 'workorders:id06',
    WORKORDERS_LOCK: 'workorders:lock',
    WORKORDERS_FILES: 'workorders:files',
    WORKORDERS_TIMEREPORTS_SELECT: 'workorders:timereports_select',
    WORKORDERS_READ_COST: 'workorders:read_cost',
    WORKORDERS_DOORAUTOMATICS_EDIT: 'workorders:edit_doorautomatics',
    WORKORDERS_SERVICEREPORT_REOPEN: 'workorders:reopen_servicereport',
    MODELS_READ: 'models:read',
    MODELS_EDIT: 'models:edit',
    MODELS_DELETE: 'models:delete',
    MODELS_FILES_MANAGE: 'models:files',
    MODELS_FILES_READ: 'models:files:read',
    AGREEMENTS_READ: 'agreements:read',
    AGREEMENTS_EDIT: 'agreements:edit',
    AGREEMENTS_DELETE: 'agreements:delete',
    SUPPLIERS_READ: 'suppliers:read',
    SUPPLIERS_EDIT: 'suppliers:edit',
    SUPPLIERS_DELETE: 'suppliers:delete',
    SUPPLIER_ORDERS_EDIT: 'supplier_orders:edit',
    SUPPLIER_INVOICES_READ: 'supplier_invoices:read',
    SUPPLIER_INVOICES_EDIT: 'supplier_invoices:edit',
    SUPPLIER_INVOICES_READ_ALL: 'supplier_invoices:read_all',
    INVOICES_READ: 'invoices:read',
    REPORTS_READ: 'reports:read',
    TIMEREPORTS_READ: 'timereports:read',
    TIMEREPORTS_EDIT: 'timereports:edit',
    DISTANCEREPORTS_READ: 'distancereports:read',
    COMPENSATIONREPORTS_READ: 'compensationreports:read',
    ONCALLREPORTS_EDIT: 'oncallreports:edit',
    PROJECTS_READ: 'projects:read',
    PROJECTS_EDIT: 'projects:edit',
    PROJECTS_DELETE: 'projects:delete',
    PROJECTS_CLOSE_NEGATIVE_INVOICE: 'projects:close_negative_invoice',
    PROJECTS_FILES: 'projects:files',
    PROJECTS_REOPEN: 'projects:reopen',
    SALES_ORDER_READ: 'salesorder:read',
    SALES_ORDER_EDIT: 'salesorder:edit',
    SALES_ORDER_DELETE: 'salesorder:delete',
    PROPOSAL_READ: 'proposal:read',
    PROPOSAL_EDIT: 'proposal:edit',
    PROPOSAL_DELETE: 'proposal:delete',
    PROPOSAL_FILES: 'proposal:files',
    PROPOSAL_REOPEN: 'proposal:reopen',

    DASHBOARD_READ: 'dashboard:read',
    DASHBOARD_OPEN_WORKORDERS_KPI_EDIT: 'dashboard:open_workorders_kpi_edit',

    SECURITY_BO_DOCUMENT_READ: 'security:bo_document:read',
    SECURITY_BO_DOCUMENT_EDIT: 'security:bo_document:edit',

    PERMISSION_MESSAGE_BOARD_POST_EDIT: 'message_board_post:edit',

    DEVIATIONS_CREATE: 'deviations:create',
    DEVIATIONS_READ: 'deviations:read',
    DEVIATIONS_EDIT: 'deviations:edit',
    DEVIATIONS_CLOSE: 'deviations:close',
    DEVIATIONS_DELETE: 'deviations:delete',
    DOORAUTOMATICS_READ: 'doorautomatics:read',
    DOORAUTOMATICS_EDIT: 'doorautomatics:edit',
    DOORAUTOMATICS_DELETE: 'doorautomatics:delete',
    DOORAUTOMATICS_FILES: 'doorautomatics:files',
    DOORAUTOMATICS_FILES_READ: 'doorautomatics:files:read',

    CUSTOMER_ZONE_EDIT_USERS: 'customerzone:edit_users',
    CUSTOMER_ZONE_PUBLISH_FILES: 'customerzone:publish_files',
};
