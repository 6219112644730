export const se_directlarm = {
    ui: {

    SETTINGS_SALES_ORDER: 'Butiksorder',
    SALES_ORDERS: 'Butiksorder',
    },

    form: {
    SALES_ORDER: 'Butiksorder',
    BUSINESS_OBJECT_DISPLAY_NAME: 'Anläggningsnamn',

    },

    acl: {
        PERMISSION_SALES_ORDER_READ: 'Visa butiksorder',
        PERMISSION_SALES_ORDER_EDIT: 'Skapa och ändra butiksorder',
        PERMISSION_SALES_ORDER_DELETE: 'Radera butiksorder',
    },
    
    stats: {
    CLOSED_SALES_ORDER_PRICING_WEEKLY_HEADER: 'Order: Försäljning och marginal',
    },
};
