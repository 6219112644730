import ReactGA from 'react-ga4';

ReactGA.initialize([{
    trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_KEY
}]);

export default {

    publish: (instance, action, label) => ReactGA.event({
        category: instance,
        action,
        label
    }),

    pageview: path => ReactGA.send({
        hitType: 'pageview',
        page: path,
        title: path
    })
}