import { invoiceType } from '../../../utils/constants';
import { get, getFile, post } from '../../../utils/http/HttpClient';
import HistoryRow from '../common/api/HistoryRow';
import Invoice from './api/Invoice';

export default {
    fetchDetails: async id => {
        const { invoice } = await get(`/invoice/${id}`);
        return new Invoice(invoice);
    },
    fetchErpDetails: async id => {
        const { externalInvoiceNr } = await get(`/invoice/${id}/erp`);
        return { externalInvoiceNr };
    },
    fetchDetailsForWorkOrder: async workOrderId => {
        const { invoice } = await get(`/invoice/wo/${workOrderId}`);
        return new Invoice(invoice);
    },
    fetchDetailsForSalesOrder: async salesOrderId => {
        const { invoice } = await get(`/invoice/salesorder/${salesOrderId}`);
        return new Invoice(invoice);
    },
    fetchDetailsForProjectInvoice: async projectInvoiceId => {
        const { invoice } = await get(`/invoice/project/${projectInvoiceId}`);
        return new Invoice(invoice);
    },
    fetchInvoicesForAgreement: async (agreementId, year) => {
        const { invoices } = await get(
            `/invoice/agreement/${agreementId}?year=${year}`
        );

        return invoices.map(i => new Invoice(i));
    },
    fetchHistory: async id => {
        const { rows } = await get(`/invoice/history/${id}`);

        return rows.map(r => new HistoryRow(r));
    },
    apply: async (type, invoiceId, fileName = 'file', extension = 'xml') => {
        if (type === invoiceType.PDF) {
            extension = 'pdf';
        }

        try {
            await getFile(
                '/invoice/apply',
                {
                    invoiceId: invoiceId,
                    type,
                },
                fileName,
                extension,
                type
            );
        } catch (err) {
            console.error(err);
        }
    },
    applyWithoutDownload: async (type, invoiceId) => {
        const response = await post('/invoice/apply', {
            invoiceId: invoiceId,
            type,
        });

        return response;
    },
};
