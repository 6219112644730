import {
    LOGIN,
    LOGIN_FULFILLED,
    LOGIN_REJECTED,
    LOGOUT,
    REQUIRE_MFA,
    REQUEST_VALIDATE_MFA,
    MFA_VALIDATION_REJECTED,
} from '../../actions/common/authActions';
import {
    CHANGE_START_PAGE
} from '../../actions/common/uiActions';
import {
    UPDATE_INSTANCE_CONFIGURATION,
    UPDATE_INSTANCE_PROPOSAL_CONFIGURATION,
    UPDATE_BO_CONFIGURATION,
    UPDATE_WO_CONFIGURATION
} from '../../actions/instance';
import {
    DEPARTMENT_CHANGED,
    DEPARTMENT_DELETED
} from '../../actions/departments';
import { aclLimit } from '../../../utils/constants';
import { UPDATE_APP_MODULE_STATE, UPDATE_INSTANCE_APP_MODULE_DYNAMIC_CONF } from 'data/actions/appmodules';

const defaultState = {
    isLoading: false,
    user: null,
    instance: null,
    notifications: [],
    departments: [],
    perms: [],
    limit: aclLimit.USER,
    error: null,
    message: null,
    token: null,
    tags: [],
    aclIds: null,
    common: {
    },
    mfa: {
        token: null,
        provider: null
    }
}

export const auth = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case LOGIN: {
            return {
                ...state,
                isLoading: true
            }
        }
        case LOGIN_FULFILLED: {

            const statusesByName = {};

            action.instance.workOrderConfiguration?.statuses.forEach(s => {
                statusesByName[s.name] = s;
            });
        
            action.instance.workOrderConfiguration.statusesByName = statusesByName;

            return {
                ...state,
                isLoading: false,
                user: {
                    ...action.user,
                    department: action.departments.find(d => d.id === action.user.departmentId),
                    initials: action.user.shortName,
                    aclIds: action.aclIds
                },
                userType: action.userType,
                perms: action.perms,
                limit: action.limit,
                instance: action.instance,
                departments: action.departments,
                token: action.token,
                common: {
                },
                appModules: action.appModules,
                onDemandServiceReports: action.onDemandServiceReports,
                mfa: { token: null, provider: null }
            }
        }
        case LOGIN_REJECTED: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        case LOGOUT: {
            return {
                ...defaultState,
                error: action.error
            }
        }
        case UPDATE_INSTANCE_CONFIGURATION: return handleUpdateInstanceConfiguration(state, action)

        case UPDATE_INSTANCE_PROPOSAL_CONFIGURATION: return handleUpdateInstanceProposalConfiguration(state, action)

        case UPDATE_BO_CONFIGURATION: return handleUpdateBoConfiguration(state, action)

        case UPDATE_WO_CONFIGURATION: return handleUpdateWoConfiguration(state, action)

        case DEPARTMENT_CHANGED: return handleUpdateDepartments(state, action)

        case DEPARTMENT_DELETED: return handleDeleteDepartment(state, action)

        case UPDATE_APP_MODULE_STATE: {
            return {
                ...state,
                appModules: {
                    ...state.appModules,
                    [action.module]: {
                        ...state.appModules[action.module],
                        ...action.state
                    }
                }
            }
        }

        case REQUIRE_MFA: {
            return {
                ...state,
                isLoading: false,
                error: null,
                mfa: { 
                    token: action.token,
                    provider: action.provider  
                }
            }
        }

        case REQUEST_VALIDATE_MFA: {
            return {
                ...state,
                isLoading: true
            }
        }

        case MFA_VALIDATION_REJECTED: {
            return {
                ...state,
                mfa: { token: null, provider: null },
                isLoading: false,
                error: action.error
            }
        }

        case CHANGE_START_PAGE: {
            return {
                ...state,
                user: {
                    ...state.user,
                    configuration: {
                        ...state.user.configuration,
                        startPage: action.startPage
                    }
                }
            }
        }

        case UPDATE_INSTANCE_APP_MODULE_DYNAMIC_CONF: {
            return {
                ...state,
                appModules: {
                    ...state.appModules,
                    [action.module]: {
                        ...state.appModules[action.module],
                        dynamic: {
                            ...action.conf
                        }
                    }
                }
            }
        }

        default: {
            return state;
        }
    }
};

const handleUpdateInstanceConfiguration = (state, action) => {

    const newConfiguration = {
        ...state.instance.configuration
    }

    newConfiguration[action.name] = action.value;

    return {
        ...state,
        instance: {
            ...state.instance,
            configuration: newConfiguration
        }
    }
}

const handleUpdateInstanceProposalConfiguration = (state, action) => {

    const newConfiguration = {
        ...state.instance.configuration,
        proposalConfiguration: {
            ...(state.instance.configuration.proposalConfiguration || {}),
            [action.name]: action.value
        }
    }

    return {
        ...state,
        instance: {
            ...state.instance,
            configuration: newConfiguration
        }
    }
}


const handleUpdateBoConfiguration = (state, action) => {

    const newConfiguration = {
        ...state.instance.businessObjectConfiguration
    }

    Object.keys(action.data).forEach(prop => {
        newConfiguration[prop] = action.data[prop];
    })

    return {
        ...state,
        instance: {
            ...state.instance,
            businessObjectConfiguration: newConfiguration
        }
    }
}

const handleUpdateWoConfiguration = (state, action) => {

    const newConfiguration = {
        ...state.instance.workOrderConfiguration
    }

    Object.keys(action.data).forEach(prop => {
        newConfiguration[prop] = action.data[prop];
    });

    const statusesByName = {};

    newConfiguration?.statuses.forEach(s => {
        statusesByName[s.name] = s;
    });

    newConfiguration.statusesByName = statusesByName;

    return {
        ...state,
        instance: {
            ...state.instance,
            workOrderConfiguration: newConfiguration
        }
    }
}

const handleUpdateDepartments = (state, action) => {
    const departments = [...state.departments];
    const idx = departments.findIndex(d => d.id === action.department.id);

    if (idx < 0) {
        departments.push(action.department);
    } else {
        departments[idx] = action.department;
    }

    return {
        ...state,
        departments
    }
}

const handleDeleteDepartment = (state, action) => {
    const departments = [...state.departments];
    const idx = departments.findIndex(d => d.id === action.id);

    if (idx > -1) {
        departments.splice(idx, 1);
    }

    return {
        ...state,
        departments
    }
}
