import workOrderService from 'data/services/workorder/workOrderService';
import _ from 'lodash';
import * as qs from 'query-string';
import { WORK_ORDER_SCHEDULE_STATUS_BOTH } from 'utils/constants';
import FilterTypes from '../../../utils/constants/filters';
import { deletee, get, patch, post } from '../../../utils/http/HttpClient';
import { storeHolder } from '../../stores/storeHolder.js';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../common/notificationActions';
import instanceService from 'data/services/instance/instanceService';

export const FETCH_WORKORDERS = 'FETCH_WORKORDERS';
export const FETCH_WORKORDERS_FULFILLED = 'FETCH_WORKORDERS_FULFILLED';
export const FETCH_WORKORDERS_REJECTED = 'FETCH_WORKORDERS_REJECTED';

export const CREATE_WORKORDER = 'CREATE_WORKORDER';
export const CREATE_WORKORDER_FULFILLED = 'CREATE_WORKORDER_FULFILLED';
export const CREATE_WORKORDER_REJECTED = 'CREATE_WORKORDER_REJECTED';

export const SAVE_WORKORDER = 'SAVE_WORKORDER';
export const SAVE_WORKORDER_FULFILLED = 'SAVE_WORKORDER_FULFILLED';
export const SAVE_WORKORDER_REJECTED = 'SAVE_WORKORDER_REJECTED';

export const DELETE_WORKORDER = 'DELETE_WORKORDER';
export const DELETE_WORKORDER_FULFILLED = 'DELETE_WORKORDER_FULFILLED';
export const DELETE_WORKORDER_REJECTED = 'DELETE_WORKORDER_REJECTED';

export const WORKORDERS_INIT_TABS = 'WORKORDERS_INIT_TABS';
export const WORKORDERS_TOGGLE_TAB = 'WORKORDERS_TOGGLE_TAB';
export const WORKORDERS_OPEN_TAB = 'WORKORDERS_OPEN_TAB';
export const WORKORDERS_CLOSE_TAB = 'WORKORDERS_CLOSE_TAB';
export const WORKORDERS_CLOSE_ALL_TABS = 'WORKORDERS_CLOSE_ALL_TABS';

export const WORKORDERS_HANDLE_CHANGE = 'WORKORDERS_HANDLE_CHANGE';

export const WORKORDER_UI = {
    SERVICE_REPORTS_VALIDATION: 'ui:service_reports_validation',
};

export const WORKORDERS_SET_FOCUSED = 'WORKORDERS_SET_FOCUSED';

const requestWorkOrders = from => ({ type: FETCH_WORKORDERS, from });
const requestWorkOrdersFulfilled = (workOrders, totalCount) => ({
    type: FETCH_WORKORDERS_FULFILLED,
    workOrders,
    totalCount,
});
const requestWorkOrdersRejected = error => ({
    type: FETCH_WORKORDERS_REJECTED,
    error,
});

const requestCreateWorkOrder = () => ({ type: CREATE_WORKORDER });
export const requestCreateWorkOrderFulfilled = workOrder => ({
    type: CREATE_WORKORDER_FULFILLED,
    workOrder,
});
const requestCreateWorkOrderRejected = error => ({
    type: CREATE_WORKORDER_REJECTED,
    error,
});

export const requestSaveWorkOrderFulfilled = workOrder => ({
    type: SAVE_WORKORDER_FULFILLED,
    workOrder,
});

const requestDeleteWorkOrder = () => ({ type: DELETE_WORKORDER });
const requestDeleteWorkOrderFulfilled = id => ({
    type: DELETE_WORKORDER_FULFILLED,
    id,
});
const requestDeleteWorkOrderRejected = error => ({
    type: DELETE_WORKORDER_REJECTED,
    error,
});

export const toggleWorkOrderTab = id => ({ type: WORKORDERS_TOGGLE_TAB, id });
export const openWorkOrderTab = (id, { title, customerName }) => ({
    type: WORKORDERS_OPEN_TAB,
    id,
    title,
    customerName,
});
export const closeWorkOrderTab = id => ({ type: WORKORDERS_CLOSE_TAB, id });

export const closeAllWorkOrderTabs = () => ({ type: WORKORDERS_CLOSE_ALL_TABS });

export const initWorkOrderTabs = (activeTab, tabs) => ({
    type: WORKORDERS_INIT_TABS,
    activeTab,
    tabs,
});

export const setFocusedWorkOrder = id => ({ type: WORKORDERS_SET_FOCUSED, id });

export const handleWorkOrderChange = (id, name, value) => ({
    type: WORKORDERS_HANDLE_CHANGE,
    id,
    name,
    value,
});

export const fetchWorkOrders =
    (from = 0) =>
        async dispatch => {
            dispatch(requestWorkOrders(from));

            fetch(dispatch, from);
        };

const fetch = _.debounce(async (dispatch, from) => {
    try {
        const start = new Date().getTime();

        const { workOrders, totalCount } = await get(
            `/wo?from=${from}${mapStateToParams(
                storeHolder.getStore().getState().ui
            )}`
        );
        dispatch(requestWorkOrdersFulfilled(workOrders, totalCount, from));
        
        const end = new Date().getTime();
        const time = end - start;

        instanceService.postMetric('wos', time);
    } catch (err) {
        console.error(err);
        dispatch(requestWorkOrdersRejected(err));
    }
}, 300);

export const createWorkOrder = wo => async dispatch => {
    dispatch(requestCreateWorkOrder());

    if (!wo.status) {
        wo.status = getDefaultStatus();
    }

    if (!wo.workType) {
        wo.workType = getDefaultWorkType();
    }

    try {
        const { workOrder } = await post('/wo', { workOrder: wo });

        dispatch(
            requestCreateWorkOrderFulfilled({
                ...workOrder,
                contacts: wo.contacts,
            })
        );

        dispatch(
            openWorkOrderTab(workOrder.id, {
                ...workOrder,
                contacts: wo.contacts,
            })
        );
    } catch (err) {
        console.error(err);
        dispatch(requestCreateWorkOrderRejected(err));
    }
};

export const copyWorkOrder = workOrderId => async dispatch => {
    dispatch(requestCreateWorkOrder());

    try {
        const workOrder = await workOrderService.copy(workOrderId);

        dispatch(
            requestCreateWorkOrderFulfilled({
                ...workOrder,
                contacts: workOrder.contacts,
            })
        );

        dispatch(
            openWorkOrderTab(workOrder.id, {
                ...workOrder,
                contacts: workOrder.contacts,
            })
        );
    } catch (err) {
        console.error(err);
        dispatch(requestCreateWorkOrderRejected(err));
    }
};

export const saveWorkOrder = wo => async dispatch => {
    const workOrder = { ...wo };
    delete workOrder.intakeTs;
    delete workOrder.updateTs;

    if (workOrder.articleReports) {
        workOrder.articleReports = workOrder.articleReports.filter(ar => {
            return !(ar.isNewArticleRow && !ar.articleId);
        });
        wo.totalPrice = workOrder.articleReports.reduce((total, report) => total + report.totalPrice || 0, 0)
    }

    try {
        const { locationLng, locationLat } = await patch('/wo', { workOrder });

        if (locationLat && locationLng) {
            wo.locationLat = locationLat;
            wo.locationLng = locationLng;
        }

        dispatch(requestSaveWorkOrderFulfilled(wo));

        // dispatch(showInfoNotification('SUCCESSFULLY_SAVED', 3000));
    } catch (err) {
        console.error(err);
        dispatch(showErrorNotification('FAILED_TO_SAVE'));
    }
};

export const deleteWorkOrder = id => async dispatch => {
    dispatch(requestDeleteWorkOrder());

    try {
        await deletee(`/wo/${id}`);

        dispatch(requestDeleteWorkOrderFulfilled(id));

        dispatch(showSuccessNotification('SUCCESSFULLY_DELETED'));
    } catch (err) {
        console.error(err);

        dispatch(requestDeleteWorkOrderRejected(err));
        dispatch(showErrorNotification('FAILED_TO_DELETE_WORKORDER'));
    }
};

const mapStateToParams = state => {
    const { dashboardFilters, sort } = state;
    let query = { sort };

    if (dashboardFilters) {
        const workTypes = dashboardFilters[FilterTypes.WORK_TYPES];
        const tags = dashboardFilters[FilterTypes.TAGS];
        const assignee = dashboardFilters[FilterTypes.ASSIGNEES];
        const scheduleStatus =
            dashboardFilters[FilterTypes.SCHEDULE_STATUS] || WORK_ORDER_SCHEDULE_STATUS_BOTH;

        if (workTypes && workTypes.length > 0) {
            query.workType = workTypes.join(',');
        }

        if (tags && tags.length > 0) {
            query.tags = tags.join(',');
        }

        if (assignee) {
            query.assignee = assignee;
        }

        query.scheduleStatus = scheduleStatus;
    }

    return `&${qs.stringify(query)}`;
};

export const getDefaultStatus = () => {
    const conf = storeHolder.getStore().getState().auth.instance
        .workOrderConfiguration.statuses;

    const defaultStatus = conf.find(s => s.default);

    return defaultStatus ? defaultStatus.name : '';
};

export const getDefaultWorkType = () => {
    const conf = storeHolder.getStore().getState().auth.instance
        .workOrderConfiguration.workTypes;

    const defaultType = conf.find(t => t.default);

    return defaultType ? defaultType.name : '';
};
