import _ from 'lodash';
import React from 'react';

export const mapSimpleValueToOption = value => ({
    key: value,
    value,
    text: value,
});

export const mapSimpleLabeledValueToOption = value => ({
    key: value.id,
    value: value.id,
    text: value.label,
});

export const mapAclToOption = acl => ({
    key: acl.id,
    value: acl.id,
    text: acl.displayName,
});

export const mapDepartmentToOption = department => ({
    key: department.id,
    value: department.id,
    text: (
        <Label
            color={department.configuration?.color}
            text={department.displayName}></Label>
    ),
});

export const mapWorkOrderStatusToOption = status => ({
    key: status.name,
    value: status.name,
    text: status.name,
    disabled: !!status.disabled,
});

export const mapTimeTypeToOption = type => ({
    key: type.name,
    value: type.name,
    text: type.name,
});

const Label = ({ text, color }) => (
    <div className="man-user-label">
        <span
            className="ui empty circular label"
            style={{ marginRight: 5, backgroundColor: color }}></span>
        {text}
    </div>
);

export const userDropdownSearch = (options, query) => {
    const re = new RegExp(_.escapeRegExp(query), 'i');
    return options.filter(opt => re.test(opt.searchText));
};

export const mapUserToDropdown = user => ({
    key: user.id,
    value: user.id,
    searchText: user.fullName,
    text: (
        <Label
            color={user.department.configuration.color}
            text={user.fullName}></Label>
    ),
});

export const mapTimeReportTagToOption = ({ id, color, label, status }) => ({
    key: id,
    value: id,
    status,
    text: <Label color={color} text={label}></Label>,
});

export const mapWorkTypeToOption = wt => ({
    key: wt.name,
    value: wt.name,
    text: wt.name,
});

export const mapAgreementToOption = a => ({
    key: a.id,
    value: a.id,
    text: a.title,
});

export const mapCustomerContactToOption = cc => ({
    key: cc.name,
    value: cc.name,
    text: cc.name,
});

export const mapCustomerContactToOptionWithRole = cc => ({
    key: cc.name,
    value: cc.name,
    text: (
        <div>
            {cc.name}
            <div className="man-secondary-text">{cc.role}</div>
        </div>
    ),
});

export const mapCustomerContactToOptionFull = cc => ({
    key: cc.name,
    value: cc.name,
    text: (
        <div>
            {[cc.name, cc.role].filter(v => v).join(' | ')}
            <div className="man-secondary-text">{[cc.phone, cc.email].filter(v => v).join(', ')}</div>
        </div>
    ),
});

export const mapCustomerContactToOptionWithCustomerName = cc => ({
    key: cc.name,
    value: cc.name,
    text: (
        <div>
            {cc.name}
            <div className="man-secondary-text">{cc.customerName}</div>
        </div>
    ),
});

export const mapModelToOption = (model, modelCodeEnabled) => ({
    key: model.id,
    value: model.id,
    text: model.name,
});

export const mapCustomerToOption = c => ({
    key: c.id,
    value: c.id,
    text: c.displayName,
});

export const mapBusinessObjectToOption = bo => ({
    key: bo.id,
    value: bo.id,
    text: [bo.serialCode, bo.modelName].filter(v => v).join(', '),
});

export const mapArticleToOption = article => ({
    key: article.id,
    value: article,
    text: `${article.title} - ${article.description}`,
});

export const mapArticleToOptionByDescription = article => ({
    key: article.id,
    value: article.description,
    text: article.description,
});

export const mapArticleToOptionWithType = article => ({
    key: article.id,
    value: article,
    text: `${article.title} - ${article.description}${article.securityType ? ` (${article.securityType})` : ''
        }`,
});

export const mapArticleGroupToOption = group => ({
    key: group.id,
    value: group.id,
    text: `${group.title}${group.displayName ? ` - ${group.displayName}` : ''}`
});


export const mapSupplierToOption = supplier => ({
    key: supplier.id,
    value: supplier.id,
    supplier,
    text: `${supplier.title} - ${supplier.name}`,
});

export const mapDashboardToOption = (value, label) => ({
    key: value,
    value,
    text: label || '-',
});

export const mapProjectToOption = titleEnabled => project => ({
    key: project.id,
    value: project.id,
    text: [titleEnabled ? project.title : null, project.displayName].filter(v => v).join(', '),
    project,
});

export const mapProjectResourceTaskToOption = task => ({
    key: task.id,
    value: task.id,
    searchText: task.label,
    text: (
        <Label
            color={task.color}
            text={task.label}></Label>
    ),
});

export const mapSupplierTagToOption = ({ id, color, text }) => ({
    key: id,
    value: id,
    text: <Label color={color} text={text}></Label>,
});

export const mapAddressToOption = (a, idx) => ({
    key: idx,
    value: [a.street, a.city, a.postIndex, a.notes].filter(v => v).join("$$$"),
    text: (
        <div>
            {a.street}
            <div className="man-secondary-text">{[a.postIndex, a.city].join(' ')}</div>
            <div className="man-secondary-text">{a.notes}</div>
        </div>
    ),
});