import { storeHolder } from 'data/stores/storeHolder';
import businessObjectsService from '../../services/business-objects/businessObjectsService';

export const FETCH_BUSINESS_OBJECTS = 'FETCH_BUSINESS_OBJECTS';
export const FETCH_BUSINESS_OBJECTS_FULFILLED = 'FETCH_BUSINESS_OBJECTS_FULFILLED';
export const FETCH_BUSINESS_OBJECTS_REJECTED = 'FETCH_BUSINESS_OBJECTS_REJECTED';
export const BUSINESS_OBJECT_CHANGED = 'BUSINESS_OBJECT_CHANGED';
export const BUSINESS_OBJECT_FILTER_CHANGE = 'BUSINESS_OBJECT_FILTER_CHANGE';
export const BUSINESS_OBJECT_TABLE_STATE_CHANGE = 'BUSINESS_OBJECT_TABLE_STATE_CHANGE';

const requestBusinessObjects = () => ({ type: FETCH_BUSINESS_OBJECTS });
const requestBusinessObjectsFulfilled = (bos) => ({ type: FETCH_BUSINESS_OBJECTS_FULFILLED, bos });
const requestBusinessObjectsRejected = (error) => ({ type: FETCH_BUSINESS_OBJECTS_REJECTED, error });

export const businessObjectTableStateChange = state => ({ type: BUSINESS_OBJECT_TABLE_STATE_CHANGE, state });

export const businessObjectFilterChange = (filterName, value) => async dispatch => {
    dispatch(({ type: BUSINESS_OBJECT_FILTER_CHANGE, filterName, value }));

    if (filterName == 'detailedSearchActive') {
        dispatch(fetchBusinessObjects());
    }
}

export const boChanged = bo => ({
    type: BUSINESS_OBJECT_CHANGED,
    bo
});

export const fetchBusinessObjects = () => async dispatch => {
    const { searchFilters } = storeHolder.getStore().getState().bos;

    dispatch(requestBusinessObjects());

    try {
        const bos = await businessObjectsService.fetch(searchFilters?.detailedSearchActive);
        dispatch(requestBusinessObjectsFulfilled(bos));
    } catch (err) {
        console.error(err);
        dispatch(requestBusinessObjectsRejected(err));
    }
}