export const se_soderlarm = {
    ui: {
        DEPARTMENTS: 'Team',
        DEPARTMENT: 'Team',
        DEPARTMENT_EDIT_TITLE: 'Ändra Team',
        FILTER_BY_DEPARTMENT: 'Team',
        SELECT_DEPARTMENT: 'Välj Team',
    },
    form: {
        DEPARTMENT: 'Team',
    },
    messages: {
    ERROR_DEPARTMENT_HAS_USERS:
            'Kan inte radera. Teamet har relaterade användare.',
    },   
};