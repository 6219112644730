import React, { FC } from 'react';
import styles from './Stepper.module.css';

interface Props {
    children?: object,
    style?: object
};

const Stepper: FC<Props> = ({ children, style }) => (
    <div className={styles.stepper}>
        {children}
    </div>
);

export default Stepper;