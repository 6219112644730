
import salesOrderService from '../../services/salesorder/salesOrderService';

export const FETCH_SALES_ORDERS = 'FETCH_SALES_ORDERS';
export const FETCH_SALES_ORDERS_FULFILLED = 'FETCH_SALES_ORDERS_FULFILLED';
export const FETCH_SALES_ORDERS_REJECTED = 'FETCH_SALES_ORDERS_REJECTED';
export const SALES_ORDER_CREATED = 'SALES_ORDER_CREATED';
export const SALES_ORDER_CHANGED = 'SALES_ORDER_CHANGED';
export const SALES_ORDER_DELETED = 'SALES_ORDER_DELETED';
export const SALES_ORDER_TABLE_STATE_CHANGE = 'SALES_ORDER_TABLE_STATE_CHANGE';
export const SALES_ORDER_FILTERS_CHANGE = 'SALES_ORDER_FILTERS_CHANGE';

const requestSalesOrders = () => ({ type: FETCH_SALES_ORDERS });
const requestSalesOrdersFulfilled = orders => ({ type: FETCH_SALES_ORDERS_FULFILLED, orders });
const requestSalesOrdersRejected = error => ({ type: FETCH_SALES_ORDERS_REJECTED, error });

export const salesOrdersTableStateChange = state => ({ type: SALES_ORDER_TABLE_STATE_CHANGE, state });

export const salesOrdersFiltersChange = filters => ({
    type: SALES_ORDER_FILTERS_CHANGE,
    filters
});

export const salesOrderCreated = () => ({
    type: SALES_ORDER_CREATED
});

export const salesOrderChanged = order => ({
    type: SALES_ORDER_CHANGED,
    order
});

export const salesOrderDeleted = id => ({
    type: SALES_ORDER_DELETED,
    id
})

export const fetchSalesOrders = ({ customerId, status, departmentId }) => async dispatch => {

    dispatch(requestSalesOrders());

    try {
        const orders = await salesOrderService.fetch(customerId, status, departmentId);
        dispatch(requestSalesOrdersFulfilled(orders));
    } catch (err) {
        console.error(err);
        dispatch(requestSalesOrdersRejected(err));
    }
};