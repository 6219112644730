import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dropdown, Input } from 'semantic-ui-react';
import { Item } from 'trukks-lib';
import { generateId } from 'utils/StringUtils';
import BottomSheet from '../bottom-sheet';
import FlatButton from '../flat-button';
import Icon from '../icon';
import { useClickOutside } from '../use-click-outside';

const SearchableDropdown = props => {
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const { t } = useTranslation(['ui']);
    const isSmallScreen = useSelector(state => state.ui.smallScreen);
    const ref = useRef();
    const inputRef = useRef();

    useClickOutside(ref, () => open && setOpen(false));

    useEffect(() => {
        console.log(inputRef)
        if (open) {
            if (inputRef.current && inputRef.current?.focus) {
                inputRef.current?.focus({
                    preventScroll: true
                })
            }
        }
    }, [inputRef, open])

    if (!props.searchEnabled) {
        return (
            <Dropdown
                {...props}
                onChange={(e, { value }) => props.onChange(value)}></Dropdown>
        );
    }

    const { options, onChange } = props;

    const filteredOptions = options
        .filter(
            c =>
                c.value
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase()) > -1
                || c.searchValue?.toLowerCase()
                    .indexOf(searchValue.toLowerCase()) > -1
        )
        .map(o => ({
            ...o,
            idempotency: generateId()
        }));

    if (isSmallScreen && open) {
        return (
            <>
                <BottomSheet open={true} onChange={() => setOpen(false)}>
                    <div className="man-flex-row man-flex-row-sb">
                        <Input
                            autoFocus
                            ref={inputRef}
                            icon="search"
                            iconPosition="left"
                            className="search"
                            value={searchValue}
                            onChange={e => setSearchValue(e.target.value)}
                        />
                        <FlatButton
                            onClick={() => setOpen(false)}
                            icon="remove">
                            {t('ui:CLOSE')}
                        </FlatButton>
                    </div>

                    {filteredOptions
                        .map(option => (
                            <div
                                key={option.idempotency}
                                style={{
                                    marginTop: 8,
                                }}>
                                <Item
                                    active
                                    onClick={() => {
                                        onChange(option.value);
                                        setOpen(false);
                                    }}>
                                    {option.text}
                                    {option.children}
                                </Item>
                            </div>
                        ))}
                </BottomSheet>
                <span>{props.text}</span>
            </>
        );
    }

    return (
        <div ref={ref}>
            <Dropdown
                {...props}
                options={null}
                onChange={null}
                open={open}
                icon={null}
                upward={false}
                text={
                    <span onClick={() => setOpen(prevOpen => !prevOpen)}>
                        {props.text}

                        <Icon name="caret down"></Icon>
                    </span>
                }>
                <Dropdown.Menu>
                    <Input
                        ref={inputRef}
                        icon="search"
                        iconPosition="left"
                        className="search"
                        value={searchValue}
                        onChange={e => setSearchValue(e.target.value)}
                    />
                    <Dropdown.Menu scrolling>
                        {filteredOptions
                            .map(option => (
                                <Dropdown.Item
                                    {...option}
                                    key={option.idempotency}
                                    onClick={() => {
                                        onChange(option.value);
                                        setOpen(false);
                                    }}
                                />
                            ))}
                    </Dropdown.Menu>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default SearchableDropdown;
