import instanceService from '../../services/instance/instanceService';

export const FETCH_ACLS = 'FETCH_ACLS';
export const FETCH_ACLS_FULFILLED = 'FETCH_ACLS_FULFILLED';
export const FETCH_ACLS_REJECTED = 'FETCH_ACLS_REJECTED';
export const ACL_CHANGED = 'ACL_CHANGED';
export const ACL_DELETED = 'ACL_DELETED';

const requestAcls = () => ({ type: FETCH_ACLS });
const requestAclsFulfilled = acls => ({ type: FETCH_ACLS_FULFILLED, acls });
const requestAclsRejected = error => ({ type: FETCH_ACLS_REJECTED, error });

export const aclChanged = acl => ({
    type: ACL_CHANGED,
    acl
});

export const aclDeleted = id => ({
    type: ACL_DELETED,
    id
})

export const fetchAcls = () => async dispatch => {

    dispatch(requestAcls());

    try {
        const acls = await instanceService.fetchAcls();
        dispatch(requestAclsFulfilled(acls));
    } catch (err) {
        console.error(err);
        dispatch(requestAclsRejected(err));
    }
}