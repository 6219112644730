import React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import Icon from '../icon';
import './FlatButton.css';

const FlatButton = ({ title, icon, className, onClick, active, disabled, children, style, position, loading }) => {

    const cName = `man-flatbtn${className ? ` ${className}` : ''}`

    if (!title) {
        return (
            <Button className={cName} icon basic onClick={onClick} active={active} disabled={disabled} style={{ ...style }} loading={loading}>
                {children}{children && ` `}{icon && <Icon style={{ verticalAlign: 'baseline' }} name={icon} />}
            </Button>
        )
    }

    return (
        <Popup position={position || 'bottom right'}
            inverted
            content={title}
            trigger={
                <Button className={cName} icon basic onClick={onClick} active={active} disabled={disabled} style={{ ...style }} loading={loading}>
                    {children}{children && ` `}<Icon style={{ verticalAlign: 'baseline' }} name={icon} />
                </Button>
            } />
    )
}

export default FlatButton;
