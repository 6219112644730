import { get } from '../../../utils/http/HttpClient';
import WorkOrderGetResponse from '../workorder/api/WorkOrderGetResponse';
import qs from 'query-string';

export default {
    fetchWorkOrderHistory: async (startTime, endTime, customerId, workType, workOrderTitle) => {
        const query = qs.stringify({
            startTime,
            endTime,
            customerId,
            workType,
            title: workOrderTitle
        });

        const { workOrders, totalCount } = await get(`/wo/history/closed?${query}`);

        return new WorkOrderGetResponse({ workOrders, totalCount });
    },
    fetchAgreementInvoices: async (startTime, endTime) => {
        const { invoices } = await get(`/invoice/agreement/history?startTime=${startTime}&endTime=${endTime}`);

        return invoices;
    },
    fetchAgreementInvoiceCount: async ({ startTime, endTime }) => {
        try {
            const { count } = await get(`/invoice/agreement/count?startTime=${startTime}&endTime=${endTime}`);

            return count;
        } catch {
            return 0;
        }
    },
}