import { STATUS_ACTIVE, STATUS_INACTIVE } from '../../../utils/constants';
import { mapUserToDropdown } from '../../../utils/DropdownMappers';
import { defaultUserSort } from '../../../utils/UserUtils';
import { LOGIN_FULFILLED } from '../../actions/common/authActions';
import {
    FETCH_USERS,
    FETCH_USERS_FULFILLED,
    FETCH_USERS_REJECTED,
    USER_CHANGED,
    USER_FILTER_CHANGE,
    USER_TABLE_STATE_CHANGE,
} from '../../actions/users';

const defaultState = {
    isFetching: false,
    departments: [],
    users: [],
    usersById: {},
    activeUsers: [],
    onlyActiveUsers: [],
    dropDownOptions: [],
    tableState: undefined,
    filters: {
        active: true,
        inactive: false,
        registered: false
    }
};

export const users = (state = defaultState, action) => {
    switch (action.type) {
        case USER_TABLE_STATE_CHANGE:
            return { ...state, tableState: action.state };
        case USER_FILTER_CHANGE: return ({
            ...state,
            filters: {
                ...state.filters,
                [action.filterName]: action.value
            }
        })
        case LOGIN_FULFILLED: {
            return {
                ...state,
                departments: action.departments,
            };
        }
        case FETCH_USERS: {
            return {
                ...state,
                isFetching: true,
            };
        }
        case FETCH_USERS_FULFILLED: {
            const { departments } = state;

            const usersById = {};

            const users = action.users
                .map(u => {
                    u.department = departments.find(
                        d => d.id === u.departmentId
                    );
                    u.initials = u.shortName;
                    u.departmentName = u.department.displayName;

                    usersById[u.id] = u;
                    return u;
                })
                .sort(defaultUserSort);

            return {
                ...state,
                isFetching: false,
                users,
                usersById,
                activeUsers: users.filter(
                    u => u.status.indexOf(STATUS_INACTIVE) == -1
                ),
                onlyActiveUsers: users.filter(u => u.status === STATUS_ACTIVE),
                dropDownOptions: users
                    .filter(u => u.status.indexOf(STATUS_INACTIVE) == -1)
                    .map(mapUserToDropdown),
            };
        }
        case FETCH_USERS_REJECTED: {
            return {
                ...state,
                isFetching: false,
            };
        }
        case USER_CHANGED: {
            const users = [...state.users];
            const idx = users.findIndex(c => c.id === action.user.id);

            const usersById = {
                ...state.usersById,
                [action.user.id]: action.user,
            };

            if (idx === -1) {
                users.push(action.user);
            } else {
                users[idx] = {
                    ...action.user,
                    initials: action.user.shortName,
                };
            }

            return {
                ...state,
                users,
                usersById,
                activeUsers: users.filter(
                    u => u.status.indexOf(STATUS_INACTIVE) == -1
                ),
                dropDownOptions: users
                    .filter(u => u.status.indexOf(STATUS_INACTIVE) == -1)
                    .map(mapUserToDropdown),
            };
        }
        default: {
            return state;
        }
    }
};
