import Alert from 'components/common/alert';
import ArticlePicker from 'components/common/article-picker/ArticlePicker';
import BottomSheet from 'components/common/bottom-sheet';
import InputNumeric from 'components/common/input-numeric';
import Loader from 'components/common/loader';
import { useConfirm } from 'components/common/use-confirm';
import WorkOrderArticleTag from 'components/main/common/workorder-article-tag';
import { useArticleGroups } from 'components/main/settings/tabs/articles/article-groups/useArticleGroups';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import TextareaAutosize from 'react-textarea-autosize';
import { Form, Input, Popup } from 'semantic-ui-react';
import { Item } from 'trukks-lib';
import articleService from '../../../../../data/services/article/articleService';
import { defaultSort } from '../../../../../utils/ArrayUtils';
import {
    mapArticleGroupToOption,
    mapArticleToOption,
} from '../../../../../utils/DropdownMappers';
import {
    calculateDiscountedPrice,
    calculateMargin,
} from '../../../../../utils/MathUtils';
import {
    getDiscountText,
    parseDiscount,
    validateDiscount,
} from '../../../../../utils/ReportRowUtils';
import {
    formatPercent,
    formatPrice,
    formatPriceSum,
} from '../../../../../utils/StringUtils';
import { STATUS_ACTIVE, articleTypes } from '../../../../../utils/constants';
import { discountTypes } from '../../../../../utils/constants/discountTypes';
import FlatButton from '../../../../common/flat-button';
import Icon from '../../../../common/icon';
import LocalizedDropdown from '../../../../common/localized-dropdown';
import ValidatableInput from '../../../../common/validatable-input/ValidatableInput';
import './ProposalArticles.css';
import CostCenterPicker from './erp-reference-picker/CostCenterPicker';
import StockPointPicker from './erp-reference-picker/StockPointPicker';
import ProjectInScopeEditor from './scope-editor';
import ProjectInScopeLabel from './scope-editor/ProjectInScopeLabel';

const ArticleSelector = ({
    t,
    articleGroupsEnabled,
    articleId,
    articleTitle,
    articleDescription,
    onArticleChange,
    articles,
}) => {
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [selectedArticleGroups, setSelectedArticleGroups] = useState([]);
    const { articleGroups, isFetching } = useArticleGroups();

    const filterArticleGroupSelected = article => {
        if (!selectedArticleGroups || selectedArticleGroups.length === 0) {
            return true;
        }

        const found = selectedArticleGroups.findIndex(
            g => article.articleGroupId === g
        );

        return found > -1;
    };

    if (articleTitle) {
        return <div>{`${articleTitle} - ${articleDescription || ''}`}</div>;
    }

    return (
        <>
            <div className="man-flex-row man-flex-row-sb">
                <label
                    style={{
                        fontWeight: '700',
                    }}>
                    {optionsOpen
                        ? t('ui:SELECT_ARTICLE_GROUPS')
                        : t('ui:SELECT_ARTICLE')}
                </label>
                {articleGroupsEnabled && (
                    <div
                        style={{
                            marginLeft: 20,
                        }}>
                        {optionsOpen ? (
                            <a
                                onClick={() => setOptionsOpen(false)}
                                style={{
                                    fontWeight: 700,
                                }}>
                                {t('ui:SELECT_ARTICLE_MORE_OPTIONS_BACK')}
                                <Icon name="angle double left" />
                            </a>
                        ) : (
                            <a
                                onClick={() => setOptionsOpen(true)}
                                style={{
                                    fontWeight: 700,
                                }}>
                                {t('ui:SELECT_ARTICLE_MORE_OPTIONS')}
                                <Icon name="angle double right" />
                            </a>
                        )}
                    </div>
                )}
            </div>
            {optionsOpen ? (
                <LocalizedDropdown
                    fluid
                    selection
                    search
                    multiple
                    defaultOpen={true}
                    value={selectedArticleGroups}
                    loading={isFetching}
                    onChange={(e, { value }) => setSelectedArticleGroups(value)}
                    options={articleGroups.map(mapArticleGroupToOption)}
                />
            ) : (
                <div>
                    <LocalizedDropdown
                        fluid
                        selection
                        search
                        defaultOpen={true}
                        value={articleId}
                        onChange={(e, { value }) => onArticleChange(value)}
                        options={articles
                            .filter(a => a.status === STATUS_ACTIVE)
                            .filter(filterArticleGroupSelected)
                            .map(mapArticleToOption)}
                    />
                    {selectedArticleGroups.length > 0 && (
                        <Alert
                            style={{
                                display: 'block',
                            }}
                            type="warning"
                            message={t('SELECT_ARTICLE_FILTERING_IS_ACTIVE')}
                        />
                    )}
                </div>
            )}
        </>
    );
};

const ProposalRow = ({
    t,
    idx,
    customerId,
    articles,
    row,
    onClick,
    currency,
    editable,
    isProjectScopeEnabled,
}) => {
    return (
        <Draggable
            key={`${idx}-${row.id}`}
            draggableId={`${idx}-${row.id}`}
            index={idx}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={{
                        background: snapshot.isDragging
                            ? 'var(--color-active)'
                            : 'initial',
                        boxShadow: snapshot.isDragging
                            ? 'var(--shadow-default)'
                            : 'initial',
                        marginTop: 4,
                        ...provided.draggableProps.style,
                    }}>
                    <Item active onClick={editable ? onClick : null}>
                        <div className="man-flex-row">
                            <div
                                {...provided.dragHandleProps}
                                className={`man-proposal-drag-handle-container${editable ? '' : ' man-disabled'
                                    }`}>
                                <Icon name="sort"></Icon>
                            </div>

                            {row.comment != null ? (
                                <CommentRow
                                    row={row}
                                    t={t}
                                    editable={editable}
                                    currency={currency}
                                />
                            ) : (
                                <ArticleRow
                                    row={row}
                                    t={t}
                                    customerId={customerId}
                                    articles={articles}
                                    currency={currency}
                                    editable={editable}
                                    isProjectScopeEnabled={
                                        isProjectScopeEnabled
                                    }
                                />
                            )}

                            <div
                                style={{
                                    marginLeft: 'auto',
                                }}>
                                {editable && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flexDirection: 'row',
                                        }}>
                                        <FlatButton icon="pencil" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </Item>
                </div>
            )}
        </Draggable>
    );
};

const ArticleRow = ({ t, row, currency, isProjectScopeEnabled }) => {
    return (
        <div
            className="man-flex-row man-flex-row-sb"
            style={{
                width: '100%',
            }}>
            <div
                style={{
                    flex: 2,
                }}>
                {row.ref?.type === 'supplier-inventory' && (
                    <Popup
                        trigger={
                            <div
                                style={{
                                    float: 'right',
                                }}
                                className="ui tiny circular label yellow">
                                B
                            </div>
                        }>
                        {row.ref?.supplierId &&
                            t(`suppliers:${row.ref?.supplierId}`)}
                    </Popup>
                )}
                {row.ref?.name === 'supplier.upstream' && (
                    <Popup
                        trigger={
                            <div
                                className={`ui circular tiny label trks-bg-accent trks-hoverable`}>
                                {`${t(
                                    'ui:WORK_ORDER_ARTICLE_FROM_SUPPLIER_SHORT'
                                )}: ${t(
                                    `suppliers:${row.ref?.upstreamRef?.name}`
                                )}`}
                            </div>
                        }>
                        {`${t('form:ID')}: ${row.ref?.upstreamRef?.orderId}`}
                    </Popup>
                )}
                {row.articleDescription}
                <div className="man-secondary-text">{row.articleTitle}</div>
                {isProjectScopeEnabled && (
                    <ProjectInScopeLabel value={row.isInProjectScope} />
                )}
            </div>
            <div
                style={{
                    textAlign: 'right',
                    flex: 1,
                }}>
                <div style={{ marginRight: 6 }} className="man-secondary-text">
                    {getDiscountText(
                        row.articleDiscountType,
                        row.articleDiscountValue,
                        currency.symbol
                    )}
                </div>
                {row.articleUnit
                    ? `${row.articleAmount} / ${row.articleUnit}`
                    : row.articleAmount}
                <div
                    className={`man-secondary-text${row.articlePrice < 0 ? ' man-negative' : ''
                        }`}>
                    <div>
                        <div>
                            <WorkOrderArticleTag tag={row.articlePriceTag} />
                            <b>
                                {formatPrice(
                                    calculateDiscountedPrice(
                                        row.articlePrice,
                                        row.articleAmount,
                                        row.articleDiscountType,
                                        row.articleDiscountValue
                                    ),
                                    currency.symbol
                                )}
                            </b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const CommentRow = ({ t, row, onChange, editable, currency }) => (
    <div
        className="man-flex-row man-flex-row-sb"
        style={{
            width: '100%',
        }}>
        <div>
            <div className="man-ui-wrap-newline">
                <b>{`${row.comment}`}</b>
            </div>
        </div>
        <div
            style={{
                textDecoration: 'underline',
            }}>
            {row.isSummaryRow && (
                <b>{formatPriceSum(row.summarySalesPrice, currency.symbol)}</b>
            )}
        </div>
    </div>
);

const BottomSheetContent = ({
    t,
    openBottomSheet,
    onChange,
    onRemove,
    articles,
    currency,
    onClose,
    isFreeText,
    customerId,
    allowNegativePrices,
    includeSupplierInventoryArticles,
    articleGroupsEnabled,
    isProjectScopeEnabled,
    purchasePriceEditingDisabled,
    stockPointEnabled,
    costCenterEnabled,
}) => {
    const [loading, setLoading] = useState(false);

    if (!openBottomSheet) {
        return null;
    }

    const { row } = openBottomSheet;

    const {
        id,
        comment,
        articleId,
        articleTitle,
        articleDescription,
        articleUnit,
        articleAmount,
        articlePrice,
        articlePurchasePrice,
        articlePriceTag,
        articleDiscountType,
        articleDiscountValue,
        erpRef,
        isInProjectScope
    } = row;

    const handleDiscountChange = value => {
        const discount = parseDiscount(value);

        onChange({
            ...row,
            articleDiscountValue: discount.discountValue,
            articleDiscountType: discount.discountType,
        });
    };

    const handleValueChange = (name, value) => {
        if (!allowNegativePrices && name === 'articlePrice' && value < 0) {
            return;
        }

        onChange({
            ...row,
            [name]: value,
        });
    };

    const handleArticleChange = async article => {
        try {
            setLoading(true);

            const { salesPrice, priceType, discountValue, discountType } =
                await articleService.fetchPrices(article.id, customerId);

            const newRow = {
                ...row,
                articleId: article.id,
                articleTitle: article.title,
                articleDescription: article.description,
                articlePurchasePrice: article.purchasePrice,
                articlePrice: salesPrice,
                articleTotalPrice: 0,
                articleAmount: 1,
                articleUnit: article.unit,
                articlePriceTag: priceType,
                ref: article.ref,
            };

            if (discountValue && discountValue > 0) {
                newRow.articleDiscountType = discountType;
                newRow.articleDiscountValue = discountValue;
            }

            onChange(newRow);
        } catch {
            onChange({
                ...row,
                articleId: article.id,
                articleTitle: article.title,
                articleDescription: article.description,
                articlePurchasePrice: article.purchasePrice,
                articlePrice: article.price,
                articleTotalPrice: 0,
                articleAmount: 1,
                articleUnit: article.unit,
                ref: article.ref,
            });
        } finally {
            setLoading(false);
        }
    };

    const articleTotalPrice = calculateDiscountedPrice(
        articlePrice,
        articleAmount,
        articleDiscountType,
        articleDiscountValue
    );

    if (comment != null) {
        return (
            <div>
                <Form.Field>
                    <label>{t('form:COMMENT')}</label>
                    <TextareaAutosize
                        autoFocus
                        onChange={e =>
                            handleValueChange('comment', e.target.value)
                        }
                        value={comment}
                    />
                </Form.Field>

                <div className="man-flex-row man-flex-row-sb">
                    <FlatButton
                        className="man-negative"
                        icon="remove"
                        onClick={onRemove}>
                        {t('ui:REMOVE')}
                    </FlatButton>

                    <FlatButton
                        className="man-positive"
                        icon="check"
                        onClick={onClose}>
                        {t('ui:SAVE_AND_CLOSE')}
                    </FlatButton>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div
                style={{
                    opacity: loading ? 0.5 : 1,
                }}>
                {loading && <Loader />}

                <Form.Field>
                    <label>{t('form:ARTICLE')}</label>

                    {includeSupplierInventoryArticles ? (
                        <div>
                            {!articleTitle ? (
                                <ArticlePicker
                                    isLoading={loading}
                                    includeSupplierInventoryArticles={true}
                                    focusOnMount={true}
                                    onChange={value =>
                                        handleArticleChange(value)
                                    }
                                />
                            ) : (
                                <div>
                                    <div>
                                        {`${articleTitle} - ${articleDescription}`}
                                    </div>
                                    <div>
                                        {row.ref?.type ===
                                            'supplier-inventory' && (
                                                <Popup
                                                    trigger={
                                                        <div
                                                            style={{
                                                                float: 'right',
                                                            }}
                                                            className="ui tiny circular label yellow">
                                                            B
                                                        </div>
                                                    }>
                                                    {row.ref?.supplierId &&
                                                        t(
                                                            `suppliers:${row.ref?.supplierId}`
                                                        )}
                                                </Popup>
                                            )}
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <ArticleSelector
                            t={t}
                            articleGroupsEnabled={articleGroupsEnabled}
                            articleId={articleId}
                            articleTitle={articleTitle}
                            articleDescription={articleDescription}
                            onArticleChange={handleArticleChange}
                            articles={articles}
                        />
                    )}
                </Form.Field>

                {articleId && isFreeText(articleId) && (
                    <Form.Field>
                        <label>{t('form:ARTICLE_DESCRIPTION')}</label>

                        <div>
                            <Input
                                value={articleDescription}
                                onChange={e =>
                                    handleValueChange(
                                        'articleDescription',
                                        e.target.value
                                    )
                                }
                            />
                        </div>
                    </Form.Field>
                )}

                <Form.Field>
                    <label>{t('form:AMOUNT')}</label>
                    <div>
                        <InputNumeric
                            label={articleUnit || ''}
                            value={articleAmount}
                            onValueChange={v =>
                                handleValueChange('articleAmount', v)
                            }
                            onChange={e =>
                                handleValueChange(
                                    'articleAmount',
                                    e.target.value
                                )
                            }
                        />
                    </div>
                </Form.Field>

                {costCenterEnabled &&
                    <Form.Field>
                        <label>{t('form:COST_CENTER')}</label>
                        <div>
                            <CostCenterPicker
                                search selection fluid
                                value={erpRef?.costCenter?.value}
                                onChange={v =>
                                    handleValueChange('erpRef', {
                                        ...(erpRef || {}),
                                        costCenter: {
                                            value: v
                                        }
                                    })
                                }
                            />
                        </div>
                    </Form.Field>}

                {stockPointEnabled &&
                    <Form.Field>
                        <label>{t('form:STOCK_POINT')}</label>
                        <div>
                            <StockPointPicker
                                search selection fluid
                                value={erpRef?.stockPoint?.value}
                                onChange={v =>
                                    handleValueChange('erpRef', {
                                        ...(erpRef || {}),
                                        stockPoint: {
                                            value: v
                                        }
                                    })
                                }
                            />
                        </div>
                    </Form.Field>}

                <Form.Field>
                    <label>{t('form:PURCHASE_PRICE')}</label>
                    <div className="man-articlereport-mobile-input-container">
                        {purchasePriceEditingDisabled ?
                            formatPrice(articlePurchasePrice, currency.symbol) :
                            <InputNumeric
                                label={currency.symbol}
                                value={articlePurchasePrice}
                                onChange={e =>
                                    handleValueChange(
                                        'articlePurchasePrice',
                                        e.target.value
                                    )
                                }
                            />
                        }
                    </div>
                </Form.Field>

                <Form.Field>
                    <label>{t('form:PRICE')}</label>
                    <div className="man-articlereport-mobile-input-container">
                        <InputNumeric
                            label={currency.symbol}
                            value={articlePrice}
                            onChange={e =>
                                handleValueChange(
                                    'articlePrice',
                                    e.target.value
                                )
                            }
                        />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <WorkOrderArticleTag tag={articlePriceTag} />
                        </div>
                    </div>
                </Form.Field>

                <Form.Field>
                    <label>{`${t('ui:DISCOUNT_PLACEHOLDER')} ${currency.symbol
                        }`}</label>

                    <ValidatableInput
                        style={{
                            display: 'flex',
                        }}
                        onChange={(name, value) => handleDiscountChange(value)}
                        validate={validateDiscount}
                        value={`${articleDiscountValue ? articleDiscountValue : ''
                            }${articleDiscountType &&
                                articleDiscountType === discountTypes.PERCENTAGE
                                ? '%'
                                : ''
                            }`}
                    />
                </Form.Field>

                <div>
                    <ProjectInScopeEditor
                        value={isInProjectScope}
                        onChange={value =>
                            handleValueChange('isInProjectScope', value)
                        }
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                    }}>
                    <Form.Field
                        style={{
                            width: '100%',
                        }}>
                        <label>{t('ui:TOTAL')}</label>
                        {formatPrice(articleTotalPrice, currency.symbol)}
                    </Form.Field>

                    {articlePurchasePrice !== null &&
                        articlePurchasePrice != 0 && (
                            <Form.Field>
                                <label
                                    style={{
                                        opacity: 0,
                                    }}>
                                    -
                                </label>
                                {t('ui:MARGIN_PERCENTAGE_SHORT', {
                                    margin: formatPercent(
                                        calculateMargin(
                                            articlePurchasePrice *
                                            articleAmount,
                                            articleTotalPrice
                                        )
                                    ),
                                })}
                            </Form.Field>
                        )}
                </div>

                <div className="man-flex-row man-flex-row-sb">
                    <FlatButton
                        className="man-negative"
                        icon="remove"
                        onClick={onRemove}>
                        {t('ui:REMOVE')}
                    </FlatButton>

                    <FlatButton
                        className="man-positive"
                        icon="check"
                        onClick={onClose}>
                        {t('ui:SAVE_AND_CLOSE')}
                    </FlatButton>
                </div>
            </div>
        </div>
    );
};

const ProposalArticlesContainerMobile = ({
    t,
    customerId,
    articles,
    currency,
    rows,
    onRowChange,
    onRowDelete,
    margin,
    totalPrice,
    totalPurchasePrice,
    totalPriceIncludingVat,
    showTotalPriceIncludingVat,
    allowNegativePrices,
    summaryRowsEnabled,
    editable,
    onDragEnd,
    totalRows,
    onAddCommentRow,
    onAddArticleRow,
    onAddSummaryRow,
    includeSupplierInventoryArticles,
    articleGroupsEnabled,
    isProjectScopeEnabled,
    purchasePriceEditingDisabled,
    stockPointEnabled,
    costCenterEnabled
}) => {
    const [openBottomSheet, setOpenBottomSheet] = useState(null);
    const { confirm } = useConfirm();

    const handleClick = idx => {
        setOpenBottomSheet({
            row: rows[idx],
            idx,
        });
    };

    const handleAddComment = () => {
        const { row, idx } = onAddCommentRow();

        setOpenBottomSheet({
            row,
            idx,
        });
    };

    const handleAddArticle = () => {
        const { row, idx } = onAddArticleRow();

        setOpenBottomSheet({
            row,
            idx,
        });
    };

    const handleAddSummaryRow = () => {
        const { row, idx } = onAddSummaryRow();

        setOpenBottomSheet({
            row,
            idx,
        });
    };

    const handleRemove = () => {
        confirm('messages:ARE_YOU_SURE', () => {
            onRowDelete(openBottomSheet.row.id);
            setOpenBottomSheet(null);
        });
    };

    const handleChange = row => {
        onRowChange(row);
        setOpenBottomSheet({
            ...openBottomSheet,
            row,
        });
    };

    const isFreeText = id => {
        if (articles.length === 0 || id == null) {
            return false;
        }

        const a = articles.find(a => a.id === id);

        return a && a.type === articleTypes.FREETEXT;
    };

    return (
        <div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="proposal-articles">
                    {provided => (
                        <div ref={provided.innerRef}>
                            {rows.sort(defaultSort).map((r, idx) => (
                                <ProposalRow
                                    t={t}
                                    key={`${r.id}-${r.sortOrder}-${idx}`}
                                    idx={idx}
                                    totalRows={totalRows}
                                    row={r}
                                    articles={articles}
                                    customerId={customerId}
                                    currency={currency}
                                    editable={editable}
                                    onClick={() => handleClick(idx)}
                                    isProjectScopeEnabled={
                                        isProjectScopeEnabled
                                    }
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <Item>
                <div className="man-flex-row man-flex-row-sb">
                    <div></div>
                    <div
                        className={totalPrice < 0 ? 'man-negative' : ''}
                        style={{
                            textAlign: 'right',
                        }}>
                        <b>{formatPriceSum(totalPrice, currency.symbol)}</b>
                        {showTotalPriceIncludingVat && (
                            <span className="trks-secondary-text">
                                {`(${t('ui:EXCLUDING_VAT')})`}
                            </span>
                        )}
                        <div className="man-secondary-text">
                            {margin
                                ? ` ${t('ui:MARGIN_PERCENTAGE', {
                                    margin: formatPercent(margin),
                                })}`
                                : ''}
                        </div>
                        {showTotalPriceIncludingVat && (
                            <div
                                style={{
                                    marginTop: 10,
                                }}>
                                {formatPriceSum(
                                    totalPriceIncludingVat,
                                    currency.symbol
                                )}
                                <span className="trks-secondary-text">
                                    {`(${t('ui:INCLUDING_VAT')})`}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </Item>

            <div className="man-flex-row man-flex-row-sb">
                {editable && (
                    <div>
                        <a
                            style={{
                                padding: '6px',
                            }}
                            onClick={handleAddComment}>
                            {t('ui:ADD_COMMENT_ROW')}
                        </a>
                        <div>
                            {summaryRowsEnabled && (
                                <a
                                    style={{
                                        padding: '6px',
                                        marginTop: 8,
                                        marginBottom: 8,
                                    }}
                                    onClick={handleAddSummaryRow}>
                                    {t('ui:ADD_SUMMARY_ROW')}
                                </a>
                            )}
                        </div>
                    </div>
                )}
                {editable && (
                    <a
                        style={{
                            padding: '6px',
                        }}
                        onClick={handleAddArticle}>
                        {t('ui:ADD_ARTICLE_ROW')}
                    </a>
                )}
            </div>

            {editable && (
                <BottomSheet
                    open={!!openBottomSheet}
                    onChange={() => setOpenBottomSheet(null)}>
                    {openBottomSheet && (
                        <div
                            style={{
                                marginBottom: 20,
                            }}>
                            <BottomSheetContent
                                openBottomSheet={openBottomSheet}
                                allowNegativePrices={allowNegativePrices}
                                purchasePriceEditingDisabled={purchasePriceEditingDisabled}
                                includeSupplierInventoryArticles={
                                    includeSupplierInventoryArticles
                                }
                                articleGroupsEnabled={articleGroupsEnabled}
                                t={t}
                                customerId={customerId}
                                onChange={handleChange}
                                onRemove={handleRemove}
                                articles={articles}
                                currency={currency}
                                onClose={() => setOpenBottomSheet(null)}
                                isFreeText={isFreeText}
                                isProjectScopeEnabled={isProjectScopeEnabled}
                                stockPointEnabled={stockPointEnabled}
                                costCenterEnabled={costCenterEnabled}
                            />
                        </div>
                    )}
                </BottomSheet>
            )}
        </div>
    );
};

ProposalArticlesContainerMobile.propTypes = {
    onChange: PropTypes.func.isRequired,
    rows: PropTypes.array,
    editable: PropTypes.bool,
    totalPrice: PropTypes.number,
    margin: PropTypes.number,
};

ProposalArticlesContainerMobile.defaultProps = {
    rows: [],
    editable: true,
};

export default ProposalArticlesContainerMobile;
