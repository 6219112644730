export default class Acl {

    id;
    name;
    description;

    constructor({ id, name, description }) {
        this.id = id;
        this.name = name;
        this.description = description;
    }

}