import reportsService from '../../services/reports/reportsService';
import { showErrorNotification } from '../common/notificationActions';

export const REPORTS_AGREEMENTS_TABLE_STATE_CHANGE = 'REPORTS_AGREEMENTS_TABLE_STATE_CHANGE';
export const REPORTS_AGREEMENTS_FILTERS_CHANGE = 'REPORTS_AGREEMENTS_FILTERS_CHANGE';
export const FETCH_REPORTS_AGREEMENT_INVOICES = 'FETCH_REPORTS_AGREEMENT_INVOICES';
export const FETCH_REPORTS_AGREEMENT_INVOICES_FULFILLED = 'FETCH_REPORTS_AGREEMENT_INVOICES_FULFILLED';
export const FETCH_REPORTS_AGREEMENT_INVOICES_REJECTED = 'FETCH_REPORTS_AGREEMENT_INVOICES_REJECTED';

export const editAgreementInvoicesReportsFilter = filters => ({
    type: REPORTS_AGREEMENTS_FILTERS_CHANGE,
    filters
});

export const editAgreementInvoicesReportsTableState = state => ({
    type: REPORTS_AGREEMENTS_TABLE_STATE_CHANGE,
    state
});

const agreementInvoicesFetch = () => ({
    type: FETCH_REPORTS_AGREEMENT_INVOICES
});

const agreementInvoicesFetchFulfilled = invoices => ({
    type: FETCH_REPORTS_AGREEMENT_INVOICES_FULFILLED,
    invoices
});

const agreementInvoicesFetchRejected = error => ({
    type: FETCH_REPORTS_AGREEMENT_INVOICES_REJECTED,
    error
});

export const fetchAgreementInvoices = ({ startTime, endTime }) => async dispatch => {
    dispatch(agreementInvoicesFetch());

    try {
        const invoices = await reportsService.fetchAgreementInvoices(startTime, endTime);

        dispatch(agreementInvoicesFetchFulfilled(invoices));
    } catch (err) {
        dispatch(agreementInvoicesFetchRejected(err));
        dispatch(showErrorNotification('FAILED_TO_FETCH'));
    }
}