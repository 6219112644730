import {
    REPORTS_AGREEMENTS_TABLE_STATE_CHANGE,
    REPORTS_AGREEMENTS_FILTERS_CHANGE, 
    FETCH_REPORTS_AGREEMENT_INVOICES, 
    FETCH_REPORTS_AGREEMENT_INVOICES_FULFILLED, 
    FETCH_REPORTS_AGREEMENT_INVOICES_REJECTED
} from '../../actions/reports';
import { getThisMonthPeriod } from '../../../utils/DateUtils';

const defaultState = {
    isLoading: false,
    invoices: [],
    tableState: undefined,
    filters: getThisMonthPeriod()
}

export const agreementInvoices = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case REPORTS_AGREEMENTS_FILTERS_CHANGE: {

            return {
                ...state,
                filters: action.filters
            }
        }
        case REPORTS_AGREEMENTS_TABLE_STATE_CHANGE: {

            return {
                ...state,
                tableState: action.state
            }
        }
        case FETCH_REPORTS_AGREEMENT_INVOICES: {

            return {
                ...state,
                isLoading: true
            }
        }
        case FETCH_REPORTS_AGREEMENT_INVOICES_FULFILLED: {

            return {
                ...state,
                isLoading: false,
                invoices: action.invoices
            }
        }
        case FETCH_REPORTS_AGREEMENT_INVOICES_REJECTED: {

            return {
                ...state,
                isLoading: false
            }
        }
        default: {
            return state;
        }
    }
};