import classNames from 'classnames';
import React, { FC } from 'react';
import { Icon } from '..';
import styles from './Stepper.module.css';

interface Props {
    children?: object;
    style?: object;
    active: boolean;
}

const Step: FC<Props> = ({ children, active, style }) => (
    <div
        className={classNames([styles.step, { [styles.active]: active }])}>
        <div className={styles.stepIndicator}>
            <div className={styles.circle}>
                {active && <Icon name="check" style={{
                    margin: 0,
                    marginBottom: 4
                }} />}
            </div>
            <div className={
                classNames([styles.line, { [styles.active]: active }])
            }></div>
        </div>
        {children}
    </div>
);

export default Step;
