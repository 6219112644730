export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
const DEFAULT_NOTIFICATION_TIME_MS = 5000;
let notificationCounter = 0;

const showNotificationAction = notification => ({
    type: SHOW_NOTIFICATION,
    notification
})

const hideNotificationAction = id => ({
    type: HIDE_NOTIFICATION,
    id
})

const generateNotificationId = type => {
    return `${++notificationCounter}-${type}`;
}

export const closeNotification = id => dispatch => {
    dispatch(hideNotificationAction(id));
}

export const showErrorNotification = text => dispatch => {
    let id = generateNotificationId('error');
    dispatch(showNotificationAction({
        id,
        text,
        className: 'negative',
        icon: 'warning'
    }));

    setTimeout(() => {
        dispatch(hideNotificationAction(id));
    }, DEFAULT_NOTIFICATION_TIME_MS);
}

export const showSuccessNotification = (text, params, lengthMs = 3000) => dispatch => {
    let id = generateNotificationId('success');

    dispatch(showNotificationAction({
        id,
        text,
        className: 'positive',
        icon: 'warning',
        params
    }));

    setTimeout(() => {
        dispatch(hideNotificationAction(id));
    }, lengthMs);
}


export const showInfoNotification = (text, lengthMs = DEFAULT_NOTIFICATION_TIME_MS) => dispatch => {
    let id = generateNotificationId('info');
    dispatch(showNotificationAction({
        id,
        text,
        className: '',
        icon: 'info'
    }));

    setTimeout(() => {
        dispatch(hideNotificationAction(id));
    }, lengthMs);
}


export const showWarningNotification = text => dispatch => {
    let id = generateNotificationId('warning');
    dispatch(showNotificationAction({
        id,
        text,
        className: 'warning',
        icon: 'warning'
    }));

    setTimeout(() => {
        dispatch(hideNotificationAction(id));
    }, DEFAULT_NOTIFICATION_TIME_MS);
}