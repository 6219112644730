import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { closeConfirm } from '../../../data/actions/common/confirmActions';
import BottomSheet from '../bottom-sheet';
import DataModal from '../modal/DataModal';

class ConfirmModalContainer extends React.Component {
    render() {
        const {
            t,
            header,
            body,
            rawBody,
            confirmButtonText,
            closeButtonText,
            onConfirm,
            onClose,
            onConfirmSuccess,
            onCancel,
            close,
            isSmallScreen,
        } = this.props;

        if (!onConfirm) {
            return null;
        }

        const closeFunc = onClose
            ? () => {
                  onClose();
                  close();
              }
            : close;

        if (isSmallScreen) {
            return (
                <BottomSheet open={true} zIndex={10000} onChange={closeFunc}>
                    <h4>{t(header)}</h4>

                    <div className="content">
                        {body ? t(body) : null}
                        {rawBody && rawBody}
                    </div>
                    <div
                        className="actions"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}>
                        <Button
                            onClick={async e => {
                                e.stopPropagation();
                                closeFunc();
                                onCancel && onCancel();
                            }}
                            style={{ width: '200px' }}>
                            {closeButtonText ? t(closeButtonText) : t('CLOSE')}
                        </Button>
                        <Button
                            onClick={async e => {
                                e.stopPropagation();
                                closeFunc();
                                await onConfirm();
                                onConfirmSuccess && onConfirmSuccess();
                            }}
                            style={{ width: '200px' }}
                            className="man-button-primary">
                            {confirmButtonText
                                ? t(confirmButtonText)
                                : t('CONFIRM')}
                        </Button>
                    </div>
                </BottomSheet>
            );
        }

        return (
            <DataModal
                style={{ zIndex: 10000 }}
                header={t(header)}
                content={
                    <React.Fragment>
                        <div className="content">
                            {body ? t(body) : null}
                            {rawBody && rawBody}
                        </div>
                        <div
                            className="actions"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                            }}>
                            <Button
                                onClick={async e => {
                                    e.stopPropagation();
                                    closeFunc();
                                    onCancel && onCancel();
                                }}
                                style={{ width: '200px' }}>
                                {closeButtonText
                                    ? t(closeButtonText)
                                    : t('CLOSE')}
                            </Button>
                            <Button
                                onClick={async e => {
                                    e.stopPropagation();
                                    closeFunc();
                                    await onConfirm();
                                    onConfirmSuccess && onConfirmSuccess();
                                }}
                                style={{ width: '200px' }}
                                className="man-button-primary">
                                {confirmButtonText
                                    ? t(confirmButtonText)
                                    : t('CONFIRM')}
                            </Button>
                        </div>
                    </React.Fragment>
                }
                onClose={closeFunc}
            />
        );
    }
}

ConfirmModalContainer.propTypes = {
    onConfirm: PropTypes.func,
    onConfirmSuccess: PropTypes.func,
    onClose: PropTypes.func,
    header: PropTypes.string,
    body: PropTypes.string,
    confirmButtonText: PropTypes.string,
    closeButtonText: PropTypes.string,
};

ConfirmModalContainer.defaultProps = {
    onConfirm: null,
    onConfirmSuccess: null,
    onClose: null,
    header: 'PLEASE_CONFIRM_ACTION',
    body: '',
    confirmButtonText: 'ACTION_CONFIRM',
    closeButtonText: 'ACTION_CANCEL',
};

const mapStateToProps = state => ({
    ...state.confirm.props,
    isSmallScreen: state.ui.smallScreen,
});

const mapDispatchToProps = dispatch => ({
    close: () => dispatch(closeConfirm()),
});

export default withTranslation(['ui', 'messages'])(
    connect(mapStateToProps, mapDispatchToProps)(ConfirmModalContainer)
);
