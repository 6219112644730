import EditableDropdown from "components/common/editable-dropdown";
import EditableText from "components/common/editable-text";
import ToggleButtonGroup from "components/common/toggle-btn-group";
import { mapSimpleValueToOption } from "utils/DropdownMappers";
import { formatPriceSum } from "utils/StringUtils";

const inbrottAlarmOptions = [
    'Upp till 16 sektioner',
    'Upp till 32 sektioner',
    'Över 32 sektioner'
];

const inbrottAlarmValues = {
    'Upp till 16 sektioner': '2800',
    'Upp till 32 sektioner': '4400',
    'Över 32 sektioner': '6000'
};

const kameraOptions = [
    'Upp till 4 kameror',
    'Upp till 8 kameror',
    'Fler än 8 kameror'
];

const kameraValues = {
    'Upp till 4 kameror': '5600',
    'Upp till 8 kameror': '8200',
    'Fler än 8 kameror': '10000'
};

const yearOptions = () => {

    const years = [];
    const year = new Date().getFullYear();

    for (let i = year - 3; i < year; i++) {
        years.push(i);
    }

    for (let i = year; i < year + 10; i++) {
        years.push(i);
    }

    return years.map(mapSimpleValueToOption);
}

const monthOptions = [
    'Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti',
    'September', 'Oktober', 'November', 'December'
].map(mapSimpleValueToOption);

const ProposalDetailsCustomFCLarm = ({ proposal, editable, data, onDataChange }) => {


    if (!data || !proposal) {
        return null;
    }

    if (!data?.initialized) {
        onDataChange({
            ...data,
            initialized: true,
            row3_value: '2800',
            row4_value: '3600',
            row5_value: '1200',
            row6_value: '3200',
            row7_value: '5940',
            year: new Date().getFullYear()
        });

        return null;
    }

    const handleValueChange = (name, value) => {
        onDataChange({
            ...data,
            [name]: value
        })
    }

    const handleValuesChange = values => {
        onDataChange({
            ...data,
            ...values
        })
    }

    const enabled = data?.serviceAgreementEnabled === 'JA';

    return (
        <div style={{
            marginTop: 20,
            marginBottom: 20
        }}>
            <div className="man-flex-row"
                style={{
                    marginBottom: 10
                }}>
                <div>
                    Serviceavtal:
                </div>
                <ToggleButtonGroup
                    toggleButtons={['JA', 'NEJ'].map(mapSimpleValueToOption)}
                    toggeled={data?.serviceAgreementEnabled}
                    onClick={value =>
                        editable && handleValueChange('serviceAgreementEnabled', value)
                    }
                    size="mini"
                />
                {enabled &&
                    <div className="man-flex-row">
                        <EditableDropdown
                            isEditable={editable}
                            name="year"
                            options={yearOptions()}
                            value={data?.year}
                            onSave={(name, value) => handleValueChange('year', value)} />

                        <EditableDropdown
                            isEditable={editable}
                            name="month"
                            options={monthOptions}
                            value={data?.month}
                            text={data?.month || 'Välj..'}
                            onSave={(name, value) => handleValueChange('month', value)} />

                    </div>
                }
            </div>

            {enabled &&
                <div>

                    <table className="ui celled fixed bordered compact table">
                        <tbody>
                            <tr>
                                <td>
                                    AK (anläggningskontroll) INBROTT:
                                </td>
                                <td style={{
                                    overflow: 'visible'
                                }}>
                                    <EditableDropdown
                                        isEditable={editable}
                                        name="row1_selection"
                                        options={inbrottAlarmOptions.map(mapSimpleValueToOption)}
                                        value={data?.row1_selection}
                                        text={data?.row1_selection || 'Välj..'}
                                        onSave={(name, value) => handleValuesChange({
                                            row1_selection: value,
                                            row1_value: inbrottAlarmValues[value]
                                        })} />
                                </td>
                                <td>
                                    <EditableText
                                        isEditable={editable}
                                        name="row1_value"
                                        value={data?.row1_value}
                                        text={formatPriceSum(data?.row1_value, '')}
                                        onSave={value => handleValueChange('row1_value', value)}
                                        onValueChange={(name, value) => handleValueChange('row1_value', value)}
                                        postfix=" kr" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Kameraövervakning:
                                </td>
                                <td style={{
                                    overflow: 'visible'
                                }}>
                                    <EditableDropdown
                                        isEditable={editable}
                                        name="row1_selection"
                                        options={kameraOptions.map(mapSimpleValueToOption)}
                                        value={data?.row2_selection}
                                        text={data?.row2_selection || 'Välj..'}
                                        onSave={(name, value) => handleValuesChange({
                                            row2_selection: value,
                                            row2_value: kameraValues[value]
                                        })} />
                                </td>
                                <td>
                                    <EditableText
                                        isEditable={editable}
                                        name="row2_value"
                                        value={data?.row2_value}
                                        text={formatPriceSum(data?.row2_value, '')}
                                        onSave={value => handleValueChange('row2_value', value)}
                                        onValueChange={(name, value) => handleValueChange('row2_value', value)}
                                        postfix=" kr" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Underhåll PASSAGE/EL-LÅSNING:
                                </td>
                                <td>
                                    <ToggleButtonGroup
                                        toggleButtons={['JA', 'NEJ'].map(mapSimpleValueToOption)}
                                        toggeled={data?.row3_selection}
                                        onClick={value =>
                                            editable && handleValueChange('row3_selection', value)
                                        }
                                        size="mini"
                                    />
                                </td>
                                <td>
                                    <EditableText
                                        isEditable={editable}
                                        name="row3_value"
                                        value={data?.row3_value}
                                        text={formatPriceSum(data?.row3_value, '')}
                                        onSave={value => handleValueChange('row3_value', value)}
                                        onValueChange={(name, value) => handleValueChange('row3_value', value)}
                                        postfix=" kr" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Service ÖVERFALL:
                                </td>
                                <td>
                                    <ToggleButtonGroup
                                        toggleButtons={['JA', 'NEJ'].map(mapSimpleValueToOption)}
                                        toggeled={data?.row4_selection}
                                        onClick={value =>
                                            editable && handleValueChange('row4_selection', value)
                                        }
                                        size="mini"
                                    />
                                </td>
                                <td>
                                    <EditableText
                                        isEditable={editable}
                                        name="row4_value"
                                        value={data?.row4_value}
                                        text={formatPriceSum(data?.row4_value, '')}
                                        onSave={value => handleValueChange('row4_value', value)}
                                        onValueChange={(name, value) => handleValueChange('row4_value', value)}
                                        postfix=" kr" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Underhåll LARMÖVERFÖRING:
                                </td>
                                <td>
                                    <ToggleButtonGroup
                                        toggleButtons={['JA', 'NEJ'].map(mapSimpleValueToOption)}
                                        toggeled={data?.row5_selection}
                                        onClick={value =>
                                            editable && handleValueChange('row5_selection', value)
                                        }
                                        size="mini"
                                    />
                                </td>
                                <td>
                                    <EditableText
                                        isEditable={editable}
                                        name="row5_value"
                                        text={formatPriceSum(data?.row5_value, '')}
                                        value={data?.row5_value}
                                        onSave={value => handleValueChange('row5_value', value)}
                                        onValueChange={(name, value) => handleValueChange('row5_value', value)}
                                        postfix=" kr" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Service BRANDINDIKERING:
                                </td>
                                <td>
                                    <ToggleButtonGroup
                                        toggleButtons={['JA', 'NEJ'].map(mapSimpleValueToOption)}
                                        toggeled={data?.row6_selection}
                                        onClick={value =>
                                            editable && handleValueChange('row6_selection', value)
                                        }
                                        size="mini"
                                    />
                                </td>
                                <td>
                                    <EditableText
                                        isEditable={editable}
                                        name="row6_value"
                                        text={formatPriceSum(data?.row6_value, '')}
                                        value={data?.row6_value}
                                        onSave={value => handleValueChange('row6_value', value)}
                                        onValueChange={(name, value) => handleValueChange('row6_value', value)}
                                        postfix=" kr" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    FCCloud:
                                </td>
                                <td>
                                    <ToggleButtonGroup
                                        toggleButtons={['JA', 'NEJ'].map(mapSimpleValueToOption)}
                                        toggeled={data?.row7_selection}
                                        onClick={value =>
                                            editable && handleValueChange('row7_selection', value)
                                        }
                                        size="mini"
                                    />
                                </td>
                                <td>
                                    <EditableText
                                        isEditable={editable}
                                        name="row7_value"
                                        value={data?.row7_value}
                                        text={formatPriceSum(data?.row7_value, '')}
                                        onSave={value => handleValueChange('row7_value', value)}
                                        onValueChange={(name, value) => handleValueChange('row7_value', value)}
                                        postfix=" kr" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Fjärruppkoppling:
                                </td>
                                <td>
                                    <ToggleButtonGroup
                                        toggleButtons={['JA', 'NEJ'].map(mapSimpleValueToOption)}
                                        toggeled={data?.row8_selection}
                                        onClick={value =>
                                            editable && handleValueChange('row8_selection', value)
                                        }
                                        size="mini"
                                    />
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            }

        </div>
    )

}

export default ProposalDetailsCustomFCLarm;