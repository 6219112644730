import {
    FETCH_BUSINESS_OBJECTS,
    FETCH_BUSINESS_OBJECTS_FULFILLED,
    FETCH_BUSINESS_OBJECTS_REJECTED,
    BUSINESS_OBJECT_CHANGED,
    BUSINESS_OBJECT_TABLE_STATE_CHANGE,
    BUSINESS_OBJECT_FILTER_CHANGE
} from 'data/actions/business-objects';
import { STATUS_ACTIVE } from '../../../utils/constants';

const defaultState = {
    isFetching: false,
    bos: [],
    tableState: undefined,
    searchFilters: {
        customer: null,
        city: null,
        model: null,
        status: STATUS_ACTIVE
    }
}

export const bos = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case BUSINESS_OBJECT_TABLE_STATE_CHANGE: return ({ ...state, tableState: action.state })
        case BUSINESS_OBJECT_FILTER_CHANGE: {
            const filters = { ...state.searchFilters };

            filters[action.filterName] = action.value;

            return {
                ...state,
                searchFilters: filters
            }
        }
        case FETCH_BUSINESS_OBJECTS: {
            return {
                ...state,
                isFetching: true
            }
        }
        case FETCH_BUSINESS_OBJECTS_FULFILLED: {
            return {
                ...state,
                isFetching: false,
                bos: action.bos
            }
        }
        case FETCH_BUSINESS_OBJECTS_REJECTED: {
            return {
                ...state,
                isFetching: false
            }
        }
        case BUSINESS_OBJECT_CHANGED: {
            const bos = [...state.bos];
            const idx = bos.findIndex(b => b.id === action.bo.id);

            if (idx < 0) {
                bos.push(action.bo);
            } else {
                bos[idx] = action.bo;
            }

            return {
                ...state,
                bos
            }
        }
        default: {
            return state;
        }
    }
};