import React from 'react';
import PropTypes from 'prop-types';
import Tag from '../tag';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import LocalizedSearch from '../localized-search';
import './TagPicker.css';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import FlatButton from '../flat-button';
import { Checkbox } from 'semantic-ui-react';
import BottomSheet from '../bottom-sheet/BottomSheet';

const SearchOption = ({ color, name }) => {
    return (
        <Tag name={name} color={color} />
    )
}

const Result = ({ color, name, onRemove }) => {
    return (
        <Tag name={name}
            color={color}
            onClick={onRemove}
            icon={onRemove ? 'remove' : null} />
    )
}

class TagPicker extends React.Component {

    state = {
        isLoading: false,
        searchValue: '',
        searchResults: [],
        bottomSheetOpen: false
    }

    handleFocus = () => {
        this.updateResults(this.state.searchValue);
    }

    handleChange = async (e, { value }) => {
        this.updateResults(value);
    }

    updateResults = (value, selected) => {
        const notSelected = tag => {
            if (selected) {
                return [...this.props.value, selected].findIndex(name => tag.name === name) === -1
            }

            return this.props.value.findIndex(name => tag.name === name) === -1;
        };
        const re = new RegExp(_.escapeRegExp(value), 'i');
        const isMatch = tag => re.test(tag.name);

        this.setState({
            isLoading: false,
            searchValue: value,
            searchResults: this.props.tags
                .filter(notSelected)
                .filter(isMatch)
                .map(tag => ({
                    ...tag,
                    title: tag.name
                })),
        });
    }

    handleSelect = (e, { result }) => {
        this.props.onTagsChange([...this.props.value, result.name]);

        this.updateResults('', result.name);
    }

    handleAdd = name => {
        this.props.onTagsChange([...this.props.value, name])
    }

    handleRemove = name => {
        this.props.onTagsChange(this.props.value.filter(tag => tag !== name));
    }

    renderSearchResult = tag => (
        <SearchOption
            key={tag.name}
            {...tag} />
    )

    renderTag = ({ name, color }) => {
        const { isEditable, isSmallScreen } = this.props;

        return (
            <span className="man-tagpicker-result" key={name}
                onClick={this.handleContainerClick}>
                <Result
                    color={color}
                    name={name}
                    onRemove={(isEditable && !isSmallScreen) ? () => this.handleRemove(name) : null} />
            </span>
        )
    }

    renderTagSmall = ({ name, color }) => {

        const isSelected = this.props.value.findIndex(n => name === n) !== -1;

        const onClick = () => isSelected ? this.handleRemove(name) : this.handleAdd(name)

        return (
            <div className="man-tagpicker-small-result" key={name}>
                <Checkbox checked={isSelected}
                    onChange={onClick} />

                <Tag name={name}
                    color={color}
                    onClick={onClick}
                    className={isSelected ? 'man-tag-selected' : ''} />
            </div>
        )
    }

    getName = (value, idx) => {
        if (Array.isArray(value)) {
            return value[idx];
        }

        return value;
    }

    handleContainerClick = () => {
        const { isSmallScreen } = this.props;

        if (!isSmallScreen) {
            return;
        }

        this.setState({
            bottomSheetOpen: true
        });
    }

    handleBottomSheetClose = () => {
        this.setState({
            bottomSheetOpen: false
        });
    }

    render() {

        const { t, value, tags, isEditable, isSmallScreen } = this.props;
        const { bottomSheetOpen } = this.state;

        return (
            <div className={`man-tagpicker${isSmallScreen ? ' man-screen-small' : ''}`}>

                <div style={{ minHeight: '32px' }}>
                    {value
                        .map((name, idx) => {
                            const tag = tags.find(t => t.name === name);

                            return tag || { name: this.getName(value, idx) };
                        })
                        .map(this.renderTag)}

                    {isEditable && !isSmallScreen &&
                        <LocalizedSearch
                            selectFirstResult={true}
                            onFocus={this.handleFocus}
                            loading={this.state.isLoading}
                            placeholder={t('TAGPICKER_ADD_TAG')}
                            minCharacters={0}
                            results={this.state.searchResults}
                            onResultSelect={this.handleSelect}
                            onSearchChange={this.handleChange}
                            value={this.state.searchValue}
                            resultRenderer={this.renderSearchResult} />
                    }

                    {isSmallScreen && (!value || value.length === 0) &&

                        <FlatButton icon="plus"
                            onClick={this.handleContainerClick}
                            style={{ padding: 4 }}>
                            {t('ui:TAGPICKER_ADD_TAG')}
                        </FlatButton>
                    }

                    {isSmallScreen &&
                        <BottomSheet
                            open={bottomSheetOpen}
                            onChange={this.handleBottomSheetClose}
                        >
                            <div className="man-tagpicker-bottomsheet-results-container">
                                {this.props.tags
                                    .map(this.renderTagSmall)}
                            </div>
                        </BottomSheet>
                    }

                </div>
            </div>
        )
    }
}

TagPicker.propTypes = {
    tags: PropTypes.array.isRequired,
    onTagsChange: PropTypes.func.isRequired,
    value: PropTypes.array,
    isEditable: PropTypes.bool,
    isSmallScreen: PropTypes.bool
}

TagPicker.defaultProps = {
    value: [],
    isEditable: true,
    isSmallScreen: false
}

export default withTranslation('ui')(TagPicker);