import CacheManager from './cache';
import CacheManagerProps from './cache/CacheManagerProps';

export const UI_PROP_LANG = 'lang';

const getUserUiSettings = async () => {
    const settings = await CacheManager.getGlobal(CacheManagerProps.CACHE_UI_PROP);
    
    if (!settings) return {};

    return JSON.parse(settings);
}

export const getUserUiSetting = async (key, def) => {
    const value = await getUserUiSettings();

    if (value[key] == null) {
        return def;
    };

    return value[key];
}

export const setUserUiSetting = async (key, value) => {
    let settings = await getUserUiSettings();
    settings[key] = value;

    await CacheManager.putGlobal(CacheManagerProps.CACHE_UI_PROP, JSON.stringify(settings));
}