export const se = {
    ui: {
        BTN_NEW_WORKORDER: 'Ny arbetsorder',
        DEPARTMENTS: 'Affärsområden',
        DEPARTMENT: 'Affärsområde',
        PRIORITY: 'Prioritet',
        REPORTS: 'Statistik',
        REPORTS_HIGH_PRIORITY: 'Hög prioritet',
        REPORTS_CREATED_BY_ME: 'Skapad av mig',
        REPORTS_ASSIGNED_TO_ME: 'Tilldelad till mig',
        REPORTS_RECENTLY_COMPLETED: 'Senast slutaförda',
        REPORTS_RECENTLY_DELETED: 'Senast raderade',
        STATISTICS: 'Statistik',
        CUSTOM_REPORTS: 'Vår statistik',
        RECYCLE_BIN: 'Papperskorgen',
        RECENTLY_CLOSED: 'Senast slutförda',
        QUICK_ACCESS: 'Smarta länkar',
        FILTER_WORKORDER_PLACEHOLDER: 'Sök arbetsorder..',
        PROFILE: 'Mina uppgifter',
        EDIT_PROFILE: 'Mina uppgifter',
        LOG_OUT: 'Logga ut',
        SETTINGS: 'Inställningar',
        INVENTORY: 'Register',
        MAP: 'Karta',
        DASHBOARD: 'Våra KPI:er',
        STATISTICS_DASHBOARD: 'Statistik',
        CALENDAR: 'Kalender',
        CALENDAR_PERSPECTIVE: 'Kalender',
        WORK_ORDERS: 'Arbetsorder',
        CLOSED_WORK_ORDERS: 'Slutförda arbetsordrar',
        OPEN_WORK_ORDERS: 'Öppna arbetsordrar',
        EXTRA_WORK_WORK_ORDERS: 'ÄTA arbetsordrar',
        TIME_REPORTING: 'Övrig tid',
        TIME_REPORTING_STATISTIC_HEADER: 'Tidrapport',
        AGREEMENT_INVOICES: 'Avtalsfakturor',
        LIST: 'Lista',
        SCHEDULED: 'Kalender',
        AGREEMENT_INVOICE_SCHEDULED_AT: 'Fakturan skapas',
        NO_WORKS_PLANNED: 'Inget planerat',
        SETTINGS_GENERAL: 'Företagsuppgifter',
        SETTINGS_USERS: 'Användare',
        SETTINGS_ACLS: 'Behörigheter',
        SETTINGS_WORKORDER: 'Arbetsorder',
        SETTINGS_BUSINESS_OBJECT: 'Objekt',
        SETTINGS_PROPOSAL: 'Offert',
        SETTINGS_SALES_ORDER: 'Försäljningsorder',
        SETTINGS_ARTICLES: 'Artiklar',
        SETTINGS_ARTICLES_PRICE_LISTS: 'Prislistor',
        SETTINGS_ARTICLES_GROUPS: 'Artikelgrupper',
        SETTINGS_ARTICLES_SUPPLIER_ARTICLES: 'Leverantörers artiklar',
        SETTINGS_ARTICLES_SBSC_PRODUCTS: 'SBSC Artiklar',
        SETTINGS_DEVIATIONS: 'Avvikelser',
        SETTINGS_PROJECT_RESOURCE_PLANNING: 'Resursplanering i projekt',
        SETTINGS_IMPORT_EXPORT: 'Import & Export',
        SETTINGS_SUPPLIERS: 'Leverantörer',
        USERPICKER_ADD_PEOPLE: 'Välj användare',
        TAGPICKER_ADD_TAG: 'Välj tagg',
        BOPICKER_ADD: 'Ny',
        CUSTOMERPICKER_SELECT: 'Välj kund',
        MODELPICKER_SELECT: 'Välj typ',
        BOPICKER_SELECT: 'Välj..',
        WORKORDERPICKER_SELECT: 'Sök arbetsorder',
        SUPPLIERPICKER_SELECT: 'Sök leverantör',
        ARTICLEPICKER_SELECT: 'Sök artikel',
        PROJECTPICKER_SELECT: 'Sök projekt',
        LOCATIONPICKER_SEARCH: 'Search for location',
        CUSTOMERS: 'Kunder',
        MODELS: 'Modeller',
        SUPPLIERS: 'Leverantörer',
        AGREEMENTS: 'Avtal',
        CUSTOMER: 'Kund',
        CUSTOMER_PRICE_LIST: 'Kundens prislista',
        PRICE_LIST: 'Prislista',
        GENERAL: 'Allmänt',
        AGREEMENT: 'Avtal',
        HISTORY: 'Historik',
        SPECIFICATION: 'Specifikation',
        OBJECTS: 'Objekt',
        ARTICLES: 'Artiklar',
        CUSTOMER_EDIT_DIALOG_TITLE: 'Ändra kunduppgifter',
        NEW_CUSTOMER_DIALOG_TITLE: 'Skapa kund',
        USER_INVITE_DIALOG_TITLE: 'Skapa användare',
        NEW_BUSINESS_OBJECT_MODAL_TITLE: 'Ny enhet',
        ARTICLE_EDIT_DIALOG_TITLE: 'Ändra artiklar',
        ACL_EDIT_DIALOG_TITLE: 'Ändra behörigheter',
        DEPARTMENT_EDIT_TITLE: 'Ändra affärsområde',
        USER_EDIT_DIALOG_TITLE: 'Ändra användare',
        MODEL_EDIT_DIALOG_TITLE: 'Ändra typ',
        NEW_AGREEMENT_MODAL_TITLE: 'Ny avtalstyp',
        STATUS_ACTIVE: 'Aktiv',
        STATUS_INACTIVE: 'Inaktiv',
        STATUS_REGISTERED: 'Inbjuden användare',
        SEARCH: 'Sök',
        FIND_ANYTHING: 'Sök..',
        RELOAD: 'Uppdatera',
        BACK: 'Avbryt',
        SORT: 'Sortera',
        EDIT: 'Ändra',
        EDIT_INTERNAL_INFO: 'Ändra intern info',
        ADD: 'Ny',
        SAVE: 'Spara',
        SAVE_AND_CLOSE: 'Spara & stäng',
        RESEND_REGISTRATION_INVITATION: 'Bjud in igen',
        MORE_ACTIONS: 'Mer..',
        PLEASE_CONFIRM_ACTION: 'Vänligen bekräfta',
        ACTION_CONFIRM: 'Bekräftar',
        ACTION_CANCEL: 'Avbryt',
        USER: 'Användare',
        INVITE_USER: 'Bjud in användare',
        REGISTER_NEW_USER: 'Skapa ny användare',
        REMOVE: 'Radera',
        REMOVE_ROW: 'Radera',
        DOWNLOAD: 'Ladda ner',
        DOWNLOAD_ALL: 'Ladda ner alla',
        SELECT: 'Välj',
        FILTER: 'Filtrering',
        RESET_FILTERS: 'Rensa filter', 
        FILTER_BY_DEPARTMENT: 'Affärsområde',
        FILTER_BY_USER: 'Person',
        FILTER_BY_CUSTOMER: 'Kund',
        FILTER_BY_PRIORITY: 'Prioritet',
        FILTER_BY_ASSIGNEE: 'Användare',
        SELECT_ASSIGNEE: 'Välj ansvarig',
        INCLUDE_SCHEDULED_WORK_ORDERS: 'Visa schemalagda',
        PIECE_SHORT: 'St',
        PIECES_SHORT: 'St',
        UNASSIGNED: 'Ingen ansvarig',
        PLAN_WORK_ORDER: 'Planera arbetsordern', 
        ON_HOLD: 'Pausad',
        DATE: 'Datum',
        VIEW: 'Visa',
        STATUS: 'Status',
        VIEW_ALL: 'Visa alla',
        USERS_VIEW: 'Användare',
        LIST_VIEW: 'Lista',
        CALENDAR_VIEW: 'Kalender',
        ADD_NOTE: 'Anteckning',
        ADD_NEW_ROW: 'Lägg till',
        NO_RESULTS_FOUND: 'Ingen träff..',
        SEARCHING: 'Söker..',
        DELETE: 'Radera',
        CLOSE: 'Stäng',
        CREATE_NEW_TAG: 'Skapa ny tagg',
        VIEW_HISTORY: 'Visa historik',
        SEND_EMAIL: 'Skicka e-post',
        SEND_EMAIL_TO_ME: 'E-Post till mig', 
        PRINT: 'Skriv ut',
        CLOSE_WORKORDER: 'Slutför arbetsorder',
        CREATE_WORKORDER: 'Skapa ny arbetsorder',
        ENABLED: 'Aktiverad',
        DISABLED: 'Inaktiv',
        TOTAL: 'Totalt',
        N_MORE: '{{ count }} fler..',
        SUBTOTAL: 'Totalt exkl. moms',
        START: 'Start',
        END: 'Slut',
        WORKORDERS_NOT_FOUND: 'Hittade inga arbetsorder..',
        LOAD_MORE: 'Ladda fler',
        PROCESSING: 'Bearbetar..',
        INTAKE_DATE: 'Skapad',
        UPDATE_DATE: 'Ändrad',
        NEWER_FIRST: 'senaste',
        OLDER_FIRST: 'äldsta',
        SCHEDULED_TO_DESC: 'Kalenderordning',
        SCHEDULED_TO_ASC: 'Omvänd kalender',
        UPDATED: 'Ändrad',
        CREATED: 'Skapad',
        CREATED_AT: 'Skapad {{datetime}}',
        CREATED_AT_BY_USER: 'Skapad {{datetime}} av {{user}}',
        CREATED_BY: 'Skapad av',
        CLOSED: 'Slutförd',
        MANAGE_OBJECTS: 'Hantera enheter',
        MANAGE_CUSTOMERS: 'Hantera kunder',
        MANAGE_MODELS: 'Hantera modeller',
        MANAGE_ARTICLES: 'Hantera artiklar',
        MANAGE_AGREEMENTS: 'Hantera avtalstyper',
        MANAGE_SUPPLIERS: 'Hantera leverantörer',
        CREATE_NEW_WORKTYPE: 'Skapa ny arbetsordertyp',
        CREATE_NEW_STATUS: 'Skapa ny status',
        DEFAULT_LANGUAGE: 'Förvalt språk',
        DEFAULT_TYPE: 'Förvald ordertyp',
        DEFAULT_TAG: 'Förvald tagg',
        DEFAULT_STATUS: 'Förvald status',
        CLOSING: 'Status slutförd',
        SELECT_DEFAULT: 'Välj startvärde',
        SELECT_CLOSING: 'Välj slutförande',
        SHOW_TODAY: 'Idag',
        SELECT_DATE: 'Välj datum',
        CLOSED_AT: 'Slutförd den',
        RECIPIENT: 'Kund',
        INVOICE: 'Faktura',
        INVOICES: 'Kommande avtalsfakturor',
        INVOICE_NR: 'Faktura',
        YES: 'Ja',
        NO: 'Nej',
        ACTIVATED: 'Aktiv',
        INACTIVATED: 'Inaktiv',
        VAT: 'Moms',
        PDF: 'PDF',
        LIMIT_NONE_BTN: 'Alla',
        LIMIT_USER_BTN: 'Delegerat till användaren',
        LIMIT_CREATED_BY_USER_BTN: 'Skapat av användaren',
        DISCOUNT_PLACEHOLDER: 'Rabatt i % eller ',
        TIME_REPORTING_PERIODS: 'Tidsintervaller arbetstid',
        TIME_REPORTING_PERIODS_WEEKEND: 'Tidsintervall för helger',
        TIME_REPORTS_ON_WORK_ORDERS: 'Tid på arbetsorder',
        TIME_REPORTS_ON_WORK_ORDERS_WEEKEND: 'Från arbetsorder - helgarbete',
        TIME_REPORTS_OTHER: 'Övrigt',
        TIME_REPORTS_OTHER_ATTENDING: 'Övrig tid - närvaro',
        TIME_REPORTS_OTHER_ABSENCE: 'Övrig tid - frånvaro',
        TIME_REPORTS_DISTANCE: 'KM',
        TIME_REPORT_PERIOD_DESCRIPTION: 'Beskrivning',
        TIME_REPORTING_LOCKING: 'Tidrapportering låses',
        TIME_REPORTS_GET_LOCKED_ON_DAY_OF_NEXT_MONTH:
            'Föregående månads tidregistreringar låses {{day}} dagar in på nästa månad.',
        TIME_HOURS: '{{count}}h',
        TIME_MINUTES: '{{count}}min',
        LOCK: 'Lås',
        UNLOCK: 'Lås upp',
        LOCKED: 'Låst',
        TIMEREPORT_FOR_USER: 'Tidrapport för användare {{name}}',
        WORK_ORDER: 'Arbetsorder',
        AGREEMENT_INVOICE: 'Avtalsfaktura',
        EXPORT_TO_EXCEL: 'Export till Excel',
        UPLOADING_FILE: 'Laddar upp.. {{fileName}}',
        NO_PREVIEW_AVAILABLE: 'Ingen förhandsvisning',
        SAVING: 'Sparar..',
        ALL_CHANGES_SAVED: 'Ändringar sparade!',
        ARTICLE_STOCK: 'Lager',
        ARTICLES_IN_STOCK: 'Antal i lager',
        NEW_SUPPLIER_MODAL_TITLE: 'Ny leverantör',
        NEW_DOORAUTOMATIC_MODAL_TITLE: 'Ny dörrautomatik',
        SHOW_ON_MAP: 'Visa på karta',
        OPEN_DIRECTIONS_IN_GOOGLE_MAPS: 'Öppna med Google karta',
        COUNT_OF_MORE_EVENTS: '{{count}} mer..',
        INTERFACE: 'Användarvyer',
        INTERFACE_WORKORDER_LIST_TYPE: 'Arbetsorderlistans utseende',
        INTERFACE_WORKORDER_LIST_TYPE_SINGLE: 'Karta och arbetsorder separat',
        INTERFACE_WORKORDER_LIST_TYPE_SPLIT:
            'Karta och arbetsorder tillsammans',
        MARGIN_PERCENTAGE: '{{margin}}% marginal',
        MARGIN_PERCENTAGE_SHORT: 'TG: {{margin}}%',
        MANAGE_STOCK: 'Hantera lager',
        DELIVER_IN_UNITS: 'Inleverera antal',
        ADD_TO_STOCK: 'Inleverera',
        ARTICLE_ACTIVATE_IN_DELIVERY: 'Aktivera inleveranser',
        ARTICLE_SHOW_SPSC: 'Visa certifierad datum',
        ARTICLE_STOCK_HISTORY_LOGS: 'Lagerhändelser',
        STOCK_RESERVED: 'Reserverat på arbetsorder',
        STOCK_INVOICED: 'Senast fakturerat',
        STOCK_TRANSACTIONS: 'Inleveranser',
        CONFIGURE: 'Konfigurera',
        PREVIEW: 'Förhandsvisning',
        PROJECT: 'Projekt',
        PROJECTS: 'Projekt',
        ADD_NEW_PROJECT: 'Nytt projekt',
        PROJECT_TASK: 'Uppgift',
        SALES_ORDERS: 'Order',
        PROPOSALS: 'Offerter',
        SELECT_ARTICLE: 'Välj artikel',
        SELECT_ARTICLE_GROUPS: 'Välj artikelgrupp(er)',
        SELECT_ARTICLE_MORE_OPTIONS_BACK: 'Tillbaka..',
        SELECT_ARTICLE_MORE_OPTIONS: 'Mer..',
        SELECT_ARTICLE_FILTERING_IS_ACTIVE: 'Urval begränsas av grupper!',
        ADD_COMMENT: 'Lägg till fritext',
        ADD_COMMENT_ROW: 'Lägg till fritext-rad',
        ADD_ARTICLE_ROW: 'Lägg till artikelrad',
        ADD_SUMMARY_ROW: 'Lägg till del-summering',
        FILTER_SALES_ORDERS: 'Filtrera ordrar',
        FILTER_PROPOSALS: 'Filter offerter',
        DELETE_PROJECT: 'Radera projekt',
        CLOSE_PROJECT: 'Stäng projekt',
        REOPEN_PROJECT: 'Öppna projektet',
        PROPOSAL_STATUS_ACTIVE: 'Aktiv',
        PROPOSAL_STATUS_ACCEPTED: 'Accepterad',
        PROPOSAL_STATUS_DECLINED: 'Avböjd',
        PROPOSAL_ACCEPT: 'Acceptera och skapa order',
        PROPOSAL_ACCEPT_AND_CREATE_WORKORDER: 'Accepterad - skapa arbetsorder',
        PROPOSAL_DECLINE: 'Avböjd och dölj',
        PROPOSAL_IS_ACCEPTED: 'Accepterad',
        PROPOSAL_IS_ACCEPTED_AT_BY_USER: 'Accepterad {{datetime}} av {{user}}',
        PROPOSAL_IS_DECLINED: 'Avböjd',
        PROPOSAL_IS_DECLINED_AT_BY_USER: 'Avböjd {{datetime}} av {{user}}',
        PROPOSAL_CREATE_NEW: 'Skapa offert',
        FILTER_DEVIATIONS: 'Filter för Avvikelser',
        DEVIATION_CREATE_NEW: 'Ny Avvikelse',
        PROPOSAL_CLEAR_CUSTOMER: 'Byt kund på offerten',
        PROPOSAL_MAKE_COPY: 'Kopiera denna offert',
        WORK_ORDER_MAKE_COPY: 'Kopiera denna arbetsorder',
        MAKING_COPY_PLEASE_WAIT: 'Kopierar offerten, vänligen vänta!',
        SET_AS_PRIMARY_ADDRESS: 'Gör till primär faktura-adress',
        ADD_NEW_POTENTIAL_CUSTOMER: 'Ny potentiell kund',
        SHOW_REGULAR_CUSTOMERS: 'Kunder',
        SHOW_POTENTIAL_CUSTOMERS: 'Potentiella kunder',
        ADD_CUSTOMER_NR: 'Infoga kundnummer',
        NEW_CUSTOMER_NUMBER_DIALOG_TITLE: 'Infoga kundnummer',
        WORD_EXPORT: 'Word',
        MODIFY_ARTICLE_DESCRIPTION: 'Ändra beskrivning',
        CREATE_INVOICE: 'Skapa faktura',
        VIEW_INVOICE: 'Visa faktura: {{invoiceNr}}',
        UPDATE_ADDRESS_FROM_CUSTOMER_ADDRESS: 'Hämta kundens adress',
        ADD_SIGNATURE: 'Signera ordern',
        CLEAR_SIGNATURE: 'Rensa',
        SERVICE_REPORT_SAVE_AND_GENERATE_DOCUMENT: 'Spara och skapa rapporten',
        SERVICE_REPORT_CLOSED_AT_BY_USER: 'Stängdes {{datetime}} av ({{user}})',
        ABSENCE_INFO: 'Frånvaro info',
        MY_TIME_REPORTS: 'Mina tidrapporter',
        TIME_REPORTED_FOR_DAY: '{{time}} rapporterat',
        ALL_ATTEND_TIME_REPORTS: 'Alla tidrapporter',
        ALL_ATTEND_TIME_REPORTED_FOR_DAY:
            '{{reported}}/{{expected}} timmar rapporterat',
        INVOICE_MARKING_OPTION_CONTACT_COST_CENTER:
            'Kontaktens kostnadställe - {{value}}',
        INVOICE_MARKING_OPTION_CUSTOMER: 'Kundens märkning - {{value}}',
        INVOICE_MARKING_OPTION_OBJECT: 'Objektets märkning - {{value}}',
        DEFAULT: 'Standardvärde',
        INTERNAL_MESSAGES: 'Interna meddelanden',
        MY_WORKORDERS: 'Mina aktuella arbetsordrar',
        START_TIMER: 'START',
        STOP_TIMER: 'STOP',
        TIME_REPORTS_WAITING: 'Väntar..',
        TIME_REPORTS_DISTANCE_KM: 'KM',
        TIME_REPORTS_REST_TIME: 'Lunch/Rast',
        TIME_REPORTS_TRAVEL_TIME: 'Varav restid',
        TIME_REPORTS_REPORTED_DISTANCE_KM: '{{total}} KM',
        TIME_REPORTS_REPORTED_TRAVEL_TIME: '{{time}} restid',
        CUSTOMER_OBJECT_CONTACT_COUNT:
            'Det finns {{count}} kontakt på någon av kundens objekt',
        CUSTOMER_OBJECT_CONTACT_COUNT_plural:
            'Det finns {{count}} kontakter på någon av kundens objekt',
        PERCENTAGE_OF_ESTIMATE: '{{percentage}} av uppskattat',
        PROJECT_TIME_REPORTED_ON_WORK_ORDERS: '{{time}} rapporterade timmar',
        PROJECT_TIME_REPORTED_ON_OPEN_WORK_ORDERS:
            '{{time}} på öppna arbetsordrar',
        PROJECT_TIME_REPORTED_ON_CLOSED_WORK_ORDERS:
            '{{time}} på stängda arbetsordrar',
        PROJECT_COST_PRICE_ON_WORK_ORDERS: '{{value}} i kostnader',
        PROJECT_COST_PRICE_ON_OPEN_WORK_ORDERS:
            '{{value}} på öppna arbetsordrar',
        PROJECT_COST_PRICE_ON_CLOSED_WORK_ORDERS:
            '{{value}} på stängda arbetsordrar',
        PROJECT_ONGOING_COST: 'Total upparbetad kostnad',
        TIME_REPORTING_CONFIRM_FINISHED_WITH_WORKORDER:
            'Jag är helt klar med mitt på denna arbetsorder',
        TIME_REPORTING_USERS_FINISHED: '{{initials}} Klar',
        TIME_REPORTING_USER_FINISHED: 'Klar!',
        SCHEDULE: 'Planera i tid',
        SCHEDULING_ADD_TIME: 'Välj Klockslag',
        SCHEDULING_ADD_HOURS: 'i timmar',
        SCHEDULED_TO: 'Planerad till',
        SCHEDULED_ON_N_DAYS: 'Planerad till 1 dag',
        SCHEDULED_ON_N_DAYS_plural: 'Planerad på {{count}} dagar',
        PROJECT_WORK_ORDER_MARK_INCLUDED: 'Ingår i projektbudgeten',
        PROJECT_WORK_ORDER_MARK_INVOICED: 'Tillägg till projektbudgeten',
        PROJECT_INVOICING_BACKLOG: 'Faktura-underlag',
        PROJECT_NEW_INVOICE: 'Skapa faktura',
        PROJECT_INVOICE_NR: 'Projektfaktura #{{number}}',
        PROJECT_WORK_ORDER_ARTICLE_MARK_INVOICED: 'Lägg till på faktura',
        PROJECT_WORK_ORDER_ARTICLE_MARK_INCLUDED: 'Ingår i projektbudgeten',
        PROJECT_DELETE_INVOICE: 'Radera projektfaktura',
        PROJECT_FINALIZE_INVOICE: 'Skicka fakturan',
        PROJECT_BACKLOG_SHOW_ALL: 'Visa alla',
        PROJECT_BACKLOG_SHOW_OPEN: 'Visa öppna',
        PROJECT_ARTICLE_INVOICED: 'Fakturerade',
        PROJECT_ARTICLE_INCLUDED: 'Inkluderade',
        WORKORDER_INCLUDE_IN_PROJECT: 'Inkludera i projekt',
        WORKORDER_CREATE_DEVIATION: 'Skapa avvikelse',
        PROJECT_TYPE_CLOSED: 'Stängda',
        PROJECT_TYPE_ONGOING: 'Öppna/pågående',
        PROJECT_ONGOING_COST_REPORT_NEW: '> Rapportera',
        PROJECT_ONGOING_COST_REPORT: 'Rapportera',
        PROJECT_ONGOING_COST_REPORT_LAST_SUBMITTED: '{{price}} ({{date}})',
        WORK_ORDER_ARTICLE_FROM_SUPPLIER_SHORT: 'fr.',
        SERVICE_REPORT_WILL_BE_EMAILED_TO: 'Rapporten kommer skickas till',
        SERVICE_REPORT_WAS_EMAILED_TO: 'Rapporten har skickats till',
        COLLAPSE_CUSTOMER_ADDRESSES_LIST: 'Göm alla..',
        EXTEND_CUSTOMER_ADDRESSES_LIST: 'Visa alla..',
        EDIT_ARTICLE_GROUP: 'Ändra artikelgrupp',
        EDIT_PRICE_LIST: 'Ändra prislista',
        PRICING_TYPE: 'Typ av prissättning',
        PRICING_TYPE_ENABLED_ON_ARTICLE_GROUPS: 'Aktiva prissättningar',
        PRICING_TYPE_DISCOUNT: 'Rabatt',
        PRICING_TYPE_ADDITION_ON_PURCHASE_PRICE: 'Påslag i % på inköpspris',
        PRICING_TYPE_ADDITION_ON_SALES_PRICE: 'Påslag i % på försäljningspris',
        NEW_PRICE_LIST_PRICING_TYPE: 'Ny prissättningstyp',
        OPEN_BUSINESS_OBJECT_SERVICE_LOG: 'Dagbok',
        CUSTOMER_ZONE_AUTH_CODE_SHOW: 'Visa',
        CUSTOMER_ZONE_AUTH_CODE_HIDE: 'Dölj',
        RENAME: 'Ändra dokumentnamn',
        PUBLISH_FILE: 'Publisera i Kund-Zon',
        UNPUBLISH_FILE: 'Ta bort från Kund-Zon',
        SALES_ORDER_VIEW_AND_CREATE_PDF_RECEIPT: 'Utskrift av kontantkvitto',
        SALES_ORDER_VIEW_PDF_RECEIPT: 'Visa kontantkvitto',
        INCLUDING_VAT: 'inkl. moms',
        EXCLUDING_VAT: 'exkl. moms',
        INTERFACE_START_PAGE: 'Startsida',
        INTERFACE_START_PAGE_WORKORDERS: 'Arbetsordrar',
        INTERFACE_START_PAGE_DASHBOARD: 'Överblickspanel',
        WORK_ORDER_INVOICE_INCLUDED_IN_PROJECT: 'Inkluderat i projekt',
        UPDATE_SUPPLIER_ARTICLES: 'Importera artikelfil',
        SELECT_DEPARTMENT: 'Välj affärsområde',
        AGREEMENT_INVOICING_KPI_HEADER: 'Avtalsintäkter',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_HEADER:
            'Underlag baserat på öppna arbetsordrar och kostnadsställe',
        OPEN_PROJECTS_KPI_HEADER: 'Sammanställning av öppna projekt',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_TO_INVOICE_ON_PURCHASE_PRICE:
            'Material',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_TO_INVOICE_ON_TIME: 'Arbetstid',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_DEBIT_FACTOR_ON_PURCHASE_PRICE:
            'Marginalpåslag på inköpspris',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_HOUR_COST: 'Pris / timme',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_DISTANCE_COST: 'Pris / KM',
        DEPARTMENT_NONE: 'Saknar affärsområde',
        WORK_ORDER_INVOICE_MARKING_PLACEHOLDER: 'Fakturamärke',
        CALENDAR_SHOW_ONLY_MY_CALENDAR: 'Visa bara min kalender',
        UPLOADING: 'Laddar in...',
        ON: 'På',
        OFF: 'Av',
        CLOSE_ALL_TABS: 'Stäng ner alla arbetsordrar',
        CUSTOMER_TYPE: 'Kundtyp',
        CUSTOMER_TYPE_ALL: 'Alla',
        CUSTOMER_PRIVATE: 'Privatperson',
        CUSTOMER_COMPANY: 'Företagskund',
        CUSTOMER_PRIVATE_PERSON_ID_PLACEHOLDER: 'Personnummer',
        CUSTOMER_PRIVATE_PERSON_NAME_PLACEHOLDER: 'Namn',
        TAX_REDUCTION_MARK_NONE: 'Ingen ROT/RUT',
        TAX_REDUCTION_MARK_ROT: 'ROT-avdrag',
        TAX_REDUCTION_MARK_RUT: 'RUT-avdrag',
        TAX_REDUCTION_MARK_GREEN: 'Grönt-avdrag',
        WORKORDER_TIMEREPORTS_N_ATTACHED:
            '{{selected}} av {{total}} bif. på faktura',
        WORKORDER_TIMEREPORTS_ATTACH:
            'Bifoga tidsrapporter till faktura-underlag',
        WORKORDER_TIMEREPORTS_ATTACH_ALL: 'Bifoga alla',
        WORKORDER_TIMEREPORTS_ATTACHED: 'Bifogad',
        WORKORDER_TIMEREPORTS_NOT_ATTACHED: 'Inte bifogad',
        WORK_ORDER_SCHEDULING_STATUS: 'Schemalagd status',
        WORK_ORDER_SCHEDULE_STATUS_BOTH: 'Alla',
        WORK_ORDER_SCHEDULE_STATUS_NOT_SCHEDULED: 'Inga schemalagda',
        WORK_ORDER_SCHEDULE_STATUS_SCHEDULED: 'Bara schemalagda',
        ADD_WORK_ORDER_INTERNAL_INFO: 'Infoga intern kommentar',
        DEVIATIONS: 'Avvikelser',
        DEVIATION_VIEW_LOG: 'Visa händelselogg',
        DEVIATION_CHANGE_STATE_STARTED: 'Påbörjad',
        DEVIATION_CHANGE_STATE_DEFINED: 'Förslag klart',
        DEVIATION_CHANGE_STATE_NEEDS_WORK: 'Måste förbättras',
        DEVIATION_CHANGE_STATE_APPROVED: 'Godkänd',
        DEVIATION_STATUS_OPEN: 'Öppen',
        DEVIATION_STATUS_CLOSED: 'Stängd',
        DEVIATION_STATUS_ALL: 'Alla',
        CLOSE_DEVIATION: 'Stäng Avvikelse',
        ARTICLE: 'Artikel',
        OBJECT: 'Objekt',
        SUPPLIER: 'Leverantör',
        DEVIATION_DASHBOARD_GO_TO_LIST: 'Visa lista på alla avvikelser',
        DEVIATION_DASHBOARD_BY_CATEGORY: 'Avvikelser per kategori',
        DEVIATION_DASHBOARD_BY_RELATION: 'Mest frekventa avvikelser',
        DEVIATION_DASHBOARD_LATEST: 'Senaste avvikelserna',
        ADD_WORK_ORDER_ON_SITE_CONTACT: 'Lägg till kontaktperson',
        PROJECT_TIME_REPORTS_EXPORT_EXCEL: 'Tidrapporter till Excel',
        PROJECT_TIME_REPORTS_DOWNLOAD_EXCEL: 'Ladda ner',
        PROJECT_TIME_REPORTS_EXCEL_FILENAME: 'Export av tidrapporter',
        OPEN_PROJECTS_SUMMARY_DOWNLOAD_EXCEL: 'Ladda ner projektsummering',
        OPEN_PROJECT_SUMMARY_EXCEL_FILENAME: 'Projektsummering',
        ARTICLE_PRODUCT_TYPE_SERVICE: 'Tjänst',
        ARTICLE_PRODUCT_TYPE_STOCK: 'Lagerförd produkt',
        ARTICLE_PRODUCT_TYPE_NON_STOCK: 'EJ lagerförd produkt',
        WORKORDER_TIMEREPORTS_SHOWING_ONLY_MINE: 'Bara mina tider',
        WORKORDER_TIMEREPORTS_SHOWING_ALL: 'Alla tider',
        WORKORDER_TIMEREPORTS_THIS_WEEK: 'Denna vecka',
        WORKORDER_TIMEREPORTS_PREVIOUS: 'Tidigare',
        WORKORDER_TIMEREPORTS_PREVIOUS_SUMMARY: 'Tidigare summering',
        GO_TO_PROPOSAL: 'Visa offerten',
        PROJECT_SORT_BY_TIME: 'Pågående',
        PROJECT_SORT_BY_NAME: 'Projektnamn',
        PROJECT_SORT_BY_RESPONSIBLE_USER: 'Projektledare',
        WORKORDER_PLANNED: 'Planerad',
        WORKORDER_CREATED_TIME: 'Skapad {{time}}',
        ADD_ACTIVITY: 'Lägg till aktivitet',
        ADD_SECTION: 'Lägg till gruppering',
        ADD_TABLE: 'Lägg till tabell',
        ADD_OPTION: 'Lägg till svarsalternativ',
        WORK_ORDER_WAS_CLOSED: 'Arbetsorder slutfördes: {{time}}',
        SELECT_TIME_RANGE: 'Välj tidsintervall',
        EXPORT_WORKORDER_TIMEREPORTS: 'Exportera tidrapporter',
        BUSINESS_OBJECT_DETAILED_SEARCH_ENABLED: 'Detaljerad sök',
        BUSINESS_OBJECT_DETAILED_SEARCH_IS_ENABLED_MESSAGE:
            'Detaljerad sök är aktiverad!',
        WORK_ORDER_ORDER_CONFIRMATION: 'Order-bekräftelse mail',
        WORK_ORDER_ORDER_CONFIRMATION_SEND: 'Skicka nu',
        WORK_ORDER_ORDER_CONFIRMATION_IGNORE: 'Skicka inte!',
        WORK_ORDER_ORDER_CONFIRMATION_SENT_TO:
            'Orderbekräftelse mail skickat till {{to}}, {{time}}',
        WORK_ORDER_ORDER_CONFIRMATION_NOT_SENT:
            'Orderbekräftelse mail skickades inte!',
        WORK_ORDER_ORDER_FINISH_CONFIRMATION:
            'Vill du skicka orderbekräftelse mail till kund på utfört arbete ?',
        WORK_ORDER_ORDER_FINISH_CONFIRMATION_RECIPIENT: 'Mottagare',
        PROJECTS_PLANNING_PERSPECTIVE: 'Perspektiv',
        PROJECTS_PLANNING_PERSPECTIVE_PROJECT: 'Projekt',
        PROJECTS_PLANNING_PERSPECTIVE_RESOURCES: 'Resurser',
        COMPENSATION_REPORTING_SETTINGS_LABEL: 'Typ av ersättning',
        TIME_REPORTS_COMPENSATION_REPORTS: 'Ersättningar',
        COMPANY_HOLIDAYS_SETTINGS_LABEL: 'Företagets lediga dagar',
        OPEN_WORK_ORDER_COUNT_ON_OBJECT_SHORT: '{{count}} öppna arbetsorder',
        OPEN_WORK_ORDER_COUNT_ON_OBJECT_SHORT_plural:
            '{{count}} öppna arbetsorder',
        OPEN_WORK_ORDER_COUNT_ON_OBJECT:
            'Det finns <0>{{count}}</0> öppna arbetsorder',
        OPEN_WORK_ORDER_COUNT_ON_OBJECT_plural:
            'Det finns <0>{{count}}</0> öppna arbetsorder',
        WORK_ORDER_ARTICLE_REPORT_USER_DISTRIBUTION_SPLIT: 'DELAS',
        WORK_ORDER_USER_DISTRIBUTION_SPLIT_HEADER: 'TB',
        WORKORDER_HISTORY_EXCEL_FILENAME: 'Arbetsordrar',
        WORK_ORDER_USER_DISTRIBUTION_KPI_TITLE:
            'Fakturerad fördelning av TB per person',
        WORK_ORDER_USER_DISTRIBUTION_ON_OPEN_WORKORDERS_KPI_TITLE:
            'TB fördelning på öppna arbetsordrar',
        WORK_ORDER_USER_DISTRIBUTION_KPI_FOR_USER:
            '{{month}}: Artiklar för {{name}}, Totalt TB: {{totalMargin}}',
        REOPEN_PROPOSAL: 'Återöppna offerten',
        REOPEN_WORKORDER: 'Åter-öppna stängd arbetsorder',
        OR: 'eller',
        ANLAGGARINTYG_PDF_EXPORT_ADD_PAGES: 'Bilagor',
        ANLAGGARINTYG_PDF_EXPORT_ADD_SIGNATURE: 'Lägg till signatur',
        PROJECT_EXTRA_WORK_STATUS_PENDING: 'I väntan på..',
        PROJECT_EXTRA_WORK_STATUS_ACCEPTED: 'Godkänd',
        PROJECT_EXTRA_WORK_STATUS_DECLINED: 'Avböjd',
        PROJECT_EXTRA_WORK_STATUS_EDITOR_HEADER: 'Godkänd eller Avböjd - ÄTA ?',
        PROJECT_EXTRA_WORK_ACCEPT: 'Godkänd ÄTA',
        PROJECT_EXTRA_WORK_DECLINE: 'Avböjd ÄTA',
        PROJECT_WORK_ORDER_CREATE_INVOICE: 'Skapa separat projektfaktura',
        PROJECT_INVOICE_ROW_IS_IN_SCOPE: 'Inom budget',
        PROJECT_INVOICE_ROW_IS_OUT_OF_SCOPE: 'ÄTA',
        PROJECT_WORK_ORDERS_SHOW_LESS: 'Dölj..',
        PROJECT_WORK_ORDERS_SHOW_MORE: 'Visa alla {{count}} arbetsordrar',
        PROJECT_OUTCOME_REGULAR_SCOPE: 'Inom projektbudget',
        PROJECT_OUTCOME_EXTRA_SCOPE: 'ÄTA ekonomi',
        PROJECT_OUTCOME_EXTRA_TOTAL_INVOICED: '{{value}} totalt fakturerat',
        SERVICE_DISTRIBUTION_SCHEDULE_SERVICE_WORK_ORDERS_NOW:
            'Vill du skapa arbetsordern nu?',
        SERVICE_DISTRIBUTION_SCHEDULE_SERVICE_WORK_ORDERS_NOW_plural:
            'Skapa {{count}} arbetsordrar?',
        SERVICE_DISTRIBUTION_SCHEDULE_SERVICE_WORK_ORDERS_CONFIRM:
            'Ja, skapa nu!',
        PROJECT_OUTCOME_SCOPE_ALL: 'Projektsummering inkl. ÄTA',
        WORK_ORDER_PER_TAG_KPI_TITLE: 'Arbetsordrar per tagg',
        START_DATE: 'Start datum',
        END_DATE: 'Slut datum',
        SUPPLIER_TAG_EDITED_BY: 'Ändrat av: {{username}} ({{datetime}})',
        SELECT_PERIOD: 'Välj intervall',
        UNTIL: 'Till och med',
        DISPLAY: 'Visa',
        HIDE: 'Dölj',
        TIME_REPORTS_SCHEDULE_SINGLE_DAY: '<< Rapportera enstaka timmar',
        TIME_REPORTS_SCHEDULE_MANY_DAYS: 'Rapportera flera dagar >>',
        DOORAUTOMATICS: 'Dörrautomatik',
        MANAGE_DOORAUTOMATICS: 'Hantera dörrautomatiker',
        WORKORDER_ATTACHED_DOORAUTOMATICS: 'Kopplade dörrautomatiker',
        WORKORDER_CREATE_REMINDER: 'Skapa påminnelse',
        WORK_ORDER_REMINDER_MODAL_HEADER: 'Påminnelse via e-post',
        WORKORDER_REMINDER_SCHEDULED_AT: 'Påminnelse schemalagd till',
        TOPBAR_SUPPLIER_INVOICES: 'Attestera kostnader',
        SUPPLIER_INVOICES: 'Leverantörsfakturor',
        SUPPLIER_INVOICE_STATUS_PENDING: 'För hantering', 
        SUPPLIER_INVOICE_STATUS_APPROVED: 'Godkänd',
        SUPPLIER_INVOICE_STATUS_DECLINED: 'Bestrides',
        SUPPLIER_INVOICE_STATUS_ALL: 'Alla', 
        REOPEN_SERVICE_REPORT: 'Återöppna rapporten', 
        SUPPLIER_INVOICE_APPROVE_CONTENT: 'Godkänn innehållet',
        SUPPLIER_INVOICE_APPROVE_PAYMENT: 'Godkänn för betalning', 
        SUPPLIER_INVOICE_DELETE: 'Radera',
        WORKORDER_CLOSE_CHANGE_ERP_INVOICE_DATE: 'Justera fakturadatumet på fakturan?', 
        WORKORDER_CLOSE_CHANGE_ERP_DELIVERY_DATE: 'Justera leveransdatumet på fakturan?', 
        WORKORDER_CLOSE_INVOICE_WITH_REVERSE_VAT: 'Faktura kommer skapas med omvänd moms',
        WORKORDER_CLOSE_INVOICE_ADD_INVOICE_FEE: 'Lägg till administrativ avgift',
        ON_CALL: 'Jouren',
        CLEAR_ON_CALL_DAY: 'Rensa dag',
        CLEAR_ON_CALL_WEEK: 'Rensa vecka', 
        SELECT_ON_CALL_FOR_WEEK: 'Välj för hela veckan',
        ON_CALL_ON_N_DAYS: '{{count}} dag',
        ON_CALL_ON_N_DAYS_plural: '{{count}} dagar',
        WORK_ORDER_CHANGE_CUSTOMER: 'Byt kund',
        WORK_ORDER_CHANGE_OBJECT: 'Byt objekt',
        WORK_ORDER_CHANGE_CUSTOMER_CURRENT: 'Nuvarande kund',
        WORK_ORDER_CHANGE_CUSTOMER_SELECT_NEW: 'Välj NY kund för Arbetsordern',
        WORK_ORDER_CHANGE_CUSTOMER_NEW: 'Välj kund',
        WORK_ORDER_CHANGE_CUSTOMER_SAVE: 'Spara ändring',
        WORK_ORDER_CHANGE_OBJECT_CURRENT: 'Nuvarande objekt',
        WORK_ORDER_CHANGE_OBJECT_SELECT_NEW: 'Välj nytt objekt för Arbetsordern',
        WORK_ORDER_CHANGE_OBJECT_NEW: 'Valt objekt',
        WORK_ORDER_CHANGE_OBJECT_SAVE: 'Spara ändring',
        PROJECT_PLANNING: 'Planering', 
        PROJECT_ACTIVITY_TITLE: 'Aktivitet',
        OPEN_PROJECTS_KPI_OTHER_COSTS_IN_PERCENTAGE: 'Extra projektkostnader i %',
        OPTION_DEFAULT: 'Standard',
        OPTION_OPTIONAL: 'Valbart',
        OPTION_TEXT_INPUT: 'Textsvar',
        OPTION_YES_NO: 'Ja / Nej',
        OPTION_SELECTION: 'Flersvarsalternativ',
        PROJECT_EXTERNAL_URL: 'Extern länk',
        PROJECT_EDIT_EXTERNAL_URL: 'Ändra extern länk',
        CUSTOMER_CONTACT_PRIORITIZED: 'Protokoll',
        CUSTOMER_CONTACT_PRIORITIZED_TOOLTIP: 'Kontakten får protokoll via mail',
        CUSTOMER_HAS_OPEN_PROPOSALS: '{{count}} aktiva offerter', 
        CUSTOMERPICKER_CREATE_NEW_CUSTOMER: 'Hittade inte kund? skapa ny här',
        MARKUP_PERCENTAGE: 'Påslag(%)',
        MARKUP_PERCENTAGE_SHORT: '{{value}}%',
        TOTAL_MARKUP: 'Påslag',
        BUSINESS_OBJECT_CODES_ADD_CODE: 'Lägg till kod', 
        BUSINESS_OBJECT_CODES_ADD_CODE_GROUP: 'Lägg till grupp', 
        BUSINESS_OBJECT_CODES_LOGS_HEADER: 'Se händelselogg känlsliga koder',
        BUSINESS_OBJECT_CODES_VIEW_LOGS: 'Visa händelselogg på koder',
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_ADD_GROUP: '{{userName}} skapade grupp {{groupName}} för känsliga koder objekt {{businessObjectId}}',
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_EDIT_GROUP: '{{userName}} ändrade grupp från {{fromGroupName}} till {{toGroupName}} för känsliga koder på objekt {{businessObjectId}}',
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_DELETE_GROUP: '{{userName}} raderade grupp {{groupName}} för känsliga koder på objekt {{businessObjectId}}',
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_ADD_CODE: '{{userName}} skapade känslig kod {{codeName}} till gruppen {{groupName}} för känsliga koder på objekt {{businessObjectId}}',
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_EDIT_CODE: '{{userName}} ändrade känslig kod namn från {{fromCodeName}} till {{toCodeName}} för grupp {{groupName}} på objekt {{businessObjectId}}',
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_EDIT_CODE_VALUE: '{{userName}} ändrade känslig kod för {{codeName}} och grupp {{groupName}} på objektet {{businessObjectId}}',
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_VIEW_CODE: '{{userName}} tittade på känslig kod för {{codeName}} och grupp {{groupName}} på objektet {{businessObjectId}}',
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_COPY_CODE: '{{userName}} kopierade känslig kod för {{codeName}} och grupp {{groupName}} på objektet {{businessObjectId}}',
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_DELETE_CODE: '{{userName}} raderade känslig kod för {{codeName}} och grupp {{groupName}} på objektet {{businessObjectId}}',
        AUTHENTICATE_AZURE_EMAIL: 'Autentisiera e-post för Microsoft 365',
        WORK_ORDER_HISTORY_FILTER_BY_ID_OVERRIDES_OTHER_FILTERS: 'Ignorerar övriga filter!',
    },
    form: {
        ID: 'ID',
        WORK_ORDER_ID: 'Arbetsorder',
        ARTICLE_ID: 'Artikelnummer',
        NAME: 'Namn',
        SHORT_NAME: 'Initialer',
        EMAIL: 'e-Post',
        CUSTOMER_EMAIL: 'e-Post faktura',
        PASSWORD: 'Lösenord',
        AVATAR: 'Ikon användare',
        DEPARTMENT: 'Affärsområde',
        DEPARTMENT_ID: 'ID', 
        PHONE: 'Telefon',
        LANGUAGE: 'Språk',
        INVOICE_LANGUAGE: 'Språk för fakturor',
        OUR_REFERENCE_ON_AGREEMENT_INVOICES: 'Vår referens på avtalsfakturor',
        NOTIFICATIONS: 'Notiser',
        PUSH_NOTIFICATIONS: 'Push notiser',
        COMPANY_NAME: 'Företagsnamn',
        LOGO: 'Logga',
        INVOICE_DETAILS: 'Faktureringsinformation',
        ORGANIZATIONAL_NAME: 'Företagsnamn',
        ORGANIZATIONAL_NUMBER: 'Org.nr',
        VAT_REGISTRATION_NUMBER: 'Momsregistreringsnummer',
        VAT_PERCENTAGE: 'Moms procent (%)',
        PLAN: 'Trukks nivå',
        TEXT: 'Text',
        NOTES: 'Anteckning',
        NOTE: 'Anteckning',
        OBJECT: 'Objekt',
        DATE_FORMAT: 'Datumformat',
        TIME_FORMAT: 'Tidsformat',
        CURRENCY: 'Valuta',
        SHOW_WEEKEND: 'Visa helg',
        AUTHENTICATION: 'Verifiering',
        CURRENT_PASSWORD: 'Nuvarande lösenord',
        NEW_PASSWORD: 'Nytt lösenord',
        CONFIRM_NEW_PASSWORD: 'Verifiera lösenord',
        UPDATE_PASSWORD: 'Ändra lösenord',
        PASSWORD_REPEAT: 'Vänligen upprepa lösenord',
        CUSTOMER: 'Kund',
        CUSTOMER_NUMBER: 'Kundnummer',
        TAG: 'Tagg',
        TAGS: 'Taggar',
        TIME_REPORT_SELECT_TAG: 'Välj tidstyp',
        TIME_REPORT: 'Tidrapport',
        MATERIAL_REPORT: 'Materialrapport',
        INVOICING: 'Material och faktura',
        SCHEDULING: 'Planerat arbete',
        PLANNED_START_TIME: 'Planerad start',
        PLANNED_START_TIME_DATE: 'Planerad start datum',
        PLANNED_START_TIME_TIME: 'Planerad start tid',
        PLANNED_END_TIME: 'Planerat slut',
        PLANNED_END_TIME_DATE: 'Planerat slut datum',
        PLANNED_END_TIME_TIME: 'Planerat slut tid',
        CONTACT: 'Beställt av',
        CONTACTS: 'Kontakter',
        OBJECT_CONTACTS: 'Kontakter',
        ON_SITE_CONTACT: 'Kontaktperson',
        ON_SITE_CONTACT_NAME_PLACEHOLDER: 'Namn',
        ON_SITE_CONTACT_EMAIL_PLACEHOLDER: 'E-post',
        ON_SITE_CONTACT_PHONE_PLACEHOLDER: 'Tel',
        INTERNAL_RESPONSIBLE_PERSONS: 'Kundsvariga',
        CUSTOMER_ORGANIZATIONAL_NUMBER: 'Org.nr',
        CUSTOMER_ADDITIONAL_INFO: 'Kompletterande kundinformation',
        CUSTOMER_MARKINGS: 'Märkning',
        PAYMENT_TERMS: 'Betalas senast',
        PAYMENT_DUE_DATE: 'Betalas senast',
        REVERSE_VAT: 'Omvänd moms',
        PAYMENT_TERM_IN_DAYS: 'Betalningsvillkor i dagar',
        BG_ACCOUNT: 'BankGiro',
        CONTACT_NAME_PLACEHOLDER: 'Namn',
        CONTACT_EMAIL_PLACEHOLDER: 'e-Post',
        CONTACT_PHONE_PLACEHOLDER: 'Telefon',
        CONTACT_ROLE_PLACEHOLDER: 'Roll',
        CONTACT_INVOICE_COST_CENTER_PLACEHOLDER: 'Kostnadsställe',
        CITY: 'Ort',
        OBJECT_COUNT: 'Objekt',
        OBJECT_LOCATION_PLACEHOLDER: 'Ort',
        OBJECT_LOCATION_2_PLACEHOLDER: 'Gata',
        OBJECT_LOCATION_3_PLACEHOLDER: 'Postnummer',
        OBJECT_AGREEMENT_TYPE_PLACEHOLDER: 'Avtalstyp',
        DATE: 'Datum',
        VALID_FROM: 'Avtal aktivt från',
        VALID_TO: 'Avtalet upphör',
        NEXT_INVOICE_SCHEDULED_TO: 'Nästa avtalsfaktura skapas',
        NEXT_INVOICE_PERIOD_FROM: 'Startdatum för nästa avtalsperiod',
        NEXT_INVOICE_PERIOD_TO: 'Slutdatum för nästa avtalsfaktura',
        AGREEMENT_INVOICE_FREQUENCY: 'Frekvens (månader)',
        AGREEMENT_INVOICE_PRINTED_PERIOD:
            'Angiven period på nästa avtalsfaktura',
        NEXT_SERVICE_SCHEDULED_TO: 'Nästa avtalsservice skapas',
        INVOICE_REGARDING_PERIOD: 'Avtalsfaktura avseende period',
        ARTICLES: 'Artiklar',
        PRIORITY: 'Prioritet',
        PRIORITY_HIGH: 'Hög',
        PRIORITY_MEDIUM: 'Medel',
        PRIORITY_LOW: 'Låg',
        ASSIGNEES: 'Ansvariga',
        ASSIGNEE: 'Ansvariga',
        LOCATION: 'Ort',
        LOCATION_2: 'Gata',
        CUSTOMER_LOCATION_2: 'Gata',
        LOCATION_3: 'Postnummer',
        LOCATION_DESCRIPTION: 'Placering',
        LOCATION_STATE: 'State', 
        LOCATION_STATE_PLACEHOLDER: 'State',
        LOCATION_COUNTRY: 'Land',
        LOCATION_COUNTRY_PLACEHOLDER: 'Land',
        AGREEMENT_TYPE: 'Avtalstyp',
        AGREEMENT_TYPES: 'Avtalstyper',
        DESCRIPTION: 'Beskrivning',
        ARTICLE_DESCRIPTION: 'Artikelbeskrivning',
        ACCESS_LEVEL_DESCRIPTION: 'Beskrivning behörighet',
        ACCESS_LEVEL_DASHBOARD: 'Startsida',
        WORK_ORDER_DESCRIPTION: 'Beskrivning',
        WORK_ORDER_INTERNAL_INFO: 'Intern information',
        SUMMARY: 'Sammanställning utfört arbete',
        TITLE: 'Titel',
        FIELDS: 'Fält',
        MODEL: 'Modell',
        CODE: 'Modellkod',
        SERIAL: 'Objekt ID',
        STATUS: 'Status',
        COLOR: 'Färg',
        PLAN_GOLD: 'Guld',
        INVOICE_CREATION: 'Debitering av avtal',
        WORKORDER_CREATION: 'Arbetsorder skapas enligt avtal',
        AGREEMENT_WORKORDER_GENERATION_DAY: 'Avtalsservice skapade dag',
        AGREEMENT_WORKORDER_GENERATION_INTERVAL:
            'Avtalsservice skapande intervall',
        AGREEMENT_INVOICE_GENERATION_DAY: 'Avtalsfaktura skapade dag',
        AGREEMENT_INVOICE_GENERATION_INTERVAL:
            'Avtalsfaktura skapande intervall',
        AGREEMENT_WORKORDER_GENERATION_INCLUDE_ARTICLES:
            'Lägg artiklar på avtals-arbetsordrar',
        AGREEMENT_WORKORDER_GENERATION_INCLUDE_DESCRIPTION:
            'Infoga beskrivningen av avtalet på arbetsordern',
        USER_COUNT: 'Användare',
        ACCESS_LEVELS: 'Behörigheter',
        UNIT: 'Enhet',
        PRICE: 'Pris',
        PURCHASE_PRICE: 'Inköpspris',
        SALES_PRICE: 'Pris',
        RECOMMENDED_SALES_PRICE: 'Rekommenderat försäljningspris',
        REGULAR_PRICE: 'Listpris',
        TOTAL_PRICE: 'Totalt',
        USER: 'Användare',
        PERMISSIONS: 'Rättigheter',
        LIMITED_TO: 'Begränsad till',
        WORK_TYPE: 'Ordertyp',
        WORK_TYPES: 'Ordertyper',
        STATUSES: 'Status arbetsorder',
        MULTIPLE_TECHNICIANS: 'Fler ansvariga på arbetsorder',
        MULTIPLE_DATES: 'Multipla datum',
        EXTRA_FIELDS: 'Extra fält',
        PRIORITIES_ENABLED: 'Prioritet aktiverad',
        PREFIX: 'Prefix',
        MIN_LENGTH: 'Minst antal tecken',
        TOTAL_TIME: 'Tid',
        TRAVEL_TIME: 'Resa',
        WORK_TIME: 'Arbete',
        PRICE_PER_HOUR: 'á Pris',
        PRICE_TOTAL: 'Summering',
        REPORTED_HOURS: 'Rapporterade timmar',
        ARTICLE: 'Artikel',
        AMOUNT: 'Antal',
        PRICE_PER_UNIT: 'á Pris',
        WORK_ORDER_DETAILS: 'Allmänt',
        INVALID_EMAIL_OR_PASSWORD: 'Fel e-post eller lösenord.',
        SERVER_ERROR: 'Något gick fel! Kontakta Trukks support.',
        IP_BLOCKED_TRY_AGAIN_LATER:
            'Antalet inloggningsförsök har överskridits. Vänligen kontakta TRUKKS support.',
        TOKEN_EXPIRED: 'Nyckeln har gått ut..',
        CANT_LOG_IN: 'Glömt inloggningsuppgifter?',
        LOG_IN: 'Logga in',
        ALREADY_HAVE_USER_LOG_IN: 'Redan kund? logga in här..',
        LOG_IN_INSTEAD: 'Logga in här..',
        CONFIRM: 'Bekräfta',
        WELCOME_TO_TRUKKS_TEXT:
            'Välkommen till TRUKKS! <br> Aktivera kontot genom att skapa lösenord nedan.',
        CLOSED_TIME: 'Slutförd den',
        CREATE_DATE: 'Skapad den',
        WORK_ORDER_NUMBER: 'Arbetsorder nummer',
        INVOICE: 'Faktura',
        LIMITED_TO_DESCRIPTION: 'Se arbetsorder',
        ARTICLE_TEXT_IS_EDITABLE: 'Artikelns text ändringsbar',
        ARTICLE_PRODUCT_TYPE: 'Typ av artikel',
        ARTICLE_ENABLE_SUPPLIERS: 'Aktivera leverantörer',
        ADDRESS: 'Kundens adresser',
        WORK_ORDER_ADDRESS: 'Arbetsorderns adress',
        CUSTOMER_VAT_REGISTRATION_NUMBER: 'Kundens moms-reg. nummer',
        DISCOUNT: 'Rabatt',
        DISCOUNT_TYPE: 'Typ av rabatt',
        RESPONSIBLE_TECHNICIAN: 'Ansvarig tekniker',
        CUSTOMER_ORDER_NUMBER: 'Kundens inköps-nr',
        AUTO_DISPATCH_NEW_WO: 'Automatisk delegering vid ny arbetsorder',
        DEFAULT_REQUIRED_FIELDS: 'Obligatoriskt fält',
        REQUIRED_FIELD: 'Obligatoriskt fält',
        TAG_REQUIRED: 'Tagg saknas!',
        ON_SITE_CONTACT_NAME_REQUIRED: 'Kontaktperson är obligatorisk!',
        CONTACT_REQUIRED: 'kontakt saknas!',
        EMAIL_REQUIRED: 'e-post saknas!',
        PHONE_REQUIRED: 'telefon saknas!',
        DESCRIPTION_REQUIRED: 'Beskrivning saknas!',
        INVOICE_MARKING_REQUIRED: 'Märke faktura är obligatoriskt!',
        CUSTOMER_ORDER_NUMBER_REQUIRED: 'Kundens inköps-nr är obligatoriskt!',
        LABEL_IS_REQUIRED: 'Namn på tidsintervall obligatoriskt',
        LABEL_DUPLICATE: 'Tidsintervall får inte ha samma namn',
        PERIOD_TIMES_HAVE_OVERLAPS: 'Tidsintervallen överlappar varandra!',
        INTERNAL_MESSAGE: 'Internt meddelande på arbetsorder',
        TOTAL_INVOICE_PRICE_EXCLUDING_VAT: 'Totalt pris',
        TOTAL_REPORTED_TIME: 'Total rapporterad tid',
        INVOICE_MARKING: 'Fakturamärke',
        ORDERED_BY: 'Beställd av',
        ORDERED_DATE: 'Beställt datum',
        FILES: 'Dokument',
        UPLOAD_FILES_PLACEHOLDER: 'Ladda upp dokument här!',
        SUPPLIER_NUMBER: 'Leverantörsnummer',
        SUPPLIER_NAME: 'Leverantörsnamn',
        CHECKLIST: 'Checklista',
        CHECKLISTS: 'Checklistor',
        CHECKLIST_TITLE: 'Titel',
        CHECKLIST_ENABLED_ON_WORK_TYPES: 'Visas på arbetsordertyper',
        CHECKLIST_ITEMS: 'Kontrollpunkter',
        INVOICE_OPTIONS: 'Fakturerings-alternativ',
        ALTERNATIVE_INVOICE_RECEIVER: 'Mottagare av faktura',
        ALTERNATIVE_INVOICE_ADDRESS: 'Alternativ faktura-adress',
        OBJECT_AGREEMENT_INVOICE_RECEIVER: 'Fakturerings-alternativ',
        PROJECT_MENU_GENERAL: 'Projekt överblick',
        PROJECT_MENU_INVOICING: 'Projektfakturor',
        PROJECT_MENU_COSTS: 'Kostnader',
        PROJECT_MENU_WORK_ORDERS: 'Arbetsordrar',
        PROJECT_MENU_FILES: 'Dokument',
        PROJECT_MENU_STATISTICS: 'Uppföljning',
        PROJECT_MENU_SETTINGS: 'Inställningar',
        PROJECT_MENU_PLANNING: 'Planering',
        PROJECT: 'Projekt',
        PROJECT_ECONOMY: 'Projektekonomi',
        PROJECT_TIMELINE: 'Planering',
        PROJECT_TITLE: 'ID',
        PROJECT_NAME: 'Namn',
        PROJECT_STATUS: 'Status',
        PROJECT_START_DATE: 'Start datum',
        PROJECT_END_DATE: 'Slut datum',
        PROJECT_OUTCOME: 'Utfall/Resultat',
        PROJECT_ESTIMATIONS: 'Uppskattat',
        PROJECT_ESTIMATED_HOURS: 'Uppskattade timmar',
        PROJECT_ESTIMATED_COST: 'Uppskattade materialkostnader',
        PROJECT_INCOME: 'Fastpris kontraktsumma',
        PROJECT_INCOME_INVOICED: 'Fakturerat',
        PROJECT_INCOME_LEFT_TO_INVOICE: 'Kvar att fakturera',
        PROJECT_OBJECTS_INVOLVED: 'Involverade objekt',
        PROJECT_CUSTOMERS_INVOLVED: 'Involverade kunder',
        PROJECT_SUPPLIERS_INVOLVED: 'Involverade leverantörer',
        PROJECT_WORK_ORDERS_INVOLVED: 'Arbetsordrar',
        PROJECT_SALES_ORDERS_INVOLVED: 'Säljordrar',
        PROJECT_RESPONSIBLE_USER: 'Projektledare',
        PROJECT_CONTACT_NAME: 'Ansvarig beställare',
        PROJECT_DESCRIPTION: 'Projektbeskrivning',
        SALES_ORDER: 'Försäljningsorder',
        SALES_ORDER_TITLE: 'ordernummer',
        PROPOSAL: 'Offert',
        PROPOSAL_TITLE: 'Offertnummer',
        PROPOSAL_VALID_TO: 'Giltig till',
        PROPOSAL_CREATED_WORK_ORDER: 'Skapade arbetsorder',
        PROPOSAL_CREATED_SALES_ORDER: 'Skapade försäljningsorder',
        PROPOSAL_STANDARD_TEXTS: 'Standardtexter',
        PROPOSAL_STANDARD_TEXTS_EXCEPTIONS: 'Ingår inte i denna offert', 
        PROPOSAL_STANDARD_TEXTS_INCLUSIONS: 'I vårt åtagande ingår',
        PROPOSAL_STANDARD_TEXTS_PAYMENT_TERMS: 'Betalningsvillkor',
        PROPOSAL_STANDARD_TEXTS_GENERAL_TERMS: 'Allmänna villkor',
        PROPOSAL_TEMPLATES: 'PDF mallar',
        PROPOSAL_FOLLOW_UP_DATE: 'Följ upp',
        PROPOSAL_CLOSE_DATE: 'Datum',
        PROPOSAL_INTRO: 'Titel på offerten',
        PROPOSAL_INTRO_TEXT: 'Introduktion',
        PROPOSAL_DELIVERY_ADDRESS: 'Leveransadress',
        PROPOSAL_DESCRIPTION_OF_EXCEPTIONS: 'Ingår inte i denna offert',
        PROPOSAL_GENERAL_TERMS: 'Allmänna villkor',
        PROPOSAL_PAYMENT_TERMS: 'Betalningsvillkor',
        DELIVERY_ADDRESS: 'Leveransadress',
        ARTICLE_E_NUMBER: 'E-nummer',
        OBJECT_AGREEMENT_INVOICE_MARKING: 'Märkning på faktura',
        OBJECT_AGREEMENT_INVOICE_REFERENCE: 'Referens avtalsfaktura',
        SALES_ORDER_CREATED_FROM_PROPOSAL: 'Baserad på offert',
        CUSTOMER_DEFAULT_DISCOUNT_PERCENTAGE: 'Rabatt på alla artiklar',
        ERP_SETTINGS: 'Till Ekonomisystemet',
        MAIL_SETTINGS: 'Inställningar för e-post',
        COMMENT: 'Intern anteckning',
        PAYMENT_ACCOUNT: 'BankGiro',
        ERP_ARTICLE_ACCOUNT: 'Konto',
        ERP_ARTICLE_ACCOUNT_GOODS: 'Produkter',
        ERP_ARTICLE_ACCOUNT_SERVICE: 'Tjänster',
        ARTICLE_SUPPLIER: 'Leverantör',
        ARTICLE_SUPPLIER_ARTICLE_NR: 'Leverantörens artikelnummer',
        REFERENCE_CONTACT: 'Förvaltare - kst',
        SERVICE_REPORTS: 'Dokumentation och rapportering',
        SERVICE_REPORT: 'Serviceorder',
        SERVICE_REPORT_TITLE: 'Titel',
        SERVICE_REPORT_TEMPLATE_TYPE: 'Dokument ID',
        SERVICE_REPORT_ENABLED_ON_WORK_TYPES: 'Aktiverad för arbetsordertyp',
        SERVICE_REPORT_EMAIL_TO: 'Rapport skickas till e-post:',
        SERVICE_REPORT_EMAIL_TO_SELF: 'Användare',
        ID06_CODE: 'ID06 kod',
        EXPORT_ID06: 'Exportera ID06',
        PERSONAL_IDENTITY_NUMBER: 'Personnummer',
        TIME_REPORT_TAG_TYPE_ATTEND: 'Närvaro',
        TIME_REPORT_TAG_TYPE_ABSENCE: 'Frånvaro',
        COMPENSATION_REPORT_TAG_TYPE_DISTANCE: 'Milersättning',
        COMPENSATION_REPORT_TAG_TYPE_TRAVEL: 'Traktamente',
        COMPENSATION_REPORT_TAG_TYPE_EXPENSE: 'Utlägg',
        TYPE: 'Typ',
        LABEL: 'Benämning',
        INVOICE_RULES: 'Affärsregler',
        INVOICE_RULE_AUTO_TIME_INVOICING:
            'Automatisk debitering enligt rapporterad arbetstid',
        INVOICE_RULE_AUTO_TRAVEL_TIME_INVOICING:
            'Automatisk debitering av rapporterad restid',
        PROJECT_INVOICE_MATERIAL_AND_INVOICING: 'Fakturamärke',
        OBJECT_SERIAL: 'ID',
        OBJECT_MODEL: 'Modell',
        AGREEMENT_NAME: 'Avtal',
        AGREEMENT_SERVICE_MONTH: 'Planerat',
        WEBSITE: 'Hemsida',
        POST_GIRO_ACCOUNT: 'PostGiro',
        ARTICLE_GROUP_ID: 'Grupp-ID',
        ARTICLE_GROUP_NAME: 'Gruppnamn',
        ARTICLE_GROUP: 'Artikelgrupp',
        PRICE_LIST_ID: 'Prislistor',
        CUSTOMER_PRICE_LIST: 'Aktiv prislista',
        OBJECT_PRICE_LIST: 'Aktiv prislista', 
        PRICE_LIST_CONFIGURATION: 'Anpassning av prislista',
        CONTINUE: 'Fortsätt',
        MFA_LOGIN_TITLE: 'Två-faktors login',
        MFA_LOGIN_DESCRIPTION:
            'Ange 6-siffrig kod från {{serviceProvider}} app',
        MFA_LOGIN_CODE_PLACEHOLDER: 'Kod',
        MFA_CHALLENGE_FAILED: 'Fel kod!',
        MFA_AUTHENTICATION_FAILED: 'Två-faktors autentisieringen misslyckades!',
        ACTIVATE: 'Aktivera',
        DEACTIVATE: 'Stäng av',
        SALES_ORDER_STATUS: 'Status',
        SALES_ORDER_STATUS_OPEN: 'Öppna',
        SALES_ORDER_STATUS_CLOSED: 'Fakturerade',
        CUSTOMER_ZONE: 'Kund-zon',
        CUSTOMER_ZONE_AUTH_CODES: 'Kod till kund-zon',
        TIME_REPORT_TAG_DEFAULT_TIME: 'Standard tid',
        MODEL_SPECIFICATIONS: 'Specifikation',
        CUSTOMER_CREATE_INVOICES_IN_ERP:
            'Skicka fakturering-underlag till ekonomisystemet',
        CUSTOMER_PERSON_NAME: 'Person',
        DISTANCE_REPORTS_LAST_READING: 'Senaste mätarställning',
        DISTANCE_REPORTS_ACTUAL_READING: 'Ny mätarställning',
        DISTANCE_REPORTS_ACTUAL_KM: 'Antal KM',
        SETTINGS_SALES_ORDER_FIXED_VAT:
            'Omvänd moms ignoreras på försäljningsorder',
        SETTINGS_NOTIFICATIONS_WORK_ORDER_SCHEDULED_EVENT:
            'Skicka e-post till användaren när arbetsorder tilldelas',
        SETTINGS_NOTIFICATIONS_WORK_ORDER_CREATED_EVENT:
            'Får e-Post när ny arbetsorder skapas',
        SETTINGS_NOTIFICATIONS_EVENT_ENABLED_FOR_TYPES:
            'Aktiv för arbetsordertyper',
        DEVIATION_CATEGORIES: 'Kategorier',
        DEVIATION_ID: 'ID',
        DEVIATION_OCCURED_DATE: 'Inträffade den',
        DEVIATION_CATEGORY: 'Kategorier',
        DEVIATION_RELATIONS: 'Relaterat till',
        DEVIATION_SHORT_TERM_SOLUTION: 'Omedelbar åtgärd',
        DEVIATION_LONG_TERM_SOLUTION: 'Långsiktig lösning',
        DEVIATION_IS_CHANGE_NEEDED: 'Förändring',
        DEVIATION_IS_CHANGE_NEEDED_YES: 'Behövs!',
        DEVIATION_IS_CHANGE_NEEDED_NO: 'Behövs INTE!',
        DEVIATION_CHANGE_DUE_DATE: 'Senast införd',
        DEVIATION_CHANGE_LAST_EDITED_BY: 'Senast ändrad av',
        DEVIATION_CHANGE_APPROVED_AT: 'Godkänd',
        DEVIATION_CHANGE_APPROVED_BY: 'Godkänd av',
        WORK_ORDER_CALCULATED_COST_ON_TIME: 'Intern kostnad per timme',
        WORK_ORDER_CALCULATED_COST_ON_DISTANCE: 'Intern kostnad per KM',
        AGREEMENT_DISCOUNT_PERCENTAGE_ON_PRODUCTS: 'Rabatt på produkter',
        AGREEMENT_DISCOUNT_PERCENTAGE_ON_SERVICES: 'Rabatt på tjänster',
        CUSTOMER_PRICE_DISCOUNT_ON_ARTICLE_GROUPS: 'Rabatt på artikelgrupper',
        BUSINESS_OBJECT_DEFAULT_AGREEMENT: 'Förvald avtalstyp',
        PROJECT_SETTINGS_DEFAULT_INVOICE_MARKING: 'Standard Märke fakturor',
        WORK_ORDER_DESCRIPTION_STANDARD_TEXTS:
            'Fördefinierade texter för beskrivning',
        WORK_ORDER_SUMMARY_STANDARD_TEXTS:
            'Fördefinierade texter för utfört arbete',
        COMPENSATION_REPORT: 'Ersättningar',
        COMPENSATION_REPORTS_TYPE: 'Typ',
        COMPENSATION_REPORTS_KM: 'Milersättning',
        COMPENSATION_REPORTS_EXPENSE_TOTAL_VALUE: 'Totalt',
        COMPENSATION_REPORTS_EXPENSE_TOTAL_VALUE_VAT: 'Moms',
        COMPENSATION_REPORTS_EXPENSE_CATEGORY: 'Typ av ersättning',
        PROJECT_RESOURCE_PLANNING_TASKS: 'Uppgifter',
        PROJECT_RESOURCE_PLANNING_TASK: 'Uppgift',
        MICROSOFT_AUTH_LOGIN: 'Logga in med Microsoft',
        LINK: 'Länk',
        ADD_LINK: 'Lägg till extern projektlänk',
        ADD_LINK_ACTION: 'Spara',
        EDIT_LINK: 'Ändra länk', 
        CUSTOMER_BROADCAST_EMAIL: 'E-post notifiering för skapad arbetsorder',
        HOURS_OF_WORKING: 'Arbetstimmar',
        SUPPLIER_TAGS: 'Statusar',
        SUPPLIER_TAG: 'Status',
        IBAN: 'IBAN',
        SALES_ORDER_REFERENCE_USER: 'Vår referens',
        DOORAUTOMATIC_TITLE: 'ID',
        DOORAUTOMATIC_SERIAL_CODE: 'Serienummer',
        DOORAUTOMATIC_BRAND: 'Fabrikat',
        DOORAUTOMATIC_MODEL: 'Modell',
        DOORAUTOMATIC_LOCATION: 'Placering',
        DOORAUTOMATIC_WARRANTY_UNTIL: 'Garanti till',
        DOORAUTOMATIC_SENSOR: 'Sensor', 
        DOORAUTOMATIC_NOTES: 'Anteckning',
        DOORAUTOMATIC_IMAGE: 'Bild',
        WORK_ORDER_REMINDER_MESSAGE: 'Meddelande',
        WORK_ORDER_REMINDER_DATE: 'Datum',
        WORK_ORDER_REMINDER_TIME: 'Tid', 
        WORK_ORDER_REMINDER_USERS: 'Användare',
        SUPPLIER_INVOICE_CREATE_TIME: 'Skapades', 
        SUPPLIER_INVOICE_SUPPLIER_NAME: 'Leverantör', 
        SUPPLIER_INVOICE_PROJECT: 'Projekt',
        SUPPLIER_INVOICE_ORDER_NUMBER: 'Ordernummer',
        SUPPLIER_INVOICE_TOTAL_COST: 'Total kostnad',
        SUPPLIER_INVOICE_EXPIRE_TIME: 'Förfaller',
        SUPPLIER_INVOICE_RESPONSIBLE_USER: 'Användare', 
        SUPPLIER_INVOICE_COMMENTS: 'Kommentarer',
        SUPPLIER_INVOICE_APPROVE: 'Godkänn',
        SUPPLIER_INVOICE_IMAGE: 'Bild',
        SUPPLIER_INVOICE_CONTENT_STATUS: 'Godkänn innehållet', 
        SUPPLIER_INVOICE_PAYMENT_STATUS: 'Godkänn för betalning', 
        SUPPLIER_INVOICE_INVOICE_NUMBER: 'Leverantörs fakturanummer', 
        SUPPLIER_INVOICE_EXTERNAL_REFERENCE: 'Leverantörens ref.',
        SUPPLIER_INVOICE_INTERNAL_REFERENCE: 'Vår ref.', 
        SUPPLIER_INVOICE_ARTICLES: 'Artiklar',
        SUPPLIER_INVOICE_ARTICLE_NAME: 'Artikelbeskrivning',
        SUPPLIER_INVOICE_ARTICLE_AMOUNT: 'Antal', 
        SUPPLIER_INVOICE_ARTICLE_PURCHASE_PRICE: 'Inköps pris',
        SUPPLIER_INVOICE_ARTICLE_ROW_PRICE: 'Rad pris',
        SUPPLIER_INVOICE_ARTICLE_VAT: 'Moms',
        SUPPLIER_INVOICE_ARTICLES_TOTAL_PRICE: 'Total kostnad', 
        SUPPLIER_INVOICE_ARTICLES_TOTAL_VAT: 'Total moms',
        DELIVERY_DATE: 'Leveransdatum',
        DELIVERY_PARTY: 'Mottagare',
        SETTINGS_WORK_ORDER_DISPATCH_RULES: 'Delegerings-regel',
        WORK_ORDER_DISPATCH_RULE_DAY_OF_WEEK: 'Dag',
        WORK_ORDER_DISPATCH_RULE_START_TIME: 'Start-tid', 
        WORK_ORDER_DISPATCH_RULE_END_TIME: 'Slut-tid', 
        WORK_ORDER_DISPATCH_RULE_DISPATCH_TO: 'Delegeras till:', 
        WORK_ORDER_DISPATCH_AUTO_TO_CREATE_USER: 'Delegeras automatiskt till skaparen', 
        UPLOAD_SUPPLIER_INVENTORY_DISCOUNT_FILE_PLACEHOLDER: 'Ladda upp rabattbrev',
        USER_FORTNOX_EMPLOYEE_ID: 'FortNox anställnings ID',
        DOORAUTOMATIC_COUNT: 'Dörrautomatiker',
        ARTICLE_IS_SECURITAS_DIRECT_ARTICLE: 'Securitas Direct artikel', 
        ARTICLE_SECURITAS_MONTHLY_COMPENSATION: 'Månatlig ersättning', 
        ARTICLE_SECURITAS_INSTALLATION_COMPENSATION: 'Installationsersättning',
        ARTICLE_SECURITAS_SALES_COMPENSATION: 'Säljersättning',
        ARTICLE_IS_SECURITY_ARTICLE: 'Säkerhetsartikel',
        BUSINESS_OBJECT_DISPLAY_NAME: 'Objektnamn',
        REGIONS: 'Regioner',
        PROJECT_REGION: 'Region',
        PROJECT_SALES_USER: 'Säljare',
        COST_CENTER: 'KS',
        STOCK_POINT: 'LS',
        STOCK_LOCATION: 'Lagerplats',
        USER_DISTRIBUTION: 'Person',
        PROPOSAL_MONTHLY_PRICE: 'Pris per månad',
        BUSINESS_OBJECT_CODES: 'Känsliga Koder',
    },
    security: {
        CAPACITY: 'Kapacitet',
        CURRENT_AT_REST: 'Ström i vila',
        CURRENT_AT_ALARM: 'Ström vid larm',
        SPECIFICATION: 'Anläggarintyg',
        TYPE: 'Typ',
        MANUFACTURER: 'Fabrikat',
        ALARM_CLASS: 'Larmklass',
        SPSC: 'Certifierad',
        SPSC_VALID_TO: 'Certifierad till',
        SBSC_ID: 'SBSC ID',
        MODEL_SYSTEM_TYPES: 'System typer',
        MODEL_SYSTEM_TYPE_BURGLAR: 'Inbrottslarm',
        MODEL_SYSTEM_TYPE_PASSAGE: 'Passagesystem',
        MODEL_SYSTEM_TYPE_CAMERA: 'Kamerasystem / CCTV',
        MODEL_SYSTEM_TYPE_FIREALARM: 'Brandsystem',
        MODEL_SYSTEM_TYPE_ASSAULT: 'Överfallslarm',

        OBJECT_PASSAGE_TYPE_OF_SYSTEM: 'Typ av system',
        OBJECT_PASSAGE_NUMBER_OF_SUBCENTRALS: 'Antal undercentral',
        OBJECT_PASSAGE_DOOR_LEAF_READER: 'Dörrbladsläsare',
        OBJECT_PASSAGE_CARD_READER: 'Kortläsare',
        OBJECT_PASSAGE_ACCESS_POINT: 'Accesspunkter / hubbar',
        OBJECT_PASSAGE_POWER_SUPPLY: 'Spänningsaggregat',
        OBJECT_PASSAGE_TYPE_OF_READER: 'Typ av läsning',
        OBJECT_PASSAGE_COMMENTS: 'Kommentarer',

        OBJECT_CAMERA_NUMBER_OF_CAMERAS: 'Antal kameror',
        OBJECT_CAMERA_NUMBER_OF_PTZ: 'Antal PTZ',
        OBJECT_CAMERA_NUMBER_OF_HEAT_CAMERAS: 'Antal värmekameror',
        OBJECT_CAMERA_TYPE_OF_SOFTWARE: 'Typ av mjukvara',
        OBJECT_CAMERA_NUMBER_OF_CHANNELS_IN_NVR: 'Antal kanaler i NVR',
        OBJECT_CAMERA_TYPE_OF_READER: 'Typ av läsnin',
        OBJECT_CAMERA_COMMENTS: 'Kommentarer',

        OBJECT_FIREALARM_FIRE_ALARM_CENTRAL: 'Brandlarmscentral',
        OBJECT_FIREALARM_TYPE_OF_BATTERY: 'Typ av batteri',
        OBJECT_FIREALARM_NUMBER_OF_SMOKE_DETECTORS: 'Antal rökdetektorer',
        OBJECT_FIREALARM_NUMBER_OF_HEAT_DETECTORS: 'Antal värmedetektorer',
        OBJECT_FIREALARM_NUMBER_OF_ALARM_BUTTONS: 'Antal larmknappar',
        OBJECT_FIREALARM_NUMBER_OF_ALARM_DEVICES: 'Antal larmdon',
        OBJECT_FIREALARM_COMMENTS: 'Kommentarer',

        OBJECT_ASSAULT_FABRIC: 'Fabrikat',
        OBJECT_ASSAULT_NUMBER_OF_SUBCENTRALS: 'Antal undercentraler',
        OBJECT_ASSAULT_NUMBER_OF_DISPLAYS: 'Antal mapp/text display',
        OBJECT_ASSAULT_NUMBER_OF_RECEIVERS: 'Antal mottagare',
        OBJECT_ASSAULT_COLOR_OF_FIXED_BUTTONS: 'Färg på knappar',
        OBJECT_ASSAULT_NUMBER_OF_FIXED_BUTTONS: 'Antal fasta knappar',
        OBJECT_ASSAULT_COLOR_OF_WIRELESS_BUTTONS: 'Färg trådlösa knappar',
        OBJECT_ASSAULT_NUMBER_OF_WIRELESS_BUTTONS: 'Antal trådlösa knappar',
        OBJECT_ASSAULT_COLOR_OF_FLASHLIGHTS: 'Color of flashlights',
        OBJECT_ASSAULT_NUMBER_OF_FLASHLIGHTS: 'Färg blixtljus',
        OBJECT_ASSAULT_COMMENTS: 'Kommentarer',
    },
    copy: {
        ARTICLE_SUFFICIENT_FOR: 'Räcker till',
        ARTICLE_SUFFICIENT_FOR_PRINTOUTS: '{{count}} utskrifter',
        AGREEMENT_CLICK_REGISTRATION: 'Räkneverksregistrering',
    },
    calendar: {
        TODAY: 'Idag',
        TOMORROW: 'Imorgon',
        YESTERDAY: 'Igår',
        THIS_WEEK: 'Denna vecka',
        THIS_MONTH: 'Denna månad',
        LAST_MONTH: 'Förra månaden',
        THIS_YEAR: 'Sedan årsskiftet',
        MONDAY: 'Måndag',
        TUESDAY: 'Tisdag',
        WEDNESDAY: 'Onsdag',
        THURSDAY: 'Torsdag',
        FRIDAY: 'Fredag',
        SATURDAY: 'Lördag',
        SUNDAY: 'Söndag',
        JANUARY: 'Januari',
        FEBRUARY: 'Februari',
        MARCH: 'Mars',
        APRIL: 'April',
        MAY: 'Maj',
        JUNE: 'Juni',
        JULY: 'July',
        AUGUST: 'Augusti',
        SEPTEMBER: 'September',
        OCTOBER: 'Oktober',
        NOVEMBER: 'November',
        DECEMBER: 'December',
        JANUARY_SHORT: 'Jan',
        FEBRUARY_SHORT: 'Feb',
        MARCH_SHORT: 'Mar',
        APRIL_SHORT: 'Apr',
        MAY_SHORT: 'Maj',
        JUNE_SHORT: 'Jun',
        JULY_SHORT: 'Jul',
        AUGUST_SHORT: 'Aug',
        SEPTEMBER_SHORT: 'Sept',
        OCTOBER_SHORT: 'Okt',
        NOVEMBER_SHORT: 'Nov',
        DECEMBER_SHORT: 'Dec',
        MONTH: 'Månad',
        YEAR: 'År',
        WEEK: 'Vecka',
        DAY: 'Dag',
        QUARTER: 'Kvartal',
        HALF_YEAR: 'Halvår',
        LAST_DAY_OF_MONTH: 'Sista dag i månaden',
        ONE_MONTH: 'Månad',
        TWO_MONTHS: 'Två månader',
        THREE_MONTHS: 'Tre månader',
        FOUR_MONTHS: 'Fyra månaders',
        FIVE_MONTHS: 'Fem månder',
        SIX_MONTHS: 'Sex månader',
        EIGHTEEN_MONTHS: '18 månader',
        ONE_YEAR: 'Årligen',
        TWO_YEARS: 'Vart annat år',
        THREE_YEARS: 'Vart tredje år',
        FOUR_YEARS: 'Vart fjärde år',
        TIME_TO: 'Till',
        TIME_FROM: 'Från',
        PERSPECTIVE_SCHEDULE: 'Tekniker',
        PERSPECTIVE_PROJECT: 'Projekt',
        DAYS: 'Dagar',
    },
    datatable: {
        NO_ROWS: 'Ingen data',
        RELOAD: 'Uppdatera',
        SEARCH: 'Sök..',
        ROWS: '{{count}} rad',
        ROWS_plural: '{{count}} rader',
        ZERO_ROWS_FILTERED: '{{count}} rader (av {{filteredFromCount}})',
        SHOWING_ROWS:
            'Visar {{startCount}} till {{endCount}} av {{count}} rader',
        SHOWING_ROWS_FILTERED:
            'Visar {{startCount}} till {{endCount}} av {{count}} rader (av {{fromCount}} rader)',
    },
    messages: {
        SUCCESSFULLY_SAVED: 'Sparad!',
        SUCCESSFULLY_DELETED: 'Raderad!',
        SUCCESSFULLY_CLOSED_WORKORDER: 'Arbetsordern är sluförd korrekt!',
        SUCCESSFULLY_CLOSED_PROJECT: 'Projektet stängdes korrekt!',
        SUCCESSFULLY_REOPENED_PROJECT: 'Projektet öppnades korrekt!',
        FAILED_TO_SAVE: 'Spara misslyckades!',
        FAILED_TO_SEND_EMAIL: 'Mail kunde inte skickas!',
        FAILED_TO_DELETE: 'Radera misslyckades!',
        FAILED_TO_FETCH: 'Kunde inte hämta uppgifter!',
        FAILED_TO_UPLOAD_FILE: 'Uppladdning misslyckades!',
        FAILED_TO_DOWNLOAD_FILE: 'Ett fel uppstod vid nedladdning av filen.',
        FILE_NOT_FOUND: 'Ingen fil bifogades',
        FAILED_TO_DOWNLOAD_FILE_ID06_CODE_NOT_FOUND: 'ID06 kod hittades inte',
        CUSTOMER_FAILED_TO_GET_INFO: 'Kunde inte hämta uppgifter!',
        FAILED_TO_GET_HISTORY: 'Kunde inte hämta uppgifter!',
        FAILED_TO_INVITE: 'Inbjudan misslyckades!',
        INVITE_SENT: 'Inbjudan skickad!',
        WRONG_CURRENT_PASSWORD: 'Fel nuvarande lösenord',
        PASSWORD_DONT_MATCH: 'Lösenord matchar inte',
        PASSWORD_MIN_CHARACTERS: 'Lösenord måste vara minst 8 tecken.',
        ERROR_DEPARTMENT_HAS_USERS:
            'Kan inte radera. Affärsområdet har användare.',
        USER_REGISTERED:
            'Lösenord sparat. <a href="/">Klicka här</a> för att logga in.',
        INVALID_SESSION: 'Sessionen har gått ut. Vänligen logga in igen.',
        ERROR_MESSAGE_TITLE: 'Något fel uppstod!',
        INFO_MESSAGE_TITLE: 'Information!',
        SUCCESS_MESSAGE_TITLE: 'Lyckad åtgärd!',
        WARNING_MESSAGE_TITLE: 'VARNING!',
        WARNING_PERMISSION_DENIED:
            'Du saknar behörighet för detta..prata med din chef.',
        BUSINESS_OBJECT_EXISTS: 'Objektnummer finns redan!',
        CUSTOMER_EXISTS: 'Kundnummer finns redan!',
        ARTICLE_EXISTS: 'Artikelnummer finns redan!',
        ARTICLE_E_NUMBER_EXISTS: 'E-nummer finns redan på en annan artikel!',
        ARTICLE_GROUP_EXISTS: 'Artikelgruppen finns redan!',
        PRICE_LIST_EXISTS: 'Prislistans namn är redan upptaget!',
        AGREEMENT_EXISTS: 'Avtals-ID finns redan!',
        MODEL_EXISTS: 'Modellen finns redan!',
        SUPPLIER_EXISTS: 'Leverantörsnummer finns redan!',
        DOORAUTOMATIC_EXISTS: 'ID för dörrautomatik finns redan!',
        CONFIRM_UNSAVED_CHANGES:
            'Icke sparade ändringar! Vill du gå miste om dessa?',
        CONFIRM_CLOSE_WORKORDER:
            'Arbetsorder kommer slutföras, vänligen bekräfta.',
        CONFIRM_DELETE_WORKORDER:
            'Arbetsorder kommer raderas! vänligen bekräfta.',
        MIN_TAG_LENGTH_2: 'Minst 2 tecken på tagg.',
        MIN_WORKTYPE_LENGTH_2: 'Minst 2 tecken för arbetsorder-typ.',
        MAX_WORKTYPE_LENGTH_50: 'Max längd på arbetsordertyp är 50 tecken!',
        MIN_STATUS_LENGTH_2: 'Minst 2 tecken för arbetsorder-status.',
        TAG_ALREADY_EXISTS: 'Taggen finns redan.',
        WORKTYPE_ALREADY_EXISTS: 'Arbetsorder-typ finns redan.',
        STATUS_ALREADY_EXISTS: 'Arbetsorder-status finns redan.',
        TAG_CONTAINS_SPECIAL_CHARACTERS:
            'Vänligen använd bara bokstäver och siffror.',
        STATUS_DEFAULT_ERROR: 'Startvärde för status krävs!',
        STATUS_NO_CLOSING_ERROR:
            'Minst en status måste representera slutförande.',
        STATUS_CLOSING_DEFAULT_SAME:
            'Slutförande och startvärde kan inte vara samma!',
        INVALID_PRICE: 'Fel pris. Använd bara siffror.',
        PLEASE_SELECT_ARTICLE: 'Välj artikel',
        CONFIRM_CUSTOMER_ARTICLE_PRICE_DELETE: 'Vill du radera kundpriset?',
        CHANGE_PASSWORD_TEXT: 'Ange nytt lösenord',
        PASSWORD_SUCCESSFULLY_CHANGED:
            'Nytt lösenord sparat! <a href="/">Klicka här</a> för att logga in.',
        REQUEST_PASSWORD_CHANGE_TEXT: 'Ange din e-post för att ändra lösenord.',
        PASSWORD_CHANGE_SUCCESSFULLY_REQUESTED:
            'Instruktion har skickats till din e-post!',
        UNABLE_TO_DELETE:
            'Det går tyvärr inte att radera informationen. Vänligen kontakta Trukks support.',
        ERROR_REQUIRED_FIELD: 'Vänligen fyll i detta obligatoriska fält',
        ERROR_SPACE_NOT_ALLOWED: 'Mellanslag i ID är inte tillåtet',
        ERROR_MAX_LENGTH: 'Max antal tecken är nådd! ({{count}})',
        EMAIL_SENT: 'e-post skickat',
        EMAIL_FAILED: 'e-post misslyckades att skickas',
        ERROR_LOADING_INVOICE: 'Laddning av faktura misslyckades..',
        ARE_YOU_SURE: 'Är du helt säker?',
        EMAIL_SENT_TO: 'e-post skickat till: {{email}}',
        EMAIL_IS_INVALID: 'Felaktig E-post',
        ERROR_HANDLING_APPLY: 'Något gick fel..kontakta Trukks!',
        ERROR_DISCOUNT_PATTERN: 'Rabatt måste vara siffra eller procent',
        TAG_IS_REQUIRED: 'Vänligen välj tagg',
        TIME_REPORT_MAXIMUM_TIME_SPAN_60_DAYS: 'Max 60 dagar kan rapporteras',
        SEARCH_FROM_SIDEBAR: 'Vänligen använd sidomenyn för sökning!',
        AMOUNT_CANT_BE_NEGATIVE: 'Antal får inte vara negativt!',
        STOCK_SUCCESSFULLY_UPDATED:
            'Lagret uppdaterades!. Nytt lagerantal: {{amount}}.',
        WARNING_NETWORK_ERROR_PLEASE_REFRESH:
            'Något gick fel. Vänligen gör refresh på webbläsaren.',
        USER_CREATED: 'Användaren har skapats!',
        FAILED_TO_CREATE_USER: 'Något gick fel. Användaren skapades inte!',
        PROPOSAL_PLEASE_SELECT_CUSTOMER_BEFORE_ACCEPTING:
            'Vänligen ange kund på offerten innan accept.',
        PROPOSAL_SUCCESSFULLY_ACCEPTED:
            'Offerten accepterades och kundorder är skapad!',
        PROPOSAL_SUCCESSFULLY_ACCEPTED_WORKORDER_CREATED:
            'Offerten accepterad och arbetsorder {{workOrderNr}} är skapad!',
        PROPOSAL_SUCCESSFULLY_DELETED: 'Offerten raderades korrekt!',
        CONFIRM_SALES_ORDER_INVOICE:
            'Vänligen bekräfta, ordern kommer nu skickas till ekonomisystemet för skapande av faktura',
        SALES_ORDER_INVOICE_SUCCESSFULLY_CREATED: 'Fakturan skapades korrekt.',
        SALES_ORDER_IS_INVOICED: 'Order är skickad/fakturerad.',
        OBJECT_IS_INACTIVATED_NO_AGREEMENT_INVOICES:
            'Avtalsfakturor skapas INTE när status är inaktiv!',
        PERCENTAGE_CANNOT_BE_OVER_100: 'Rabatt kan inte vara mer än 100%',
        PERCENTAGE_CANNOT_BE_NEGATIVE: 'Rabatt kan inte mindre än 0%',
        AGREEMENT_CONFIGURATION_WARNING_DOUBLE_INVOICING:
            'Vänligen kontrollera inställningen. Dubbel debitering mot kund kan uppstå!',
        AGREEMENT_CONFIGURATION_VALID_FROM_MUST_BE_BEFORE_VALID_TO:
            'Felaktig konfiguration. Giltig fr.o.m måste vara före giltig t.o.m',
        CANNOT_DELETE_WORKORDER_HAS_LOCKED_TIMEREPORTS:
            'Arbetsordern har låsta tidregistreringar och kan inte raderas. Vänligen slutför arbetsordern!',
        HELLO_USER: 'Hej {{name}}!',
        WELCOME_TO_YOUR_STARTPAGE: 'Välkommen till din startsida!',
        PROJECT_FAILED_TO_SET_INCLUDED_HAS_INVOICED_ARTICLES:
            'Kan inte inkluderas, vissa artiklar är reda fakturerade..',
        PROJECT_FAILED_TO_SET_ARTICLE_INCLUDED_ALREADY_ON_INVOICE:
            'Artikelraden är redan inkluderad på en faktura..',
        CONFIRM_CLOSE_PROJECT_INVOICE:
            'Faktureringsunderlaget skickas till ekonomisystemet. Vänligen bekräfta!',
        PROJECT_INVOICE_SUCCESSFULLY_CREATED:
            'Fakturan skapas nu i ekonomisystemet!',
        FAILED_TO_HANDLE_SUPPLIER_INVOICE: 'Fel i ny arbetsorder referens',
        SUCCESSFULLY_HANDLED_SUPPLIER_INVOICE:
            'Ny arbetsorder referens hittades! Tack!',
        ARTICLE_GROUP_CANNOT_DELETE:
            'Ej tillåtet att radera artikelgruppen! Det finns artiklar knutna till artikelgruppen och eller en prislista.',
        PRICE_LIST_CANNOT_DELETE:
            'Ej tillåtet att radera prislistan! Det finns kunder som använder den.',
        REMOVE_MFA_PROVIDER_CONFIRM:
            'Vänligen bekräfta att du vill ta bort 2-faktors autentisieringen med {{serviceProvider}}',
        REGISTER_MFA_PROVIDER_CONFIRM:
            'Vänligen bekräfta att du vill lägga till 2-faktors autentisiering med {{serviceProvider}}. Säkerställ att {{serviceProvider}} är installerad först!',
        REGISTERING_MFA_FAILED:
            '2-faktors autentisiering med {{serviceProvider}} misslyckades!',
        FAILED_TO_REMOVE_MFA_PROVIDER:
            '{{serviceProvider}} kunde inte tas bort!',
        SUCCESSFULLY_REMOVED_MFA_PROVIDER: '{{serviceProvider}} är borttagen!',
        SUCCESSFULLY_REGISTERED_MFA:
            '2-faktors autentisiering är aktiverad!. Skanna QR-koden nedan mec {{serviceProvider}}',
        CONFIRM_SALES_ORDER_MANUAL_RECEIPT_IS_OK:
            'Bekräfta om kontantkvittot är utskrivet korrekt.',
        SALES_ORDER_MANUAL_RECEIPT_SUCCESSFUL:
            'Kontant-ordern avslutades korrekt!',
        FILE_UPLOAD_SUCCESSFUL_PROCESSING_FILE:
            'Filen laddades in korrekt och bearbetas.',
        SUPPLIER_INVENTORY_CONFIRM_FORMAT_OF_FIRST_10_ROWS:
            'Vänligen bekräfta att formatet på artikelfilen är korrekt',
        FAILED_TO_MOVE_FILE: 'Kunde inte flytta filen',
        CUSTOMER_INFO_INVOICES_WILL_NOT_BE_GENERATED:
            'Fakturor kommer inte skickas till ekonomisystemet!',
        WORK_ORDER_CONFIRM_CLOSE_CUSTOMER_IS_CONFIGURED_TO_NOT_SEND_TO_ERP:
            'Fakturor kommer inte skickas till ekonomi-systemet enligt kundens inställningar!',

        SUPPLIER_INVENTORY_LAST_FILE_UPLOAD:
            'Artikelpriser uppdaterades från importerad fil {{time}}.',
        SUPPLIER_INVENTORY_LAST_FILE_UPLOAD_NOT_FOUND:
            'Ingen prisfil har laddats upp än..',
        VALIDATION_DEVIATION_CATEGORY_NAME_BETWEEN_2_50_CHARS:
            'Namn kan vara max 50 tecken',
        DEVIATION_SUCCESSFULLY_CLOSED: 'Avvikelsen stängdes korrekt',
        PROJECT_RESOURCE_DATE_ALREADY_SELECTED: 'Datumen är upptagna!',
        OBJECT_AGREEMENT_VALID_FROM_DATE_NOT_SET:
            'Avtals- fakturor/service startar efter detta datum!',
        OBJECT_AGREEMENT_VALID_TO_DATE_SET:
            'Avtals- fakturor/service upphör efter detta datum!',
        WORK_ORDER_USER_DISTRIBUTION_USER_ALREADY_ADDED:
            'Användare finns redan!',
        PROPOSAL_REOPEN_ARE_YOU_SURE:
            'Är du säker att du vill åter-öppna offerten?',
        PROPOSAL_REOPEN_WORKORDER_WILL_NOT_BE_DELETED:
            'Behöver du radera tidigare skapad arbetsorder?',
        PROPOSAL_REOPEN_SALESORDER_WILL_NOT_BE_DELETED:
            'Behöver du radera tidigare skapad kundorder?',
        WORK_ORDER_REOPEN_ARE_YOU_SURE:
            'Är du säker på att du vill återöppna arbetsordern?',
        WORK_ORDER_REOPEN_INVOICE_WILL_BE_DELETED:
            'Fakturan i Trukks kommer raderas.',
        WORK_ORDER_REOPEN_INVOICE_IN_ERP_WILL_NOT_BE_DELETED:
            'Fakturan i ekonomisystemet måste raderas separat!',
        WORKORDER_SERVICEREPORT_REOPEN_ARE_YOU_SURE: 'Är du säker att du vill återöppna rapporten?', 
        WORKORDER_SERVICEREPORT_REOPEN_FILE_WILL_NOT_BE_DELETED:
            'Tidigare automatiskt skapade filer måste hanteras manuellt!', 
        ADD_LINK_REQUIRED_FIELDS: 'Länk och namn måste fyllas i',
        FAILED_TO_AUTHENTICATE_MS_AUTH:
            'Autentisiering med Microsoft misslyckades',
        SUCCESSFULLY_SCHEDULED_WORK_ORDERS:
            'Arbetsorder skapas om någon minut!',
        COMPANY_HOLIDAY_DATE_ALREADY_USED: 'Semesterdagen finns redan!',
        WORK_ORDER_BY_TAGS_KPI_ERROR_MESSAGE:
            'Något gick fel, data kunde inte hämtas. Försök igen senare..',
        WORK_ORDER_REOPEN_SUCCESSFUL: 'Arbetsorder återöppnades korrekt!',
        CANNOT_CLOSE_PROJECT_INVOICE_CUSTOMER_IS_POTENTIAL_CUSTOMER:
            'Ej tillåtet! Kunden saknar kundnummer!',
        CANNOT_CLOSE_PROPOSAL_WORKORDER_CUSTOMER_NUMBER_MISSING:
            'Ej tillåtet! Kunden saknar kundnummer!',
        CANNOT_CLOSE_PROPOSAL_WORKORDER_OBJECT_IS_MISSING:
            'Ej tillåtet, välj objekt först.',
        CANNOT_CLOSE_PROPOSAL_SALES_ORDER_CUSTOMER_NUMBER_MISSING:
            'Ej tillåtet! Kunden saknar kundnummer!',
        CANNOT_CLOSE_WORK_ORDER_CUSTOMER_NUMBER_MISSING:
            'Ej tillåtet! Kunden saknar kundnummer!',
        CANNOT_CLOSE_SALES_ORDER_CUSTOMER_NUMBER_MISSING:
            'Ej tillåtet! Kunden saknar kundnummer!',
        CANNOT_CLOSE_SALES_ORDER_REFERENCE_USER_REQUIRED: 'Ordern kan inte stängas, vår referens saknas!',
        CONFIRM_SUPPLIER_INVOICE_APPROVE_PAYMENT: 'Är du säker att du vill godkänna för betalning?',
        USER_REQUIRED: 'Vänligen välj dag',
        DAY_OF_WEEK_REQUIRED: 'Vänligen välj användare',
        PROJECT_HAS_OPEN_WORK_ORDERS: 'Projektet har öppna arbetsordrar, vänligen avsluta allt pågående arbete innan projektet stängs',
        WORK_ORDER_CHANGE_CUSTOMER_INFO: 'Priser på befintliga artiklar ändras / uppdateras inte!', 
        WORK_ORDER_CHANGE_OBJECT_INFO: 'Priser på befintliga artiklar ändras / uppdateras inte!',
        WORK_ORDER_ASSIGNEE_REQUIRED: 'Minst en ansvarig är obligatoriskt!',
        WORK_ORDER_CAN_NOT_REMOVE_SCHEDULED_USER: 'Anvarig person får inte raderas!',
        COPIED_TO_CLIPBOARD: 'Kopierat känslig kod till urklipp',
        SERVICE_REPORT_ALL_GENERATED_PDFS_WILL_BE_PUBLISHED: 'Alla PDF filer från checklistor kommer automatiskt publiseras i Kundens Kund-Zon!',
    },
    helpers: {
        AGREEMENT_WORKORDER_GENERATION_DAY_HELPER:
            'Välj vilken dag i månaden avtalsservice ska skapas',
        AGREEMENT_WORKORDER_GENERATION_INTERVAL_HELPER:
            'Välj hur ofta avtalsservice ska skapas',
        AGREEMENT_INVOICE_GENERATION_DAY_HELPER:
            'Välj vilken dag i månaden avtalsfaktura ska skapas',
        AGREEMENT_INVOICE_GENERATION_INTERVAL_HELPER:
            'Välj hur ofta avtalsfaktura ska skapas',
        AGREEMENT_ARTICLES_ARE_ADDED_TO_WORKORDER:
            'Artiklar inkluderas automatiskt på genererade avtals-arbetsordrar',
        INVOICE_MIN_ID_LEN_VALIDATION: 'Vänligen ange ett positivt nummer',
        CUSTOMER_EMAIL_HELP_TEXT: 'e-post för fakturor',
        CUSTOMER_INVOICE_MARKING_HELP_TEXT:
            'Denna märkning sätts på kundens alla arbetsordrar!',
        PRIMARY_CUSTOMER_ADDRESS: 'Primär faktura-adress',
        PRIMARY_CUSTOMER_ADDRESS_FOR_ERP:
            'Denna adress uppdateras till ekonomisystemet',
        OBJECT_AGREEMENT_INVOICE_MARKING_HELPTEXT:
            'Denna märkning läggs automatiskt på alla nya arbetsordrar till denna anläggning och visas på avtalsfakturan',
        REFERENCE_CONTACT_FOR_ERP:
            'Visas som `er referens` på fakturan i FortNox',
        FIELD_WILL_BE_DISPLAYED_ON_WORKORDER_IF_FILLED_IN:
            'Visas på arbetsorder om något är ifyllt',
        WORKORDER_THIS_ARTICLE_IS_INVOICED_AUTOMATICALLY:
            'Vald artikel debiteras automatisk utifrån rapporterad tid på arbetsorder',
        PROJECT_SETTINGS_DEFAULT_INVOICE_MARKING_HELPTEXT:
            'Denna märkning sätts som standard på projektets fakturor.',
        CUSTOMER_BROADCAST_EMAIL_HELP_TEXT:
            'Denna epost används för att skicka mail till kund för vaje ny arbetsorder',
        PROJECT_INVOICING_STATUS_ONLY_FOR_IN_SCOPE:
            'Bara projektets artiklar, ÄTA är exkluderat.',
        PROJECT_OUTCOME_EXTRA_SCOPE_ONLY_APPROVED:
            'Enbart godkända ÄTA är inkluderade',
        PROJECT_OUTCOME_EXTRA_SCOPE_ALL: 'Alla kostnader & intäkter är inkluderade',
    },
    invoice: {
        INVOICE_INFORMATION: 'Faktura avseende',
        INVOICE_DESCRIPTION: 'Beskrivning av faktura',
        ISSUED_WITH_REVERSE_VAT: 'Fakturan omfattas av omvänd moms',
    },
    acl: {
        PERMISSION_GROUP_WORK_ORDER: 'Arbetsorder',
        PERMISSION_GROUP_INVENTORY: 'Register',
        PERMISSION_GROUP_OTHER: 'Övrigt',
        PERMISSION_SETTINGS_READ: 'Se inställningar',
        PERMISSION_SETTINGS_IMPORT_EXPORT: 'Se Inställningar - Export & Import',
        PERMISSION_USERS_READ: 'Se användare',
        PERMISSION_USERS_EDIT: 'Ändra användare',
        PERMISSION_INSTANCE_READ: 'Se företagsuppgifter',
        PERMISSION_INSTANCE_EDIT: 'Ändra företagsuppgifter',
        PERMISSION_CUSTOMERS_READ: 'Se kunduppgifter',
        PERMISSION_CUSTOMERS_CREATE: 'Skapa Kund',
        PERMISSION_CUSTOMERS_CREATE_POTENTIAL: 'Skapa potentiell kund',
        PERMISSION_CUSTOMERS_EDIT: 'Ändra kunduppgifter',
        PERMISSION_CUSTOMERS_DELETE: 'Radera kund',
        PERMISSION_CUSTOMERS_FILES: 'Hantera dokument under Kund',
        PERMISSION_CUSTOMERS_FILES_READ: 'Se dokument under Kund',
        PERMISSION_BUSINESS_OBJECTS_READ: 'Se objekt',
        PERMISSION_BUSINESS_OBJECTS_EDIT: 'Ändra objekt',
        PERMISSION_BUSINESS_OBJECTS_DELETE: 'Radera objekt',
        PERMISSION_BUSINESS_OBJECT_SERVICE_LOG: 'Visa/ändra i dagboken',
        PERMISSION_BUSINESS_OBJECT_FILES: 'Hantera dokument under Objekt',
        PERMISSION_BUSINESS_OBJECT_FILES_READ: 'Se dokument under Objekt',
        PERMISSION_BUSINESS_OBJECT_AGREEMENT_EDIT: 'Ändra Avtal under Objekt',
        PERMISSION_BUSINESS_OBJECTS_CODES_VIEW: 'Se känsliga koder',
        PERMISSION_BUSINESS_OBJECTS_CODES_EDIT: 'Ändra känsliga koder',
        PERMISSION_BUSINESS_OBJECTS_CODES_VIEW_LOG: 'Se logg för känsliga koder', 
        PERMISSION_ARTICLES_CREATE: 'Skapa artikel',
        PERMISSION_ARTICLES_READ: 'Se artikelinformation',
        PERMISSION_ARTICLES_PRICE: 'Se artikelpriser',
        PERMISSION_ARTICLES_EDIT: 'Ändra artiklar',
        PERMISSION_ARTICLES_DELETE: 'Radera artikel',
        PERMISSION_ARTICLES_SETTINGS:
            'Se och ändra artiklar under Inställningar',
        PERMISSION_ARTICLE_FILES_READ: 'Se dokument under artiklar',
        PERMISSION_ARTICLE_FILES_MANAGE: 'Ändra dokument under artiklar',
        PERMISSION_ACL_READ: 'Se behörigheter',
        PERMISSION_ACL_EDIT: 'Ändra behörigheter',
        PERMISSION_WORKORDERS_READ: 'Se arbetsorder',
        PERMISSION_WORKORDERS_EDIT: 'Ändra arbetsorder',
        PERMISSION_WORKORDERS_EDIT_INTERNAL_INFO:
            'Ändra intern info på arbetsorder',
        PERMISSION_WORKORDERS_CREATE: 'Skapa arbetsorder',
        PERMISSION_WORKORDERS_CREATE_PROJECT_EXTRA_WORK:
            'Skapa ÄTA arbetsorder',
        PERMISSION_WORKORDERS_ACCEPT_PROJECT_EXTRA_WORK:
            'Godkänna / Avböja ÄTA i projekt',
        PERMISSION_WORKORDERS_CLOSE: 'Slutföra arbetsorder',
        PERMISSION_WORKORDERS_REOPEN: 'Åter-öppna arbetsorder',
        PERMISSION_WORKORDERS_COPY: 'Skapa kopia av arbetsordern',
        PERMISSION_WORKORDERS_DELETE: 'Radera arbetsorder',
        PERMISSION_WORKORDERS_REPORT:
            'Rapportera tid och text för sammanställning på arbetsorder',
        PERMISSION_WORKORDERS_REPORT_ARTICLES:
            'Lägga till artiklar på arbetsorder',
        PERMISSION_WORKORDERS_EDIT_ARTICLE_DISTRIBUTIONS:
            'Ändra TB fördelning av artikel på arbetsorder',
        PERMISSION_WORKORDERS_TIMEREPORTS_SELECT:
            'Bifoga tidrapporter i bilaga till faktura',
        PERMISSION_WORKORDERS_READ_COST: 'Se TB/TG av arbetsorder',
        PERMISSION_MODELS_READ: 'Se typ',
        PERMISSION_MODELS_EDIT: 'Ändra typ',
        PERMISSION_MODELS_DELETE: 'Radera objekt-typ',
        PERMISSION_MODEL_FILES_READ: 'Se dokument under objekt-typ',
        PERMISSION_MODEL_FILES_MANAGE: 'Ändra dokument under objekt-typ',
        PERMISSION_AGREEMENTS_READ: 'Se avtalstyp',
        PERMISSION_AGREEMENTS_EDIT: 'Ändra avtalstyp',
        PERMISSION_AGREEMENTS_DELETE: 'Radera avtalstyp',
        PERMISSION_SUPPLIERS_READ: 'Se leverantör',
        PERMISSION_SUPPLIERS_EDIT: 'Ändra leverantör',
        PERMISSION_SUPPLIERS_DELETE: 'Radera leverantör',
        PERMISSION_SUPPLIER_ORDERS_EDIT: 'Ändra leverantörers beställningar',
        PERMISSION_INVOICES_READ: 'Visa faktura',
        PERMISSION_REPORTS_READ: 'Visa statistik',
        PERMISSION_WORKORDERS_LOCK: 'Låsa arbetsorder',
        PERMISSION_TIMEREPORTS_READ: 'Visa andras tidrapporter',
        PERMISSION_TIMEREPORTS_EDIT:
            'Se detaljer på Övrig tid för andra användare',
        PERMISSION_ON_CALL_REPORTS_EDIT: 'Ändra jour-schema',
        PERMISSION_DISTANCEREPORTS_READ:
            'Se KM rapportering för andra användare',
        PERMISSION_COMPENSATIONREPORTS_READ:
            'Se ersättningar för andra användare',
        PERMISSION_WORKORDERS_FILES: 'Hantera dokument på arbetsorder',
        PERMISSION_PROJECTS_READ: 'Visa projekt',
        PERMISSION_PROJECTS_EDIT: 'Skapa och ändra projekt',
        PERMISSION_PROJECTS_DELETE: 'Radera projekt',
        PERMISSION_PROJECTS_FILES: 'Hantera dokument under Projekt',
        PERMISSION_PROJECTS_CLOSE_NEGATIVE_INVOICE:
            'Skicka projektfakturor med negativt belopp',
        PERMISSION_PROJECTS_REOPEN: 'Återöppna projekt',
        PERMISSION_SALES_ORDER_READ: 'Visa försäljningsorder',
        PERMISSION_SALES_ORDER_EDIT: 'Skapa och ändra försäljningsorder',
        PERMISSION_SALES_ORDER_DELETE: 'Radera försäljningsorder',
        PERMISSION_PROPOSAL_READ: 'Visa offerter',
        PERMISSION_PROPOSAL_EDIT: 'Ändra offerter',
        PERMISSION_PROPOSAL_DELETE: 'Radera offert',
        PERMISSION_PROPOSAL_FILES: 'Hantera dokument på Offert',
        PERMISSION_PROPOSAL_REOPEN: 'Återöppna stängd Offert',
        PERMISSION_SECURITY_BO_DOCUMENT_READ: 'Se anläggnings specifikationer',
        PERMISSION_SECURITY_BO_DOCUMENT_EDIT:
            'Ändra anläggnings specifikationer',

        PERMISSION_WORKORDERS_EDIT_ID06_CODE: 'Hantera ID06 kod på arbetsorder',
        PERMISSION_MESSAGE_BOARD_POST_EDIT: 'Ändra meddelanden på startsida',
        PERMISSION_DASHBOARD_READ: 'Visa överblickspanel',
        PERMISSION_DASHBOARD_OPEN_WORKORDERS_KPI_EDIT:
            'Ändra pris/timme och debiteringsfaktor på startsidan',
        PERMISSION_DEVIATIONS_READ: 'Se Avvikelser',
        PERMISSION_DEVIATIONS_EDIT: 'Ändra Avvikelser',
        PERMISSION_DEVIATIONS_CREATE: 'Skapa Avvikelser',
        PERMISSION_DEVIATIONS_CLOSE: 'Stänga Avvikelser',
        PERMISSION_DEVIATIONS_DELETE: 'Radera Avvikelser',
        PERMISSION_DOORAUTOMATICS_READ: 'Se dörrautomatiker',
        PERMISSION_DOORAUTOMATICS_EDIT: 'Ändra dörrautomatiker',
        PERMISSION_DOORAUTOMATICS_DELETE: 'Radera dörrautomatik',
        PERMISSION_DOORAUTOMATICS_FILES: 'Ändra filer under Dörrautomatik',
        PERMISSION_DOORAUTOMATICS_FILES_READ: 'Se filer under Dörrautomatik',
        PERMISSION_WORKORDER_DOORAUTOMATICS_EDIT: 'Ändra kopplad dörrautomatik på arbetsorder',
        PERMISSION_CUSTOMER_ZONE_EDIT_USERS: 'Ändra användare till kundzon',
        PERMISSION_CUSTOMER_ZONE_PUBLISH_FILES: 'Publisera & av-publisera dokument för Kund-Zon',
        PERMISSION_SUPPLIER_INVOICES_READ: 'Se Attest av leverantörsfakturor',
        PERMISSION_SUPPLIER_INVOICES_EDIT: 'Hantera Attestering av leverantörsfakturor',
        PERMISSION_SUPPLIER_INVOICES_READ_ALL: 'Se alla leverantörsfakturor för attestering', 
        PERMISSION_WORKORDERS_SERVICEREPORT_REOPEN: 'Återköppna stängda rapporter/checklistor',
    },
    customerSpecifications: {
        invoiceSubjectTemplate: 'Ämnesrad i e-post för fakturor',
    },
    workorderhistory: {
        close: 'Slutförd',
        edit: 'Ändra',
        'edit.internal': 'Ändra',
        'edit.customer': 'Kund ändrad',
        'edit.object': 'Objekt ändrad',
        create: 'Skapa',
        delete: 'Radera',
        'agreement.create': 'Automatiskt skapad',
        lock: 'Låst',
        unlock: 'Lås upp',
        add_articles: 'Artiklar från leverantör via EDI',
    },
    invoicehistory: {
        create: 'Skapad',
        email: 'skickad med e-post',
        'erp.created.visma': 'Visma',
        'erp.created.wint': 'Wint',
        'erp.created.magenta': 'Magenta',
        'erp.created.visma-e': 'Visma eAccounting',
        'erp.created.fortnox': 'Fortnox',
        'erp.created.quickbooks': 'QuickBooks',
        visma: 'Visma',
        wint: 'Wint',
        magenta: 'Magenta',
        'visma-e': 'Visma eAccounting',
        fortnox: 'Fortnox',
        quickbooks: 'QuickBooks',
    },
    stats: {
        NO_DATA_FOUND: 'Underlag saknas',
        BAR_CHART: 'Staplar',
        LINE_CHART: 'Linje',

        SERVICE_DISTRIBUTION: 'Fördelning av service',
        SERVICE_DISTRIBUTION_EXCEL_EXPORT_FILENAME: 'Planerad service',
        PLANNED_SERVICES: '{{count}} planerad avtals-service',
        PLANNED_SERVICES_plural: '{{count}} planerade avtals-service-besök',
        CLICK_TO_VIEW_DETAILS: 'Klicka för att visa mer detaljer',

        INVOICING: 'Fakturerat',
        INVOICING_LAST_12_MONTHS: 'Senaste 12 månaderna',
        INVOICING_THIS_YEAR: 'Nuvarande år',
        INVOICING_WORK_ORDERS: 'Arbetsorder',
        INVOICING_AGREEMENTS: 'Avtal',
        INVOICING_ALL: 'Allt',
        INVOICING_SUMMED: 'Summerat',

        ACTIVE_WORK_ORDERS: 'Öppna arbetsordrar',
        ACTIVE_WORK_ORDERS_BY_STATUS: 'Per status',
        NUMBER_OF_WORKORDERS: '{{count}} Arbetsordrar',

        LARMKLASS: 'Antal anläggningar per Larmklass',

        OPEN_WORKORDERS_TO_INVOICE: 'Öppna arbetsordrar ',

        OPEN_WORKORDERS_ON_SERVICE: 'Arbetsorders inom service',
        OPEN_WORKORDERS_TO_INVOICE_TOTAL_PRICE:
            'Totalt försäljningspris: {{price}}',
        OPEN_WORKORDERS_TO_INVOICE_TOTAL_COUNT:
            'Totalt Antal arbetsordrar: {{count}}',
        OPEN_WORKORDERS_TO_INVOICE_TOTAL_TIME: '{{time}} rapporterade timmar',
        OPEN_WORKORDERS_TO_INVOICE_WORKORDER_PRICE:
            'Försäljningspris: {{price}}',
        OPEN_WORKORDERS_TO_INVOICE_WORKORDER_TIME: 'Rapporterad tid: {{time}}',
        OPEN_WORKORDERS_TO_INVOICE_WORKORDER_COST: 'Total kostnad: {{price}}',
        OPEN_WORKORDERS_TO_INVOICE_TOTAL_COST:
            'Total kostnad att fakturera: {{price}}',
        OPEN_WORKORDERS_TO_INVOICE_PRESENTING_DAYS: 'Presenterar dagar',
        OPEN_WORKORDERS_TO_INVOICE_PRESENTING_DAYS_TO: 'till', 
        OPEN_WORKORDERS_TO_INVOICE_PRESENTING_DAYS_TOTAL: '(av {{count}} dagar)', 
        OPEN_WORKORDERS_ON_PROJECT: 'I projekt',
        OPEN_WORKORDERS_TO_INVOICE_TOTAL_PROJECT_WORK_ORDERS_COUNT:
            '{{count}} öppna arbetsordrar',

        NON_MATCHING_SUPPLIER_ORDERS:
            'Leverantörers beställningar med fel AO-referens',
        NON_MATCHING_SUPPLIER_ORDERS_PAST_2_MONTHS: 'Senaste 2 månaderna',
        NON_MATCHING_SUPPLIER_ORDERS_SUPPLIER_NAME: 'Leverantör',
        NON_MATCHING_SUPPLIER_ORDERS_ARRIVED_TO_TRUKKS: 'Anlände till Trukks',
        NON_MATCHING_SUPPLIER_ORDERS_SUPPLIER_ORDER_ID:
            'Leverantörens Order Nr.',
        NON_MATCHING_SUPPLIER_ORDERS_ORDER_REFERENCE: 'Referens',
        NON_MATCHING_SUPPLIER_ORDERS_NO_ORDERS:
            'Inga beställningar med felaktig referens hittades',
        NON_MATCHING_SUPPLIER_ORDERS_ADJUST_ORDER: 'Ändra referensen',
        NON_MATCHING_SUPPLIER_ORDERS_ORDER_WILL_BE_REPROCESSED:
            'Beställningens nya referens processas..',
        NON_MATCHING_SUPPLIER_ORDERS_DISCARD_ORDER: 'Släng underlaget',
        NON_MATCHING_SUPPLIER_ORDERS_TOTAL_COST: 'Total kostnad',
        NON_MATCHING_SUPPLIER_ORDERS_LIST_OPTION: 'Orderreferens & värde',
        NON_MATCHING_SUPPLIER_ORDERS_DETAILS_OPTION: 'Orderdetaljer',
        OPEN_PROJECTS_KPI_TOTAL_CONTRACTS_LABEL: 'Kontraktsumma totalt',
        OPEN_PROJECTS_KPI_TOTAL_CONTRACTS:
            '{{price}} på {{total}} öppna projekt',
        OPEN_PROJECTS_KPI_TOTAL_INVOICED_LABEL: 'Totalt fakturerat',
        OPEN_PROJECTS_KPI_TOTAL_INVOICED:
            '{{price}} ({{percent}} av total kontraktsumma)',
        OPEN_PROJECTS_KPI_TOTAL_LEFT_TO_INVOICE_LABEL:
            'Totalt kvar att fakturera',
        OPEN_PROJECTS_KPI_TOTAL_LEFT_TO_INVOICE:
            '{{price}} ({{percent}} av total kontraktsumma)',
        OPEN_PROJECTS_KPI_ONGOING_COST_LABEL: 'Kostnader totalt',
        OPEN_PROJECTS_KPI_ONGOING_COST: '{{price}}',
        OPEN_PROJECTS_KPI_ONGOING_COST_X_NOT_REPORTED: '{{count}} saknas',
        OPEN_PROJECTS_KPI_ONGOING_COST_NOT_INVOICED_LABEL:
            'Ej fakturerade kostnader',
        OPEN_PROJECTS_KPI_ONGOING_COST_NOT_INVOICED: '{{price}}',
        OPEN_PROJECTS_KPI_BIGGEST_CONTRACT_LABEL: 'Största kontrakt',
        OPEN_PROJECTS_KPI_BIGGEST_CONTRACT: '{{price}} ({{projectName}})',
        OPEN_PROJECTS_KPI_AVERAGE_CONTRACT_LABEL: 'Medelvärde på kontrakt',
        OPEN_PROJECTS_KPI_AVERAGE_CONTRACT: '{{price}}',
        OPEN_PROJECTS_KPI_RESET_DAY_OF_MONTH:
            'Nollställ kostnader från projekt dag nr',
        OPEN_PROJECTS_INCOME_KPI_HEADER: 'Projektuppföljning',
        OPEN_PROJECTS_INCOME_KPI_INVOICED: 'Fakturerat',
        OPEN_PROJECTS_INCOME_KPI_ONGOING_COST: 'Total pågående kostnad',
        OPEN_PROJECTS_INCOME_KPI_WEEKS_OPEN: 'Veckor',

        KPI_CLOSED_WORKORDERS_BY_TYPE_HEADER: 'Stängda arbetsordrar per typ',
        KPI_CLOSED_WORKORDERS_BY_TYPE_COUNT_OF_WORKORDERS: 'Antal',
        KPI_ONBOARDED_AND_LOST_OBJECTS_TITLE: 'Nya och tappade anläggningar',
        ONBOARDED_OBJECTS: 'Nya',
        LOST_OBJECTS: 'Tappade',
        STATS_ACCRUED_INCOME: 'Upplupna intäkter',
        STATS_ACCRUED_INCOME_DISPLAYED_UNTIL: 'T.o.m {{date}}',
        STATS_ACCRUED_INCOME_FILE_NAME: 'Upplupen intäkt', 
        STATS_ERP_SUPPLIER_INVOICES_CARD_HEADER: 'Leverantörskostnader',

        WORK_ORDER_LEAD_TIME_PERIOD: 'Vald period',
        WORK_ORDER_LEAD_TIME_WORK_TYPE: 'Arbetsorder-typ',
        WORK_ORDER_LEAD_TIME_KPI_HEADER: 'Genomloppstid',
        WORK_ORDER_LEAD_TIME_DAYS_OPEN: 'Antal dagar',
        WORK_ORDER_LEAD_TIME_COUNT_OF_ORDERS: 'Antal',
        WORK_ORDER_LEAD_TIME_PERIOD_TIME_SPAN_VALIDATION_MESSAGE: 'Vald period måste vara minst 10 dagar och mest 1 år',
        
        CLOSED_SALES_ORDER_PRICING_WEEKLY_HEADER: 'Order: Försäljning och marginal',
        CLOSED_WORK_ORDER_PRICING_WEEKLY_HEADER: 'Arbetsorder: Försäljning och marginal',
        TOTAL_SALES_PRICE: 'Totalt',
        TOTAL_MARGIN: 'Marginal',
    },
    suppliers: {
        elektroskandia: 'Elektroskandia',
        solar: 'Solar',
        copiax: 'Copiax',
        ahlsell: 'Ahlsell',
        axsnordic: 'AXS Nordic',
        rexel: 'Rexel',
        dafo: 'Dafo',
        extronic: 'Extronic',
        teletec: 'Teletec',
    },
    erp: {
        INVOICE_NR_HANDLED_BY: 'Fakturan hanteras i {{name}}',
        INVOICE_WAS_NOT_CREATED_IN_ERP: 'Faktura skapades INTE i {{name}}!',
        INVOICE_STATUS_IN_ERP_UNKNOWN: 'Faktura status i {{erpName}}: okänd',
        INVOICE_CREATED_IN_ERP: 'Faktura i {{erpName}}: skapad',
        INVOICE_CREATED_IN_ERP_WITH_ID: 'Faktura i {{erpName}}: {{id}}',
        INVOICE_WAS_NOT_CREATED_IN_ERP_ZERO_SUM:
            'Faktura skapades inte i {{erpName}} ( 0 kr )',
        INVOICE_WAS_NOT_CREATED_IN_ERP_CUSTOMER_CONF:
            'Faktura skapades inte i {{erpName}} (inställning på Kund)',
        visma: 'Visma',
        fortnox: 'Fortnox',
        magenta: 'Magenta',
        'visma-e': 'Visma e-economy',
        wint: 'Wint',
        quickbooks: 'QuickBooks',
    },
    files: {
        ROOT_FOLDER: 'Bakåt..',
        BACK_TO: 'Tillbaka till',
    },
    sbsc: {
        CERTIFICATE_NUMBER: 'SBSC certifikat',
        VALID_TO: 'Giltigt till',
        ARTICLE_GROUP: 'Produkt',
        ARTICLE_DESCRIPTION: 'Beskrivning',
        MANUFACTURER_NAME: 'Tillverkare',
        MANUFACTURER_MOBILE: 'Mobil',
        MANUFACTURER_PHONE: 'Telefon',
        MANUFACTURER_EMAIL: 'E-post',
        ALARM_CLASS: 'Larmklass',
        E_NUMBER: 'E-nummer',
        ARTICLE_REGULATION: 'Regelverk',
        CURRENT_AT_REST: 'Ström vid vila',
        CURRENT_AT_ALARM: 'Ström vid larm',
        ENVIRONMENT_CLASS: 'Miljöklass',
        CERTIFICATE_SEQUENCE: 'Certifieringsordning',
        MESSAGE_NO_PRODUCT_IMAGE: 'Ingen produkt bild.',
        USING_PROLONGED_VALID_TO_DATE:
            'Produkten har förlängt certifieringsdatum',
        VIEW_ON_SBSC_WEBSITE: 'Visa på SBSC hemsida',
    },
    importexport: {
        FILE_NAME: 'Filnamn',
        ARTICLE_EXPORT_NAME: 'Artiklar',
        CUSTOMER_EXPORT_NAME: 'Kunder',
        OBJECT_EXPORT_NAME: 'Objekt',
        UPCOMING_AGREEMENT_INVOICES_EXPORT_NAME: 'Avtalsfakturor',
    },
    countries: {
        AF: 'Afganistan', 
        AX: 'Åland',
        AL: 'Albanien',
        DZ: 'Algeriet',
        AS: 'Amerikanska Samoa',
        AD: 'Andorra',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarktis',
        AG: 'Antigua och Barbuda',
        AR: 'Argentina',
        AM: 'Armenien',
        AW: 'Aruba',
        AU: 'Australien',
        AT: 'Österrike',
        AZ: 'Azerbajdzjan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Vitryssland',
        BE: 'Belgien',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Bolivia',
        BQ: 'Bonaire, Sint Eustatius och Saba',
        BA: 'Bosnien och Hercegovina', 
        BW: 'Botswana', // missing from here..
        BV: 'Bouvet Island',
        BR: 'Brasilen',
        IO: 'British Indian Ocean Territory',
        BN: 'Brunei Darussalam',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        CV: 'Cabo Verde',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        KY: 'Cayman Islands',
        CF: 'Central African Republic',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CX: 'Christmas Island',
        CC: 'Cocos (Keeling) Islands',
        CO: 'Colombia',
        KM: 'Comoros',
        CG: 'Congo',
        CD: 'Congo, Democratic Republic of the',
        CK: 'Cook Islands',
        CR: 'Costa Rica',
        CI: 'Côte d\'Ivoire',
        HR: 'Kroatien',
        CU: 'Kuba',
        CW: 'Curaçao',
        CY: 'Cyprus',
        CZ: 'Czechia',
        DK: 'Danmark',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        EC: 'Ecuador',
        EG: 'Egypten',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        SZ: 'Eswatini',
        ET: 'Ethiopia',
        FK: 'Falkland Islands (Malvinas)',
        FO: 'Faroe Islands',
        FJ: 'Fiji',
        FI: 'Finland',
        FR: 'Frankrike',
        GF: 'French Guiana',
        PF: 'French Polynesia',
        TF: 'French Southern Territories',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgia',
        DE: 'Tyskland',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GR: 'Greece',
        GL: 'Grönland',
        GD: 'Grenada',
        GP: 'Guadeloupe',
        GU: 'Guam',
        GT: 'Guatemala',
        GG: 'Guernsey',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        HM: 'Heard Island and McDonald Islands',
        VA: 'Holy See',
        HN: 'Honduras',
        HK: 'Hong Kong',
        HU: 'Hungary',
        IS: 'Island',
        IN: 'Indien',
        ID: 'Indonesien',
        IR: 'Iran',
        IQ: 'Irak',
        IE: 'Ireland',
        IM: 'Isle of Man',
        IL: 'Israel',
        IT: 'Italen',
        JM: 'Jamaica',
        JP: 'Japan',
        JE: 'Jersey',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KP: 'Korea (Democratic Peoples Republic of) ',
        KR: 'Korea, Republic of',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: 'Lao Peoples Democratic Republic',
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libya',
        LI: 'Liechtenstein',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        MO: 'Macao',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MU: 'Mauritius',
        YT: 'Mayotte',
        MX: 'Mexico',
        FM: 'Micronesia (Federated States of)',
        MD: 'Moldova, Republic of',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MS: 'Montserrat',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Nederländerna',
        NC: 'New Caledonia',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        NF: 'Norfolk Island',
        MK: 'North Macedonia',
        MP: 'Northern Mariana Islands',
        NO: 'Norge',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PS: 'Palestine, State of',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines',
        PN: 'Pitcairn',
        PL: 'Polen',
        PT: 'Portugal',
        PR: 'Puerto Rico',
        QA: 'Qatar',
        RE: 'Réunion',
        RO: 'Romania',
        RU: 'Ryssland',
        RW: 'Rwanda',
        BL: 'Saint Barthélemy',
        SH: 'Saint Helena, Ascension and Tristan da Cunha',
        KN: 'Saint Kitts and Nevis',
        LC: 'Saint Lucia',
        MF: 'Saint Martin (French part)',
        PM: 'Saint Pierre and Miquelon',
        VC: 'Saint Vincent and the Grenadines',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'Sao Tome and Principe',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SX: 'Sint Maarten (Dutch part)',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        GS: 'South Georgia and the South Sandwich Islands',
        SS: 'South Sudan',
        ES: 'Spain',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SJ: 'Svalbard and Jan Mayen',
        SE: 'Sverige',
        CH: 'Switzerland',
        SY: 'Syrian Arab Republic',
        TW: 'Taiwan, Province of China',
        TJ: 'Tajikistan',
        TZ: 'Tanzania, United Republic of',
        TH: 'Thailand',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad and Tobago',
        TN: 'Tunisia',
        TR: 'Turkey',
        TM: 'Turkmenistan',
        TC: 'Turks and Caicos Islands',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates',
        GB: 'Storbritannien',
        US: 'USA',
        UM: 'United States Minor Outlying Islands',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela (Bolivarian Republic of)',
        VN: 'Viet Nam',
        VG: 'Virgin Islands (British)',
        VI: 'Virgin Islands (U.S.)',
        WF: 'Wallis and Futuna',
        EH: 'Western Sahara',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe',
    }
};
