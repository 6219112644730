import React from 'react';
import { withTranslation } from 'react-i18next';
import { Search } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class LocalizedSearch extends React.Component {

    render() {
        const cName = `man-noprint${this.props.className ? ` ${this.props.className}` : ''}`;

        return (
            <Search
                className={cName}
                style={{ ...this.props.style }}
                category={this.props.category}
                onFocus={this.props.onFocus}
                fluid={this.props.fluid}
                placeholder={this.props.placeholder}
                icon={this.props.icon}
                results={this.props.results}
                loading={this.props.loading}
                onResultSelect={this.props.onResultSelect}
                onSearchChange={this.props.onSearchChange}
                value={this.props.value}
                aligned={this.props.aligned}
                minCharacters={this.props.minCharacters}
                resultRenderer={this.props.resultRenderer}
                noResultsMessage={this.props.isFetching ? '...' : this.props.t('NO_RESULTS_FOUND')}
                noResultsDescription={this.props.noResultsDescription}
                open={this.props.open} />

        )
    }
}

LocalizedSearch.propTypes = {
    noResultsDescription: PropTypes.string,
    isFetching: PropTypes.bool,
    minCharacters: PropTypes.number,
    icon: PropTypes.string,
    onFocus: PropTypes.func
}

LocalizedSearch.defaultProps = {
    icon: 'search',
    minCharacters: 1
}

export default withTranslation('ui')(LocalizedSearch);