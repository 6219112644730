import React from 'react';
import { Form, Input, Button, Divider } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Alert from '../common/alert';
import { post } from '../../utils/http/HttpClient';
import Loader from '../common/loader';

class RegistrationFormContainer extends React.Component {

    state = {
        token: this.props.rt.replace(' ', '+'),
        password: '',
        passwordRepeat: '',
        isLoading: true,
        error: null,
        validationError: null,
        userRegistered: false
    }

    componentDidMount() {
        this.checkTokenValidity(this.state.token);
    }

    checkTokenValidity = async (token) => {
        try {
            await post('/registration/rt', { token });

            this.setState({
                isLoading: false
            })
        } catch (error) {
            this.setState({
                error,
                isLoading: false
            })
        }
    }

    handleSubmit = async () => {
        if (this.state.password.length < 8) {
            this.setState({
                validationError: this.props.t('messages:PASSWORD_MIN_CHARACTERS')
            });

            return;
        }

        if (this.state.password !== this.state.passwordRepeat) {
            this.setState({
                validationError: this.props.t('messages:PASSWORD_DONT_MATCH')
            });

            return;
        }

        this.setState({
            validationError: null,
            isLoading: true
        });

        try {
            await post('/registration/comp', {
                token: this.state.token,
                password: this.state.password
            });

            this.setState({
                isLoading: false,
                userRegistered: true
            })

        } catch (error) {
            this.setState({
                error,
                isLoading: false
            })
        }
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        const { t } = this.props;

        if (this.state.userRegistered) {
            return (
                <div style={{ textAlign: 'center', justifyContent: 'center' }}>
                    <p dangerouslySetInnerHTML={{ __html: t('messages:USER_REGISTERED') }} />
                </div>
            )
        }

        return (
            <div style={{ opacity: this.state.isLoading ? 0.5 : 1 }}>
                {this.state.isLoading && <Loader />}

                <p dangerouslySetInnerHTML={{ __html: t('WELCOME_TO_TRUKKS_TEXT') }} />

                {this.state.error ?
                    <Alert type="error" message={this.state.error.code === 400 ? t('TOKEN_EXPIRED') : t('SERVER_ERROR')} />
                    :
                    <Form onSubmit={this.handleSubmit} style={{ opacity: this.props.isLoading ? 0.5 : 1 }}>

                        <Form.Field>
                            <label>{t('PASSWORD')}</label>
                            <Input
                                name="password"
                                type="password"
                                placeholder="&#x25cf;&#x25cf;&#x25cf;&#x25cf;&#x25cf;&#x25cf;"
                                value={this.state.password}
                                onChange={this.handleInputChange} />
                        </Form.Field>

                        <Form.Field>
                            <label>{t('PASSWORD_REPEAT')}</label>
                            <Input
                                name="passwordRepeat"
                                type="password"
                                placeholder="&#x25cf;&#x25cf;&#x25cf;&#x25cf;&#x25cf;&#x25cf;"
                                value={this.state.passwordRepeat}
                                onChange={this.handleInputChange} />
                        </Form.Field>

                        <Divider />
                        <Button
                            className="Login-Form-Button man-button-primary"
                            loading={this.state.isLoading}
                            onClick={this.handleSubmit}>
                            {t('CONFIRM')}
                        </Button>
                    </Form>
                }
                {this.state.validationError &&
                    <Alert type="error" message={this.state.validationError} />}
                <a href="/" style={{ margin: '10px 0', textAlign: 'right' }}>{t('ALREADY_HAVE_USER_LOG_IN')}</a>
            </div>
        )
    }
}

RegistrationFormContainer.propTypes = {
    rt: PropTypes.string.isRequired
}

export default RegistrationFormContainer;