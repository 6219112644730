export const STATUS_ACTIVE = 'STATUS_ACTIVE';
export const STATUS_INACTIVE = 'STATUS_INACTIVE';

const statuses = [
    {
        key: STATUS_ACTIVE,
        value: STATUS_ACTIVE,
        t_text: `ui:${STATUS_ACTIVE}`,
    },
    {
        key: STATUS_INACTIVE,
        value: STATUS_INACTIVE,
        t_text: `ui:${STATUS_INACTIVE}`,
    },
];

export const getStatus = (t, status) => {
    if (status === STATUS_INACTIVE) {
        return t('ui:STATUS_INACTIVE');
    }
    
    if (status === STATUS_ACTIVE) {
        return t('ui:STATUS_ACTIVE');
    }

    return ''
}

export const getStatuses = t =>
    statuses.map(s => {
        s.text = t(s.t_text);
        return s;
    });

export const PROPOSAL_STATUS_ACTIVE = 'active';
export const PROPOSAL_STATUS_ACCEPTED = 'accepted';
export const PROPOSAL_STATUS_ACCEPTED_WORK_ORDER = 'accepted-wo';
export const PROPOSAL_STATUS_DECLINED = 'declined';

const proposalStatuses = [
    {
        key: PROPOSAL_STATUS_ACTIVE,
        value: PROPOSAL_STATUS_ACTIVE,
        t_text: 'PROPOSAL_STATUS_ACTIVE',
    },
    {
        key: PROPOSAL_STATUS_ACCEPTED,
        value: PROPOSAL_STATUS_ACCEPTED,
        t_text: 'PROPOSAL_STATUS_ACCEPTED',
    },
    {
        key: PROPOSAL_STATUS_DECLINED,
        value: PROPOSAL_STATUS_DECLINED,
        t_text: 'PROPOSAL_STATUS_DECLINED',
    },
];

export const getProposalStatuses = t =>
    proposalStatuses.map(s => {
        s.text = t(s.t_text);
        return s;
    });


export const WORK_ORDER_SCHEDULE_STATUS_BOTH = 'both';
export const WORK_ORDER_SCHEDULE_STATUS_NOT_SCHEDULED = 'notScheduled';
export const WORK_ORDER_SCHEDULE_STATUS_SCHEDULED = 'scheduled';


const workOrderSchedulingStatuses = [
    {
        key: WORK_ORDER_SCHEDULE_STATUS_BOTH,
        value: WORK_ORDER_SCHEDULE_STATUS_BOTH,
        t_text: 'WORK_ORDER_SCHEDULE_STATUS_BOTH',
    },
    {
        key: WORK_ORDER_SCHEDULE_STATUS_NOT_SCHEDULED,
        value: WORK_ORDER_SCHEDULE_STATUS_NOT_SCHEDULED,
        t_text: 'WORK_ORDER_SCHEDULE_STATUS_NOT_SCHEDULED',
    },
    {
        key: WORK_ORDER_SCHEDULE_STATUS_SCHEDULED,
        value: WORK_ORDER_SCHEDULE_STATUS_SCHEDULED,
        t_text: 'WORK_ORDER_SCHEDULE_STATUS_SCHEDULED',
    },
];

export const getWorkOrderSchedulingStatuses = t =>
    workOrderSchedulingStatuses.map(s => {
        s.text = t(s.t_text);
        return s;
    });


export const DEVIATION_STATUS_OPEN = 'open';
export const DEVIATION_STATUS_CLOSED = 'closed';
export const DEVIATION_STATUS_ALL = 'all';

const deviationStatuses = [
    {
        key: DEVIATION_STATUS_OPEN,
        value: DEVIATION_STATUS_OPEN,
        t_text: 'DEVIATION_STATUS_OPEN',
    },
    {
        key: DEVIATION_STATUS_CLOSED,
        value: DEVIATION_STATUS_CLOSED,
        t_text: 'DEVIATION_STATUS_CLOSED',
    },
    {
        key: DEVIATION_STATUS_ALL,
        value: DEVIATION_STATUS_ALL,
        t_text: 'DEVIATION_STATUS_ALL',
    },
];

export const getDeviationStatuses = t =>
    deviationStatuses.map(s => {
        s.text = t(s.t_text);
        return s;
    });

export const SUPPLIER_INVOICE_STATUS_PENDING = 'pending';
export const SUPPLIER_INVOICE_STATUS_APPROVED = 'approved';
export const SUPPLIER_INVOICE_STATUS_ALL = 'all';

export const getSupplierInvoiceStatuses = t =>
    supplierInvoiceStatuses.map(s => {
        s.text = t(s.t_text);
        return s;
    });

const supplierInvoiceStatuses = [
    {
        key: SUPPLIER_INVOICE_STATUS_PENDING,
        value: SUPPLIER_INVOICE_STATUS_PENDING,
        t_text: 'SUPPLIER_INVOICE_STATUS_PENDING',
    },
    {
        key: SUPPLIER_INVOICE_STATUS_APPROVED,
        value: SUPPLIER_INVOICE_STATUS_APPROVED,
        t_text: 'SUPPLIER_INVOICE_STATUS_APPROVED',
    },
    {
        key: SUPPLIER_INVOICE_STATUS_ALL,
        value: SUPPLIER_INVOICE_STATUS_ALL,
        t_text: 'SUPPLIER_INVOICE_STATUS_ALL',
    }
];

