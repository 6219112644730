import ProposalDetailsContainer from 'components/main/proposals/details/ProposalDetailsContainer';
import WorkOrderBroadcastOrderFinishedModal from 'components/main/workorder/form/broadcast/WorkOrderBroadcastOrderFinishedModal';
import WorkOrderCloseModal from 'components/main/workorder/form/close-modal';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { showModal } from '../../../data/actions/common/uiActions';
import { modalTypes } from '../../../utils/constants/modals';
import InvoiceHistoryContainer from '../../main/common/invoice-history';
import WorkOrderHistoryContainer from '../../main/common/workorder-history';
import InvoiceContainer from '../../main/invoice/InvoiceContainer';
import BottomSheet from '../bottom-sheet';
import DataModal from './DataModal';
import MultiFactorRegisteredModal from './MultiFactorRegisteredModal/MultiFactorRegisteredModal';
import BusinessObjectSecurityCodeLogsModal from 'components/main/inventory/tabs/business-objects/details/general/codes/log';
class ModalContainer extends Component {
    getModalContent = (modal, t) => {
        switch (modal.modalType) {
            case modalTypes.INVOICE: {
                return (
                    <div className="content">
                        <InvoiceContainer {...modal.props} />
                    </div>
                );
            }
            case modalTypes.INVOICE_HISTORY: {
                return (
                    <div className="content">
                        <InvoiceHistoryContainer {...modal.props} />
                    </div>
                );
            }
            case modalTypes.WORK_ORDER_HISTORY: {
                return (
                    <div className="content">
                        <WorkOrderHistoryContainer {...modal.props} />
                    </div>
                );
            }
            case modalTypes.PROPOSAL: {
                return (
                    <div className="content">
                        <ProposalDetailsContainer {...modal.props} />
                    </div>
                );
            }
            case modalTypes.MFA_REGISTERED: {
                return (
                    <div className="content">
                        <MultiFactorRegisteredModal {...modal.props} />
                    </div>
                );
            }
            case modalTypes.WORKORDER_ORDER_FINISH_BROADCAST: {
                return (
                    <div className="content">
                        <WorkOrderBroadcastOrderFinishedModal
                            {...modal.props}
                            onClose={this.props.closeModal}
                        />
                    </div>
                );
            }
            case modalTypes.WORKORDER_CLOSE: {
                return (
                    <WorkOrderCloseModal
                        {...modal.props}
                        onClose={this.handleClose}
                    />
                );
            }
            case modalTypes.BUSINESS_OBJECT_CODE_LOG: {
                return (
                    <BusinessObjectSecurityCodeLogsModal
                        {...modal.props}
                        onClose={this.handleClose}
                    />
                );
            }
            case modalTypes.MESSAGE: {
                return (
                    <>
                        <div className="content">
                            <div className="man-ui-wrap-newline description">
                                {modal.props.message}
                            </div>
                        </div>
                        <div
                            className="actions"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                            }}>
                            <Button
                                className="man-button-primary"
                                onClick={async e => {
                                    e.stopPropagation();
                                    this.props.closeModal();
                                }}
                                style={{ width: '200px' }}>
                                {t('CLOSE')}
                            </Button>
                        </div>
                    </>
                );
            }
            default: {
                return null;
            }
        }
    };

    handleClose = () => {
        const { closeModal, modal } = this.props;
        
        if (modal?.props?.onClose) {
            modal.props.onClose();
        }

        closeModal();
    }

    render() {
        const { modal, closeModal, isSmallScreen, t } = this.props;

        if (!modal || !modal.modalType) {
            return null;
        }

        if (isSmallScreen) {
            return (
                <BottomSheet open={true} zIndex={10000} onChange={closeModal}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                        <i
                            className="close icon Modal-Close"
                            onClick={e => {
                                e.stopPropagation();
                                this.handleClose();
                            }}></i>
                    </div>

                    {this.getModalContent(modal, t)}
                </BottomSheet>
            );
        }

        return (
            <DataModal
                style={{ zIndex: '3000' }}
                header={modal.header}
                content={this.getModalContent(modal, t)}
                onClose={this.handleClose}
            />
        );
    }
}

const mapStateToProps = state => ({
    modal: state.ui.modal,
    isSmallScreen: state.ui.smallScreen,
});

const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch(showModal(null)),
});

export default withTranslation(['ui'])(
    connect(mapStateToProps, mapDispatchToProps)(ModalContainer)
);
