
export default class CustomerInvoiceListGetResponse {

    invoices = [];
    totalCount;

    constructor({ invoices, totalCount }) {
        this.invoices = invoices;
        this.totalCount = totalCount;
    }

}