import React, { FC } from 'react';
import styles from './Header3.module.css';

interface Props {
    text: string
}

const Header3: FC<Props> = ({ text }) => {

    return (
        <h4 className={styles.header3}>
            {text}
        </h4>
    )
}

export default Header3;