export const se_security = {
    ui: {
        SETTINGS_BUSINESS_OBJECT: 'Anläggning',
        OBJECTS: 'Anläggningar',
        MANAGE_OBJECTS: 'Hantera anläggningar',
        MANAGE_MODELS: 'Hantera anläggningstyper',
        MODELS: 'Anläggningstyp',
        NEW_BUSINESS_OBJECT_MODAL_TITLE: 'Ny anläggning',
        MODEL_EDIT_DIALOG_TITLE: 'Ändra anläggningstyp',
        MODELPICKER_SELECT: 'Välj anläggningstyp',
        OBJECT: 'Anläggning',
    },
    form: {
        OBJECT_COUNT: 'Anläggningar',
        OBJECT: 'Anläggning',
        MODEL: 'Anläggningstyp',
        CODE: 'Anläggningskod',
        SERIAL: 'Anläggnings ID',
        PROJECT_OBJECTS_INVOLVED: 'Involverade anläggningar',
    },
    acl: {
        PERMISSION_BUSINESS_OBJECTS_READ: 'Se anläggningar',
        PERMISSION_BUSINESS_OBJECTS_DELETE: 'Radera anläggning',
        PERMISSION_BUSINESS_OBJECTS_EDIT: 'Ändra anläggningar',
        PERMISSION_MODELS_READ: 'Se anläggningstyp',
        PERMISSION_MODELS_DELETE: 'Radera anläggningstyp',
        PERMISSION_MODELS_EDIT: 'Ändra anläggningstyp',
        PERMISSION_BUSINESS_OBJECT_FILES: 'Hantera Dokument under Anläggning',
        PERMISSION_BUSINESS_OBJECT_AGREEMENT_EDIT: 'Ändra Avtal under Anläggning',

    },

     messages: {

    BUSINESS_OBJECT_EXISTS: 'Anläggnings-ID finns redan!',

     },
}