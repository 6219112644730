export const se_procontrol = {
    ui: {
    },

    form: {
    },

    messages: {
    },

};