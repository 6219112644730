import React from 'react';
import './loader.min.css';
interface Props {
    text?: string | undefined;
}

const Loader: React.FC<Props> = ({ text }) => {
    return (
        <div
            className={`ui active man-loader centered loader${
                text ? ' text' : ''
            }`}>
            {text || ''}
        </div>
    );
};

export default Loader;
