import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { showErrorNotification } from '../../../../data/actions/common/notificationActions';
import { hideModal } from '../../../../data/actions/common/uiActions';
import workOrderService from '../../../../data/services/workorder/workOrderService';
import { SettingsRow, SettingsGrid } from '../../../common/settings-grid';
import { formatDateTimeVerbose } from '../../../../utils/DateUtils';
import HistoryListLoader from '../history-list-loader';

const suppliers = ['elektroskandia', 'solar', 'copiax', 'ahlsell', 'axsnordic', 'rexel', 'dafo', 'extronic'];

class WorkOrderHistoryContainer extends Component {
    state = {
        isLoading: true,
        rows: []
    }

    componentDidMount() {
        const { workOrderId } = this.props;
        this.fetchHistory(workOrderId);
    }

    fetchHistory = async workOrderId => {
        const { t, closeModal, showError } = this.props;

        this.setState({
            isLoading: true
        }, async () => {
            try {
                const rows = await workOrderService.fetchHistory(workOrderId);

                this.setState({
                    isLoading: false,
                    rows
                })
            } catch (err) {
                this.setState({
                    isLoading: false
                })

                closeModal();
                showError(t('messages:FAILED_TO_FETCH'));
            }
        })
    }

    render() {
        const { t } = this.props;

        const { isLoading, rows } = this.state;

        if (isLoading) {
            return (
                <HistoryListLoader />
            )
        }

        return (
            <SettingsGrid>

                {rows
                    .sort((r1, r2) => r1.timestamp > r2.timestamp ? -1 : 1)
                    .map(({ userName, action, timestamp }, idx) => {

                        let name = suppliers.includes(userName) ? t(`suppliers:${userName}`) : userName;

                        return (
                            <SettingsRow key={timestamp} label={
                                <label>{formatDateTimeVerbose(timestamp)}</label>
                            }>
                                <div className="ui relaxed selection list">
                                    <div className="item" style={{
                                        cursor: 'default'
                                    }}>
                                        <div className="content">
                                            <div className="header">
                                                {name}
                                            </div>
                                            <div className="description">
                                                {t(`workorderhistory:${action}`)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SettingsRow>
                        )
                    })}

            </SettingsGrid>
        )
    }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({
    showError: text => dispatch(showErrorNotification(text)),
    closeModal: () => dispatch(hideModal())
})

export default withTranslation(['ui', 'messages'])(connect(mapStateToProps, mapDispatchToProps)(WorkOrderHistoryContainer));