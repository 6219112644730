
import modelService from '../../services/model/modelService';

export const FETCH_MODELS = 'FETCH_MODELS';
export const FETCH_MODELS_FULFILLED = 'FETCH_MODELS_FULFILLED';
export const FETCH_MODELS_REJECTED = 'FETCH_MODELS_REJECTED';
export const MODEL_CHANGED = 'MODEL_CHANGED';
export const MODEL_DELETED = 'MODEL_DELETED';
export const MODEL_TABLE_STATE_CHANGE = 'MODEL_TABLE_STATE_CHANGE';

const requestModels = () => ({ type: FETCH_MODELS });
const requestModelsFulfilled = models => ({ type: FETCH_MODELS_FULFILLED, models });
const requestModelsRejected = error => ({ type: FETCH_MODELS_REJECTED, error });

export const modelTableStateChange = state => ({ type: MODEL_TABLE_STATE_CHANGE, state });

export const modelChanged = model => ({
    type: MODEL_CHANGED,
    model
});

export const modelDeleted = id => ({
    type: MODEL_DELETED,
    id
})

export const fetchModels = () => async dispatch => {

    dispatch(requestModels());

    try {
        const models = await modelService.fetch();
        dispatch(requestModelsFulfilled(models));
    } catch (err) {
        console.error(err);
        dispatch(requestModelsRejected(err));
    }
};