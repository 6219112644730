import React, { useEffect } from 'react';
import Loader from '../../common/loader';
import { withRouter } from 'react-router-dom';
import * as qs from 'query-string';
import CacheManager from '../../../utils/cache';
import CacheManagerProps from '../../../utils/cache/CacheManagerProps';
import { connect } from 'react-redux';
import { validateSession } from '../../../data/actions/common/authActions';

const GoogleSuccessLogin = ({ history, location, validateSession }) => {
    useEffect(() => {

        const setSession = async () => {
            const { token } = qs.parse(location.search, { decode: false });
            await CacheManager.putGlobal(CacheManagerProps.AUTH_DATA, token);

            validateSession();
            history.push('/');
        }
        setSession();
    }, []);

    return (
        <Loader />
    )
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
    validateSession
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoogleSuccessLogin));