import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class Breadcrumbs extends React.Component {

    navigateTo = href => this.props.history.push(href);

    render() {

        const items = this.props.items
            .filter(bc => bc);

        const itemsLength = items.length;

        return (
            <div className="ui large breadcrumb">
                {items.map((bc, idx) => (
                    <React.Fragment key={idx}>
                        {bc.href ?
                            <a className="section man-internal-link"
                                style={{ color: '#000' }}
                                onClick={() => this.navigateTo(bc.href)}>{bc.text}</a> : <div className="section">{bc.text}</div>}
                        {idx + 1 !== itemsLength && <i className="right angle icon divider"></i>}
                    </React.Fragment>
                ))
                }
            </div>
        )
    }
}

Breadcrumbs.propTypes = {
    items: PropTypes.array
}

Breadcrumbs.defaultProps = {
    items: []
}

export default withRouter(Breadcrumbs);