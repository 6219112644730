import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { FilesContainer } from 'trukks-lib';
import { showConfirm } from '../../../data/actions/common/confirmActions';
import { showErrorNotification } from '../../../data/actions/common/notificationActions';
import { appModules } from '../../../utils/constants/appModules';
import { requireAppModule } from '../require-app-module/RequireAppModule';
import ImagePreview from './ImagePreview';
import Icon from '../icon';

const FileContainer = ({
    t,
    id,
    showErrorNotification,
    showConfirm,
    uploadFunc,
    fetchFunc,
    deleteFunc,
    detailsFunc,
    renameFunc,
    moveFunc,
    reorderFunc,
    editable,
    compact,
    foldersEnabled = false,
    handleNewFolder,
    linksEnabled,
    onNewLink,
    onPublishFile,
    onUnpublishFile,
    linkChangeFunc,
    downloadAllEnabled = false,
    publishEnabled = false
}) => {
    const [preview, setPreview] = useState(null);

    const handleFilePreview = ({ file, url }) => {
        console.log(url);
        setPreview({
            file,
            url,
        });
    };

    // TODO limitations
    // 1. does not support folders
    // 2. max 100 files
    const handleDownloadAll = async () => {
        try {
            const { files } = await fetchFunc(id, null, 0, 100);

            files.forEach(f => {
                const win = window.open(f.url);
                win.focus();
            });
        } catch (e) {
            console.error(e);
        }
    }

    const translations = {
        actionConfirm: t('ui:ACTION_CONFIRM'),
        uploadPlaceholder: t('form:UPLOAD_FILES_PLACEHOLDER'),
        uploadingFile: t('ui:UPLOADING'),
        loadMore: t('ui:LOAD_MORE'),
        total: t('ui:TOTAL'),
        rootFolder: t('files:ROOT_FOLDER'),
        backToFolder: t('files:BACK_TO'),
        editTranslations: {
            remove: t('ui:REMOVE'),
            rename: t('ui:RENAME'),
            download: t('ui:DOWNLOAD'),
            editLink: t('form:EDIT_LINK'),
            publish: t('ui:PUBLISH_FILE'),
            unpublish: t('ui:UNPUBLISH_FILE')
        },
        linkTranslations: {
            name: t('form:NAME'),
            link: t('form:LINK'),
            cancel: t('ui:ACTION_CANCEL'),
            addLink: t('form:ADD_LINK'),
            add: t('ui:SAVE'),
            requiredFields: t('messages:ADD_LINK_REQUIRED_FIELDS'),
        },
    };

    return (
        <React.Fragment>
            {preview && (
                <ImagePreview
                    onClose={() => setPreview(null)}
                    fileName={preview.file.originalFileName}
                    type={preview.file.type}
                    url={preview.url}
                />
            )}

            <div className="man-files-container">
                {downloadAllEnabled &&
                    <div className="man-flex-row man-flex-row-sb"
                        style={{
                            paddingRight: 20,
                            paddingBottom: 10
                        }}>
                        <div></div>
                        <Dropdown
                            trigger={<Icon name="ellipsis vertical"></Icon>}
                            className="man-extra-actions-toggle"
                            item
                            simple
                            direction="left">
                            <Dropdown.Menu>
                                <div
                                    className={`ui item`}
                                    size="medium"
                                    onClick={handleDownloadAll}>
                                    {t('ui:DOWNLOAD_ALL')}
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                }

                <FilesContainer
                    id={id}
                    fetchFunc={fetchFunc}
                    uploadFunc={uploadFunc}
                    deleteFunc={deleteFunc}
                    detailsFunc={detailsFunc}
                    editable={editable}
                    onPreviewFile={handleFilePreview}
                    onFileReposition={reorderFunc}
                    compact={compact}
                    onNameChange={renameFunc}
                    showConfirm={showConfirm}
                    onFetchFailed={() =>
                        showErrorNotification('messages:FAILED_TO_FETCH')
                    }
                    onUploadFailed={() =>
                        showErrorNotification('messages:FAILED_TO_UPLOAD_FILE')
                    }
                    onDeleteFailed={() =>
                        showErrorNotification('messages:FAILED_TO_DELETE')
                    }
                    onMoveFailed={() =>
                        showErrorNotification('messages:FAILED_TO_MOVE_FILE')
                    }
                    translations={translations}
                    foldersEnabled={foldersEnabled}
                    onNewFolder={handleNewFolder}
                    onMoveToFolder={moveFunc}
                    onLinkChange={linkChangeFunc}
                    linksEnabled={linksEnabled}
                    onNewLink={onNewLink}
                    publishEnabled={publishEnabled}
                    onPublishFile={onPublishFile}
                    onUnpublishFile={onUnpublishFile}
                />
            </div>
        </React.Fragment>
    );
};

FileContainer.propTypes = {
    id: PropTypes.string.isRequired,
    fetchFunc: PropTypes.func.isRequired,
    uploadFunc: PropTypes.func.isRequired,
    deleteFunc: PropTypes.func.isRequired,
    editable: PropTypes.bool,
    compact: PropTypes.bool,
    foldersEnabled: PropTypes.bool,
    downloadAllEnabled: PropTypes.bool,
    publishEnabled: PropTypes.bool
};

FileContainer.defaultProps = {
    editable: true,
    foldersEnabled: false,
    compact: false,
    downloadAllEnabled: false,
    publishEnabled: false
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    showErrorNotification,
    showConfirm,
};

export default requireAppModule(
    withTranslation(['ui', 'form', 'files'])(
        connect(mapStateToProps, mapDispatchToProps)(FileContainer)
    ),
    appModules.FILES
);
