import {
    AGREEMENT_CHANGED,
    AGREEMENT_TABLE_STATE_CHANGE,
    FETCH_AGREEMENTS,
    FETCH_AGREEMENTS_FULFILLED,
    FETCH_AGREEMENTS_REJECTED,
} from '../../actions/agreements';

const defaultState = {
    isFetching: false,
    agreements: [],
    agreementsById: {},
    tableState: undefined,
};

export const agreements = (state = defaultState, action) => {
    switch (action.type) {
        case AGREEMENT_TABLE_STATE_CHANGE:
            return { ...state, tableState: action.state };
        case FETCH_AGREEMENTS: {
            return {
                ...state,
                isFetching: true,
            };
        }
        case FETCH_AGREEMENTS_FULFILLED: {
            const agreementsById = {};

            action.agreements.forEach(u => {
                agreementsById[u.id] = u;
            });

            return {
                ...state,
                isFetching: false,
                agreements: action.agreements,
                agreementsById,
            };
        }
        case FETCH_AGREEMENTS_REJECTED: {
            return {
                ...state,
                isFetching: false,
            };
        }
        case AGREEMENT_CHANGED: {
            const agreements = [...state.agreements];
            const idx = agreements.findIndex(a => a.id === action.agreement.id);

            if (idx < 0) {
                agreements.push(action.agreement);
            } else {
                agreements[idx] = action.agreement;
            }

            return {
                ...state,
                agreements,
            };
        }
        default: {
            return state;
        }
    }
};
