import React from 'react';
import { Form } from 'semantic-ui-react';
import './UIComponents.css';

export const Row = ({ children, style, unstackable, className }) => (
    <div
        className={`man-ui-row${unstackable ? '' : ' stackable'}${
            className ? ` ${className}` : ''
        }`}
        style={style}>
        {children}
    </div>
);

export const Col = ({ ref, children, className, style, onClick }) => (
    <div
        ref={ref}
        className={`man-ui-col${className ? ` ${className}` : ''}`}
        onClick={() => onClick && onClick()}
        style={style}>
        {children}
    </div>
);

export const Section = ({ children, className, style }) => (
    <div
        className={`man-ui-section${className ? ` ${className}` : ''}`}
        style={style}>
        {children}
    </div>
);

export const SectionHeader = ({
    children,
    className,
    style,
    header,
    onClick,
}) => (
    <h5
        className={`ui dividing header${onClick ? ' clickable' : ''}${
            className ? ` ${className}` : ''
        }`}
        onClick={onClick}
        style={{ style }}>
        {header ? header : ''} {children}
    </h5>
);

export const SectionBody = ({ children, className, style }) => (
    <div
        className={`man-ui-section-body${className ? ` ${className}` : ''}`}
        style={style}>
        {children}
    </div>
);

export const SectionValueContainer = ({ children, style }) => (
    <Form.Field style={style}>{children}</Form.Field>
);

export const Label = ({ children, inline, style }) => {
    let labelStyle = {
        marginBottom: 0,
        display: inline ? 'inline-block' : 'block',
        paddingRight: 4,
    };

    if (style) {
        labelStyle = {
            ...labelStyle,
            ...style,
        };
    }

    return <label style={labelStyle}>{children}</label>;
};

export const Value = ({ children, className, inline, style }) => (
    <div
        className={`man-ui-value${className ? ` ${className}` : ''}`}
        style={{ display: inline ? 'inline-block' : 'block', ...(style || {}) }}>
        {children}
    </div>
);
