import { get, put, post, deletee } from '../../../utils/http/HttpClient';
import instanceService from '../instance/instanceService';

export default {
    fetch: async () => {
        const start = new Date().getTime();

        const { suppliers } = await get('/suppliers');

        const end = new Date().getTime();
        const time = end - start;

        instanceService.postMetric('suppliers', time);
        return suppliers;
    },
    query: async q => {
        const { suppliers } = await get(`/suppliers/q?q=${q}`);

        return suppliers;   
    },
    fetchDetails: async id => {
        const { supplier } = await get(`/suppliers/${id}`);

        return supplier;
    },
    create: async (title, name) => {
        const { id } = await post('/suppliers', {
            title,
            name
        });

        return id;
    },
    update: async supplier => {
        await put('/suppliers', { supplier });
    },
    updateContacts: async (id, contacts) => {
        await put(`/suppliers/${id}/contacts`, {
            contacts
        });
    },
    delete: async id => {
        await deletee(`/suppliers/${id}`);
    },
    fetchProposedId: async length => {
        const { id } = await get(`/suppliers/id/propose?length=${length}`);

        return id;
    },
    fetchErrorOrders: async () => {
        const { orders } = await get('/suppliers/order');
        
        return orders;
    },
    discardOrder: async id => {
        await post(`/suppliers/order/${id}/discard`, {});
    },
    reHandleOrder: async (id, workOrderTitle) => {
        return await post(`/suppliers/order/${id}/handle`, {
            workOrderTitle
        });
    }
}