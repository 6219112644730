import { SettingsGrid } from "components/common/settings-grid";
import { useNotifications } from "components/common/use-notifications";
import businessObjectsService from "data/services/business-objects/businessObjectsService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Item, LineLoader, SettingsRow } from "trukks-lib";
import { formatDateTimeVerbose } from "utils/DateUtils";

const LOG_TYPE = {
    TYPE_ADD_GROUP: "add.group",
    TYPE_EDIT_GROUP: "edit.group",
    TYPE_DELETE_GROUP: "delete.group",
    TYPE_ADD_CODE: "add.code",
    TYPE_EDIT_CODE: "edit.code",
    TYPE_EDIT_CODE_VALUE: "edit.code.value",
    TYPE_VIEW_CODE: "view.code",
    TYPE_COPY_CODE: "copy.code",
    TYPE_DELETE_CODE: "delete.code",
}


const parseNullableJson = json => {
    if (!json) {
        return null;
    }

    try {
        return JSON.parse(json);
    } catch {
        return null;
    }
}

const BusinessObjectSecurityCodeLogsModal = ({ businessObjectId }) => {

    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);

    const { showErrorNotification } = useNotifications();
    const { t } = useTranslation(['ui']);

    const fetchLogs = async () => {
        try {
            const l = await businessObjectsService.fetchLogs(businessObjectId);

            setLogs(l);
            setLoading(false);
        } catch (err) {
            console.error(err);
            showErrorNotification('FAILED_TO_FETCH');
        }
    }

    useEffect(() => {
        fetchLogs();
    }, [businessObjectId]);


    const getLogMessage = log => {
        const { userName, businessObjectSerialCode } = log;
        const from = parseNullableJson(log.from);
        const to = parseNullableJson(log.to);

        switch (log.type) {
            case LOG_TYPE.TYPE_ADD_GROUP: {

                return t('ui:BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_ADD_GROUP', {
                    groupName: to?.displayName,
                    userName,
                    businessObjectId: businessObjectSerialCode
                });
            }
            case LOG_TYPE.TYPE_EDIT_GROUP: {

                return t('ui:BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_EDIT_GROUP', {
                    fromGroupName: from?.displayName,
                    toGroupName: to?.displayName,
                    userName,
                    businessObjectId: businessObjectSerialCode
                });
            }
            case LOG_TYPE.TYPE_DELETE_GROUP: {

                return t('ui:BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_DELETE_GROUP', {
                    groupName: from?.displayName,
                    userName,
                    businessObjectId: businessObjectSerialCode
                });
            }
            case LOG_TYPE.TYPE_ADD_CODE: {

                return t('ui:BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_ADD_CODE', {
                    groupName: to?.groupName,
                    codeName: to?.displayName,
                    userName,
                    businessObjectId: businessObjectSerialCode
                });
            }
            case LOG_TYPE.TYPE_EDIT_CODE: {

                return t('ui:BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_EDIT_CODE', {
                    groupName: from?.groupName,
                    fromCodeName: from?.displayName,
                    toCodeName: to?.displayName,
                    userName,
                    businessObjectId: businessObjectSerialCode
                });
            }
            case LOG_TYPE.TYPE_EDIT_CODE_VALUE: {

                return t('ui:BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_EDIT_CODE_VALUE', {
                    groupName: from?.groupName,
                    codeName: from?.displayName,
                    userName,
                    businessObjectId: businessObjectSerialCode
                });
            }
            case LOG_TYPE.TYPE_VIEW_CODE: {

                return t('ui:BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_VIEW_CODE', {
                    groupName: from?.groupName,
                    codeName: from?.displayName,
                    userName,
                    businessObjectId: businessObjectSerialCode
                });
            }
            case LOG_TYPE.TYPE_COPY_CODE: {

                return t('ui:BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_COPY_CODE', {
                    groupName: from?.groupName,
                    codeName: from?.displayName,
                    userName,
                    businessObjectId: businessObjectSerialCode
                });
            }
            case LOG_TYPE.TYPE_DELETE_CODE: {

                return t('ui:BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_DELETE_CODE', {
                    groupName: from?.groupName,
                    codeName: from?.displayName,
                    userName,
                    businessObjectId: businessObjectSerialCode
                });
            }
        }

        return '-';
    }

    return (
        <div className="content">
            <div className="description">
                <SettingsGrid>
                    {loading &&
                        <SettingsRow label={
                            <LineLoader lines={3} />
                        }>
                            <LineLoader lines={3} />
                        </SettingsRow>
                    }

                    {logs.map(log => (
                        <SettingsRow label={
                            formatDateTimeVerbose(log.timestamp)
                        }>
                            <Item>
                                {getLogMessage(log)}
                            </Item>
                        </SettingsRow>
                    ))}

                </SettingsGrid>

            </div>
        </div>
    )
}

export default BusinessObjectSecurityCodeLogsModal;