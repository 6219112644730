import qs from 'query-string';
import {
    deletee,
    get,
    getFile,
    getPrintPreviewPdf,
    post,
    put,
} from '../../../utils/http/HttpClient';

export default {
    fetch: async (customerId, status, departmentId) => {
        const query = qs.stringify({
            customerId,
            status,
            departmentId,
        });

        const { orders } = await get(`/salesorders?${query}`);

        return orders;
    },
    fetchDetails: async id => {
        const { order } = await get(`/salesorders/${id}`);

        return order;
    },
    fetchByProposalId: async id => {
        const { order } = await get(`/salesorders/proposal/${id}`);

        return order;
    },
    create: async customerId => {
        const { id } = await post('/salesorders', { customerId });

        return id;
    },
    update: async order => {
        await put('/salesorders', order);
    },
    delete: async id => {
        await deletee(`/salesorders/${id}`);
    },
    close: async (id, isPaid) => {
        await post(`/salesorders/${id}/close`, {
            paid: isPaid,
        });
    },
    reopen: async id => {
        await post(`/salesorders/${id}/reopen`, {});
    },
    exportWord: async (salesOrder, templateId) => {
        try {
            await getFile(
                '/salesorders/word',
                {
                    salesOrder,
                    templateId,
                },
                salesOrder.title,
                '.docx',
                'order'
            );
        } catch (err) {
            console.error(err);
        }
    },
    pdfPreview: async (salesOrder, templateId) => {
        try {
            await getPrintPreviewPdf(
                '/salesorders/pdf',
                {
                    salesOrder,
                    templateId,
                },
                salesOrder.title
            );
        } catch (err) {
            console.error(err);

            throw err;
        }
    },
    pdf: async (salesOrder, templateId) => {
        try {
            await getFile(
                '/salesorders/pdf',
                {
                    salesOrder,
                    templateId,
                },
                salesOrder.title,
                '.pdf',
                'order'
            );
        } catch (err) {
            console.error(err);

            throw err;
        }
    },
};
