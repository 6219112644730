export const appModules = {
    COPY: 'copy',
    SECURITY: 'security',

    UI_SETTINGS: 'ui.settings',

    EXPERIMENTAL: 'experimental',
    FILES: 'files',
    MAPS: 'maps',
    ERP: 'erp.integration',
    BROADCAST: 'broadcast',
    DEVIATIONS: 'deviations',
    DOORAUTOMATICS: 'doorautomatics',
    ANLAGGARYNTIG: 'anlaggaryntig',
    ALTERNATIVE_INVOICE_RECEIVER: 'alternative.invoice.receiver',
    CUSTOMER_WORK_ORDERS: 'customer.workorders',
    CUSTOMER_DEFAULT_DISCOUNT: 'customer.default-discount',
    CUSTOMER_INVOICE_CONTACT: 'customer.invoice-contact',
    CUSTOMER_ARTICLEGROUP_DISCOUNTS: 'customer.article-group.discounts',
    CUSTOMER_ZONE: 'customer-zone',
    COMPENSATION_REPORTS: 'compensation-reports',
    MODEL_CODE: 'model.code',
    ARTICLE_ENUMBER: 'article.e-number',
    ARTICLE_GROUPS: 'article.groups',
    BUSINESS_OBJECT_SERVICE_LOG: 'business-object.service-log',
    BUSINESS_OBJECT_DETAILED_SEARCH: 'business-object.detailed-search',
    BUSINESS_OBJECT_DISPLAY_NAME: 'business-object.display-name',
    BUSINESS_OBJECT_CODES: 'business-object.codes',
    CHECKLISTS: 'checklists',
    SERVICE_REPORTS: 'service-reports',
    CRM: 'crm',
    PROJECTS: 'projects',
    PROJECTS_RESOURCE_PLANNING: 'projects.resource-planning',
    WORKORDER_SIGNATURE: 'workorder.signature',
    WORKORDER_INVOICE_RULES: 'workorder.invoice-rules',
    WORKORDER_DEPARTMENT: 'workorder.department',
    WORKORDER_INTERNAL_INFO: 'workorder.internal-info',
    WORKORDER_ARTICLE_DISTRIBUTIONS: 'workorder.article-distributions',
    WORKORDER_REMINDERS: 'workorder.reminders',
    WORKORDER_INVOICE_DETAILS: 'workorder.invoice-details',
    WORKORDER_DISPATCH_RULES: 'workorder.dispatch-rules',
    AGREEMENT_PRICING: 'agreement.pricing',
    AGREEMENTS_WORKORDER_ARTICLES: 'agreements.workorder.articles',
    AGREEMENTS_WORKORDER_DESCRIPTION: 'agreements.workorder.description',
    DASHBOARD: 'dashboard',
    DISTANCE_REPORTS: 'distance-reports',
    MULTIFACTOR_AUTHENTICATION: 'authentication.multifactor',
    MICROSOFT_AUTHENTICATION: 'authentication.microsoft',
    LOCATION_STATE: 'location.state',
    ON_CALL: 'on-call',
    REGIONS: 'regions',

    ID06: 'ID06',
    IMPORTER_EXPORTER: 'importer-exporter',

    SALESORDER_DEPARTMENT: 'salesorder.department',

    SUPPLIER_INVENTORY: 'supplier.inventory',
    SUPPLIER_INVOICES: 'supplier.invoices',
    SUPPLIER_TAGS: 'supplier.tags',

    STATS_SERVICE_DISTRIBUTION: 'stats.service-distribution',
    STATS_INVOICING: 'stats.invoicing',
    STATS_OBJECTS: 'stats.objects',
    STATS_LARMKLASS: 'stats.larmklass',
    STATS_WORKORDERS_OPEN_INVOICING: 'stats.workorders.open.invoicing',
    STATS_SUPPLIER_ORDERS: 'stats.supplier-orders',
    STATS_PROJECTS_OPEN_KPI: 'stats.projects.open.kpi',
    STATS_AGREEMENT_INVOICING: 'stats.agreement.invoicing',
    STATS_CLOSED_WORKORDERS_BY_TYPE: 'stats.closed-workorders-by-type',
    STATS_ACCRUED_WORKORDER_ARTICLES: 'stats.accrued-wo-articles',
    STATS_ERP_SUPPLIER_INVOICES: 'stats.erp-supplier-invoices',

    SBSC: 'sbsc',
    SECURITAS_DIRECT: 'securitas-direct',

    CUSTOM_ELTE_LARMTEKNIK: 'custom.elte-larmteknik',
};
