import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { Route, Switch, withRouter } from 'react-router-dom';
import { permission } from '../../../utils/constants/permissions';
import { withPermissions } from '../../common/with-permissions/WithPermissions';
import './Sidebar.css';

const FilterLoader = () => (
    <div className="item">
        <div className="ui placeholder inverted">
            <div className="paragraph">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
        </div>
    </div>
);

const WorkOrderListSidebar = Loadable({
    loader: () => import('./workorder-filters'),
    loading: FilterLoader,
});

const BusinessObjectsListSidebar = Loadable({
    loader: () => import('./business-object-filters'),
    loading: FilterLoader,
});

const CustomerListSidebar = Loadable({
    loader: () => import('./customer-filters'),
    loading: FilterLoader,
});

const ArticleListSidebar = Loadable({
    loader: () => import('./article-filters'),
    loading: FilterLoader,
});

const InventorySidebar = Loadable({
    loader: () => import('./inventory-sidebar'),
    loading: FilterLoader,
});

const CalendarSidebar = Loadable({
    loader: () => import('./calendar-filters'),
    loading: FilterLoader,
});

const ReportsSidebar = Loadable({
    loader: () => import('./reports-filters'),
    loading: FilterLoader,
});

const SalesOrdersSidebar = Loadable({
    loader: () => import('./salesorder-filters'),
    loading: FilterLoader,
});

const ProposalsSidebar = Loadable({
    loader: () => import('./proposal-filters'),
    loading: FilterLoader,
});

const ProjectsSidebar = Loadable({
    loader: () => import('./project-filters'),
    loading: FilterLoader,
});

const SupplierInventorySidebar = Loadable({
    loader: () => import('./supplier-inventory-filters'),
    loading: FilterLoader,
});

const TimeReportsSidebar = Loadable({
    loader: () => import('./timereport-filters'),
    loading: FilterLoader,
});

const DeviationsSidebar = Loadable({
    loader: () => import('./deviation-filters'),
    loading: FilterLoader
});

const SupplierSidebar = Loadable({
    loader: () => import('./supplier-filters'),
    loading: FilterLoader
});

const SupplierInvoicesSidebar = Loadable({
    loader: () => import('./supplier-invoices-filters'),
    loading: FilterLoader
});

const DoorautomaticsSidebar = Loadable({
    loader: () => import('./doorautomatic-filters'),
    loading: FilterLoader
});

const UsersSidebar = Loadable({
    loader: () => import('./user-filters'),
    loading: FilterLoader
});

const DashboardSidebar = Loadable({
    loader: () => import('./dashboard-filters'),
    loading: FilterLoader
});

class Sidebar extends Component {
    render() {
        const { hasPermission } = this.props;

        return (
            <div
                className={`ui accordion large inverted vertical sticky menu top pointing man-sidebar`}>
                <Switch>
                    {/* TODO or haspermission PROJECTS */}
                    {hasPermission(permission.WORKORDERS_READ) && (
                        <Route
                            exact
                            path="/"
                            component={
                                this.props.isCalendarOpen
                                    ? CalendarSidebar
                                    : WorkOrderListSidebar
                            }
                        />
                    )}

                    {hasPermission(permission.WORKORDERS_READ) && (
                        <Route
                            exact
                            path="/index.html"
                            component={
                                this.props.isCalendarOpen
                                    ? CalendarSidebar
                                    : WorkOrderListSidebar
                            }
                        />
                    )}

                    <Route
                        exact
                        path="/inventory/objects"
                        render={() => (
                            <React.Fragment>
                                <InventorySidebar />
                                <BusinessObjectsListSidebar />
                            </React.Fragment>
                        )}
                    />

                    <Route
                        exact
                        path="/inventory/customers"
                        render={() => (
                            <React.Fragment>
                                <InventorySidebar />
                                <CustomerListSidebar />
                            </React.Fragment>
                        )}
                    />

                    <Route
                        exact
                        path="/inventory/articles"
                        render={() => (
                            <React.Fragment>
                                <InventorySidebar />
                                <ArticleListSidebar />
                            </React.Fragment>
                        )}
                    />

                    <Route
                        exact
                        path="/inventory/suppliers"
                        render={() => (
                            <React.Fragment>
                                <InventorySidebar />
                                <SupplierSidebar />
                            </React.Fragment>
                        )}
                    />

                    <Route
                        exact
                        path="/inventory/doorautomatics"
                        render={() => (
                            <React.Fragment>
                                <InventorySidebar />
                                <DoorautomaticsSidebar />
                            </React.Fragment>
                        )}
                    />

                    {hasPermission(permission.SALES_ORDER_READ) && (
                        <Route
                            exact
                            path="/sales"
                            component={SalesOrdersSidebar}
                        />
                    )}

                    {hasPermission(permission.PROPOSAL_READ) && (
                        <Route
                            exact
                            path="/proposals"
                            component={ProposalsSidebar}
                        />
                    )}

                    {hasPermission(permission.PROJECTS_READ) && (
                        <Route
                            exact
                            path="/projects"
                            component={ProjectsSidebar}
                        />
                    )}

                    <Route path="/time" component={TimeReportsSidebar} />

                    <Route path="/inventory" component={InventorySidebar} />

                    <Route path="/reports" component={ReportsSidebar} />

                    {hasPermission(permission.DEVIATIONS_READ) && (
                        <Route
                            exact
                            path="/deviations/list"
                            component={DeviationsSidebar}
                        />
                    )}

                    {hasPermission(permission.SUPPLIER_INVOICES_READ) && (
                        <Route
                            exact
                            path="/supplierinvoices"
                            component={SupplierInvoicesSidebar}
                        />
                    )}

                    <Route
                        exact
                        path="/dashboard"
                        render={() => (
                            <DashboardSidebar />
                        )}
                    />


                    <Route
                        path="/settings/articles/suppliers"
                        component={SupplierInventorySidebar}
                    />

                    <Route
                        path="/settings/users"
                        component={UsersSidebar}
                    />
                </Switch>

                <div
                    className="item"
                    style={{ marginTop: 'auto', marginBottom: 50 }}>
                    <div className="man-sidebar-footer-container">
                        <p>&copy; Trukks</p>
                    </div>
                </div>
            </div>
        );
    }
}

Sidebar.propTypes = {
    isCalendarOpen: PropTypes.bool,
};

Sidebar.defaultProps = {
    isCalendarOpen: false,
};

export default withRouter(withPermissions(Sidebar));
