export const languages = [
    {
        key: 'se',
        value: 'se',
        flag: 'se',
        text: 'Swedish (Svenska)'
    },
    {
        key: 'en',
        value: 'en',
        flag: 'gb',
        text: 'English'
    },
    {
        key: 'ee',
        value: 'ee',
        flag: 'ee',
        text: 'Estonian (Eesti)'
    },
    {
        key: 'lv',
        value: 'lv',
        flag: 'lv',
        text: 'Latvian'
    }
]