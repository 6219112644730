import Icon from 'components/common/icon';
import { useInstanceData } from 'components/common/use-instance-data';
import { useNotifications } from 'components/common/use-notifications';
import { usePermissions } from 'components/common/use-permissions';
import fileService from 'data/services/file/fileService';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { shortenText } from 'utils/StringUtils';
import { appModules as modules } from 'utils/constants/appModules';
import { permission } from 'utils/constants/permissions';
import './ProposalActions.css';

const ProposalActionButton = ({
    icon,
    onClick,
    text,
    className,
    loading,
    isSmallScreen,
    disabled
}) => (
    <div
        className={`ui item${className ? ` ${className}` : ''}${loading ? ' man-proposal-actions-loading-item' : ''}${disabled ? ' disabled' : ''}`}
        size="medium"
        onClick={onClick}>
        <Icon name={icon}></Icon>
        {isSmallScreen ? shortenText(text, 32) : text}
    </div>
);

const ProposalActions = ({
    t,
    proposal,
    onClearCustomer,
    onWordExport,
    onPdf,
    onMakeCopy,
    onReopen,
    onDelete,
    closed,
}) => {
    const [opening, setOpening] = useState(null);

    const isSmallScreen = useSelector(state => state.ui.smallScreen);

    const { appModules } = useInstanceData();
    const { hasPermission, isSuperUser } = usePermissions();
    const { showErrorNotification } = useNotifications();

    const templates = (appModules[modules.CRM]?.custom?.proposalTemplates || [])
        .map(template => {
            if (!proposal) {
                return template;
            }

            if (!template.requiredCustomFields) {
                return {
                    ...template,
                    disabled: false
                };
            }

            return {
                ...template,
                disabled: !proposal.customFields || template.requiredCustomFields.some(f => !proposal?.customFields[f])
            }
        });


    const files = useSelector(
        state =>
            state.auth.instance.configuration?.proposalConfiguration
                ?.templates || []
    );

    const handleFileOpen = async fileId => {
        try {
            setOpening(fileId);

            const { previewUrl } = await fileService.fetchGenericFile(
                'proposal',
                fileId
            );

            window.open(previewUrl);
        } catch {
            showErrorNotification('FAILED_TO_DOWNLOAD_FILE');
        } finally {
            setOpening(null);
        }
    };


    return (
        <div className="man-proposal-actions-container">
            <div></div>

            <div>
                <Dropdown
                    trigger={<Icon name="ellipsis vertical"></Icon>}
                    className="man-extra-actions-toggle"
                    item
                    simple
                    direction="left">
                    <Dropdown.Menu>
                        {proposal &&
                            proposal.customerId &&
                            templates.map(template => (
                                <React.Fragment key={template.id}>
                                    {template.wordEnabled && (
                                        <ProposalActionButton
                                            isSmallScreen={isSmallScreen}
                                            disabled={template.disabled || false}
                                            icon="file word"
                                            text={`${template.label
                                                ? `${template.label} - `
                                                : ''
                                                }${t('WORD_EXPORT')}`}
                                            onClick={() =>
                                                onWordExport(template.id)
                                            }
                                        />
                                    )}
                                    {template.pdfEnabled && (
                                        <ProposalActionButton
                                            isSmallScreen={isSmallScreen}
                                            disabled={template.disabled || false}
                                            icon="file pdf outline"
                                            text={`${template.label
                                                ? `${template.label} - `
                                                : ''
                                                }${t('PDF')}`}
                                            onClick={() => onPdf(template.id, template.fileName)}
                                        />
                                    )}
                                </React.Fragment>
                            ))}

                        {files.map(f => (
                            <ProposalActionButton
                                isSmallScreen={isSmallScreen}
                                key={f.fileId}
                                icon="file pdf outline"
                                text={f.label}
                                loading={opening === f.fileId}
                                onClick={() => handleFileOpen(f.fileId)}
                            />
                        ))}

                        <ProposalActionButton
                            isSmallScreen={isSmallScreen}
                            icon="copy"
                            text={t('ui:PROPOSAL_MAKE_COPY')}
                            onClick={onMakeCopy}
                        />

                        {proposal && proposal.customerId && !closed && (
                            <ProposalActionButton
                                isSmallScreen={isSmallScreen}
                                icon="lock open"
                                text={t('PROPOSAL_CLEAR_CUSTOMER')}
                                onClick={onClearCustomer}
                            />
                        )}

                        {hasPermission(permission.PROPOSAL_DELETE) &&
                            proposal &&
                            !closed && (
                                <ProposalActionButton
                                    isSmallScreen={isSmallScreen}
                                    icon="remove"
                                    text={t('DELETE')}
                                    onClick={onDelete}
                                />
                            )}

                        {closed && hasPermission(permission.PROPOSAL_REOPEN) && (
                            <ProposalActionButton
                                isSmallScreen={isSmallScreen}
                                icon="redo"
                                text={t('REOPEN_PROPOSAL')}
                                onClick={onReopen}
                            />
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
};

export default withTranslation('ui')(ProposalActions);
