import timereportingService from "../../services/timereporting/timereportingService";
import { showErrorNotification } from "../common/notificationActions";

export const REPORTS_TIMEREPORT_FILTERS_CHANGE = 'REPORTS_TIMEREPORT_FILTERS_CHANGE';

export const FETCH_REPORTS_TIMEREPORT = 'FETCH_REPORTS_TIMEREPORT';
export const FETCH_REPORTS_TIMEREPORT_FULFILLED = 'FETCH_REPORTS_TIMEREPORT_FULFILLED';
export const FETCH_REPORTS_TIMEREPORT_REJECTED = 'FETCH_REPORTS_TIMEREPORT_REJECTED';

export const editTimeReportStatsFilters = filters => ({
    type: REPORTS_TIMEREPORT_FILTERS_CHANGE,
    filters
});

const timereportsFetch = () => ({
    type: FETCH_REPORTS_TIMEREPORT
});

const timereportsFulfilled = reports => ({
    type: FETCH_REPORTS_TIMEREPORT_FULFILLED,
    reports
});

const timereportsRejected = error => ({
    type: FETCH_REPORTS_TIMEREPORT_REJECTED,
    error
});

export const fetchTimeReports = ({ startTime, endTime, userId }, hasDistanceReports) => async dispatch => {
    dispatch(timereportsFetch());

    try {
        const reports = await timereportingService.fetchTimeReports(startTime, endTime, userId, hasDistanceReports);
        dispatch(timereportsFulfilled(reports));
    } catch (err) {
        console.log(err)
        dispatch(timereportsRejected(err));
        dispatch(showErrorNotification('FAILED_TO_FETCH'));
    }
}