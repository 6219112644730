import React, { useEffect, useRef } from 'react';
import { DeviceUtils } from '../../../utils/DeviceUtils';
import FlatButton from '../flat-button';
import './InputNumeric.css';

const InputNumeric = props => {

    const ref = useRef(null);

    const { onValueChange, label, disabled, fluid, focus } = props;

    const handleAdd = () => {
        if (!(onValueChange && ref.current && ref.current.stepUp)) {
            return;
        }

        ref.current.stepUp();
        onValueChange(ref.current.value);
    }

    const handleRemove = () => {
        if (!(onValueChange && ref.current && ref.current.stepDown)) {
            return;
        }

        ref.current.stepDown();
        onValueChange(ref.current.value);
    }

    useEffect(() => {
        if (focus && ref.current && ref.current.focus) {
            ref.current.focus({
                preventScroll: true
            })
        }
    }, [focus, ref])

    return (
        <div className={`ui input man-input-numeric-container${fluid ? ' fluid' : ''}`}
            style={props.style || null}>
            <input
                ref={ref}
                className={`man-input-numeric${label ? ' labeled' : ''}`}
                type="number"
                {...props} />

            {label &&
                <div className="man-input-numeric-label">
                    {label}
                </div>
            }
            {!disabled && onValueChange && !DeviceUtils.isMicrosoft() &&
                <React.Fragment>
                    <FlatButton icon="minus" active
                        className="man-input-numeric-increment-btn man-input-numeric-increment-btn--negative"
                        onClick={handleRemove} />
                    <FlatButton icon="plus" active
                        className="man-input-numeric-increment-btn man-input-numeric-increment-btn--positive"
                        onClick={handleAdd} />
                </React.Fragment>
            }
        </div>
    )
}

export default InputNumeric;