export const en = {
    ui: {
        BTN_NEW_WORKORDER: 'New workorder',
        DEPARTMENTS: 'Departments',
        DEPARTMENT: 'Department',
        PRIORITY: 'Priority',
        REPORTS: 'Reports',
        REPORTS_HIGH_PRIORITY: 'High priority',
        REPORTS_CREATED_BY_ME: 'Created by me',
        REPORTS_ASSIGNED_TO_ME: 'Assigned to me',
        REPORTS_RECENTLY_COMPLETED: 'Recently completed',
        REPORTS_RECENTLY_DELETED: 'Recently deleted',
        STATISTICS: 'Statistics',
        CUSTOM_REPORTS: 'Custom reports',
        RECYCLE_BIN: 'Recycle bin',
        RECENTLY_CLOSED: 'Recently closed',
        QUICK_ACCESS: 'Quick access',
        FILTER_WORKORDER_PLACEHOLDER: 'Filter workorders...',
        PROFILE: 'Profile',
        EDIT_PROFILE: 'Edit profile',
        LOG_OUT: 'Log out',
        SETTINGS: 'Settings',
        INVENTORY: 'Inventory',
        MAP: 'Map',
        DASHBOARD: 'Dashboard',
        STATISTICS_DASHBOARD: 'Statistics',
        CALENDAR: 'Calendar',
        CALENDAR_PERSPECTIVE: 'Calendar perspective',
        WORK_ORDERS: 'Work orders',
        CLOSED_WORK_ORDERS: 'Closed work orders',
        OPEN_WORK_ORDERS: 'Open work orders',
        EXTRA_WORK_WORK_ORDERS: 'ÄTA work orders',
        TIME_REPORTING: 'Time reporting',
        TIME_REPORTING_STATISTIC_HEADER: 'Techinician time reports',
        AGREEMENT_INVOICES: 'Agreement invoices',
        LIST: 'List',
        SCHEDULED: 'Scheduled',
        AGREEMENT_INVOICE_SCHEDULED_AT: 'Scheduled',
        NO_WORKS_PLANNED: 'No planned work orders',
        SETTINGS_GENERAL: 'General',
        SETTINGS_USERS: 'Users',
        SETTINGS_ACLS: 'Access levels',
        SETTINGS_WORKORDER: 'Workorder',
        SETTINGS_BUSINESS_OBJECT: 'Object',
        SETTINGS_PROPOSAL: 'Proposal',
        SETTINGS_SALES_ORDER: 'Sales order',
        SETTINGS_ARTICLES: 'Articles',
        SETTINGS_ARTICLES_PRICE_LISTS: 'Price lists',
        SETTINGS_ARTICLES_GROUPS: 'Article groups',
        SETTINGS_ARTICLES_SUPPLIER_ARTICLES: 'Supplier articles',
        SETTINGS_ARTICLES_SBSC_PRODUCTS: 'SBSC articles',
        SETTINGS_DEVIATIONS: 'Deviations',
        SETTINGS_PROJECT_RESOURCE_PLANNING: 'Project resources',
        SETTINGS_IMPORT_EXPORT: 'Import & Export',
        SETTINGS_SUPPLIERS: 'Suppliers',
        USERPICKER_ADD_PEOPLE: 'Add people',
        TAGPICKER_ADD_TAG: 'Add tags',
        BOPICKER_ADD: 'Add',
        CUSTOMERPICKER_SELECT: 'Select customer',
        MODELPICKER_SELECT: 'Select model',
        WORKORDERPICKER_SELECT: 'Search work order',
        SUPPLIERPICKER_SELECT: 'Search supplier',
        ARTICLEPICKER_SELECT: 'Search article',
        PROJECTPICKER_SELECT: 'Search project',
        BOPICKER_SELECT: 'Search...',
        LOCATIONPICKER_SEARCH: 'Search for locati#on',
        CUSTOMERS: 'Customers',
        MODELS: 'Models',
        SUPPLIERS: 'Suppliers',
        AGREEMENTS: 'Agreements',
        CUSTOMER: 'Customer',
        CUSTOMER_PRICE_LIST: 'Customer price list',
        PRICE_LIST: 'Price list',
        GENERAL: 'General',
        AGREEMENT: 'Agreement',
        HISTORY: 'History',
        SPECIFICATION: 'Specification',
        OBJECTS: 'Objects',
        ARTICLES: 'Articles',
        CUSTOMER_EDIT_DIALOG_TITLE: 'Edit customer',
        NEW_CUSTOMER_DIALOG_TITLE: 'New customer',
        USER_INVITE_DIALOG_TITLE: 'Register new user',
        NEW_BUSINESS_OBJECT_MODAL_TITLE: 'New object',
        ARTICLE_EDIT_DIALOG_TITLE: 'Edit article',
        ACL_EDIT_DIALOG_TITLE: 'Edit access level',
        DEPARTMENT_EDIT_TITLE: 'Edit department',
        USER_EDIT_DIALOG_TITLE: 'Edit user',
        MODEL_EDIT_DIALOG_TITLE: 'Edit model',
        NEW_AGREEMENT_MODAL_TITLE: 'New agreement',
        STATUS_ACTIVE: 'Active',
        STATUS_INACTIVE: 'Inactive',
        STATUS_REGISTERED: 'User invited..',
        SEARCH: 'Search...',
        FIND_ANYTHING: 'Find anything...',
        RELOAD: 'Reload',
        BACK: 'Back',
        SORT: 'Sort',
        VIEW: 'View',
        STATUS: 'Status',
        VIEW_ALL: 'View all',
        EDIT: 'Edit',
        EDIT_INTERNAL_INFO: 'Edit internal info',
        ADD: 'Add',
        SAVE: 'Save',
        SAVE_AND_CLOSE: 'Save and close',
        RESEND_REGISTRATION_INVITATION: 'Resend invitation',
        MORE_ACTIONS: 'More actions',
        PLEASE_CONFIRM_ACTION: 'Please confirm',
        ACTION_CONFIRM: 'Confirm',
        ACTION_CANCEL: 'Cancel',
        USER: 'User',
        INVITE_USER: 'Invite user',
        REGISTER_NEW_USER: 'Register new user',
        REMOVE: 'Remove',
        REMOVE_ROW: 'Remove row',
        DOWNLOAD: 'Download',
        DOWNLOAD_ALL: 'Download all',
        SELECT: 'Select',
        FILTER: 'Filter',
        RESET_FILTERS: 'Clear all',
        FILTER_BY_DEPARTMENT: 'By department',
        FILTER_BY_USER: 'By user',
        FILTER_BY_CUSTOMER: 'By customer',
        FILTER_BY_PRIORITY: 'By priority',
        FILTER_BY_ASSIGNEE: 'Filter assignee',
        SELECT_ASSIGNEE: 'Select assignee',
        INCLUDE_SCHEDULED_WORK_ORDERS: 'Include scheduled',
        PIECE_SHORT: 'pc',
        PIECES_SHORT: 'pcs',
        UNASSIGNED: 'Unassigned',
        PLAN_WORK_ORDER: 'Plan work order', 
        ON_HOLD: 'On hold',
        DATE: 'Date',
        USERS_VIEW: 'Users view',
        LIST_VIEW: 'List view',
        CALENDAR_VIEW: 'Calendar view',
        ADD_NOTE: 'Add note',
        ADD_NEW_ROW: 'Add row',
        NO_RESULTS_FOUND: 'No results found',
        SEARCHING: 'Searching...',
        DELETE: 'Delete',
        CLOSE: 'Close',
        CREATE_NEW_TAG: 'Create new tag',
        VIEW_HISTORY: 'View history',
        SEND_EMAIL: 'Send email',
        SEND_EMAIL_TO_ME: 'Email to me',
        PRINT: 'Print',
        CLOSE_WORKORDER: 'Close work order',
        CREATE_WORKORDER: 'Create new work order',
        ENABLED: 'Enabled',
        DISABLED: 'Disabled',
        TOTAL: 'Total',
        N_MORE: '{{ count }} more...',
        SUBTOTAL: 'Subtotal',
        START: 'Start',
        END: 'End',
        WORKORDERS_NOT_FOUND: 'Work orders not found',
        LOAD_MORE: 'Load more',
        PROCESSING: 'Processing...',
        INTAKE_DATE: 'Created',
        UPDATE_DATE: 'Updated',
        NEWER_FIRST: 'newer first',
        OLDER_FIRST: 'older first',
        SCHEDULED_TO_DESC: 'scheduled last',
        SCHEDULED_TO_ASC: 'scheduled next',
        UPDATED: 'Updated',
        CREATED: 'Created',
        CREATED_AT: 'Created {{datetime}}',
        CREATED_AT_BY_USER: 'Created {{datetime}} by {{user}}',
        CREATED_BY: 'Created by',
        CLOSED: 'Closed',
        MANAGE_OBJECTS: 'Manage business objects',
        MANAGE_CUSTOMERS: 'Manage customers',
        MANAGE_MODELS: 'Manage models',
        MANAGE_ARTICLES: 'Manage articles',
        MANAGE_AGREEMENTS: 'Manage agreements',
        MANAGE_SUPPLIERS: 'Manage suppliers',
        CREATE_NEW_WORKTYPE: 'Create new work type',
        CREATE_NEW_STATUS: 'Create new status',
        DEFAULT_TYPE: 'Default type',
        DEFAULT_TAG: 'Default tag',
        DEFAULT_STATUS: 'Default work order status',
        DEFAULT_LANGUAGE: 'Default language',
        CLOSING: 'Closing',
        SELECT_DEFAULT: 'Select default',
        SELECT_CLOSING: 'Select closing',
        SHOW_TODAY: 'Show today',
        SELECT_DATE: 'Select date',
        CLOSED_AT: 'Closed at',
        RECIPIENT: 'Recipient',
        INVOICE: 'Invoice',
        INVOICES: 'Invoices',
        INVOICE_NR: 'Invoice number',
        YES: 'Yes',
        NO: 'No',
        ACTIVATED: 'Activated',
        INACTIVATED: 'Inactivated',
        VAT: 'VAT',
        PDF: 'PDF',
        LIMIT_NONE_BTN: 'ALL',
        LIMIT_USER_BTN: 'ASSIGNED TO USER',
        LIMIT_CREATED_BY_USER_BTN: 'CREATED BY USER',
        DISCOUNT_PLACEHOLDER: 'Discount in % or ',
        TIME_REPORTING_PERIODS: 'Time report periods',
        TIME_REPORTING_PERIODS_WEEKEND: 'Time report periods for weekend',
        TIME_REPORTS_ON_WORK_ORDERS: 'On work orders',
        TIME_REPORTS_ON_WORK_ORDERS_WEEKEND: 'On work orders - weekends',
        TIME_REPORTS_OTHER: 'Other',
        TIME_REPORTS_OTHER_ATTENDING: 'Other - attending',
        TIME_REPORTS_OTHER_ABSENCE: 'Other - absence',
        TIME_REPORTS_DISTANCE: 'KM',
        TIME_REPORT_PERIOD_DESCRIPTION: 'Label',
        TIME_REPORTING_LOCKING: 'Time reports locking',
        TIME_REPORTS_GET_LOCKED_ON_DAY_OF_NEXT_MONTH:
            'Reports will be locked on the {{day}} day of the following month.',
        TIME_HOURS: '{{count}}h',
        TIME_MINUTES: '{{count}}min',
        TIMEREPORT_FOR_USER: 'Time report for {{name}}',
        LOCK: 'Lock',
        UNLOCK: 'Unlock',
        LOCKED: 'Locked',
        WORK_ORDER: 'Work order',
        AGREEMENT_INVOICE: 'Agreement invoice',
        EXPORT_TO_EXCEL: 'Export to Excel',
        UPLOADING_FILE: 'Uploading {{fileName}}',
        NO_PREVIEW_AVAILABLE: 'No preview',
        SAVING: 'Saving...',
        ALL_CHANGES_SAVED: 'All changes saved',
        ARTICLE_STOCK: 'Stock',
        ARTICLES_IN_STOCK: 'In stock',
        NEW_SUPPLIER_MODAL_TITLE: 'New supplier',
        NEW_DOORAUTOMATIC_MODAL_TITLE: 'New doorautomatic',
        INTERFACE: 'Interface',
        INTERFACE_WORKORDER_LIST_TYPE: 'Workorder list type',
        INTERFACE_WORKORDER_LIST_TYPE_SINGLE: 'Single column',
        INTERFACE_WORKORDER_LIST_TYPE_SPLIT: '2 columns',
        SHOW_ON_MAP: 'Show on map',
        OPEN_DIRECTIONS_IN_GOOGLE_MAPS: 'Open directions in Google Maps',
        COUNT_OF_MORE_EVENTS: '{{count}} more...',
        MARGIN_PERCENTAGE: '{{margin}}% margin',
        MARGIN_PERCENTAGE_SHORT: '{{margin}}% margin',
        MANAGE_STOCK: 'Manage stock',
        DELIVER_IN_UNITS: 'Deliver in units',
        ADD_TO_STOCK: 'Add to stock',
        ARTICLE_ACTIVATE_IN_DELIVERY: 'Activate in-delivery',
        ARTICLE_SHOW_SPSC: 'Show certified info',
        ARTICLE_STOCK_HISTORY_LOGS: 'Logs',
        STOCK_RESERVED: 'Reserved in work orders',
        STOCK_INVOICED: 'Latest invoiced',
        STOCK_TRANSACTIONS: 'Transactions',
        CONFIGURE: 'Configure',
        PREVIEW: 'Preview',
        PROJECT: 'Project',
        PROJECTS: 'Projects',
        ADD_NEW_PROJECT: 'New project',
        PROJECT_TASK: 'Task',
        SALES_ORDERS: 'Orders',
        PROPOSALS: 'Proposals',
        SELECT_ARTICLE: 'Select article',
        SELECT_ARTICLE_GROUPS: 'Select article groups',
        SELECT_ARTICLE_MORE_OPTIONS_BACK: 'Back...',
        SELECT_ARTICLE_MORE_OPTIONS: 'More...',
        SELECT_ARTICLE_FILTERING_IS_ACTIVE: 'Group filtering is on!',
        ADD_COMMENT: 'Add comment',
        ADD_COMMENT_ROW: 'Add comment row',
        ADD_ARTICLE_ROW: 'Add article row',
        ADD_SUMMARY_ROW: 'Add summary row',
        FILTER_SALES_ORDERS: 'Find orders',
        FILTER_PROPOSALS: 'Find proposals',
        DELETE_PROJECT: 'Delete project',
        CLOSE_PROJECT: 'Close project',
        REOPEN_PROJECT: 'Re-open project',
        PROPOSAL_STATUS_ACTIVE: 'Active',
        PROPOSAL_STATUS_ACCEPTED: 'Accepted',
        PROPOSAL_STATUS_DECLINED: 'Declined',
        PROPOSAL_ACCEPT: 'Accept and create order',
        PROPOSAL_ACCEPT_AND_CREATE_WORKORDER: 'Accept and create work order',
        PROPOSAL_DECLINE: 'Decline and hide',
        PROPOSAL_IS_ACCEPTED: 'Accepted',
        PROPOSAL_IS_ACCEPTED_AT_BY_USER: 'Accepted {{datetime}} ({{user}})',
        PROPOSAL_IS_DECLINED: 'Declined',
        PROPOSAL_IS_DECLINED_AT_BY_USER: 'Declined {{datetime}} ({{user}})',
        PROPOSAL_CREATE_NEW: 'Create proposal',
        FILTER_DEVIATIONS: 'Filter deviations',
        DEVIATION_CREATE_NEW: 'New deviation',
        PROPOSAL_CLEAR_CUSTOMER: 'Clear customer',
        PROPOSAL_MAKE_COPY: 'Make a copy',
        WORK_ORDER_MAKE_COPY: 'Make a copy',
        MAKING_COPY_PLEASE_WAIT: 'Making a copy. Please wait.',
        SET_AS_PRIMARY_ADDRESS: 'Set as primary address',
        ADD_NEW_POTENTIAL_CUSTOMER: 'Add new potential customer',
        SHOW_REGULAR_CUSTOMERS: 'Customers',
        SHOW_POTENTIAL_CUSTOMERS: 'Potential customers',
        ADD_CUSTOMER_NR: 'Add customer number',
        NEW_CUSTOMER_NUMBER_DIALOG_TITLE: 'Add customer number',
        WORD_EXPORT: 'Export to word',
        MODIFY_ARTICLE_DESCRIPTION: 'Modify description',
        CREATE_INVOICE: 'Create invoice',
        VIEW_INVOICE: 'View invoice: {{invoiceNr}}',
        UPDATE_ADDRESS_FROM_CUSTOMER_ADDRESS:
            'Update address with address from customer',
        ADD_SIGNATURE: 'Add signature',
        CLEAR_SIGNATURE: 'Clear',
        SERVICE_REPORT_SAVE_AND_GENERATE_DOCUMENT: 'Save and generate document',
        SERVICE_REPORT_CLOSED_AT_BY_USER: 'Closed {{datetime}} ({{user}})',
        ABSENCE_INFO: 'Absence info',
        MY_TIME_REPORTS: 'My time reports',
        TIME_REPORTED_FOR_DAY: '{{time}} reported',
        ALL_ATTEND_TIME_REPORTS: 'All time reports',
        ALL_ATTEND_TIME_REPORTED_FOR_DAY:
            '{{reported}}/{{expected}} hours reported',
        INVOICE_MARKING_OPTION_CONTACT_COST_CENTER:
            'Contact cost center - {{value}}',
        INVOICE_MARKING_OPTION_CUSTOMER: 'Customer - {{value}}',
        INVOICE_MARKING_OPTION_OBJECT: 'Agreement - {{value}}',
        DEFAULT: 'Default',
        INTERNAL_MESSAGES: 'Internal messages',
        MY_WORKORDERS: 'My upcoming workorders',
        START_TIMER: 'START',
        STOP_TIMER: 'STOP',
        TIME_REPORTS_WAITING: 'Waiting...',
        TIME_REPORTS_DISTANCE_KM: 'KM',
        TIME_REPORTS_REST_TIME: 'Rest time',
        TIME_REPORTS_TRAVEL_TIME: 'Travel time',
        TIME_REPORTS_REPORTED_DISTANCE_KM: '{{total}} KM',
        TIME_REPORTS_REPORTED_TRAVEL_TIME: '{{time}} travel',
        CUSTOMER_OBJECT_CONTACT_COUNT:
            'There is {{count}} contact on objects for this customer',
        CUSTOMER_OBJECT_CONTACT_COUNT_plural:
            'There are {{count}} contacts on objects for this customer',
        PERCENTAGE_OF_ESTIMATE: '{{percentage}} of estimate',
        PROJECT_TIME_REPORTED_ON_WORK_ORDERS: '{{time}} on work orders',
        PROJECT_TIME_REPORTED_ON_OPEN_WORK_ORDERS:
            '{{time}} on open work orders',
        PROJECT_TIME_REPORTED_ON_CLOSED_WORK_ORDERS:
            '{{time}} on closed work orders',
        PROJECT_COST_PRICE_ON_WORK_ORDERS: '{{value}} on work orders',
        PROJECT_COST_PRICE_ON_OPEN_WORK_ORDERS: '{{value}} on open work orders',
        PROJECT_COST_PRICE_ON_CLOSED_WORK_ORDERS:
            '{{value}} on closed work orders',
        PROJECT_ONGOING_COST: 'Ongoing cost',
        TIME_REPORTING_CONFIRM_FINISHED_WITH_WORKORDER:
            'I am finished with all work on this work order',
        TIME_REPORTING_USERS_FINISHED: '{{initials}} finished',
        TIME_REPORTING_USER_FINISHED: 'OK',
        SCHEDULE: 'Plan time',
        SCHEDULING_ADD_TIME: 'in time',
        SCHEDULING_ADD_HOURS: 'in hours',
        SCHEDULED_TO: 'Scheduled to',
        SCHEDULED_ON_N_DAYS: 'Planned on 1 day',
        SCHEDULED_ON_N_DAYS_plural: 'Planned on {{count}} days',
        PROJECT_WORK_ORDER_MARK_INCLUDED: 'Mark as included',
        PROJECT_WORK_ORDER_MARK_INVOICED: 'Add to invoicing backlog',
        PROJECT_INVOICING_BACKLOG: 'Backlog',
        PROJECT_NEW_INVOICE: 'Create invoice',
        PROJECT_INVOICE_NR: 'Invoice #{{number}}',
        PROJECT_WORK_ORDER_ARTICLE_MARK_INVOICED: 'Add to invoice',
        PROJECT_WORK_ORDER_ARTICLE_MARK_INCLUDED: 'Mark as included',
        PROJECT_DELETE_INVOICE: 'Delete invoice',
        PROJECT_FINALIZE_INVOICE: 'Close invoice',
        PROJECT_BACKLOG_SHOW_ALL: 'Show all',
        PROJECT_BACKLOG_SHOW_OPEN: 'Show open',
        PROJECT_ARTICLE_INVOICED: 'Invoiced',
        PROJECT_ARTICLE_INCLUDED: 'Included',
        WORKORDER_INCLUDE_IN_PROJECT: 'Include in project',
        WORKORDER_CREATE_DEVIATION: 'Create deviation',
        PROJECT_TYPE_CLOSED: 'Closed',
        PROJECT_TYPE_ONGOING: 'Active',
        PROJECT_ONGOING_COST_REPORT_NEW: '> Report',
        PROJECT_ONGOING_COST_REPORT: 'Report',
        PROJECT_ONGOING_COST_REPORT_LAST_SUBMITTED: '{{price}} ({{date}})',
        WORK_ORDER_ARTICLE_FROM_SUPPLIER_SHORT: 'From',
        SERVICE_REPORT_WILL_BE_EMAILED_TO: 'Will be emailed to',
        SERVICE_REPORT_WAS_EMAILED_TO: 'Emailed to',
        COLLAPSE_CUSTOMER_ADDRESSES_LIST: 'Hide all',
        EXTEND_CUSTOMER_ADDRESSES_LIST: 'Show all',
        EDIT_ARTICLE_GROUP: 'Edit article group',
        EDIT_PRICE_LIST: 'Edit price list',
        PRICING_TYPE: 'Pricing type',
        PRICING_TYPE_ENABLED_ON_ARTICLE_GROUPS: 'Enabled on article groups',
        PRICING_TYPE_DISCOUNT: 'Discount',
        PRICING_TYPE_ADDITION_ON_PURCHASE_PRICE: 'Markup % on purchase price',
        PRICING_TYPE_ADDITION_ON_SALES_PRICE: 'Markup % on sales price',
        NEW_PRICE_LIST_PRICING_TYPE: 'New pricing type',
        OPEN_BUSINESS_OBJECT_SERVICE_LOG: 'Daily service book',
        CUSTOMER_ZONE_AUTH_CODE_SHOW: 'Show',
        CUSTOMER_ZONE_AUTH_CODE_HIDE: 'Hide',
        SALES_ORDER_VIEW_AND_CREATE_PDF_RECEIPT: 'Create manually paid receipt',
        SALES_ORDER_VIEW_PDF_RECEIPT: 'View manual reciept',
        RENAME: 'Rename',
        PUBLISH_FILE: 'Publish',
        UNPUBLISH_FILE: 'Unpublish',
        INCLUDING_VAT: 'incl. VAT',
        EXCLUDING_VAT: 'excl. VAT',
        INTERFACE_START_PAGE: 'Startpage',
        INTERFACE_START_PAGE_WORKORDERS: 'Work orders',
        INTERFACE_START_PAGE_DASHBOARD: 'Dashboard',
        WORK_ORDER_INVOICE_INCLUDED_IN_PROJECT: 'Included in project',
        UPDATE_SUPPLIER_ARTICLES: 'Upload new prices',
        SELECT_DEPARTMENT: 'Select department',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_HEADER:
            'Open workorders to invoice per department',
        AGREEMENT_INVOICING_KPI_HEADER: 'Summary of agreement invoicing',
        OPEN_PROJECTS_KPI_HEADER: 'Summary of open projects',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_TO_INVOICE_ON_PURCHASE_PRICE:
            'On material',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_TO_INVOICE_ON_TIME: 'On time',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_DEBIT_FACTOR_ON_PURCHASE_PRICE:
            'Debit factor purchase price',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_HOUR_COST: 'Hour cost',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_DISTANCE_COST: 'KM cost',
        DEPARTMENT_NONE: 'Not selected',
        WORK_ORDER_INVOICE_MARKING_PLACEHOLDER: 'Invoice marking',
        CALENDAR_SHOW_ONLY_MY_CALENDAR: 'Display only my calendar',
        UPLOADING: 'Uploading...',
        ON: 'On',
        OFF: 'Off',
        CLOSE_ALL_TABS: 'Close all tabs',
        CUSTOMER_TYPE: 'Type',
        CUSTOMER_TYPE_ALL: 'All',
        CUSTOMER_PRIVATE: 'Private',
        CUSTOMER_COMPANY: 'Company',
        CUSTOMER_PRIVATE_PERSON_ID_PLACEHOLDER: 'Personal number',
        CUSTOMER_PRIVATE_PERSON_NAME_PLACEHOLDER: 'Name',
        TAX_REDUCTION_MARK_NONE: 'None',
        TAX_REDUCTION_MARK_ROT: 'ROT',
        TAX_REDUCTION_MARK_RUT: 'RUT',
        TAX_REDUCTION_MARK_GREEN: 'Green',
        WORKORDER_TIMEREPORTS_N_ATTACHED: '{{selected}} of {{total}} attached',
        WORKORDER_TIMEREPORTS_ATTACH: 'Attach time reports to invoice',
        WORKORDER_TIMEREPORTS_ATTACH_ALL: 'Select all',
        WORKORDER_TIMEREPORTS_ATTACHED: 'Attached',
        WORKORDER_TIMEREPORTS_NOT_ATTACHED: 'Not attached',
        WORK_ORDER_SCHEDULING_STATUS: 'Scheduling status',
        WORK_ORDER_SCHEDULE_STATUS_BOTH: 'All',
        WORK_ORDER_SCHEDULE_STATUS_NOT_SCHEDULED: 'Only non-scheduled',
        WORK_ORDER_SCHEDULE_STATUS_SCHEDULED: 'Only scheduled',
        ADD_WORK_ORDER_INTERNAL_INFO: 'Add internal info',
        DEVIATIONS: 'Deviations',
        DEVIATION_VIEW_LOG: 'View log',
        DEVIATION_CHANGE_STATE_STARTED: 'Started',
        DEVIATION_CHANGE_STATE_DEFINED: 'Defined',
        DEVIATION_CHANGE_STATE_NEEDS_WORK: 'Needs improvements',
        DEVIATION_CHANGE_STATE_APPROVED: 'Approved',
        DEVIATION_STATUS_OPEN: 'Open',
        DEVIATION_STATUS_CLOSED: 'Closed',
        DEVIATION_STATUS_ALL: 'All',
        CLOSE_DEVIATION: 'Close deviation',
        ARTICLE: 'Article',
        OBJECT: 'Object',
        SUPPLIER: 'Supplier',
        DEVIATION_DASHBOARD_GO_TO_LIST: 'View all deviations in list',
        DEVIATION_DASHBOARD_BY_CATEGORY: 'Deviations by category',
        DEVIATION_DASHBOARD_BY_RELATION: 'Most frequent deviations',
        DEVIATION_DASHBOARD_LATEST: 'Latest deviations',
        ADD_WORK_ORDER_ON_SITE_CONTACT: 'Add contact person',
        PROJECT_TIME_REPORTS_EXPORT_EXCEL: 'Export time reports',
        PROJECT_TIME_REPORTS_DOWNLOAD_EXCEL: 'Download',
        PROJECT_TIME_REPORTS_EXCEL_FILENAME: 'Export_timereports',
        OPEN_PROJECTS_SUMMARY_DOWNLOAD_EXCEL: 'Download projects summary',
        OPEN_PROJECT_SUMMARY_EXCEL_FILENAME: 'Projects',
        ARTICLE_PRODUCT_TYPE_SERVICE: 'Service',
        ARTICLE_PRODUCT_TYPE_STOCK: 'Stock',
        ARTICLE_PRODUCT_TYPE_NON_STOCK: 'Non-stock',
        WORKORDER_TIMEREPORTS_SHOWING_ONLY_MINE: 'Showing only mine',
        WORKORDER_TIMEREPORTS_SHOWING_ALL: 'Showing all',
        WORKORDER_TIMEREPORTS_THIS_WEEK: 'This week',
        WORKORDER_TIMEREPORTS_PREVIOUS: 'Previously',
        WORKORDER_TIMEREPORTS_PREVIOUS_SUMMARY: 'Previous summary',
        GO_TO_PROPOSAL: 'Go to proposal',
        PROJECT_SORT_BY_TIME: 'Ongoing',
        PROJECT_SORT_BY_NAME: 'Alphabetical',
        PROJECT_SORT_BY_RESPONSIBLE_USER: 'Project manager',
        WORKORDER_PLANNED: 'Planned',
        WORKORDER_CREATED_TIME: 'Created {{time}}',
        ADD_ACTIVITY: 'Add activity',
        ADD_SECTION: 'Add section',
        ADD_TABLE: 'Add table',
        ADD_OPTION: 'Add option',
        WORK_ORDER_WAS_CLOSED: 'Work order closed: {{time}}',
        SELECT_TIME_RANGE: 'Select time range',
        EXPORT_WORKORDER_TIMEREPORTS: 'Export time reports',
        BUSINESS_OBJECT_DETAILED_SEARCH_ENABLED: 'Detailed search',
        BUSINESS_OBJECT_DETAILED_SEARCH_IS_ENABLED_MESSAGE:
            'Detailed search is active!',
        WORK_ORDER_ORDER_CONFIRMATION: 'Confirmation email',
        WORK_ORDER_ORDER_CONFIRMATION_SEND: 'Send now',
        WORK_ORDER_ORDER_CONFIRMATION_IGNORE: 'Do not send',
        WORK_ORDER_ORDER_CONFIRMATION_SENT_TO:
            'Confirmation email sent to {{to}}, {{time}}',
        WORK_ORDER_ORDER_CONFIRMATION_NOT_SENT: 'Confirmation email not sent',
        WORK_ORDER_ORDER_FINISH_CONFIRMATION:
            'Send order confirmation to customer about finished work?',
        WORK_ORDER_ORDER_FINISH_CONFIRMATION_RECIPIENT: 'Recipient',
        PROJECTS_PLANNING_PERSPECTIVE: 'Planning perspective',
        PROJECTS_PLANNING_PERSPECTIVE_PROJECT: 'Projects',
        PROJECTS_PLANNING_PERSPECTIVE_RESOURCES: 'Resources',
        COMPENSATION_REPORTING_SETTINGS_LABEL: 'Compensation types',
        TIME_REPORTS_COMPENSATION_REPORTS: 'Compensation',
        COMPANY_HOLIDAYS_SETTINGS_LABEL: 'Company holidays',
        OPEN_WORK_ORDER_COUNT_ON_OBJECT_SHORT: '{{count}} open work order',
        OPEN_WORK_ORDER_COUNT_ON_OBJECT_SHORT_plural:
            '{{count}} open work orders',
        OPEN_WORK_ORDER_COUNT_ON_OBJECT:
            'There is <0>{{count}}</0> open work order',
        OPEN_WORK_ORDER_COUNT_ON_OBJECT_plural:
            'There are <0>{{count}}</0> open work orders',
        WORK_ORDER_ARTICLE_REPORT_USER_DISTRIBUTION_SPLIT: 'SPLIT',
        WORK_ORDER_USER_DISTRIBUTION_SPLIT_HEADER: 'SPLIT',
        WORKORDER_HISTORY_EXCEL_FILENAME: 'work_orders',
        WORK_ORDER_USER_DISTRIBUTION_KPI_TITLE: 'Margin distribution',
        WORK_ORDER_USER_DISTRIBUTION_ON_OPEN_WORKORDERS_KPI_TITLE:
            'Margin distribution on open work orders',
        WORK_ORDER_USER_DISTRIBUTION_KPI_FOR_USER:
            '{{month}}: Articles for {{name}}, total margin: {{totalMargin}}',
        REOPEN_PROPOSAL: 'Open proposal',
        REOPEN_WORKORDER: 'Open work order',
        OR: 'OR',
        ANLAGGARINTYG_PDF_EXPORT_ADD_PAGES: 'Append pages',
        ANLAGGARINTYG_PDF_EXPORT_ADD_SIGNATURE: 'Add signature',
        PROJECT_EXTRA_WORK_STATUS_PENDING: 'Pending',
        PROJECT_EXTRA_WORK_STATUS_ACCEPTED: 'Accepted',
        PROJECT_EXTRA_WORK_STATUS_DECLINED: 'Declined',
        PROJECT_EXTRA_WORK_STATUS_EDITOR_HEADER: 'Accept or decline ÄTA?',
        PROJECT_EXTRA_WORK_ACCEPT: 'Accept ÄTA',
        PROJECT_EXTRA_WORK_DECLINE: 'Decline ÄTA',
        PROJECT_WORK_ORDER_CREATE_INVOICE: 'Create direct invoice',
        PROJECT_INVOICE_ROW_IS_IN_SCOPE: 'In scope',
        PROJECT_INVOICE_ROW_IS_OUT_OF_SCOPE: 'Out of scope',
        PROJECT_WORK_ORDERS_SHOW_LESS: 'Show less',
        PROJECT_WORK_ORDERS_SHOW_MORE: 'Show {{count}} more work orders',
        PROJECT_OUTCOME_REGULAR_SCOPE: 'In project scope',
        PROJECT_OUTCOME_EXTRA_SCOPE: 'ÄTA / Extended scope',
        PROJECT_OUTCOME_EXTRA_TOTAL_INVOICED: '{{value}} totally invoiced',
        SERVICE_DISTRIBUTION_SCHEDULE_SERVICE_WORK_ORDERS_NOW:
            'Schedule work order now?',
        SERVICE_DISTRIBUTION_SCHEDULE_SERVICE_WORK_ORDERS_NOW_plural:
            'Schedule {{count}} work orders now?',
        SERVICE_DISTRIBUTION_SCHEDULE_SERVICE_WORK_ORDERS_CONFIRM: 'Confirm',
        PROJECT_OUTCOME_SCOPE_ALL: 'Project summary including ÄTA',
        WORK_ORDER_PER_TAG_KPI_TITLE: 'Number of work orders per tag',
        START_DATE: 'Start date',
        END_DATE: 'End date',
        SUPPLIER_TAG_EDITED_BY: 'Set by: {{username}} ({{datetime}})',
        SELECT_PERIOD: 'Select period',
        UNTIL: 'Until',
        DISPLAY: 'Display',
        HIDE: 'Hide',
        TIME_REPORTS_SCHEDULE_SINGLE_DAY: '<< Schedule single day',
        TIME_REPORTS_SCHEDULE_MANY_DAYS: 'Schedule many days >>',
        DOORAUTOMATICS: 'Doorautomatics',
        MANAGE_DOORAUTOMATICS: 'Manage doorautomatics',
        WORKORDER_ATTACHED_DOORAUTOMATICS: 'Attached doorautomatics',
        WORKORDER_CREATE_REMINDER: 'Create reminder',
        WORK_ORDER_REMINDER_MODAL_HEADER: 'Work order reminder',
        WORKORDER_REMINDER_SCHEDULED_AT: 'Reminder scheduled at',
        TOPBAR_SUPPLIER_INVOICES: 'Approve costs',
        SUPPLIER_INVOICES: 'Supplier invoices',
        SUPPLIER_INVOICE_STATUS_PENDING: 'To approve',
        SUPPLIER_INVOICE_STATUS_APPROVED: 'Approved',
        SUPPLIER_INVOICE_STATUS_DECLINED: 'Declined',
        SUPPLIER_INVOICE_STATUS_ALL: 'All',
        REOPEN_SERVICE_REPORT: 'Open form',
        SUPPLIER_INVOICE_APPROVE_CONTENT: 'Approve',
        SUPPLIER_INVOICE_APPROVE_PAYMENT: 'Approve',
        SUPPLIER_INVOICE_DELETE: 'Delete',
        WORKORDER_CLOSE_CHANGE_ERP_INVOICE_DATE: 'Adjust invoice date sent to ERP',
        WORKORDER_CLOSE_CHANGE_ERP_DELIVERY_DATE: 'Adjust delivery date sent to ERP',
        WORKORDER_CLOSE_INVOICE_WITH_REVERSE_VAT: 'Invoice will be created with reverse VAT',
        WORKORDER_CLOSE_INVOICE_ADD_INVOICE_FEE: 'Add invoice fee',
        ON_CALL: 'On-call',
        CLEAR_ON_CALL_DAY: 'Clear',
        CLEAR_ON_CALL_WEEK: 'Clear',
        SELECT_ON_CALL_FOR_WEEK: 'Select for week',
        ON_CALL_ON_N_DAYS: '{{count}} day',
        ON_CALL_ON_N_DAYS_plural: '{{count}} days',
        WORK_ORDER_CHANGE_CUSTOMER: 'Change customer',
        WORK_ORDER_CHANGE_OBJECT: 'Change object',
        WORK_ORDER_CHANGE_CUSTOMER_CURRENT: 'Current customer',
        WORK_ORDER_CHANGE_CUSTOMER_SELECT_NEW: 'Select new customer for work order',
        WORK_ORDER_CHANGE_CUSTOMER_NEW: 'Selected customer',
        WORK_ORDER_CHANGE_CUSTOMER_SAVE: 'Apply change',
        WORK_ORDER_CHANGE_OBJECT_CURRENT: 'Current object',
        WORK_ORDER_CHANGE_OBJECT_SELECT_NEW: 'Select new object for work order',
        WORK_ORDER_CHANGE_OBJECT_NEW: 'Selected object',
        WORK_ORDER_CHANGE_OBJECT_SAVE: 'Apply change',
        PROJECT_PLANNING: 'Planning',
        PROJECT_ACTIVITY_TITLE: 'Activity description',
        OPEN_PROJECTS_KPI_OTHER_COSTS_IN_PERCENTAGE: 'Project extra costs in %',
        OPTION_DEFAULT: 'Default',
        OPTION_OPTIONAL: 'Optional',
        OPTION_TEXT_INPUT: 'Text Input',
        OPTION_YES_NO: 'Yes / No',
        OPTION_SELECTION: 'Selection',
        PROJECT_EXTERNAL_URL: 'External URL',
        PROJECT_EDIT_EXTERNAL_URL: 'Edit external URL',
        CUSTOMER_CONTACT_PRIORITIZED: 'Protocol',
        CUSTOMER_CONTACT_PRIORITIZED_TOOLTIP: 'Contact will receive protocol emails',
        CUSTOMER_HAS_OPEN_PROPOSALS: '{{count}} open proposals',
        CUSTOMERPICKER_CREATE_NEW_CUSTOMER: 'Could not find the customer? Create here',
        MARKUP_PERCENTAGE: 'Markup (%)',
        MARKUP_PERCENTAGE_SHORT: '{{value}}%',
        TOTAL_MARKUP: 'Markup',
        BUSINESS_OBJECT_CODES_ADD_CODE: 'Add code',
        BUSINESS_OBJECT_CODES_ADD_CODE_GROUP: 'Add group',
        BUSINESS_OBJECT_CODES_LOGS_HEADER: 'Access logs',
        BUSINESS_OBJECT_CODES_VIEW_LOGS: 'View log',
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_ADD_GROUP: '{{userName}} created group {{groupName}} on {{businessObjectId}}',
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_EDIT_GROUP: '{{userName}} changed group name from {{fromGroupName}} to {{toGroupName}} on {{businessObjectId}}',
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_DELETE_GROUP: '{{userName}} deleted group {{groupName}} on {{businessObjectId}}',
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_ADD_CODE: '{{userName}} created secret code {{codeName}} for group {{groupName}} on {{businessObjectId}}',
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_EDIT_CODE: '{{userName}} changed secret code name from {{fromCodeName}} to {{toCodeName}} for group {{groupName}} on {{businessObjectId}}',
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_EDIT_CODE_VALUE: '{{userName}} edited secret code {{codeName}} for group {{groupName}} on {{businessObjectId}}',
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_VIEW_CODE: '{{userName}} viewed secret code {{codeName}} for group {{groupName}} on {{businessObjectId}}',
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_COPY_CODE: '{{userName}} copied secret code {{codeName}} for group {{groupName}} on {{businessObjectId}}',
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_DELETE_CODE: '{{userName}} deleted code {{codeName}} for group {{groupName}} on {{businessObjectId}}',
        AUTHENTICATE_AZURE_EMAIL: 'Authenticate E-Mail for Azure',
        WORK_ORDER_HISTORY_FILTER_BY_ID_OVERRIDES_OTHER_FILTERS: 'Overrides all other filters'
    },
    form: {
        ID: 'ID',
        WORK_ORDER_ID: 'ID',
        ARTICLE_ID: 'Article nr',
        NAME: 'Name',
        SHORT_NAME: 'Avatar',
        EMAIL: 'E-mail',
        CUSTOMER_EMAIL: 'E-mail',
        PASSWORD: 'Password',
        AVATAR: 'Avatar',
        DEPARTMENT: 'Department',
        DEPARTMENT_ID: 'ID',
        PHONE: 'Phone',
        LANGUAGE: 'Language',
        INVOICE_LANGUAGE: 'Invoice language',
        OUR_REFERENCE_ON_AGREEMENT_INVOICES:
            'Our reference on agreement invoices',
        NOTIFICATIONS: 'Notifications',
        PUSH_NOTIFICATIONS: 'Push notifications',
        COMPANY_NAME: 'Company name',
        LOGO: 'Logo',
        INVOICE_DETAILS: 'Invoice details',
        ORGANIZATIONAL_NAME: 'Organizational name',
        ORGANIZATIONAL_NUMBER: 'Organizational number',
        VAT_REGISTRATION_NUMBER: 'VAT',
        VAT_PERCENTAGE: 'VAT percentage (%)',
        PLAN: 'Plan',
        TEXT: 'Text',
        NOTES: 'Notes',
        NOTE: 'Note',
        OBJECT: 'Object',
        DATE_FORMAT: 'Date format',
        TIME_FORMAT: 'Time format',
        CURRENCY: 'Currency',
        SHOW_WEEKEND: 'Show weekends',
        AUTHENTICATION: 'Authentication',
        CURRENT_PASSWORD: 'Current password',
        NEW_PASSWORD: 'New password',
        CONFIRM_NEW_PASSWORD: 'Confirm new password',
        UPDATE_PASSWORD: 'Update password',
        PASSWORD_REPEAT: 'Please repeat password',
        CUSTOMER: 'Customer',
        CUSTOMER_NUMBER: 'Customer number',
        TAGS: 'Tags',
        TAG: 'Tag',
        TIME_REPORT_SELECT_TAG: 'Select tag',
        TIME_REPORT: 'Time report',
        MATERIAL_REPORT: 'Material report',
        INVOICING: 'Invoicing',
        SCHEDULING: 'Schedule work',
        PLANNED_START_TIME: 'Planned start time',
        PLANNED_START_TIME_DATE: 'Planned start date',
        PLANNED_START_TIME_TIME: 'Planned start time',
        PLANNED_END_TIME: 'Planned end time',
        PLANNED_END_TIME_DATE: 'Planned end date',
        PLANNED_END_TIME_TIME: 'Planned end time',
        CONTACT: 'Contact',
        CONTACTS: 'Contacts',
        OBJECT_CONTACTS: 'Contacts',
        ON_SITE_CONTACT: 'Contact person',
        ON_SITE_CONTACT_NAME_PLACEHOLDER: 'Name',
        ON_SITE_CONTACT_EMAIL_PLACEHOLDER: 'Email',
        ON_SITE_CONTACT_PHONE_PLACEHOLDER: 'Phone',
        INTERNAL_RESPONSIBLE_PERSONS: 'Internal responsible persons',
        CUSTOMER_ORGANIZATIONAL_NUMBER: 'Organizational number',
        CUSTOMER_ADDITIONAL_INFO: 'Customer additional info',
        CUSTOMER_MARKINGS: 'Markings',
        PAYMENT_TERMS: 'Payment terms',
        REVERSE_VAT: 'Reverse vat',
        PAYMENT_TERM_IN_DAYS: 'Payment term in days',
        PAYMENT_DUE_DATE: 'Payment due',
        BG_ACCOUNT: 'BG account',
        CONTACT_NAME_PLACEHOLDER: 'Name',
        CONTACT_EMAIL_PLACEHOLDER: 'Email',
        CONTACT_PHONE_PLACEHOLDER: 'Phone',
        CONTACT_ROLE_PLACEHOLDER: 'Role',
        CONTACT_INVOICE_COST_CENTER_PLACEHOLDER: 'Cost center',
        CITY: 'City',
        OBJECT_COUNT: 'Objects',
        OBJECT_LOCATION_PLACEHOLDER: 'city',
        OBJECT_LOCATION_2_PLACEHOLDER: 'street, house',
        OBJECT_LOCATION_3_PLACEHOLDER: 'post index',
        OBJECT_AGREEMENT_TYPE_PLACEHOLDER: 'Agreement type',
        DATE: 'Date',
        VALID_FROM: 'Valid from',
        VALID_TO: 'Valid to',
        NEXT_INVOICE_SCHEDULED_TO: 'Next invoice is scheduled for',
        NEXT_INVOICE_PERIOD_FROM: 'Period start for next agreement invoice',
        NEXT_INVOICE_PERIOD_TO: 'Period end for next agreement invoice',
        AGREEMENT_INVOICE_FREQUENCY: 'Frequency (months)',
        AGREEMENT_INVOICE_PRINTED_PERIOD:
            'Period written on the next agreement invoice',
        NEXT_SERVICE_SCHEDULED_TO: 'Next work order is scheduled for',
        INVOICE_REGARDING_PERIOD: 'Invoice regarding period',
        ARTICLES: 'Articles',
        PRIORITY: 'Priority',
        PRIORITY_HIGH: 'High',
        PRIORITY_MEDIUM: 'Medium',
        PRIORITY_LOW: 'Low',
        ASSIGNEES: 'Assignees',
        ASSIGNEE: 'Assignee',
        LOCATION: 'City',
        LOCATION_2: 'Street',
        CUSTOMER_LOCATION_2: 'Street',
        LOCATION_3: 'Postal index',
        LOCATION_DESCRIPTION: 'Location details',
        LOCATION_STATE: 'State',
        LOCATION_STATE_PLACEHOLDER: 'State',
        LOCATION_COUNTRY: 'Country',
        LOCATION_COUNTRY_PLACEHOLDER: 'Country',
        AGREEMENT_TYPE: 'Agreement type',
        AGREEMENT_TYPES: 'Agreement types',
        DESCRIPTION: 'Description',
        ARTICLE_DESCRIPTION: 'Description',
        ACCESS_LEVEL_DESCRIPTION: 'Description',
        ACCESS_LEVEL_DASHBOARD: 'Dashboard',
        WORK_ORDER_DESCRIPTION: 'Description',
        WORK_ORDER_INTERNAL_INFO: 'Internal info',
        SUMMARY: 'Summary',
        TITLE: 'Title',
        FIELDS: 'Fields',
        MODEL: 'Model',
        CODE: 'Code',
        SERIAL: 'Serial code',
        STATUS: 'Status',
        COLOR: 'Color',
        PLAN_GOLD: 'Gold',
        INVOICE_CREATION: 'Invoice created',
        WORKORDER_CREATION: 'Work order',
        AGREEMENT_WORKORDER_GENERATION_DAY: 'Work order generation day',
        AGREEMENT_WORKORDER_GENERATION_INTERVAL:
            'Work order generation interval',
        AGREEMENT_INVOICE_GENERATION_DAY: 'Invoice generation day',
        AGREEMENT_INVOICE_GENERATION_INTERVAL: 'Invoice generation interval',
        AGREEMENT_WORKORDER_GENERATION_INCLUDE_ARTICLES:
            'Include articles in agreement work order',
        AGREEMENT_WORKORDER_GENERATION_INCLUDE_DESCRIPTION:
            'Include description to agreement work order',
        USER_COUNT: 'Users',
        ACCESS_LEVELS: 'Access levels',
        UNIT: 'Unit',
        PRICE: 'Price',
        PURCHASE_PRICE: 'Purchase price',
        REGULAR_PRICE: 'Regular price',
        SALES_PRICE: 'Sales price',
        RECOMMENDED_SALES_PRICE: 'Recommended sales price',
        TOTAL_PRICE: 'Total',
        USER: 'User',
        PERMISSIONS: 'Permissions',
        LIMITED_TO: 'Limited to',
        WORK_TYPE: 'Type',
        WORK_TYPES: 'Work types',
        STATUSES: 'Statuses',
        MULTIPLE_TECHNICIANS: 'Multiple technicians',
        MULTIPLE_DATES: 'Multiple dates',
        EXTRA_FIELDS: 'Extra fields',
        PRIORITIES_ENABLED: 'Priorities',
        PREFIX: 'Prefix',
        MIN_LENGTH: 'Min. id length',
        TOTAL_TIME: 'Time',
        TRAVEL_TIME: 'Travel',
        WORK_TIME: 'Work',
        PRICE_PER_HOUR: 'Price',
        PRICE_TOTAL: 'Total price',
        REPORTED_HOURS: 'Reported hours',
        ARTICLE: 'Article',
        AMOUNT: 'Amount',
        PRICE_PER_UNIT: 'Price',
        WORK_ORDER_DETAILS: 'General',
        INVALID_EMAIL_OR_PASSWORD: 'Invalid email or password.',
        SERVER_ERROR: 'Unable to connect to server.',
        IP_BLOCKED_TRY_AGAIN_LATER:
            'We have received too many requests from your address. Please try again later or contact our customer support.',
        TOKEN_EXPIRED: 'Token expired.',
        CANT_LOG_IN: 'Unable to log in?',
        LOG_IN: 'LOG IN',
        ALREADY_HAVE_USER_LOG_IN: 'Already have an user? Log in instead',
        LOG_IN_INSTEAD: 'Log in instead',
        CONFIRM: 'Confirm',
        WELCOME_TO_TRUKKS_TEXT:
            'Welcome to Trukks! <br>Please complete your account by creating password below.',
        CLOSED_TIME: 'Close time',
        CREATE_DATE: 'Create date',
        WORK_ORDER_NUMBER: 'Work Order no.',
        INVOICE: 'Invoice',
        LIMITED_TO_DESCRIPTION: 'Access work orders',
        ARTICLE_TEXT_IS_EDITABLE: 'Article text is changable',
        ARTICLE_PRODUCT_TYPE: 'Product type',
        ARTICLE_ENABLE_SUPPLIERS: 'Enable suppliers',
        ADDRESS: 'Address',
        WORK_ORDER_ADDRESS: 'Address',
        CUSTOMER_VAT_REGISTRATION_NUMBER: 'Customer VAT number',
        DISCOUNT: 'Discount',
        DISCOUNT_TYPE: 'Discount type',
        RESPONSIBLE_TECHNICIAN: 'Responsible technician',
        CUSTOMER_ORDER_NUMBER: 'Customer order nr',
        AUTO_DISPATCH_NEW_WO: 'Auto-dispatch for all new work orders',
        DEFAULT_REQUIRED_FIELDS: 'Required fields',
        REQUIRED_FIELD: 'Required field',
        TAG_REQUIRED: 'Atleast one tag is required',
        ON_SITE_CONTACT_NAME_REQUIRED: 'On site contact is required',
        CONTACT_REQUIRED: 'Contact is required',
        EMAIL_REQUIRED: 'Contact email is required',
        PHONE_REQUIRED: 'Contact phone is required',
        DESCRIPTION_REQUIRED: 'Description is required',
        INVOICE_MARKING_REQUIRED: 'Please fill invoice marking',
        CUSTOMER_ORDER_NUMBER_REQUIRED: 'Please fill customer order number',
        LABEL_IS_REQUIRED: 'Label is required',
        LABEL_DUPLICATE: 'Labels must be unique',
        PERIOD_TIMES_HAVE_OVERLAPS: 'Periods have overlaps',
        INTERNAL_MESSAGE: 'Internal message on work order',
        TOTAL_INVOICE_PRICE_EXCLUDING_VAT: 'Total price',
        TOTAL_REPORTED_TIME: 'Total reported time',
        INVOICE_MARKING: 'Invoice marking',
        ORDERED_BY: 'Ordered by',
        ORDERED_DATE: 'Order date', // missing
        FILES: 'Files',
        UPLOAD_FILES_PLACEHOLDER: 'Drag and drop files here or click to upload',
        SUPPLIER_NUMBER: 'Supplier number',
        SUPPLIER_NAME: 'Supplier name',
        CHECKLIST: 'Checklist',
        CHECKLISTS: 'Checklists',
        CHECKLIST_TITLE: 'Title',
        CHECKLIST_ENABLED_ON_WORK_TYPES: 'Enabled on work types',
        CHECKLIST_ITEMS: 'List items',
        INVOICE_OPTIONS: 'Invoice options',
        ALTERNATIVE_INVOICE_RECEIVER: 'Invoice receiver',
        ALTERNATIVE_INVOICE_ADDRESS: 'Invoice address',
        OBJECT_AGREEMENT_INVOICE_RECEIVER: 'Invoice options',
        PROJECT_MENU_GENERAL: 'General info',
        PROJECT_MENU_INVOICING: 'Invoicing',
        PROJECT_MENU_COSTS: 'Costs',
        PROJECT_MENU_WORK_ORDERS: 'Work orders',
        PROJECT_MENU_FILES: 'Documents',
        PROJECT_MENU_STATISTICS: 'Statistics',
        PROJECT_MENU_SETTINGS: 'Settings',
        PROJECT_MENU_PLANNING: 'Planning',
        PROJECT: 'Project',
        PROJECT_ECONOMY: 'Project economy',
        PROJECT_TIMELINE: 'Timeline',
        PROJECT_TITLE: 'ID',
        PROJECT_NAME: 'Name',
        PROJECT_STATUS: 'Status',
        PROJECT_START_DATE: 'Start date',
        PROJECT_END_DATE: 'End date',
        PROJECT_OUTCOME: 'Outcome',
        PROJECT_ESTIMATIONS: 'Estimations',
        PROJECT_ESTIMATED_HOURS: 'Estimated hours',
        PROJECT_ESTIMATED_COST: 'Estimated cost',
        PROJECT_INCOME: 'Income',
        PROJECT_INCOME_INVOICED: 'Invoiced so far',
        PROJECT_INCOME_LEFT_TO_INVOICE: 'Left to invoice',
        PROJECT_OBJECTS_INVOLVED: 'Objects',
        PROJECT_CUSTOMERS_INVOLVED: 'Customers involved',
        PROJECT_SUPPLIERS_INVOLVED: 'Suppliers involved',
        PROJECT_WORK_ORDERS_INVOLVED: 'Work orders',
        PROJECT_SALES_ORDERS_INVOLVED: 'Sales orders',
        PROJECT_RESPONSIBLE_USER: 'Responsible person',
        PROJECT_CONTACT_NAME: 'Contact',
        PROJECT_DESCRIPTION: 'Description',
        SALES_ORDER: 'Order',
        SALES_ORDER_TITLE: 'Order number',
        PROPOSAL: 'Proposal',
        PROPOSAL_TITLE: 'Proposal number',
        PROPOSAL_VALID_TO: 'Valid to',
        PROPOSAL_CREATED_WORK_ORDER: 'Created work order',
        PROPOSAL_CREATED_SALES_ORDER: 'Created sales order',
        PROPOSAL_STANDARD_TEXTS: 'Standard texts',
        PROPOSAL_STANDARD_TEXTS_EXCEPTIONS: 'Standard texts - Exceptions',
        PROPOSAL_STANDARD_TEXTS_INCLUSIONS: 'Standard texts - Inclusions',
        PROPOSAL_STANDARD_TEXTS_PAYMENT_TERMS: 'Standard texts - Payment terms',
        PROPOSAL_STANDARD_TEXTS_GENERAL_TERMS: 'Standard texts - General terms',
        PROPOSAL_TEMPLATES: 'PDF Templates',
        PROPOSAL_FOLLOW_UP_DATE: 'Follow up',
        PROPOSAL_CLOSE_DATE: 'Date',
        PROPOSAL_INTRO: 'Title',
        PROPOSAL_INTRO_TEXT: 'Introduction text',
        PROPOSAL_DELIVERY_ADDRESS: 'Delivery address',
        PROPOSAL_DESCRIPTION_OF_EXCEPTIONS: 'Exceptions',
        PROPOSAL_GENERAL_TERMS: 'General terms',
        PROPOSAL_PAYMENT_TERMS: 'Payment terms',
        DELIVERY_ADDRESS: 'Delivery address',
        ARTICLE_E_NUMBER: 'E-number',
        OBJECT_AGREEMENT_INVOICE_MARKING: 'Invoice marking',
        OBJECT_AGREEMENT_INVOICE_REFERENCE: 'Invoice reference',
        SALES_ORDER_CREATED_FROM_PROPOSAL: 'Created from proposal',
        CUSTOMER_DEFAULT_DISCOUNT_PERCENTAGE:
            'Discount percentage on all articles',
        ERP_SETTINGS: 'ERP',
        MAIL_SETTINGS: 'E-Mail settings',
        COMMENT: 'Comment',
        PAYMENT_ACCOUNT: 'Payment account',
        ERP_ARTICLE_ACCOUNT: 'Account',
        ERP_ARTICLE_ACCOUNT_GOODS: 'Goods',
        ERP_ARTICLE_ACCOUNT_SERVICE: 'Service',
        ARTICLE_SUPPLIER: 'Supplier',
        ARTICLE_SUPPLIER_ARTICLE_NR: 'Supplier article nr',
        REFERENCE_CONTACT: 'Reference contact',
        SERVICE_REPORTS: 'Service reports',
        SERVICE_REPORT: 'Service report',
        SERVICE_REPORT_TITLE: 'Title',
        SERVICE_REPORT_TEMPLATE_TYPE: 'Template ID',
        SERVICE_REPORT_ENABLED_ON_WORK_TYPES: 'Enabled on work types',
        SERVICE_REPORT_EMAIL_TO: 'Email report to',
        SERVICE_REPORT_EMAIL_TO_SELF: 'User',
        ID06_CODE: 'ID06 Code',
        EXPORT_ID06: 'Export ID06',
        PERSONAL_IDENTITY_NUMBER: 'Personal Identity Number',
        TIME_REPORT_TAG_TYPE_ATTEND: 'Attending',
        TIME_REPORT_TAG_TYPE_ABSENCE: 'Absence',
        COMPENSATION_REPORT_TAG_TYPE_DISTANCE: 'KM',
        COMPENSATION_REPORT_TAG_TYPE_TRAVEL: 'Travel',
        COMPENSATION_REPORT_TAG_TYPE_EXPENSE: 'Expense',
        TYPE: 'Type',
        LABEL: 'Label',
        INVOICE_RULES: 'Invoice rules',
        INVOICE_RULE_AUTO_TIME_INVOICING: 'Automated invoicing of time',
        INVOICE_RULE_AUTO_TRAVEL_TIME_INVOICING:
            'Automated invoicing of travel time',
        PROJECT_INVOICE_MATERIAL_AND_INVOICING: 'Invoice marking & material',
        OBJECT_SERIAL: 'ID',
        OBJECT_MODEL: 'Model',
        AGREEMENT_NAME: 'Agreement',
        AGREEMENT_SERVICE_MONTH: 'Planned',
        WEBSITE: 'Website',
        POST_GIRO_ACCOUNT: 'PostGiro',
        ARTICLE_GROUP_ID: 'Group ID',
        ARTICLE_GROUP_NAME: 'Group Name',
        ARTICLE_GROUP: 'Article group',
        PRICE_LIST_ID: 'Price list ID',
        CUSTOMER_PRICE_LIST: 'Active price list',
        OBJECT_PRICE_LIST: 'Active price list',
        PRICE_LIST_CONFIGURATION: 'Configuration',
        CONTINUE: 'Continue',
        MFA_LOGIN_TITLE: 'Two-factor authentication',
        MFA_LOGIN_DESCRIPTION:
            'Enter 6-digit code from your {{serviceProvider}} APP',
        MFA_LOGIN_CODE_PLACEHOLDER: 'Code',
        MFA_CHALLENGE_FAILED: 'Incorrect code',
        MFA_AUTHENTICATION_FAILED: 'Two-factor authentication failed',
        ACTIVATE: 'Activate',
        DEACTIVATE: 'Deactivate',
        SALES_ORDER_STATUS: 'Status',
        SALES_ORDER_STATUS_OPEN: 'Active',
        SALES_ORDER_STATUS_CLOSED: 'Invoiced',
        CUSTOMER_ZONE: 'Customer Zone',
        CUSTOMER_ZONE_AUTH_CODES: 'Access codes',
        TIME_REPORT_TAG_DEFAULT_TIME: 'Default time',
        MODEL_SPECIFICATIONS: 'Specifications',
        CUSTOMER_CREATE_INVOICES_IN_ERP: 'Send invoice to ERP',
        CUSTOMER_PERSON_NAME: 'Person',
        DISTANCE_REPORTS_LAST_READING: 'Last reading',
        DISTANCE_REPORTS_ACTUAL_READING: 'Actual reading',
        DISTANCE_REPORTS_ACTUAL_KM: 'Actual KM',
        SETTINGS_SALES_ORDER_FIXED_VAT:
            'Reverse VAT disabled for Sales order invoices',
        SETTINGS_NOTIFICATIONS_WORK_ORDER_SCHEDULED_EVENT:
            'Send email when scheduled on work order',
        SETTINGS_NOTIFICATIONS_WORK_ORDER_CREATED_EVENT:
            'Send email when work order is created',
        SETTINGS_NOTIFICATIONS_EVENT_ENABLED_FOR_TYPES:
            'Enabled for work types',
        DEVIATION_CATEGORIES: 'Categories',
        DEVIATION_OCCURED_DATE: 'Occured on',
        DEVIATION_CATEGORY: 'Category',
        DEVIATION_RELATIONS: 'Related to',
        DEVIATION_ID: 'ID',
        DEVIATION_SHORT_TERM_SOLUTION: 'Short-term solution',
        DEVIATION_LONG_TERM_SOLUTION: 'Long-term solution',
        DEVIATION_IS_CHANGE_NEEDED: 'Change',
        DEVIATION_IS_CHANGE_NEEDED_YES: 'Needed!',
        DEVIATION_IS_CHANGE_NEEDED_NO: 'Not needed!',
        DEVIATION_CHANGE_DUE_DATE: 'Due date',
        DEVIATION_CHANGE_LAST_EDITED_BY: 'Last edited by',
        DEVIATION_CHANGE_APPROVED_AT: 'Approved',
        DEVIATION_CHANGE_APPROVED_BY: 'Approved by',
        WORK_ORDER_CALCULATED_COST_ON_TIME: 'Calculated internal cost per hour',
        WORK_ORDER_CALCULATED_COST_ON_DISTANCE:
            'Calculated internal cost per km',
        AGREEMENT_DISCOUNT_PERCENTAGE_ON_PRODUCTS: 'Discount on products',
        AGREEMENT_DISCOUNT_PERCENTAGE_ON_SERVICES: 'Discount on services',
        CUSTOMER_PRICE_DISCOUNT_ON_ARTICLE_GROUPS: 'Discount on article groups',
        BUSINESS_OBJECT_DEFAULT_AGREEMENT: 'Default selected agreement',
        PROJECT_SETTINGS_DEFAULT_INVOICE_MARKING: 'Default invoice marking',
        WORK_ORDER_DESCRIPTION_STANDARD_TEXTS: 'Standard texts for description',
        WORK_ORDER_SUMMARY_STANDARD_TEXTS: 'Standard texts for summary',
        COMPENSATION_REPORT: 'Compensation',
        COMPENSATION_REPORTS_TYPE: 'Type',
        COMPENSATION_REPORTS_KM: 'KM',
        COMPENSATION_REPORTS_EXPENSE_TOTAL_VALUE: 'Total',
        COMPENSATION_REPORTS_EXPENSE_TOTAL_VALUE_VAT: 'VAT',
        COMPENSATION_REPORTS_EXPENSE_CATEGORY: 'Category',
        PROJECT_RESOURCE_PLANNING_TASKS: 'Tasks',
        PROJECT_RESOURCE_PLANNING_TASK: 'Task',
        MICROSOFT_AUTH_LOGIN: 'Sign in with Microsoft',
        LINK: 'Link',
        ADD_LINK: 'Add link',
        ADD_LINK_ACTION: 'Add link',
        EDIT_LINK: 'Edit link',
        CUSTOMER_BROADCAST_EMAIL: 'Auto email address for new work orders',
        HOURS_OF_WORKING: 'Hours of working',
        SUPPLIER_TAGS: 'Statuses',
        SUPPLIER_TAG: 'Status',
        IBAN: 'IBAN',
        SALES_ORDER_REFERENCE_USER: 'Our reference',
        DOORAUTOMATIC_TITLE: 'ID',
        DOORAUTOMATIC_SERIAL_CODE: 'Serial code',
        DOORAUTOMATIC_BRAND: 'Brand',
        DOORAUTOMATIC_MODEL: 'Model',
        DOORAUTOMATIC_LOCATION: 'Location',
        DOORAUTOMATIC_WARRANTY_UNTIL: 'Warranty until',
        DOORAUTOMATIC_SENSOR: 'Sensor',
        DOORAUTOMATIC_NOTES: 'Notes',
        DOORAUTOMATIC_IMAGE: 'Image',
        WORK_ORDER_REMINDER_MESSAGE: 'Message',
        WORK_ORDER_REMINDER_DATE: 'Date',
        WORK_ORDER_REMINDER_TIME: 'Time',
        WORK_ORDER_REMINDER_USERS: 'Users',
        SUPPLIER_INVOICE_CREATE_TIME: 'Arrived',
        SUPPLIER_INVOICE_SUPPLIER_NAME: 'Supplier',
        SUPPLIER_INVOICE_PROJECT: 'Project',
        SUPPLIER_INVOICE_ORDER_NUMBER: 'Order number',
        SUPPLIER_INVOICE_TOTAL_COST: 'Total cost',
        SUPPLIER_INVOICE_EXPIRE_TIME: 'Expire',
        SUPPLIER_INVOICE_RESPONSIBLE_USER: 'User',
        SUPPLIER_INVOICE_COMMENTS: 'Comments',
        SUPPLIER_INVOICE_APPROVE: 'Approve',
        SUPPLIER_INVOICE_IMAGE: 'Image',
        SUPPLIER_INVOICE_CONTENT_STATUS: 'Approve content',
        SUPPLIER_INVOICE_PAYMENT_STATUS: 'Approve payment',
        SUPPLIER_INVOICE_INVOICE_NUMBER: 'Supplier invoice number',
        SUPPLIER_INVOICE_EXTERNAL_REFERENCE: 'Supplier ref.',
        SUPPLIER_INVOICE_INTERNAL_REFERENCE: 'Our ref.',
        SUPPLIER_INVOICE_ARTICLES: 'Articles',
        SUPPLIER_INVOICE_ARTICLE_NAME: 'Article',
        SUPPLIER_INVOICE_ARTICLE_AMOUNT: 'Amount',
        SUPPLIER_INVOICE_ARTICLE_PURCHASE_PRICE: 'Purchase price',
        SUPPLIER_INVOICE_ARTICLE_ROW_PRICE: 'Row price',
        SUPPLIER_INVOICE_ARTICLE_VAT: 'VAT',
        SUPPLIER_INVOICE_ARTICLES_TOTAL_PRICE: 'Total price',
        SUPPLIER_INVOICE_ARTICLES_TOTAL_VAT: 'Total VAT',
        DELIVERY_DATE: 'Delivery date',
        DELIVERY_PARTY: 'Delivery party',
        SETTINGS_WORK_ORDER_DISPATCH_RULES: 'Dispatch rules',
        WORK_ORDER_DISPATCH_RULE_DAY_OF_WEEK: 'Day',
        WORK_ORDER_DISPATCH_RULE_START_TIME: 'Start time',
        WORK_ORDER_DISPATCH_RULE_END_TIME: 'End time',
        WORK_ORDER_DISPATCH_RULE_DISPATCH_TO: 'Dispatch to',
        WORK_ORDER_DISPATCH_AUTO_TO_CREATE_USER: 'Auto-dispatch to creating user',
        UPLOAD_SUPPLIER_INVENTORY_DISCOUNT_FILE_PLACEHOLDER: 'Upload discount file',
        USER_FORTNOX_EMPLOYEE_ID: 'FortNox employee ID',
        DOORAUTOMATIC_COUNT: 'Doorautomatics',
        ARTICLE_IS_SECURITAS_DIRECT_ARTICLE: 'Securitas Direct article',
        ARTICLE_SECURITAS_MONTHLY_COMPENSATION: 'Monthly compensation',
        ARTICLE_SECURITAS_INSTALLATION_COMPENSATION: 'Installation compensation',
        ARTICLE_SECURITAS_SALES_COMPENSATION: 'Sales compensation',
        ARTICLE_IS_SECURITY_ARTICLE: 'Security article',
        BUSINESS_OBJECT_DISPLAY_NAME: 'Name',
        REGIONS: 'Regions',
        PROJECT_REGION: 'Region',
        PROJECT_SALES_USER: 'Sales person',
        COST_CENTER: 'CC',
        STOCK_POINT: 'SP',
        STOCK_LOCATION: 'Stock location',
        USER_DISTRIBUTION: 'Person',
        PROPOSAL_MONTHLY_PRICE: 'Monthly price',
        BUSINESS_OBJECT_CODES: 'Access codes'
    },
    security: {
        CAPACITY: 'Capacity',
        CURRENT_AT_REST: 'Current at rest',
        CURRENT_AT_ALARM: 'Current at alarm',
        SPECIFICATION: 'Anläggarintyg',
        TYPE: 'Type',
        MANUFACTURER: 'Manufacturer',
        ALARM_CLASS: 'Alarm class',
        SPSC: 'Certifierad', // missing
        SPSC_VALID_TO: 'Certifierad Until', // missing
        SBSC_ID: 'SBSC ID',
        MODEL_SYSTEM_TYPES: 'System types',
        MODEL_SYSTEM_TYPE_BURGLAR: 'Burglary',
        MODEL_SYSTEM_TYPE_PASSAGE: 'Passage',
        MODEL_SYSTEM_TYPE_CAMERA: 'Camera/CCTV',
        MODEL_SYSTEM_TYPE_FIREALARM: 'Fire alarm',
        MODEL_SYSTEM_TYPE_ASSAULT: 'Assault',

        OBJECT_PASSAGE_TYPE_OF_SYSTEM: 'Type of system',
        OBJECT_PASSAGE_NUMBER_OF_SUBCENTRALS: 'Number of sub-centrals',
        OBJECT_PASSAGE_DOOR_LEAF_READER: 'Door leaf reader',
        OBJECT_PASSAGE_CARD_READER: 'Card reader',
        OBJECT_PASSAGE_ACCESS_POINT: 'Access point',
        OBJECT_PASSAGE_POWER_SUPPLY: 'Power supply',
        OBJECT_PASSAGE_TYPE_OF_READER: 'Type of reader',
        OBJECT_PASSAGE_COMMENTS: 'Comments',

        OBJECT_CAMERA_NUMBER_OF_CAMERAS: 'Number of cameras',
        OBJECT_CAMERA_NUMBER_OF_PTZ: 'Number of PTZ',
        OBJECT_CAMERA_NUMBER_OF_HEAT_CAMERAS: 'Number of heat cameras',
        OBJECT_CAMERA_TYPE_OF_SOFTWARE: 'Type of software',
        OBJECT_CAMERA_NUMBER_OF_CHANNELS_IN_NVR: 'Number of channels in NVR',
        OBJECT_CAMERA_TYPE_OF_READER: 'Type of reader',
        OBJECT_CAMERA_COMMENTS: 'Comments',

        OBJECT_FIREALARM_FIRE_ALARM_CENTRAL: 'Fire alarm central',
        OBJECT_FIREALARM_TYPE_OF_BATTERY: 'Type of battery',
        OBJECT_FIREALARM_NUMBER_OF_SMOKE_DETECTORS: 'Number of smoke detectors',
        OBJECT_FIREALARM_NUMBER_OF_HEAT_DETECTORS: 'Number of heat detectors',
        OBJECT_FIREALARM_NUMBER_OF_ALARM_BUTTONS: 'Number of alarm buttoms',
        OBJECT_FIREALARM_NUMBER_OF_ALARM_DEVICES: 'Number of alarm devices',
        OBJECT_FIREALARM_COMMENTS: 'Comments',

        OBJECT_ASSAULT_FABRIC: 'Fabric',
        OBJECT_ASSAULT_NUMBER_OF_SUBCENTRALS: 'Number of sub-centrals',
        OBJECT_ASSAULT_NUMBER_OF_DISPLAYS: 'Number of map/text displays',
        OBJECT_ASSAULT_NUMBER_OF_RECEIVERS: 'Number of receivers',
        OBJECT_ASSAULT_COLOR_OF_FIXED_BUTTONS: 'Color of fixed buttons',
        OBJECT_ASSAULT_NUMBER_OF_FIXED_BUTTONS: 'Number of fixed buttons',
        OBJECT_ASSAULT_COLOR_OF_WIRELESS_BUTTONS: 'Color of wireless buttons',
        OBJECT_ASSAULT_NUMBER_OF_WIRELESS_BUTTONS: 'Number of wireless buttons',
        OBJECT_ASSAULT_COLOR_OF_FLASHLIGHTS: 'Color of flashlights',
        OBJECT_ASSAULT_NUMBER_OF_FLASHLIGHTS: 'Number of flashlights',
        OBJECT_ASSAULT_COMMENTS: 'Comments',
    },
    copy: {
        ARTICLE_SUFFICIENT_FOR: 'Sufficent for',
        ARTICLE_SUFFICIENT_FOR_PRINTOUTS: '{{count}} print-outs',
        AGREEMENT_CLICK_REGISTRATION: 'Click registration',
    },
    calendar: {
        TODAY: 'Today',
        TOMORROW: 'Tomorrow',
        YESTERDAY: 'Yesterday',
        THIS_WEEK: 'This week',
        THIS_MONTH: 'This month',
        THIS_YEAR: 'This year',
        LAST_MONTH: 'Last month',
        MONDAY: 'Monday',
        TUESDAY: 'Tuesday',
        WEDNESDAY: 'Wednesday',
        THURSDAY: 'Thursday',
        FRIDAY: 'Friday',
        SATURDAY: 'Saturday',
        SUNDAY: 'Sunday',
        JANUARY: 'January',
        FEBRUARY: 'February',
        MARCH: 'March',
        APRIL: 'April',
        MAY: 'May',
        JUNE: 'June',
        JULY: 'July',
        AUGUST: 'August',
        SEPTEMBER: 'September',
        OCTOBER: 'October',
        NOVEMBER: 'November',
        DECEMBER: 'December',
        JANUARY_SHORT: 'Jan',
        FEBRUARY_SHORT: 'Feb',
        MARCH_SHORT: 'Mar',
        APRIL_SHORT: 'Apr',
        MAY_SHORT: 'May',
        JUNE_SHORT: 'Jun',
        JULY_SHORT: 'Jul',
        AUGUST_SHORT: 'Aug',
        SEPTEMBER_SHORT: 'Sept',
        OCTOBER_SHORT: 'Oct',
        NOVEMBER_SHORT: 'Nov',
        DECEMBER_SHORT: 'Dec',
        MONTH: 'Month',
        YEAR: 'Year',
        WEEK: 'Week',
        DAY: 'Day',
        QUARTER: 'Quarter',
        HALF_YEAR: '6mo',
        LAST_DAY_OF_MONTH: 'Last day of month',
        ONE_MONTH: 'month',
        TWO_MONTHS: 'two months',
        THREE_MONTHS: 'three months',
        FOUR_MONTHS: 'four months',
        FIVE_MONTHS: 'five months',
        SIX_MONTHS: 'six months',
        EIGHTEEN_MONTHS: '18 months',
        ONE_YEAR: 'year',
        TWO_YEARS: 'two years',
        THREE_YEARS: 'three years',
        FOUR_YEARS: 'four years',
        TIME_TO: 'To',
        TIME_FROM: 'From',
        PERSPECTIVE_SCHEDULE: 'Technician',
        PERSPECTIVE_PROJECT: 'Project',
        DAYS: 'days',
    },
    datatable: {
        NO_ROWS: 'No data',
        RELOAD: 'Reload',
        SEARCH: 'Search',
        ROWS: '{{count}} row',
        ROWS_plural: '{{count}} rows',
        ZERO_ROWS_FILTERED:
            '{{count}} rows (filtered from {{filteredFromCount}})',
        SHOWING_ROWS:
            'Showing {{startCount}} to {{endCount}} of {{count}} rows',
        SHOWING_ROWS_FILTERED:
            'Showing {{startCount}} to {{endCount}} of {{count}} rows (filtered from {{fromCount}} rows)',
    },
    messages: {
        SUCCESSFULLY_SAVED: 'Saved',
        SUCCESSFULLY_DELETED: 'Deleted',
        SUCCESSFULLY_CLOSED_WORKORDER: 'Work order successfully closed',
        SUCCESSFULLY_CLOSED_PROJECT: 'Project successfully closed',
        SUCCESSFULLY_REOPENED_PROJECT: 'Projecct successfully reopened',
        FAILED_TO_SAVE: 'Failed to save',
        FAILED_TO_SEND_EMAIL: 'Failed to send email!',
        FAILED_TO_DELETE: 'Failed to delete',
        FAILED_TO_FETCH: 'Failed to fetch details',
        FAILED_TO_UPLOAD_FILE: 'Failed to upload file',
        FAILED_TO_DOWNLOAD_FILE: 'There was an error downloading the file',
        FILE_NOT_FOUND: 'No file attached',
        FAILED_TO_DOWNLOAD_FILE_ID06_CODE_NOT_FOUND: 'ID06 Code not found',
        CUSTOMER_FAILED_TO_GET_INFO: 'Failed to get customer data',
        FAILED_TO_GET_HISTORY: 'Failed to get history',
        FAILED_TO_INVITE: 'Failed to invite',
        INVITE_SENT: 'Invitation sent',
        WRONG_CURRENT_PASSWORD: 'Invalid current password',
        PASSWORD_DONT_MATCH: 'Passwords dont match',
        PASSWORD_MIN_CHARACTERS: 'Password must be at least 8 characters long.',
        ERROR_DEPARTMENT_HAS_USERS: 'Can not delete. Department has users.',
        USER_REGISTERED:
            'Password created. <a href="/">Click here</a> to log in.',
        INVALID_SESSION: 'Session expired. Please log in again.',
        ERROR_MESSAGE_TITLE: 'Error happened!',
        INFO_MESSAGE_TITLE: 'Info!',
        SUCCESS_MESSAGE_TITLE: 'Successful action!',
        WARNING_MESSAGE_TITLE: 'Warning!',
        WARNING_PERMISSION_DENIED:
            'You do not have permission for this action. Ask your administrator.',
        BUSINESS_OBJECT_EXISTS: 'Object with same serial code already exists!',
        CUSTOMER_EXISTS: 'Customer with same ID already exists!',
        ARTICLE_EXISTS: 'Article with same ID already exists!',
        ARTICLE_E_NUMBER_EXISTS: 'Article with same E-Number already exists!',
        ARTICLE_GROUP_EXISTS: 'Article group with same ID already exists!',
        PRICE_LIST_EXISTS: 'Price list with same ID already exists!',
        AGREEMENT_EXISTS: 'Agreement with same type already exists!',
        MODEL_EXISTS: 'Model with same code already exists!',
        SUPPLIER_EXISTS: 'Supplier with same number already exists!',
        DOORAUTOMATIC_EXISTS: 'Doorautomatic with same ID already exists!',
        CONFIRM_UNSAVED_CHANGES:
            'You have unsaved changes. Are you sure you want to close tab?',
        CONFIRM_CLOSE_WORKORDER: 'Workorder will be closed. Please confirm.',
        CONFIRM_DELETE_WORKORDER: 'Workorder will be deleted! Please confirm.',
        MIN_TAG_LENGTH_2: 'Minimum tag length is 2.',
        MIN_WORKTYPE_LENGTH_2: 'Minimum work type length is 2 characters.',
        MAX_WORKTYPE_LENGTH_50: 'Maximum work type length is 50 characters.',
        MIN_STATUS_LENGTH_2: 'Minimum status length is 2.',
        TAG_ALREADY_EXISTS: 'Tag already exists.',
        WORKTYPE_ALREADY_EXISTS: 'Work type already exists.',
        STATUS_ALREADY_EXISTS: 'Status already exists.',
        TAG_CONTAINS_SPECIAL_CHARACTERS:
            'Please only use characters and numbers.',
        STATUS_DEFAULT_ERROR: 'One default status is required.',
        STATUS_NO_CLOSING_ERROR: 'Atleast one closing status is required.',
        STATUS_CLOSING_DEFAULT_SAME:
            'Closing and default status can not be the same.',
        INVALID_PRICE: 'Invalid price. Please only use numbers.',
        PLEASE_SELECT_ARTICLE: 'Please select article',
        CONFIRM_CUSTOMER_ARTICLE_PRICE_DELETE:
            'Are you sure you want to delete customer price?',
        CHANGE_PASSWORD_TEXT: 'Insert new password',
        PASSWORD_SUCCESSFULLY_CHANGED:
            'Password successfully changed. <a href="/">Click here</a> to log in.',
        REQUEST_PASSWORD_CHANGE_TEXT:
            'Insert your email. We will send instructions on how to change your password.',
        PASSWORD_CHANGE_SUCCESSFULLY_REQUESTED:
            'Message with instructions is sent to your email.',
        UNABLE_TO_DELETE:
            'Unable to delete. We suggest to update status to "Inactive" instead or contact our customer support.',
        ERROR_REQUIRED_FIELD: 'Please fill in this field',
        ERROR_SPACE_NOT_ALLOWED: 'Space is not allowed',
        ERROR_MAX_LENGTH: 'Maximum field length exceeded ({{count}})',
        EMAIL_SENT: 'Email sent',
        EMAIL_FAILED: 'Failed to send email',
        ERROR_LOADING_INVOICE: 'Failed to load invoice',
        ARE_YOU_SURE: 'Are you sure?',
        EMAIL_SENT_TO: 'Email sent to: {{email}}',
        EMAIL_IS_INVALID: 'Invalid email',
        ERROR_HANDLING_APPLY: 'Failed to process request',
        ERROR_DISCOUNT_PATTERN: 'Discount must be a number or percentage',
        TAG_IS_REQUIRED: 'Please select tag',
        TIME_REPORT_MAXIMUM_TIME_SPAN_60_DAYS: 'Maximum time span is 60 days',
        SEARCH_FROM_SIDEBAR: 'Please use sidebar to search',
        AMOUNT_CANT_BE_NEGATIVE: 'Amount can not be negative!',
        STOCK_SUCCESSFULLY_UPDATED:
            'Stock successfully updated. New stock amount: {{amount}}.',
        WARNING_NETWORK_ERROR_PLEASE_REFRESH:
            'We are unable to reach our servers. Please try refreshing the page.',
        USER_CREATED: 'Successfully created user',
        FAILED_TO_CREATE_USER: 'Failed to create user',
        PROPOSAL_PLEASE_SELECT_CUSTOMER_BEFORE_ACCEPTING:
            'Please select customer before accepting',
        PROPOSAL_SUCCESSFULLY_ACCEPTED:
            'Successfully accepted proposal and created sales order',
        PROPOSAL_SUCCESSFULLY_ACCEPTED_WORKORDER_CREATED:
            'Successfully accepted proposal and created work order {{workOrderNr}}',
        PROPOSAL_SUCCESSFULLY_DELETED: 'Proposal deleted successfully!',
        CONFIRM_SALES_ORDER_INVOICE:
            'Please confirm. Sales order invoice will be generated and sent to ERP system.',
        SALES_ORDER_INVOICE_SUCCESSFULLY_CREATED:
            'Invoice successfully created',
        SALES_ORDER_IS_INVOICED: 'Sales order is invoiced',
        OBJECT_IS_INACTIVATED_NO_AGREEMENT_INVOICES:
            'Agreement invoices are not generated!',
        PERCENTAGE_CANNOT_BE_OVER_100: 'Percent can not be over 100%',
        PERCENTAGE_CANNOT_BE_NEGATIVE: 'Percent can not be below 0',
        AGREEMENT_CONFIGURATION_WARNING_DOUBLE_INVOICING:
            'Please double-check your configuration. Potential double invoicing of articles!',
        AGREEMENT_CONFIGURATION_VALID_FROM_MUST_BE_BEFORE_VALID_TO:
            'Invalid configuration. Valid from must be before valid to.',
        CANNOT_DELETE_WORKORDER_HAS_LOCKED_TIMEREPORTS:
            'Can not delete work order. Work order has locked time reports',
        HELLO_USER: 'Hello {{name}}!',
        WELCOME_TO_YOUR_STARTPAGE: 'Welcome to your startpage!',
        PROJECT_FAILED_TO_SET_INCLUDED_HAS_INVOICED_ARTICLES:
            'Can not set as included. Some articles are already invoiced.',
        PROJECT_FAILED_TO_SET_ARTICLE_INCLUDED_ALREADY_ON_INVOICE:
            'Row is already included on some invoice.',
        CONFIRM_CLOSE_PROJECT_INVOICE:
            'Invoice will be created. Please confirm.',
        PROJECT_INVOICE_SUCCESSFULLY_CREATED: 'Invoice created successfully.',
        FAILED_TO_HANDLE_SUPPLIER_INVOICE: 'Failed to handle supplier invoice.',
        SUCCESSFULLY_HANDLED_SUPPLIER_INVOICE:
            'Successfully handled supplier invoice.',
        ARTICLE_GROUP_CANNOT_DELETE:
            'Unable to delete article group. Group has articles in it or is enabled on some price list.',
        PRICE_LIST_CANNOT_DELETE:
            'Unable to delete price list. Price list is enabled for some customer.',
        REMOVE_MFA_PROVIDER_CONFIRM:
            'Please confirm that you would like to remove two-factor authentication via {{serviceProvider}}',
        REGISTER_MFA_PROVIDER_CONFIRM:
            'Please confirm that you would like to add two-factor authentication via {{serviceProvider}}. Make sure that you have {{serviceProvider}} installed',
        REGISTERING_MFA_FAILED:
            'Failed to add two-factor authentication via {{serviceProvider}}',
        FAILED_TO_REMOVE_MFA_PROVIDER: 'Failed to remove {{serviceProvider}}',
        SUCCESSFULLY_REMOVED_MFA_PROVIDER:
            'Successfully removed {{serviceProvider}}',
        SUCCESSFULLY_REGISTERED_MFA:
            'Successfully added two-factor authentication. Scan the QR-code below in {{serviceProvider}}',
        CONFIRM_SALES_ORDER_MANUAL_RECEIPT_IS_OK:
            'Please confirm printing order was successful.',
        SALES_ORDER_MANUAL_RECEIPT_SUCCESSFUL:
            'Sales order successfully invoiced',
        FILE_UPLOAD_SUCCESSFUL_PROCESSING_FILE:
            'File was successfully uploaded. It is being processed.',
        SUPPLIER_INVENTORY_CONFIRM_FORMAT_OF_FIRST_10_ROWS:
            'Confirm data format is correct',
        FAILED_TO_MOVE_FILE: 'Failed to move the file',
        CUSTOMER_INFO_INVOICES_WILL_NOT_BE_GENERATED:
            'Invoices will not be sent to ERP!',
        WORK_ORDER_CONFIRM_CLOSE_CUSTOMER_IS_CONFIGURED_TO_NOT_SEND_TO_ERP:
            'Invoice will not be sent to ERP according to customer configuration!',
        SUPPLIER_INVENTORY_LAST_FILE_UPLOAD:
            'Last update to article prices was on {{time}}.',
        SUPPLIER_INVENTORY_LAST_FILE_UPLOAD_NOT_FOUND:
            'Last update time not found',
        VALIDATION_DEVIATION_CATEGORY_NAME_BETWEEN_2_50_CHARS:
            'Name must be between 2-50 characters',
        DEVIATION_SUCCESSFULLY_CLOSED: 'Deviation successfully closed',
        PROJECT_RESOURCE_DATE_ALREADY_SELECTED: 'Dates already planned!',
        OBJECT_AGREEMENT_VALID_FROM_DATE_NOT_SET:
            'Date must be set to start agreement invoicing!',
        OBJECT_AGREEMENT_VALID_TO_DATE_SET:
            'No agreement invoices will be generated past this date!',
        WORK_ORDER_USER_DISTRIBUTION_USER_ALREADY_ADDED: 'User already added!',
        PROPOSAL_REOPEN_ARE_YOU_SURE: 'Are you sure to open proposal?',
        PROPOSAL_REOPEN_WORKORDER_WILL_NOT_BE_DELETED:
            'If work order was created from this proposal - it is not deleted!',
        PROPOSAL_REOPEN_SALESORDER_WILL_NOT_BE_DELETED:
            'If sales order was created from this proposal - it is not deleted!',
        WORK_ORDER_REOPEN_ARE_YOU_SURE: 'Are you sure to open the work order?',
        WORK_ORDER_REOPEN_INVOICE_WILL_BE_DELETED:
            'Invoice in Trukks will be deleted.',
        WORK_ORDER_REOPEN_INVOICE_IN_ERP_WILL_NOT_BE_DELETED:
            'Invoice in ERP will not be deleted!',
        WORKORDER_SERVICEREPORT_REOPEN_ARE_YOU_SURE: 'Are you sure to reopen the form?',
        WORKORDER_SERVICEREPORT_REOPEN_FILE_WILL_NOT_BE_DELETED:
            'Previously generated file will not be deleted!',
        ADD_LINK_REQUIRED_FIELDS: 'Link and name must be provided',
        FAILED_TO_AUTHENTICATE_MS_AUTH: 'Authentication via Microsoft failed',
        SUCCESSFULLY_SCHEDULED_WORK_ORDERS:
            'Successfully scheduled. Work orders will be created in few minutes!',
        COMPANY_HOLIDAY_DATE_ALREADY_USED:
            'Could not update company holiday, holiday date already in use',
        WORK_ORDER_BY_TAGS_KPI_ERROR_MESSAGE:
            'Something went wrong while loading the data. Please try again later',
        WORK_ORDER_REOPEN_SUCCESSFUL: 'Re-opening was successful',
        CANNOT_CLOSE_PROJECT_INVOICE_CUSTOMER_IS_POTENTIAL_CUSTOMER:
            'Cannot close project invoice. Customer must have customer number.',
        CANNOT_CLOSE_PROPOSAL_WORKORDER_CUSTOMER_NUMBER_MISSING:
            'Cannot accept proposal. Customer must have customer number.',
        CANNOT_CLOSE_PROPOSAL_WORKORDER_OBJECT_IS_MISSING:
            'Cannot accept proposal. Please select object first.',
        CANNOT_CLOSE_PROPOSAL_SALES_ORDER_CUSTOMER_NUMBER_MISSING:
            'Cannot accept proposal. Customer must have customer number.',
        CANNOT_CLOSE_WORK_ORDER_CUSTOMER_NUMBER_MISSING:
            'Cannot close work order. Customer must have customer number.',
        CANNOT_CLOSE_SALES_ORDER_CUSTOMER_NUMBER_MISSING:
            'Cannot close sales order. Customer must have customer number.',
        CANNOT_CLOSE_SALES_ORDER_REFERENCE_USER_REQUIRED: 'Cannot close sales order. Please set reference user',
        CONFIRM_SUPPLIER_INVOICE_APPROVE_PAYMENT: 'Are you sure to approve payment?',
        USER_REQUIRED: 'Please select day',
        DAY_OF_WEEK_REQUIRED: 'Please select user',
        PROJECT_HAS_OPEN_WORK_ORDERS: 'Can not close project. Project has open work orders',
        WORK_ORDER_CHANGE_CUSTOMER_INFO: 'Article prices on work order are not updated!',
        WORK_ORDER_CHANGE_OBJECT_INFO: 'Article prices on work order are not updated!',
        WORK_ORDER_ASSIGNEE_REQUIRED: 'One assigned user is required!',
        WORK_ORDER_CAN_NOT_REMOVE_SCHEDULED_USER: 'Can not remove assignee. Please add anothed assigned user before removing.',
        COPIED_TO_CLIPBOARD: 'Copied to clipboard',
        SERVICE_REPORT_ALL_GENERATED_PDFS_WILL_BE_PUBLISHED: 'All generated PDFs will be automatically published to customer zone!',
    },
    helpers: {
        AGREEMENT_WORKORDER_GENERATION_DAY_HELPER:
            'Choose day of month, when work order will be created',
        AGREEMENT_WORKORDER_GENERATION_INTERVAL_HELPER:
            'Choose interval of work order creation',
        AGREEMENT_INVOICE_GENERATION_DAY_HELPER:
            'Choose day of month, when invoice will be created',
        AGREEMENT_INVOICE_GENERATION_INTERVAL_HELPER:
            'Choose interval of invoice creation',
        AGREEMENT_ARTICLES_ARE_ADDED_TO_WORKORDER:
            'Agreement articles are added to generated workorder',
        INVOICE_MIN_ID_LEN_VALIDATION: 'Please enter a positive number',
        CUSTOMER_EMAIL_HELP_TEXT: 'This email will be used for invoices',
        CUSTOMER_INVOICE_MARKING_HELP_TEXT:
            'This marking will be set for all new workorders',
        PRIMARY_CUSTOMER_ADDRESS: 'Primary address',
        PRIMARY_CUSTOMER_ADDRESS_FOR_ERP:
            'This address is used for ERP integration',
        OBJECT_AGREEMENT_INVOICE_MARKING_HELPTEXT:
            'This marking is displayed on agreement invoice and pre-selected on all new work orders and override customer invoice marking.',
        REFERENCE_CONTACT_FOR_ERP: 'Presented as `your reference` in ERP',
        FIELD_WILL_BE_DISPLAYED_ON_WORKORDER_IF_FILLED_IN:
            'Will be displayed on work order if filled in',
        WORKORDER_THIS_ARTICLE_IS_INVOICED_AUTOMATICALLY:
            'Automatically added from time reports',
        PROJECT_SETTINGS_DEFAULT_INVOICE_MARKING_HELPTEXT:
            'This marking will be pre-selected on all project invoices for this project.',
        CUSTOMER_BROADCAST_EMAIL_HELP_TEXT:
            'This email will be used for sending emails about new work orders',
        PROJECT_INVOICING_STATUS_ONLY_FOR_IN_SCOPE:
            'Only for in-scope articles. ÄTA is excluded.',
        PROJECT_OUTCOME_EXTRA_SCOPE_ONLY_APPROVED:
            'Only approved work orders are included', // missing
        PROJECT_OUTCOME_EXTRA_SCOPE_ALL: 'Accepted, pending, declined work orders are included', // missing
    },
    invoice: {
        INVOICE_INFORMATION: 'Invoice about',
        INVOICE_DESCRIPTION: 'Description',
        ISSUED_WITH_REVERSE_VAT: 'VAT reverse charged',
    },
    acl: {
        PERMISSION_GROUP_WORK_ORDER: 'Work order',
        PERMISSION_GROUP_INVENTORY: 'Inventory',
        PERMISSION_GROUP_OTHER: 'Other',
        PERMISSION_SETTINGS_READ: 'View settings',
        PERMISSION_SETTINGS_IMPORT_EXPORT: 'Export & Import',
        PERMISSION_USERS_READ: 'View users',
        PERMISSION_USERS_EDIT: 'Edit users',
        PERMISSION_INSTANCE_READ: 'View company details',
        PERMISSION_INSTANCE_EDIT: 'Edit company details',
        PERMISSION_CUSTOMERS_READ: 'View customers',
        PERMISSION_CUSTOMERS_CREATE: 'Create customers',
        PERMISSION_CUSTOMERS_CREATE_POTENTIAL: 'Create potential customers',
        PERMISSION_CUSTOMERS_EDIT: 'Edit customers',
        PERMISSION_CUSTOMERS_DELETE: 'Delete customers',
        PERMISSION_CUSTOMERS_FILES: 'Manage files on customers',
        PERMISSION_CUSTOMERS_FILES_READ: 'Read files on customers',
        PERMISSION_BUSINESS_OBJECTS_READ: 'View business objects',
        PERMISSION_BUSINESS_OBJECTS_EDIT: 'Edit business objects',
        PERMISSION_BUSINESS_OBJECTS_DELETE: 'Delete business objects',
        PERMISSION_BUSINESS_OBJECT_SERVICE_LOG: 'View/Edit daily service book',
        PERMISSION_BUSINESS_OBJECT_FILES: 'Manage files on objects',
        PERMISSION_BUSINESS_OBJECT_FILES_READ: 'Read files on objects',
        PERMISSION_BUSINESS_OBJECT_AGREEMENT_EDIT: 'Edit agreement under object',
        PERMISSION_BUSINESS_OBJECTS_CODES_VIEW: 'See object codes',
        PERMISSION_BUSINESS_OBJECTS_CODES_EDIT: 'Edit object codes',
        PERMISSION_BUSINESS_OBJECTS_CODES_VIEW_LOG: 'See logs for object codes',
        PERMISSION_ARTICLES_CREATE: 'Create articles',
        PERMISSION_ARTICLES_READ: 'View articles',
        PERMISSION_ARTICLES_PRICE: 'View article prices',
        PERMISSION_ARTICLES_EDIT: 'Edit articles',
        PERMISSION_ARTICLES_DELETE: 'Delete articles',
        PERMISSION_ARTICLES_SETTINGS: 'View and edit article settings',
        PERMISSION_ARTICLE_FILES_READ: 'View article files',
        PERMISSION_ARTICLE_FILES_MANAGE: 'Upload & delete article files',
        PERMISSION_ACL_READ: 'View access levels',
        PERMISSION_ACL_EDIT: 'Edit access levels',
        PERMISSION_WORKORDERS_READ: 'View work orders',
        PERMISSION_WORKORDERS_EDIT: 'Edit work orders',
        PERMISSION_WORKORDERS_EDIT_INTERNAL_INFO:
            'Edit work orders (creation time)',
        PERMISSION_WORKORDERS_CREATE: 'Create work orders',
        PERMISSION_WORKORDERS_CREATE_PROJECT_EXTRA_WORK:
            'Create ÄTA work orders',
        PERMISSION_WORKORDERS_ACCEPT_PROJECT_EXTRA_WORK:
            'Accept or decline ÄTA work orders',
        PERMISSION_WORKORDERS_CLOSE: 'Close work orders',
        PERMISSION_WORKORDERS_REOPEN: 'Re-open work orders',
        PERMISSION_WORKORDERS_COPY: 'Copy work order',
        PERMISSION_WORKORDERS_DELETE: 'Delete work orders',
        PERMISSION_WORKORDERS_REPORT: 'Report on work orders (summary, time)',
        PERMISSION_WORKORDERS_REPORT_ARTICLES:
            'Report articles on work orders (also gives access to article information)',
        PERMISSION_WORKORDERS_EDIT_ARTICLE_DISTRIBUTIONS:
            'Edit article distributions on work order',
        PERMISSION_WORKORDERS_LOCK: 'Lock work orders',
        PERMISSION_WORKORDERS_FILES: 'Manage workorder files',
        PERMISSION_WORKORDERS_TIMEREPORTS_SELECT:
            'Attach work order time reports to invoice',
        PERMISSION_WORKORDERS_READ_COST: 'See margin of workorder',
        PERMISSION_MODELS_READ: 'View models',
        PERMISSION_MODELS_EDIT: 'Edit models',
        PERMISSION_MODELS_DELETE: 'Delete models',
        PERMISSION_MODEL_FILES_READ: 'View model files',
        PERMISSION_MODEL_FILES_MANAGE: 'Upload & delete model files',
        PERMISSION_AGREEMENTS_READ: 'View agreements',
        PERMISSION_AGREEMENTS_EDIT: 'Edit agreements',
        PERMISSION_AGREEMENTS_DELETE: 'Delete agreements',
        PERMISSION_SUPPLIERS_READ: 'View suppliers',
        PERMISSION_SUPPLIERS_EDIT: 'Edit suppliers',
        PERMISSION_SUPPLIERS_DELETE: 'Delete suppliers',
        PERMISSION_SUPPLIER_ORDERS_EDIT: 'Modify supplier orders',
        PERMISSION_INVOICES_READ: 'View invoices',
        PERMISSION_REPORTS_READ: 'View reports',
        PERMISSION_TIMEREPORTS_READ: 'Read timereports of other users',
        PERMISSION_TIMEREPORTS_EDIT: 'Edit details of other user timereports',
        PERMISSION_ON_CALL_REPORTS_EDIT: 'Edit on-call',
        PERMISSION_DISTANCEREPORTS_READ: 'Read distance reports of other users',
        PERMISSION_COMPENSATIONREPORTS_READ:
            'See compensation reports for other users',
        PERMISSION_PROJECTS_READ: 'View projects',
        PERMISSION_PROJECTS_EDIT: 'Create and modify projects',
        PERMISSION_PROJECTS_DELETE: 'Delete projects',
        PERMISSION_PROJECTS_FILES: 'Manage files on projects',
        PERMISSION_PROJECTS_CLOSE_NEGATIVE_INVOICE:
            'Close negative project invoice',
        PERMISSION_PROJECTS_REOPEN: 'Re-open projects',
        PERMISSION_SALES_ORDER_READ: 'View sales orders',
        PERMISSION_SALES_ORDER_EDIT: 'Create and modify sales orders',
        PERMISSION_SALES_ORDER_DELETE: 'Delete sales orders',
        PERMISSION_PROPOSAL_READ: 'See proposals',
        PERMISSION_PROPOSAL_EDIT: 'Manage proposals',
        PERMISSION_PROPOSAL_DELETE: 'Delete proposals',
        PERMISSION_PROPOSAL_FILES: 'Manage files on proposals',
        PERMISSION_PROPOSAL_REOPEN: 'Reopen closed proposals',
        PERMISSION_SECURITY_BO_DOCUMENT_READ: 'Read security system type info',
        PERMISSION_SECURITY_BO_DOCUMENT_EDIT: 'Edit security system type info',
        PERMISSION_WORKORDERS_EDIT_ID06_CODE: 'Edit ID06 Code on work orders',
        PERMISSION_MESSAGE_BOARD_POST_EDIT: 'Edit internal messages',
        PERMISSION_DASHBOARD_READ: 'View dashboard',
        PERMISSION_DASHBOARD_OPEN_WORKORDERS_KPI_EDIT:
            'View and edit open work order pricing factors',
        PERMISSION_DEVIATIONS_READ: 'View deviations',
        PERMISSION_DEVIATIONS_EDIT: 'Edit deviations',
        PERMISSION_DEVIATIONS_CREATE: 'Create deviations',
        PERMISSION_DEVIATIONS_CLOSE: 'Close deviations',
        PERMISSION_DEVIATIONS_DELETE: 'Delete deviations',
        PERMISSION_DOORAUTOMATICS_READ: 'View doorautomatics',
        PERMISSION_DOORAUTOMATICS_EDIT: 'Edit doorautomatics',
        PERMISSION_DOORAUTOMATICS_DELETE: 'Delete doorautomatics',
        PERMISSION_DOORAUTOMATICS_FILES: 'Edit doorautomatic files',
        PERMISSION_DOORAUTOMATICS_FILES_READ: 'Read doorautomatic files',
        PERMISSION_WORKORDER_DOORAUTOMATICS_EDIT: 'Edit doorautomatics on work order',
        PERMISSION_CUSTOMER_ZONE_EDIT_USERS: 'Edit users for customer zone',
        PERMISSION_CUSTOMER_ZONE_PUBLISH_FILES: 'Publish and unpublish files for customer zone', // missing
        PERMISSION_SUPPLIER_INVOICES_READ: 'Read assigned supplier invoices',
        PERMISSION_SUPPLIER_INVOICES_EDIT: 'Manage supplier invoices',
        PERMISSION_SUPPLIER_INVOICES_READ_ALL: 'Read all supplier invoices',
        PERMISSION_WORKORDERS_SERVICEREPORT_REOPEN: 'Re-open closed forms',
    },
    customerSpecifications: {
        invoiceSubjectTemplate: 'Invoice email subject template',
    },
    workorderhistory: {
        close: 'Closed',
        edit: 'Edited',
        'edit.internal': 'Edited internal',
        'edit.customer': 'Edited customer',
        'edit.object': 'Edited object',
        create: 'Created',
        delete: 'Deleted',
        'agreement.create': 'Created automatically',
        lock: 'Locked',
        unlock: 'Unlocked',
        add_articles: 'Added articles from supplier order',
    },
    invoicehistory: {
        create: 'Created',
        email: 'Emailed',
        'erp.created.visma': 'Sent to Visma',
        'erp.created.wint': 'Sent to Wint',
        'erp.created.Magenta': 'Sent to Magenta',
        'erp.created.visma-e': 'Visma eAccounting',
        'erp.created.fortnox': 'Fortnox',
        'erp.created.quickbooks': 'QuickBooks',
        visma: 'Sent to Visma',
        wint: 'Sent to Wint',
        magenta: 'Sent to Magenta',
        'visma-e': 'Sent to Visma eAccounting',
        fortnox: 'Sent to Fortnox',
        quickbooks: 'Sent to QuickBooks',
    },
    stats: {
        NO_DATA_FOUND: 'No data found',
        BAR_CHART: 'Bar',
        LINE_CHART: 'Line',

        SERVICE_DISTRIBUTION: 'Service distribution',
        SERVICE_DISTRIBUTION_EXCEL_EXPORT_FILENAME: 'Service distribution',
        PLANNED_SERVICES: '{{count}} planned service visit',
        PLANNED_SERVICES_plural: '{{count}} planned service visits',
        CLICK_TO_VIEW_DETAILS: 'Click to view more details',

        INVOICING: 'Invoicing',
        INVOICING_LAST_12_MONTHS: 'Last 12 months',
        INVOICING_THIS_YEAR: 'This year',
        INVOICING_WORK_ORDERS: 'Work orders',
        INVOICING_AGREEMENTS: 'Agreements',
        INVOICING_ALL: 'All',
        INVOICING_SUMMED: 'Summed',

        ACTIVE_WORK_ORDERS: 'Active work orders',
        ACTIVE_WORK_ORDERS_BY_STATUS: 'By status',
        NUMBER_OF_WORKORDERS: '{{count}} work orders',

        LARMKLASS: 'Alarm class distribution of objects',

        OPEN_WORKORDERS_TO_INVOICE: 'Open workorders',
        OPEN_WORKORDERS_ON_SERVICE: 'On service',
        OPEN_WORKORDERS_TO_INVOICE_TOTAL_PRICE: '{{price}} sales value',
        OPEN_WORKORDERS_TO_INVOICE_TOTAL_COUNT: '{{count}} open work orders',
        OPEN_WORKORDERS_TO_INVOICE_TOTAL_TIME: '{{time}} reported hours',
        OPEN_WORKORDERS_TO_INVOICE_TOTAL_COST: '{{price}} total cost',
        OPEN_WORKORDERS_TO_INVOICE_WORKORDER_COST: 'Total cost: {{price}}',
        OPEN_WORKORDERS_TO_INVOICE_PRESENTING_DAYS: 'Presenting days',
        OPEN_WORKORDERS_TO_INVOICE_PRESENTING_DAYS_TO: 'to',
        OPEN_WORKORDERS_TO_INVOICE_PRESENTING_DAYS_TOTAL: '(of {{count}} days)',
        OPEN_WORKORDERS_ON_PROJECT: 'On projects',
        OPEN_WORKORDERS_TO_INVOICE_TOTAL_PROJECT_WORK_ORDERS_COUNT:
            '{{count}} open work orders',

        OPEN_WORKORDERS_TO_INVOICE_WORKORDER_PRICE: 'Sales price: {{price}}',
        OPEN_WORKORDERS_TO_INVOICE_WORKORDER_TIME: 'Time spent: {{time}}',

        NON_MATCHING_SUPPLIER_ORDERS: 'Non-matching supplier orders',
        NON_MATCHING_SUPPLIER_ORDERS_PAST_2_MONTHS: 'Past 2 months',
        NON_MATCHING_SUPPLIER_ORDERS_ARRIVED_TO_TRUKKS: 'Arrived to Trukks',
        NON_MATCHING_SUPPLIER_ORDERS_SUPPLIER_NAME: 'Supplier',
        NON_MATCHING_SUPPLIER_ORDERS_SUPPLIER_ORDER_ID: 'Supplier Order ID',
        NON_MATCHING_SUPPLIER_ORDERS_ORDER_REFERENCE: 'Order Reference',
        NON_MATCHING_SUPPLIER_ORDERS_NO_ORDERS: 'No non-matching orders found',
        NON_MATCHING_SUPPLIER_ORDERS_ADJUST_ORDER: 'Adjust reference',
        NON_MATCHING_SUPPLIER_ORDERS_ORDER_WILL_BE_REPROCESSED:
            'Order will be reprocessed with new reference',
        NON_MATCHING_SUPPLIER_ORDERS_DISCARD_ORDER: 'Discard order',
        NON_MATCHING_SUPPLIER_ORDERS_TOTAL_COST: 'Total cost',
        NON_MATCHING_SUPPLIER_ORDERS_LIST_OPTION: 'Order reference & value',
        NON_MATCHING_SUPPLIER_ORDERS_DETAILS_OPTION: 'Order details',
        OPEN_PROJECTS_KPI_TOTAL_CONTRACTS_LABEL: 'Total contracts',
        OPEN_PROJECTS_KPI_TOTAL_CONTRACTS:
            '{{price}} on {{total}} open projects',
        OPEN_PROJECTS_KPI_TOTAL_INVOICED_LABEL: 'Total invoiced',
        OPEN_PROJECTS_KPI_TOTAL_INVOICED:
            '{{price}} ({{percent}} of total contracts)',
        OPEN_PROJECTS_KPI_TOTAL_LEFT_TO_INVOICE_LABEL: 'Total left to invoice',
        OPEN_PROJECTS_KPI_TOTAL_LEFT_TO_INVOICE:
            '{{price}} ({{percent}} of total contracts)',
        OPEN_PROJECTS_KPI_ONGOING_COST_LABEL: 'Ongoing cost',
        OPEN_PROJECTS_KPI_ONGOING_COST: '{{price}}',
        OPEN_PROJECTS_KPI_ONGOING_COST_X_NOT_REPORTED: '{{count}} missing',
        OPEN_PROJECTS_KPI_ONGOING_COST_NOT_INVOICED_LABEL: 'Non-invoiced cost',
        OPEN_PROJECTS_KPI_ONGOING_COST_NOT_INVOICED: '{{price}}',
        OPEN_PROJECTS_KPI_BIGGEST_CONTRACT_LABEL: 'Biggest contract',
        OPEN_PROJECTS_KPI_BIGGEST_CONTRACT: '{{price}} ({{projectName}})',
        OPEN_PROJECTS_KPI_AVERAGE_CONTRACT_LABEL: 'Average contract',
        OPEN_PROJECTS_KPI_AVERAGE_CONTRACT: '{{price}}',
        OPEN_PROJECTS_KPI_RESET_DAY_OF_MONTH:
            'Day of month for resetting costs',
        OPEN_PROJECTS_INCOME_KPI_HEADER: 'Project income',
        OPEN_PROJECTS_INCOME_KPI_INVOICED: 'Invoiced',
        OPEN_PROJECTS_INCOME_KPI_ONGOING_COST: 'Ongoing cost',
        OPEN_PROJECTS_INCOME_KPI_WEEKS_OPEN: 'Weeks open',
        KPI_CLOSED_WORKORDERS_BY_TYPE_HEADER: 'Closed work orders by type',
        KPI_CLOSED_WORKORDERS_BY_TYPE_COUNT_OF_WORKORDERS: 'Count',
        KPI_ONBOARDED_AND_LOST_OBJECTS_TITLE: 'Onboarded and lost objects',
        ONBOARDED_OBJECTS: 'Onboarded',
        LOST_OBJECTS: 'Lost',
        STATS_ACCRUED_INCOME: 'Accrued income',
        STATS_ACCRUED_INCOME_DISPLAYED_UNTIL: 'Until {{date}}',
        STATS_ACCRUED_INCOME_FILE_NAME: 'Accrued income',
        STATS_ERP_SUPPLIER_INVOICES_CARD_HEADER: 'Supplier invoices',

        WORK_ORDER_LEAD_TIME_PERIOD: 'Presented period',
        WORK_ORDER_LEAD_TIME_WORK_TYPE: 'Work order type',
        WORK_ORDER_LEAD_TIME_KPI_HEADER: 'Lead time',
        WORK_ORDER_LEAD_TIME_DAYS_OPEN: 'Days open',
        WORK_ORDER_LEAD_TIME_COUNT_OF_ORDERS: 'Count',
        WORK_ORDER_LEAD_TIME_PERIOD_TIME_SPAN_VALIDATION_MESSAGE: 'Specified period must be between 10 days and 1 year',

        CLOSED_SALES_ORDER_PRICING_WEEKLY_HEADER: 'Sales order: Summary of sales & margin',
        CLOSED_WORK_ORDER_PRICING_WEEKLY_HEADER: 'Work order: Summary of sales & margin',
        TOTAL_SALES_PRICE: 'Total',
        TOTAL_MARGIN: 'Margin',
    },
    suppliers: {
        elektroskandia: 'Elektroskandia',
        solar: 'Solar',
        copiax: 'Copiax',
        ahlsell: 'Ahlsell',
        axsnordic: 'AXS Nordic',
        rexel: 'Rexel',
        dafo: 'Dafo',
        extronic: 'Extronic',
        teletec: 'Teletec',
    },
    erp: {
        INVOICE_NR_HANDLED_BY: 'Handled by {{name}}',
        INVOICE_WAS_NOT_CREATED_IN_ERP: 'Invoice was not created in {{name}}!',
        INVOICE_STATUS_IN_ERP_UNKNOWN: 'Invoice status in {{erpName}}: unknown',
        INVOICE_CREATED_IN_ERP: 'Invoice in {{erpName}}: created',
        INVOICE_CREATED_IN_ERP_WITH_ID: 'Invoice in {{erpName}}: {{id}}',
        INVOICE_WAS_NOT_CREATED_IN_ERP_ZERO_SUM:
            'Invoice was not created in {{erpName}} (0 total price)',
        INVOICE_WAS_NOT_CREATED_IN_ERP_CUSTOMER_CONF:
            'Invoice was not created in {{erpName}} (customer conf.)',
        visma: 'Visma',
        fortnox: 'Fortnox',
        magenta: 'Magenta',
        'visma-e': 'Visma e-economy',
        wint: 'Wint',
        quickbooks: 'QuickBooks',
    },
    files: {
        ROOT_FOLDER: 'Files',
        BACK_TO: 'Back to',
    },
    sbsc: {
        CERTIFICATE_NUMBER: 'SBSC Certificate',
        VALID_TO: 'Valid to',
        ARTICLE_GROUP: 'Article group',
        ARTICLE_DESCRIPTION: 'Description',
        MANUFACTURER_NAME: 'Manufacturer',
        MANUFACTURER_MOBILE: 'Mobile',
        MANUFACTURER_PHONE: 'Phone',
        MANUFACTURER_EMAIL: 'Email',
        ALARM_CLASS: 'Alarm class',
        E_NUMBER: 'E-number',
        ARTICLE_REGULATION: 'Regulations',
        CURRENT_AT_REST: 'Current at rest',
        CURRENT_AT_ALARM: 'Current at alarm',
        ENVIRONMENT_CLASS: 'Environment',
        CERTIFICATE_SEQUENCE: 'Certificate sequence',
        MESSAGE_NO_PRODUCT_IMAGE: 'No product image.',
        USING_PROLONGED_VALID_TO_DATE:
            'The product has a prolonged certification date',
        VIEW_ON_SBSC_WEBSITE: 'View on SBSC website',
    },
    importexport: {
        FILE_NAME: 'File name',
        ARTICLE_EXPORT_NAME: 'Articles',
        CUSTOMER_EXPORT_NAME: 'Customers',
        OBJECT_EXPORT_NAME: 'Objects',
        UPCOMING_AGREEMENT_INVOICES_EXPORT_NAME: 'Upcoming invoices',
    },
    countries: {
        AF: 'Afghanistan',
        AX: 'Åland Islands',
        AL: 'Albania',
        DZ: 'Algeria',
        AS: 'American Samoa',
        AD: 'Andorra',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarctica',
        AG: 'Antigua and Barbuda',
        AR: 'Argentina',
        AM: 'Armenia',
        AW: 'Aruba',
        AU: 'Australia',
        AT: 'Austria',
        AZ: 'Azerbaijan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Belarus',
        BE: 'Belgium',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Bolivia (Plurinational State of)',
        BQ: 'Bonaire, Sint Eustatius and Saba',
        BA: 'Bosnia and Herzegovina',
        BW: 'Botswana',
        BV: 'Bouvet Island',
        BR: 'Brazil',
        IO: 'British Indian Ocean Territory',
        BN: 'Brunei Darussalam',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        CV: 'Cabo Verde',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        KY: 'Cayman Islands',
        CF: 'Central African Republic',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CX: 'Christmas Island',
        CC: 'Cocos (Keeling) Islands',
        CO: 'Colombia',
        KM: 'Comoros',
        CG: 'Congo',
        CD: 'Congo, Democratic Republic of the',
        CK: 'Cook Islands',
        CR: 'Costa Rica',
        CI: 'Côte d\'Ivoire',
        HR: 'Croatia',
        CU: 'Cuba',
        CW: 'Curaçao',
        CY: 'Cyprus',
        CZ: 'Czechia',
        DK: 'Denmark',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        EC: 'Ecuador',
        EG: 'Egypt',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        SZ: 'Eswatini',
        ET: 'Ethiopia',
        FK: 'Falkland Islands (Malvinas)',
        FO: 'Faroe Islands',
        FJ: 'Fiji',
        FI: 'Finland',
        FR: 'France',
        GF: 'French Guiana',
        PF: 'French Polynesia',
        TF: 'French Southern Territories',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgia',
        DE: 'Germany',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GR: 'Greece',
        GL: 'Greenland',
        GD: 'Grenada',
        GP: 'Guadeloupe',
        GU: 'Guam',
        GT: 'Guatemala',
        GG: 'Guernsey',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        HM: 'Heard Island and McDonald Islands',
        VA: 'Holy See',
        HN: 'Honduras',
        HK: 'Hong Kong',
        HU: 'Hungary',
        IS: 'Iceland',
        IN: 'India',
        ID: 'Indonesia',
        IR: 'Iran (Islamic Republic of)',
        IQ: 'Iraq',
        IE: 'Ireland',
        IM: 'Isle of Man',
        IL: 'Israel',
        IT: 'Italy',
        JM: 'Jamaica',
        JP: 'Japan',
        JE: 'Jersey',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KP: 'Korea (Democratic Peoples Republic of) ',
        KR: 'Korea, Republic of',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: 'Lao Peoples Democratic Republic',
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libya',
        LI: 'Liechtenstein',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        MO: 'Macao',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MU: 'Mauritius',
        YT: 'Mayotte',
        MX: 'Mexico',
        FM: 'Micronesia (Federated States of)',
        MD: 'Moldova, Republic of',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MS: 'Montserrat',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Netherlands',
        NC: 'New Caledonia',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        NF: 'Norfolk Island',
        MK: 'North Macedonia',
        MP: 'Northern Mariana Islands',
        NO: 'Norway',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PS: 'Palestine, State of',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines',
        PN: 'Pitcairn',
        PL: 'Poland',
        PT: 'Portugal',
        PR: 'Puerto Rico',
        QA: 'Qatar',
        RE: 'Réunion',
        RO: 'Romania',
        RU: 'Russian Federation',
        RW: 'Rwanda',
        BL: 'Saint Barthélemy',
        SH: 'Saint Helena, Ascension and Tristan da Cunha',
        KN: 'Saint Kitts and Nevis',
        LC: 'Saint Lucia',
        MF: 'Saint Martin (French part)',
        PM: 'Saint Pierre and Miquelon',
        VC: 'Saint Vincent and the Grenadines',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'Sao Tome and Principe',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SX: 'Sint Maarten (Dutch part)',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        GS: 'South Georgia and the South Sandwich Islands',
        SS: 'South Sudan',
        ES: 'Spain',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SJ: 'Svalbard and Jan Mayen',
        SE: 'Sweden',
        CH: 'Switzerland',
        SY: 'Syrian Arab Republic',
        TW: 'Taiwan, Province of China',
        TJ: 'Tajikistan',
        TZ: 'Tanzania, United Republic of',
        TH: 'Thailand',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad and Tobago',
        TN: 'Tunisia',
        TR: 'Turkey',
        TM: 'Turkmenistan',
        TC: 'Turks and Caicos Islands',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates',
        GB: 'United Kingdom',
        US: 'United States of America',
        UM: 'United States Minor Outlying Islands',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela (Bolivarian Republic of)',
        VN: 'Viet Nam',
        VG: 'Virgin Islands (British)',
        VI: 'Virgin Islands (U.S.)',
        WF: 'Wallis and Futuna',
        EH: 'Western Sahara',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe',
    }
};
