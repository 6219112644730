import {
    deletee,
    get,
    patch,
    post,
    postFile,
    put,
} from '../../../utils/http/HttpClient';

export default {
    fetchWorkOrderFiles: async (
        workOrderId,
        folderId = null,
        from = null,
        size = null
    ) => {
        const queryParams = new URLSearchParams();
        if ((from || from == 0) && size) {
            queryParams.append('from', from);
            queryParams.append('size', size);
        }

        if (folderId) {
            queryParams.append('folderId', folderId);
        }

        const data = await get(
            `/file/wo/${workOrderId}?${queryParams.toString()}`
        );

        return data;
    },
    createWorkOrderFileFolder: async (
        workOrderId,
        folderName,
        sortOrder,
        parentId = null
    ) => {
        return await post(`/file/wo/${workOrderId}/folder`, {
            folderName,
            parentId,
            sortOrder,
        });
    },
    fetchWorkOrderFile: async (workOrderId, fileId) => {
        const response = await get(`/file/wo/${workOrderId}/${fileId}`);

        return response;
    },
    removeWorkOrderFile: async (workOrderId, fileId) => {
        await deletee(`/file/wo/${workOrderId}/${fileId}`);
    },
    uploadWorkOrderFiles: async (workOrderId, files) => {
        const file = await postFile(`/file/wo/${workOrderId}`, files);

        return file;
    },
    renameWorkOrderFile: async (workOrderId, fileId, fileName) => {
        await patch(`/file/wo/${workOrderId}/${fileId}/rename`, { fileName });
    },
    moveWorkOrderFileToFolder: async (workOrderId, fileId, folderId) => {
        await patch(`/file/wo/${workOrderId}/${fileId}/move`, { folderId });
    },
    reorderWorkOrderFile: async (workOrderId, fileId, sortOrder) => {
        await patch(`/file/wo/${workOrderId}/${fileId}/reorder`, { sortOrder });
    },
    fetchBusinessObjectFiles: async (
        businessObjectId,
        folderId = null,
        from = null,
        size = null
    ) => {
        const queryParams = new URLSearchParams();
        if ((from || from == 0) && size) {
            queryParams.append('from', from);
            queryParams.append('size', size);
        }

        if (folderId) {
            queryParams.append('folderId', folderId);
        }

        const data = await get(
            `/file/bo/${businessObjectId}?${queryParams.toString()}`
        );

        return data;
    },
    fetchBusinessObjectFile: async (businessObjectId, fileId) => {
        const response = await get(`/file/bo/${businessObjectId}/${fileId}`);

        return response;
    },
    removeBusinessObjectFile: async (businessObjectId, fileId) => {
        await deletee(`/file/bo/${businessObjectId}/${fileId}`);
    },
    uploadBusinessObjectFiles: async (businessObjectId, files) => {
        const file = await postFile(`/file/bo/${businessObjectId}`, files);

        return file;
    },
    createBusinessObjectFolder: async (
        businessObjectId,
        folderName,
        sortOrder,
        parentId = null
    ) => {
        return await post(`/file/bo/${businessObjectId}/folder`, {
            folderName,
            parentId,
            sortOrder,
        });
    },
    createBusinessObjectFileLink: async ({
        id: businessObjectId,
        linkProps,
        folderId = null,
        sortOrder,
    }) => {
        return await post(`/file/bo/${businessObjectId}/link`, {
            link: linkProps.link,
            name: linkProps.name,
            parentId: folderId,
            sortOrder,
        });
    },
    updateBusinessObjectFileLink: async (fileId, businessObjectId, linkProps) => {
        return await put(`/file/bo/${businessObjectId}/${fileId}/link`, {
            link: linkProps.link,
            name: linkProps.name
        });
    },
    renameBusinessObjectFile: async (businessObjectId, fileId, fileName) => {
        await patch(`/file/bo/${businessObjectId}/${fileId}/rename`, { fileName });
    },
    publishBusinessObjectFile: async (businessObjectId, fileId) => {
        await post(`/file/bo/${businessObjectId}/${fileId}/publish`, {});
    },
    unpublishBusinessObjectFile: async (businessObjectId, fileId) => {
        await post(`/file/bo/${businessObjectId}/${fileId}/unpublish`, {});
    },
    moveBusinessObjectFileToFolder: async (businessObjectId, fileId, folderId) => {
        await patch(`/file/bo/${businessObjectId}/${fileId}/move`, { folderId });
    },
    reorderBusinessObjectFile: async (businessObjectId, fileId, sortOrder) => {
        await patch(`/file/bo/${businessObjectId}/${fileId}/reorder`, { sortOrder });
    },
    fetchCustomerFiles: async (
        customerId,
        folderId = null,
        from = null,
        size = null
    ) => {
        const queryParams = new URLSearchParams();
        if ((from || from == 0) && size) {
            queryParams.append('from', from);
            queryParams.append('size', size);
        }

        if (folderId) {
            queryParams.append('folderId', folderId);
        }

        const data = await get(
            `/file/customer/${customerId}?${queryParams.toString()}`
        );

        return data;
    },
    fetchCustomerFile: async (customerId, fileId) => {
        const response = await get(`/file/customer/${customerId}/${fileId}`);

        return response;
    },
    removeCustomerFile: async (customerId, fileId) => {
        await deletee(`/file/customer/${customerId}/${fileId}`);
    },
    uploadCustomerFiles: async (customerId, files) => {
        const file = await postFile(`/file/customer/${customerId}`, files);

        return file;
    },
    createCustomerFilesFolder: async (
        customerId,
        folderName,
        sortOrder,
        parentId = null
    ) => {
        return await post(`/file/customer/${customerId}/folder`, {
            folderName,
            parentId,
            sortOrder,
        });
    },
    renameCustomerFile: async (customerId, fileId, fileName) => {
        await patch(`/file/customer/${customerId}/${fileId}/rename`, { fileName });
    },
    moveCustomerFileToFolder: async (customerId, fileId, folderId) => {
        await patch(`/file/customer/${customerId}/${fileId}/move`, { folderId });
    },
    reorderCustomerFile: async (customerId, fileId, sortOrder) => {
        await patch(`/file/customer/${customerId}/${fileId}/reorder`, { sortOrder });
    },
    fetchArticleFiles: async (
        articleId,
        folderId = null,
        from = null,
        size = null
    ) => {
        const queryParams = new URLSearchParams();
        if ((from || from == 0) && size) {
            queryParams.append('from', from);
            queryParams.append('size', size);
        }

        if (folderId) {
            queryParams.append('folderId', folderId);
        }

        const data = await get(
            `/file/article/${articleId}?${queryParams.toString()}`
        );

        return data;
    },
    fetchArticleFile: async (articleId, fileId) => {
        const response = await get(`/file/article/${articleId}/${fileId}`);

        return response;
    },
    removeArticleFile: async (articleId, fileId) => {
        await deletee(`/file/article/${articleId}/${fileId}`);
    },
    uploadArticleFiles: async (articleId, files) => {
        const file = await postFile(`/file/article/${articleId}`, files);

        return file;
    },
    createArticleFilesFolder: async (
        articleId,
        folderName,
        sortOrder,
        parentId = null
    ) => {
        return await post(`/file/article/${articleId}/folder`, {
            folderName,
            parentId,
            sortOrder,
        });
    },
    renameArticleFile: async (articleId, fileId, fileName) => {
        await patch(`/file/article/${articleId}/${fileId}/rename`, { fileName });
    },
    moveArticleFileToFolder: async (articleId, fileId, folderId) => {
        await patch(`/file/article/${articleId}/${fileId}/move`, { folderId });
    },
    reorderArticleFile: async (articleId, fileId, sortOrder) => {
        await patch(`/file/article/${articleId}/${fileId}/reorder`, { sortOrder });
    },
    fetchModelFiles: async (
        modelId,
        folderId = null,
        from = null,
        size = null
    ) => {
        const queryParams = new URLSearchParams();
        if ((from || from == 0) && size) {
            queryParams.append('from', from);
            queryParams.append('size', size);
        }

        if (folderId) {
            queryParams.append('folderId', folderId);
        }

        const data = await get(
            `/file/model/${modelId}?${queryParams.toString()}`
        );

        return data;
    },
    fetchModelFile: async (modelId, fileId) => {
        const response = await get(`/file/model/${modelId}/${fileId}`);

        return response;
    },
    removeModelFile: async (modelId, fileId) => {
        await deletee(`/file/model/${modelId}/${fileId}`);
    },
    uploadModelFiles: async (modelId, files) => {
        const file = await postFile(`/file/model/${modelId}`, files);

        return file;
    },
    createModelFilesFolder: async (
        modelId,
        folderName,
        sortOrder,
        parentId = null
    ) => {
        return await post(`/file/model/${modelId}/folder`, {
            folderName,
            parentId,
            sortOrder,
        });
    },
    renameModelFile: async (modelId, fileId, fileName) => {
        await patch(`/file/model/${modelId}/${fileId}/rename`, { fileName });
    },
    moveModelFileToFolder: async (modelId, fileId, folderId) => {
        await patch(`/file/model/${modelId}/${fileId}/move`, { folderId });
    },
    reorderModelFile: async (modelId, fileId, sortOrder) => {
        await patch(`/file/model/${modelId}/${fileId}/reorder`, { sortOrder });
    },
    fetchProjectFiles: async (
        projectId,
        folderId = null,
        from = null,
        size = null
    ) => {
        const queryParams = new URLSearchParams();
        if ((from || from == 0) && size) {
            queryParams.append('from', from);
            queryParams.append('size', size);
        }

        if (folderId) {
            queryParams.append('folderId', folderId);
        }

        const data = await get(
            `/file/project/${projectId}?${queryParams.toString()}`
        );

        return data;
    },
    createProjectFileFolder: async (
        projectId,
        folderName,
        sortOrder,
        parentId = null
    ) => {
        return await post(`/file/project/${projectId}/folder`, {
            folderName,
            parentId,
            sortOrder,
        });
    },
    createProjectFileLink: async ({
        id: projectId,
        linkProps,
        folderId = null,
        sortOrder,
    }) => {
        return await post(`/file/project/${projectId}/link`, {
            link: linkProps.link,
            name: linkProps.name,
            parentId: folderId,
            sortOrder,
        });
    },
    fetchProjectFile: async (projectId, fileId) => {
        const response = await get(`/file/project/${projectId}/${fileId}`);

        return response;
    },
    removeProjectFile: async (projectId, fileId) => {
        await deletee(`/file/project/${projectId}/${fileId}`);
    },
    uploadProjectFiles: async (projectId, files) => {
        const file = await postFile(`/file/project/${projectId}`, files);

        return file;
    },
    renameProjectFile: async (projectId, fileId, fileName) => {
        await patch(`/file/project/${projectId}/${fileId}/rename`, { fileName });
    },
    moveProjectFileToFolder: async (projectId, fileId, folderId) => {
        await patch(`/file/project/${projectId}/${fileId}/move`, { folderId });
    },
    reorderProjectFile: async (projectId, fileId, sortOrder) => {
        await patch(`/file/project/${projectId}/${fileId}/reorder`, { sortOrder });
    },
    fetchProposalFiles: async (
        proposalId,
        folderId = null,
        from = null,
        size = null
    ) => {
        const queryParams = new URLSearchParams();
        if ((from || from == 0) && size) {
            queryParams.append('from', from);
            queryParams.append('size', size);
        }

        if (folderId) {
            queryParams.append('folderId', folderId);
        }

        const data = await get(
            `/file/proposal/${proposalId}?${queryParams.toString()}`
        );

        return data;
    },
    fetchProposalFile: async (proposalId, fileId) => {
        const response = await get(`/file/proposal/${proposalId}/${fileId}`);

        return response;
    },
    removeProposalFile: async (proposalId, fileId) => {
        await deletee(`/file/proposal/${proposalId}/${fileId}`);
    },
    uploadProposalFiles: async (proposalId, files) => {
        const file = await postFile(`/file/proposal/${proposalId}`, files);

        return file;
    },
    renameProposalFile: async (proposalId, fileId, fileName) => {
        await patch(`/file/proposal/${proposalId}/${fileId}/rename`, { fileName });
    },
    moveProposalFileToFolder: async (proposalId, fileId, folderId) => {
        await patch(`/file/proposal/${proposalId}/${fileId}/move`, { folderId });
    },
    reorderProposalFile: async (proposalId, fileId, sortOrder) => {
        await patch(`/file/proposal/${proposalId}/${fileId}/reorder`, { sortOrder });
    },
    fetchDeviationFiles: async (
        deviationId,
        folderId = null,
        from = null,
        size = null
    ) => {
        const queryParams = new URLSearchParams();
        if ((from || from == 0) && size) {
            queryParams.append('from', from);
            queryParams.append('size', size);
        }

        if (folderId) {
            queryParams.append('folderId', folderId);
        }

        const data = await get(
            `/file/deviation/${deviationId}?${queryParams.toString()}`
        );

        return data;
    },
    fetchDeviationFile: async (deviationId, fileId) => {
        const response = await get(`/file/deviation/${deviationId}/${fileId}`);

        return response;
    },
    removeDeviationFile: async (deviationId, fileId) => {
        await deletee(`/file/deviation/${deviationId}/${fileId}`);
    },
    uploadDeviationFiles: async (deviationId, files) => {
        const file = await postFile(`/file/deviation/${deviationId}`, files);

        return file;
    },
    renameDeviationFile: async (deviationId, fileId, fileName) => {
        await patch(`/file/deviation/${deviationId}/${fileId}/rename`, { fileName });
    },
    moveDeviationFileToFolder: async (deviationId, fileId, folderId) => {
        await patch(`/file/deviation/${deviationId}/${fileId}/move`, { folderId });
    },
    reorderDeviationFile: async (deviationId, fileId, sortOrder) => {
        await patch(`/file/deviation/${deviationId}/${fileId}/reorder`, { sortOrder });
    },
    fetchSalesOrderFiles: async (
        salesOrderId,
        folderId = null,
        from = null,
        size = null
    ) => {
        const queryParams = new URLSearchParams();
        if ((from || from == 0) && size) {
            queryParams.append('from', from);
            queryParams.append('size', size);
        }

        if (folderId) {
            queryParams.append('folderId', folderId);
        }

        const data = await get(
            `/file/salesorder/${salesOrderId}?${queryParams.toString()}`
        );

        return data;
    },
    fetchSalesOrderFile: async (salesOrderId, fileId) => {
        const response = await get(`/file/salesorder/${salesOrderId}/${fileId}`);

        return response;
    },
    removeSalesOrderFile: async (salesOrderId, fileId) => {
        await deletee(`/file/salesorder/${salesOrderId}/${fileId}`);
    },
    uploadSalesOrderFiles: async (salesOrderId, files) => {
        const file = await postFile(`/file/salesorder/${salesOrderId}`, files);

        return file;
    },
    renameSalesOrderFile: async (salesOrderId, fileId, fileName) => {
        await patch(`/file/salesorder/${salesOrderId}/${fileId}/rename`, { fileName });
    },
    moveSalesOrderFileToFolder: async (salesOrderId, fileId, folderId) => {
        await patch(`/file/salesorder/${salesOrderId}/${fileId}/move`, { folderId });
    },
    reorderSalesOrderFile: async (salesOrderId, fileId, sortOrder) => {
        await patch(`/file/salesorder/${salesOrderId}/${fileId}/reorder`, { sortOrder });
    },
    fetchDoorautomaticFiles: async (
        doorautomaticId,
        folderId = null,
        from = null,
        size = null
    ) => {
        const queryParams = new URLSearchParams();
        if ((from || from == 0) && size) {
            queryParams.append('from', from);
            queryParams.append('size', size);
        }

        if (folderId) {
            queryParams.append('folderId', folderId);
        }

        const data = await get(
            `/file/doorautomatic/${doorautomaticId}?${queryParams.toString()}`
        );

        return data;
    },
    fetchDoorautomaticFile: async (doorautomaticId, fileId) => {
        const response = await get(`/file/doorautomatic/${doorautomaticId}/${fileId}`);

        return response;
    },
    removeDoorautomaticFile: async (doorautomaticId, fileId) => {
        await deletee(`/file/doorautomatic/${doorautomaticId}/${fileId}`);
    },
    uploadDoorautomaticFiles: async (doorautomaticId, files) => {
        const file = await postFile(`/file/doorautomatic/${doorautomaticId}`, files);

        return file;
    },
    renameDoorautomaticFile: async (doorautomaticId, fileId, fileName) => {
        await patch(`/file/doorautomatic/${doorautomaticId}/${fileId}/rename`, { fileName });
    },
    moveDoorautomaticFileToFolder: async (doorautomaticId, fileId, folderId) => {
        await patch(`/file/doorautomatic/${doorautomaticId}/${fileId}/move`, { folderId });
    },
    reorderDoorautomaticFile: async (doorautomaticId, fileId, sortOrder) => {
        await patch(`/file/doorautomatic/${doorautomaticId}/${fileId}/reorder`, { sortOrder });
    },
    fetchSupplierInvoiceFiles: async supplierInvoiceId => {
        const response = await get(`/file/supplierinvoice/${supplierInvoiceId}`);

        return response;
    },
    fetchSupplierInvoiceFile: async (supplierInvoiceId, fileId) => {
        const response = await get(`/file/supplierinvoice/${supplierInvoiceId}/${fileId}`);

        return response;
    },
    confirmSupplierArticlesUpload: async (supplierId, files) => {
        const { totalCount, articles } = await postFile(
            `/supplierinventory/upload/${supplierId}/confirm`,
            files
        );

        return {
            totalCount,
            articles,
        };
    },
    uploadSupplierArticles: async (supplierId, files) => {
        await postFile(`/supplierinventory/upload/${supplierId}`, files);
    },
    confirmSupplierArticleDiscountsUpload: async (supplierId, files) => {
        const { totalCount, discounts } = await postFile(
            `/supplierinventory/upload/${supplierId}/discount/confirm`,
            files
        );

        return {
            totalCount,
            discounts,
        };
    },
    uploadSupplierArticleDiscounts: async (supplierId, files) => {
        await postFile(`/supplierinventory/upload/${supplierId}/discount`, files);
    },

    renameFile: async (fileId, fileName) => {
        await patch(`/file/${fileId}/rename`, { fileName });
    },
    moveFileToFolder: async (fileId, folderId) => {
        await patch(`/file/${fileId}/move`, { folderId });
    },
    reorderFile: async (fileId, sortOrder) => {
        await patch(`/file/${fileId}/reorder`, { sortOrder });
    },
    fetchGenericFile: async (type, fileId) => {
        const response = await get(`/file/generic/${type}/${fileId}`);

        return response;
    },
    uploadGenericFile: async (type, data) => {
        data.append('sortOrder', 100);
        const file = await postFile(`/file/generic/${type}`, data);

        return file;
    },
};
