import React from 'react'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import './MultiFactorRegisteredModal.css';

const MultiFactorRegisteredModal = ({ qrValue, provider }) => {
    const { t } = useTranslation('messages');

    return (
        <div className='man-multifactor-modal'>
            <h3>{t('SUCCESSFULLY_REGISTERED_MFA', { serviceProvider: provider })}</h3>
            <QRCode value={qrValue} />
        </div>
    )
}

MultiFactorRegisteredModal.propTypes = {
    qrValue: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired
}

export default MultiFactorRegisteredModal
