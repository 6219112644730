import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { getDepartmentStyle } from '../../../../utils/UserUtils';
import './Avatar.css';

const Avatar = ({ user, onClick, editable, hoverable, tiny }) => {
    if (!user) {
        return (
            <span className={`man-avatar ui circular label${editable ? ' editable' : ''}${tiny ? ' man-avatar-tiny' : ''}`}>
                
            </span>
        );
    }

    const { department, initials, fullName } = user;

    let width = 32;

    if (initials.length > 3) {
        width += ((initials.length - 3) * 4);
    }

    if (width > 48) {
        width = 48;
    }

    if (!hoverable) {
        return (
            <span className={`man-avatar ui circular label${editable ? ' editable' : ''}${tiny ? ' man-avatar-tiny' : ''}`}
                style={{
                    ...getDepartmentStyle(department),
                    width
                }}
                onClick={onClick}>
                {initials}
            </span>
        )
    }

    return (
        <Popup
            trigger={
                <span className={`man-avatar ui circular label${editable ? ' editable' : ''}${tiny ? ' man-avatar-tiny' : ''}`}
                    style={{
                        ...getDepartmentStyle(department),
                        width
                    }}
                    onClick={onClick}>
                    {initials}
                </span>
            }>

            {fullName}
        </Popup>
    )
}

Avatar.propTypes = {
    userId: PropTypes.string,
    user: PropTypes.object,
    onClick: PropTypes.func,
    department: PropTypes.object,
    editable: PropTypes.bool,
    hoverable: PropTypes.bool,
    tiny: PropTypes.bool
}

Avatar.defaultProps = {
    onClick: () => { },
    editable: false,
    user: null,
    hoverable: true,
    tiny: false
}

const mapStateToProps = (state, { userId, user }) => ({
    user: user && user.department ? user : state.users.users.find(u => u.id === userId)
})

export default connect(mapStateToProps)(Avatar);



