export const getInvoiceAddress = (addressId, customer) => {
    if (!customer) {
        return null;
    }

    if (!addressId) {
        return {
            notes: '',
            city: customer.locationText || '',
            street: customer.locationText2 || '',
            postIndex: customer.locationText3 || '',
            costCenter: customer.invoiceCostCenter || '',
            contactName: customer.invoiceContactName || '',
            contactEmail: customer.invoiceContactEmail || '',
            contactPhone: customer.invoiceContactPhone || ''
        }
    }

    const invoiceAddresses = customer.invoiceAddresses || [];

    const address = invoiceAddresses.find(({ id }) => id === addressId);

    if (!address) {
        return null;
    }

    return address || {
        postIndex: '', city: '', street: '', notes: '',
        costCenter: '', contactName: '', contactEmail: '', contactPhone: ''
    };
}