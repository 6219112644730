import ToggleButtonGroup from "components/common/toggle-btn-group";
import { useTranslation } from "react-i18next";

const ProjectInScopeEditor = ({ value, onChange }) => {
    const { t } = useTranslation(['ui']);

    return (
        <div>
            <ToggleButtonGroup
                positiveColor={value === 1 ? 'var(--color-positive)' : 'var(--color-accent)'}
                toggleButtons={[
                    {
                        text: t('ui:PROJECT_INVOICE_ROW_IS_IN_SCOPE'),
                        key: 1,
                        value: 1,
                    },
                    {
                        text: t('ui:PROJECT_INVOICE_ROW_IS_OUT_OF_SCOPE'),
                        key: 0,
                        value: 0,
                    },
                ]}
                toggeled={value}
                onClick={val => onChange(val)}
                size="mini"
            />
        </div>
    );
};

export default ProjectInScopeEditor;
