import { deletee, get, patch, post, put } from '../../../utils/http/HttpClient';
import Acl from './api/Acl';
import Permission from './api/Permission';

export default {
    fetchAcls: async () => {
        const { acls } = await get('/acls');

        return acls.map(a => new Acl(a));
    },
    fetchAclDetails: async id => {
        const { acl } = await get(`/acls/${id}`);

        return new Acl(acl);
    },
    patchConfiguration: async conf => {
        await patch('/instance/conf', conf);
    },
    patchWorkOrderConfiguration: async conf => {
        await patch('/instance/wo', conf);
    },
    patchBusinessObjectConfiguration: async conf => {
        await patch('/instance/bo', conf);
    },
    createAcl: async acl => {
        const { id } = await post('/acls', { acl });

        return id;
    },
    updateAcl: async acl => {
        await put('/acls', { acl });
    },
    deleteAcl: async id => {
        await deletee(`/acls/${id}`);
    },
    fetchPermissions: async () => {
        const { permissions } = await get('/acls/permissions');

        return permissions.map(p => new Permission(p));
    },
    createDepartment: async department => {
        const { id } = await post('/instance/departments', { department });

        return id;
    },
    updateDepartment: async department => {
        await put('/instance/departments', { department });
    },
    deleteDepartment: async id => {
        await deletee(`/instance/departments/${id}`);
    },
    fetchDepartmentConfiguration: async id => {
        return await get(`/instance/departments/${id}`);
    },
    fetchInstance: async () => {
        return await get('/instance');
    },
    fetchTimeReportTags: async () => {
        return await get('/timereports/conf/tags');
    },
    createTimeReportTag: async () => {
        return await post('/timereports/conf/tags');
    },
    updateTimeReportTag: async tag => {
        return await put('/timereports/conf/tags', tag);
    },
    deleteTimeReportTag: async id => {
        return await deletee(`/timereports/conf/tags/${id}`);
    },
    fetchCompensationReportTags: async () => {
        return await get('/timereports/conf/compensation/tags');
    },
    createCompensationReportTag: async () => {
        return await post('/timereports/conf/compensation/tags');
    },
    updateCompensationReportTag: async tag => {
        return await put('/timereports/conf/compensation/tags', tag);
    },
    deleteCompensationReportTag: async id => {
        return await deletee(`/timereports/conf/compensation/tags/${id}`);
    },
    fetchChecklists: async () => {
        const { checklists } = await get('/instance/checklist');

        return checklists;
    },
    createChecklist: async name => {
        const { id } = await post('/instance/checklist', { name });

        return id;
    },
    updateChecklist: async checklist => {
        return await put('/instance/checklist', checklist);
    },
    deleteChecklist: async id => {
        return await deletee(`/instance/checklist/${id}`);
    },
    getErpAuthenticateUrl: async () => {
        const { url } = await post('/instance/erp/auth');

        return url;
    },
    getErpData: async type => {
        return await get(`/instance/erp/data/${type}`);
    },
    getEmailOauthAuthenticateUrl: async () => {
        const { url } = await post('/instance/mail/auth');

        return url;
    },
    fetchServiceReports: async () => {
        const { serviceReports } = await get('/instance/servicereport');

        return serviceReports;
    },
    createServiceReport: async (name, type) => {
        const { id } = await post('/instance/servicereport', { name, type });

        return id;
    },
    updateServiceReport: async serviceReport => {
        return await put('/instance/servicereport', serviceReport);
    },
    deleteServiceReport: async (id, type) => {
        return await post(`/instance/servicereport/delete`, {
            id,
            type,
        });
    },
    updateAppModuleConfiguration: async (appModuleId, configuration) => {
        return await put('/instance/appmodule', {
            appModuleId,
            configuration,
        });
    },
    getAppModuleInternalConfiguration: async appModuleId => {
        return await get(`/instance/appmodule/${appModuleId}/internal`);
    },
    updateAppModuleInternalConfiguration: async (
        appModuleId,
        configuration
    ) => {
        return await put('/instance/appmodule/internal', {
            appModuleId,
            configuration,
        });
    },
    updateSupplierTagsAppModuleConfiguration: async tags => {
        return await put('/instance/appmodule/suppliertags', {
            tags
        });
    },
    createWorkOrderDispatchRule: async rule => {
        const { id } = await post('/instance/wo/dispatchrule', rule);

        return id;
    },
    updateWorkOrderDispatchRule: async rule => {
        return await put('/instance/wo/dispatchrule', rule);
    },
    deleteWorkOrderDispatchRule: async id => {
        return await deletee(`/instance/wo/dispatchrule/${id}`);
    },
    fetchWorkOrderDispatchRules: async () => {
        const { rules } = await get('/instance/wo/dispatchrule');

        return rules;
    },
    postMetric: async (name, timeMs) => {
        try {
            await post(`/instance/m`, {
                name, timeMs
            });
        } catch (err) {

        }
    }
};
