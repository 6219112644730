import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { configureStore } from './data/stores/store.js';
import { storeHolder } from './data/stores/storeHolder.js';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import 'trukks-lib/build/index.css';
import './index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const store = configureStore();
storeHolder.registerStore(store);

const queryClient = new QueryClient();

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <App i18n={i18n} />
                </QueryClientProvider>
            </BrowserRouter>
        </Provider>
    </I18nextProvider>,
    document.getElementById('root')
);
