import MicrosoftAuthButton from 'components/common/microsoft-auth-button';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, Input } from 'semantic-ui-react';
import { get } from 'utils/http/HttpClient';
import Alert from '../common/alert';

const getErrorMessage = code => {
    switch (code) {
        case 401:
            return 'INVALID_EMAIL_OR_PASSWORD';
        case 409:
            return 'MFA_AUTHENTICATION_FAILED';
        case 429:
            return 'IP_BLOCKED_TRY_AGAIN_LATER';
        default:
            return 'SERVER_ERROR';
    }
};

class LoginForm extends React.Component {
    state = {
        email: '',
        password: '',
        errors: {},
        loading: true,
        microsoftAuthEnabled: false,
        passwordDisabled: false
    };

    componentDidMount = () => {
        const fetchIsEnabled = async () => {
            try {
                const { oauthEnabled, passwordDisabled } = await get(
                    `/authenticate/microsoft?hostname=${window.location.hostname}`
                );

                this.setState({
                    loading: false,
                    microsoftAuthEnabled: oauthEnabled,
                    passwordDisabled: passwordDisabled
                });

            } catch (err) {
                this.setState({
                    loading: false,
                    microsoftAuthEnabled: false,
                    passwordDisabled: false
                });
            }
        };

        fetchIsEnabled();
    };

    login = ({ email, password }) => {
        const errors = [];

        if (!email) {
            errors.email = 'messages:ERROR_REQUIRED_FIELD';
        }

        if (!password) {
            errors.password = 'messages:ERROR_REQUIRED_FIELD';
        }

        this.setState(
            {
                errors,
            },
            () => {
                if (errors.email || errors.password) {
                    return;
                }

                this.props.onSubmit(email, password);
            }
        );
    };

    handleSubmit = () => {
        this.login(this.state);
    };

    handleInputChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <Form
                    onSubmit={this.handleSubmit}
                    style={{
                        opacity:
                            this.props.isLoading || this.state.isLoading
                                ? 0.5
                                : 1,
                    }}>
                    {!this.state.passwordDisabled && (
                        <>
                            <Form.Field error={!!this.state.errors['email']}>
                                <label>{t('EMAIL')}</label>
                                <Input
                                    name="email"
                                    placeholder="user@company.com"
                                    className="man-login-input"
                                    value={this.state.email}
                                    onChange={this.handleInputChange}
                                />
                                {this.state.errors['email'] && (
                                    <label>
                                        {this.props.t(
                                            this.state.errors['email']
                                        )}
                                    </label>
                                )}
                            </Form.Field>

                            <Form.Field error={!!this.state.errors['password']}>
                                <label>{t('PASSWORD')}</label>
                                <Input
                                    name="password"
                                    type="password"
                                    placeholder="&#x25cf;&#x25cf;&#x25cf;&#x25cf;&#x25cf;&#x25cf;"
                                    className="man-login-input"
                                    value={this.state.password}
                                    onChange={this.handleInputChange}
                                />
                                {this.state.errors['password'] && (
                                    <label>
                                        {this.props.t(
                                            this.state.errors['password']
                                        )}
                                    </label>
                                )}
                            </Form.Field>

                            <Button
                                className="Login-Form-Button man-button-primary"
                                loading={this.props.isLoading}>
                                {t('LOG_IN')}
                            </Button>
                        </>
                    )}
                    {this.state.microsoftAuthEnabled && <MicrosoftAuthButton />}
                </Form>
                {this.props.message && (
                    <Alert type="error" message={t(this.props.message)} />
                )}
                {this.props.error && (
                    <Alert
                        type="error"
                        message={t(getErrorMessage(this.props.error.code))}
                    />
                )}
            </React.Fragment>
        );
    }
}

LoginForm.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.object,
};

export default LoginForm;
