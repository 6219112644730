import React, { FC, SyntheticEvent, useState } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

interface DropdownOption {
    key: string;
    text: string;
    value: string | number;
}

interface Props {
    children?: any;
    asButton?: boolean;
    className?: string;
    options: DropdownOption[];
    selectOnBlur: boolean;
    selectOnNavigation: boolean;
    direction?: 'left' | 'right';
    trigger: React.ReactNode;
    onChange: (
        event: SyntheticEvent<HTMLElement, Event>,
        data: DropdownProps
    ) => void;
}

const LocalizedDropdown: FC<Props> = ({
    children,
    selectOnNavigation,
    selectOnBlur,
    asButton,
    ...props
}) => {
    return (
        <Dropdown
            {...props}
            {...(asButton && { value: '' })}
            selectOnBlur={selectOnBlur}
            selectOnNavigation={selectOnNavigation}
            noResultsMessage={'NO_RESULTS_FOUND'}
            additionLabel={`${'ADD'} `}
            icon={null}>
            {children}
        </Dropdown>
    );
};

export default LocalizedDropdown;
