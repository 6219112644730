import React from 'react';
import { Grid } from 'semantic-ui-react';

export const SettingsGrid = ({ children, style }) => (
    <Grid columns='two' divided style={{ margin: '30px 0 15px 0', ...style }}>
        {children}
    </Grid>
);

export const SettingsRow = ({ hoverable, small, label, children, style, rowStyle, compact }) => (
    <Grid.Row className={`man-settings-row${hoverable ? ' hoverable' : ''}${compact ? ' compact' : ''}`} style={rowStyle}>
        <Grid.Column className="man-settings-label-column" width={small ? 2 : 4}>
            {label}
        </Grid.Column>
        <Grid.Column className="man-settings-value-column man-ui-wrap-newline" width={12}
            style={style}>
            {children}
        </Grid.Column>
    </Grid.Row>
);
