import Model from './api/Model';
import { get, put, post, deletee } from '../../../utils/http/HttpClient';
import { STATUS_ACTIVE } from '../../../utils/constants';

export default {
    query: async query => {
        const { models } = await get(`/models?q=${query}`);

        return models.map(m => new Model(m));
    },
    fetch: async () => {
        const { models } = await get('/models');

        return models.map(m => new Model(m));
    },
    fetchDetails: async id => {
        const { model } = await get(`/models/${id}`);

        return model;
    },
    create: async model => {
        model.status = STATUS_ACTIVE;
        const { id } = await post('/models', { model });

        return id;
    },
    update: async model => {
        await put('/models', { model });
    },
    delete: async id => {
        await deletee(`/models/${id}`);
    }
}