export const se_eliotprotect = {
    ui: {

    },

    form: {
    COMMENT: 'Beskrivning utfört arbete',
    },

    acl: {
        
    },

};