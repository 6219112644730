export default class Model {

    id;
    code;
    name;
    status;
    objectCount = 0;

    constructor({ id, code, name, status, objectCount }) {
        this.id = id;
        this.code = code;
        this.name = name;
        this.status = status;
        this.objectCount = objectCount;
    }

}