import React from 'react';

const SecondaryMenuContainer = ({ title, children, style }) => (
    <div className="man-secondary-menu-container man-shadow">
        {title &&
            <h2 style={{ marginBottom: 5, ...style }}>{title}</h2>
        }
        {children}
    </div>
)

export default SecondaryMenuContainer;