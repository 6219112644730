import React, { Component } from 'react';
import { connect } from 'react-redux';

export const withInstanceData = ComposedComponent => {
    class WithInstanceData extends Component {

        hasAppModule = requiredModule => {
            const { appModules } = this.props;

            return appModules && !!appModules[requiredModule];
        }

        render() {
            return <ComposedComponent
                hasAppModule={this.hasAppModule}
                {...this.props} />
        }
    }

    const mapStateToProps = state => ({
        appModules: state.auth.appModules
    })

    return connect(mapStateToProps)(WithInstanceData);
}