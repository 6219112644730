import React, { FC } from 'react';
import classnames from 'classnames';
import styles from './Item.module.css';

interface Props {
    children: any;
    onClick?: () => void;
    active?: boolean;
    style?: object;
    className?: string;
    compact?: boolean;
}

const Item: FC<Props> = React.forwardRef(
    ({ children, onClick, active = false, style, className, compact }, ref) => {
        return (
            <div
                ref={ref}
                className={classnames(
                    styles.item,
                    className,
                    { [styles.compact]: compact },
                    { [styles.clickable]: !!onClick },
                    { [styles.active]: active }
                )}
                style={style}
                onClick={onClick}>
                {children}
            </div>
        );
    }
);

export default Item;
