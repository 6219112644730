import React from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import { Estonian } from 'flatpickr/dist/l10n/et.js';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { Swedish } from 'flatpickr/dist/l10n/sv.js';
import { Latvian } from 'flatpickr/dist/l10n/lv.js';
import { english } from 'flatpickr/dist/l10n/default.js';
import 'flatpickr/dist/themes/airbnb.css';
import { connect } from 'react-redux';
import FlatButton from '../flat-button';
import './DatePicker.css';
import { appModules as modules } from 'utils/constants/appModules';
import { useInstanceData } from '../use-instance-data';
import withInstanceData from '../with-instancedata';

const mapToFlatpickrFormat = (format, enableTime) => {
    switch (format) {
        case 'DD.MM.YYYY': return `d.m.Y${enableTime ? ' H:i' : ''}`;
        case 'MM/DD/YYYY': return `m/d/Y${enableTime ? ' H:i' : ''}`;
        case 'YYYY-MM-DD': return `Y-m-d${enableTime ? ' H:i' : ''}`;
        default: return 'm/d/Y';
    }
}

const mapToFlatpickrLocale = (language) => {
    switch (language) {
        case 'ee': return 'et';
        case 'ru': return 'ru';
        case 'se': return 'sv';
        case 'lv': return 'lv';
        default: return 'default';
    }
}

const arraysEqual = (array1, array2) => array1.length === array2.length && array1.sort().every(function (value, index) { return value === array2.sort()[index] });

class DatePicker extends React.Component {



    shouldComponentUpdate(nextProps) {

        return !arraysEqual(nextProps.value, this.props.value);
    }

    handleClear = () => {
        this.props.onDatesChange(this.props.name, null);
    }

    handleDatesChange = dates => {
        const val = dates
            .map(d => {
                if (!this.props.enableTime && !this.props.timeIncluded) {
                    d.setHours(8);
                }

                const setDefaultTimes = !(this.props.value && this.props.value[0]) && this.props.timeIncluded;

                if (setDefaultTimes) {
                    d.setHours(this.props.defaultHour);
                    d.setMinutes(this.props.defaultMinute);
                } else if (this.props.value && this.props.value[0] && this.props.timeIncluded) {
                    d.setHours(new Date(this.props.value[0]).getHours());
                    d.setMinutes(new Date(this.props.value[0]).getMinutes());
                }

                return d.getTime();
            });

        if (this.props.multiple || this.props.type === "range") {
            this.props.onDatesChange(this.props.name, val);
            return;
        }

        if (val.length > 0) {
            this.props.onDatesChange(this.props.name, val[0])
            return;
        }

        this.props.onDatesChange(this.props.name, null);
    }

    render() {
        const { value, placeholder, clearable } = this.props;

        const val = value.filter(v => v);

        let verboseShowYear = false;

        if (val[0]) {
            if (typeof val[0] === 'number') {
                verboseShowYear = new Date().getFullYear() !== new Date(this.props.value[0]).getFullYear();
            } else if (this.props.value[0].getFullYear) {
                verboseShowYear = new Date().getFullYear() === this.props.value[0].getFullYear();
            }
        }

        let verboseFormat = `${this.props.verboseShowMonth ? 'F j ' : ''}${this.props.verboseShowTime ? 'H:i' : ''}${verboseShowYear ? ', Y' : ''}`;

        const useNativeTimePicker = this.props.appModules[modules.UI_SETTINGS]?.custom?.datePicker === 'native';

        return (
            <React.Fragment>

                <Flatpickr
                    className="flatpickr-input"
                    onChange={this.handleDatesChange}
                    value={val}
                    placeholder={placeholder}
                    disabled={this.props.disabled}
                    style={this.props.style}
                    options={{
                        onClose: (selectedDates, dateStr) => {
                            console.log(`DatePicker -> onClose selectedDates:`)
                            console.log(selectedDates);
                        },
                        dateFormat: mapToFlatpickrFormat(this.props.format, this.props.enableTime),
                        locale: mapToFlatpickrLocale(this.props.language),
                        mode: this.props.type,
                        position: this.props.position || 'auto',
                        positionElement: this.props.positionElement || null,
                        enableTime: this.props.enableTime,
                        time_24hr: true,
                        altInput: this.props.verbose,
                        altFormat: verboseFormat,
                        minDate: this.props.minDate,
                        maxDate: this.props.maxDate,
                        weekNumbers: true,
                        defaultHour: this.props.defaultHour,
                        defaultMinute: this.props.defaultMinute,
                        inline: this.props.inline,
                        onDayCreate: this.props.onDayCreate,
                        disableMobile: !useNativeTimePicker
                    }} />

                {clearable &&
                    <span className="man-datepicker-clear-container">
                        <FlatButton icon="remove" onClick={this.handleClear}></FlatButton>
                    </span>}
            </React.Fragment>
        )
    }
}

DatePicker.propTypes = {
    name: PropTypes.string.isRequired,
    onDatesChange: PropTypes.func.isRequired,
    value: PropTypes.array,
    enableTime: PropTypes.bool,
    verbose: PropTypes.bool,
    verboseShowMonth: PropTypes.bool,
    verboseShowTime: PropTypes.bool,
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
    placeholder: PropTypes.string,
    clearable: PropTypes.bool,
    defaultHour: PropTypes.number,
    defaultMinute: PropTypes.number,
    inline: PropTypes.bool,
    onDayCreate: PropTypes.func,
    disabled: PropTypes.bool
}

DatePicker.defaultProps = {
    type: 'single', // single, multiple, range
    value: [],
    enableTime: false,
    verbose: false,
    verboseShowMonth: true,
    verboseShowTime: true,
    minDate: null,
    maxDate: null,
    placeholder: '',
    clearable: false,
    defaultHour: 12,
    defaultMinute: 0,
    inline: false,
    disabled: false
}

const mapStateToProps = state => ({
    language: state.auth.user.configuration.language,
    format: state.auth.instance.configuration.dateFormat,
    appModules: state.auth.appModules
})

export default connect(mapStateToProps)(DatePicker);