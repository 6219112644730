import {
    FETCH_CUSTOMERS,
    FETCH_CUSTOMERS_FULFILLED,
    FETCH_CUSTOMERS_REJECTED,
    CUSTOMER_CHANGED,
    CUSTOMER_TABLE_STATE_CHANGE,
    CUSTOMER_FILTER_CHANGE
} from '../../actions/customers';
import { STATUS_ACTIVE } from '../../../utils/constants';

const defaultState = {
    isFetching: false,
    customers: [],
    tableState: undefined,
    searchFilters: {
        status: STATUS_ACTIVE,
        customerType: 'CUSTOMER_TYPE_ALL'
    }
}

export const customers = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case CUSTOMER_TABLE_STATE_CHANGE: return ({
            ...state,
            tableState: action.state
        })
        case CUSTOMER_FILTER_CHANGE: {
            const filters = { ...state.searchFilters };

            filters[action.filterName] = action.value;

            return {
                ...state,
                searchFilters: filters
            }
        }
        case FETCH_CUSTOMERS: {
            return {
                ...state,
                isFetching: true
            }
        }
        case FETCH_CUSTOMERS_FULFILLED: {
            return {
                ...state,
                isFetching: false,
                customers: action.customers
            }
        }
        case FETCH_CUSTOMERS_REJECTED: {
            return {
                ...state,
                isFetching: false
            }
        }
        case CUSTOMER_CHANGED: {
            const customers = [...state.customers];
            const idx = customers.findIndex(c => c.id === action.customer.id);

            if (idx < 0) {
                customers.push(action.customer);
            } else {
                customers[idx] = action.customer;
            }

            return {
                ...state,
                customers
            }
        }
        default: {
            return state;
        }
    }
};