export const se_professionell_sakerhet = {
    ui: {
        DEPARTMENTS: 'Teams',
        DEPARTMENT: 'Team',
        DEPARTMENT_EDIT_TITLE: 'Ändra team',
        FILTER_BY_DEPARTMENT: 'Team',
    },
    form: {
        CONTACT: 'Beställare',
        ADDRESS: 'Fakturamottagare',
        DEPARTMENT: 'Team',
        WORK_ORDER_DESCRIPTION: 'Beskrivning av situation (internt)',
        SUMMARY: 'Fakturatext (sammanställning av utfört arbete) ',
        PROJECT_MENU_INVOICING: 'Projektfakturor',
        WORK_ORDER_ADDRESS: 'Arbetsorderns adress',
    },
    messages: {
        ERROR_DEPARTMENT_HAS_USERS: 'Kan inte radera. Teamet har tillhörande användare.',
    }
}