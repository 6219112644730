export const getInitials = name => {
    if (!name) {
        return '';
    }

    if (name.length < 2) {
        return name;
    }

    const splited = name.split(' ');

    if (splited.length < 2) {
        return name.substring(0, 2);
    }

    return splited.map(s => s.substring(0, 1)).join('');
}

export const getUserDataById = (id, users) => {
    if (!users || users.length === 0) {
        return 'Unknown user';
    }

    const user = users.find(u => u.id === id);

    if (!user) {
        return {
            id,
            fullName: 'Unknown user'
        };
    }

    return user;
}

export const getDepartmentStyle = department => {
    if (!department || !department.configuration || !department.configuration.color) {
        return {};
    }

    return { backgroundColor: department.configuration.color };
}

export const defaultUserSort = (u1, u2) => {
    if (u1.department.displayName === u2.department.displayName) {
        return u1.fullName > u2.fullName ? 1 : -1;
    }

    return u1.department.displayName > u2.department.displayName ? 1 : -1;
}