export const se_primohiss = {
    ui: {
        OBJECTS: 'Hissar',
        MODELS: 'Hisstyper',
        CLOSE_WORKORDER: 'Skapa fakturaunderlag!',
        SETTINGS_BUSINESS_OBJECT: 'Hiss',
        INVOICE_MARKING_OPTION_OBJECT: 'Hissens märkning - {{value}}',
        CUSTOMER_OBJECT_CONTACT_COUNT:
            'Det finns {{count}} kontakt på någon av kundens hissar',
        CUSTOMER_OBJECT_CONTACT_COUNT_plural:
            'Det finns {{count}} kontakter på någon av kundens hissar',
        OBJECT: 'Hiss',
    },
    form: {
        CUSTOMER_LOCATION_2: 'Fakturaadress',
        SERIAL: 'Hiss-ID',
        OBJECT: 'Hiss',
        OBJECT_COUNT: 'Hissar',
        PROJECT_OBJECTS_INVOLVED: 'Involverade hissar',
    },
    acl: {
        PERMISSION_BUSINESS_OBJECTS_READ: 'Se hissar',
        PERMISSION_BUSINESS_OBJECTS_EDIT: 'Ändra hissar',
        PERMISSION_BUSINESS_OBJECTS_DELETE: 'Radera hissar',
        PERMISSION_MODELS_READ: 'Se hisstyper',
        PERMISSION_MODELS_EDIT: 'Ändra hisstyper',
        PERMISSION_MODELS_DELETE: 'Radera hisstyper',
    },
};
