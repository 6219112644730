export default class Agreement {

    id;
    title;
    description;
    status;
    configuration;
    objectCount = 0;

    constructor({ id, title, description, status, configuration, objectCount }) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.status = status;
        this.configuration = configuration;
        this.objectCount = objectCount;
    }

}