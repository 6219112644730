import React, { useRef, useState } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import ReactPlayer from 'react-player/lazy';
import Divider from '../divider';
import FlatButton from '../flat-button';
import './FilesImagePreview.css';

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts/`,
};

const MAX_SCALE = 2;
const MIN_SCALE = 0.6;

const FilePreview = ({ url, type }) => {

    const [numPages, setNumPages] = useState(null);
    const [scale, setScale] = useState(1);
    const pdfRef = useRef();

    const increaseScale = () => setScale(s => Math.min(MAX_SCALE, s + 0.2));

    const decreaseScale = () => setScale(s => Math.max(MIN_SCALE, s - 0.2));

    const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
        setNumPages(nextNumPages);
    };

    const [isMouseDown, setIsMouseDown] = useState(false);

    const mouseCoords = useRef({
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0
    });
    
    const handleDragStart = (e) => {
        if (!pdfRef.current) {
            return;
        }

        const slider = pdfRef.current.children[0];
        const startX = e.pageX - slider.offsetLeft;
        const startY = e.pageY - slider.offsetTop;
        const scrollLeft = slider.scrollLeft;
        const scrollTop = slider.scrollTop;

        mouseCoords.current = { 
            startX, 
            startY, 
            scrollLeft, 
            scrollTop
        };

        setIsMouseDown(true);
    
        document.body.style.cursor = 'grabbing';
    }

    const handleDragEnd = () => {
        setIsMouseDown(false);
        if (!pdfRef.current) {
            return;
        }

        document.body.style.cursor = 'default';
    }

    const handleDrag = (e) => {
        if (!isMouseDown || !pdfRef.current) {
            return;
        }
        e.preventDefault();
        
        const slider = pdfRef.current.children[0];
        const x = e.pageX - slider.offsetLeft;
        const y = e.pageY - slider.offsetTop;
        const walkX = (x - mouseCoords.current.startX) * 1.5;
        const walkY = (y - mouseCoords.current.startY) * 1.5;
        slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
        slider.scrollTop = mouseCoords.current.scrollTop - walkY;
    }

    return <>
        {type === 'application/pdf' ? (
            <div style={{
                position: 'relative'
            }}>
                <div style={{
                    float: 'right',
                    position: 'fixed',
                    zIndex: 9999,
                    right: 34
                }}>
                    <FlatButton icon="zoom in"
                        className="man-bordered"
                        onClick={increaseScale}
                        disabled={scale >= MAX_SCALE} />

                    <FlatButton icon="zoom out"
                        className="man-bordered"
                        onClick={decreaseScale}
                        disabled={scale <= MIN_SCALE} />
                </div>

                <div ref={pdfRef} 
                    onMouseDown={handleDragStart} 
                    onMouseUp={handleDragEnd} 
                    onMouseMove={handleDrag}
                    onMouseLeave={handleDragEnd}>
                    <Document
                        file={url}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={options}>
                        {Array.from(
                            new Array(numPages > 100 ? 100 : numPages),
                            (el, index) => (
                                <React.Fragment
                                    key={`page_${index + 1}`}>
                                    <Page
                                        scale={scale}
                                        renderAnnotationLayer={true}
                                        renderForms={true}
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                    />
                                    <Divider
                                        style={{
                                            fontWeight: 400,
                                        }}>
                                        <span className="trks-secondary-text">
                                            {`${index + 1}/${numPages}`}
                                        </span>
                                    </Divider>
                                </React.Fragment>
                            )
                        )}
                    </Document>
                </div>
            </div>
        ) : type.indexOf('video') > -1 ||
            type.indexOf('audio') > -1 ? (
            <ReactPlayer controls={true} url={url}
                style={{
                    maxWidth: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }} />
        ) : (
            <img
                src={url}
                style={{
                    width: '100%',
                    height: '100%',
                }}
            />
        )}
    </>
}

export default FilePreview;