import { authenticateWithMicrosoft } from 'data/actions/common/authActions';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';
import './MicrosoftAuthButton.css';
import { ReactComponent as MsIcon } from './ms-icon.svg';

const MicrosoftAuthButton = () => {
    const { t } = useTranslation(['form', 'ui']);
    const dispatch = useDispatch();

    const handleMicrosoftAuth = async e => {
        e.preventDefault();
        dispatch(authenticateWithMicrosoft());
    };

    return (
        <>
            <Button
                className="microsoft-auth-btn-container"
                onClick={handleMicrosoftAuth}>
                <MsIcon />
                <span className="microsoft-auth-btn-text">
                    {t('MICROSOFT_AUTH_LOGIN')}
                </span>
            </Button>
        </>
    );
};

export default MicrosoftAuthButton;
