export default class Acl {

    id;
    displayName;
    description;
    configuration;
    permissions;

    constructor({ id, displayName, description, configuration, permissions }) {
        this.id = id;
        this.displayName = displayName;
        this.description = description;
        this.configuration = configuration;
        this.permissions = permissions;
    }

}