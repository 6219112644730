import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { DropTargetMonitor, XYCoord, useDrag, useDrop } from 'react-dnd';
import { Input, Popup } from 'semantic-ui-react';
import { formatDateTimeVerbose } from '../../utils/DateUtils';
import { formatBytes } from '../../utils/MathUtils';
import FlatButton from '../flat-button/FlatButton';
import Icon from '../icon/Icon';
import Item from '../item/Item';
import LocalizedDropdown from '../localized-dropdown/LocalizedDropdown';
import AddLinkPopup, { AddLinkPopupTranslations, LinkProps } from './AddLinkPopup';
import {
    AudioFileType,
    FileExtensions,
    FileMIMETypes,
    FileTypes,
    ImageFileTypes,
    VideoFileType,
} from './FileConstants';
import { EditTranslations, FileTranslations, FileType } from './FileType';

interface Props {
    index: number;
    moveFile: (dragIndex: number, hoverIndex: number) => void;
    moveToFolder: (fileId: string, folderId: string) => void;
    reorderFileToPosition: (fileId: string, newOrderIndex: number) => void;
    editable: boolean;
    fileData: FileType;
    publishEnabled: boolean;
    loading: boolean;
    compact?: boolean;
    onFolderClick: Function;
    onClick: Function;
    onDelete: Function;
    onPublish: Function | undefined;
    onUnpublish: Function | undefined;
    onNameChange: (fileId: string, newName: string) => void;
    onLinkChange: (fileId: string, linkProps: LinkProps) => void;
    isTouchScreen: boolean;
    editTranslations: EditTranslations;
    linkTranslations: AddLinkPopupTranslations;
    fileTranslations: FileTranslations;
}

interface DragDropItem {
    index: number;
    id: string;
    type: string;
}

const isPreviewable = (fileType: string): boolean => {
    return (
        ImageFileTypes.includes(fileType) ||
        fileType === FileMIMETypes.PDF ||
        fileType.indexOf(VideoFileType) > -1 ||
        fileType.indexOf(AudioFileType) > -1
    );
};

const getFileExtension = (fileName: string): string => {
    const splitName = fileName.split('.');

    return splitName[1] ? splitName[1].toLowerCase() : '';
};

const isTypeImage = (fileName?: string) => {
    if (!fileName) {
        return false;
    }

    const fileExtension = getFileExtension(fileName);
    switch (fileExtension) {
        case FileExtensions.JPG:
        case FileExtensions.JPEG:
        case FileExtensions.PNG:
            return true;
        default:
            return false;
    }
};

const getFileIcon = (type: string, fileName: string): string => {
    if (type === FileTypes.FOLDER) {
        return 'folder outline';
    }
    if (type === FileTypes.BACK_FOLDER) {
        return 'arrow left';
    }
    if (type === FileTypes.LINK) {
        return 'external alternate';
    }

    const fileExtension = getFileExtension(fileName);
    switch (fileExtension) {
        case FileExtensions.JPG:
        case FileExtensions.JPEG:
        case FileExtensions.PNG:
            return 'file image outline';
        case FileExtensions.DOCX:
        case FileExtensions.ODT:
            return 'file word outline';
        case FileExtensions.XLS:
        case FileExtensions.XLSX:
        case FileExtensions.CSV:
        case FileExtensions.ODS:
            return 'file excel outline';
        case FileExtensions.PDF:
            return 'file pdf outline';
        case FileExtensions.PPT:
        case FileExtensions.PPTX:
        case FileExtensions.ODP:
            return 'file powerpoint outline';
        default:
            return 'file outline';
    }
};

const fileNameWithoutExtension = (fileName: string): string => {
    const splitName = fileName.split('.');

    return splitName[1] ? splitName[0] : fileName;
};

function notEmpty<T>(value: T | null): value is T {
    return value !== null;
}

const File: React.FC<Props> = ({
    index,
    moveFile,
    moveToFolder,
    reorderFileToPosition,
    fileData,
    editable,
    onFolderClick,
    onClick,
    onDelete,
    onNameChange,
    onLinkChange,
    onPublish,
    onUnpublish,
    isTouchScreen,
    editTranslations,
    linkTranslations,
    fileTranslations,
    compact,
    publishEnabled,
}) => {
    const id = fileData.file.id;
    const { file, url: fileUrl } = fileData;
    const [isEditingName, setIsEditingName] = useState(false);
    const [isEditingLink, setIsEditingLink] = useState(false);
    const [fileName, setFileName] = useState(file.originalFileName);
    const [url, setUrl] = useState(fileUrl);
    const nameInputRef = useRef<any>(null);
    const isFolder =
        file.type === FileTypes.FOLDER || file.type === FileTypes.BACK_FOLDER;
    const isBackFolder = file.type === FileTypes.BACK_FOLDER;
    const isLink = file.type === FileTypes.LINK && fileUrl;

    const dndRef = useRef<HTMLDivElement>(null);
    const [{ handlerId, isOver }, drop] = useDrop({
        accept: FileTypes.FILE,
        collect(monitor) {
            return {
                isOver: isFolder && monitor.isOver(),
                handlerId: monitor.getHandlerId(),
            };
        },
        drop: () => ({ id: file.id, index: index, type: file.type }),
        hover(item: DragDropItem, monitor: DropTargetMonitor) {
            if (!dndRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (
                (item.type === FileTypes.FOLDER && !isFolder) ||
                (item.type === FileTypes.FILE) ||
                dragIndex === hoverIndex ||
                !editable
            ) {
                return;
            }

            const hoverBoundingRect = dndRef.current?.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY =
                (clientOffset as XYCoord).y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveFile(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: FileTypes.FILE,
        item: () => {
            return {
                id,
                index,
                type: isFolder ? FileTypes.FOLDER : FileTypes.FILE,
            };
        },
        canDrag: !isBackFolder && !isLink && editable,
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult<DragDropItem>();
            if (
                item &&
                dropResult &&
                (dropResult.type === FileTypes.FOLDER ||
                    dropResult.type === FileTypes.BACK_FOLDER) &&
                dropResult.id !== item.id
            ) {
                moveToFolder(item.id, dropResult.id);
                return;
            }

            const dropIndex = item?.index;
            reorderFileToPosition(item.id, dropIndex);
        },
    });
    drag(drop(dndRef));

    useEffect(() => {
        if (isEditingName) {
            nameInputRef.current!.select();
        }
    }, [isEditingName]);

    const handleAction = async (action: string) => {
        switch (action) {
            case 'dl':
                const win = window.open(fileData.url!)!;
                win.focus();
                return;
            case 'rm':
                handleDelete();
                return;
            case 'rn':
                setIsEditingName(true);
                return;
            case 'el':
                setIsEditingLink(true);
                return;
            case 'pub':
                onPublish && onPublish(file.id);
                return;
            case 'unpub':
                onUnpublish && onUnpublish(file.id);
                return;
            default:
                return null;
        }
    };

    const handleDelete = async () => onDelete(id);

    const options = [
        url && !isFolder && !isLink
            ? { key: 'dl', text: editTranslations.download, value: 'dl' }
            : null,
        editable && !isLink
            ? { key: 'rn', text: editTranslations.rename, value: 'rn' }
            : null,
        editable && publishEnabled && onPublish && file.published !== true
            ? { key: 'pub', text: editTranslations.publish, value: 'pub' }
            : null,
        editable && publishEnabled && onUnpublish && file.published === true
            ? { key: 'unpub', text: editTranslations.unpublish, value: 'unpub' }
            : null,
        editable && isLink
            ? { key: 'el', text: editTranslations.editLink, value: 'el' }
            : null,
        editable
            ? { key: 'rm', text: editTranslations.remove, value: 'rm' }
            : null,
    ].filter(notEmpty);

    const handleNameChange = (event: any) => {
        const isBlurEvent = event.type === 'blur';

        if (event.key === 'Enter' || isBlurEvent) {
            const fileName = event.target.value;
            setFileName(fileName);
            onNameChange(file.id, fileName);
            setIsEditingName(false);
            return;
        }

        if (event.keyCode === 27) {
            setFileName(file.originalFileName);
            setIsEditingName(false);
            return;
        }
    };

    const handleLinkChange = async (linkProps: LinkProps) => {
        setFileName(linkProps.name || '');
        setUrl(linkProps.link || '')
        onLinkChange(file.id, linkProps);
        setIsEditingLink(false);

        return Promise.resolve();
    }

    const handleClick = () => {
        if (isEditingName) {
            return;
        }

        if (isFolder) {
            onFolderClick(file.id, file.originalFileName);
            return;
        }

        if (isPreviewable(file.type)) {
            onClick(fileData);
            return;
        }
    };

    const dragDropItemOpacity = isDragging ? (isTouchScreen ? 0.5 : 0) : 1;

    const DisplayedName = ({ className }: { className?: string }) => {
        if (isLink) {
            const path = url.includes('://') ? url : `//${url}`;
            return (
                <a
                    className={className}
                    href={path}
                    target="_blank"
                    rel="noreferrer noopener">
                    {fileName}
                </a>
            );
        }

        return <p className={className}>{fileName}</p>;
    };

    const renderNameField = () => {
        return isEditingName ? (
            <>
                <Input
                    className={classNames({
                        'man-file-name-field-input-compact': compact,
                    })}
                    ref={nameInputRef}
                    value={fileName}
                    onChange={e => setFileName(e.target.value)}
                    onKeyDown={handleNameChange}
                    onBlur={handleNameChange}
                />
            </>
        ) : (
            <DisplayedName
                className={classNames({
                    'man-file-name-field-compact': compact,
                })}
            />
        );
    };

    if (compact) {
        return (
            <Item
                ref={dndRef}
                data-handler-id={handlerId}
                className={classNames('man-file-compact', {
                    'man-file-extra-padding':
                        isBackFolder || (isFolder && options.length === 0),
                })}
                onClick={handleClick}
                style={{
                    opacity: dragDropItemOpacity,
                }}
                compact>

                {publishEnabled && file && file.published === true &&
                    <div style={{
                        position: 'absolute',
                        left: 0,
                        top: 0
                    }}>
                        <Icon name="globe"
                            style={{
                                color: 'var(--color-primary)'
                            }} />
                    </div>
                }
                {file && (
                    <>
                        {!isTypeImage(file.fileName) ? (
                            <div className="man-file-preview-icon-container-compact">
                                {/* TODO compact link editing, bottomsheet */}
                                <Icon name={getFileIcon(file.type, file.fileName)} />
                            </div>
                        ) : (
                            <div
                                className="man-file-preview"
                                style={{
                                    backgroundImage: `url(${url})`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                }}
                            />
                        )}
                        {renderNameField()}
                    </>
                )}
                {options.length > 0 && !isBackFolder && (
                    <LocalizedDropdown
                        className="man-file-actions-compact"
                        options={options}
                        onChange={(e, { value }: any) => handleAction(value)}
                        direction="left"
                        selectOnNavigation={false}
                        selectOnBlur={false}
                        asButton={true}
                        trigger={
                            <FlatButton
                                active={false}
                                icon="ellipsis vertical"
                                style={{ textDecoration: 'none' }}
                            />
                        }
                    />
                )}
            </Item>
        );
    }

    return (
        <Item
            ref={dndRef}
            data-handler-id={handlerId}
            className={classNames('man-file', {
                'man-file-hover-folder': isOver,
                'man-file-extra-padding':
                    isBackFolder || (isFolder && options.length === 0),
            })}
            onClick={handleClick}
            style={{
                opacity: dragDropItemOpacity,
            }}>
            {file && (
                <>
                    <div className={'man-file-name-input'}>
                        {file.type === FileTypes.LINK && isEditingLink ?
                            <AddLinkPopup
                                translations={linkTranslations}
                                defaultOpen={true}
                                onSave={handleLinkChange}
                                name={file.originalFileName}
                                link={url || ''}
                                trigger={
                                    <Icon
                                        name={getFileIcon(file.type, file.fileName)}
                                        style={{ fontSize: '1.3rem' }}
                                    />
                                }>
                            </AddLinkPopup>
                            :
                            <Icon
                                name={getFileIcon(file.type, file.fileName)}
                                style={{ fontSize: '1.3rem' }}
                            />
                        }
                        {renderNameField()}
                    </div>
                    <div className="man-secondary-text">
                        {formatDateTimeVerbose(file.createTs)}
                    </div>
                    <div className="man-secondary-text">
                        {!isFolder && formatBytes(file?.sizeBytes)}
                    </div>
                </>
            )}
            <div style={{ justifySelf: 'end' }}>
                {publishEnabled && file && file.published === true &&
                    <Popup
                        inverted
                        content={fileTranslations?.ispublished}
                        trigger={
                            <Icon name="globe"
                                style={{
                                    color: 'var(--color-primary)'
                                }} />
                        } />
                }
                {options.length > 0 && !isBackFolder && (
                    <LocalizedDropdown
                        options={options}
                        onChange={(e, { value }: any) => handleAction(value)}
                        direction="left"
                        selectOnNavigation={false}
                        selectOnBlur={false}
                        asButton={true}
                        trigger={
                            <FlatButton
                                active={false}
                                icon="ellipsis vertical"
                                style={{ textDecoration: 'none' }}
                            />
                        }
                    />
                )}
            </div>
        </Item>
    );
};

export default File;
