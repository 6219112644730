export default class Invoice {
    id;
    companyLogo;
    articles;
    invoiceNr;
    invoiceSequence;
    totalPrice;
    status;
    customer;
    date;
    data;
    createTs;
    totalPriceVat;
    vatPercentage;
    paymentDueDate;
    bgAccount;
    periodFrom;
    periodTo;
    customerAddressId;
    marking;
    description;
    erpReferenceId;

    constructor({ id, status, totalPrice, invoiceNr, invoiceSequence, articles, customer,
        invoiceDate, data, createTs, totalPriceVat, vatPercentage, paymentDueDate, bgAccount,
        periodFrom, periodTo, customerAddressId, marking, description, erpReferenceId }) {
        this.id = id;
        this.status = status;
        this.totalPrice = totalPrice;
        this.invoiceNr = invoiceNr;
        this.invoiceSequence = invoiceSequence;
        this.articles = articles;
        this.customer = customer;
        this.date = invoiceDate;
        this.data = JSON.parse(data);
        this.createTs = createTs;
        this.totalPriceVat = totalPriceVat;
        this.vatPercentage = vatPercentage;
        this.paymentDueDate = paymentDueDate;
        this.bgAccount = bgAccount;
        this.periodFrom = periodFrom;
        this.periodTo = periodTo;
        this.customerAddressId = customerAddressId;
        this.marking = marking;
        this.description = description;
        this.erpReferenceId = erpReferenceId;
    }
}