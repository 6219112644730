import React from 'react';
import { SettingsRow } from '../../../common/settings-grid';

const HistoryListLoader = () => (
    [1, 2, 3].map(i => (
        <SettingsRow key={i} label={
            <div className="ui placeholder" style={{ maxWidth: 200, marginLeft: 'auto' }}>
                <div className="paragraph">
                    <div className="line"></div>
                </div>
            </div>
        }>
            <div className="ui fluid placeholder">
                <div className="paragraph">
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </div >
        </SettingsRow>
    ))
)

export default HistoryListLoader;