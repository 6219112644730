export const lv = {
    ui: {
        BTN_NEW_WORKORDER: 'Jauns darba uzdevums',
        DEPARTMENTS: 'Nodaļas',
        DEPARTMENT: 'Nodaļa',
        PRIORITY: 'Prioritāte',
        REPORTS: 'Atskaites',
        REPORTS_HIGH_PRIORITY: 'Augsta prioritāte',
        REPORTS_CREATED_BY_ME: 'Mani sagatavotie',
        REPORTS_ASSIGNED_TO_ME: 'Man piešķirtie',
        REPORTS_RECENTLY_COMPLETED: 'Nesen pabeigts',
        REPORTS_RECENTLY_DELETED: 'Nesen izdzēsts',
        STATISTICS: 'Statistika',
        CUSTOM_REPORTS: 'Pielāgotas atskaites',
        RECYCLE_BIN: 'Miskaste',
        RECENTLY_CLOSED: 'Nesen aizvērts',
        QUICK_ACCESS: 'Ātrā piekļuve',
        FILTER_WORKORDER_PLACEHOLDER: 'Filtrēt darba uzdevumus…',
        PROFILE: 'Profils',
        EDIT_PROFILE: 'Labot pofilu',
        LOG_OUT: 'Iziet',
        SETTINGS: 'Iestatījumi',
        INVENTORY: 'Reģistrs',
        MAP: 'Karte',
        STATISTICS_DASHBOARD: 'Statistics', // missing
        DASHBOARD: 'Darba panelis',
        CALENDAR: 'Kalendārs',
        CALENDAR_PERSPECTIVE: 'Kalendāra perspektīva',
        WORK_ORDERS: 'Darba uzdevumi',
        CLOSED_WORK_ORDERS: 'Pabeigtie darba uzdevumi',
        OPEN_WORK_ORDERS: 'Atvērtie darba uzdevumi',
        EXTRA_WORK_WORK_ORDERS: 'ÄTA work orders', // missing
        TIME_REPORTING: 'Laika atskaite',
        TIME_REPORTING_STATISTIC_HEADER: 'Tehniķa laika atskaites',
        AGREEMENT_INVOICES: 'Līguma rēķini',
        LIST: 'Saraksts',
        SCHEDULED: 'Plānots',
        AGREEMENT_INVOICE_SCHEDULED_AT: 'Plānots',
        NO_WORKS_PLANNED: 'Nav ieplānotu darba uzdevumu',
        SETTINGS_GENERAL: 'Vispārīgi',
        SETTINGS_USERS: 'Lietotāji',
        SETTINGS_ACLS: 'Piekļuves līmeņi',
        SETTINGS_WORKORDER: 'Darba uzdevums',
        SETTINGS_BUSINESS_OBJECT: 'Objekts',
        SETTINGS_PROPOSAL: 'Piedāvājums',
        SETTINGS_SALES_ORDER: 'Pasūtījums',
        SETTINGS_ARTICLES: 'Artikuls',
        SETTINGS_ARTICLES_PRICE_LISTS: 'Cenu lapas',
        SETTINGS_ARTICLES_GROUPS: 'Artikulu grupas',
        SETTINGS_ARTICLES_SUPPLIER_ARTICLES: 'Piegādātāja artikuli',
        SETTINGS_DEVIATIONS: 'Novirzes',
        SETTINGS_PROJECT_RESOURCE_PLANNING: 'Project resources', // missing
        SETTINGS_IMPORT_EXPORT: 'Import & Export', // missing
        SETTINGS_SUPPLIERS: 'Suppliers', // missing
        USERPICKER_ADD_PEOPLE: 'Pievienot cilvēkus',
        TAGPICKER_ADD_TAG: 'Pievienot tagus',
        BOPICKER_ADD: 'Pievienot',
        CUSTOMERPICKER_SELECT: 'Izvēlēties klientu',
        MODELPICKER_SELECT: 'Izvēlēties modeļa veidu',
        WORKORDERPICKER_SELECT: 'Meklēt darba uzdevumu',
        SUPPLIERPICKER_SELECT: 'Meklēt piegādātāju',
        ARTICLEPICKER_SELECT: 'Meklēt artikulu',
        PROJECTPICKER_SELECT: 'Meklēt projektu',
        BOPICKER_SELECT: 'Meklēt',
        LOCATIONPICKER_SEARCH: 'Meklēt atrašanās vietu',
        CUSTOMERS: 'Klienti',
        MODELS: 'Modeļi',
        SUPPLIERS: 'Piegādātāji',
        AGREEMENTS: 'Līgumi',
        CUSTOMER: 'Klients',
        CUSTOMER_PRICE_LIST: 'Aktīvs cenrādis',
        PRICE_LIST: 'Cenu lapa',
        GENERAL: 'Vispārīgi',
        AGREEMENT: 'Līgums',
        HISTORY: 'Vēsture',
        SPECIFICATION: 'Specifikācija',
        OBJECTS: 'Objekti',
        ARTICLES: 'Artikuli',
        CUSTOMER_EDIT_DIALOG_TITLE: 'Labot klientu',
        NEW_CUSTOMER_DIALOG_TITLE: 'Jauns klients',
        USER_INVITE_DIALOG_TITLE: 'Reģistrēt jaunu klientu',
        NEW_BUSINESS_OBJECT_MODAL_TITLE: 'Jauns objekts',
        ARTICLE_EDIT_DIALOG_TITLE: 'Labot artikulu',
        ACL_EDIT_DIALOG_TITLE: 'Labot pieejas līmeni',
        DEPARTMENT_EDIT_TITLE: 'Labot nodaļu',
        USER_EDIT_DIALOG_TITLE: 'Labot lietotāju',
        MODEL_EDIT_DIALOG_TITLE: 'Labot modeli',
        NEW_AGREEMENT_MODAL_TITLE: 'Jauns līgums',
        STATUS_ACTIVE: 'Aktīvs',
        STATUS_INACTIVE: 'Neaktīvs',
        STATUS_REGISTERED: 'Lietotājs ielūgts…',
        SEARCH: 'Meklēt',
        FIND_ANYTHING: 'Atrast jebko…',
        RELOAD: 'Pārlādēt',
        BACK: 'Atpakaļ',
        SORT: 'Šķirot',
        VIEW: 'Skatīt',
        STATUS: 'Statuss',
        VIEW_ALL: 'Skatīt visus',
        EDIT: 'Labot',
        EDIT_INTERNAL_INFO: 'Labot iekšējo informāciju',
        ADD: 'Pievienot',
        SAVE: 'Saglabāt',
        SAVE_AND_CLOSE: 'Saglabāt un aizvērt',
        RESEND_REGISTRATION_INVITATION: 'Akārtoti nosūtīt ielūgumu',
        MORE_ACTIONS: 'Vairāk darbības',
        PLEASE_CONFIRM_ACTION: 'Lūdzu apstipriniet',
        ACTION_CONFIRM: 'Apstiprināt',
        ACTION_CANCEL: 'Atcelt',
        USER: 'Lietotājs',
        INVITE_USER: 'Ielūgt lietotāju',
        REGISTER_NEW_USER: 'Reģistrēt jaunu lietotāju',
        REMOVE: 'Izdzēst',
        REMOVE_ROW: 'Izdzēst rindu',
        DOWNLOAD_ALL: 'Download all', // missing
        DOWNLOAD: 'Lejupielādēt',
        SELECT: 'Izvēlēties',
        FILTER: 'Filtrēt',
        RESET_FILTERS: 'Clear all', // missing
        FILTER_BY_DEPARTMENT: 'Pēc nodaļas',
        FILTER_BY_USER: 'Pēc lietotāja',
        FILTER_BY_CUSTOMER: 'Pēc klienta',
        FILTER_BY_PRIORITY: 'Pēc prioritātes',
        FILTER_BY_ASSIGNEE: 'Filtrēt atbildīgo',
        SELECT_ASSIGNEE: 'Atzīmēt atbildīgo',
        INCLUDE_SCHEDULED_WORK_ORDERS: 'Iekļaut ieplānoto',
        PIECE_SHORT: 'gab.',
        PIECES_SHORT: 'gab.',
        UNASSIGNED: 'Nav norīkots',
        PLAN_WORK_ORDER: 'Plan work order', // missing
        ON_HOLD: 'Nopauzēts',
        DATE: 'Datums',
        USERS_VIEW: 'Lietotāja skats',
        LIST_VIEW: 'Saraksta skats',
        CALENDAR_VIEW: 'Kalendāra skats',
        ADD_NOTE: 'Pievienot piezīmi',
        ADD_NEW_ROW: 'Pievienot rindu',
        NO_RESULTS_FOUND: 'Rezultāti netika atrasti',
        SEARCHING: 'Meklē…',
        DELETE: 'Izdzēst',
        CLOSE: 'Aizvērt',
        CREATE_NEW_TAG: 'Izveidot jaunu tagu',
        VIEW_HISTORY: 'Skatīt vēsturi',
        SEND_EMAIL: 'Nosūtīt e-pastu',
        SEND_EMAIL_TO_ME: 'Email to me', // missing
        PRINT: 'Printēt',
        CLOSE_WORKORDER: 'Pabeigt darba uzdevumu',
        CREATE_WORKORDER: 'Izveidot jaunu darba uzdevumu',
        ENABLED: 'Iespējots',
        DISABLED: 'Atspējots',
        TOTAL: 'Kopā',
        N_MORE: '{{ count }} vēl...',
        SUBTOTAL: 'Starpsumma',
        START: 'Sākt',
        END: 'Beigt',
        WORKORDERS_NOT_FOUND: 'Darba uzdevumi netika atrasti',
        LOAD_MORE: 'Ielādēt vēl',
        PROCESSING: 'Apstrādā…',
        INTAKE_DATE: 'Izveidots',
        UPDATE_DATE: 'Atjaunināts',
        NEWER_FIRST: 'jaunākais vispirms',
        OLDER_FIRST: 'vecākais virspirms',
        SCHEDULED_TO_DESC: 'ieplānots pēdējais',
        SCHEDULED_TO_ASC: 'ieplānots nākamais',
        UPDATED: 'Atjaunināts',
        CREATED: 'Izveidots',
        CREATED_AT: 'Izveidots {{datetime}}',
        CREATED_AT_BY_USER: 'Izveidoja {{datetime}}: {{user}}',
        CREATED_BY: 'Created by', // missing
        CLOSED: 'Pabeigts',
        MANAGE_OBJECTS: 'Pārvaldīt objektus',
        MANAGE_CUSTOMERS: 'Pārvaldīt klientus',
        MANAGE_MODELS: 'Pārvaldīt modeļus',
        MANAGE_ARTICLES: 'Pārvaldīt artikulus',
        MANAGE_AGREEMENTS: 'Pārvaldīt līgumus',
        MANAGE_SUPPLIERS: 'Pārvaldīt piegādātājus',
        CREATE_NEW_WORKTYPE: 'Izveidot jaunu darba veidu',
        CREATE_NEW_STATUS: 'Izveidot jaunu statusu',
        DEFAULT_TYPE: 'Noklusējuma veids',
        DEFAULT_TAG: 'Default tag', // missing
        DEFAULT_STATUS: 'Noklusējuma darba pasūtījuma statuss',
        DEFAULT_LANGUAGE: 'Noklusējuma valoda',
        CLOSING: 'Beigas',
        SELECT_DEFAULT: 'Izvēlēties pēc noklusējuma',
        SELECT_CLOSING: 'Izvēlēties beigas',
        SHOW_TODAY: 'Rādīt šodienu',
        SELECT_DATE: 'Izvēlēties datumu',
        CLOSED_AT: 'Aizvērts',
        RECIPIENT: 'Saņēmējs',
        INVOICE: 'Rēķins',
        INVOICES: 'Rēķini',
        INVOICE_NR: 'Rēķina numurs',
        YES: 'Jā',
        NO: 'Nē',
        ACTIVATED: 'Aktivizēts',
        INACTIVATED: 'Deaktivizēts',
        VAT: 'PVN',
        PDF: 'PDF',
        LIMIT_NONE_BTN: 'VISI',
        LIMIT_USER_BTN: 'LIETOTĀJS',
        LIMIT_CREATED_BY_USER_BTN: 'CREATED BY USER', // missing
        DISCOUNT_PLACEHOLDER: 'Atlaide % vai',
        TIME_REPORTING_PERIODS: 'Laika atskaites periods',
        TIME_REPORTING_PERIODS_WEEKEND: 'Laika atskaites periods brīvdienās',
        TIME_REPORTS_ON_WORK_ORDERS: 'Pēc darba uzdevumiem',
        TIME_REPORTS_ON_WORK_ORDERS_WEEKEND:
            'Pēc darba uzdevumiem - brīvdienās',
        TIME_REPORTS_OTHER: 'Cits',
        TIME_REPORTS_OTHER_ATTENDING: 'Cits - piedalās',
        TIME_REPORTS_OTHER_ABSENCE: 'Cits - prombūtne',
        TIME_REPORTS_DISTANCE: 'KM',
        TIME_REPORT_PERIOD_DESCRIPTION: 'Marķējums',
        TIME_REPORTING_LOCKING: 'Bloķēt laika atskaites',
        TIME_REPORTS_GET_LOCKED_ON_DAY_OF_NEXT_MONTH:
            'Atskaites tiks bloķētas nākamā mēneša {{day}} dienā.',
        TIME_HOURS: '{{count}}h',
        TIME_MINUTES: '{{count}}min',
        TIMEREPORT_FOR_USER: 'Laika atskaite priekš {{name}}',
        LOCK: 'Bloķēt',
        UNLOCK: 'Atbloķēt',
        LOCKED: 'Bloķēts',
        WORK_ORDER: 'Darba uzdevums',
        AGREEMENT_INVOICE: 'Līguma rēķins',
        EXPORT_TO_EXCEL: 'Eksportēt uz Excel',
        UPLOADING_FILE: 'Augšupielādē {{fileName}}',
        NO_PREVIEW_AVAILABLE: 'Nav priekšskatījuma',
        SAVING: 'Saglabā…',
        ALL_CHANGES_SAVED: 'Visas izmaiņas saglabātas',
        ARTICLE_STOCK: 'Krājumi',
        ARTICLES_IN_STOCK: 'Krājumā',
        NEW_SUPPLIER_MODAL_TITLE: 'Jauns piegādātājs',
        NEW_DOORAUTOMATIC_MODAL_TITLE: 'New doorautomatic', // missing
        INTERFACE: 'Interface',
        INTERFACE_WORKORDER_LIST_TYPE: 'Darba uzdevumu saraksta veids',
        INTERFACE_WORKORDER_LIST_TYPE_SINGLE:
            'Karte un darba uzdevums atsevišķi',
        INTERFACE_WORKORDER_LIST_TYPE_SPLIT: 'Karte un darba uzdevums kopā',
        SHOW_ON_MAP: 'Rādīt kartē',
        OPEN_DIRECTIONS_IN_GOOGLE_MAPS: 'Atvērt norādes Google Maps',
        COUNT_OF_MORE_EVENTS: '{{count}} vēl...',
        MARGIN_PERCENTAGE: '{{margin}}% starpība',
        MARGIN_PERCENTAGE_SHORT: '{{margin}}% starpība',
        MANAGE_STOCK: 'Pārvaldīt krājumus',
        DELIVER_IN_UNITS: 'Piegādāt vienībās',
        ADD_TO_STOCK: 'Pievienot noliktavai',
        ARTICLE_ACTIVATE_IN_DELIVERY: 'Aktivizējiet piegādi',
        ARTICLE_SHOW_SPSC: 'Rādīt sertificētu informāciju',
        ARTICLE_STOCK_HISTORY_LOGS: 'Darbību vēsture',
        STOCK_RESERVED: 'Rezervēts darba pasūtījumos',
        STOCK_INVOICED: 'Jaunākais rēķins',
        STOCK_TRANSACTIONS: 'Darījumi',
        CONFIGURE: 'Konfigurēt',
        PREVIEW: 'Priekšskatījums',
        PROJECT: 'Projekts',
        PROJECTS: 'Projekti',
        ADD_NEW_PROJECT: 'Jauns projekts',
        PROJECT_TASK: 'Task', // missing
        SALES_ORDERS: 'Pasūtījumi',
        PROPOSALS: 'Priekšlikumi',
        SELECT_ARTICLE: 'Izvēlēties artikulu',
        SELECT_ARTICLE_GROUPS: 'Select article groups', // missing
        SELECT_ARTICLE_MORE_OPTIONS_BACK: 'Back...', // missing
        SELECT_ARTICLE_MORE_OPTIONS: 'More...', // missing
        SELECT_ARTICLE_FILTERING_IS_ACTIVE: 'Group filtering is on!', // missing
        ADD_COMMENT: 'Pievienot komentāru',
        ADD_COMMENT_ROW: 'Pievienot komentāra rindu',
        ADD_ARTICLE_ROW: 'Pievienot artikula rindu',
        ADD_SUMMARY_ROW: 'Pievienot kopsavilkuma rindu',
        FILTER_SALES_ORDERS: 'Atrast pasūtījumus',
        FILTER_PROPOSALS: 'Atrast priekšlikumus',
        DELETE_PROJECT: 'Izdzēs projektu',
        CLOSE_PROJECT: 'Noslēgt projektu',
        REOPEN_PROJECT: 'Atkārtoti atvērt projektu',
        PROPOSAL_STATUS_ACTIVE: 'Aktīvs',
        PROPOSAL_STATUS_ACCEPTED: 'Pieņemts',
        PROPOSAL_STATUS_DECLINED: 'Noraidīts',
        PROPOSAL_ACCEPT: 'Akceptēt un izveidot pasūtījumu',
        PROPOSAL_ACCEPT_AND_CREATE_WORKORDER:
            'Akceptēt un izveidot darba uzdevumu',
        PROPOSAL_DECLINE: 'Noraidīt un paslēpt',
        PROPOSAL_IS_ACCEPTED: 'Pieņemts',
        PROPOSAL_IS_ACCEPTED_AT_BY_USER: 'Pieņemts {{datetime}} ({{user}})',
        PROPOSAL_IS_DECLINED: 'Noraidīts',
        PROPOSAL_IS_DECLINED_AT_BY_USER: 'Noraidīts {{datetime}} ({{user}})',
        PROPOSAL_CREATE_NEW: 'Izveidot piedāvājumu',
        FILTER_DEVIATIONS: 'Filtrēt novirzes',
        DEVIATION_CREATE_NEW: 'Jauna novirze',
        PROPOSAL_CLEAR_CUSTOMER: 'Noņemt klientu',
        PROPOSAL_MAKE_COPY: 'Sagatavot kopiju',
        WORK_ORDER_MAKE_COPY: 'Sagatavot kopiju',
        MAKING_COPY_PLEASE_WAIT: 'Gatavo kopiju. Lūdzu uzgaidiet.',
        SET_AS_PRIMARY_ADDRESS: 'Iestatīt kā primāro adresi',
        ADD_NEW_POTENTIAL_CUSTOMER: 'Pievienot jaunu potenciālo klientu',
        SHOW_REGULAR_CUSTOMERS: 'Klienti',
        SHOW_POTENTIAL_CUSTOMERS: 'Potenciālie klienti',
        ADD_CUSTOMER_NR: 'Pievienot klienta numuru',
        NEW_CUSTOMER_NUMBER_DIALOG_TITLE: 'Pievienot klienta numuru',
        WORD_EXPORT: 'Eksportēt uz word',
        MODIFY_ARTICLE_DESCRIPTION: 'Mainīt aprakstu',
        CREATE_INVOICE: 'Sagatavot rēķinu',
        VIEW_INVOICE: 'Skatīt rēķinu',
        UPDATE_ADDRESS_FROM_CUSTOMER_ADDRESS:
            'Atjaunot adresi ar adresi no klienta',
        ADD_SIGNATURE: 'Pievienot parakstu',
        CLEAR_SIGNATURE: 'Notīrīt',
        SERVICE_REPORT_SAVE_AND_GENERATE_DOCUMENT:
            'Saglabāt un izveidot dokumentu',
        SERVICE_REPORT_CLOSED_AT_BY_USER: 'Aizvērts {{datetime}} ({{user}})',
        ABSENCE_INFO: 'Informācija par prombūtni',
        MY_TIME_REPORTS: 'Manas laika atskaites',
        TIME_REPORTED_FOR_DAY: 'ziņots {{time}} ',
        ALL_ATTEND_TIME_REPORTS: 'Visas laika atskaites',
        ALL_ATTEND_TIME_REPORTED_FOR_DAY:
            'Ziņots par {{reported}}/{{expected}} stundām',
        INVOICE_MARKING_OPTION_CONTACT_COST_CENTER:
            'Sazināties ar izmaksu centru - {{value}}',
        INVOICE_MARKING_OPTION_CUSTOMER: 'Klients - {{value}}',
        INVOICE_MARKING_OPTION_OBJECT: 'Līgums - {{value}}',
        DEFAULT: 'Noklusējums',
        INTERNAL_MESSAGES: 'Iekšējie ziņojumi',
        MY_WORKORDERS: 'Mani gaidāmie darba uzdevumi',
        START_TIMER: 'SĀKT',
        STOP_TIMER: 'BEIGT',
        TIME_REPORTS_WAITING: 'Gaida…',
        TIME_REPORTS_DISTANCE_KM: 'KM',
        TIME_REPORTS_REST_TIME: 'Atpūtas laiks',
        TIME_REPORTS_TRAVEL_TIME: 'Travel time', // missing
        TIME_REPORTS_REPORTED_DISTANCE_KM: '{{total}} KM',
        TIME_REPORTS_REPORTED_TRAVEL_TIME: '{{time}} travel', // missing
        CUSTOMER_OBJECT_CONTACT_COUNT:
            'Šim klientam ir {{count}} kontaktpersona par objektiem',
        CUSTOMER_OBJECT_CONTACT_COUNT_plural:
            'Šim klientam ir {{count}} kontaktpersonas par objektiem',
        PERCENTAGE_OF_ESTIMATE: '{{percentage}} no aptuvenā',
        PROJECT_TIME_REPORTED_ON_WORK_ORDERS: '{{time}} on work orders', // missing
        PROJECT_TIME_REPORTED_ON_OPEN_WORK_ORDERS:
            '{{time}} uz atvērtiem uzdevumiem',
        PROJECT_TIME_REPORTED_ON_CLOSED_WORK_ORDERS:
            '{{time}} pabeigtiem darba uzdevumiem',
        PROJECT_COST_PRICE_ON_WORK_ORDERS: '{{value}} on work orders', // missing
        PROJECT_COST_PRICE_ON_OPEN_WORK_ORDERS:
            '{{value}} par atvērtiem darba uzdevumiem',
        PROJECT_COST_PRICE_ON_CLOSED_WORK_ORDERS:
            '{{value}} pabeigtiem darba uzdevumiem',
        PROJECT_ONGOING_COST: 'Pastāvīgās izmaksas',
        TIME_REPORTING_CONFIRM_FINISHED_WITH_WORKORDER:
            'Esmu pabeidzis visus darbus šajā darba pasūtījumā',
        TIME_REPORTING_USERS_FINISHED: '{{initials}} pabeigts',
        TIME_REPORTING_USER_FINISHED: 'OK',
        SCHEDULE: 'Laika plāns',
        SCHEDULING_ADD_TIME: 'laikā',
        SCHEDULING_ADD_HOURS: 'stundās',
        SCHEDULED_TO: 'Paredzēts līdz',
        SCHEDULED_ON_N_DAYS: 'Plānots 1 dienā',
        SCHEDULED_ON_N_DAYS_plural: 'Plānots {{count}} dienās',
        PROJECT_WORK_ORDER_MARK_INCLUDED: 'Atzīmēt kā iekļautu',
        PROJECT_WORK_ORDER_MARK_INVOICED: 'Pievienot nepabeigtajiem rēķiniem',
        PROJECT_INVOICING_BACKLOG: 'Nepabeigts',
        PROJECT_NEW_INVOICE: 'Sagatavot rēķinu',
        PROJECT_INVOICE_NR: 'Rēķins #{{number}}',
        PROJECT_WORK_ORDER_ARTICLE_MARK_INVOICED: 'Pievienot rēķinam',
        PROJECT_WORK_ORDER_ARTICLE_MARK_INCLUDED: 'Atzīmēt kā iekļautu',
        PROJECT_DELETE_INVOICE: 'Dzēst rēķinu',
        PROJECT_FINALIZE_INVOICE: 'Aizvērt rēķinu',
        PROJECT_BACKLOG_SHOW_ALL: 'Radīt visu',
        PROJECT_BACKLOG_SHOW_OPEN: 'Rādīt atvērtos',
        PROJECT_ARTICLE_INVOICED: 'Izrakstīts rēķins',
        PROJECT_ARTICLE_INCLUDED: 'Iekļauts',
        WORKORDER_INCLUDE_IN_PROJECT: 'Iekļauts projektā',
        WORKORDER_CREATE_DEVIATION: 'Create deviation', // missing
        PROJECT_TYPE_CLOSED: 'Aizvērts',
        PROJECT_TYPE_ONGOING: 'Aktīvs',
        PROJECT_ONGOING_COST_REPORT_NEW: '> Ziņot',
        PROJECT_ONGOING_COST_REPORT: 'Ziņot',
        PROJECT_ONGOING_COST_REPORT_LAST_SUBMITTED: '{{price}} ({{date}})',
        WORK_ORDER_ARTICLE_FROM_SUPPLIER_SHORT: 'No',
        SERVICE_REPORT_WILL_BE_EMAILED_TO: 'Tiks nosūtīts uz ',
        SERVICE_REPORT_WAS_EMAILED_TO: 'Nosūtīts uz',
        COLLAPSE_CUSTOMER_ADDRESSES_LIST: 'Slēpt visu',
        EXTEND_CUSTOMER_ADDRESSES_LIST: 'Rādīt visu',
        EDIT_ARTICLE_GROUP: 'Rediģēt artikulu grupu',
        EDIT_PRICE_LIST: 'Rediģēt cenu sarakstu',
        PRICING_TYPE: 'Cenu veids',
        PRICING_TYPE_ENABLED_ON_ARTICLE_GROUPS: 'Atspējot artikulu grupā',
        PRICING_TYPE_DISCOUNT: 'Atlaide',
        PRICING_TYPE_ADDITION_ON_PURCHASE_PRICE:
            'Uzcenojums % no pirkuma cenas',
        PRICING_TYPE_ADDITION_ON_SALES_PRICE:
            'Uzcenojums % no pārdošanas cenas',
        NEW_PRICE_LIST_PRICING_TYPE: 'Jauns cenu veids',
        OPEN_BUSINESS_OBJECT_SERVICE_LOG: 'Ikdienas servisa grāmatiņa',
        CUSTOMER_ZONE_AUTH_CODE_SHOW: 'Rādīt',
        CUSTOMER_ZONE_AUTH_CODE_HIDE: 'Slēpt',
        SALES_ORDER_VIEW_AND_CREATE_PDF_RECEIPT:
            'Izveidot manuāli apmaksātu kvīti',
        SALES_ORDER_VIEW_PDF_RECEIPT: 'Skatīt manuālo kvīti',
        RENAME: 'Pārsaukt',
        PUBLISH_FILE: 'Publish file', // missing
        UNPUBLISH_FILE: 'Unpublish file', // missing
        INCLUDING_VAT: 'ar PVN',
        EXCLUDING_VAT: 'bez PVN',
        INTERFACE_START_PAGE: 'Sākumlapa',
        INTERFACE_START_PAGE_WORKORDERS: 'Darba uzdevumi',
        INTERFACE_START_PAGE_DASHBOARD: 'Darba panelis',
        WORK_ORDER_INVOICE_INCLUDED_IN_PROJECT: 'Iekļauts projektā',
        UPDATE_SUPPLIER_ARTICLES: 'Augšupielādēt jaunas cenas',
        SELECT_DEPARTMENT: 'Izvēlēties nodaļu',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_HEADER:
            'Atvērt darba uzdevumus, lai izrakstītu rēķinu katrai nodaļai',
        AGREEMENT_INVOICING_KPI_HEADER:
            'Līguma rēķinu izrakstīšanas kopsavilkums',
        OPEN_PROJECTS_KPI_HEADER: 'Aktīvo projektu kopsavilkums',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_TO_INVOICE_ON_PURCHASE_PRICE:
            'Materiālos',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_TO_INVOICE_ON_TIME: 'Laikā',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_DEBIT_FACTOR_ON_PURCHASE_PRICE:
            'Debit factor purchase price',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_HOUR_COST: 'Stundas likme',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_DISTANCE_COST: 'Price / KM', // missing
        DEPARTMENT_NONE: 'Nav atlasīts',
        WORK_ORDER_INVOICE_MARKING_PLACEHOLDER: 'Rēķina marķēšana',
        CALENDAR_SHOW_ONLY_MY_CALENDAR: 'Rādīt tikai manu kalendāru',
        UPLOADING: 'Augšupielādē..',
        ON: 'Ieslēgts',
        OFF: 'Izslēgts',
        CLOSE_ALL_TABS: 'Aizvērt visas tabulas',
        CUSTOMER_TYPE: 'Type', // missing
        CUSTOMER_TYPE_ALL: 'All', // missing
        CUSTOMER_PRIVATE: 'Privāts',
        CUSTOMER_COMPANY: 'Uzņēmums',
        CUSTOMER_PRIVATE_PERSON_ID_PLACEHOLDER: 'Personas numurs',
        CUSTOMER_PRIVATE_PERSON_NAME_PLACEHOLDER: 'Vārds',
        TAX_REDUCTION_MARK_NONE: 'Nav',
        TAX_REDUCTION_MARK_ROT: 'ROT',
        TAX_REDUCTION_MARK_RUT: 'RUT',
        TAX_REDUCTION_MARK_GREEN: 'Zaļš',
        WORKORDER_TIMEREPORTS_N_ATTACHED:
            '{{selected}} no {{total}} pievienoti',
        WORKORDER_TIMEREPORTS_ATTACH: 'Pievienot laika atskaiti rēķinam',
        WORKORDER_TIMEREPORTS_ATTACH_ALL: 'Atzīmēt visu',
        WORKORDER_TIMEREPORTS_ATTACHED: 'Pievienots',
        WORKORDER_TIMEREPORTS_NOT_ATTACHED: 'Nav pievienots',
        WORK_ORDER_SCHEDULING_STATUS: 'Plānošanas statuss',
        WORK_ORDER_SCHEDULE_STATUS_BOTH: 'Viss',
        WORK_ORDER_SCHEDULE_STATUS_NOT_SCHEDULED: 'Tikai neplānotie',
        WORK_ORDER_SCHEDULE_STATUS_SCHEDULED: 'Tikai plānotie',
        ADD_WORK_ORDER_INTERNAL_INFO: 'Pievienojiet iekšējo informāciju',
        DEVIATIONS: 'Novirzes',
        DEVIATION_VIEW_LOG: 'Skatīt žurnālu',
        DEVIATION_CHANGE_STATE_STARTED: 'Sākts',
        DEVIATION_CHANGE_STATE_DEFINED: 'Definēts',
        DEVIATION_CHANGE_STATE_NEEDS_WORK: 'Nepieciešami uzlabojumi',
        DEVIATION_CHANGE_STATE_APPROVED: 'Apstiprināts',
        DEVIATION_STATUS_OPEN: 'Atvērts',
        DEVIATION_STATUS_CLOSED: 'Slēgts',
        DEVIATION_STATUS_ALL: 'Viss',
        CLOSE_DEVIATION: 'Tuva novirze',
        ARTICLE: 'Artikuls',
        OBJECT: 'Objekts',
        SUPPLIER: 'Piegādātājs',
        DEVIATION_DASHBOARD_GO_TO_LIST: 'Skatīt visas novirzes sarakstā',
        DEVIATION_DASHBOARD_BY_CATEGORY: 'Novirzes pēc kategorijas',
        DEVIATION_DASHBOARD_BY_RELATION: 'Visbiežākās novirzes',
        DEVIATION_DASHBOARD_LATEST: 'Jaunākās novirzes',
        ADD_WORK_ORDER_ON_SITE_CONTACT: 'Pievienot kontaktpersonu',
        PROJECT_TIME_REPORTS_EXPORT_EXCEL: 'Eksportēt laika atskaites',
        PROJECT_TIME_REPORTS_DOWNLOAD_EXCEL: 'Lejupielādēt',
        PROJECT_TIME_REPORTS_EXCEL_FILENAME: 'Eksportēt_laika atskaites',
        OPEN_PROJECTS_SUMMARY_DOWNLOAD_EXCEL:
            'Lejupielādēt projektu kopsavilkumu',
        OPEN_PROJECT_SUMMARY_EXCEL_FILENAME: 'Projekti',
        ARTICLE_PRODUCT_TYPE_SERVICE: 'Serviss',
        ARTICLE_PRODUCT_TYPE_STOCK: 'Ir noliktavā',
        ARTICLE_PRODUCT_TYPE_NON_STOCK: 'Nav noliktavā',
        WORKORDER_TIMEREPORTS_SHOWING_ONLY_MINE: 'Rādīt tikai manu',
        WORKORDER_TIMEREPORTS_SHOWING_ALL: 'Rādīt visu',
        GO_TO_PROPOSAL: 'Doties uz piedāvājumu',
        PROJECT_SORT_BY_TIME: 'Ongoing', // missing
        PROJECT_SORT_BY_NAME: 'Alphabetical', // missing
        PROJECT_SORT_BY_RESPONSIBLE_USER: 'Project manager', // missing
        WORKORDER_PLANNED: 'Planned',
        WORKORDER_CREATED_TIME: 'Created {{time}}',
        ADD_ACTIVITY: 'Add activity',
        ADD_SECTION: 'Add section',
        ADD_TABLE: 'Add table', // missing
        ADD_OPTION: 'Add option', // missing
        WORK_ORDER_WAS_CLOSED: 'Darba uzdevums tika pabeigts {{time}}',
        BUSINESS_OBJECT_DETAILED_SEARCH_ENABLED: 'Detailed search', // missing
        BUSINESS_OBJECT_DETAILED_SEARCH_IS_ENABLED_MESSAGE:
            'Detailed search is active!', // missing
        WORK_ORDER_ARTICLE_REPORT_USER_DISTRIBUTION_SPLIT: 'SPLIT', // missing
        WORK_ORDER_USER_DISTRIBUTION_SPLIT_HEADER: 'TB - split', // missing
        WORKORDER_HISTORY_EXCEL_FILENAME: 'work_orders', // missing
        WORK_ORDER_USER_DISTRIBUTION_KPI_TITLE: 'Margin distribution', // missing
        WORK_ORDER_USER_DISTRIBUTION_ON_OPEN_WORKORDERS_KPI_TITLE:
            'Margin distribution on open work orders', // missing
        WORK_ORDER_USER_DISTRIBUTION_KPI_FOR_USER:
            '{{month}}: Articles for {{name}}, total margin: {{totalMargin}}', // missing
        REOPEN_PROPOSAL: 'Open proposal', // missing
        REOPEN_WORKORDER: 'Open work order', // missing
        OR: 'OR',
        ANLAGGARINTYG_PDF_EXPORT_ADD_PAGES: 'Append pages', // missing
        ANLAGGARINTYG_PDF_EXPORT_ADD_SIGNATURE: 'Add signature', // missing
        PROJECT_EXTRA_WORK_STATUS_PENDING: 'Pending', // missing
        PROJECT_EXTRA_WORK_STATUS_ACCEPTED: 'Accepted', // missing
        PROJECT_EXTRA_WORK_STATUS_DECLINED: 'Declined', // missing
        PROJECT_EXTRA_WORK_STATUS_EDITOR_HEADER: 'Accept or decline ÄTA?', // missing
        PROJECT_EXTRA_WORK_ACCEPT: 'Accept ÄTA', // missing
        PROJECT_EXTRA_WORK_DECLINE: 'Decline ÄTA', // missing
        PROJECT_WORK_ORDER_CREATE_INVOICE: 'Create direct invoice', // missing
        PROJECT_INVOICE_ROW_IS_IN_SCOPE: 'In scope', // missing
        PROJECT_INVOICE_ROW_IS_OUT_OF_SCOPE: 'Out of scope', // missing
        PROJECT_WORK_ORDERS_SHOW_LESS: 'Show less', // missing
        PROJECT_WORK_ORDERS_SHOW_MORE: 'Show {{count}} more work orders', // missing
        PROJECT_OUTCOME_REGULAR_SCOPE: 'In project scope', // missing
        PROJECT_OUTCOME_EXTRA_SCOPE: 'ÄTA / Extended scope', // missing
        PROJECT_OUTCOME_EXTRA_TOTAL_INVOICED: '{{value}} totally invoiced', // missing
        SERVICE_DISTRIBUTION_SCHEDULE_SERVICE_WORK_ORDERS_NOW:
            'Schedule work order now?', // missing
        SERVICE_DISTRIBUTION_SCHEDULE_SERVICE_WORK_ORDERS_NOW_plural:
            'Schedule {{count}} work orders now?', // missing
        SERVICE_DISTRIBUTION_SCHEDULE_SERVICE_WORK_ORDERS_CONFIRM: 'Confirm', // missing
        PROJECT_OUTCOME_SCOPE_ALL: 'Project summary including ÄTA', // missing
        WORK_ORDER_PER_TAG_KPI_TITLE: 'Number of work orders per tag', //MISSING
        START_DATE: 'Start date', //MISSING
        END_DATE: 'End date', //MISSING
        SUPPLIER_TAG_EDITED_BY: 'Set by: {{username}} ({{datetime}})', // missing
        SELECT_PERIOD: 'Select period', // missing
        UNTIL: 'Until', // missing
        DISPLAY: 'Display', // missing
        HIDE: 'Hide', // missing
        TIME_REPORTS_SCHEDULE_SINGLE_DAY: '<< Schedule single day', // missing
        TIME_REPORTS_SCHEDULE_MANY_DAYS: 'Schedule many days >>', // missing
        DOORAUTOMATICS: 'Doorautomatics', // missing
        MANAGE_DOORAUTOMATICS: 'Manage doorautomatic', // missing
        WORKORDER_ATTACHED_DOORAUTOMATICS: 'Attached doorautomatics', // missing
        WORKORDER_CREATE_REMINDER: 'Create reminder', // missing
        WORK_ORDER_REMINDER_MODAL_HEADER: 'Work order reminder', // missing
        WORKORDER_REMINDER_SCHEDULED_AT: 'Reminder scheduled at', // missing
        TOPBAR_SUPPLIER_INVOICES: 'Approve costs', // missing
        SUPPLIER_INVOICES: 'Supplier invoices', // missing
        SUPPLIER_INVOICE_STATUS_PENDING: 'To approve', // missing
        SUPPLIER_INVOICE_STATUS_APPROVED: 'Approved', // missing
        SUPPLIER_INVOICE_STATUS_DECLINED: 'Declined', // missing
        SUPPLIER_INVOICE_STATUS_ALL: 'All', // missing
        REOPEN_SERVICE_REPORT: 'Open form', // missing
        SUPPLIER_INVOICE_APPROVE_CONTENT: 'Approve', // missing
        SUPPLIER_INVOICE_APPROVE_PAYMENT: 'Approve', // missing
        SUPPLIER_INVOICE_DELETE: 'Delete', // missing
        WORKORDER_CLOSE_CHANGE_ERP_INVOICE_DATE: 'Adjust invoice date sent to ERP', // missing
        WORKORDER_CLOSE_CHANGE_ERP_DELIVERY_DATE: 'Adjust delivery date sent to ERP', // missing
        WORKORDER_CLOSE_INVOICE_WITH_REVERSE_VAT: 'Invoice will be created with reverse VAT', // missing
        WORKORDER_CLOSE_INVOICE_ADD_INVOICE_FEE: 'Add invoice fee', // missing
        ON_CALL: 'On-call', // missing
        CLEAR_ON_CALL_DAY: 'Clear', // missing
        CLEAR_ON_CALL_WEEK: 'Clear', // missing
        SELECT_ON_CALL_FOR_WEEK: 'Select for week', // missing
        ON_CALL_ON_N_DAYS: '{{count}} day', // missing
        ON_CALL_ON_N_DAYS_plural: '{{count}} days', // missing
        WORK_ORDER_CHANGE_CUSTOMER: 'Change customer', // missing
        WORK_ORDER_CHANGE_OBJECT: 'Change object', // missing
        WORK_ORDER_CHANGE_CUSTOMER_CURRENT: 'Current customer', // missing
        WORK_ORDER_CHANGE_CUSTOMER_SELECT_NEW: 'Select new customer for work order', // missing
        WORK_ORDER_CHANGE_CUSTOMER_NEW: 'Selected customer', // missing
        WORK_ORDER_CHANGE_CUSTOMER_SAVE: 'Apply change', // missing
        WORK_ORDER_CHANGE_OBJECT_CURRENT: 'Current object', // missing
        WORK_ORDER_CHANGE_OBJECT_SELECT_NEW: 'Select new object for work order', // missing
        WORK_ORDER_CHANGE_OBJECT_NEW: 'Selected object', // missing
        WORK_ORDER_CHANGE_OBJECT_SAVE: 'Apply change', // missing
        PROJECT_PLANNING: 'Planning', // missing
        PROJECT_ACTIVITY_TITLE: 'Activity description', // missing
        OPEN_PROJECTS_KPI_OTHER_COSTS_IN_PERCENTAGE: 'Project extra costs in %', // missing
        OPTION_DEFAULT: 'Default', // missing
        OPTION_OPTIONAL: 'Optional', // missing
        OPTION_TEXT_INPUT: 'Text Input', // missing
        OPTION_YES_NO: 'Yes / No', // missing
        OPTION_SELECTION: 'Selection', // missing
        PROJECT_EXTERNAL_URL: 'External URL', // missing
        PROJECT_EDIT_EXTERNAL_URL: 'Edit external URL', // missing
        CUSTOMER_CONTACT_PRIORITIZED: 'Protocol', // missing
        CUSTOMER_CONTACT_PRIORITIZED_TOOLTIP: 'Contact will receive protocol emails', // missing
        CUSTOMER_HAS_OPEN_PROPOSALS: '{{count}} open proposals',
        CUSTOMERPICKER_CREATE_NEW_CUSTOMER: 'Could not find the customer? Create here',
        MARKUP_PERCENTAGE: 'Markup (%)', // missing
        MARKUP_PERCENTAGE_SHORT: '{{value}}%', // missing
        TOTAL_MARKUP: 'Markup', // missing
        BUSINESS_OBJECT_CODES_ADD_CODE: 'Add code', // missing
        BUSINESS_OBJECT_CODES_ADD_CODE_GROUP: 'Add group', // missing
        BUSINESS_OBJECT_CODES_LOGS_HEADER: 'Access logs', // missing
        BUSINESS_OBJECT_CODES_VIEW_LOGS: 'View log', // missing
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_ADD_GROUP: '{{userName}} created group {{groupName}} on {{businessObjectId}}', // missing
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_EDIT_GROUP: '{{userName}} changed group name from {{fromGroupName}} to {{toGroupName}} on {{businessObjectId}}', // missing
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_DELETE_GROUP: '{{userName}} deleted group {{groupName}} on {{businessObjectId}}', // missing
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_ADD_CODE: '{{userName}} created secret code {{codeName}} for group {{groupName}} on {{businessObjectId}}', // missing
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_EDIT_CODE: '{{userName}} changed secret code name from {{fromCodeName}} to {{toCodeName}} for group {{groupName}} on {{businessObjectId}}', // missing
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_EDIT_CODE_VALUE: '{{userName}} edited secret code {{codeName}} for group {{groupName}} on {{businessObjectId}}', // missing
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_VIEW_CODE: '{{userName}} viewed secret code {{codeName}} for group {{groupName}} on {{businessObjectId}}', // missing
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_COPY_CODE: '{{userName}} copied secret code {{codeName}} for group {{groupName}} on {{businessObjectId}}', // missing
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_DELETE_CODE: '{{userName}} deleted code {{codeName}} for group {{groupName}} on {{businessObjectId}}', // missing
        AUTHENTICATE_AZURE_EMAIL: 'Authenticate E-Mail for Azure', // missing
        WORK_ORDER_HISTORY_FILTER_BY_ID_OVERRIDES_OTHER_FILTERS: 'Overrides all other filters', // missing
    },
    form: {
        ID: 'ID',
        WORK_ORDER_ID: 'ID',
        ARTICLE_ID: 'Artikula nr',
        NAME: 'Vārds',
        SHORT_NAME: 'Niks',
        EMAIL: 'E-pasts',
        CUSTOMER_EMAIL: 'E-pasts',
        PASSWORD: 'Parole',
        AVATAR: 'Niks',
        DEPARTMENT: 'Nodaļa',
        DEPARTMENT_ID: 'ID', // missing
        PHONE: 'Telefons',
        LANGUAGE: 'Valoda',
        INVOICE_LANGUAGE: 'Rēķina valoda',
        OUR_REFERENCE_ON_AGREEMENT_INVOICES: 'Mūsu atsauce uz līguma rēķiniem',
        NOTIFICATIONS: 'Notifikācijas',
        PUSH_NOTIFICATIONS: 'Push notifications',
        COMPANY_NAME: 'Uzņēmuma nosaukums',
        LOGO: 'Logo',
        INVOICE_DETAILS: 'Rēķina detaļas',
        ORGANIZATIONAL_NAME: 'Organizācijas nosaukums',
        ORGANIZATIONAL_NUMBER: 'Organizācijas numurs',
        VAT_REGISTRATION_NUMBER: 'PVN',
        VAT_PERCENTAGE: 'PVN procenti (%)',
        PLAN: 'Plāns',
        TEXT: 'Teksts',
        NOTES: 'Piezīmes',
        NOTE: 'Piezīme',
        OBJECT: 'Objekts',
        DATE_FORMAT: 'Datuma formāts',
        TIME_FORMAT: 'Laika formāts',
        CURRENCY: 'Valūta',
        SHOW_WEEKEND: 'Rādīt nedēļas nogales',
        AUTHENTICATION: 'Autentifikācija',
        CURRENT_PASSWORD: 'Esošā parole',
        NEW_PASSWORD: 'Jauna parole',
        CONFIRM_NEW_PASSWORD: 'Apstiprināt jauno paroli',
        UPDATE_PASSWORD: 'Atjaunot paroli',
        PASSWORD_REPEAT: 'Lūdzu atkārtojiet paroli',
        CUSTOMER: 'Klients',
        CUSTOMER_NUMBER: 'Klienta numurs',
        TAGS: 'Tags',
        TAG: 'Tag',
        TIME_REPORT_SELECT_TAG: 'Izvēlēties tagu',
        TIME_REPORT: 'Laika atskaite',
        MATERIAL_REPORT: 'Materiālu atskaite',
        INVOICING: 'Rēķinu izrakstīšana',
        SCHEDULING: 'Ieplānot darbu',
        PLANNED_START_TIME: 'Plānotais sākuma laiks',
        PLANNED_START_TIME_DATE: 'Plānotais sākuma laiks', // missing
        PLANNED_START_TIME_TIME: 'Plānotais sākuma laiks', // missing
        PLANNED_END_TIME: 'Plānotais beigu laiks',
        PLANNED_END_TIME_DATE: 'Plānotais beigu laiks', // missing
        PLANNED_END_TIME_TIME: 'Plānotais beigu laiks', // missing
        CONTACT: 'izsaukuma pieteicējs',
        CONTACTS: 'Kontakti',
        OBJECT_CONTACTS: 'Kontakti',
        ON_SITE_CONTACT: 'Kontakpersona',
        ON_SITE_CONTACT_NAME_PLACEHOLDER: 'Vārds',
        ON_SITE_CONTACT_EMAIL_PLACEHOLDER: 'E-pasts',
        ON_SITE_CONTACT_PHONE_PLACEHOLDER: 'Telefons',
        INTERNAL_RESPONSIBLE_PERSONS: 'Iekšējās atbildīgās personas',
        CUSTOMER_ORGANIZATIONAL_NUMBER: 'Organizācijas numurs',
        CUSTOMER_ADDITIONAL_INFO: 'Klienta papildus informācija',
        CUSTOMER_MARKINGS: 'Marķējums',
        PAYMENT_TERMS: 'Maksājuma nosacījumi',
        REVERSE_VAT: 'Apgrieztais PVN',
        PAYMENT_TERM_IN_DAYS: 'Apmaksas termiņš dienās',
        PAYMENT_DUE_DATE: 'Maksājuma termiņš',
        BG_ACCOUNT: 'BG konts',
        CONTACT_NAME_PLACEHOLDER: 'Vārds',
        CONTACT_EMAIL_PLACEHOLDER: 'E-pasts',
        CONTACT_PHONE_PLACEHOLDER: 'Telefons',
        CONTACT_ROLE_PLACEHOLDER: 'Loma',
        CONTACT_INVOICE_COST_CENTER_PLACEHOLDER: 'Izmaksu centrs',
        CITY: 'Pilsēta',
        OBJECT_COUNT: 'Objekti',
        OBJECT_LOCATION_PLACEHOLDER: 'pilsēta',
        OBJECT_LOCATION_2_PLACEHOLDER: 'iela/māja',
        OBJECT_LOCATION_3_PLACEHOLDER: 'pasta indekss',
        OBJECT_AGREEMENT_TYPE_PLACEHOLDER: 'Līguma veids',
        DATE: 'Datums',
        VALID_FROM: 'Spēkā no',
        VALID_TO: 'Spēkā līdz',
        NEXT_INVOICE_SCHEDULED_TO: 'Nākamais rēķins ir ieplānots',
        NEXT_INVOICE_PERIOD_FROM: 'Nākamā līguma rēķina perioda sākums',
        NEXT_INVOICE_PERIOD_TO: 'Period end for next agreement invoice', // missing
        AGREEMENT_INVOICE_FREQUENCY: 'Frequency (months)', // missing
        AGREEMENT_INVOICE_PRINTED_PERIOD:
            'Nākamajā līguma rēķinā uzrakstītais periods',
        NEXT_SERVICE_SCHEDULED_TO: 'Nākamā apkopes vizīte paredzēta',
        INVOICE_REGARDING_PERIOD: 'Rēķins par periodu',
        ARTICLES: 'Artikuli',
        PRIORITY: 'Prioritāte',
        PRIORITY_HIGH: 'Augsta',
        PRIORITY_MEDIUM: 'Vidēja',
        PRIORITY_LOW: 'Zema',
        ASSIGNEES: 'Norīkotie',
        ASSIGNEE: 'Norīkotais',
        LOCATION: 'Pilsēta',
        LOCATION_2: 'Iela',
        CUSTOMER_LOCATION_2: 'Iela',
        LOCATION_3: 'Pasta idekss',
        LOCATION_DESCRIPTION: 'Lokācijas detaļas',
        LOCATION_STATE: 'State', // missing
        LOCATION_STATE_PLACEHOLDER: 'State', // missing
        LOCATION_COUNTRY: 'Country', // missing
        LOCATION_COUNTRY_PLACEHOLDER: 'Country', // missing
        AGREEMENT_TYPE: 'Līguma veids',
        AGREEMENT_TYPES: 'Līguma veidi',
        DESCRIPTION: 'Apraksts',
        ARTICLE_DESCRIPTION: 'Apraksts',
        ACCESS_LEVEL_DESCRIPTION: 'Apraksts',
        ACCESS_LEVEL_DASHBOARD: 'Darba panelis',
        WORK_ORDER_DESCRIPTION: 'Izsaukuma teksts',
        WORK_ORDER_INTERNAL_INFO: 'Iekšējais info',
        SUMMARY: 'Paveiktā darba kopsavilkums',
        TITLE: 'Nosaukums',
        FIELDS: 'Lauki',
        MODEL: 'Modelis',
        CODE: 'Kods',
        SERIAL: 'Sērijas kods',
        STATUS: 'Statuss',
        COLOR: 'Krāsa',
        PLAN_GOLD: 'Zelts',
        INVOICE_CREATION: 'Rēķins izveidots',
        WORKORDER_CREATION: 'Darba uzdevums',
        AGREEMENT_WORKORDER_GENERATION_DAY:
            'Darba uzdevuma sagatavošanas diena',
        AGREEMENT_WORKORDER_GENERATION_INTERVAL:
            'Darba uzdevuma sagatavošanas intervāls',
        AGREEMENT_INVOICE_GENERATION_DAY: 'Rēķina sagatavošanas diena',
        AGREEMENT_INVOICE_GENERATION_INTERVAL: 'Rēķina sagatavošanas intervāls',
        AGREEMENT_WORKORDER_GENERATION_INCLUDE_ARTICLES:
            'Iekļaut artikulus līguma darbu uzdevumā',
        AGREEMENT_WORKORDER_GENERATION_INCLUDE_DESCRIPTION:
            'Iekļaut aprakstu līguma darbu uzdevumā',
        USER_COUNT: 'Lietotāji',
        ACCESS_LEVELS: 'Pieejas līmeņi',
        UNIT: 'Gabali',
        PRICE: 'Cena',
        PURCHASE_PRICE: 'Iepirkuma cena',
        REGULAR_PRICE: 'Parastā cena',
        SALES_PRICE: 'Pārdošanas cena',
        RECOMMENDED_SALES_PRICE: 'Ieteikā pārdošanas cena',
        TOTAL_PRICE: 'Kopā',
        USER: 'Lietotājs',
        PERMISSIONS: 'Atļaujas',
        LIMITED_TO: 'Ierobežots līdz ',
        WORK_TYPE: 'Veids',
        WORK_TYPES: 'Darba veids',
        STATUSES: 'Statusi',
        MULTIPLE_TECHNICIANS: 'Vairāki tehniķi',
        MULTIPLE_DATES: 'Vairāki datumi',
        EXTRA_FIELDS: 'Papildus lauki',
        PRIORITIES_ENABLED: 'Prioritātes',
        PREFIX: 'Prefix',
        MIN_LENGTH: 'Min. id garums',
        TOTAL_TIME: 'Laiks',
        TRAVEL_TIME: 'Attālums',
        WORK_TIME: 'Darbs',
        PRICE_PER_HOUR: 'Cena',
        PRICE_TOTAL: 'Kopējā cena',
        REPORTED_HOURS: 'Norādītās stundas',
        ARTICLE: 'Artikuls',
        AMOUNT: 'Daudzums',
        PRICE_PER_UNIT: 'Cena',
        WORK_ORDER_DETAILS: 'Vispārīgi',
        INVALID_EMAIL_OR_PASSWORD: 'Nederīgs e-pasts vai parole',
        SERVER_ERROR: 'Nevar izveidot savienojumu ar serveri.',
        IP_BLOCKED_TRY_AGAIN_LATER:
            'Mēs esam saņēmuši pārāk daudz pieprasījumu no jūsu adreses. Lūdzu, vēlāk mēģiniet vēlreiz vai sazinieties ar mūsu klientu atbalsta dienestu.',
        TOKEN_EXPIRED: 'Tokens beidzies',
        CANT_LOG_IN: 'Nesanāca ielogoties?',
        LOG_IN: 'IELOGOTIES',
        ALREADY_HAVE_USER_LOG_IN: 'Vai jums jau ir konts? Pierakstīties ar to',
        LOG_IN_INSTEAD: 'Tā vietā ielogoties',
        CONFIRM: 'Apstiprināt',
        WELCOME_TO_TRUKKS_TEXT:
            'Laipni lūgti Trukks! <br>Lūdzu pabeidziet profila reģistrāciju izveidojot paroli.',
        CLOSED_TIME: 'Aizvēršanas laiks',
        CREATE_DATE: 'Izveidot datumu',
        WORK_ORDER_NUMBER: 'Darba uzdevuma Nr.',
        INVOICE: 'Rēķins',
        LIMITED_TO_DESCRIPTION: 'Piekļūt darba pasūtījumiem, kas piešķirti',
        ARTICLE_TEXT_IS_EDITABLE: 'Artikula teksts ir maināms',
        ARTICLE_PRODUCT_TYPE: 'Produkta veids',
        ARTICLE_ENABLE_SUPPLIERS: 'Iespējot piegādātājus',
        ADDRESS: 'Adrese',
        WORK_ORDER_ADDRESS: 'Adrese',
        CUSTOMER_VAT_REGISTRATION_NUMBER: 'Klienta PVN numurs',
        DISCOUNT: 'Atlaide',
        DISCOUNT_TYPE: 'Atlaides veids',
        RESPONSIBLE_TECHNICIAN: 'Atbildīgais tehniķis',
        CUSTOMER_ORDER_NUMBER: 'Klienta pasūtījuma nr.',
        AUTO_DISPATCH_NEW_WO:
            'Automātiskā izsūtīšana visiem jaunajiem darba uzdevumiem',
        DEFAULT_REQUIRED_FIELDS: 'Obligāti aizpildāmie lauki',
        REQUIRED_FIELD: 'Obligāti aizpildāmais lauks',
        ON_SITE_CONTACT_NAME_REQUIRED: 'On site contact is required', // missing
        TAG_REQUIRED: 'Nepieciešams vismaz viens tags',
        CONTACT_REQUIRED: 'Nepieciešama kontaktpersona',
        EMAIL_REQUIRED: 'Nepieciešams kontakpersonas e-pasts',
        PHONE_REQUIRED: 'Nepieciešams kontakpersonas telefona nr.',
        DESCRIPTION_REQUIRED: 'Nepieciešams apraksts',
        INVOICE_MARKING_REQUIRED: 'Lūdzu, aizpildiet rēķina marķējumu',
        CUSTOMER_ORDER_NUMBER_REQUIRED:
            'Lūdzu, ievadiet klienta pasūtījuma numuru',
        LABEL_IS_REQUIRED: 'Marķējums ir nepieciešams',
        LABEL_DUPLICATE: 'Marķējumam jābūt unikālam',
        PERIOD_TIMES_HAVE_OVERLAPS: 'Periodi pārklājas',
        INTERNAL_MESSAGE: 'Iekšējais ziņojums par darba uzdevumu',
        TOTAL_INVOICE_PRICE_EXCLUDING_VAT: 'Kopējā cena',
        TOTAL_REPORTED_TIME: 'Kopējais ziņojuma laiks',
        INVOICE_MARKING: 'Rēķina marķējums',
        ORDERED_BY: 'Pasūtījis',
        ORDERED_DATE: 'Pasūtījuma datums',
        FILES: 'Faili',
        UPLOAD_FILES_PLACEHOLDER:
            'Ievelciet failus šeit vai noklikšķiniet, lai augšupielādētu',
        SUPPLIER_NUMBER: 'Piegādātāja numurs',
        SUPPLIER_NAME: 'Piegādātāja nosaukums',
        CHECKLIST: 'Kontrolsaraksts',
        CHECKLISTS: 'Kontrolsaraksti',
        CHECKLIST_TITLE: 'Virsraksts',
        CHECKLIST_ENABLED_ON_WORK_TYPES: 'Iespējots darba veidiem',
        CHECKLIST_ITEMS: 'Saraksta vienības',
        INVOICE_OPTIONS: 'Rēķina opcijas',
        ALTERNATIVE_INVOICE_RECEIVER: 'Rēķina saņēmējs',
        ALTERNATIVE_INVOICE_ADDRESS: 'Rēķina adrese',
        OBJECT_AGREEMENT_INVOICE_RECEIVER: 'Rēķina opcijas',
        PROJECT_MENU_GENERAL: 'Vispārīgā informācija',
        PROJECT_MENU_INVOICING: 'Rēķinu izrakstīšana',
        PROJECT_MENU_COSTS: 'Izmaksas',
        PROJECT_MENU_WORK_ORDERS: 'Darbu pasūtījumi',
        PROJECT_MENU_FILES: 'Dokumenti',
        PROJECT_MENU_STATISTICS: 'Statistika',
        PROJECT_MENU_SETTINGS: 'Settings', // missing
        PROJECT_MENU_PLANNING: 'Planning', // missing
        PROJECT: 'Projekts',
        PROJECT_ECONOMY: 'Projekta izmaksas',
        PROJECT_TIMELINE: 'Laika grafiks',
        PROJECT_TITLE: 'ID',
        PROJECT_NAME: 'Vārds',
        PROJECT_STATUS: 'Status',
        PROJECT_START_DATE: 'Sākuma datums',
        PROJECT_END_DATE: 'Beigu datums',
        PROJECT_OUTCOME: 'Outcome', // missing
        PROJECT_ESTIMATIONS: 'Aprēķini',
        PROJECT_ESTIMATED_HOURS: 'Aprēķinātās stundas',
        PROJECT_ESTIMATED_COST: 'Aprēķinātās izmaksas',
        PROJECT_INCOME: 'Ienākumi',
        PROJECT_INCOME_INVOICED: 'Līdz šim izrakstītie rēķini',
        PROJECT_INCOME_LEFT_TO_INVOICE: 'Vēl jāizraksta rēķini',
        PROJECT_OBJECTS_INVOLVED: 'Objekti',
        PROJECT_CUSTOMERS_INVOLVED: 'Iesaistītie klienti',
        PROJECT_SUPPLIERS_INVOLVED: 'Suppliers involved',
        PROJECT_WORK_ORDERS_INVOLVED: 'Darba uzdevumi',
        PROJECT_SALES_ORDERS_INVOLVED: 'Pārdošanas pasūtījumi',
        PROJECT_RESPONSIBLE_USER: 'Atbildīgā persona',
        PROJECT_CONTACT_NAME: 'Kontakts',
        PROJECT_DESCRIPTION: 'Apraksts',
        SALES_ORDER: 'Pasūtījums',
        SALES_ORDER_TITLE: 'Pasūtījuma numurs',
        PROPOSAL: 'Priekšlikums',
        PROPOSAL_TITLE: 'Priekšlikuma numurs',
        PROPOSAL_VALID_TO: 'Valid to', // missing
        PROPOSAL_CREATED_WORK_ORDER: 'Izveidots darba uzdevums',
        PROPOSAL_CREATED_SALES_ORDER: 'Created sales order', // missing
        PROPOSAL_STANDARD_TEXTS: 'Standarta teksts',
        PROPOSAL_STANDARD_TEXTS_EXCEPTIONS: 'Standard texts - Exceptions', // missing
        PROPOSAL_STANDARD_TEXTS_INCLUSIONS: 'Standard texts - Inclusions', // missing
        PROPOSAL_STANDARD_TEXTS_PAYMENT_TERMS: 'Standard texts - Payment terms', // missing
        PROPOSAL_STANDARD_TEXTS_GENERAL_TERMS: 'Standard texts - General terms', // missing
        PROPOSAL_TEMPLATES: 'PDF veidnes',
        PROPOSAL_FOLLOW_UP_DATE: 'Sekot',
        PROPOSAL_CLOSE_DATE: 'Datums',
        PROPOSAL_INTRO: 'Virsraksts',
        PROPOSAL_INTRO_TEXT: 'Introduction text', // missing
        PROPOSAL_DELIVERY_ADDRESS: 'Delivery address', // missing
        PROPOSAL_DESCRIPTION_OF_EXCEPTIONS: 'Izņēmumi',
        PROPOSAL_GENERAL_TERMS: 'Galvenie noteikumi',
        PROPOSAL_PAYMENT_TERMS: 'Maksājuma nosacījumi',
        DELIVERY_ADDRESS: 'Delivery address', // missing
        ARTICLE_E_NUMBER: 'E-numurs',
        OBJECT_AGREEMENT_INVOICE_MARKING: 'Rēķina marķējums',
        OBJECT_AGREEMENT_INVOICE_REFERENCE: 'Invoice reference', // missing
        SALES_ORDER_CREATED_FROM_PROPOSAL: 'Izveidots no priekšlikuma',
        CUSTOMER_DEFAULT_DISCOUNT_PERCENTAGE:
            'Atlaides procenti visiem artikuliem',
        ERP_SETTINGS: 'ERP',
        MAIL_SETTINGS: 'E-pasta iestatījumi',
        COMMENT: 'Iekšējā ziņa par paveikto',
        PAYMENT_ACCOUNT: 'Maksājumu konts',
        ERP_ARTICLE_ACCOUNT: 'Konts',
        ERP_ARTICLE_ACCOUNT_GOODS: 'Preces',
        ERP_ARTICLE_ACCOUNT_SERVICE: 'Serviss',
        ARTICLE_SUPPLIER: 'Piegādātājs',
        ARTICLE_SUPPLIER_ARTICLE_NR: 'Piegādātāja artikula nr.',
        REFERENCE_CONTACT: 'References kontaktpersona',
        SERVICE_REPORTS: 'Servisa atskaites',
        SERVICE_REPORT: 'Servisa atskaite',
        SERVICE_REPORT_TITLE: 'Virsraksts',
        SERVICE_REPORT_TEMPLATE_TYPE: 'Veidnes ID',
        SERVICE_REPORT_ENABLED_ON_WORK_TYPES: 'Iespējots darba veidiem',
        SERVICE_REPORT_EMAIL_TO: 'Nosūtīt ziņojumu uz e-pastu',
        SERVICE_REPORT_EMAIL_TO_SELF: 'Lietotājs',
        ID06_CODE: 'ID06 Kods',
        EXPORT_ID06: 'Eksportēt ID06',
        PERSONAL_IDENTITY_NUMBER: 'Personas kods',
        TIME_REPORT_TAG_TYPE_ATTEND: 'Apmeklējumi',
        TIME_REPORT_TAG_TYPE_ABSENCE: 'Prombūtne',
        TYPE: 'Tips',
        LABEL: 'Marķējums',
        INVOICE_RULES: 'Rēķinu noteikumi',
        INVOICE_RULE_AUTO_TIME_INVOICING:
            'Automatizēta laika rēķina izrakstīšana',
        INVOICE_RULE_AUTO_TRAVEL_TIME_INVOICING:
            'Automātiska brauciena laika rēķina izrakstīšana',
        PROJECT_INVOICE_MATERIAL_AND_INVOICING: 'Rēķina marķēšana un materiāli',
        OBJECT_SERIAL: 'ID',
        OBJECT_MODEL: 'Modelis',
        AGREEMENT_NAME: 'Līgums',
        AGREEMENT_SERVICE_MONTH: 'Plānotais',
        WEBSITE: 'Mājaslapa',
        POST_GIRO_ACCOUNT: 'PostGiro',
        ARTICLE_GROUP_ID: 'Grupas ID',
        ARTICLE_GROUP_NAME: 'Grupas nosaukums',
        ARTICLE_GROUP: 'Artikulu grupa',
        PRICE_LIST_ID: 'Cenrāža ID',
        CUSTOMER_PRICE_LIST: 'Aktīvs cenrādis',
        OBJECT_PRICE_LIST: 'Aktīvs cenrādis', // missing
        PRICE_LIST_CONFIGURATION: 'Konfigurācija',
        CONTINUE: 'Turpināt',
        MFA_LOGIN_TITLE: 'Divfaktoru autentifikācija',
        MFA_LOGIN_DESCRIPTION:
            'Ievadiet 6 ciparu kodu no savas {{serviceProvider}} lietotnes',
        MFA_LOGIN_CODE_PLACEHOLDER: 'Kods',
        MFA_CHALLENGE_FAILED: 'Nepareizs kods',
        MFA_AUTHENTICATION_FAILED: 'Divfaktoru autentifikācija neizdevās',
        ACTIVATE: 'Aktivizēt',
        DEACTIVATE: 'Deaktivizēt',
        SALES_ORDER_STATUS: 'Statuss',
        SALES_ORDER_STATUS_OPEN: 'Aktīvs',
        SALES_ORDER_STATUS_CLOSED: 'Izrakstīts rēķins',
        CUSTOMER_ZONE: 'Klientu Zona',
        CUSTOMER_ZONE_AUTH_CODES: 'Pieejas kodi',
        TIME_REPORT_TAG_DEFAULT_TIME: 'Noklusējuma laiks',
        MODEL_SPECIFICATIONS: 'Specifikācijas',
        CUSTOMER_CREATE_INVOICES_IN_ERP: 'Nosūtīt rēķinu ERP',
        CUSTOMER_PERSON_NAME: 'Persona',
        DISTANCE_REPORTS_LAST_READING: 'Pēdējais lasījums',
        DISTANCE_REPORTS_ACTUAL_READING: 'Faktiskais lasījums',
        DISTANCE_REPORTS_ACTUAL_KM: 'Faktiskie KM',
        SETTINGS_SALES_ORDER_FIXED_VAT:
            'Pārdošanas pasūtījumu rēķiniem ir atslēgts reversais PVN',
        SETTINGS_NOTIFICATIONS_WORK_ORDER_SCHEDULED_EVENT:
            'Nosūtīt e-pastu, kad ieplānots darba uzdevums',
        SETTINGS_NOTIFICATIONS_WORK_ORDER_CREATED_EVENT:
            'Nosūtīt e-pastu, kad ir izveidots darba uzdevums',
        SETTINGS_NOTIFICATIONS_EVENT_ENABLED_FOR_TYPES:
            'Enabled for work types', // missing
        DEVIATION_CATEGORIES: 'Kategorijas',
        DEVIATION_OCCURED_DATE: 'Notika',
        DEVIATION_CATEGORY: 'Kategorija',
        DEVIATION_RELATIONS: 'Saistīts ar',
        DEVIATION_ID: 'ID',
        DEVIATION_SHORT_TERM_SOLUTION: 'Īstermiņa risinājums',
        DEVIATION_LONG_TERM_SOLUTION: 'Ilgtermiņa risinājms',
        DEVIATION_IS_CHANGE_NEEDED: 'Mainīt',
        DEVIATION_IS_CHANGE_NEEDED_YES: 'Nepieciešams!',
        DEVIATION_IS_CHANGE_NEEDED_NO: 'Nav nepieciešams!',
        DEVIATION_CHANGE_DUE_DATE: 'Beigu termiņš',
        DEVIATION_CHANGE_LAST_EDITED_BY: 'Pēdējo reizi rediģēja',
        DEVIATION_CHANGE_APPROVED_AT: 'Apstiprināts',
        DEVIATION_CHANGE_APPROVED_BY: 'Apstiprināja',
        WORK_ORDER_CALCULATED_COST_ON_TIME:
            'Aprēķinātās iekšējās izmaksas stundā',
        WORK_ORDER_CALCULATED_COST_ON_DISTANCE:
            'Calculated internal cost per km', // missing
        AGREEMENT_DISCOUNT_PERCENTAGE_ON_PRODUCTS: 'Atlaide precēm',
        AGREEMENT_DISCOUNT_PERCENTAGE_ON_SERVICES: 'Atlaides servisam',
        CUSTOMER_PRICE_DISCOUNT_ON_ARTICLE_GROUPS: 'Atlaide artikulu grupām',
        BUSINESS_OBJECT_DEFAULT_AGREEMENT: 'Default selected agreement',
        PROJECT_SETTINGS_DEFAULT_INVOICE_MARKING: 'Default invoice marking',
        WORK_ORDER_DESCRIPTION_STANDARD_TEXTS: 'Standard texts for description', // missing
        WORK_ORDER_SUMMARY_STANDARD_TEXTS: 'Standard texts for summary', // missing
        PROJECT_RESOURCE_PLANNING_TASKS: 'Tasks', // missing
        PROJECT_RESOURCE_PLANNING_TASK: 'Task', // missing
        MICROSOFT_AUTH_LOGIN: 'Sign in with Microsoft', // missing
        LINK: 'Link', //MISSING
        ADD_LINK: 'Add link', //MISSING
        ADD_LINK_ACTION: 'Add link', //MISSING
        EDIT_LINK: 'Edit link', // missing
        CUSTOMER_BROADCAST_EMAIL: 'Auto email address for new work orders', // missing
        HOURS_OF_WORKING: 'Hours of working', //missing
        SUPPLIER_TAGS: 'Statuses', // missing
        SUPPLIER_TAG: 'Status', // missing
        IBAN: 'IBAN',
        SALES_ORDER_REFERENCE_USER: 'Our reference', // missing
        DOORAUTOMATIC_TITLE: 'ID', // missing
        DOORAUTOMATIC_SERIAL_CODE: 'Serial code', // missing
        DOORAUTOMATIC_BRAND: 'Brand', // missing
        DOORAUTOMATIC_MODEL: 'Model', // missing
        DOORAUTOMATIC_LOCATION: 'Location', // missing
        DOORAUTOMATIC_WARRANTY_UNTIL: 'Warranty until', // missing
        DOORAUTOMATIC_SENSOR: 'Sensor', // missing
        DOORAUTOMATIC_NOTES: 'Notes', // missing
        DOORAUTOMATIC_IMAGE: 'Image', // missing
        WORK_ORDER_REMINDER_MESSAGE: 'Message', // missing
        WORK_ORDER_REMINDER_DATE: 'Date', // missing
        WORK_ORDER_REMINDER_TIME: 'Time', // missing
        WORK_ORDER_REMINDER_USERS: 'Users', // missing
        SUPPLIER_INVOICE_CREATE_TIME: 'Arrived', // missing
        SUPPLIER_INVOICE_SUPPLIER_NAME: 'Supplier', // missing
        SUPPLIER_INVOICE_PROJECT: 'Project', // missing
        SUPPLIER_INVOICE_ORDER_NUMBER: 'Order number', // missing
        SUPPLIER_INVOICE_TOTAL_COST: 'Total cost', // missing
        SUPPLIER_INVOICE_EXPIRE_TIME: 'Expire', // missing
        SUPPLIER_INVOICE_RESPONSIBLE_USER: 'User', // missing
        SUPPLIER_INVOICE_COMMENTS: 'Comments', // missing
        SUPPLIER_INVOICE_APPROVE: 'Approve', // missing
        SUPPLIER_INVOICE_IMAGE: 'Image', // missing
        SUPPLIER_INVOICE_CONTENT_STATUS: 'Approve content', // missing
        SUPPLIER_INVOICE_PAYMENT_STATUS: 'Approve payment', // missing
        SUPPLIER_INVOICE_INVOICE_NUMBER: 'Supplier invoice number', // missing
        SUPPLIER_INVOICE_EXTERNAL_REFERENCE: 'Supplier ref.', // missing
        SUPPLIER_INVOICE_INTERNAL_REFERENCE: 'Our ref.', // missing
        SUPPLIER_INVOICE_ARTICLES: 'Articles', // missing
        SUPPLIER_INVOICE_ARTICLE_NAME: 'Article', // missing
        SUPPLIER_INVOICE_ARTICLE_AMOUNT: 'Amount', // missing
        SUPPLIER_INVOICE_ARTICLE_PURCHASE_PRICE: 'Purchase price', // missing
        SUPPLIER_INVOICE_ARTICLE_ROW_PRICE: 'Row price', // missing
        SUPPLIER_INVOICE_ARTICLE_VAT: 'VAT', // missing
        SUPPLIER_INVOICE_ARTICLES_TOTAL_PRICE: 'Total price', // missing
        SUPPLIER_INVOICE_ARTICLES_TOTAL_VAT: 'Total VAT', // missing
        DELIVERY_DATE: 'Delivery date', // missing
        DELIVERY_PARTY: 'Delivery party', // missing
        SETTINGS_WORK_ORDER_DISPATCH_RULES: 'Dispatch rules', // missing
        WORK_ORDER_DISPATCH_RULE_DAY_OF_WEEK: 'Day', // missing
        WORK_ORDER_DISPATCH_RULE_START_TIME: 'Start time', // missing
        WORK_ORDER_DISPATCH_RULE_END_TIME: 'End time', // missing
        WORK_ORDER_DISPATCH_RULE_DISPATCH_TO: 'Dispatch to', // missing
        WORK_ORDER_DISPATCH_AUTO_TO_CREATE_USER: 'Auto-dispatch to creating user', // missing
        UPLOAD_SUPPLIER_INVENTORY_DISCOUNT_FILE_PLACEHOLDER: 'Upload discount file', // missing
        USER_FORTNOX_EMPLOYEE_ID: 'FortNox employee ID', // missing
        DOORAUTOMATIC_COUNT: 'Doorautomatics', // missing
        ARTICLE_IS_SECURITAS_DIRECT_ARTICLE: 'Securitas Direct article', // missing
        ARTICLE_SECURITAS_MONTHLY_COMPENSATION: 'Monthly compensation', // missing
        ARTICLE_SECURITAS_INSTALLATION_COMPENSATION: 'Installation compensation', // missing
        ARTICLE_SECURITAS_SALES_COMPENSATION: 'Sales compensation', // missing
        ARTICLE_IS_SECURITY_ARTICLE: 'Security article', // missing
        BUSINESS_OBJECT_DISPLAY_NAME: 'Name', // missing
        REGIONS: 'Regions', // missing
        PROJECT_REGION: 'Region', // missing
        PROJECT_SALES_USER: 'Sales person', // missing
        COST_CENTER: 'CC', // missing
        STOCK_POINT: 'SP', // missing
        STOCK_LOCATION: 'Stock location', // missing
        USER_DISTRIBUTION: 'Person', // missing
        PROPOSAL_MONTHLY_PRICE: 'Monthly price', // missing
        BUSINESS_OBJECT_CODES: 'Access codes' // missing
    },
    security: {
        CAPACITY: 'Capacity',
        CURRENT_AT_REST: 'Current at rest',
        CURRENT_AT_ALARM: 'Current at alarm',
        SPECIFICATION: 'Specifikācija',
        TYPE: 'Tips',
        SPSC: 'Certifierad',
        SPSC_VALID_TO: 'Certifierad Until',
        SBSC_ID: 'SBSC ID',
        MODEL_SYSTEM_TYPES: 'System types',
        MODEL_SYSTEM_TYPE_BURGLAR: 'Burglary',
        MODEL_SYSTEM_TYPE_PASSAGE: 'Passage',
        MODEL_SYSTEM_TYPE_CAMERA: 'Camera/CCTV',
        MODEL_SYSTEM_TYPE_FIREALARM: 'Fire alarm',
        MODEL_SYSTEM_TYPE_ASSAULT: 'Assault',
        OBJECT_PASSAGE_TYPE_OF_SYSTEM: 'Type of system',
        OBJECT_PASSAGE_NUMBER_OF_SUBCENTRALS: 'Number of sub-centrals',
        OBJECT_PASSAGE_DOOR_LEAF_READER: 'Door leaf reader',
        OBJECT_PASSAGE_CARD_READER: 'Card reader',
        OBJECT_PASSAGE_ACCESS_POINT: 'Access point',
        OBJECT_PASSAGE_POWER_SUPPLY: 'Power supply',
        OBJECT_PASSAGE_TYPE_OF_READER: 'Type of reader',
        OBJECT_PASSAGE_COMMENTS: 'Comments',
        OBJECT_CAMERA_NUMBER_OF_CAMERAS: 'Number of cameras',
        OBJECT_CAMERA_NUMBER_OF_PTZ: 'Number of PTZ',
        OBJECT_CAMERA_NUMBER_OF_HEAT_CAMERAS: 'Number of heat cameras',
        OBJECT_CAMERA_TYPE_OF_SOFTWARE: 'Type of software',
        OBJECT_CAMERA_NUMBER_OF_CHANNELS_IN_NVR: 'Number of channels in NVR',
        OBJECT_CAMERA_TYPE_OF_READER: 'Type of reader',
        OBJECT_CAMERA_COMMENTS: 'Comments',
        OBJECT_FIREALARM_FIRE_ALARM_CENTRAL: 'Fire alarm central',
        OBJECT_FIREALARM_TYPE_OF_BATTERY: 'Type of battery',
        OBJECT_FIREALARM_NUMBER_OF_SMOKE_DETECTORS: 'Number of smoke detectors',
        OBJECT_FIREALARM_NUMBER_OF_HEAT_DETECTORS: 'Number of heat detectors',
        OBJECT_FIREALARM_NUMBER_OF_ALARM_BUTTONS: 'Number of alarm buttoms',
        OBJECT_FIREALARM_NUMBER_OF_ALARM_DEVICES: 'Number of alarm devices',
        OBJECT_FIREALARM_COMMENTS: 'Comments',
        OBJECT_ASSAULT_FABRIC: 'Fabric',
        OBJECT_ASSAULT_NUMBER_OF_SUBCENTRALS: 'Number of sub-centrals',
        OBJECT_ASSAULT_NUMBER_OF_DISPLAYS: 'Number of map/text displays',
        OBJECT_ASSAULT_NUMBER_OF_RECEIVERS: 'Number of receivers',
        OBJECT_ASSAULT_COLOR_OF_FIXED_BUTTONS: 'Color of fixed buttons',
        OBJECT_ASSAULT_NUMBER_OF_FIXED_BUTTONS: 'Number of fixed buttons',
        OBJECT_ASSAULT_COLOR_OF_WIRELESS_BUTTONS: 'Color of wireless buttons',
        OBJECT_ASSAULT_NUMBER_OF_WIRELESS_BUTTONS: 'Number of wireless buttons',
        OBJECT_ASSAULT_COLOR_OF_FLASHLIGHTS: 'Color of flashlights',
        OBJECT_ASSAULT_NUMBER_OF_FLASHLIGHTS: 'Number of flashlights',
        OBJECT_ASSAULT_COMMENTS: 'Comments',
    },
    copy: {
        ARTICLE_SUFFICIENT_FOR: 'Sufficent for',
        ARTICLE_SUFFICIENT_FOR_PRINTOUTS: '{{count}} print-outs',
        AGREEMENT_CLICK_REGISTRATION: 'Click registration',
    },
    calendar: {
        TODAY: 'Šodien',
        TOMORROW: 'Rīt',
        YESTERDAY: 'Vakar',
        THIS_WEEK: 'Šonedēļ',
        THIS_MONTH: 'Šomēnes',
        THIS_YEAR: 'Šogad',
        LAST_MONTH: 'Pagājušajā mēnesī',
        MONDAY: 'Pirmdien',
        TUESDAY: 'Otrdiena',
        WEDNESDAY: 'Trešdiena',
        THURSDAY: 'Ceturdiena',
        FRIDAY: 'Piektdiena',
        SATURDAY: 'Sestdiena',
        SUNDAY: 'Svētdiena',
        JANUARY: 'Janvāris',
        FEBRUARY: 'Februāris',
        MARCH: 'Marts',
        APRIL: 'Aprīlis',
        MAY: 'Maijs',
        JUNE: 'Jūnijs',
        JULY: 'Jūljs',
        AUGUST: 'Augusts',
        SEPTEMBER: 'Septembris',
        OCTOBER: 'Oktobris',
        NOVEMBER: 'Novembris',
        DECEMBER: 'Decembris',
        JANUARY_SHORT: 'Janv.',
        FEBRUARY_SHORT: 'Febr.',
        MARCH_SHORT: 'Marts',
        APRIL_SHORT: 'Apr.',
        MAY_SHORT: 'Maijs',
        JUNE_SHORT: 'Jūn.',
        JULY_SHORT: 'Jūl.',
        AUGUST_SHORT: 'Aug.',
        SEPTEMBER_SHORT: 'Sept.',
        OCTOBER_SHORT: 'Okt.',
        NOVEMBER_SHORT: 'Nov.',
        DECEMBER_SHORT: 'Dec.',
        MONTH: 'Mēnesis',
        YEAR: 'Gads',
        WEEK: 'Nedēļa',
        DAY: 'Diena',
        QUARTER: 'Quarter',
        HALF_YEAR: '6mo',
        LAST_DAY_OF_MONTH: 'Pēdējā mēneša diena',
        ONE_MONTH: 'mēnesis',
        TWO_MONTHS: 'divi mēneši',
        THREE_MONTHS: 'trīs mēneši',
        FOUR_MONTHS: 'četri mēneši',
        FIVE_MONTHS: 'pieci mēneši',
        SIX_MONTHS: 'seši mēneši',
        EIGHTEEN_MONTHS: '18 mēneši',
        ONE_YEAR: 'gads',
        TWO_YEARS: 'divi gadi',
        THREE_YEARS: 'trīs gadi',
        FOUR_YEARS: 'četri gadi',
        TIME_TO: 'Līdz',
        TIME_FROM: 'No',
        PERSPECTIVE_SCHEDULE: 'Tehniķis',
        PERSPECTIVE_PROJECT: 'Projekts',
        DAYS: 'dienas',
    },
    datatable: {
        NO_ROWS: 'Nav datu',
        RELOAD: 'Pārlādēt',
        SEARCH: 'Meklēt',
        ROWS: '{{count}} rinda',
        ROWS_plural: '{{count}} rindas',
        ZERO_ROWS_FILTERED:
            '{{count}} rindas (filtrētas no {{filteredFromCount}})',
        SHOWING_ROWS:
            'Rāda {{startCount}} līdz {{endCount}} no {{count}} rindām',
        SHOWING_ROWS_FILTERED:
            'Rāda {{startCount}} līdz {{endCount}} no {{count}} rindām (filtrēts no {{fromCount}} rindām)',
    },
    messages: {
        SUCCESSFULLY_SAVED: 'Saglabāts',
        SUCCESSFULLY_DELETED: 'Izdzēsts',
        SUCCESSFULLY_CLOSED_WORKORDER: 'Darba uzdevums veiksmīgi pabeigts',
        SUCCESSFULLY_CLOSED_PROJECT: 'Projekts veiksmīgi aizvērts',
        SUCCESSFULLY_REOPENED_PROJECT: 'Projekts veiksmīgi atsākts',
        FAILED_TO_SAVE: 'Neizdevās saglabāt',
        FAILED_TO_DELETE: 'Neizdevās izdzēst',
        FAILED_TO_FETCH: 'Neizdevās iegūt informāciju',
        FAILED_TO_UPLOAD_FILE: 'Neizdevās augšupielādēt failu',
        FAILED_TO_DOWNLOAD_FILE: 'Radās kļūda lejupielādējot failu',
        FILE_NOT_FOUND: 'No file attached', // missing
        FAILED_TO_DOWNLOAD_FILE_ID06_CODE_NOT_FOUND: 'ID06 kods netika atrasts',
        CUSTOMER_FAILED_TO_GET_INFO: 'Neizdevās iegūt klienta datus',
        FAILED_TO_GET_HISTORY: 'Neizdevās iegūt vēsturi',
        FAILED_TO_INVITE: 'Neizdevās uzaicināt',
        INVITE_SENT: 'Uzaicinājums nosūtīts',
        WRONG_CURRENT_PASSWORD: 'Nederīga pašreizējā parole',
        PASSWORD_DONT_MATCH: 'Paroles nesakrīt',
        PASSWORD_MIN_CHARACTERS: 'Parolei jābūt vismaz 8 rakstzīmēm garai.',
        ERROR_DEPARTMENT_HAS_USERS: 'Nevar izdzēst. Nodaļai ir lietotāji.',
        USER_REGISTERED:
            'Parole izveidota. <a href="/">Noklikšķiniet šeit</a>, lai ielogotos.',
        INVALID_SESSION: 'Sesija beigusies. Lūdzu ielogoties vēlreiz. ',
        ERROR_MESSAGE_TITLE: 'Notika kļūda!',
        INFO_MESSAGE_TITLE: 'Info!',
        SUCCESS_MESSAGE_TITLE: 'Darbība veiksmīga!',
        WARNING_MESSAGE_TITLE: 'Brīdinājums!',
        WARNING_PERMISSION_DENIED:
            'Jums nav atļaujas veikt šo darbību. Jautājiet savam administratoram.',
        BUSINESS_OBJECT_EXISTS: 'Objekts ar tādu pašu sērijas kodu jau pastāv!',
        CUSTOMER_EXISTS: 'Klients ar tādu pašu ID jau pastāv!',
        ARTICLE_EXISTS: 'Artikuls ar tādu pašu ID jau pastāv!',
        ARTICLE_E_NUMBER_EXISTS: 'Artikuls ar tādu pašu E-Numuru jau pastāv!',
        ARTICLE_GROUP_EXISTS:
            'Artikulu grupa ar tādu pašu ID numuru jau pastāv!',
        PRICE_LIST_EXISTS: 'Cenrādis ar tādu pašu ID jau pastāv!',
        AGREEMENT_EXISTS: 'Tāda paša tipa līgums jau pastāv!',
        MODEL_EXISTS: 'Modelis ar tādu pašu kodu jau pastāv!',
        SUPPLIER_EXISTS: 'Piegādātājs ar tādu pašu numuru jau pastāv!',
        DOORAUTOMATIC_EXISTS: 'Doorautomatic with same ID already exists!', // missing
        CONFIRM_UNSAVED_CHANGES:
            'Jums ir nesaglabātas izmaiņas. Vai tiešām vēlaties aizvērt cilni?',
        CONFIRM_CLOSE_WORKORDER:
            'Darba uzdevums tiks pabeigts! Lūdzu apstipriniet.',
        CONFIRM_DELETE_WORKORDER:
            'Darba uzdevums tiks dzēsts! Lūdzu apstipriniet.',
        MIN_TAG_LENGTH_2: 'Minimālais taga garums ir 2.',
        MIN_WORKTYPE_LENGTH_2: 'Minimālais darba veida garums ir 2 rakstzīmes.',
        MAX_WORKTYPE_LENGTH_50:
            'Maksimālais darba veida garums ir 2 rakstzīmes.',
        MIN_STATUS_LENGTH_2: 'Minimālais statusa garums ir 2.',
        TAG_ALREADY_EXISTS: 'Tags jau pastāv.',
        WORKTYPE_ALREADY_EXISTS: 'Darba veids jau pastāv.',
        STATUS_ALREADY_EXISTS: 'Status jau pastāv.',
        TAG_CONTAINS_SPECIAL_CHARACTERS:
            'Lūdzu, izmantojiet tikai rakstzīmes un ciparus.',
        STATUS_DEFAULT_ERROR: 'Ir nepieciešams viens noklusējuma statuss.',
        STATUS_NO_CLOSING_ERROR:
            'Ir nepieciešams vismaz viens aizvēršanas statuss.',
        STATUS_CLOSING_DEFAULT_SAME:
            'Aizvēršanas un noklusējuma statuss nevar būt vienāds.',
        INVALID_PRICE: 'Nederīga cena. Lūdzu, izmantojiet tikai ciparus.',
        PLEASE_SELECT_ARTICLE: 'Lūdzu, izvēlēties artikulu',
        CONFIRM_CUSTOMER_ARTICLE_PRICE_DELETE:
            'Vai tiešām vēlaties dzēst klienta cenu?',
        CHANGE_PASSWORD_TEXT: 'Ierakstiet jaunu paroli',
        PASSWORD_SUCCESSFULLY_CHANGED:
            'Parole veiksmīgi nomainīta. <a href="/">Noklikšķiniet šeit</a>, lai pierakstītos.',
        REQUEST_PASSWORD_CHANGE_TEXT:
            'Ierakstiet savu e-pastu. Mēs nosūtīsim instrukcijas paroles maiņai.',
        PASSWORD_CHANGE_SUCCESSFULLY_REQUESTED:
            'Ziņa ar norādījumiem nosūtīta uz jūsu e-pastu.',
        UNABLE_TO_DELETE:
            'Nevar izdzēst. Mēs iesakām tā vietā atjaunināt statusu uz "Neaktīvs" vai sazināties ar mūsu klientu atbalsta dienestu.',
        ERROR_REQUIRED_FIELD: 'Lūdzu aizpildiet šo lauku',
        ERROR_SPACE_NOT_ALLOWED: 'Atstarpe nav atļauta',
        ERROR_MAX_LENGTH: 'Pārsniegts maksimālais lauka garums ({{count}})',
        EMAIL_SENT: 'E-pasts nosūtīts',
        EMAIL_FAILED: 'Neizdevās nosūtīt e-pastu',
        ERROR_LOADING_INVOICE: 'Neizdevās ielādēt rēķinu',
        ARE_YOU_SURE: 'Vai tu esi pārliecināts?',
        EMAIL_SENT_TO: 'E-pasts nosūtīts uz: {{email}}',
        EMAIL_IS_INVALID: 'Invalid email', // missing
        ERROR_HANDLING_APPLY: 'Neizdevās apstrādāt pieprasījumu',
        ERROR_DISCOUNT_PATTERN: 'Atlaidei ir jābūt skaitlim vai procentam',
        TAG_IS_REQUIRED: 'Lūdzu, izvēlieties tagu',
        TIME_REPORT_MAXIMUM_TIME_SPAN_60_DAYS: 'Maximum time span is 60 days', // missing
        SEARCH_FROM_SIDEBAR: 'Lūdzu, izmantojiet sānjoslu, lai meklētu',
        AMOUNT_CANT_BE_NEGATIVE: 'Summa nevar būt negatīva!',
        STOCK_SUCCESSFULLY_UPDATED:
            'Noliktava veiksmīgi atjaunināta. Jaunā noliktavas summa: {{amount}}.',
        WARNING_NETWORK_ERROR_PLEASE_REFRESH:
            'Mēs nevaram sasniegt savus serverus. Lūdzu, mēģiniet atjaunot lapu.',
        USER_CREATED: 'Lietotājs ir veiksmīgi izveidots',
        FAILED_TO_CREATE_USER: 'Neizdevās izveidot lietotāju',
        PROPOSAL_PLEASE_SELECT_CUSTOMER_BEFORE_ACCEPTING:
            'Lūdzu izvēlieties klientu pirms apstiprinājuma',
        PROPOSAL_SUCCESSFULLY_ACCEPTED:
            'Piedāvājums ir veiksmīgi pieņemts un pārdošanas pasūtījums izveidots',
        PROPOSAL_SUCCESSFULLY_ACCEPTED_WORKORDER_CREATED:
            'Piedāvājums ir veiksmīgi pieņemts un pārdošanas pasūtījums izveidots {{workOrderNr}}',
        PROPOSAL_SUCCESSFULLY_DELETED: 'Piedāvājums veiksmīgi izdzēsts!',
        CONFIRM_SALES_ORDER_INVOICE:
            'Lūdzu apstipriniet. Pārdošanas pasūtījuma rēķins tiks ģenerēts un nosūtīts uz ERP sistēmu.',
        SALES_ORDER_INVOICE_SUCCESSFULLY_CREATED: 'Rēķins veiksmīgi izveidots',
        SALES_ORDER_IS_INVOICED: 'Izrakstīts rēķins par pārdošanas pasūtījumu',
        OBJECT_IS_INACTIVATED_NO_AGREEMENT_INVOICES:
            'Līguma rēķini netiek ģenerēti!',
        PERCENTAGE_CANNOT_BE_OVER_100: 'Procenti nevar būt lielāki par 100%',
        PERCENTAGE_CANNOT_BE_NEGATIVE: 'Procenti nedrīkst būt mazāki par 0',
        AGREEMENT_CONFIGURATION_WARNING_DOUBLE_INVOICING:
            'Lūdzu, vēlreiz pārbaudiet savu konfigurāciju. Iespējama dubulta rēķinu izrakstīšana par artikuliem!',
        AGREEMENT_CONFIGURATION_VALID_FROM_MUST_BE_BEFORE_VALID_TO:
            'Nederīga konfigurācija. Derīgs "no" ir jābūt pirms "derīgums līdz".',
        CANNOT_DELETE_WORKORDER_HAS_LOCKED_TIMEREPORTS:
            'Nevar izdzēst darba uzdevumu. Darba uzdevumā ir bloķētas laika atskaites',
        HELLO_USER: 'Čau {{name}}!',
        WELCOME_TO_YOUR_STARTPAGE: 'Laipni lūdzam jūsu sākumlapā!',
        PROJECT_FAILED_TO_SET_INCLUDED_HAS_INVOICED_ARTICLES:
            'Nevar iekļaut. Dažiem artikuliem jau ir izrakstīts rēķins.',
        PROJECT_FAILED_TO_SET_ARTICLE_INCLUDED_ALREADY_ON_INVOICE:
            'Rinda jau ir iekļauta kādā rēķinā.',
        CONFIRM_CLOSE_PROJECT_INVOICE:
            'Tiks izveidots rēķins. Lūdzu apstipriniet.',
        PROJECT_INVOICE_SUCCESSFULLY_CREATED: 'Rēķins izveidots veiksmīgi.',
        FAILED_TO_HANDLE_SUPPLIER_INVOICE:
            'Neizdevās apstrādāt piegādātāja rēķinu.',
        SUCCESSFULLY_HANDLED_SUPPLIER_INVOICE:
            'Veiksmīgi apstrādāts piegādātāja rēķins.',
        ARTICLE_GROUP_CANNOT_DELETE:
            'Neizdevās izdzēs artikulu grupu. Grupā ir artikuli vai tā ir iespējota kādā cenrādī.',
        PRICE_LIST_CANNOT_DELETE:
            'Nevar izdzēst cenrādi. Dažiem klientiem ir iespējots cenrādis.',
        REMOVE_MFA_PROVIDER_CONFIRM:
            'Lūdzu, apstipriniet, ka vēlaties noņemt divu faktoru autentifikāciju, izmantojot {{serviceProvider}}',
        REGISTER_MFA_PROVIDER_CONFIRM:
            'Lūdzu, apstipriniet, ka vēlaties pievienot divu faktoru autentifikāciju, izmantojot pakalpojumu {{serviceProvider}}. Pārliecinieties, vai ir instalēts pakalpojums {{serviceProvider}}',
        REGISTERING_MFA_FAILED:
            'Neizdevās pievienot divu faktoru autentifikāciju, izmantojot {{serviceProvider}}',
        FAILED_TO_REMOVE_MFA_PROVIDER: 'Neizdevās noņemt {{serviceProvider}}',
        SUCCESSFULLY_REMOVED_MFA_PROVIDER:
            '{{serviceProvider}} ir veiksmīgi noņemts',
        SUCCESSFULLY_REGISTERED_MFA:
            'Veiksmīgi pievienota divu faktoru autentifikācija. Skenējiet tālāk norādīto QR kodu {{serviceProvider}}',
        CONFIRM_SALES_ORDER_MANUAL_RECEIPT_IS_OK:
            'Lūdzu, apstipriniet, ka drukāšanas uzdevums bija veiksmīgs.',
        SALES_ORDER_MANUAL_RECEIPT_SUCCESSFUL:
            'Pārdošanas pasūtījuma rēķins veiksmīgi izrakstīts',
        FILE_UPLOAD_SUCCESSFUL_PROCESSING_FILE:
            'Fails tika veiksmīgi augšupielādēts. Tas tiek apstrādāts.',
        SUPPLIER_INVENTORY_CONFIRM_FORMAT_OF_FIRST_10_ROWS:
            'Apstipriniet, ka datu formāts ir pareizs',
        FAILED_TO_MOVE_FILE: 'Neizdevās pārvietot failu',
        CUSTOMER_INFO_INVOICES_WILL_NOT_BE_GENERATED:
            'Rēķini netiks nosūtīti ERP!',
        WORK_ORDER_CONFIRM_CLOSE_CUSTOMER_IS_CONFIGURED_TO_NOT_SEND_TO_ERP:
            'Atbilstoši klienta konfigurācijai rēķins netiks nosūtīts uz ERP!',
        SUPPLIER_INVENTORY_LAST_FILE_UPLOAD:
            'Pēdējā artikulu cenas atjaunināšana notika {{time}}.',
        SUPPLIER_INVENTORY_LAST_FILE_UPLOAD_NOT_FOUND:
            'Last update time not found',
        VALIDATION_DEVIATION_CATEGORY_NAME_BETWEEN_2_50_CHARS:
            'Nosaukumam ir jābūt no 2 līdz 50 rakstzīmēm',
        DEVIATION_SUCCESSFULLY_CLOSED: 'Novirze veiksmīgi aizvērta',
        OBJECT_AGREEMENT_VALID_FROM_DATE_NOT_SET:
            'Jānorāda datums, lai sāktu līguma rēķinu izrakstīšanu!',
        OBJECT_AGREEMENT_VALID_TO_DATE_SET:
            'Pēc šī datuma līguma rēķini netiks ģenerēti!',
        WORK_ORDER_USER_DISTRIBUTION_USER_ALREADY_ADDED: 'User already added!', // missing
        PROPOSAL_REOPEN_ARE_YOU_SURE: 'Are you sure to open proposal?', // missing
        PROPOSAL_REOPEN_WORKORDER_WILL_NOT_BE_DELETED:
            'If work order was created from this proposal - it is not deleted!', // missing
        PROPOSAL_REOPEN_SALESORDER_WILL_NOT_BE_DELETED:
            'If sales order was created from this proposal - it is not deleted!', // missing
        WORK_ORDER_REOPEN_ARE_YOU_SURE: 'Are you sure to open the work order?', // missing
        WORK_ORDER_REOPEN_INVOICE_WILL_BE_DELETED:
            'Invoice in Trukks will be deleted.', // missing
        WORK_ORDER_REOPEN_INVOICE_IN_ERP_WILL_NOT_BE_DELETED:
            'Invoice in ERP will not be deleted!', // missing
        WORKORDER_SERVICEREPORT_REOPEN_ARE_YOU_SURE: 'Are you sure to reopen the form?',
        WORKORDER_SERVICEREPORT_REOPEN_FILE_WILL_NOT_BE_DELETED:
            'Previously generated file will not be deleted!',
        ADD_LINK_REQUIRED_FIELDS: 'Link and name must be provided', //MISSING
        FAILED_TO_AUTHENTICATE_MS_AUTH: 'Authentication via Microsoft failed', //MISSING
        SUCCESSFULLY_SCHEDULED_WORK_ORDERS:
            'Successfully scheduled. Work orders will be created in few minutes!', // missing
        COMPANY_HOLIDAY_DATE_ALREADY_USED:
            'Could not update company holiday, holiday date already in use', //missing
        WORK_ORDER_BY_TAGS_KPI_ERROR_MESSAGE:
            'Something went wrong while loading the data. Please try again later', //MISSING
        WORK_ORDER_REOPEN_SUCCESSFUL: 'Re-opening was successful', // missing
        CANNOT_CLOSE_PROJECT_INVOICE_CUSTOMER_IS_POTENTIAL_CUSTOMER:
            'Cannot close project invoice. Customer must have customer number.', // missing
        CANNOT_CLOSE_PROPOSAL_WORKORDER_CUSTOMER_NUMBER_MISSING:
            'Cannot accept proposal. Customer must have customer number.', // missing
        CANNOT_CLOSE_PROPOSAL_WORKORDER_OBJECT_IS_MISSING:
            'Cannot accept proposal. Please select object first.', // missing
        CANNOT_CLOSE_PROPOSAL_SALES_ORDER_CUSTOMER_NUMBER_MISSING:
            'Cannot accept proposal. Customer must have customer number.', // missing
        CANNOT_CLOSE_WORK_ORDER_CUSTOMER_NUMBER_MISSING:
            'Cannot close work order. Customer must have customer number.', // missing
        CANNOT_CLOSE_SALES_ORDER_CUSTOMER_NUMBER_MISSING:
            'Cannot close work order. Customer must have customer number.', // missing
        CANNOT_CLOSE_SALES_ORDER_REFERENCE_USER_REQUIRED: 'Cannot close sales order. Please set reference user', // missing
        CONFIRM_SUPPLIER_INVOICE_APPROVE_PAYMENT: 'Are you sure to approve payment?', // missing
        USER_REQUIRED: 'Please select day', // missing
        DAY_OF_WEEK_REQUIRED: 'Please select user', // missing
        PROJECT_HAS_OPEN_WORK_ORDERS: 'Can not close project. Project has open work orders', // missing
        WORK_ORDER_CHANGE_CUSTOMER_INFO: 'Article prices on work order are not updated!', // missing
        WORK_ORDER_CHANGE_OBJECT_INFO: 'Article prices on work order are not updated!', // missing
        WORK_ORDER_ASSIGNEE_REQUIRED: 'One assigned user is required!', // missing
        WORK_ORDER_CAN_NOT_REMOVE_SCHEDULED_USER: 'Can not remove assignee. Please add anothed assigned user before removing.', // missing
        COPIED_TO_CLIPBOARD: 'Copied to clipboard', // missing
        SERVICE_REPORT_ALL_GENERATED_PDFS_WILL_BE_PUBLISHED: 'All generated PDFs will be automatically published to customer zone!', // missing
    },
    helpers: {
        AGREEMENT_WORKORDER_GENERATION_DAY_HELPER:
            'Izvēlieties mēneša dienu, kad tiks izveidots darba uzdevums',
        AGREEMENT_WORKORDER_GENERATION_INTERVAL_HELPER:
            'Izvēlieties darba uzdevuma izveides intervālu',
        AGREEMENT_INVOICE_GENERATION_DAY_HELPER:
            'Izvēlieties mēneša dienu, kad tiks izveidots rēķins',
        AGREEMENT_INVOICE_GENERATION_INTERVAL_HELPER:
            'Izvēlieties rēķina izveides intervālu',
        AGREEMENT_ARTICLES_ARE_ADDED_TO_WORKORDER:
            'Līguma artikuli ir pievienoti ģenerētajam darba uzdevumam',
        INVOICE_MIN_ID_LEN_VALIDATION: 'Lūdzu, ievadiet pozitīvu skaitli',
        CUSTOMER_EMAIL_HELP_TEXT: 'Šis e-pasts tiks izmantots rēķiniem',
        CUSTOMER_INVOICE_MARKING_HELP_TEXT:
            'Šis marķējums tiks iestatīts visiem jaunajiem darba uzdevumiem',
        PRIMARY_CUSTOMER_ADDRESS: 'Primārā adrese',
        PRIMARY_CUSTOMER_ADDRESS_FOR_ERP:
            'Šī adrese tiek izmantota ERP integrācijai',
        OBJECT_AGREEMENT_INVOICE_MARKING_HELPTEXT:
            'Šis marķējums parādās līguma rēķinā un ir iepriekš atlasīts visos jaunajos darba uzdevumos un ignorē klienta rēķina marķējumu.',
        REFERENCE_CONTACT_FOR_ERP: 'Tiek parādīts kā "jūsu atsauce" ERP',
        FIELD_WILL_BE_DISPLAYED_ON_WORKORDER_IF_FILLED_IN:
            'Tiks parādīts darba pasūtījumā, ja tas būs aizpildīts',
        WORKORDER_THIS_ARTICLE_IS_INVOICED_AUTOMATICALLY:
            'Automātiski pievienots no laika atskaitēm',
        PROJECT_SETTINGS_DEFAULT_INVOICE_MARKING_HELPTEXT:
            'This marking will be pre-selected on all project invoices for this project.',
        CUSTOMER_BROADCAST_EMAIL_HELP_TEXT:
            'This email will be used for sending emails about new work orders', // missing
        PROJECT_INVOICING_STATUS_ONLY_FOR_IN_SCOPE:
            'Only for in-scope articles. ÄTA is excluded.', // missing
        PROJECT_OUTCOME_EXTRA_SCOPE_ONLY_APPROVED:
            'Only approved work orders are included', // missing
        PROJECT_OUTCOME_EXTRA_SCOPE_ALL: 'Accepted, pending, declined work orders are included', // missing
    },
    invoice: {
        INVOICE_INFORMATION: 'Rēķins par',
        INVOICE_DESCRIPTION: 'Apraksts',
        ISSUED_WITH_REVERSE_VAT: 'Apgrieztais PVN piemērots',
    },
    acl: {
        PERMISSION_GROUP_WORK_ORDER: 'Darba uzdevums',
        PERMISSION_GROUP_INVENTORY: 'Inventārs',
        PERMISSION_GROUP_OTHER: 'Citi',
        PERMISSION_SETTINGS_READ: 'Skatīt iestatījumus',
        PERMISSION_SETTINGS_IMPORT_EXPORT: 'Export & Import', // missing
        PERMISSION_USERS_READ: 'Skatīt lietotājus',
        PERMISSION_USERS_EDIT: 'Rediģēt lietotājus',
        PERMISSION_INSTANCE_READ: 'Skatīt uzņēmuma informāciju',
        PERMISSION_INSTANCE_EDIT: 'Rediģēt uzņēmuma informāciju',
        PERMISSION_CUSTOMERS_READ: 'Skatīt klientus',
        PERMISSION_CUSTOMERS_CREATE: 'Create customers', // missing
        PERMISSION_CUSTOMERS_CREATE_POTENTIAL: 'Create potential customers', // missing
        PERMISSION_CUSTOMERS_EDIT: 'Rediģēt klientus',
        PERMISSION_CUSTOMERS_DELETE: 'Dzēst klientus',
        PERMISSION_CUSTOMERS_FILES: 'Manage files on customers',
        PERMISSION_CUSTOMERS_FILES_READ: 'Read files on customers', // missing
        PERMISSION_BUSINESS_OBJECTS_READ: 'Skatīt objektus',
        PERMISSION_BUSINESS_OBJECTS_EDIT: 'Rediģēt objektus',
        PERMISSION_BUSINESS_OBJECTS_DELETE: 'Dzēst liftus',
        PERMISSION_BUSINESS_OBJECT_SERVICE_LOG:
            'Skatīt/rediģēt ikdienas servisa grāmatu',
        PERMISSION_BUSINESS_OBJECT_FILES: 'Manage files on objects',
        PERMISSION_BUSINESS_OBJECT_FILES_READ: 'Read files on objects', // missing
        PERMISSION_BUSINESS_OBJECT_AGREEMENT_EDIT: 'Edit agreement under object', // missing
        PERMISSION_BUSINESS_OBJECTS_CODES_VIEW: 'See object codes', // missing
        PERMISSION_BUSINESS_OBJECTS_CODES_EDIT: 'Edit object codes', // missing
        PERMISSION_BUSINESS_OBJECTS_CODES_VIEW_LOG: 'See logs for object codes', // missing
        PERMISSION_ARTICLES_CREATE: 'Create articles', // missing
        PERMISSION_ARTICLES_READ: 'Skatīt artikulus',
        PERMISSION_ARTICLES_PRICE: 'Skatīt artikulu cenas',
        PERMISSION_ARTICLES_EDIT: 'Labot artikulus',
        PERMISSION_ARTICLES_DELETE: 'Dzēst artikulus',
        PERMISSION_ARTICLES_SETTINGS: 'Skatīt un rediģēt artikulu iestatījumus',
        PERMISSION_ARTICLE_FILES_READ: 'View article files', // missing
        PERMISSION_ARTICLE_FILES_MANAGE: 'Upload & delete article files', // missing
        PERMISSION_ACL_READ: 'Skatīt piekļuves līmeņus',
        PERMISSION_ACL_EDIT: 'Rediģēt piekļuves līmeņus',
        PERMISSION_WORKORDERS_READ: 'Skatīt darba uzdevumus',
        PERMISSION_WORKORDERS_EDIT: 'Rediģēt darba uzdevumus',
        PERMISSION_WORKORDERS_EDIT_INTERNAL_INFO:
            'Rediģēt darba uzdevumus (izveides laiks)',
        PERMISSION_WORKORDERS_EDIT_ARTICLE_DISTRIBUTIONS:
            'Edit article distributions on work order', // missing
        PERMISSION_WORKORDERS_CREATE: 'Izveidot darba uzdevumus',
        PERMISSION_WORKORDERS_CLOSE: 'Aizvērt darba uzdevumus',
        PERMISSION_WORKORDERS_REOPEN: 'Re-open work orders', // missing
        PERMISSION_WORKORDERS_COPY: 'Kopēt darba uzdevumus',
        PERMISSION_WORKORDERS_DELETE: 'Dzēst darba uzdevumus',
        PERMISSION_WORKORDERS_REPORT:
            'Pārskats par darba uzdevumiem (artikulu laiks)',
        PERMISSION_WORKORDERS_LOCK: 'Bloķēt darba uzdevumus',
        PERMISSION_WORKORDERS_FILES: 'Pārvaldīt darba uzdevumu failus',
        PERMISSION_WORKORDERS_TIMEREPORTS_SELECT:
            'Pievienot rēķinam darba uzdevuma laika atskaites',
        PERMISSION_WORKORDERS_READ_COST: 'Skatīt darba uzdevuma robežu',
        PERMISSION_FILES_EDIT: 'Rediģēt failus',
        PERMISSION_MODELS_READ: 'Skatīt modeļus',
        PERMISSION_MODELS_EDIT: 'Rediģēt modeļus',
        PERMISSION_MODELS_DELETE: 'Dzēst modeļus',
        PERMISSION_MODEL_FILES_READ: 'View model files', // missing
        PERMISSION_MODEL_FILES_MANAGE: 'Upload & delete model files', // missing
        PERMISSION_AGREEMENTS_READ: 'Skatīt līgumus',
        PERMISSION_AGREEMENTS_EDIT: 'Rediģēt līgumus',
        PERMISSION_AGREEMENTS_DELETE: 'Dzēst līgumus',
        PERMISSION_SUPPLIERS_READ: 'Skatīt piegādātājus',
        PERMISSION_SUPPLIERS_EDIT: 'Rediģēt piegādātājus',
        PERMISSION_SUPPLIERS_DELETE: 'Dzēst piegādātājus',
        PERMISSION_SUPPLIER_ORDERS_EDIT: 'Mainīt piegādātāju pasūtījumus',
        PERMISSION_INVOICES_READ: 'Skatīt rēķinus',
        PERMISSION_REPORTS_READ: 'Skatīt pārskatus',
        PERMISSION_TIMEREPORTS_READ: 'Skatīt citu lietotāju laika atskaites',
        PERMISSION_TIMEREPORTS_EDIT:
            'Rediģēt informāciju par citu lietotāju laika atskaitēm',
        PERMISSION_ON_CALL_REPORTS_EDIT: 'Edit on-call', // missing
        PERMISSION_DISTANCEREPORTS_READ:
            'Skatīt citu lietotāju attāluma atskaites',
        PERMISSION_PROJECTS_READ: 'Skatīt projektus',
        PERMISSION_PROJECTS_EDIT: 'Izveidot un modificēt projektus',
        PERMISSION_PROJECTS_DELETE: 'Dzēst projektus',
        PERMISSION_PROJECTS_FILES: 'Manage files on projects',
        PERMISSION_PROJECTS_CLOSE_NEGATIVE_INVOICE:
            'Aizvērt negatīvo projekta rēķinu',
        PERMISSION_PROJECTS_REOPEN: 'Re-open projects', // missing
        PERMISSION_SALES_ORDER_READ: 'Skatīt pārdošanas pasūtījumus',
        PERMISSION_SALES_ORDER_EDIT:
            'Izveidot un modificēt pārdošanas pasūtījumus',
        PERMISSION_SALES_ORDER_DELETE: 'Dzēst pārdošanās pasūtījumus',
        PERMISSION_PROPOSAL_READ: 'Skatīt priekšlikumus',
        PERMISSION_PROPOSAL_EDIT: 'Pārvaldīt priekšlikumus',
        PERMISSION_PROPOSAL_DELETE: 'Dzēst priekšlikumus',
        PERMISSION_PROPOSAL_FILES: 'Manage files on proposals',
        PERMISSION_PROPOSAL_REOPEN: 'Reopen closed proposals',
        PERMISSION_SECURITY_BO_DOCUMENT_READ:
            'Izlasiet informāciju par drošības sistēmas veidu',
        PERMISSION_SECURITY_BO_DOCUMENT_EDIT:
            'Rediģēt drošības sistēmas veida informāciju',
        PERMISSION_WORKORDERS_EDIT_ID06_CODE:
            'Rediģēt ID06 kodu darba pasūtījumos',
        PERMISSION_MESSAGE_BOARD_POST_EDIT: 'Rediģēt iekšējos ziņojumus',
        PERMISSION_DASHBOARD_READ: 'Skatīt informācijas paneli',
        PERMISSION_DASHBOARD_OPEN_WORKORDERS_KPI_EDIT:
            'Skatīt un rediģēt atvērtā darba uzdevuma cenu faktorus',
        PERMISSION_DEVIATIONS_READ: 'Skatīt novirzes',
        PERMISSION_DEVIATIONS_EDIT: 'Rediģēt novirzes',
        PERMISSION_DEVIATIONS_CREATE: 'Radīt novirzes',
        PERMISSION_DEVIATIONS_CLOSE: 'Aizvērt novirzes',
        PERMISSION_DEVIATIONS_DELETE: 'Dzēst novirzes',
        PERMISSION_DOORAUTOMATICS_READ: 'View doorautomatics', // missing
        PERMISSION_DOORAUTOMATICS_EDIT: 'Edit doorautomatics', // missing
        PERMISSION_DOORAUTOMATICS_DELETE: 'Delete doorautomatics', // missing
        PERMISSION_DOORAUTOMATICS_FILES: 'Edit doorautomatic files', // missing
        PERMISSION_DOORAUTOMATICS_FILES_READ: 'Read doorautomatic files', // missing
        PERMISSION_WORKORDER_DOORAUTOMATICS_EDIT: 'Edit doorautomatics on work order', // missing
        PERMISSION_CUSTOMER_ZONE_EDIT_USERS: 'Edit users for customer zone', // missing
        PERMISSION_CUSTOMER_ZONE_PUBLISH_FILES: 'Publish and unpublish files for customer zone', // missing
        PERMISSION_SUPPLIER_INVOICES_READ: 'Read assigned supplier invoices', // missing
        PERMISSION_SUPPLIER_INVOICES_EDIT: 'Manage supplier invoices', // missing
        PERMISSION_SUPPLIER_INVOICES_READ_ALL: 'Read all supplier invoices', // missing
        PERMISSION_WORKORDERS_SERVICEREPORT_REOPEN: 'Re-open closed forms', // missing
    },
    customerSpecifications: {
        invoiceSubjectTemplate: 'Invoice email subject template',
    },
    workorderhistory: {
        close: 'Closed',
        edit: 'Edited',
        'edit.internal': 'Edited internal',
        'edit.customer': 'Edited customer', // missing
        'edit.object': 'Edited object', // missing
        create: 'Created',
        delete: 'Deleted',
        'agreement.create': 'Created automatically',
        lock: 'Locked',
        unlock: 'Unlocked',
        add_articles: 'Added articles from supplier order',
    },
    invoicehistory: {
        create: 'Created',
        email: 'Emailed',
        'erp.created.visma': 'Sent to Visma',
        'erp.created.wint': 'Sent to Wint',
        'erp.created.Magenta': 'Sent to Magenta',
        'erp.created.visma-e': 'Visma eAccounting',
        'erp.created.fortnox': 'Fortnox',
        'erp.created.quickbooks': 'QuickBooks',
        visma: 'Sent to Visma',
        wint: 'Sent to Wint',
        magenta: 'Sent to Magenta',
        'visma-e': 'Sent to Visma eAccounting',
        fortnox: 'Sent to Fortnox',
        quickbooks: 'Sent to QuickBooks',
    },
    stats: {
        NO_DATA_FOUND: 'Dati nav atrasti',
        BAR_CHART: 'Aplis',
        LINE_CHART: 'Līnija',
        SERVICE_DISTRIBUTION: 'Pakalpojumu izplatīšana',
        SERVICE_DISTRIBUTION_EXCEL_EXPORT_FILENAME: 'Pakalpojumu izplatīšana',
        PLANNED_SERVICES: 'Plānots {{count}} servisa apmeklējums',
        PLANNED_SERVICES_plural: 'Plānoti {{count}} servisa apmeklējumi',
        CLICK_TO_VIEW_DETAILS: 'Noklikšķiniet, lai skatītu sīkāk',
        INVOICING: 'Rēķinu izrakstīšana',
        INVOICING_LAST_12_MONTHS: 'Pēdējie 12 mēneši',
        INVOICING_THIS_YEAR: 'Šis gads',
        INVOICING_WORK_ORDERS: 'Darba uzdevumi',
        INVOICING_AGREEMENTS: 'Līgumi',
        INVOICING_ALL: 'Visi',
        INVOICING_SUMMED: 'Kopskaits',
        ACTIVE_WORK_ORDERS: 'Aktīvie darba uzdevumi',
        ACTIVE_WORK_ORDERS_BY_STATUS: 'Pēc statusa',
        NUMBER_OF_WORKORDERS: '{{count}} darba uzdevumi',
        LARMKLASS: 'Alarm class distribution of objects',
        OPEN_WORKORDERS_TO_INVOICE: 'Atvērt darba uzdevumus',
        OPEN_WORKORDERS_ON_SERVICE: 'Uz sevisu',
        OPEN_WORKORDERS_TO_INVOICE_TOTAL_PRICE: '{{price}} pārdošanas vērtība',
        OPEN_WORKORDERS_TO_INVOICE_TOTAL_COUNT:
            '{{count}} atvērti darba uzdevumi',
        OPEN_WORKORDERS_TO_INVOICE_TOTAL_TIME: '{{time}} norādītās stundas',
        OPEN_WORKORDERS_TO_INVOICE_TOTAL_COST: 'Kopējās izmaksas: {{price}}',
        OPEN_WORKORDERS_TO_INVOICE_WORKORDER_COST:
            'Kopējās izmaksas: {{price}}',
        OPEN_WORKORDERS_TO_INVOICE_PRESENTING_DAYS: 'Presenting days', // missing
        OPEN_WORKORDERS_TO_INVOICE_PRESENTING_DAYS_TO: 'to', // missing
        OPEN_WORKORDERS_TO_INVOICE_PRESENTING_DAYS_TOTAL: '(of {{count}} days)', // missing
        OPEN_WORKORDERS_ON_PROJECT: 'Par projektiem',
        OPEN_WORKORDERS_TO_INVOICE_TOTAL_PROJECT_WORK_ORDERS_COUNT:
            '{{count}} atvērti darba uzdevumi',
        OPEN_WORKORDERS_TO_INVOICE_WORKORDER_PRICE:
            'Pārdošanas cena: {{price}}',
        OPEN_WORKORDERS_TO_INVOICE_WORKORDER_TIME: 'Pavadītais laiks: {{time}}',
        NON_MATCHING_SUPPLIER_ORDERS: 'Neatbilstoši piegādātāju pasūtījumi',
        NON_MATCHING_SUPPLIER_ORDERS_PAST_2_MONTHS: 'Pēdējie 2 mēneši',
        NON_MATCHING_SUPPLIER_ORDERS_ARRIVED_TO_TRUKKS: 'Piegādāts Trukks',
        NON_MATCHING_SUPPLIER_ORDERS_SUPPLIER_NAME: 'Piegādātājs',
        NON_MATCHING_SUPPLIER_ORDERS_SUPPLIER_ORDER_ID:
            'Piegādātāja pasūtījuma ID',
        NON_MATCHING_SUPPLIER_ORDERS_ORDER_REFERENCE: 'Pasūtījuma atsauce',
        NON_MATCHING_SUPPLIER_ORDERS_NO_ORDERS:
            'Nav atrasts neviens neatbilstošs pasūtījums',
        NON_MATCHING_SUPPLIER_ORDERS_ADJUST_ORDER: 'Pielāgot atsauci',
        NON_MATCHING_SUPPLIER_ORDERS_ORDER_WILL_BE_REPROCESSED:
            'Pasūtījums tiks atkārtoti apstrādāts ar jaunu atsauci',
        NON_MATCHING_SUPPLIER_ORDERS_DISCARD_ORDER: 'Atmest pasūtījumu',
        NON_MATCHING_SUPPLIER_ORDERS_TOTAL_COST: 'Total cost', //MISSING
        NON_MATCHING_SUPPLIER_ORDERS_LIST_OPTION: 'Order reference & value', // missing
        NON_MATCHING_SUPPLIER_ORDERS_DETAILS_OPTION: 'Order details', // missing
        OPEN_PROJECTS_KPI_TOTAL_CONTRACTS_LABEL: 'Kopā līgumi',
        OPEN_PROJECTS_KPI_TOTAL_CONTRACTS:
            '{{price}} par {{total}} atvērtiem projektiem',
        OPEN_PROJECTS_KPI_TOTAL_INVOICED_LABEL: 'Kopā izrakstīti rēķini',
        OPEN_PROJECTS_KPI_TOTAL_INVOICED:
            '{{price}} ({{percent}} no kopējiem līgumiem)',
        OPEN_PROJECTS_KPI_TOTAL_LEFT_TO_INVOICE_LABEL:
            'Kopā vēl jāizraksta rēķini',
        OPEN_PROJECTS_KPI_TOTAL_LEFT_TO_INVOICE:
            '{{price}} ({{percent}} no kopējiem līgumiem)',
        OPEN_PROJECTS_KPI_ONGOING_COST_LABEL: 'Pastāvīgās izmaksas',
        OPEN_PROJECTS_KPI_ONGOING_COST: '{{price}}',
        OPEN_PROJECTS_KPI_ONGOING_COST_X_NOT_REPORTED: '{{count}} trūkst',
        OPEN_PROJECTS_KPI_ONGOING_COST_NOT_INVOICED_LABEL:
            'Maksa, kas nav iekļauta rēķinā',
        OPEN_PROJECTS_KPI_ONGOING_COST_NOT_INVOICED: '{{price}}',
        OPEN_PROJECTS_KPI_BIGGEST_CONTRACT_LABEL: 'Lielākais līgums',
        OPEN_PROJECTS_KPI_BIGGEST_CONTRACT: '{{price}} ({{projectName}})',
        OPEN_PROJECTS_KPI_AVERAGE_CONTRACT_LABEL: 'Vidējais līgums',
        OPEN_PROJECTS_KPI_AVERAGE_CONTRACT: '{{price}}',
        OPEN_PROJECTS_KPI_RESET_DAY_OF_MONTH: 'Vidējais līgums',
        OPEN_PROJECTS_INCOME_KPI_HEADER: 'Projekta ienākumi',
        OPEN_PROJECTS_INCOME_KPI_INVOICED: 'Izrakstīts rēķins',
        OPEN_PROJECTS_INCOME_KPI_ONGOING_COST: 'Pastāvīgās izmaksas',
        OPEN_PROJECTS_INCOME_KPI_WEEKS_OPEN: 'Atvērtas nedēļas',
        KPI_CLOSED_WORKORDERS_BY_TYPE_HEADER:
            'Pabeigtie darba uzdevumi pēc to veida',
        KPI_CLOSED_WORKORDERS_BY_TYPE_COUNT_OF_WORKORDERS: 'Skaitīt',
        KPI_ONBOARDED_AND_LOST_OBJECTS_TITLE: 'Onboarded and lost objects', // missing
        ONBOARDED_OBJECTS: 'Onboarded', // missing
        LOST_OBJECTS: 'Lost', // missing
        STATS_ACCRUED_INCOME: 'Accrued income', // missing
        STATS_ACCRUED_INCOME_DISPLAYED_UNTIL: 'Until {{date}}', // missing
        STATS_ACCRUED_INCOME_FILE_NAME: 'Accrued income', // missing
        STATS_ERP_SUPPLIER_INVOICES_CARD_HEADER: 'Supplier invoices', // missing

        WORK_ORDER_LEAD_TIME_PERIOD: 'Presented period', // missing
        WORK_ORDER_LEAD_TIME_WORK_TYPE: 'Work order type', // missing
        WORK_ORDER_LEAD_TIME_KPI_HEADER: 'Lead time', // missing
        WORK_ORDER_LEAD_TIME_DAYS_OPEN: 'Days open', // missing
        WORK_ORDER_LEAD_TIME_COUNT_OF_ORDERS: 'Count', // missing
        WORK_ORDER_LEAD_TIME_PERIOD_TIME_SPAN_VALIDATION_MESSAGE: 'Specified period must be between 10 days and 1 year', // missing
        
        CLOSED_SALES_ORDER_PRICING_WEEKLY_HEADER: 'Sales order: Summary of sales & margin', // missing
        CLOSED_WORK_ORDER_PRICING_WEEKLY_HEADER: 'Work order: Summary of sales & margin', // missing
        TOTAL_SALES_PRICE: 'Total', // missing
        TOTAL_MARGIN: 'Margin', // missing
    },
    suppliers: {
        elektroskandia: 'Elektroskandia',
        solar: 'Solar',
        copiax: 'Copiax',
        ahlsell: 'Ahlsell',
        axsnordic: 'AXS Nordic',
        rexel: 'Rexel',
        dafo: 'Dafo',
        extronic: 'Extronic',
        teletec: 'Teletec',
    },
    erp: {
        INVOICE_NR_HANDLED_BY: 'Apstrādāja {{name}}',
        INVOICE_WAS_NOT_CREATED_IN_ERP: 'Rēķins netika izveidots {{name}}!',
        visma: 'Visma',
        fortnox: 'Fortnox',
        magenta: 'Magenta',
        'visma-e': 'Visma e-economy',
        wint: 'Wint',
        quickbooks: 'QuickBooks',
    },
    files: {
        ROOT_FOLDER: 'Faili',
        BACK_TO: 'Atpakaļ uz',
    },
    importexport: {
        FILE_NAME: 'File name', // missing
        ARTICLE_EXPORT_NAME: 'Articles', // missing
        CUSTOMER_EXPORT_NAME: 'Customers', // missing
        OBJECT_EXPORT_NAME: 'Objects', // missing
        UPCOMING_AGREEMENT_INVOICES_EXPORT_NAME: 'Upcoming invoices', // missing
    },
    countries: {
        AF: 'Afghanistan',
        AX: 'Åland Islands',
        AL: 'Albania',
        DZ: 'Algeria',
        AS: 'American Samoa',
        AD: 'Andorra',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarctica',
        AG: 'Antigua and Barbuda',
        AR: 'Argentina',
        AM: 'Armenia',
        AW: 'Aruba',
        AU: 'Australia',
        AT: 'Austria',
        AZ: 'Azerbaijan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Belarus',
        BE: 'Belgium',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Bolivia (Plurinational State of)',
        BQ: 'Bonaire, Sint Eustatius and Saba',
        BA: 'Bosnia and Herzegovina',
        BW: 'Botswana',
        BV: 'Bouvet Island',
        BR: 'Brazil',
        IO: 'British Indian Ocean Territory',
        BN: 'Brunei Darussalam',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        CV: 'Cabo Verde',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        KY: 'Cayman Islands',
        CF: 'Central African Republic',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CX: 'Christmas Island',
        CC: 'Cocos (Keeling) Islands',
        CO: 'Colombia',
        KM: 'Comoros',
        CG: 'Congo',
        CD: 'Congo, Democratic Republic of the',
        CK: 'Cook Islands',
        CR: 'Costa Rica',
        CI: 'Côte d\'Ivoire',
        HR: 'Croatia',
        CU: 'Cuba',
        CW: 'Curaçao',
        CY: 'Cyprus',
        CZ: 'Czechia',
        DK: 'Denmark',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        EC: 'Ecuador',
        EG: 'Egypt',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        SZ: 'Eswatini',
        ET: 'Ethiopia',
        FK: 'Falkland Islands (Malvinas)',
        FO: 'Faroe Islands',
        FJ: 'Fiji',
        FI: 'Finland',
        FR: 'France',
        GF: 'French Guiana',
        PF: 'French Polynesia',
        TF: 'French Southern Territories',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgia',
        DE: 'Germany',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GR: 'Greece',
        GL: 'Greenland',
        GD: 'Grenada',
        GP: 'Guadeloupe',
        GU: 'Guam',
        GT: 'Guatemala',
        GG: 'Guernsey',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        HM: 'Heard Island and McDonald Islands',
        VA: 'Holy See',
        HN: 'Honduras',
        HK: 'Hong Kong',
        HU: 'Hungary',
        IS: 'Iceland',
        IN: 'India',
        ID: 'Indonesia',
        IR: 'Iran (Islamic Republic of)',
        IQ: 'Iraq',
        IE: 'Ireland',
        IM: 'Isle of Man',
        IL: 'Israel',
        IT: 'Italy',
        JM: 'Jamaica',
        JP: 'Japan',
        JE: 'Jersey',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KP: 'Korea (Democratic Peoples Republic of) ',
        KR: 'Korea, Republic of',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: 'Lao Peoples Democratic Republic',
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libya',
        LI: 'Liechtenstein',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        MO: 'Macao',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MU: 'Mauritius',
        YT: 'Mayotte',
        MX: 'Mexico',
        FM: 'Micronesia (Federated States of)',
        MD: 'Moldova, Republic of',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MS: 'Montserrat',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Netherlands',
        NC: 'New Caledonia',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        NF: 'Norfolk Island',
        MK: 'North Macedonia',
        MP: 'Northern Mariana Islands',
        NO: 'Norway',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PS: 'Palestine, State of',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines',
        PN: 'Pitcairn',
        PL: 'Poland',
        PT: 'Portugal',
        PR: 'Puerto Rico',
        QA: 'Qatar',
        RE: 'Réunion',
        RO: 'Romania',
        RU: 'Russian Federation',
        RW: 'Rwanda',
        BL: 'Saint Barthélemy',
        SH: 'Saint Helena, Ascension and Tristan da Cunha',
        KN: 'Saint Kitts and Nevis',
        LC: 'Saint Lucia',
        MF: 'Saint Martin (French part)',
        PM: 'Saint Pierre and Miquelon',
        VC: 'Saint Vincent and the Grenadines',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'Sao Tome and Principe',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SX: 'Sint Maarten (Dutch part)',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        GS: 'South Georgia and the South Sandwich Islands',
        SS: 'South Sudan',
        ES: 'Spain',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SJ: 'Svalbard and Jan Mayen',
        SE: 'Sweden',
        CH: 'Switzerland',
        SY: 'Syrian Arab Republic',
        TW: 'Taiwan, Province of China',
        TJ: 'Tajikistan',
        TZ: 'Tanzania, United Republic of',
        TH: 'Thailand',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad and Tobago',
        TN: 'Tunisia',
        TR: 'Turkey',
        TM: 'Turkmenistan',
        TC: 'Turks and Caicos Islands',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates',
        GB: 'United Kingdom',
        US: 'United States of America',
        UM: 'United States Minor Outlying Islands',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela (Bolivarian Republic of)',
        VN: 'Viet Nam',
        VG: 'Virgin Islands (British)',
        VI: 'Virgin Islands (U.S.)',
        WF: 'Wallis and Futuna',
        EH: 'Western Sahara',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe',
    }
};
