import React, { Component } from 'react';
import { connect } from 'react-redux';
import { aclLimit } from '../../../utils/constants';

export const withPermissions = ComposedComponent => {
    class WithPermissions extends Component {
        
        hasLimit = limit => {
            const { userLimit } = this.props;

            if (limit === aclLimit.NONE) {
                return userLimit === aclLimit.NONE;    
            }

            return true;
        }

        hasPermission = name => this.props.userPermissions.indexOf(name) > -1;

        render() {
            return <ComposedComponent 
                hasLimit={this.hasLimit}
                hasPermission={this.hasPermission}
                isSuperUser={this.props.userType === 'super'}
                {...this.props} />
        }
    }

    const mapStateToProps = state => ({
        userLimit: state.auth.limit,
        userPermissions: state.auth.perms,
        userType: state.auth.userType,
    })

    return connect(mapStateToProps)(WithPermissions);
}