import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Modal.css';

class DataModal extends Component {

    componentDidMount() {
        this.props.closeOnEsc && document.addEventListener('keydown', this.handleEscClicked, false);
    }

    componentWillUnmount() {
        this.props.closeOnEsc && document.removeEventListener('keydown', this.handleEscClicked, false);
    }

    handleEscClicked = (e) => {
        if (this.props.closeOnEsc && e.keyCode === 27) {
            this.props.onClose();
        }
    }

    render() {
        const { header, content, onClose, style } = this.props;

        return (
            <div className="ui dimmer active man-modal-container" style={style}>
                <div className="ui modal active">
                    <div className="header">
                        {header}
                        &nbsp;
                        <i className="close icon Modal-Close"
                            onClick={e => {
                                e.stopPropagation();
                                onClose();
                            }}></i>
                    </div>
                    {content}
                </div>
            </div>
        )
    }
}

DataModal.propTypes = {
    header: PropTypes.string,
    content: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    closeOnEsc: PropTypes.bool
}

DataModal.defaultProps = {
    closeOnEsc: true
}

export default DataModal;