import { DEVIATION_STATUS_OPEN } from 'utils/constants';
import deviationService from '../../services/deviation/deviationService';

export const FETCH_DEVIATIONS = 'FETCH_DEVIATIONS';
export const FETCH_DEVIATIONS_FULFILLED = 'FETCH_DEVIATIONS_FULFILLED';
export const FETCH_DEVIATIONS_REJECTED = 'FETCH_DEVIATIONS_REJECTED';
export const DEVIATION_CREATED = 'DEVIATION_CREATED';
export const DEVIATION_CHANGED = 'DEVIATION_CHANGED';
export const DEVIATION_CLOSED = 'DEVIATION_CLOSED';
export const DEVIATION_DELETED = 'DEVIATION_DELETED';
export const DEVIATIONS_TABLE_STATE_CHANGE = 'DEVIATIONS_TABLE_STATE_CHANGE';
export const DEVIATIONS_FILTERS_CHANGE = 'DEVIATIONS_FILTERS_CHANGE';

const requestDeviations = () => ({ type: FETCH_DEVIATIONS });

const requestDeviationsFulfilled = deviations => ({
    type: FETCH_DEVIATIONS_FULFILLED,
    deviations,
});

const requestDeviationsRejected = error => ({
    type: FETCH_DEVIATIONS_REJECTED,
    error,
});

export const deviationsTableStateChange = state => ({
    type: DEVIATIONS_TABLE_STATE_CHANGE,
    state,
});

export const deviationsFiltersChange = filters => ({
    type: DEVIATIONS_FILTERS_CHANGE,
    filters,
});

export const deviationCreated = deviation => ({
    type: DEVIATION_CREATED,
    deviation,
});

export const deviationChanged = deviation => ({
    type: DEVIATION_CHANGED,
    deviation,
});

export const deviationClosed = id => ({
    type: DEVIATION_CLOSED,
    id,
});

export const deviationDeleted = id => ({
    type: DEVIATION_DELETED,
    id,
});

export const fetchDeviations =
    ({status}) =>
    async dispatch => {
        dispatch(requestDeviations());

        try {
            const deviations = await deviationService.fetch(status || DEVIATION_STATUS_OPEN);
            dispatch(requestDeviationsFulfilled(deviations));
        } catch (err) {
            console.error(err);
            dispatch(requestDeviationsRejected(err));
        }
    };
