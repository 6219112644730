import {
    REPORTS_WORKORDER_TABLE_STATE_CHANGE,
    REPORTS_WORKORDER_FILTERS_CHANGE, 
    FETCH_REPORTS_WORK_ORDERS, 
    FETCH_REPORTS_WORK_ORDERS_FULFILLED, 
    FETCH_REPORTS_WORK_ORDERS_REJECTED
} from '../../actions/reports';
import { getThisWeekPeriod } from '../../../utils/DateUtils';

const defaultState = {
    isLoading: false,
    workOrders: [],
    tableState: undefined,
    filters: getThisWeekPeriod()
}

export const closedWorkOrders = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case REPORTS_WORKORDER_FILTERS_CHANGE: {

            return {
                ...state,
                filters: action.filters
            }
        }
        case REPORTS_WORKORDER_TABLE_STATE_CHANGE: {

            return {
                ...state,
                tableState: action.state
            }
        }
        case FETCH_REPORTS_WORK_ORDERS: {

            return {
                ...state,
                isLoading: true
            }
        }
        case FETCH_REPORTS_WORK_ORDERS_FULFILLED: {

            return {
                ...state,
                isLoading: false,
                workOrders: action.workOrders
            }
        }
        case FETCH_REPORTS_WORK_ORDERS_REJECTED: {

            return {
                ...state,
                isLoading: false
            }
        }
        default: {
            return state;
        }
    }
};