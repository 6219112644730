import { SAVE_WORKORDER_FULFILLED } from 'data/actions/workorders';
import { calendarPeriod } from 'utils/CalendarUtils';
import { newDate } from 'utils/DateUtils';
import {
    FETCH_CLOSED_PROJECTS,
    FETCH_CLOSED_PROJECTS_FULFILLED,
    FETCH_CLOSED_PROJECTS_REJECTED,
    FETCH_ONGOING_PROJECTS,
    FETCH_ONGOING_PROJECTS_FULFILLED,
    FETCH_ONGOING_PROJECTS_REJECTED,
    projectPlanningType,
    PROJECT_CALENDAR_CHANGE_DATE,
    PROJECT_CALENDAR_CHANGE_PERIOD,
    PROJECT_CALENDAR_CHANGE_RESPONSIBLE,
    PROJECT_CALENDAR_CHANGE_SEARCH,
    PROJECT_CALENDAR_CHANGE_TYPE,
    PROJECT_CALENDAR_CHANGE_SORT,
    PROJECT_CALENDAR_CHANGE_REGION,
    PROJECT_CHANGED,
    PROJECT_CLOSED,
    PROJECT_PLANNING_CALENDAR_CHANGE_DATE,
    PROJECT_PLANNING_CALENDAR_CHANGE_PERIOD,
    PROJECT_PLANNING_CALENDAR_CHANGE_TYPE,
    PROJECT_REOPENED,
    RESOURCE_CALENDAR_SEQUENCE_UPDATE,
    PROJECT_GLOBAL_ACTIVE_INVOICE_CHANGED,
} from '../../actions/projects';

export const TYPE_ONGOING = 'ongoing';
export const TYPE_CLOSED = 'closed';

export const PROJECT_SORT = {
    ALPHANUMERIC: 'alphanumeric',
    TIME: 'time',
    RESPONSIBLE_USER: 'responsibleuser'
}

export const projects = (
    state = {
        activeDate: newDate(),
        activeType: TYPE_ONGOING,
        activePeriod: calendarPeriod.QUARTER,
        activeRegion: null,
        sort: PROJECT_SORT.TIME,
        responsibleUserId: null,
        ongoingProjects: [],
        isLoadingOngoing: false,
        closedProjects: [],
        isLoadingClosed: false,
        searchValue: '',
        activePlanningType: projectPlanningType.PROJECTS,
        activePlanningDate: newDate(),
        activePlanningPeriod: calendarPeriod.WEEK,
        projectsById: {},
        resourceCalendarSequence: 1,
        projectWorkOrdersSequence: 1,
        activeProjectInvoice: null,
    },
    action
) => {
    switch (action.type) {
        case SAVE_WORKORDER_FULFILLED: {
            return {
                ...state,
                projectWorkOrdersSequence: state.projectWorkOrdersSequence + 1,
            };
        }
        case RESOURCE_CALENDAR_SEQUENCE_UPDATE:
            return {
                ...state,
                resourceCalendarSequence: state.resourceCalendarSequence + 1,
            };
        case PROJECT_GLOBAL_ACTIVE_INVOICE_CHANGED:
            return {
                ...state,
                activeProjectInvoice: action.invoiceId,
            };
        case PROJECT_CALENDAR_CHANGE_DATE:
            return {
                ...state,
                activeDate: action.date,
            };

        case PROJECT_CALENDAR_CHANGE_PERIOD:
            return {
                ...state,
                activePeriod: action.activePeriod,
                activeDate: newDate(),
            };

        case PROJECT_CALENDAR_CHANGE_TYPE:
            return {
                ...state,
                activeType: action.activeType,
            };
        case PROJECT_CALENDAR_CHANGE_SORT:
            return {
                ...state,
                sort: action.sort,
            };
        case PROJECT_CALENDAR_CHANGE_REGION:
            return {
                ...state,
                activeRegion: action.region
            };
        case PROJECT_PLANNING_CALENDAR_CHANGE_DATE:
            return {
                ...state,
                activePlanningDate: action.date,
            };

        case PROJECT_PLANNING_CALENDAR_CHANGE_PERIOD:
            return {
                ...state,
                activePlanningPeriod: action.activePeriod,
                activePlanningDate: newDate(),
            };

        case PROJECT_PLANNING_CALENDAR_CHANGE_TYPE:
            return {
                ...state,
                activePlanningType: action.activeType,
            };

        case PROJECT_CALENDAR_CHANGE_RESPONSIBLE:
            return {
                ...state,
                responsibleUserId: action.userId,
            };

        case PROJECT_CALENDAR_CHANGE_SEARCH:
            return {
                ...state,
                searchValue: action.searchValue,
            };

        case PROJECT_CLOSED: {
            const projects = [...state.ongoingProjects];
            const idx = projects.findIndex(p => p.id === action.id);

            if (idx > -1) {
                projects.splice(idx, 1);
            }

            return {
                ...state,
                ongoingProjects: projects,
                projectsById: normalizeProjects([
                    ...projects,
                    ...state.closedProjects,
                ]),
            };
        }

        case PROJECT_CHANGED: {
            const projects = [...state.ongoingProjects];
            const idx = projects.findIndex(p => p.id === action.project.id);

            if (idx > -1) {
                projects[idx] = action.project;
            }

            return {
                ...state,
                ongoingProjects: projects,
                projectsById: normalizeProjects([
                    ...projects,
                    ...state.closedProjects,
                ]),
            };
        }

        case PROJECT_REOPENED: {
            const projects = [...state.closedProjects];
            const idx = projects.findIndex(p => p.id === action.project.id);

            if (idx > -1) {
                projects.splice(idx, 1);
            }

            const ongoing = [...state.ongoingProjects, action.project];

            return {
                ...state,
                closedProjects: projects,
                ongoingProjects: ongoing,
                projectsById: normalizeProjects([...projects, ...ongoing]),
            };
        }

        case FETCH_ONGOING_PROJECTS:
            return { ...state, isLoadingOngoing: true };

        case FETCH_ONGOING_PROJECTS_FULFILLED:
            return {
                ...state,
                isLoadingOngoing: false,
                ongoingProjects: action.projects,
                projectsById: normalizeProjects([
                    ...action.projects,
                    ...state.closedProjects,
                ]),
            };

        case FETCH_ONGOING_PROJECTS_REJECTED:
            return { ...state, isLoadingOngoing: false };

        case FETCH_CLOSED_PROJECTS:
            return { ...state, isLoadingClosed: true };

        case FETCH_CLOSED_PROJECTS_FULFILLED:
            return {
                ...state,
                isLoadingClosed: false,
                closedProjects: action.projects,
                projectsById: normalizeProjects([
                    ...state.ongoingProjects,
                    ...action.projects,
                ]),
            };

        case FETCH_CLOSED_PROJECTS_REJECTED:
            return { ...state, isLoadingClosed: false };

        default: {
            return state;
        }
    }
};

const normalizeProjects = projects => {
    const normalized = {};

    projects.forEach(p => (normalized[p.id] = p));

    return normalized;
};
