import React, { FC } from 'react';

interface Props {
    name: string,
    style?: object,
    className?: string
};

const Icon: FC<Props> = ({ name, style, className }) => (
    <i className={`icon ${name}${className ? ` ${className}` : ''}`} style={style}></i>
);

export default Icon;