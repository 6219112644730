import { normalize } from 'components/main/time-reporting/timeReportingUtils';
import { newDate } from './DateUtils';


export const getTranslatedTimeString = (t, hours = 0, minutes = 0, ignoreZeroMins = true) => {

    return `${t('ui:TIME_HOURS', { count: hours })}${ignoreZeroMins && minutes === 0 ? '' : ` ${t('ui:TIME_MINUTES',
        { count: (minutes || 0).toString().padStart(2, '0') })}`}`;
}

export const getTranslatedTimeStringForTimeObject = (t, time, ignoreZeroMins = true) => {
    const { hours, minutes } = normalize(time)

    return getTranslatedTimeString(t, hours || 0, minutes || 0, ignoreZeroMins);
}
    

export const getTranslatedTimeStringFromHours = (t, hours) => {

    if (!hours) {
        return getTranslatedTimeString(t, 0, 0);
    }

    const minutes = (hours % 1) * 60;

    return getTranslatedTimeString(t, Math.floor(hours), Math.floor(minutes));
}

export const getTimeReportsLockedSinceDateExclusive = dayOfMonth => {
    if (!dayOfMonth) {
        return null;
    }

    const today = newDate();

    if (dayOfMonth >= today.date()) {

        return today.subtract(1, 'month').startOf('month').valueOf();
    }

    return today.startOf('month').valueOf();
}

export const toHoursSummed = ({ hours = 0, minutes = 0 }) => Math.ceil(hours + (minutes / 60));

export const toHours = ({ hours = 0, minutes = 0 }) => hours + (minutes / 60);