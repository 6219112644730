import doorautomaticsService from '../../services/doorautomatics/doorautomaticsService';

export const FETCH_DOORAUTOMATICS = 'FETCH_DOORAUTOMATICS';
export const FETCH_DOORAUTOMATICS_FULFILLED = 'FETCH_DOORAUTOMATICS_FULFILLED';
export const FETCH_DOORAUTOMATICS_REJECTED = 'FETCH_DOORAUTOMATICS_REJECTED';
export const DOORAUTOMATICS_CHANGED = 'DOORAUTOMATICS_CHANGED';
export const DOORAUTOMATICS_DELETED = 'DOORAUTOMATICS_DELETED';
export const DOORAUTOMATICS_TABLE_STATE_CHANGE = 'DOORAUTOMATICS_TABLE_STATE_CHANGE';
export const DOORAUTOMATICS_FILTERS_CHANGE = 'DOORAUTOMATICS_FILTERS_CHANGE';

const requestDoorautomatics = () => ({ type: FETCH_DOORAUTOMATICS });
const requestDoorautomaticsFulfilled = doorautomatics => ({ type: FETCH_DOORAUTOMATICS_FULFILLED, doorautomatics });
const requestDoorautomaticsRejected = error => ({ type: FETCH_DOORAUTOMATICS_REJECTED, error });

export const doorautomaticsTableStateChange = state => ({ type: DOORAUTOMATICS_TABLE_STATE_CHANGE, state });

export const doorautomaticsFiltersChange = (filterName, value) => ({
    type: DOORAUTOMATICS_FILTERS_CHANGE,
    filterName,
    value
});

export const doorautomaticChanged = doorautomatic => ({
    type: DOORAUTOMATICS_CHANGED,
    doorautomatic
});

export const doorautomaticDeleted = id => ({
    type: DOORAUTOMATICS_DELETED,
    id
})

export const fetchDoorautomatics = () => async dispatch => {

    dispatch(requestDoorautomatics());

    try {
        const doorautomatics = await doorautomaticsService.fetch();

        dispatch(requestDoorautomaticsFulfilled(doorautomatics));
    } catch (err) {
        console.error(err);
        dispatch(requestDoorautomaticsRejected(err));
    }
};