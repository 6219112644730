import Icon from 'components/common/icon';
import { useNotifications } from 'components/common/use-notifications';
import workOrderService from 'data/services/workorder/workOrderService';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import {
    workOrderBroadcastEvents,
    workOrderBroadcastStatuses,
} from 'utils/constants/workOrderBroadcastEvents';

const WorkOrderBroadcastOrderFinishedModal = ({ workOrder, onHandled, onClose }) => {
    const [loading, setLoading] = useState(null);
    const { t } = useTranslation(['ui']);

    const { showErrorNotification } = useNotifications();

    const handleBroadcast = async status => {
        try {
            setLoading(status);

            await workOrderService.broadcast(
                workOrder,
                workOrderBroadcastEvents.ORDERFINISH,
                status
            );

            onHandled();
            onClose();
        } catch (err) {
            console.error(err);
            showErrorNotification('FAILED_TO_SEND_EMAIL');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <div>{t('ui:WORK_ORDER_ORDER_FINISH_CONFIRMATION')}</div>
            <div>
                <Icon name="mail"></Icon>{' '}
                {`${t('ui:WORK_ORDER_ORDER_FINISH_CONFIRMATION_RECIPIENT')}: ${workOrder.contactEmail}`}
            </div>
            <div
                className="man-flex-row man-flex-row-sb"
                style={{
                    marginTop: 20,
                }}>
                <Button
                    style={{
                        minWidth: '180px',
                        opacity: loading ? 0.5 : 1,
                        pointerEvents: loading ? 'none' : 'default',
                    }}
                    onClick={() =>
                        handleBroadcast(workOrderBroadcastStatuses.SEND)
                    }
                    loading={loading === workOrderBroadcastStatuses.SEND}
                    color="green">
                    <Icon name="check" />{' '}
                    {t('ui:WORK_ORDER_ORDER_CONFIRMATION_SEND')}
                </Button>
                <Button
                    style={{
                        minWidth: '180px',
                        opacity: loading ? 0.5 : 1,
                        pointerEvents: loading ? 'none' : 'default',
                    }}
                    onClick={() =>
                        handleBroadcast(workOrderBroadcastStatuses.IGNORE)
                    }
                    loading={loading === workOrderBroadcastStatuses.IGNORE}
                    color="red">
                    <Icon name="remove" />
                    {t('ui:WORK_ORDER_ORDER_CONFIRMATION_IGNORE')}
                </Button>
            </div>
        </div>
    );
};

export default WorkOrderBroadcastOrderFinishedModal;
