import { calendarPeriod } from 'utils/CalendarUtils.js';
import projectService from '../../services/project/projectService';
import { storeHolder } from '../../stores/storeHolder.js';

export const projectPlanningType = {
    PROJECTS: 'projects',
    RESOURCES: 'resources'
}

export const PROJECT_CHANGED = 'PROJECT_CHANGED';
export const PROJECT_CLOSED = 'PROJECT_CLOSED';
export const PROJECT_REOPENED = 'PROJECT_REOPENED';
export const PROJECT_DELETED = 'PROJECT_DELETED';
export const PROJECT_CALENDAR_CHANGE_DATE = 'PROJECT_CALENDAR_CHANGE_DATE';
export const PROJECT_CALENDAR_CHANGE_PERIOD = 'PROJECT_CALENDAR_CHANGE_PERIOD';
export const PROJECT_CALENDAR_CHANGE_TYPE = 'PROJECT_CALENDAR_CHANGE_TYPE';
export const PROJECT_CALENDAR_CHANGE_SORT = 'PROJECT_CALENDAR_CHANGE_SORT';
export const PROJECT_CALENDAR_CHANGE_REGION = 'PROJECT_CALENDAR_CHANGE_REGION';


export const PROJECT_PLANNING_CALENDAR_CHANGE_DATE = 'PROJECT_PLANNING_CALENDAR_CHANGE_DATE';
export const PROJECT_PLANNING_CALENDAR_CHANGE_PERIOD = 'PROJECT_PLANNING_CALENDAR_CHANGE_PERIOD';
export const PROJECT_PLANNING_CALENDAR_CHANGE_TYPE = 'PROJECT_PLANNING_CALENDAR_CHANGE_TYPE';

export const PROJECT_CALENDAR_CHANGE_RESPONSIBLE = 'PROJECT_CALENDAR_CHANGE_RESPONSIBLE';

export const PROJECT_CALENDAR_CHANGE_SEARCH = 'PROJECT_CALENDAR_CHANGE_SEARCH';

export const FETCH_ONGOING_PROJECTS = 'FETCH_ONGOING_PROJECTS';
export const FETCH_ONGOING_PROJECTS_FULFILLED = 'FETCH_ONGOING_PROJECTS_FULFILLED';
export const FETCH_ONGOING_PROJECTS_REJECTED = 'FETCH_ONGOING_PROJECTS_REJECTED';

export const FETCH_CLOSED_PROJECTS = 'FETCH_CLOSED_PROJECTS';
export const FETCH_CLOSED_PROJECTS_FULFILLED = 'FETCH_CLOSED_PROJECTS_FULFILLED';
export const FETCH_CLOSED_PROJECTS_REJECTED = 'FETCH_CLOSED_PROJECTS_REJECTED';

export const RESOURCE_CALENDAR_SEQUENCE_UPDATE = 'RESOURCE_CALENDAR_SEQUENCE_UPDATE';

export const PROJECT_GLOBAL_ACTIVE_INVOICE_CHANGED = 'PROJECT_GLOBAL_ACTIVE_INVOICE_CHANGED';

export const updateProjectsResourceCalendarSequence = () => ({
    type: RESOURCE_CALENDAR_SEQUENCE_UPDATE
});

export const updateGlobalActiveProjectInvoice = invoiceId => ({
    type: PROJECT_GLOBAL_ACTIVE_INVOICE_CHANGED,
    invoiceId
});

const startFetchOngoingProjects = () => ({
    type: FETCH_ONGOING_PROJECTS
});

const fetchOngoingProjectsFulfilled = projects => ({
    type: FETCH_ONGOING_PROJECTS_FULFILLED,
    projects
});

const fetchOngoingProjectsRejected = err => ({
    type: FETCH_ONGOING_PROJECTS_REJECTED,
    err
});

const startFetchClosedProjects = () => ({
    type: FETCH_CLOSED_PROJECTS
});

const fetchClosedProjectsFulfilled = projects => ({
    type: FETCH_CLOSED_PROJECTS_FULFILLED,
    projects
});

const fetchClosedProjectsRejected = err => ({
    type: FETCH_CLOSED_PROJECTS_REJECTED,
    err
});

const projectPlanningCalendarChangeDate = date => ({ type: PROJECT_PLANNING_CALENDAR_CHANGE_DATE, date });

const projectCalendarChangeDate = date => ({ type: PROJECT_CALENDAR_CHANGE_DATE, date });

export const projectChanged = project => ({
    type: PROJECT_CHANGED,
    project
});

export const projectDeleted = id => ({
    type: PROJECT_DELETED,
    id
});

export const projectClosed = id => ({
    type: PROJECT_CLOSED,
    id
});

export const projectReopened = project => ({
    type: PROJECT_REOPENED,
    project
});

export const projectChangeActiveType = activeType => dispatch => dispatch({ type: PROJECT_CALENDAR_CHANGE_TYPE, activeType });

export const projectChangeSort = sort => dispatch => dispatch({ type: PROJECT_CALENDAR_CHANGE_SORT, sort });

export const projectChangeRegion = region => dispatch => dispatch({ type: PROJECT_CALENDAR_CHANGE_REGION, region });

export const projectPlanningCalendarChangeActiveType = activeType => dispatch => dispatch({ type: PROJECT_PLANNING_CALENDAR_CHANGE_TYPE, activeType });

export const projectChangeResponsibleUser = userId => dispatch => dispatch({ type: PROJECT_CALENDAR_CHANGE_RESPONSIBLE, userId });

export const projectChangeSearch = searchValue => dispatch => dispatch({ type: PROJECT_CALENDAR_CHANGE_SEARCH, searchValue });

export const projectChangePeriod = activePeriod => dispatch => dispatch({ type: PROJECT_CALENDAR_CHANGE_PERIOD, activePeriod });

export const projectPlanningCalendarChangePeriod = activePeriod => dispatch => dispatch({ type: PROJECT_PLANNING_CALENDAR_CHANGE_PERIOD, activePeriod });

export const projectCalendarNextPeriod = () => dispatch => {
    const { activeDate, activePeriod } = storeHolder.getStore().getState().projects;

    let months = 1;

    if (activePeriod === calendarPeriod.QUARTER) {
        months = 3;
    } else if (activePeriod === calendarPeriod.HALF_YEAR) {
        months = 6;
    } else if (activePeriod === calendarPeriod.YEAR) {
        months = 12;
    }

    const date = activeDate.clone().add(months, 'month');
    dispatch(projectCalendarChangeDate(date));
}

export const projectCalendarPreviousPeriod = () => dispatch => {
    const { activeDate, activePeriod } = storeHolder.getStore().getState().projects;

    let months = 1;

    if (activePeriod === calendarPeriod.QUARTER) {
        months = 3;
    } else if (activePeriod === calendarPeriod.HALF_YEAR) {
        months = 6;
    } else if (activePeriod === calendarPeriod.YEAR) {
        months = 12;
    }

    const date = activeDate.clone().subtract(months, 'month');
    dispatch(projectCalendarChangeDate(date));
}

export const projectPlanningCalendarNextPeriod = () => dispatch => {
    const { activePlanningDate, activePlanningPeriod } = storeHolder.getStore().getState().projects;

    const date = activePlanningDate.clone().add(1, activePlanningPeriod);
    dispatch(projectPlanningCalendarChangeDate(date));
}

export const projectPlanningCalendarPreviousPeriod = () => dispatch => {
    const { activePlanningDate, activePlanningPeriod } = storeHolder.getStore().getState().projects;

  
    const date = activePlanningDate.clone().subtract(1, activePlanningPeriod);
    dispatch(projectPlanningCalendarChangeDate(date));
}


export const fetchOngoingProjects = () => async dispatch => {
    try {
        dispatch(startFetchOngoingProjects());

        const projects = await projectService.fetchOngoing();

        dispatch(fetchOngoingProjectsFulfilled(projects));
    } catch (err) {
        console.error(err);
        dispatch(fetchOngoingProjectsRejected(err));
    }
}

export const fetchClosedProjects = () => async dispatch => {
    try {
        dispatch(startFetchClosedProjects());

        const projects = await projectService.fetchClosed();

        dispatch(fetchClosedProjectsFulfilled(projects));
    } catch (err) {
        console.error(err);
        dispatch(fetchClosedProjectsRejected(err));
    }
}