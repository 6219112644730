import { defaultDateSort } from 'components/common/datatable/defaults';
import { normalize } from 'components/main/time-reporting/timeReportingUtils';
import moment from 'moment';
import React from 'react';
import { advancedSchedulingTypes, schedulingTypes } from './constants';
import './moment';
import { getTranslatedTimeStringForTimeObject } from './TimeUtils';

export const newDate = () => {
    const res = moment().locale('abc');

    return res;
};

export const today = newDate();

export const tomorrow = newDate().add(1, 'day');

export const yesterday = newDate().subtract(1, 'day');

export const formatTimeSince = (dateString, maxDiff = 7) => {
    const date = toDate(dateString);
    const diff = today.diff(date, 'days');
    return diff < maxDiff ? date.fromNow() : formatDateTimeVerbose(dateString);
};

export const toDateUnix = date => moment(date).locale('abc');

export const toDate = dateString => moment(dateString).locale('abc');

export const dateFromYearAndWeekNr = (year, weekNr) =>
    newDate().day(1).year(year).isoWeek(weekNr);

export const parseDate = (dateString, format) => moment(dateString, format);

export const sortByIntakeTs = (wo1, wo2) =>
    toDate(wo1.intakeTs).isBefore(toDate(wo2.intakeTs)) ? 1 : -1;

export const formatDate = (str, format) => {
    if (!str) {
        return '-';
    }

    const date = toDate(str);

    if (!date.isValid()) {
        return '-';
    }

    return date.format(format);
};

export const formatMonthVerbose = d => toDate(d).format(`MMM`);

export const formatDateVerboseShort = str => {
    if (!str) {
        return '';
    }

    const d = toDate(str);

    if (!d.isValid()) {
        return '';
    }

    return d.format(`Do MMM`);
};

export const formatDateVerbose = str => {
    if (!str) {
        return '';
    }

    const d = toDate(str);

    if (!d.isValid()) {
        return '';
    }

    if (d.year() === moment().year()) {
        return d.format(`Do MMM`);
    }

    return d.format(`Do MMM YYYY`);
};

export const formatDayOfWeekShort = str => {
    if (!str) {
        return '';
    }

    const d = toDate(str);

    if (!d.isValid()) {
        return '';
    }

    return d.format(`ddd`);
};

export const formatDateVerboseFull = str => {
    if (!str) {
        return '';
    }

    const d = toDate(str);

    if (!d.isValid()) {
        return '';
    }

    return d.format(`Do MMM YYYY`);
};

export const formatDateTime = (str, format) => {
    if (!str) {
        return '';
    }
    
    const d = toDate(str);

    if (!d.isValid()) {
        return '';
    }

    return d.format(`${format} HH:mm`);
};

export const formatDateTimeVerbose = str => {
    if (!str) {
        return '';
    }

    const d = toDate(str);

    if (!d.isValid()) {
        return '';
    }

    if (d.year() === today.year()) {
        return d.format(`Do MMM HH:mm`);
    }

    return d.format(`Do MMM YYYY, HH:mm`);
};

export const formatTime = date => {
    if (!date) {
        return '';
    }

    const d = moment(date);

    if (d.isValid()) {
        return d.format('HH:mm');
    }

    return '';
};

export const getDateFromTime = (date, timeString) => {
    return parseDate(
        `${formatDate(date, 'MM-DD-YYYY')} ${timeString}`,
        'MM-DD-YYYY HH:mm'
    );
};

export const formatToEpoch = dateString => moment(dateString).valueOf();

export const formatYear = date => moment(date).format('YYYY');

export const groupFollowingDates = timeReports => {
    const dates = timeReports
        .map(time => {
            time.date = time.startTs || time.endTs;

            return time;
        })
        .filter(t => t.date);

    const sorted = (dates || []).sort(defaultDateSort('date')).reverse();

    const res = [];

    let i = 0;
    while (i < sorted.length) {
        const start = sorted[i];
        let end = sorted[i];

        let j = 1;

        if (start.type === advancedSchedulingTypes.HOURS) {
            while (j < sorted.length) {
                if (sorted.length > i + j) {
                    const temp = sorted[i + j];

                    if (temp.type !== advancedSchedulingTypes.HOURS) {
                        break;
                    }

                    if (
                        !isSameDate(
                            toDateUnix(start.date).add(j, 'day'),
                            toDateUnix(temp.date)
                        )
                    ) {
                        break;
                    } else {
                        end = sorted[i + j];
                    }
                } else {
                    break;
                }

                j++;
            }
        }

        if (isSameDate(start.date, end.date)) {
            if (start.type !== advancedSchedulingTypes.HOURS) {
                res.push(
                    `${formatDateTimeVerbose(start.startTs)} - ${formatTime(
                        start.endTs
                    )}`
                );
            } else {
                res.push(formatDateVerbose(start.date));
            }
        } else {
            res.push(
                `${toDate(start.date).date()} - ${formatDateVerbose(end.date)}`
            );
        }

        i += j;
    }

    return res;
};

export const getWeekOfYear = date => moment(date).isoWeek();

export const getWeekYear = date => moment(date).isoWeekYear();

export const getYear = date => moment(date).year();

export const isSameDate = (date1, date2) =>
    moment(date1).isSame(moment(date2), 'day');

export const isBefore = (date1, date2) =>
    moment(date1).isBefore(moment(date2), 'day');

export const isDateThisYear = date => moment(date).isSame(today, 'year');

export const isBetween = (date, start, end) =>
    moment(date).isBetween(moment(start), moment(end));

export const isBetweenDays = (date, start, end) =>
    moment(date).isBetween(
        moment(start).startOf('day'),
        moment(end).endOf('day')
    );

export const isSameYear = (date1, date2) =>
    moment(date1).isSame(moment(date2), 'year');

export const isSameYearWeek = (date1, date2) =>
    isSameYear(date1, date2) && getWeekOfYear(date1) === getWeekOfYear(date2);

export const daysDiff = (date1, date2) => {
    const val = moment.duration(toDate(date1).diff(toDate(date2))).asDays();

    return Math.abs(val);
};

export const workdaysDiff = (date1, date2) => {
    let day = toDate(date2);
    let day2 = toDate(date1);
    let businessDays = 0;

    while (day.isBefore(day2, 'day')) {
        if (day.day() != 0 && day.day() != 6) businessDays++;
        day.add(1, 'd');
    }

    return businessDays;
};

export const addWorkDays = (date, count) => {
    let i = 0;

    let res = toDate(date);
    while (i < count) {
        res = res.add(1, 'day');

        while (res.day() === 0 || res.day() === 6) {
            res = res.add(1, 'day');
        }

        i++;
    }

    return res;
};

export const hoursDiff = (date1, date2) => {
    const val = moment.duration(date1.diff(date2)).asHours();

    return Math.abs(val);
};

export const calculateEventHours = (
    startTs,
    endTs,
    workingHoursStart,
    workingHoursEnd,
    defaultWorkingHours
) => {
    const start = moment(startTs);
    const end = moment(endTs);

    if (isSameDate(start, end)) {
        return hoursDiff(start, end);
    }

    let hours = hoursDiff(
        start,
        parseDate(
            `${formatDate(start, 'MM-DD-YYYY')} ${workingHoursEnd}`,
            'MM-DD-YYYY HH:mm'
        )
    );

    start.add(1, 'day');
    while (!isSameDate(start, end)) {
        hours += defaultWorkingHours;
        start.add(1, 'day');
    }

    hours += hoursDiff(
        end,
        parseDate(
            `${formatDate(end, 'MM-DD-YYYY')} ${workingHoursStart}`,
            'MM-DD-YYYY HH:mm'
        )
    );

    return hours;
};

export const getScheduledHoursForDate = (
    e,
    date,
    workingHoursStart,
    workingHoursEnd,
    defaultWorkingHours,
    schedulingType
) => {
    if (schedulingType === schedulingTypes.ADVANCED) {
        if (e.type === advancedSchedulingTypes.HOURS) {
            return (e.hours || 0) + (e.minutes || 0) / 60;
        }

        return hoursDiff(moment(e.startTs), moment(e.endTs));
    }

    const eventStartTs = e.startTs;
    const eventEndTs = e.endTs;

    if (isSameDate(eventStartTs, eventEndTs)) {
        return hoursDiff(moment(eventStartTs), moment(eventEndTs));
    }

    if (isSameDate(date, eventStartTs)) {
        return hoursDiff(
            moment(eventStartTs),
            parseDate(
                `${formatDate(eventStartTs, 'MM-DD-YYYY')} ${workingHoursEnd}`,
                'MM-DD-YYYY HH:mm'
            )
        );
    }

    if (isSameDate(date, eventEndTs)) {
        return hoursDiff(
            moment(eventEndTs),
            parseDate(
                `${formatDate(eventEndTs, 'MM-DD-YYYY')} ${workingHoursStart}`,
                'MM-DD-YYYY HH:mm'
            )
        );
    }

    return defaultWorkingHours;
};

export const getTimesStringForEvent = (
    e,
    date,
    workingHoursStart,
    workingHoursEnd,
    schedulingType,
    t
) => {
    const startTs = e.startTs;
    const endTs = e.endTs;

    if (schedulingType === schedulingTypes.ADVANCED) {
        if (e.type === advancedSchedulingTypes.HOURS) {
            return getTranslatedTimeStringForTimeObject(t, {
                hours: e.hours,
                minutes: e.minutes,
            });
        }
    }

    if (isSameDate(startTs, endTs)) {
        return `${formatTime(startTs)} - ${formatTime(endTs)}`;
    }

    if (isSameDate(date, startTs)) {
        return `${formatTime(startTs)} - ${workingHoursEnd}`;
    }

    if (isSameDate(date, endTs)) {
        return `${workingHoursStart} - ${formatTime(endTs)}`;
    }

    return `${workingHoursStart} - ${workingHoursEnd}`;
};

// 17:30 => { hour: 17, minute: 30 }
export const parseTimeString = s => {
    const splited = s.split(':');

    if (splited.length < 2) {
        return {
            hour: 9,
            minute: 0,
        };
    }

    return {
        hour: parseInt(splited[0]),
        minute: parseInt(splited[1]),
    };
};

// { hour: 17, minute: 30 } => 17:30
export const formatTimeToTimeString = ({ hours, minutes }) => {
    const formatter = new Intl.NumberFormat(undefined, {
        minimumIntegerDigits: 2,
    });
    const { hours: normalizedHours, minutes: normalizedMinutes } = normalize({
        hours,
        minutes,
    });

    return `${normalizedHours}:${formatter.format(normalizedMinutes)}`;
};

export const getThisWeekPeriod = () => {
    const startTime = newDate().startOf('isoWeek').valueOf();
    const endTime = newDate().endOf('isoWeek').valueOf();

    return { startTime, endTime };
};

export const getThisMonthPeriod = () => {
    const startTime = newDate().startOf('month').valueOf();
    const endTime = newDate().endOf('month').valueOf();

    return { startTime, endTime };
};

export const getLastMonthPeriod = () => {
    const startTime = newDate().subtract(1, 'month').startOf('month').valueOf();
    const endTime = newDate().subtract(1, 'month').endOf('month').valueOf();

    return { startTime, endTime };
};

export const getThisYearPeriod = () => {
    const startTime = newDate().startOf('year').valueOf();
    const endTime = newDate().endOf('year').valueOf();

    return { startTime, endTime };
};

export const getSinceLastMonthPeriod = () => {
    const startTime = newDate().subtract(1, 'month').startOf('month').valueOf();
    const endTime = newDate().endOf('month').valueOf();

    return { startTime, endTime };
};

export const getWorkOrderNextActionDate = scheduleReports => {
    if (!scheduleReports) {
        return null;
    }

    const dates = scheduleReports
        .filter(r => r.startTs || r.endTs)
        .map(r => [r.startTs, r.endTs])
        .flat()
        .sort((t1, t2) => {
            if (t1 === t2) return 0;

            if (!t1) return -1;

            if (!t2) return 1;

            if (toDate(t1).isBefore(toDate(t2))) {
                return -1;
            }

            return 1;
        })
        .map(d => toDate(d));

    if (dates.length === 0) {
        return null;
    }

    const nextDate = dates.find(d => d.isAfter(today));

    if (nextDate) {
        return nextDate;
    }

    const reversed = dates.reverse();

    const previousDate = reversed.find(d => d.isBefore(today));

    return previousDate;
};

export const formatDaysTo = (date, t) => {
    if (!date) {
        return null;
    }

    const d = toDate(date);

    if (isSameDate(d, today)) {
        return (
            <div className="man-nextactiondate-container">
                <div className="man-secondary-text">
                    <span
                        style={{
                            marginRight: 5,
                        }}
                        className="ui empty circular orange label"></span>
                    {t('calendar:TODAY')}
                </div>
            </div>
        );
    }

    if (isSameDate(d, tomorrow)) {
        return (
            <div className="man-nextactiondate-container">
                <div className="man-secondary-text">
                    <span
                        style={{
                            marginRight: 5,
                        }}
                        className="ui empty circular orange label"></span>
                    {t('calendar:TOMORROW')}
                </div>
            </div>
        );
    }

    if (isSameDate(d, yesterday)) {
        return (
            <div className="man-nextactiondate-container">
                <div className="man-secondary-text">
                    <span
                        style={{
                            marginRight: 5,
                        }}
                        className="ui empty circular red label"></span>
                    {t('calendar:YESTERDAY')}
                </div>
            </div>
        );
    }

    return (
        <div className="man-nextactiondate-container">
            <div className="man-secondary-text">
                {d.isBefore(today) ? (
                    <span
                        style={{
                            marginRight: 5,
                        }}
                        className="ui empty circular red label"></span>
                ) : (
                    <React.Fragment>
                        {isSameYearWeek(d, today) ? (
                            <span
                                style={{
                                    marginRight: 5,
                                }}
                                className="ui empty circular orange label"></span>
                        ) : (
                            <span
                                style={{
                                    marginRight: 5,
                                }}
                                className="ui empty circular green label"></span>
                        )}
                    </React.Fragment>
                )}
                {d.fromNow()}
            </div>
        </div>
    );
};
