import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { appModules } from '../../../utils/constants/appModules';
import { permission } from '../../../utils/constants/permissions';
import Icon from '../../common/icon';
import Avatar from '../profile/avatar';

class TopbarUserDropdown extends Component {

    render() {
        const { t, hasPermission, hasAppModule, user, hasDashboardAccess, userType, supplierInvoiceCount } = this.props;

        return (
            <Dropdown
                item
                simple
                direction="left"
                style={{ flexDirection: 'row' }}
                trigger={
                    <div className="text">
                        <Avatar
                            hoverable={false}
                            userId={user.id}
                            user={user} />
                        {user.fullName}
                    </div>
                }>

                <Dropdown.Menu>
                    {hasAppModule(appModules.DASHBOARD) && hasPermission(permission.DASHBOARD_READ) && (hasDashboardAccess || userType === 'super') &&
                        <NavLink to="/dashboard" className="item man-small-only">
                            <Icon name="th large" />{t('DASHBOARD')}
                        </NavLink>
                    }


                    {hasAppModule(appModules.SUPPLIER_INVOICES) &&
                        hasPermission(permission.SUPPLIER_INVOICES_READ) && (
                            <NavLink to="/supplierinvoices" className="item man-small-only">
                                <Icon name="shopping cart" />{t('TOPBAR_SUPPLIER_INVOICES')}
                                {!supplierInvoiceCount ?
                                    null
                                    :
                                    <div className="ui red label"
                                        style={{
                                            float: 'right'
                                        }}>
                                        {supplierInvoiceCount > 100 ? '99+' : supplierInvoiceCount}
                                    </div>
                                }
                            </NavLink>
                        )
                    }

                    {hasAppModule(appModules.DEVIATIONS) && hasPermission(permission.DEVIATIONS_READ) &&
                        <NavLink to="/deviations" className="item man-small-only">
                            <Icon name="certificate" />{t('DEVIATIONS')}
                        </NavLink>
                    }

                    {hasAppModule(appModules.PROJECTS) && hasPermission(permission.PROJECTS_READ) &&
                        <NavLink to="/projects" className="item man-small-only">
                            <Icon name="sitemap" />{t('PROJECTS')}
                        </NavLink>
                    }

                    {hasAppModule(appModules.CRM) && hasPermission(permission.PROPOSAL_READ) &&
                        <NavLink to="/proposals" className="item man-small-only">
                            <Icon name="handshake outline" />{t('PROPOSALS')}
                        </NavLink>}

                    {hasAppModule(appModules.CRM) && hasPermission(permission.SALES_ORDER_READ) &&
                        <NavLink to="/sales" className="item man-small-only">
                            <Icon name="copy outline" />{t('SALES_ORDERS')}
                        </NavLink>
                    }

                    <NavLink to="/time" className="item man-small-only">
                        <Icon name="clock outline" />{this.props.t('TIME_REPORTING')}
                    </NavLink>

                    <NavLink exact to="/" className="item man-small-only">
                        <Icon name="tasks" />{this.props.t('WORK_ORDERS')}
                    </NavLink>

                    {this.props.hasPermission(permission.REPORTS_READ) &&
                        <NavLink to="/reports" className="item man-small-only">
                            <Icon name="chart line" />{this.props.t('STATISTICS')}
                        </NavLink>
                    }

                    <NavLink to="/inventory" className="item man-small-only">
                        <Icon name="file alternate outline" />{this.props.t('INVENTORY')}
                    </NavLink>


                    <NavLink to="/profile" className="item">
                        <Icon name="user" />{this.props.t('EDIT_PROFILE')}
                    </NavLink>

                    {this.props.hasPermission(permission.SETTINGS_READ) &&
                        <NavLink to="/settings" className="item">
                            <Icon name="setting" />{this.props.t('SETTINGS')}
                        </NavLink>
                    }

                    <a onClick={this.props.logout} className="item">
                        <Icon name="logout" />{this.props.t('LOG_OUT')}
                    </a>
                </Dropdown.Menu>
            </Dropdown >
        )
    }
}

TopbarUserDropdown.propTypes = {
    t: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    departments: PropTypes.array.isRequired,
    hasPermission: PropTypes.func.isRequired,
    supplierInvoiceCount: PropTypes.number
}

const mapStateToProps = state => ({
    hasDashboardAccess: state.ui.hasDashboardAccess,
    userType: state.auth.userType
});

export default connect(mapStateToProps)(withRouter(TopbarUserDropdown));

