
import supplierService from '../../services/supplier/supplierService';

export const FETCH_SUPPLIERS = 'FETCH_SUPPLIERS';
export const FETCH_SUPPLIERS_FULFILLED = 'FETCH_SUPPLIERS_FULFILLED';
export const FETCH_SUPPLIERS_REJECTED = 'FETCH_SUPPLIERS_REJECTED';
export const SUPPLIER_CHANGED = 'SUPPLIER_CHANGED';
export const SUPPLIER_DELETED = 'SUPPLIER_DELETED';
export const SUPPLIER_TABLE_STATE_CHANGE = 'SUPPLIER_TABLE_STATE_CHANGE';
export const SUPPLIER_FILTERS_CHANGE = 'SUPPLIER_FILTERS_CHANGE';

const requestSuppliers = () => ({ type: FETCH_SUPPLIERS });
const requestSuppliersFulfilled = suppliers => ({ type: FETCH_SUPPLIERS_FULFILLED, suppliers });
const requestSuppliersRejected = error => ({ type: FETCH_SUPPLIERS_REJECTED, error });

export const supplierTableStateChange = state => ({ type: SUPPLIER_TABLE_STATE_CHANGE, state });

export const supplierFiltersChange = filters => ({
    type: SUPPLIER_FILTERS_CHANGE,
    filters
});

export const supplierChanged = supplier => ({
    type: SUPPLIER_CHANGED,
    supplier
});

export const supplierDeleted = id => ({
    type: SUPPLIER_DELETED,
    id
})

export const fetchSuppliers = () => async dispatch => {

    dispatch(requestSuppliers());

    try {
        const suppliers = await supplierService.fetch();
        dispatch(requestSuppliersFulfilled(suppliers));
    } catch (err) {
        console.error(err);
        dispatch(requestSuppliersRejected(err));
    }
};