export const ee_machine = {
    ui: {
        SETTINGS_BUSINESS_OBJECT: 'Masin',
        OBJECTS: 'Masinad',
        MANAGE_OBJECTS: 'Vaata/muuda masinaid',
    },
    form: {
        OBJECT: 'Masin',
    },
    acl: {
        PERMISSION_BUSINESS_OBJECTS_READ: 'Näha masinaid',
        PERMISSION_BUSINESS_OBJECTS_EDIT: 'Muuta masinaid',
    }
}