import FilesContainer from 'components/common/files';
import { withPermissions } from 'components/common/with-permissions/WithPermissions';
import fileService from 'data/services/file/fileService';
import PropTypes from 'prop-types';
import React from 'react';  

const ProposalFiles = ({ id, editable }) => (
    <FilesContainer
        id={id}
        fetchFunc={fileService.fetchProposalFiles}
        uploadFunc={fileService.uploadProposalFiles}
        deleteFunc={fileService.removeProposalFile}
        detailsFunc={fileId => fileService.fetchProposalFile(id, fileId)}
        renameFunc={(fileId, fileName) => fileService.renameProposalFile(id, fileId, fileName)}
        moveFunc={(fileId, folderId) => fileService.moveProposalFileToFolder(id, fileId, folderId)}
        reorderFunc={(fileId, sortOrder) => fileService.reorderProposalFile(id, fileId, sortOrder)}
        editable={editable}
        foldersEnabled={false}
    />
);

const ProposalFilesContainer = ({ id }) => {

    return <ProposalFiles editable={true} id={id} />;
};

ProposalFilesContainer.propTypes = {
    id: PropTypes.string.isRequired,
};

export default withPermissions(ProposalFilesContainer);
