import React, { Component } from 'react';
import './Padding.css';

class Padding extends Component {

    render() {
        return (
            <div className={`man-padding${this.props.className ? ` ${this.props.className}` : ''}`} style={this.props.style}>
                {this.props.children}
            </div>
        )
    }
}

export default Padding;