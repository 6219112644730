import _ from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import customerService from '../../../data/services/customer/customerService';
import LocalizedSearch from '../../common/localized-search';
import './CustomerPicker.css';

const CustomerSearchOption = ({ t, type, displayName, customerId, locationText, locationText2, locationText3, showProposalCount, proposalCount }) => {
    if (type === 'add') {
        return (
            <div className="ui feed">
                <div className="event">
                    <div className="content">
                        <div>
                            <a className="user man-secondary-text man-internal-link">
                                {t('ui:CUSTOMERPICKER_CREATE_NEW_CUSTOMER')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className="ui feed">
            <div className="event">
                <div className="content">
                    <div className="summary man-flex-row man-flex-row-sb" style={{
                        alignItems: 'flex-end'
                    }}>
                        <a className="user">
                            {[customerId ? `${customerId}` : `####`, displayName].join(', ')}
                        </a>

                        <div>
                            {showProposalCount && proposalCount > 0 &&
                                <div className="ui label circular tiny" style={{
                                    backgroundColor: 'rgb(165, 204, 107)',
                                    color: 'white'
                                }}>
                                    {t('ui:CUSTOMER_HAS_OPEN_PROPOSALS', {
                                        count: proposalCount,
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="meta" style={{ margin: 0 }}>
                        <a className="like">
                            {[locationText2, locationText3, locationText].filter(v => v).join(', ')}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

class CustomerPicker extends Component {
    state = {
        isLoading: false,
        searchValue: this.props.initialValue,
        searchResults: [],
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.clear) {
            this.setState({
                searchValue: '',
            });
        }

        if (newProps.value) {
            this.setState({
                searchValue: newProps.value,
            });
        }
    }

    componentDidMount() {
        if (this.props.focusOnMount) {
            this.handleMount();
        }
    }

    handleMount = () => {
        const ref = this.searchInputRef.querySelector(
            '.ui.search .ui.input input'
        );

        ref && ref.focus();
    };

    handleChange = async (e, { value }) => {
        if (!value) {
            this.props.onCustomerChange({ id: null });

            this.setState({
                isLoading: false,
                searchValue: '',
            });
            return;
        }

        this.setState({
            isLoading: true,
            searchValue: value,
        });

        this.queryCustomers(value);
    };

    queryCustomers = _.debounce(async value => {
        try {
            const customers = await customerService.query(value);

            this.setState({
                isLoading: false,
                searchResults: customers,
            });
        } catch (err) {
            this.setState({
                isLoading: false,
            });
        }
    }, 350);

    handleSelect = (e, { result }) => {
        if (result && result.type === 'add') {
            this.props.history.push(`/inventory/customers`);
            return;
        }

        this.props.onCustomerChange(result);

        this.setState({
            searchValue: result.displayName,
            searchResults: [],
        });
    };

    renderSearchResult = customer => (
        <CustomerSearchOption key={customer.id} {...customer} showProposalCount={this.props.showProposalCount} t={this.props.t} />
    );

    render() {
        return (
            <div
                className="man-std-customerpicker"
                ref={node => (this.searchInputRef = node)}>
                <LocalizedSearch
                    placeholder={this.props.t('CUSTOMERPICKER_SELECT')}
                    results={[...this.state.searchResults, { type: 'add' }]}
                    loading={this.state.isLoading}
                    onResultSelect={this.handleSelect}
                    onSearchChange={this.handleChange}
                    value={this.state.searchValue}
                    resultRenderer={this.renderSearchResult}
                    on
                />
            </div>
        );
    }
}

CustomerPicker.propTypes = {
    onCustomerChange: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    value: PropTypes.string,
    focusOnMount: PropTypes.bool,
    showProposalCount: PropTypes.bool
};

CustomerPicker.defaultProps = {
    initialValue: '',
    focusOnMount: false,
    showProposalCount: false
};

export default withRouter(withTranslation('ui')(CustomerPicker));
