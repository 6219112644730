
export default class WorkOrderGetResponse {

    workOrders = [];
    totalCount;

    constructor({ workOrders, totalCount }) {
        this.workOrders = workOrders;
        this.totalCount = totalCount;
    }

}