import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers'

const loggerMiddleware = createLogger();

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStoreForDevelopment = (preloadedState = {}) => {
    const store = createStore(
        rootReducer,
        preloadedState,
        composeEnhancer(
            applyMiddleware(
                thunkMiddleware,
                loggerMiddleware
            )
        )
    )
    return store;
};

const configureStoreForProduction = (preloadedState = {}) => {
    const store = createStore(
        rootReducer,
        preloadedState,
        composeEnhancer(
            applyMiddleware(
                thunkMiddleware
            )
        )
    )
    return store;
};

export const configureStore = process.env.NODE_ENV === 'development' ? configureStoreForDevelopment : configureStoreForProduction;