import qs from 'query-string';
import { deletee, get, patch, post, put } from '../../../utils/http/HttpClient';

export default {
    fetch: async (status) => {
        const query = qs.stringify({
            status
        });

        const { deviations } = await get(`/deviations?${query}`);

        return deviations;
    },
    fetchDetails: async id => {
        const { deviation, relations } = await get(`/deviations/${id}`);

        return {
            deviation,
            relations,
        };
    },
    create: async () => {
        const { id } = await post('/deviations', {});

        return id;
    },
    update: async deviation => {
        await patch('/deviations', deviation);
    },
    updateRelations: async (deviationId, relations) => {
        await put(`/deviations/${deviationId}/relations`, { relations });
    },
    delete: async id => {
        await deletee(`/deviations/${id}`);
    },
    close: async id => {
        await post(`/deviations/${id}/close`, {});

        return id;
    },
    fetchStatsByCategory: async () => {
        return await get(`/deviations/stats/category`);
    },
    fetchStatsByRelation: async () => {
        return await get(`/deviations/stats/relation`);
    },
    fetchStatsLatest: async () => {
        return await get(`/deviations/stats/latest`);
    }
};
