import { deletee, get, post, put } from '../../../utils/http/HttpClient';
import Agreement from './api/Agreement';

export default {
    fetch: async () => {
        const { agreements } = await get('/agreements');

        return agreements.map(a => new Agreement(a));
    },
    fetchDetails: async id => {
        const { agreement } = await get(`/agreements/${id}`);

        return new Agreement(agreement);
    },
    create: async title => {
        const { id } = await post('/agreements', { title });

        return id;
    },
    update: async agreement => {
        await put('/agreements', { agreement });
    },
    delete: async id => {
        await deletee(`/agreements/${id}`);
    },
    fetchUpcomingAgreementInvoices: async () => {
        const { invoices } = await get(`/agreements/invoices/upcoming`);

        return invoices;
    },
    fetchPricing: async id => {
        const { priceConfiguration } = await get(`/agreements/${id}/pricing`);

        return priceConfiguration;
    },
    updatePricing: async (id, priceConfiguration) => {
        await put(`/agreements/${id}/pricing`, priceConfiguration);
    },
    fetchArticles: async id => {
        const { articles } = await get(`/agreements/${id}/articles`);

        return articles;
    },
    createArticle: async ({
        agreementId,
        discountType,
        discountValue,
        articleId,
    }) => {
        const { id } = await post('/agreements/articles', {
            agreementId,
            discountType,
            discountValue,
            articleId,
        });

        return id;
    },
    updateArticle: async ({
        agreementId,
        discountType,
        discountValue,
        articleId,
    }) => {
        await put('/agreements/articles', {
            agreementId,
            discountType,
            discountValue,
            articleId,
        });
    },
    deleteArticle: async ({ agreementId, articleId }) => {
        await deletee(
            `/agreements/${agreementId}/articles?articleId=${articleId}`
        );
    },
};
