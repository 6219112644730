import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';
import { connect } from 'react-redux';
import { getColor } from './TagUtils';

class Tag extends React.PureComponent {

    render() {

        const color = this.props.color ? this.props.color : getColor(this.props.name, this.props.tags);

        return (
            <div className={`ui circular label man-tag${this.props.className ? ` ${this.props.className}` : ''}`}
                style={{ backgroundColor: color, color: '#FFFFFF', cursor: this.props.onClick ? 'pointer' : 'initial' }}
                onClick={this.props.onClick}>
                {this.props.icon && <Icon className="man-noprint" name={this.props.icon} />}
                {this.props.name}
            </div>
        )
    }
}

Tag.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func
}

Tag.defaultProps = {
    icon: null,
    onClick: null
}

const mapStateToProps = state => ({
    tags: state.auth.instance.workOrderConfiguration.tags
})

export default connect(mapStateToProps)(Tag);