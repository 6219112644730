const { useTranslation } = require('react-i18next');

const ProjectInScopeLabel = ({ value }) => {
    const { t } = useTranslation(['ui']);

    if (value === 0) {
        return (
            <span
                className="ui label tiny"
                style={{
                    backgroundColor: 'var(--color-accent)',
                    color: 'white'
                }}>
                {t('ui:PROJECT_INVOICE_ROW_IS_OUT_OF_SCOPE')}
            </span>
        );
    }

    if (value === 1) {
        return (
            <span
                className="ui label tiny"
                style={{
                    backgroundColor: 'var(--color-positive)',
                    color: 'white'
                }}>
                {t('ui:PROJECT_INVOICE_ROW_IS_IN_SCOPE')}
            </span>
        );
    }

    return null;
};

export default ProjectInScopeLabel;
