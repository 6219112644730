import ToggleButtonGroup from 'components/common/toggle-btn-group';
import { Col, Label, Row, SectionValueContainer, Value } from 'components/common/ui';
import TextareaAutosize from 'react-textarea-autosize';

const TextRow = ({ t, data, prop, label, onValueChange, editable }) => {

    const display = data[`${prop}_display`] === true;
    const val = data[`${prop}_value`];

    return (
        <Row>
            <Col>
                <SectionValueContainer>
                    <Label>
                        {label}

                        {editable &&
                            <ToggleButtonGroup
                                style={{
                                    marginLeft: 10,
                                    marginBottom: 4
                                }}
                                toggleButtons={[
                                    { text: t('ui:HIDE'), key: t('ui:HIDE'), value: false },
                                    { text: t('ui:DISPLAY'), key: t('ui:DISPLAY'), value: true }
                                ]}
                                toggeled={display}
                                onClick={v => onValueChange(`${prop}_display`, v)}
                                size='mini'
                            />
                        }
                    </Label>
                    {display &&
                        <Value className="man-ui-wrap-newline">
                            {editable ?
                                <TextareaAutosize
                                    name={`${prop}_value`}
                                    value={
                                        val || ''
                                    }
                                    onChange={e => onValueChange(e.target.name, e.target.value)}
                                />
                                :
                                val
                            }
                        </Value>
                    }
                </SectionValueContainer>
            </Col>
        </Row>
    )

}

const ProposalDetailsCustomEdsvikenslarm = ({ t, isSmallScreen, proposal, editable, data, onDataChange }) => {

    if (!data || !proposal) {
        return null;
    }

    if (!data?.initializedEdsvikenslarm) {
        onDataChange({
            ...data,
            initializedEdsvikenslarm: true,
            row1_display: true,
            row1_value: `Omflyttning av eventuella inventarier
230 v samt internet skall finnas tillgängligt där centralutrustning placeras
Ethernet tillhandahålls av kund
Tillhandahålla eventuella IP-adresser`,

            row2_display: true,
            row2_value: `Larmförmedling till Polis vid A larm
GPRS/GSM abonnemang
Uppkoppling till larmcentral med övervakning och åtgärd enl. larmklass 2
Nyckelförvaring hos Securitas
Fritt reparations material och arbete vid garanti service
Fria väktarutryckningar`,

            row3_display: true,
            row3_value: `Materialgaranti XX månader`,

            row4_display: true,
            row4_value: `Bildmottagning, motringning, larmhantering
Väktaråtgärd med nyckelhantering
Lagring av inkomna larmbilder
Funktionsövervakning av kameror
Materialgaranti 36 månader`,
        });

        return null;
    }

    const handleValueChange = (name, value) => {
        onDataChange({
            ...data,
            [name]: value
        })
    }

    const handleValuesChange = values => {
        onDataChange({
            ...data,
            ...values
        })
    }

    return (
        <div>
            <TextRow t={t}
                data={data}
                editable={editable}
                label={'Följande ska utföras av kund innan installationstillfället'}
                prop={'row1'}
                onValueChange={handleValueChange} />

            <TextRow t={t}
                data={data}
                editable={editable}
                label={'I månadskostnaden för Larm ingår'}
                prop={'row2'}
                onValueChange={handleValueChange} />

            <TextRow t={t}
                data={data}
                editable={editable}
                label={'I kostnaden för Passagesystem ingår'}
                prop={'row3'}
                onValueChange={handleValueChange} />

            <TextRow t={t}
                data={data}
                editable={editable}
                label={'I månadskostnaden för Kamerasystem ingår'}
                prop={'row4'}
                onValueChange={handleValueChange} />
        </div>
    )

}

export default ProposalDetailsCustomEdsvikenslarm;