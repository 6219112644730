import moment from 'moment';
import { timereportTypes } from 'utils/constants';
import { formatTime, parseDate, toDate } from '../../../utils/DateUtils';

export const normalize = value => {
    let { hours, minutes } = value;

    hours = parseInt((hours || 0).toFixed(0));
    minutes = parseInt((minutes || 0).toFixed(0));

    if (minutes < 0) {
        minutes = 60 - Math.abs(minutes);
        hours = hours - 1;
    }

    hours += Math.floor(minutes / 60);
    minutes %= 60;

    return {
        hours,
        minutes,
    };
};

// sort === ['asc', 'desc'], default 'desc'
export const timereportsDefaultSort = (r1, r2, sort) => {
    const d1 = toDate(r1.date);
    const d2 = toDate(r2.date);

    if (r1.startTime) {
        const d = toDate(r1.startTime);
        d1.set('hour', d.hour());
        d1.set('minute', d.minute());
    } else {
        d1.endOf('day');
    }
    if (r2.startTime) {
        const d = toDate(r2.startTime);
        d2.set('hour', d.hour());
        d2.set('minute', d.minute());
    } else {
        d2.endOf('day');
    }

    if (sort === 'asc') {
        return d1.isBefore(d2) ? -1 : 1;
    }

    return d1.isBefore(d2) ? 1 : -1;
};

export const getReportedTotalTime = reports => {
    let total = {
        hours: 0,
        minutes: 0,
    };

    if (!reports || !reports.reports) {
        return total;
    }

    Object.keys(reports.reports).forEach(
        week => (total = add(total, getTotalTime(reports, week)))
    );

    return total;
};

export const getTotalTime = (timeReports, week) => {
    let time = {
        hours: 0,
        minutes: 0,
    };

    if (!timeReports) {
        return time;
    }

    const { reports } = timeReports;

    if (!reports || !reports[week]) {
        return time;
    }

    Object.keys(reports[week]).forEach(dayOfWeek => {
        const events = reports[week][dayOfWeek];

        if (events) {
            events.forEach(event => {
                time = add(time, {
                    hours: event.reportedHours,
                    minutes: event.reportedMinutes,
                });
            });
        }
    });

    return normalize(time);
};

export const getTotalTimeFull = timeReports => {
    let time = {
        hours: 0,
        minutes: 0,
    };

    if (!timeReports) {
        return time;
    }

    const { reports } = timeReports;

    if (!reports) {
        return time;
    }

    Object.keys(reports).forEach(week => {
        Object.keys(reports[week]).forEach(dayOfWeek => {
            const events = reports[week][dayOfWeek];

            if (events) {
                events.forEach(event => {
                    time = add(time, {
                        hours: event.reportedHours,
                        minutes: event.reportedMinutes,
                    });
                });
            }
        });
    });

    return normalize(time);
};

export const getTotalTimeForEntries = entries => {
    let time = {
        hours: 0,
        minutes: 0,
    };

    entries.forEach(event => {
        time = add(time, {
            hours: event.reportedHours,
            minutes: event.reportedMinutes,
        });
    });

    return normalize(time);
};

export const getGroupedByPeriodTotalTime = (
    reports,
    timeReportPeriods = [],
    type = 'weekday',
    reportingType
) => {
    const defaultTotal = {
        hours: 0,
        minutes: 0,
    };

    let grouped = {
        '@@total@@': {
            label: '@@total@@',
            total: { ...defaultTotal },
        },
        '@@workTotal@@': {
            label: '@@workTotal@@',
            total: { ...defaultTotal },
        },
        '@@travelTotal@@': {
            label: '@@travelTotal@@',
            total: { ...defaultTotal },
        },
    };

    const periods = [];

    if (reportingType !== timereportTypes.HOURS) {
        (timeReportPeriods || [])
            .sort((p1, p2) => (p1.startTime > p2.startTime ? 1 : -1))
            .forEach(period => {
                let p1,
                    p2 = null;

                let st = timeStringToInt(period.startTime);
                let et = timeStringToInt(period.endTime);

                if (st > et) {
                    // report is over midnight (23 - 03)
                    p1 = {
                        label: period.label,
                        startTime: st,
                        endTime: 2400,
                    };

                    p2 = {
                        label: period.label,
                        startTime: 0,
                        endTime: et,
                    };
                } else {
                    p1 = {
                        label: period.label,
                        startTime: st,
                        endTime: et,
                    };
                }

                periods.push(p1);

                if (p2) {
                    periods.push(p2);
                }

                const p = {
                    ...period,
                    total: defaultTotal,
                };

                grouped[p.label] = p;
            });
    }

    if (!reports || !reports.reports) {
        return grouped;
    }

    Object.keys(reports.reports).forEach(week => {
        return Object.keys(reports.reports[week]).forEach(dayOfWeek => {
            if (type === 'weekday') {
                if (dayOfWeek == '6' || dayOfWeek == '7') {
                    return;
                }
            }

            if (type === 'weekend') {
                if (dayOfWeek != '6' && dayOfWeek != '7') {
                    return;
                }
            }

            if (reportingType !== timereportTypes.HOURS) {
                const dailyReports = reports.reports[week][dayOfWeek];

                dailyReports.forEach(({ startTime, endTime }) => {
                    if (!startTime || !endTime) {
                        return;
                    }

                    let r1,
                        r2 = null;

                    let st = timeStringToInt(formatTime(startTime));
                    let et = timeStringToInt(formatTime(endTime));

                    if (st > et) {
                        // report is over midnight (23 - 03)
                        r1 = {
                            startTime: st,
                            endTime: 2400,
                        };

                        r2 = {
                            startTime: 0,
                            endTime: et,
                        };
                    } else {
                        r1 = {
                            startTime: st,
                            endTime: et,
                        };
                    }

                    grouped = groupReportToPeriods(periods, grouped, r1);

                    if (r2) {
                        grouped = groupReportToPeriods(periods, grouped, r2);
                    }
                });
            }

            const dailyReports = reports.reports[week][dayOfWeek];

            dailyReports.forEach(
                ({
                    reportedHours,
                    reportedMinutes,
                    reportedWorkHours,
                    reportedWorkMinutes,
                    reportedTravelHours,
                    reportedTravelMinutes,
                }) => {
                    grouped['@@total@@'].total = add(
                        grouped['@@total@@'].total,
                        { hours: reportedHours, minutes: reportedMinutes }
                    );
                    grouped['@@workTotal@@'].total = add(
                        grouped['@@workTotal@@'].total,
                        {
                            hours: reportedWorkHours,
                            minutes: reportedWorkMinutes,
                        }
                    );
                    grouped['@@travelTotal@@'].total = add(
                        grouped['@@travelTotal@@'].total,
                        {
                            hours: reportedTravelHours,
                            minutes: reportedTravelMinutes,
                        }
                    );
                }
            );
        });
    });

    return grouped;
};

export const getTotalDistanceForWeek = (reports, week) => {
    if (!reports || !reports.reports || !reports.reports[week]) {
        return 0;
    }

    let total = 0;

    Object.keys(reports.reports[week]).forEach(dayOfWeek => {
        const dailyReports = reports.reports[week][dayOfWeek];

        dailyReports.forEach(({ reportedDistanceKm }) => {
            if (reportedDistanceKm) {
                total += reportedDistanceKm;
            }
        });
    });

    return total;
};

export const getTotalDistance = reports => {
    if (!reports || !reports.reports) {
        return 0;
    }

    let total = 0;

    Object.keys(reports.reports).forEach(week => {
        Object.keys(reports.reports[week]).forEach(dayOfWeek => {
            const dailyReports = reports.reports[week][dayOfWeek];

            dailyReports.forEach(({ reportedDistanceKm }) => {
                if (reportedDistanceKm) {
                    total += reportedDistanceKm;
                }
            });
        });
    });

    return total;
};

export const getTotalDistanceForWeekDay = (reports, week, dayOfWeek) => {
    if (
        !reports ||
        !reports.reports ||
        !reports.reports[week] ||
        !reports.reports[week][dayOfWeek]
    ) {
        return 0;
    }

    let total = 0;

    const dailyReports = reports.reports[week][dayOfWeek];

    dailyReports.forEach(({ reportedDistanceKm }) => {
        if (reportedDistanceKm) {
            total += reportedDistanceKm;
        }
    });

    return total;
};

const groupReportToPeriods = (periods, grouped, report) => {
    if (!report || !report.startTime || !report.endTime) {
        return grouped;
    }

    // grouped['@@total@@'].total = add(grouped['@@total@@'].total, subtractIntTimes(report.endTime, report.startTime));

    periods.forEach(period => {
        grouped[period.label].total = add(
            grouped[period.label].total,
            getReportTimeForPeriod(period, report)
        );
    });

    return grouped;
};

const subtractIntTimes = (t1, t2) => {
    if (t2 >= t1) {
        return {
            hours: 0,
            minutes: 0,
        };
    }

    const t1String = `${t1}`.padStart(4, '0');
    const t2String = `${t2}`.padStart(4, '0');

    let hours =
        parseInt(t1String.substring(0, 2)) - parseInt(t2String.substring(0, 2));
    let minutes =
        parseInt(t1String.substring(2, 4)) - parseInt(t2String.substring(2, 4));

    if (minutes < 0) {
        minutes += 60;
        hours -= 1;
    }

    return {
        hours,
        minutes,
    };
};

export const getReportTimeForPeriod = (period, report) => {
    let periodStart = period.startTime;
    let periodEnd = period.endTime;

    if (
        report.startTime <= period.startTime &&
        report.endTime <= period.startTime
    ) {
        return {
            minutes: 0,
            hours: 0,
        };
    }

    if (report.startTime >= period.endTime) {
        return {
            minutes: 0,
            hours: 0,
        };
    }

    if (report.startTime >= periodStart && report.endTime <= periodEnd) {
        return subtractIntTimes(report.endTime, report.startTime);
    }

    if (report.startTime <= periodStart && report.endTime >= periodEnd) {
        return subtractIntTimes(periodEnd, periodStart);
    }

    if (
        report.startTime <= periodStart &&
        report.endTime >= periodStart &&
        report.endTime <= periodEnd
    ) {
        return subtractIntTimes(report.endTime, periodStart);
    }

    if (
        report.startTime >= periodStart &&
        report.startTime <= periodEnd &&
        report.endTime >= periodEnd
    ) {
        return subtractIntTimes(periodEnd, report.startTime);
    }

    return {
        hours: 0,
        minutes: 0,
    };
};

export const getGroupedByTagTotalTime = reports => {
    const grouped = {};

    const defaultTotal = {
        hours: 0,
        minutes: 0,
    };

    if (!reports || !reports.reports) {
        return grouped;
    }

    Object.keys(reports.reports).forEach(week => {
        return Object.keys(reports.reports[week]).forEach(dayOfWeek => {
            const dailyReports = reports.reports[week][dayOfWeek];

            dailyReports.forEach(
                ({ tagId, reportedHours, reportedMinutes }) => {
                    if (!grouped[tagId]) {
                        grouped[tagId] = defaultTotal;
                    }

                    grouped[tagId] = add(grouped[tagId], {
                        hours: reportedHours,
                        minutes: reportedMinutes,
                    });
                }
            );
        });
    });

    return grouped;
};

export const add = (t1, t2) => {
    if (!t1 && !t2) {
        return {
            hours: 0,
            minutes: 0,
        };
    }

    if (!t1) {
        return t2;
    }

    if (!t2) {
        return t1;
    }

    let hours = t1.hours + t2.hours;
    let minutes = t1.minutes + t2.minutes;

    return normalize({ hours, minutes });
};

export const subtractTimeReports = (t1, t2) => {
    if (!t1 && !t2) {
        return {
            hours: 0,
            minutes: 0,
        };
    }

    if (!t1) {
        return t2;
    }

    if (!t2) {
        return t1;
    }

    let hours = t1.hours - t2.hours;
    let minutes = t1.minutes - t2.minutes;

    return normalize({ hours, minutes });
};

export const getEntriesForDay = (timeReports, week, dayOfWeek) => {
    if (dayOfWeek === 0) {
        dayOfWeek = 7;
    }

    if (!timeReports || !timeReports.reports || !timeReports.reports[week]) {
        return [];
    }

    return timeReports.reports[week][dayOfWeek] || [];
};

export const getEntriesForWeek = (timeReports, week) => {
    if (!timeReports || !timeReports.reports || !timeReports.reports[week]) {
        return [];
    }

    const dailyReports = timeReports.reports[week];

    const reports = [];

    if (!dailyReports) {
        return reports;
    }

    Object.keys(dailyReports)
        .map(dayOfWeek => dailyReports[dayOfWeek])
        .forEach(dailyReports =>
            dailyReports.forEach(report => reports.push(report))
        );

    return reports;
};

export const getTimeForEntries = entries => {
    let time = {
        hours: 0,
        minutes: 0,
    };

    if (!entries) {
        return time;
    }

    entries.forEach(e => {
        time = add(time, {
            hours: e.reportedHours,
            minutes: e.reportedMinutes,
        });
    });

    return normalize(time);
};

export const getDistanceForEntries = entries => {
    if (!entries) {
        return 0;
    }

    return entries
        .map(r => r.reportedDistanceKm)
        .filter(v => v)
        .reduce((a, b) => a + b, 0);
};

/**
 * Function to get time between two epoch dates. Date is ignored.
 * @param {*} startTime
 * @param {*} endTime
 */
export const getTimeBetween = (startTime, endTime) => {
    if (!endTime || !startTime) {
        return { hours: 0, minutes: 0 };
    }

    const start = parseDate(
        `10-10-2010 ${formatTime(toDate(startTime))}`,
        'MM-DD-YYYY HH:mm'
    );
    const end = parseDate(
        `10-10-2010 ${formatTime(toDate(endTime))}`,
        'MM-DD-YYYY HH:mm'
    );

    let minutes = 0;

    if (start > end) {
        const start = parseDate(
            `10-10-2010 ${formatTime(toDate(startTime))}`,
            'MM-DD-YYYY HH:mm'
        );
        const end1 = parseDate(`10-11-2010 00:00`, 'MM-DD-YYYY HH:mm');
        const end = parseDate(
            `10-11-2010 ${formatTime(toDate(endTime))}`,
            'MM-DD-YYYY HH:mm'
        );

        minutes =
            Math.abs(moment.duration(end1.diff(start)).asMinutes()) +
            Math.abs(moment.duration(end1.diff(end)).asMinutes());
    } else {
        minutes = Math.abs(moment.duration(end.diff(start)).asMinutes());
    }

    return normalize({ hours: 0, minutes: minutes || 0 });
};

export const isTimeBefore = (t1, t2) => {
    if (!t1) {
        return true;
    }

    if (!t2) {
        return false;
    }

    const start = parseDate(
        `10-10-2010 ${formatTime(toDate(t1))}`,
        'MM-DD-YYYY HH:mm'
    );
    const end = parseDate(
        `10-10-2010 ${formatTime(toDate(t2))}`,
        'MM-DD-YYYY HH:mm'
    );

    return start.isBefore(end);
};

export const timeStringToInt = s => parseInt(s.replace(':', ''));

export const areTimeReportPeriodsValid = timeReportPeriods => {
    if (!timeReportPeriods) {
        return true;
    }

    let periods = timeReportPeriods
        .map(({ startTime, endTime }) => {
            const start = timeStringToInt(startTime);
            let end = timeStringToInt(endTime);

            return {
                startTime: start,
                endTime: end,
            };
        })
        .sort((p1, p2) => (p1.startTime > p2.startTime ? 1 : -1));

    for (let index = 0; index < periods.length; index++) {
        const others = [...periods];
        others.splice(index, 1);

        const period = periods[index];

        if (period.startTime === period.endTime) {
            return false;
        }

        for (let j = 0; j < others.length; j++) {
            const period2 = others[j];

            if (
                period.startTime > period2.startTime &&
                period.startTime < period2.endTime
            ) {
                return false;
            }

            if (
                period.endTime > period2.startTime &&
                period.endTime < period2.endTime
            ) {
                return false;
            }
        }
    }

    return true;
};
