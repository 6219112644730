import LocalizedDropdown from "components/common/localized-dropdown";
import { useInstanceData } from "components/common/use-instance-data";
import { appModules as modules } from "utils/constants/appModules";
import './ErpReferencePicker.css';
import { useState } from "react";

const CostCenterPicker = ({ value, onChange, search, selection, fluid, wrapAsColumn }) => {

    const [open, setOpen] = useState(false);
    const { appModules } = useInstanceData();

    const costCenters = appModules[modules.ERP]?.dynamic.costCenters || [];

    const options = costCenters.map(cc => ({
        key: cc.value,
        text: `${cc.value} ${cc.name}`,
        value: cc.value
    }));

    const dd = (<LocalizedDropdown
        className={`man-erp-reference-picker${value || wrapAsColumn ? '' : ' man-required'}`}
        open={wrapAsColumn ? open : undefined}
        onClick={() => setOpen(o => !o)}
        fluid={fluid}
        search={search}
        selection={selection}
        options={options}
        value={value}
        text={value}
        onChange={(e, { value }) => {
            onChange(value);
            setOpen(false);
        }} />);

    if (wrapAsColumn) {
        return (
            <td onClick={() => setOpen(o => !o)}
                style={{
                    background: value ? 'initial' : '#ee5855b3'
                }}>
                {dd}
            </td>
        )
    }

    return dd;
}

export default CostCenterPicker;