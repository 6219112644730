import { storeHolder } from '../../data/stores/storeHolder';
import { showWarningNotification } from '../../data/actions/common/notificationActions';
import { logout } from '../../data/actions/common/authActions';
import HttpError from './HttpError';
import { invoiceType } from '../constants';
import fetch from './fetch';
import HttpRequestTimeoutException from './HttpRequestTimeoutError';
import printJS from 'print-js-updated';

const API_ROOT = process.env.REACT_APP_API_ROOT;

export const get = async (url, token = null) => {
    try {
        const resp = await fetch(`${API_ROOT}${url}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token ? token : storeHolder.getStore().getState().auth.token}`
            }
        });

        if (resp.status !== 200) {
            if (resp.status === 401) {
                storeHolder.getStore().dispatch(logout('INVALID_SESSION'));
            }
            if (resp.status === 403) {
                storeHolder.getStore().dispatch(showWarningNotification('WARNING_PERMISSION_DENIED'));
            }
            throw new HttpError(resp.status, `Failed to get ${url}`);
        }

        return await resp.json();
    } catch (err) {
        if (err instanceof HttpRequestTimeoutException) {
            storeHolder.getStore().dispatch(showWarningNotification('WARNING_NETWORK_ERROR_PLEASE_REFRESH'));
        }
        throw err;
    }
}

export const post = async (url, data, logoutOn401 = true) => {
    try {
        const resp = await fetch(`${API_ROOT}${url}`, {
            method: 'POST',
            redirect: 'follow',
            headers: {
                'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
                'Authorization': `Bearer ${storeHolder.getStore().getState().auth.token}`
            },
            body: JSON.stringify(data)
        });

        let responseBody = {};
        const status = resp.status;

        if (status !== 200) {
            if (status === 401 && logoutOn401) {
                storeHolder.getStore().dispatch(logout('INVALID_SESSION'));
            }
            if (status === 403) {
                storeHolder.getStore().dispatch(showWarningNotification('WARNING_PERMISSION_DENIED'));
            }
            if (status === 302) {
                responseBody = await resp.json();
            }
            if (status === 409) {
                responseBody = await resp.json();
            }
            throw new HttpError(status, `Failed to post to ${url}`, responseBody);
        }

        return await resp.json();
    } catch (err) {
        if (err instanceof HttpRequestTimeoutException) {
            storeHolder.getStore().dispatch(showWarningNotification('WARNING_NETWORK_ERROR_PLEASE_REFRESH'));
        }
        throw err;
    }
}

export const put = async (url, data) => {
    try {
        const resp = await fetch(`${API_ROOT}${url}`, {
            method: 'PUT',
            redirect: 'follow',
            headers: {
                'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
                'Authorization': `Bearer ${storeHolder.getStore().getState().auth.token}`
            },
            body: JSON.stringify(data)
        });

        if (resp.status !== 200) {
            
            let responseBody = {};

            if (resp.status === 401) {
                storeHolder.getStore().dispatch(logout('INVALID_SESSION'));
            }
            if (resp.status === 403) {
                storeHolder.getStore().dispatch(showWarningNotification('WARNING_PERMISSION_DENIED'));
            }

            try {
                responseBody = await resp.json();
            } catch {

            }

            throw new HttpError(resp.status, `Failed to put to ${url}`, responseBody);
        }

        return await resp.json();
    } catch (err) {
        if (err instanceof HttpRequestTimeoutException) {
            storeHolder.getStore().dispatch(showWarningNotification('WARNING_NETWORK_ERROR_PLEASE_REFRESH'));
        }
        throw err;
    }
}


export const head = async (url, token = null) => {
    try {
        const resp = await fetch(`${API_ROOT}${url}`, {
            method: 'HEAD',
            redirect: 'follow',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Authorization': `Bearer ${token ? token : storeHolder.getStore().getState().auth.token}`
            }
        })

        if (resp.status !== 200) {
            if (resp.status === 401) {
                storeHolder.getStore().dispatch(logout('INVALID_SESSION'));
            }
            throw new HttpError(resp.status, `Failed head to ${url}`);
        }

        return resp;
    } catch (err) {
        if (err instanceof HttpRequestTimeoutException) {
            storeHolder.getStore().dispatch(showWarningNotification('WARNING_NETWORK_ERROR_PLEASE_REFRESH'));
        }
        throw err;
    }
}

export const patch = async (url, data) => {
    try {
        const resp = await fetch(`${API_ROOT}${url}`, {
            method: 'PATCH',
            redirect: 'follow',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Authorization': `Bearer ${storeHolder.getStore().getState().auth.token}`
            },
            body: JSON.stringify(data)
        })

        if (resp.status !== 200) {
            if (resp.status === 401) {
                storeHolder.getStore().dispatch(logout('INVALID_SESSION'));
            }
            if (resp.status === 403) {
                storeHolder.getStore().dispatch(showWarningNotification('WARNING_PERMISSION_DENIED'));
            }
            throw new HttpError(resp.status, `Failed patch to ${url}`);
        }

        return await resp.json();
    } catch (err) {
        if (err instanceof HttpRequestTimeoutException) {
            storeHolder.getStore().dispatch(showWarningNotification('WARNING_NETWORK_ERROR_PLEASE_REFRESH'));
        }
        throw err;
    }

}

export const deletee = async (url) => {
    try {
        const resp = await fetch(`${API_ROOT}${url}`, {
            method: 'DELETE',
            redirect: 'follow',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Authorization': `Bearer ${storeHolder.getStore().getState().auth.token}`
            }
        })

        if (resp.status !== 200) {
            if (resp.status === 401) {
                storeHolder.getStore().dispatch(logout('INVALID_SESSION'));
            }
            if (resp.status === 403) {
                storeHolder.getStore().dispatch(showWarningNotification('WARNING_PERMISSION_DENIED'));
            }
            throw new HttpError(resp.status, `Failed to delete ${url}`);
        }

        return resp;
    } catch (err) {
        if (err instanceof HttpRequestTimeoutException) {
            storeHolder.getStore().dispatch(showWarningNotification('WARNING_NETWORK_ERROR_PLEASE_REFRESH'));
        }
        throw err;
    }
}

export const postFile = async (url, data) => {
    const resp = await fetch(`${API_ROOT}${url}`, {
        method: 'POST',
        redirect: 'follow',
        headers: {
            'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
            'Authorization': `Bearer ${storeHolder.getStore().getState().auth.token}`
        },
        body: data
    }, 60000);
    
    if (resp.status !== 200) {
        throw new HttpError(resp.status, `Failed to post file ${url}`);
    }

    return await resp.json();
}

export const getFile = async (url, data, fileName = 'file', extension = 'xml', type, ignoreServerFileName = false) => {
    let resp = await fetch(`${API_ROOT}${url}`, {
        method: 'POST',
        redirect: 'follow',
        headers: {
            'Authorization': `Bearer ${storeHolder.getStore().getState().auth.token}`
        },
        body: JSON.stringify(data)
    });

    if (resp.status !== 200) {
        throw new HttpError(resp.status, `Failed to get file ${url}`);
    }

    const blob = await resp.blob();
    let newBlob = blob;

    if (type === invoiceType.PDF) {
        newBlob = new Blob([blob], { type: 'application/pdf' });
    }

    let downloadFileName = `${fileName}.${extension}`;

    if (!ignoreServerFileName) {
        resp.headers.forEach((val, key) => {
            if (key === 'content-disposition' && val.indexOf('attachment') !== -1) {
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(val);
                if (matches != null && matches[1]) {
                    downloadFileName = matches[1].replace(/['"]/g, '');
                }
            }
        })
    }
    // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
    } else {
        // For other browsers: create a link pointing to the ObjectURL containing the blob.
        const objUrl = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = objUrl;
        link.download = downloadFileName;
        link.click();

        // For Firefox it is necessary to delay revoking the ObjectURL.
        setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
    }
}

export const getPrintPreviewPdf = async (url, data, fileName = 'file') => {
    let resp = await fetch(`${API_ROOT}${url}`, {
        method: 'POST',
        redirect: 'follow',
        headers: {
            'Authorization': `Bearer ${storeHolder.getStore().getState().auth.token}`
        },
        body: JSON.stringify(data)
    });

    if (resp.status !== 200) {
        throw new HttpError(resp.status, `Failed to get file ${url}`);
    }

    const blob = await resp.blob();
    let newBlob = new Blob([blob], { type: 'application/pdf' });
    
    let downloadFileName = `${fileName}.pdf`;

    resp.headers.forEach((val, key) => {
        if (key === 'content-disposition' && val.indexOf('attachment') !== -1) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            let matches = filenameRegex.exec(val);
            if (matches != null && matches[1]) {
                downloadFileName = matches[1].replace(/['"]/g, '');
            }
        }
    });

    const pdfURL = URL.createObjectURL(newBlob);
    
    printJS({
        printable: pdfURL,
        type: 'pdf',
        documentTitle: downloadFileName
    });
}