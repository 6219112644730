import { useSelector } from 'react-redux';
import { aclLimit } from 'utils/constants';

export const usePermissions = () => {
    const { userLimit, userPermissions, userType } = useSelector(state => ({
        userLimit: state.auth.limit,
        userPermissions: state.auth.perms,
        userType: state.auth.userType,
    }));

    const hasLimit = limit => {
        if (limit === aclLimit.NONE) {
            return userLimit === aclLimit.NONE;
        }

        return true;
    };

    const hasPermission = name => userPermissions.indexOf(name) > -1;

    const isSuperUser = userType === 'super';

    return {
        hasLimit,
        hasPermission,
        isSuperUser,
    };
};
