import {
    FETCH_WORKORDERS,
    FETCH_WORKORDERS_FULFILLED,
    FETCH_WORKORDERS_REJECTED,
    CREATE_WORKORDER,
    CREATE_WORKORDER_FULFILLED,
    CREATE_WORKORDER_REJECTED,
    SAVE_WORKORDER_FULFILLED,
    DELETE_WORKORDER,
    DELETE_WORKORDER_FULFILLED,
    DELETE_WORKORDER_REJECTED,
    WORKORDERS_TOGGLE_TAB,
    WORKORDERS_CLOSE_TAB,
    WORKORDERS_CLOSE_ALL_TABS,
    WORKORDERS_OPEN_TAB,
    WORKORDERS_HANDLE_CHANGE,
    WORKORDERS_INIT_TABS,
    WORKORDERS_SET_FOCUSED,
} from '../../actions/workorders';

import {
    TOGGLE_DASHBOARD_FILTER
} from '../../actions/common/uiActions';

import {
    DASHBOARD_TAB_CHANGED,
    DASHBOARD_TAB_CLOSED
} from '../../actions/dashboard';

import {
    LOGIN_FULFILLED
} from '../../actions/common/authActions';
import CacheManager from '../../../utils/cache';
import CacheManagerProps from '../../../utils/cache/CacheManagerProps';

export const workOrders = (
    state = {
        uid: '',
        isFetching: false,
        workOrders: [],
        totalCount: 0,
        error: null,
        activeTab: 'list',
        prevActiveTab: 'list',
        tabs: [],
        isSaving: false,
        from: 0,
        focused: null
    },
    action
) => {
    switch (action.type) {
        case DASHBOARD_TAB_CHANGED: {
            const tabs = [...state.tabs];

            const idx = tabs.findIndex(t => t.id === action.id);

            if (idx === -1) {
                return state;
            }

            const tab = {
                ...tabs[idx],
                ...action.values
            };

            tabs[idx] = tab;

            CacheManager.put(state.uid, CacheManagerProps.TABS, tabs);

            return {
                ...state,
                tabs
            }

        }
        case DASHBOARD_TAB_CLOSED: {
            const tabs = [...state.tabs].filter(t => t.id !== action.id);
            const activeTab = state.activeTab === action.id ? state.prevActiveTab : state.activeTab;

            CacheManager.put(state.uid, CacheManagerProps.TABS, tabs);
            CacheManager.put(state.uid, CacheManagerProps.ACTIVE_TAB, activeTab);

            return {
                ...state,
                activeTab,
                tabs,
                focused: null
            }

        }
        case LOGIN_FULFILLED: return ({ ...state, uid: action.user.id })
        case FETCH_WORKORDERS: return ({
            ...state,
            isFetching: true,
            from: action.from,
            workOrders: action.from === 0 ? [] : state.workOrders
        })
        case FETCH_WORKORDERS_FULFILLED: {
            const workOrders = state.from === 0 ? action.workOrders : [...state.workOrders, ...action.workOrders]

            return {
                ...state,
                isFetching: false,
                workOrders,
                totalCount: action.totalCount
            }
        }
        case FETCH_WORKORDERS_REJECTED: return ({ ...state, isFetching: false, error: action.error })

        case CREATE_WORKORDER: return ({ ...state, isSaving: true })
        case CREATE_WORKORDER_REJECTED: return ({ ...state, isSaving: false })
        case CREATE_WORKORDER_FULFILLED: return ({
            ...state,
            workOrders: state.workOrders.length > 0 ? [action.workOrder, ...state.workOrders] : [], // force a reload when its empty at first,
            totalCount: state.totalCount + 1,
            isSaving: false
        })
        case SAVE_WORKORDER_FULFILLED: return handleWorkOrderSaveFulfilled(state, action)

        case DELETE_WORKORDER: return ({ ...state, isSaving: true })
        case DELETE_WORKORDER_REJECTED: return ({ ...state, isSaving: false })
        case DELETE_WORKORDER_FULFILLED: return handleWorkOrderDeleteFulfilled(state, action)

        case WORKORDERS_INIT_TABS: {
            return {
                ...state,
                activeTab: action.activeTab,
                focused: action.activeTab,
                tabs: action.tabs.map(t => {
                    t.isEdited = false;
                    return t;
                })
            }
        }
        case WORKORDERS_TOGGLE_TAB: {
            const { activeTab, prevActiveTab } = state;

            CacheManager.put(state.uid, CacheManagerProps.ACTIVE_TAB, action.id);

            return {
                ...state,
                activeTab: action.id,
                prevActiveTab: activeTab === 'list' || activeTab === 'calendar' || activeTab === 'map' ? activeTab : prevActiveTab
            }
        }
        case WORKORDERS_OPEN_TAB: {
            const tabs = [...state.tabs];
            const { id, title, customerName } = action;
            const { activeTab, prevActiveTab } = state;

            const tabIndex = tabs.findIndex(t => t.id === id);
            if (tabIndex === -1) {
                tabs.push({
                    id,
                    title,
                    customerName,
                    isEdited: false
                });
            } else {
                tabs[tabIndex] = {
                    ...tabs[tabIndex],
                    id,
                    title,
                    customerName
                }
            }

            CacheManager.put(state.uid, CacheManagerProps.TABS, tabs);
            CacheManager.put(state.uid, CacheManagerProps.ACTIVE_TAB, id);

            return {
                ...state,
                activeTab: id,
                prevActiveTab: activeTab === 'list' || activeTab === 'calendar' || activeTab === 'map' ? activeTab : prevActiveTab,
                tabs,
                focused: id
            }
        }
        case WORKORDERS_CLOSE_TAB: {
            const tabs = [...state.tabs].filter(t => t.id !== action.id);
            const activeTab = state.activeTab === action.id ? state.prevActiveTab : state.activeTab;

            CacheManager.put(state.uid, CacheManagerProps.TABS, tabs);
            CacheManager.put(state.uid, CacheManagerProps.ACTIVE_TAB, activeTab);

            return {
                ...state,
                activeTab,
                tabs,
                focused: null
            }
        }
        case WORKORDERS_CLOSE_ALL_TABS: {
            const tabs = [];
            const activeTab = 'list'

            CacheManager.put(state.uid, CacheManagerProps.TABS, tabs);
            CacheManager.put(state.uid, CacheManagerProps.ACTIVE_TAB, activeTab);

            return {
                ...state,
                activeTab,
                tabs,
                focused: null
            }
        }
        case WORKORDERS_HANDLE_CHANGE: {
            const tabs = [...state.tabs];

            const idx = tabs.findIndex(t => t.id === action.id);

            if (idx === -1) {
                return state;
            }

            const ignorableActions = ["ui:", "locked"];

            const tab = {
                ...tabs[idx],
                isEdited: !ignorableActions.some(a => action.name.includes(a)),
                [action.name]: action.value
            };


            tabs[idx] = tab;

            CacheManager.put(state.uid, CacheManagerProps.TABS, tabs);

            return {
                ...state,
                tabs
            }
        }
        case TOGGLE_DASHBOARD_FILTER: {
            if (action.name !== 'search') return state;

            const tab = state.activeTab === 'map' ? 'map' : 'list';

            CacheManager.put(state.uid, CacheManagerProps.ACTIVE_TAB, tab);

            return {
                ...state,
                activeTab: tab
            }
        }
        case WORKORDERS_SET_FOCUSED: {

            return {
                ...state,
                focused: action.id
            }
        }
        default: {
            return state;
        }
    }
};

const handleWorkOrderSaveFulfilled = (state, action) => {
    const workOrders = [...state.workOrders];
    const idx = workOrders.findIndex(wo => wo.id === action.workOrder.id);
    let totalCount = state.totalCount;

    if (idx !== -1) {
        if (action.workOrder.isClosed) {
            totalCount--;
            workOrders.splice(idx, 1);
        } else {
            if (action.workOrder.scheduleReports != undefined) {
                action.workOrder.users = [...new Set((action.workOrder.scheduleReports || []).map(({ userId }) => userId))];
            }

            workOrders[idx] = {
                ...workOrders[idx],
                ...action.workOrder
            };
        }
    }

    const tabs = [...state.tabs];

    const i = tabs.findIndex(t => t.id === action.workOrder.id);

    if (i !== -1) {
        const tab = {
            ...tabs[i],
            isEdited: false
        };

        tabs[i] = tab;

        CacheManager.put(state.uid, CacheManagerProps.TABS, tabs);
    }

    return { ...state, workOrders, isSaving: false, tabs, totalCount };
}

const handleWorkOrderDeleteFulfilled = (state, action) => {
    const workOrders = [...state.workOrders].filter(wo => wo.id !== action.id);
    let totalCount = state.totalCount - 1;

    const tabs = [...state.tabs].filter(wo => wo.id !== action.id);

    CacheManager.put(state.uid, CacheManagerProps.TABS, tabs);
    CacheManager.put(state.uid, CacheManagerProps.ACTIVE_TAB, 'list');

    return {
        ...state,
        workOrders,
        isSaving: false,
        tabs,
        activeTab: 'list',
        totalCount
    };
}