import PropTypes from 'prop-types';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import DataModal from '../modal/DataModal';
import FilePreview from './FilePreview';
import './FilesImagePreview.css';

const ImagePreview = ({ onClose, url, fileName, type }) => {

    return (
        <DataModal
            onClose={onClose}
            header={fileName}
            content={
                <div className="content">
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                        }}>
                        <FilePreview url={url} fileName={fileName} type={type} />
                    </div>
                </div>
            }
        />
    );
};

ImagePreview.propTypes = {
    onClose: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default ImagePreview;
