
import proposalService from '../../services/proposal/proposalService';

export const FETCH_PROPOSALS = 'FETCH_PROPOSALS';
export const FETCH_PROPOSALS_FULFILLED = 'FETCH_PROPOSALS_FULFILLED';
export const FETCH_PROPOSALS_REJECTED = 'FETCH_PROPOSALS_REJECTED';
export const PROPOSAL_CREATED = 'PROPOSAL_CREATED';
export const PROPOSAL_CHANGED = 'PROPOSAL_CHANGED';
export const PROPOSAL_CLOSED = 'PROPOSAL_CLOSED';
export const PROPOSAL_DELETED = 'PROPOSAL_DELETED';
export const PROPOSAL_TABLE_STATE_CHANGE = 'PROPOSAL_TABLE_STATE_CHANGE';
export const PROPOSAL_FILTERS_CHANGE = 'PROPOSAL_FILTERS_CHANGE';

const requestProposals = () => ({ type: FETCH_PROPOSALS });

const requestProposalsFulfilled = (proposals, status) => ({
    type: FETCH_PROPOSALS_FULFILLED,
    proposals,
    status
});

const requestProposalsRejected = error => ({ type: FETCH_PROPOSALS_REJECTED, error });

export const proposalsTableStateChange = state => ({ type: PROPOSAL_TABLE_STATE_CHANGE, state });

export const proposalsFiltersChange = filters => ({
    type: PROPOSAL_FILTERS_CHANGE,
    filters
});

export const proposalCreated = proposal => ({
    type: PROPOSAL_CREATED,
    proposal
});

export const proposalChanged = proposal => ({
    type: PROPOSAL_CHANGED,
    proposal
});

export const proposalClosed = id => ({
    type: PROPOSAL_CLOSED,
    id
});

export const proposalDeleted = id => ({
    type: PROPOSAL_DELETED,
    id
});

export const fetchProposals = ({ startTime, endTime, status, tags, userId }) => async dispatch => {

    dispatch(requestProposals());

    try {
        const proposals = await proposalService.fetch(status, startTime, endTime, tags, userId);
        dispatch(requestProposalsFulfilled(proposals, status));
    } catch (err) {
        console.error(err);
        dispatch(requestProposalsRejected(err));
    }
};