export const en_machine = {
    ui: {
        SETTINGS_BUSINESS_OBJECT: 'Machine',
        OBJECTS: 'Machines',
        MANAGE_OBJECTS: 'Edit machines',
    },
    form: {
        OBJECT: 'Machine',
    },
    acl: {
        PERMISSION_BUSINESS_OBJECTS_READ: 'View machines',
        PERMISSION_BUSINESS_OBJECTS_EDIT: 'Edit machines',
    }
}