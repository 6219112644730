import React from 'react';
import PropTypes from 'prop-types';
import './LineLoader.css';

const LineLoader = ({ lines = 1 }) => (
    <div className="man-lineloader ui placeholder">
        {[...Array(lines).keys()].map(i =>
            <div key={i} className="ui line"></div>
        )}
    </div>
);

LineLoader.propTypes = {
    lines: PropTypes.number
}

LineLoader.defaultProps = {
    lines: 1
}

export default LineLoader;