import supplierInvoiceService from '../../services/supplierinvoices/supplierInvoicesService';

export const FETCH_SUPPLIERINVOICES = 'FETCH_SUPPLIERINVOICES';
export const FETCH_SUPPLIERINVOICES_FULFILLED = 'FETCH_SUPPLIERINVOICES_FULFILLED';
export const FETCH_SUPPLIERINVOICES_REJECTED = 'FETCH_SUPPLIERINVOICES_REJECTED';
export const SUPPLIERINVOICE_CHANGED = 'SUPPLIERINVOICE_CHANGED';
export const SUPPLIERINVOICES_TABLE_STATE_CHANGE = 'SUPPLIERINVOICES_TABLE_STATE_CHANGE';
export const SUPPLIERINVOICES_FILTERS_CHANGE = 'SUPPLIERINVOICES_FILTERS_CHANGE';

const requestSupplierInvoices = () => ({ type: FETCH_SUPPLIERINVOICES });

const requestSupplierInvoicesFulfilled = (supplierInvoices, paymentPendingCount) => ({
    type: FETCH_SUPPLIERINVOICES_FULFILLED,
    invoices: supplierInvoices,
    paymentPendingCount
});

const requestSupplierInvoicesRejected = error => ({
    type: FETCH_SUPPLIERINVOICES_REJECTED,
    error,
});

export const supplierInvoicesTableStateChange = state => ({
    type: SUPPLIERINVOICES_TABLE_STATE_CHANGE,
    state,
});

export const supplierInvoicesFiltersChange = filters => ({
    type: SUPPLIERINVOICES_FILTERS_CHANGE,
    filters,
});

export const supplierInvoiceChanged = supplierInvoice => ({
    type: SUPPLIERINVOICE_CHANGED,
    supplierInvoice,
});

export const fetchSupplierInvoices = (filters) =>
    async dispatch => {
        const { status, userId } = filters;
        dispatch(requestSupplierInvoices());

        try {
            const { invoices, paymentPendingCount } = await supplierInvoiceService.fetch(status);

            const inv = invoices.filter(i => {
                if (userId === 'none') {
                    return !i.responsibleUserId;
                } else if (userId) {
                    return i.responsibleUserId === userId;
                }

                return true;
            });

            dispatch(requestSupplierInvoicesFulfilled(inv, paymentPendingCount));
        } catch (err) {
            console.error(err);
            dispatch(requestSupplierInvoicesRejected(err));
        }
    };
