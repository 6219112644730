import HttpRequestTimeoutException from './HttpRequestTimeoutError';

export default async (url, options, timeout = 30000) =>
    Promise.race([
        fetch(url, options),
        new Promise((_, reject) =>
            setTimeout(() => {
                reject(new HttpRequestTimeoutException());
            }, timeout)
        )
    ]);