import { discountTypes } from './constants/discountTypes';
import { formatPrice } from './StringUtils';

export const getDiscountText = (discountType, discountValue, currency) => {
    if (!discountValue) {
        return '';
    }

    if (discountType === discountTypes.PERCENTAGE) {
        return ` -${discountValue} %`;
    }

    return ` -${formatPrice(discountValue, currency)}`;
}

export const validateDiscount = (value) => {
    const isCorrect = value.match(/(^[0-9]*)%?$/g);

    if (!isCorrect) {
        return "messages:ERROR_DISCOUNT_PATTERN";
    }

    return;
}

export const parseDiscount = value => {
    if (!value || value == "%") {
        return {
            discountType: null,
            discountValue: null
        }
    }

    const discountValue = Number(value.match(/(\d+)/)[0]);
    const discountType = value.includes("%") ? discountTypes.PERCENTAGE : discountTypes.REGULAR;

    return {
        discountType,
        discountValue
    }
}

export const shouldShowDiscountColumn = (reports) => {
    if (reports != null) {
        return reports.some(r => r.discountValue);
    }
    return false;
}