export const se_doorway = {
    ui: {
        SETTINGS_BUSINESS_OBJECT: 'Objekt',
        DEPARTMENTS: 'Kostnadsställen',
        DEPARTMENT: 'Kostnadsställe',
        DEPARTMENT_EDIT_TITLE: 'Ändra kostnadsställe',
        FILTER_BY_DEPARTMENT: 'Kostnadsställe',
        OBJECTS: 'Objekt',
        MANAGE_OBJECTS: 'Hantera objekt',
        MANAGE_MODELS: 'Hantera objekt-typer',
        MODELS: 'Objekt-typ',
        CLOSE_WORKORDER: 'Skapa faktura!',
        NEW_BUSINESS_OBJECT_MODAL_TITLE: 'Nytt objekt',
        MODEL_EDIT_DIALOG_TITLE: 'Ändra objekt-typ',
        SALES_ORDERS: 'Butiksorder',
        MODELPICKER_SELECT: 'Välj objekt-typ',
        SELECT_DEPARTMENT: 'Välj kostnadsställe',
        DEPARTMENT_NONE: 'Saknar kostnadsställe',
    },

    form: {
        OBJECT_COUNT: 'Objekt',
        OBJECT: 'Objekt',
        MODEL: 'Objekt-typ',
        CODE: 'Objektnr',
        SERIAL: 'Objektnr',
        SALES_ORDER: 'Butiksorder',
        DEPARTMENT: 'Kostnadsställe',
        PROJECT_MENU_INVOICING: 'Lyftplan',
        LOCATION_DESCRIPTION: 'Objektnamn',
    },

    acl: {
        PERMISSION_BUSINESS_OBJECTS_READ: 'Se objekt',
        PERMISSION_BUSINESS_OBJECTS_EDIT: 'Ändra objekt',
        PERMISSION_MODELS_READ: 'Se objekt-typ',
        PERMISSION_SALES_ORDER_READ: 'Visa butiksorder',
        PERMISSION_SALES_ORDER_EDIT: 'Skapa och ändra butiksorder',
        PERMISSION_SALES_ORDER_DELETE: 'Radera butiksorder',
        PERMISSION_MODELS_EDIT: 'Ändra objekt-typ',
    },

    messages: {
        ERROR_DEPARTMENT_HAS_USERS:
            'Kan inte radera. Kostnadsstället har användare.',
    },
};
