export const se_sensec = {
    ui: {
        SETTINGS_BUSINESS_OBJECT: 'Maskin',
        OBJECTS: 'Maskiner',
        MANAGE_OBJECTS: 'Hantera maskiner',
        MANAGE_MODELS: 'Hantera modeller',
        MODELS: 'Modeller',
        NEW_BUSINESS_OBJECT_MODAL_TITLE: 'Ny maskin',
        MODEL_EDIT_DIALOG_TITLE: 'Ändra modell',
        MODELPICKER_SELECT: 'Välj modell',
        OBJECT: 'Maskin',
    },

    form: {
        OBJECT_COUNT: 'Maskiner',
        OBJECT: 'Maskin',
        MODEL: 'Modell',
        CODE: 'Maskin-ID',
        SERIAL: 'Maskin ID',
        PROJECT_OBJECTS_INVOLVED: 'Involverade maskiner',
    },

    acl: {
        PERMISSION_BUSINESS_OBJECTS_READ: 'Se maskin',
        PERMISSION_BUSINESS_OBJECTS_DELETE: 'Radera maskin',
        PERMISSION_BUSINESS_OBJECTS_EDIT: 'Ändra maskiner',
        PERMISSION_MODELS_READ: 'Se modeller',
        PERMISSION_MODELS_DELETE: 'Radera modell',
        PERMISSION_MODELS_EDIT: 'Ändra modell',
        PERMISSION_BUSINESS_OBJECT_FILES: 'Hantera dokument under maskin',
        PERMISSION_MODEL_FILES_READ: 'Se dokument under modell',
        PERMISSION_MODEL_FILES_MANAGE: 'Ändra dokument under modell',
    },

};