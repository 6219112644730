export default class BusinessObjectAgreement {

    id;
    agreementId;
    businessObjectId;
    woScheduledTo;
    invoiceScheduledTo;
    invoicePeriodFrom;
    invoicePeriodTo;
    validFrom;
    validTo;
    totalPrice;
    articles;
    fieldText1;
    fieldText2;
    fieldText3;
    fieldDate1;
    invoiceAddressId;
    invoiceCustomerId;
    invoiceExtra;

    constructor(values, boId) {
        if (!values) {
            this.setDefaults(boId);
            return;
        }

        const { id, agreementId, businessObjectId,
            woScheduledTo, invoiceScheduledTo, invoicePeriodFrom, validFrom, validTo, totalPrice, articles,
            fieldText1, fieldText2, fieldText3, fieldDate1, invoiceAddressId, invoiceCustomerId, invoiceExtra, fieldTextArea1, fieldTextArea2 } = values;

        this.id = id;
        this.agreementId = agreementId;
        this.businessObjectId = businessObjectId;
        this.woScheduledTo = woScheduledTo;
        this.invoiceScheduledTo = invoiceScheduledTo;
        this.invoicePeriodFrom = invoicePeriodFrom;
        this.validFrom = validFrom;
        this.validTo = validTo;
        this.totalPrice = totalPrice;
        this.articles = articles;
        this.fieldText1 = fieldText1;
        this.fieldText2 = fieldText2;
        this.fieldText3 = fieldText3;
        this.fieldTextArea1 = fieldTextArea1;
        this.fieldTextArea2 = fieldTextArea2;
        this.fieldDate1 = fieldDate1;
        this.invoiceAddressId = invoiceAddressId;
        this.invoiceCustomerId = invoiceCustomerId;
        this.invoiceExtra = invoiceExtra;
    }

    setDefaults(businessObjectId) {
        this.id = 'new';
        this.agreementId = '';
        this.businessObjectId = businessObjectId;
        this.totalPrice = 0;
        this.articles = [];
    }

}