import { post, get } from '../../../utils/http/HttpClient';

import { getUserUiSetting, UI_PROP_LANG } from '../../../utils/UiUtils';
import CacheManager from '../../../utils/cache';
import { initWorkOrderTabs } from '../workorders';
import { reloadWithParam } from '../../../utils/UrlUtils';
import {
    initDashboardSort,
    toggleSidebar,
    initDashboardFilters,
    toggleLanguage,
} from 'data/actions/common/uiActions';
import { toggleActivePeriodFromCache } from '../calendar';
import CacheManagerProps from '../../../utils/cache/CacheManagerProps';
import { deviceDetect } from 'react-device-detect';
import ActionRequiredError from 'utils/errors/ActionRequiredError';
import MfaValidationRejectedError from 'utils/errors/MfaValidationRejectedError';

export const REQUEST_VALIDATE_SESSION = 'REQUEST_VALIDATE_SESSION';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';
export const REQUIRE_MFA = 'REQUIRE_MFA';
export const REQUEST_VALIDATE_MFA = 'REQUEST_VALIDATE_MFA';
export const MFA_VALIDATION_REJECTED = 'MFA_VALIDATION_REJECTED';

const requestValidateSession = () => ({ type: REQUEST_VALIDATE_SESSION });
const requestLogin = () => ({ type: LOGIN });
const loginFulfilled = loginData => ({ type: LOGIN_FULFILLED, ...loginData });
const loginRejected = error => ({ type: LOGIN_REJECTED, error });
const requestLogoutRequest = () => ({ type: LOGOUT_REQUEST });
const requestLogout = () => ({ type: LOGOUT });
const requireMfa = data => ({ type: REQUIRE_MFA, ...data });
const requestValidateMfa = () => ({ type: REQUEST_VALIDATE_MFA });
const mfaValidationRejected = error => ({
    type: MFA_VALIDATION_REJECTED,
    error,
});

export const login = (email, password) => {
    return async dispatch => {
        dispatch(requestLogin());

        try {
            const resp = await post(
                '/authenticate',
                {
                    email,
                    password,
                    hostname: window.location.hostname,
                    deviceData: deviceDetect(),
                },
                false
            );

            if (resp.status == 'ACTION_REQUIRED') {
                throw new ActionRequiredError(
                    resp.status,
                    resp.action,
                    resp.data
                );
            }

            await CacheManager.putGlobal(
                CacheManagerProps.AUTH_DATA,
                resp.token
            );

            dispatch(
                toggleLanguage(
                    resp.user.configuration.language
                        ? resp.user.configuration.language
                        : getUserUiSetting(UI_PROP_LANG, 'en')
                )
            );

            await initFromCache(dispatch, resp.user.id);

            dispatch(loginFulfilled(resp));
        } catch (err) {
            if (err.code === 302) {
                const { url } = err.body;

                if (url) {
                    window.location = url;
                    return;
                }
            }

            if (err instanceof ActionRequiredError) {
                if (err.action === 'MFA') {
                    dispatch(requireMfa(err.data));
                    return;
                }
            }

            console.error(err);
            dispatch(loginRejected(err));
        }
    };
};

export const authenticateWithMicrosoft = () => {
    return async dispatch => {
        dispatch(requestLogin());
        try {
            await post('/authenticate/microsoft');
        } catch (err) {
            const { url } = err.body || {};
            if (url) {
                window.location = url;
                return;
            }
            console.error(err);
            dispatch(loginRejected(err));
        }
    };
};

export const logout = message => {
    return async dispatch => {
        CacheManager.removeGlobal(CacheManagerProps.AUTH_DATA);
        dispatch(requestLogoutRequest());

        try {
            if (message !== 'INVALID_SESSION') {
                await post('/logout');
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(requestLogout());

            if (message) {
                showMessage(message);
            }
        }
    };
};

export const keepalive = () => {
    return async dispatch => {
        try {
            await get('/keepalive');
        } catch (err) {
            console.error(err);
            dispatch(logout('INVALID_SESSION'));
        }
    };
};

export const validateSession = () => {
    return async dispatch => {
        dispatch(requestValidateSession());

        let token = await CacheManager.getGlobal(CacheManagerProps.AUTH_DATA);

        if (!token) {
            dispatch(requestLogout());
            return;
        }

        try {
            const resp = await get('/keepalive', token);

            dispatch(
                toggleLanguage(await getUserUiSetting(UI_PROP_LANG, 'se'))
            );

            await initFromCache(dispatch, resp.user.id);

            dispatch(loginFulfilled(resp));
        } catch (err) {
            dispatch(logout('INVALID_SESSION'));
        }
    };
};

export const validateMfaCode = (token, mfaCode) => {
    return async dispatch => {
        dispatch(requestValidateMfa());
        try {
            const resp = await post(
                '/authenticate/mfa',
                {
                    mfaCode,
                    token,
                },
                false
            );

            await CacheManager.putGlobal(
                CacheManagerProps.AUTH_DATA,
                resp.token
            );
            dispatch(validateSession());
        } catch (err) {
            console.error(err);

            if (err.code === 400) {
                const error = new MfaValidationRejectedError(409, err.message);
                dispatch(mfaValidationRejected(error));
                return;
            }

            dispatch(loginRejected(err));
        }
    };
};

const initFromCache = async (dispatch, uid) => {
    const activeTab = await CacheManager.getOrDefault(
        uid,
        CacheManagerProps.ACTIVE_TAB,
        null
    );
    const tabs = await CacheManager.getOrDefault(
        uid,
        CacheManagerProps.TABS,
        null
    );

    if (activeTab && tabs) {
        dispatch(initWorkOrderTabs(activeTab, tabs));
    }

    const filters = await CacheManager.getOrDefault(
        uid,
        CacheManagerProps.FILTERS,
        null
    );

    if (filters) {
        dispatch(initDashboardFilters(filters));
    }

    const sort = await CacheManager.getOrDefault(
        uid,
        CacheManagerProps.SORT,
        null
    );

    if (sort) {
        dispatch(initDashboardSort(sort));
    }

    const sidebarOpen = await CacheManager.getOrDefault(
        uid,
        CacheManagerProps.SIDEBAR_OPEN,
        false
    );

    if (sidebarOpen) {
        dispatch(toggleSidebar());
    }

    const activeCalendarPeriod = await CacheManager.getOrDefault(
        uid,
        CacheManagerProps.CALENDAR_PERIOD,
        null
    );

    if (activeCalendarPeriod) {
        dispatch(toggleActivePeriodFromCache(activeCalendarPeriod));
    }
};

const showMessage = message => reloadWithParam('msg', message);
