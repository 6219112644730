export const se_orebro = {
    ui: {
        SETTINGS_BUSINESS_OBJECT: 'Hiss',
        OBJECTS: 'Hissar',
        MANAGE_OBJECTS: 'Hantera hissar',
        MANAGE_MODELS: 'Hantera hisstyper',
        MODELS: 'Hisstyper',
        PRICE_LIST: 'Hissens Prislista',
        
    },
    form: { 
        SERIAL: 'Hiss ID',
        OBJECT: 'Hiss',
        OBJECT_COUNT: 'Hissar',
        LOCATION_DESCRIPTION: 'Placering',
    },
    acl: {
        PERMISSION_BUSINESS_OBJECTS_READ: 'Se hiss',
        PERMISSION_BUSINESS_OBJECTS_EDIT: 'Ändra hiss',
    },

     helpers: {

        OBJECT_AGREEMENT_INVOICE_MARKING_HELPTEXT:
            'Denna märkning läggs automatiskt på alla nya arbetsordrar till denna hiss samt visas på avtalsfakturan',

     },


}