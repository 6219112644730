import { useSelector } from 'react-redux';

export const useInstanceData = () => {

    const dateFormat = useSelector(state => state.auth.instance.configuration.dateFormat);
    const appModules = useSelector(state => state.auth.appModules);
    const currency = useSelector(state => state.auth.instance.configuration.currency.symbol);

    const hasAppModule = requiredModule => appModules && !!appModules[requiredModule];

    return {
        hasAppModule,
        appModules,
        dateFormat,
        currency
    }
}