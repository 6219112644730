import articleService from 'data/services/article/articleService';
import supplierInventoryService from 'data/services/supplier-inventory/supplierInventoryService';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import LocalizedSearch from '../../common/localized-search';
import './ArticlePicker.css';
import { v4 as uuidv4 } from 'uuid';

const SearchOption = ({ t, type, title, description, supplierId }) => {
    return (
        <div className="ui feed" style={{ margin: '0.4em' }}>
            <div className="event">
                <div className="content">
                    <div className="summary man-flex-row man-flex-row-sb">
                        <div>
                            <a className="user">{title}</a>
                        </div>
                        {type === 'supplier-inventory' && (
                            <div className="man-flex-row">
                                <div className="ui circular label yellow">
                                    B
                                </div>
                                {supplierId && 
                                    <div className="man-secondary-text">{t(`suppliers:${supplierId}`)}</div>
                                }
                            </div>
                        )}
                    </div>
                    <div className="meta" style={{ margin: 0 }}>
                        <a className="like">{description}</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

class ArticlePicker extends Component {
    state = {
        isLoading: false,
        searchValue: this.props.initialValue,
        searchResults: [],
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.clear) {
            this.setState({
                searchValue: '',
            });
        }

        if (newProps.value) {
            this.setState({
                searchValue: newProps.value,
            });
        }
    }

    componentDidMount() {
        if (this.props.focusOnMount) {
            this.handleMount();
        }
    }

    handleMount = () => {
        const ref = this.searchInputRef.querySelector(
            '.ui.search .ui.input input'
        );

        ref && ref.focus();
    };

    handleChange = async (e, { value }) => {
        if (!value) {
            this.props.onChange({ id: null });

            this.setState({
                isLoading: false,
                searchValue: '',
            });
            return;
        }

        this.setState({
            isLoading: true,
            searchValue: value,
        });

        this.query(value);
    };

    query = _.debounce(async value => {
        try {
            let results = await articleService.query(value);

            if (this.props.includeSupplierInventoryArticles) {
                const { articles } =
                    await supplierInventoryService.queryArticles(
                        null,
                        value,
                        0,
                        10
                    );

                results = [
                    ...results,
                    ...articles.map(r => ({
                        ...r,
                        type: 'supplier-inventory',
                        id: uuidv4(),
                        title: r.articleTitle,
                        description: r.articleDescription,
                        price: r.recommendedSalesPrice,
                        purchasePrice: r.purchasePrice,
                        ref: {
                            type: 'supplier-inventory',
                            supplierId: r.supplierId,
                            supplierArticleTitle: r.articleTitle
                        }
                    })),
                ];
            }

            this.setState({
                isLoading: false,
                searchResults: results,
            });
        } catch (err) {
            this.setState({
                isLoading: false,
            });
        }
    }, 350);

    handleSelect = (e, { result }) => {
        this.props.onChange(result);

        this.setState({
            searchValue: result.title,
            searchResults: [],
        });
    };

    renderSearchResult = article => (
        <SearchOption key={article.id} {...article} t={this.props.t} />
    );

    render() {
        return (
            <div
                className="man-std-articlepicker"
                ref={node => (this.searchInputRef = node)}>
                <LocalizedSearch
                    placeholder={this.props.t('ARTICLEPICKER_SELECT')}
                    results={this.state.searchResults}
                    loading={this.state.isLoading || this.props.isLoading}
                    onResultSelect={this.handleSelect}
                    onSearchChange={this.handleChange}
                    value={this.state.searchValue}
                    resultRenderer={this.renderSearchResult}
                    on
                />
            </div>
        );
    }
}

ArticlePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    value: PropTypes.string,
    focusOnMount: PropTypes.bool,
    includeSupplierInventoryArticles: PropTypes.bool,
};

ArticlePicker.defaultProps = {
    initialValue: '',
    focusOnMount: false,
    includeSupplierInventoryArticles: false,
};

    export default withTranslation(['ui', 'suppliers'])(ArticlePicker);
