import { toDate } from "../../../utils/DateUtils";

export const defaultPagination = [20, 50, 100];

export const defaultSort = key => {
    if (!!key) {
        return (a, b) => {
            if (a[key] === b[key]) return 0;

            if (!a[key]) return -1;

            if (!b[key]) return 1;

            return a[key] > b[key] ? 1 : -1;
        };
    }

    return (a, b) => 0;
};

export const defaultNumericSort = key => (a, b) => b[key] - a[key];

export const defaultNumericStringSort = key => (a, b) => {
    if (a[key] === b[key]) return 0;

    if (!a[key]) return 1;

    if (!b[key]) return -1;

    return a[key].localeCompare(b[key], undefined, { numeric: true });
};

export const defaultDateSort = key => {
    return (a, b) => {
        if (a[key] === b[key]) return 0;

        if (!a[key]) return 1;

        if (!b[key]) return -1;

        if (toDate(a[key]).isBefore(toDate(b[key]))) {
            return 1;
        }

        return -1;
    };
};

export const defaultDateSortPrimitive = () => {
    return (a, b) => {
        if (!a) return -1;

        if (!b) return 1;

        if (toDate(a).isBefore(toDate(b))) {
            return 1;
        }

        return -1;
    };
};