import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

export const NotPermitted = ({ t }) => (
    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', padding: 40 }}>
        <div className="ui huge statistic">
            <div className="value">
                403
            </div>
            <div className="label">
                {t && t('messages:WARNING_PERMISSION_DENIED')}
                <div><Link to="/">{t && t('BACK')}</Link></div>
            </div>
        </div>
    </div>
)

export const requirePermission = (ComposedComponent, requiredPermission, NotPermittedComponent = null) => {
    class RequirePermission extends Component {

        render() {
            if (!requiredPermission) {
                return <ComposedComponent {...this.props} />
            }

            const isPermitted = this.props.userPermissions.indexOf(requiredPermission) !== -1;

            if (isPermitted) {
                return <ComposedComponent {...this.props} />
            }

            return NotPermittedComponent ?
                <NotPermittedComponent t={this.props.t} /> : null
        }
    }

    const mapStateToProps = state => ({
        userPermissions: state.auth.perms
    })

    return withTranslation(['ui', 'messages'])(connect(mapStateToProps)(RequirePermission));
}