const special = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

export const containsSpecialCharacters = s => special.test(s);

export const containsComma = s => s.indexOf(',') !== -1;

const roundToString = (value, decimals = 0) => (value || 0).toFixed(decimals);

export const formatPrice = (value, currency, decimals = 2) => {
    let val = parseFloat(value || 0);

    if (isNaN(val)) {
        val = 0;
    }

    return `${formatDecimals(roundToString(val, decimals))}${
        currency ? ` ${currency}` : ''
    }`.replaceAll('.', ',');
};

export const formatPriceSum = (value, currency) => {
    let val = parseFloat(value || 0);

    if (isNaN(val)) {
        val = 0;
    }

    return `${formatDecimals(val.toFixed(0))}${currency ? ` ${currency}` : ''}`;
};

export const formatDecimals = (value, separator = ' ') => {
    if (!value) {
        return '';
    }

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const formatPercent = value => {
    const val = parseFloat(value || 0);

    const formatted = val.toFixed(2);

    if (formatted.substring(formatted.length - 3, formatted.length) == '.00') {
        return formatted.substring(0, formatted.length - 3);
    }

    return val.toFixed(2);
};

export const generateId = (excludeIds = []) => {
    const id = Math.random().toString(36).substring(7);

    if (excludeIds.includes(id)) {
        return generateId(excludeIds);
    }

    return id;
};

export const generateIndexBetween = (lower, upper) => {
    if (lower == null && upper == null) {
        return 100;
    }

    if (lower == null) {
        return Math.floor(upper - 1);
    }

    if (upper == null) {
        return Math.ceil(lower + 1);
    }

    if (upper === lower) {
        // ??
    }

    return (upper - lower) / 2 + lower;
};

export const generateMaxIndex = indexes => {
    if (!indexes) {
        return 100;
    }

    const max = indexes
        .filter(v => v)
        .sort((a, b) => a - b)
        .pop();

    const res = generateIndexBetween(max, null);

    return res;
};

export const shortenText = (text, length) => {
    if (!text) {
        return '';
    }

    if (length > text.length) {
        return text;
    }

    const diff = text.length - length;

    if (diff > 2) {
        return `${text.substring(0, length)}...`;
    }

    return text;
};

export const formatCityName = cityName => {
    if (cityName === null || cityName === undefined) return '';

    const delimiter = getCityDelimiter(cityName);
    if (delimiter === '') {
        return cityName.capitalize();
    }

    const parts = cityName.split(delimiter);

    return parts.map(part => part.capitalize()).join(delimiter);
};

export const formatPostCode = postCode => {
    if (postCode === null || postCode === undefined) return '';
    const onlyNumbers = /^\d+$/.test(postCode);

    return onlyNumbers ? postCode.replace(/(\d{3})(\d)/, '$1 $2') : postCode;
};

const getCityDelimiter = cityName => {
    if (cityName.includes(' ')) return ' ';
    if (cityName.includes('-')) return '-';
    return '';
};

Object.defineProperty(String.prototype, 'capitalize', {
    value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
    },
    enumerable: false,
});

Object.defineProperty(String.prototype, 'capitalizeFirstLetter', {
    value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false,
});

export const safeJSONParse = str => {
    try {
        return JSON.parse(str);
    } catch {
        return null;
    }
};

export const isValidEmail = str => {
    if (!str) {
        return false;
    }

    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(str);
};
