export const se_jtsecurity = {
    ui: {

    },

    form: {
        LOCATION_DESCRIPTION: 'Anläggningsnamn',
    },

    acl: {
        
    },
};
