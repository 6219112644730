import React, { FC } from 'react';

interface Props {
    children?: object,
    style?: object
};

const Divider: FC<Props> = ({ children, style }) => (
    <h4 className="ui dividing header" style={style}>{children}</h4>
);

export default Divider;