import {
    LOGOUT_REQUEST,
    LOGOUT
} from '../../actions/common/authActions';

import {
    SHOW_PRINT,
    SHOW_PRINT_FULFILLED
} from '../../actions/common/uiActions';

import {
    CREATE_WORKORDER,
    CREATE_WORKORDER_FULFILLED,
    CREATE_WORKORDER_REJECTED
} from '../../actions/workorders';

export const loader = (
    state = {
        isLoading: false
    },
    action
) => {
    switch (action.type) {
        case CREATE_WORKORDER: return ({ ...state, isLoading: true })
        case CREATE_WORKORDER_REJECTED: return ({ ...state, isLoading: false })
        case CREATE_WORKORDER_FULFILLED: return ({ ...state, isLoading: false })
        case SHOW_PRINT: return ({ ...state, isLoading: true })
        case SHOW_PRINT_FULFILLED: return ({ ...state, isLoading: false })
        case LOGOUT_REQUEST: return ({ ...state, isLoading: true })
        case LOGOUT: return ({ ...state, isLoading: false })

        default: {
            return state;
        }
    }
};

