import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'semantic-ui-react';

class Notification extends React.Component {

    render() {
        const { className, onCloseClick, iconClass, text } = this.props;

        return (
            <Transition visible={true} duration={600} animation='fade right' transitionOnMount={true}>
                <div className={`man-notification ui icon message ${className}`}>
                    <i className="close icon" onClick={onCloseClick}></i>
                    <i className={`${iconClass} icon`} style={{ fontSize: '1em' }}></i>
                    <div className="content">
                        <p style={{ margin: 0, fontWeight: 700 }}>
                            {text}
                        </p>
                        <div className="ui tiny progress man-notification-progress" style={{ width: '100%', margin: 0, fontSize: '.5rem' }}>
                            <div className="bar"></div>
                        </div>
                    </div>
                </div>
            </Transition>
        )
    }
}

Notification.propTypes = {
    className: PropTypes.string,
    onCloseClick: PropTypes.func,
    iconClass: PropTypes.string,
    text: PropTypes.string
}

export default Notification;
