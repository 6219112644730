import React from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './ToggleButtonGroup.css';

const ToggleButtonGroup = ({ toggleButtons, disabled, toggeled, onClick, size, positiveColor, negativeColor, classNames, style }) => {
    return (
        <Button.Group size={size} className={classNames} style={style} >
            {toggleButtons.map(btn => (
                <Button
                    disabled={disabled}
                    compact
                    className={toggeled == btn.value ? 'man-toggeled-button' : 'man-untoggeled-button'}
                    style={{ backgroundColor: toggeled == btn.value ? positiveColor : negativeColor }}
                    size={size}
                    key={btn.key}
                    content={btn.text}
                    onClick={() => onClick(btn.value)}
                />
            )
            )}
        </Button.Group>
    )
}

ToggleButtonGroup.propTypes = {
    toggleButtons: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string.isRequired,
        key: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired
    })),
    toggeled: PropTypes.any.isRequired,
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    positiveColor: PropTypes.string,
    negativeColor: PropTypes.string,
    disabled: PropTypes.bool
}

ToggleButtonGroup.defaultProps = {
    size: 'small',
    positiveColor: 'var(--color-accent)',
    negativeColor: 'var(--color-light)',
    disabled: false
}

export default ToggleButtonGroup
