import {
    FETCH_SUPPLIERS,
    FETCH_SUPPLIERS_FULFILLED,
    FETCH_SUPPLIERS_REJECTED,
    SUPPLIER_CHANGED,
    SUPPLIER_DELETED,
    SUPPLIER_TABLE_STATE_CHANGE,
    SUPPLIER_FILTERS_CHANGE
} from '../../actions/suppliers';

const defaultState = {
    isFetching: false,
    suppliers: [],
    tableState: undefined,
    filters: {
        tag: null
    },
}

export const suppliers = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case SUPPLIER_TABLE_STATE_CHANGE: return ({ ...state, tableState: action.state })
        case FETCH_SUPPLIERS: {
            return {
                ...state,
                isFetching: true
            }
        }
        case SUPPLIER_FILTERS_CHANGE: return ({
            ...state,
            filters: action.filters
        })
        case FETCH_SUPPLIERS_FULFILLED: {
            return {
                ...state,
                isFetching: false,
                suppliers: action.suppliers
            }
        }
        case FETCH_SUPPLIERS_REJECTED: {
            return {
                ...state,
                isFetching: false
            }
        }
        case SUPPLIER_CHANGED: {
            const suppliers = [...state.suppliers];
            const idx = suppliers.findIndex(s => s.id === action.supplier.id);

            if (idx < 0) {
                suppliers.push(action.supplier);
            } else {
                suppliers[idx] = action.supplier;
            }

            return {
                ...state,
                suppliers
            }
        }
        case SUPPLIER_DELETED: {
            const suppliers = [...state.suppliers];
            const idx = suppliers.findIndex(s => s.id === action.id);

            suppliers.splice(idx, 1);

            return {
                ...state,
                suppliers
            }
        }
        default: {
            return state;
        }
    }
};