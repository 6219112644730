import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Popup } from 'semantic-ui-react';
import { FlatButton } from 'trukks-lib';
import BottomSheet from '../bottom-sheet';
import InputNumeric from '../input-numeric';
import LocalizedDropdown from '../localized-dropdown';

class PopupInput extends Component {

    state = {
        isEditing: this.props.defaultOpen,
        value: this.props.value,
        error: '',
        isLoading: false
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.props.value !== this.state.value && this.props.name === newProps.name) {
            return;
        }

        if (this.props.name !== newProps.name) {
            this.props.onSave(this.props.name, this.state.value);
        }

        this.setState({
            value: newProps.value
        });
    }

    handleOpen = e => {
        console.log('handleOpen')
        e.preventDefault();
        e.stopPropagation();
        this.setState({ isEditing: true });
    }

    handleClose = () => {
        this.setState({ isEditing: false });
        if (this.props.onCloseCallback) {
            this.props.onCloseCallback();
        }
    }

    handleChange = e => {
        if (this.props.disabled) {
            return;
        }

        this.setState({ [e.target.name]: e.target.value });
    }

    handleValueChange = (name, value) => {
        if (this.props.disabled) {
            return;
        }

        this.setState({
            [name]: value
        })
    }

    handleSubmit = async () => {
        if (this.props.validate) {
            let validationResult = this.props.validate(this.state.value);
            if (validationResult) {
                this.setState({ error: validationResult })
                return;
            } else {
                this.setState({ error: '' })
            }
        }

        this.setState({ isLoading: true })

        try {
            await this.props.onSave(this.props.name, this.state.value);
            this.setState({ isLoading: false, isEditing: false })
        } catch (err) {
            this.setState({ isLoading: false })
            console.error(err)
        }
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit();
        }
    }

    getField = (type) => {
        if (type === 'select') {
            return (
                <LocalizedDropdown
                    ref={node => (this.inputRef = node)}
                    key={this.props.name}
                    compact
                    name="value"
                    onChange={this.handleChange}
                    options={this.props.options}
                    value={this.state.value}
                    floating button search
                    className="icon" />
            )
        }

        if (type === 'number') {
            return (
                <InputNumeric
                    focus={true}
                    disabled={this.props.disabled}
                    key={this.props.name}
                    style={{ minWidth: 125 }}
                    name="value"
                    type={type}
                    value={this.state.value}
                    onValueChange={val => this.handleValueChange('value', val)}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}></InputNumeric>
            )
        }

        if (type === 'textarea') {
            return (
                <div className="ui form">
                    <TextareaAutosize
                        disabled={this.props.disabled}
                        inputRef={node => (this.textareaRef = node)}
                        key={this.props.name}
                        style={{
                            minWidth: 360,
                            maxWidth: '100%'
                        }}
                        minRows={2}
                        value={this.state.value}
                        onChange={e => this.handleValueChange('value', e.target.value)} />
                </div>
            )
        }

        return (
            <input
                ref={node => (this.inputRef = node)}
                style={{ minWidth: 220 }}
                name="value"
                type={type}
                value={this.state.value}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}></input>
        )
    }

    handleMount = () => {
        if (this.textareaRef) {
            console.log(this.textareaRef)
            if (this.textareaRef.focus) {
                this.textareaRef.focus({
                    preventScroll: true
                })
            }
        }

        if (this.inputRef) {
            if (this.inputRef.focus) {
                this.inputRef.focus({
                    preventScroll: true
                })
            }
        }
    };


    render() {
        const trigger = this.props.trigger ?
            this.props.isSmallScreen ? <div onClick={this.handleOpen}>{this.props.trigger}</div> : this.props.trigger
            : <a style={{
                opacity: this.props.text || this.props.value ? 1 : 0.5
            }} onClick={this.handleOpen}>{this.props.text ? this.props.text : this.props.value ? this.props.value : this.props.placeholder}</a>;


        if (this.props.isSmallScreen) {
            return (
                <>
                    <BottomSheet open={this.state.isEditing}
                        onChange={() => this.handleClose()}>
                        <div className="man-flex-row man-flex-row-sb">
                            <div></div>
                            <FlatButton
                                onClick={() => this.handleClose()}
                                icon="remove">
                                {this.props.t('ui:CLOSE')}
                            </FlatButton>
                        </div>
                        {/* COPY OF NON-mobile  */}
                        {/* TODO migrate to common fn */}
                        {this.props.label &&
                            <label className="man-secondary-text">{this.props.label}</label>
                        }
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginTop: 4
                        }}>
                            <div className={`ui input${this.state.error ? ' error' : ''}`}
                                style={{
                                    marginRight: 4
                                }}>
                                {this.getField(this.props.type)}
                            </div>
                            <div>
                                {this.props.extra}
                            </div>
                        </div>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginTop: 4
                        }}>

                            <div style={{
                                marginRight: 4
                            }}>
                                {this.props.children}
                            </div>
                            <div>
                                {this.props.disabled !== true &&
                                    <Button className={`man-button-primary${this.state.isLoading ? ' loading' : ''}`}
                                        style={{
                                            maxHeight: 36
                                        }}
                                        onClick={this.handleSubmit}>
                                        {this.props.t('ui:SAVE')}
                                    </Button>
                                }
                                <div className="ui icon button"
                                    style={{
                                        maxHeight: 36
                                    }}
                                    onClick={this.handleClose}>
                                    <i className="icon close"></i>
                                </div>
                            </div>
                        </div>

                        {this.state.error &&
                            <div style={{ color: '#9f3a38', marginTop: 5 }}>
                                {this.props.t(this.state.error)}
                            </div>}
                    </BottomSheet>

                    {trigger}
                </>
            )
        }

        return (
            <Popup
                trigger={trigger}
                on='click'
                open={this.state.isEditing}
                onClose={this.handleClose}
                onOpen={this.handleOpen}
                onMount={this.handleMount}
                style={this.props.style}
                position={this.props.position || 'top left'}>
                {this.props.label &&
                    <label className="man-secondary-text">{this.props.label}</label>
                }
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 4
                }}>
                    <div className={`ui input${this.state.error ? ' error' : ''}`}
                        style={{
                            marginRight: 4
                        }}>
                        {this.getField(this.props.type)}
                    </div>
                    <div>
                        {this.props.extra}
                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: 4
                }}>

                    <div style={{
                        marginRight: 4
                    }}>
                        {this.props.children}
                    </div>
                    <div>
                        {this.props.disabled !== true &&
                            <Button className={`man-button-primary${this.state.isLoading ? ' loading' : ''}`}
                                style={{
                                    maxHeight: 36
                                }}
                                onClick={this.handleSubmit}>
                                {this.props.t('ui:SAVE')}
                            </Button>
                        }
                        <div className="ui icon button"
                            style={{
                                maxHeight: 36
                            }}
                            onClick={this.handleClose}>
                            <i className="icon close"></i>
                        </div>
                    </div>
                </div>

                {this.state.error &&
                    <div style={{ color: '#9f3a38', marginTop: 5 }}>
                        {this.props.t(this.state.error)}
                    </div>}

            </Popup>
        )
    }
}

PopupInput.propTypes = {
    onSave: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    validate: PropTypes.func,
    text: PropTypes.any,
    trigger: PropTypes.object,
    onCloseCallback: PropTypes.func,
    inputLabel: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    defaultOpen: PropTypes.bool
}

PopupInput.defaultProps = {
    type: 'text',
    placeholder: '',
    disabled: false,
    defaultOpen: false
}

const mapStateToProps = state => ({
    isSmallScreen: state.ui.smallScreen
})

export default connect(mapStateToProps, null)(withTranslation(['messages', 'ui'])(PopupInput));