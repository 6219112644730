import * as qs from 'query-string';
import {
    deletee,
    get,
    getFile,
    patch,
    post,
    put,
} from '../../../utils/http/HttpClient';

export default {
    query: async (year, month) => {
        const { projects } = await get(`/projects/month/${year}/${month}`);

        return projects;
    },
    queryByTitle: async q => {
        const { projects } = await get(`/projects/q?q=${q}`);

        return projects;
    },
    fetchDetails: async id => {
        const { project } = await get(`/projects/${id}`);

        return project;
    },
    fetchOngoing: async () => {
        const { projects } = await get('/projects/ongoing');

        return projects;
    },
    fetchClosed: async () => {
        const { projects } = await get('/projects/closed');

        return projects;
    },
    fetchWorkOrders: async id => {
        const { workOrders } = await get(`/projects/${id}/workorders`);

        return workOrders;
    },
    create: async () => {
        const { id } = await post('/projects');

        return id;
    },
    update: async project => {
        await put('/projects', project);
    },
    updateConfiguration: async (projectId, conf) => {
        await put(`/projects/${projectId}/conf`, conf);
    },
    close: async id => {
        await post(`/projects/${id}/close`);
    },
    reopen: async id => {
        await post(`/projects/${id}/reopen`);
    },
    delete: async id => {
        await deletee(`/projects/${id}`);
    },
    addCustomer: async (projectId, customerId) => {
        await post(`/projects/${projectId}/customers`, {
            id: customerId,
        });
    },
    removeCustomer: async (projectId, customerId) => {
        await deletee(`/projects/${projectId}/customers/${customerId}`);
    },
    addSupplier: async (projectId, supplierId) => {
        await post(`/projects/${projectId}/suppliers`, {
            id: supplierId,
        });
    },
    removeSupplier: async (projectId, supplierId) => {
        await deletee(`/projects/${projectId}/suppliers/${supplierId}`);
    },
    addBusinessObject: async (projectId, businessObjectId) => {
        await post(`/projects/${projectId}/bos`, {
            id: businessObjectId,
        });
    },
    removeBusinessObject: async (projectId, businessObjectId) => {
        await deletee(`/projects/${projectId}/bos/${businessObjectId}`);
    },
    patchWorkOrderInvoicingStatus: async (projectId, workOrderId, status) => {
        await patch(`/projects/${projectId}/workorders/status`, {
            workOrderId,
            status,
        });
    },
    fetchProjectArticles: async projectId => {
        const { articles } = await get(`/projects/${projectId}/articles`);

        return articles;
    },
    fetchInvoices: async projectId => {
        const { invoices } = await get(`/projects/${projectId}/invoices`);

        return invoices;
    },
    fetchInvoice: async (projectId, invoiceId) => {
        const { invoice } = await get(
            `/projects/${projectId}/invoices/${invoiceId}`
        );

        return invoice;
    },
    createInvoice: async (projectId, data) => {
        const { id } = await post(`/projects/${projectId}/invoices`, data);

        return id;
    },
    updateInvoice: async (projectId, invoice) => {
        await put(`/projects/${projectId}/invoices`, invoice);
    },
    deleteInvoice: async (projectId, invoiceId) => {
        await deletee(`/projects/${projectId}/invoices/${invoiceId}`);
    },
    patchProjectArticleStatus: async (projectId, rowId, status, invoiceId) => {
        await patch(`/projects/${projectId}/articles/status`, {
            status,
            rowId,
            invoiceId,
        });
    },
    closeInvoice: async (projectId, invoiceId) => {
        await post(`/projects/${projectId}/invoices/${invoiceId}/close`);
    },
    reopenInvoice: async (projectId, invoiceId) => {
        await post(`/projects/${projectId}/invoices/${invoiceId}/reopen`);
    },
    fetchOngoingCost: async projectId => {
        const { totalCost, snapshot } = await get(
            `/projects/${projectId}/snapshot`
        );

        return {
            totalCost,
            snapshot,
        };
    },
    createSnapshot: async (projectId, ongoingCost) => {
        await post(`/projects/${projectId}/snapshot`, {
            ongoingCost,
        });
    },
    pdf: async (projectId, templateId, data, fileName, project) => {
        let requestData = {
            templateId
        }

        if (data) {
            requestData = {
                templateId,
                details: data
            }
        }

        try {
            await getFile(
                `/projects/${projectId}/pdf`,
                requestData,
                fileName ? fileName.replace('{{title}}', project?.title).replace('{{displayName}}', project?.displayName) : project?.displayName || '',
                'pdf',
                'pdf',
                !!fileName
            );
        } catch (err) {
            console.error(err);
        }
    },
    exportWord: async (projectId, templateId) => {
        // TODO
        try {
            await getFile(
                `/projects/${projectId}/pdf`,
                {
                    templateId,
                },
                'project',
                'pdf',
                'pdf'
            );
        } catch (err) {
            console.error(err);
        }
    },
    fetchTimereports: async (id, startTime, endTime) => {
        const { reports } = await get(
            `/projects/${id}/timereports?${qs.stringify({
                startTime,
                endTime,
            })}`
        );

        return reports;
    },
    fetchResourceTimes: async (year, month) => {
        const { projectTimes, projectTimesById } = await get(
            `/projects/resources/${year}/${month}`
        );

        return {
            reports: projectTimes,
            reportsById: projectTimesById,
        };
    },
    deleteResourceTime: async id => {
        await deletee(`/projects/resources/${id}`);
    },
    updateResourceTime: async time => {
        if (!time.id || time.id === 'new') {
            await post('/projects/resources', time);
            return;
        }

        await put('/projects/resources', time);
    },
    fetchActiveSuppliers: async () => {
        const { suppliers } = await get(`/projects/resources/suppliers`);

        return suppliers;
    },
    fetchActivities: async projectId => {
        const { activities } = await get(`/projects/${projectId}/activities`);

        return activities;
    },
    createActivity: async (projectId, sortOrder) => {
        const { id } = await post(`/projects/${projectId}/activities`, {
            sortOrder
        });

        return id;
    },
    reorderActivity: async (projectId, id, sortOrder) => {
        await put(`/projects/${projectId}/activities/${id}/reorder`, {
            id,
            sortOrder
        });
    },
    completeActivity: async (projectId, id) => {
        await put(`/projects/${projectId}/activities/${id}/complete`, {});
    },
    deleteActivity: async (projectId, id) => {
        await deletee(`/projects/${projectId}/activities/${id}`, {});
    },
    updateActivity: async (projectId, activity) => {
        await put(`/projects/${projectId}/activities/${activity.id}`, activity);
    }
};
