import reportsService from '../../services/reports/reportsService';
import { showErrorNotification } from '../common/notificationActions';

export const REPORTS_WORKORDER_TABLE_STATE_CHANGE = 'REPORTS_WORKORDER_TABLE_STATE_CHANGE';
export const REPORTS_WORKORDER_FILTERS_CHANGE = 'REPORTS_WORKORDER_FILTERS_CHANGE';
export const FETCH_REPORTS_WORK_ORDERS = 'FETCH_REPORTS_WORK_ORDERS';
export const FETCH_REPORTS_WORK_ORDERS_FULFILLED = 'FETCH_REPORTS_WORK_ORDERS_FULFILLED';
export const FETCH_REPORTS_WORK_ORDERS_REJECTED = 'FETCH_REPORTS_WORK_ORDERS_REJECTED';

export const editClosedWorkOrderReportsFilter = filters => ({
    type: REPORTS_WORKORDER_FILTERS_CHANGE,
    filters
});

export const editClosedWorkOrdersTableState = state => ({
    type: REPORTS_WORKORDER_TABLE_STATE_CHANGE,
    state
});

const closedWorkOrdersFetch = () => ({
    type: FETCH_REPORTS_WORK_ORDERS
});

const closedWorkOrdersFulfilled = workOrders => ({
    type: FETCH_REPORTS_WORK_ORDERS_FULFILLED,
    workOrders
});

const closedWorkOrdersRejected = error => ({
    type: FETCH_REPORTS_WORK_ORDERS_REJECTED,
    error
});

export const fetchClosedWorkOrders = ({ startTime, endTime, customerId, workType, workOrderTitle }) => async dispatch => {
    dispatch(closedWorkOrdersFetch());

    try {
        const response = await reportsService.fetchWorkOrderHistory(startTime, endTime, customerId, workType, workOrderTitle);

        dispatch(closedWorkOrdersFulfilled(response.workOrders));
    } catch (err) {
        dispatch(closedWorkOrdersRejected(err));
        dispatch(showErrorNotification('FAILED_TO_FETCH'));
    }
}