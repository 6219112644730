import {
    deletee,
    get,
    getFile,
    post,
    put,
} from '../../../utils/http/HttpClient';
import CustomerInvoiceListGetResponse from '../customer/api/CustomerInvoiceListGetResponse';
import instanceService from '../instance/instanceService';
import BusinessObjectAgreement from './api/BusinessObjectAgreement';
import * as qs from 'query-string';

export default {
    fetch: async includeSpecification => {
        const start = new Date().getTime();

        const { bos } = await get(
            `/bo${includeSpecification ? '?includeSpecificationData=true' : ''} `
        );

        const end = new Date().getTime();
        const time = end - start;

        instanceService.postMetric('bos', time);

        return bos;
    },
    fetchForCustomer: async customerId => {
        const { bos } = await get(
            `/bo?customerId=${customerId}`
        );

        return bos;
    },
    fetchDetails: async id => {
        const { bo } = await get(`/bo/${id}`);

        return bo;
    },
    fetchCustomerDetails: async id => {
        const { customer } = await get(`/bo/${id}/customer`);

        return customer;
    },
    fetchSpecification: async id => {
        const { specification } = await get(`/bo/${id}/spec`);

        return specification.data;
    },
    updateSpecification: async (id, specification) => {
        await put(`/bo/${id}/spec`, {
            data: specification,
        });
    },
    fetchCustomSpecification: async (id, type) => {
        const { data } = await get(`/bo/${id}/spec/custom/${type}`);

        return data;
    },
    updateCustomSpecification: async (id, type, data) => {
        await put(`/bo/${id}/spec/custom/${type}`, {
            businessObjectId: id,
            data,
        });
    },
    create: async bo => {
        const { id } = await post('/bo', { bo });

        return id;
    },
    update: async bo => {
        return await put('/bo', { bo });
    },
    updateContacts: async (id, contacts) => {
        await put(`/bo/${id}/contacts`, {
            contacts,
        });
    },
    delete: async id => {
        await deletee(`/bo/${id}`);
    },
    fetchHistory: async (id, from, pageSize) => {
        const response = await get(
            `/bo/${id}/history?from=${from}&size=${pageSize}`
        );

        return new CustomerInvoiceListGetResponse(response);
    },
    fetchAgreementDetails: async id => {
        const { agreement } = await get(`/bo/${id}/agreement`);

        return new BusinessObjectAgreement(agreement, id);
    },
    updateAgreement: async (id, agreement) => {
        await put(`/bo/${id}/agreement`, { agreement });
    },
    fetchAgreementWorkOrders: async id => {
        const { agreementWorkOrders } = await get(`/bo/${id}/agreement/wo`);

        return agreementWorkOrders;
    },
    createAgreementWorkOrder: async boId => {
        const { id } = await post(`/bo/${boId}/agreement/wo`);

        return id;
    },
    updateAgreementWorkOrder: async (boId, wo) => {
        await put(`/bo/${boId}/agreement/wo`, wo);
    },
    fetchAgreementWorkOrderDetails: async (boId, id) => {
        const { agreementWorkOrder } = await get(`/bo/${boId}/agreement/wo/${id}`);

        return agreementWorkOrder;
    },
    deleteAgreementWorkOrder: async (boId, id) => {
        await deletee(`/bo/${boId}/agreement/wo/${id}`);
    },
    fetchAgreementInvoices: async id => {
        const { agreementInvoices } = await get(`/bo/${id}/agreement/invoice`);

        return agreementInvoices;
    },
    createAgreementInvoice: async boId => {
        const { id } = await post(`/bo/${boId}/agreement/invoice`);

        return id;
    },
    updateAgreementInvoice: async (boId, i) => {
        await put(`/bo/${boId}/agreement/invoice`, i);
    },
    fetchAgreementInvoiceDetails: async (boId, id) => {
        const { agreementInvoice } = await get(`/bo/${boId}/agreement/invoice/${id}`);

        return agreementInvoice;
    },
    deleteAgreementInvoice: async (boId, id) => {
        await deletee(`/bo/${boId}/agreement/invoice/${id}`);
    },
    fetchArticles: async id => {
        const { articles } = await get(`/bo/${id}/articles`);

        return articles;
    },
    createArticle: async ({ businessObjectId, price, articleId }) => {
        const { id } = await post('/bo/articles', {
            businessObjectId,
            price,
            articleId,
        });

        return id;
    },
    updateArticle: async ({ businessObjectId, price, articleId }) => {
        await put('/bo/articles', {
            businessObjectId,
            price,
            articleId,
        });
    },
    deleteArticle: async ({ businessObjectId, articleId }) => {
        await deletee(
            `/bo/${businessObjectId}/articles?articleId=${articleId}`
        );
    },
    fetchProposedId: async length => {
        const { id } = await get(`/bo/id/propose?length=${length}`);

        return id;
    },
    fetchSecurityDetailsForm: async id => {
        const { businessObjectId, data, articles } = await get(
            `/bo/${id}/sec/form`
        );

        return {
            responseData: data,
            articles,
        };
    },
    updateSecurityDetailsForm: async (id, data) => {
        await put(`/bo/${id}/sec/form`, data);
    },
    exportSecurityFormToWord: async id => {
        await getFile(
            `/bo/${id}/sec/form/export`,
            {
                businessObjectId: id,
                type: 'docx'
            },
            'anläggarintyg',
            '.docx',
            'anläggarintyg'
        );
    },
    exportSecurityFormToPdf: async (id, signature, excludedPages) => {
        await getFile(
            `/bo/${id}/sec/form/export`,
            {
                businessObjectId: id,
                type: 'pdf',
                signature,
                excludedPages
            },
            'anläggarintyg',
            '.pdf',
            'anläggarintyg'
        );
    },
    fetchSecurityTypePassage: async id => {
        const { businessObjectId, data } = await get(`/bo/${id}/sec/passage`);

        return data;
    },
    updateSecurityTypePassage: async (id, data) => {
        await put(`/bo/${id}/sec/passage`, {
            businessObjectId: id,
            data,
        });
    },
    fetchSecurityTypeCamera: async id => {
        const { businessObjectId, data } = await get(`/bo/${id}/sec/camera`);

        return data;
    },
    updateSecurityTypeCamera: async (id, data) => {
        await put(`/bo/${id}/sec/camera`, {
            businessObjectId: id,
            data,
        });
    },
    fetchSecurityTypeFirealarm: async id => {
        const { businessObjectId, data } = await get(`/bo/${id}/sec/firealarm`);

        return data;
    },
    updateSecurityTypeFirealarm: async (id, data) => {
        await put(`/bo/${id}/sec/firealarm`, {
            businessObjectId: id,
            data,
        });
    },
    fetchSecurityTypeAssault: async id => {
        const { businessObjectId, data } = await get(`/bo/${id}/sec/assault`);

        return data;
    },
    updateSecurityTypeAssault: async (id, data) => {
        await put(`/bo/${id}/sec/assault`, {
            businessObjectId: id,
            data,
        });
    },
    fetchServiceLogs: async (businessObjectId, from) => {
        const { logs, totalCount } = await get(
            `/bo/${businessObjectId}/servicelog?from=${from}`
        );

        return {
            logs,
            totalCount,
        };
    },
    createServiceLog: async businessObjectId => {
        const { id } = await post(`/bo/${businessObjectId}/servicelog`);

        return id;
    },
    updateServiceLog: async (businessObjectId, logId, entry) => {
        await put(`/bo/${businessObjectId}/servicelog/${logId}`, {
            entry: entry || '',
        });
    },
    scheduleAgreementServiceWorkOrders: async (ids) => {
        await post(`/bo/agreement/wo/schedule`, {
            objectAgreementIds: ids
        });
    },
    fetchCodeGroups: async id => {
        const { groups } = await get(`/bo/${id}/codes/groups`);

        return groups;
    },
    createCodeGroup: async (businessObjectId, displayName) => {
        const { id } = await post(`/bo/${businessObjectId}/codes/groups`, {
            displayName,
            businessObjectId
        });

        return id;
    },
    updateCodeGroup: async (groupId, businessObjectId, displayName) => {
        await put(`/bo/${businessObjectId}/codes/groups`, {
            displayName,
            id: groupId,
            businessObjectId
        });
    },
    deleteCodeGroup: async (businessObjectId, groupId) => {
        await deletee(`/bo/${businessObjectId}/codes/groups/${groupId}`);
    },
    fetchCodesForGroup: async (businessObjectId, groupId) => {
        const { codes } = await get(`/bo/${businessObjectId}/codes/groups/${groupId}`);

        return codes;
    },
    createCode: async (businessObjectId, groupId, displayName, value, sortOrder) => {
        const { id } = await post(`/bo/${businessObjectId}/codes/groups/code`, {
            businessObjectId,
            groupId,
            displayName,
            value,
            sortOrder
        });

        return id;
    },
    updateCode: async (businessObjectId, code) => {
        await put(`/bo/${businessObjectId}/codes/groups/code`, code);
    },
    deleteCode: async (businessObjectId, codeId) => {
        await deletee(`/bo/${businessObjectId}/codes/groups/code/${codeId}`);
    },
    fetchCodeValue: async (businessObjectId, codeId, isCopy) => {
        const { value } = await get(`/bo/${businessObjectId}/codes/groups/code/${codeId}?copy=${isCopy ? '1' : '0'}`);

        return value;
    },
    updateCodeValue: async (businessObjectId, codeId, value) => {
        await put(`/bo/${businessObjectId}/codes/groups/code/${codeId}`, {
            id: codeId,
            value
        });
    },
    fetchLogs: async (businessObjectId, userId) => {
        const query = {
            businessObjectId,
            userId
        }

        const { logs } = await get(`/bo/codes/logs?${qs.stringify(query)}`);

        return logs;
    }
};
