import React, { FC } from 'react';
import styles from './LineLoader.module.css';

interface Props {
    lines?: number
};

const LineLoader: FC<Props> = ({ lines = 1 }) => {

    return (
        <div className={`${styles.lineLoader} ui placeholder`}>
            {Array.from(Array(lines).keys()).map(i =>
                <div key={i} className="ui line"></div>
            )}
        </div>
    )
};

export default LineLoader;