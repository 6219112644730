import withInstanceData from 'components/common/with-instancedata';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { appModules as modules } from 'utils/constants/appModules';
import { erp } from 'utils/constants/erp';
import { showErrorNotification, showSuccessNotification } from '../../../data/actions/common/notificationActions';
import { hideModal, openPrint } from '../../../data/actions/common/uiActions';
import invoiceService from '../../../data/services/invoice/invoiceService';
import { invoiceType } from '../../../utils/constants';
import Loader from '../../common/loader';
import Invoice from './Invoice';

const parseExternalId = invoice => {
    try {
        const ref = JSON.parse(invoice.erpReference);

        return ref?.externalId
    } catch (err) {
        return '';
    }
}

class InvoiceContainer extends Component {
    state = {
        isLoading: true,
        invoice: null,
        externalId: null,
        useExternalId: this.props.appModules[modules.UI_SETTINGS]?.custom?.invoice?.useExternalId,
        erpId: this.props.appModules[modules.ERP]?.custom?.erpId
    }

    componentDidMount() {
        this.fetchInvoice(this.props);
    }

    fetchInvoice = async props => {
        const { closeModal, showError, workOrderId, salesOrderId, projectInvoiceId, id, appModules } = props;

        this.setState({
            isLoading: true
        }, async () => {
            try {
                const invoice = workOrderId ?
                    await invoiceService.fetchDetailsForWorkOrder(workOrderId) :
                    salesOrderId ?
                        await invoiceService.fetchDetailsForSalesOrder(salesOrderId) :
                        projectInvoiceId ?
                            await invoiceService.fetchDetailsForProjectInvoice(projectInvoiceId) :
                            await invoiceService.fetchDetails(id);

                let externalId = null;

                if (this.state.useExternalId) {
                    if (this.state.erpId === erp.FORTNOX) {
                        externalId = invoice?.erpReferenceId;
                    } else if (this.state.erpId === erp.QUICKBOOKS) {
                        externalId = parseExternalId(invoice);
                    } else {
                        const { externalInvoiceNr } = await invoiceService.fetchErpDetails(invoice.id);
                        externalId = externalInvoiceNr;
                    }
                }

                this.setState({
                    isLoading: false,
                    invoice,
                    externalId
                })
            } catch (err) {
                this.setState({
                    isLoading: false
                })

                closeModal();
                showError('messages:ERROR_LOADING_INVOICE');
            }
        })
    }

    handleApply = async type => {
        const { invoice } = this.state;
        const { showSuccess, showError } = this.props;

        this.setState({ isLoading: true });
        try {
            if (type == invoiceType.EMAIL) {
                const recipient = await invoiceService.applyWithoutDownload(type, invoice.id, invoice.invoiceNr);
                this.setState({ isLoading: false });
                showSuccess('messages:EMAIL_SENT_TO', { email: recipient[0] });
                return;
            }

            if (type.substring(0, 4) === invoiceType.ERP) {
                await invoiceService.applyWithoutDownload(type, invoice.id, invoice.invoiceNr);
                this.setState({ isLoading: false });
                showSuccess('messages:SUCCESSFULLY_SAVED');
                return;
            }

            await invoiceService.apply(type, invoice.id, invoice.invoiceNr);
            this.setState({ isLoading: false });
        } catch (err) {
            this.setState({ isLoading: false });
            showError('messages:ERROR_HANDLING_APPLY');
        }
    }

    handlePrint = () => {
        this.props.handlePrint(
            <Invoice
                instanceConfiguration={this.props.instanceConfiguration}
                onApply={this.handleApply}
                invoice={this.state.invoice}
                onPrint={this.handlePrint}
                useExternalId={this.state.useExternalId}
                externalId={this.state.externalId}
                erpId={this.state.erpId}
            />
        );
    }

    render() {
        const { t, instanceConfiguration } = this.props;

        const { isLoading, invoice, useExternalId, externalId, erpId } = this.state;

        if (isLoading) {
            return (
                <Loader text={t('PROCESSING')}></Loader>
            )
        }

        if (!invoice) {
            return null;
        }

        return (
            <Invoice
                instanceConfiguration={instanceConfiguration}
                onApply={this.handleApply}
                invoice={invoice}
                onPrint={this.handlePrint}
                useExternalId={useExternalId}
                externalId={externalId}
                erpId={erpId}
            />
        )
    }
}

const mapStateToProps = state => ({
    instanceConfiguration: state.auth.instance.configuration
})

const mapDispatchToProps = dispatch => ({
    showError: text => dispatch(showErrorNotification(text)),
    showSuccess: (text, params) => dispatch(showSuccessNotification(text, params)),
    closeModal: () => dispatch(hideModal()),
    handlePrint: content => dispatch(openPrint(content))
})

export default withInstanceData(withTranslation(['ui', 'messages'])(connect(mapStateToProps, mapDispatchToProps)(InvoiceContainer)));