import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { appModules as modules } from '../../../utils/constants/appModules';
import { permission } from '../../../utils/constants/permissions';
import Icon from '../../common/icon';
import { withInstanceData } from '../../common/with-instancedata/WithInstanceData';
import { withPermissions } from '../../common/with-permissions/WithPermissions';
import './Topbar.css';
import TopbarUserDropdown from './TopbarUserDropdown';

const TopbarMenuItem = ({ href, title, icon, exact, count }) => (
    <NavLink to={href} exact={exact || false} className="item man-large-only">
        <Icon name={icon} />
        {title}
        {!count ?
            null
            :
            <div className="floating ui red label"
                style={{
                    left: 100,
                    top: 4
                }}>
                {count > 100 ? '99+' : count}
            </div>
        }
    </NavLink>
);

class Topbar extends Component {
    render() {
        const {
            t,
            hasPermission,
            hasAppModule,
            toggleSidebar,
            instanceLogoUrl,
            logout,
            user,
            departments,
            userType,
            hasDashboardAccess,
            appModules,
        } = this.props;

        const extraTopbarLinks =
            appModules[modules.UI_SETTINGS]?.custom?.topbarLinks || [];

        return (
            <Menu id="Topbar" icon="labeled" className="man-shadow">
                <Menu.Item
                    className="man-sidebar-toggle"
                    onClick={toggleSidebar}>
                    <Icon name="bars" />
                </Menu.Item>

                <div className="man-logo-container">
                    <Link
                        to="/"
                        style={{
                            backgroundImage: `url(${instanceLogoUrl})`,
                        }}></Link>
                </div>

                <div className="man-custom-container">
                    {extraTopbarLinks.length > 0 && (
                        <React.Fragment>
                            {extraTopbarLinks.indexOf('articles') > -1 && (
                                <Link
                                    to="/inventory/articles"
                                    className="man-internal-link man-large-only">
                                    {t('ARTICLES')}
                                </Link>
                            )}
                            {extraTopbarLinks.indexOf('customers') > -1 && (
                                <Link
                                    to="/inventory/customers"
                                    className="man-internal-link man-large-only">
                                    {t('CUSTOMERS')}
                                </Link>
                            )}
                            {extraTopbarLinks.indexOf('objects') > -1 && (
                                <Link
                                    to="/inventory/objects"
                                    className="man-internal-link man-large-only">
                                    {t('OBJECTS')}
                                </Link>
                            )}
                        </React.Fragment>
                    )}
                </div>

                {hasAppModule(modules.SUPPLIER_INVOICES) &&
                    hasPermission(permission.SUPPLIER_INVOICES_READ) && (
                        <TopbarMenuItem
                            href="/supplierinvoices"
                            title={t('TOPBAR_SUPPLIER_INVOICES')}
                            icon="shopping cart"
                            count={this.props.supplierInvoiceCount}
                        />
                    )
                }

                {hasAppModule(modules.DEVIATIONS) &&
                    hasPermission(permission.DEVIATIONS_READ) && (
                        <TopbarMenuItem
                            href="/deviations"
                            title={t('DEVIATIONS')}
                            icon="certificate"
                        />
                    )}

                {hasAppModule(modules.PROJECTS) &&
                    hasPermission(permission.PROJECTS_READ) && (
                        <TopbarMenuItem
                            href="/projects"
                            title={t('PROJECTS')}
                            icon="sitemap"
                        />
                    )}

                {hasAppModule(modules.CRM) &&
                    hasPermission(permission.PROPOSAL_READ) && (
                        <TopbarMenuItem
                            href="/proposals"
                            title={t('PROPOSALS')}
                            icon="handshake outline"
                        />
                    )}

                {hasAppModule(modules.CRM) &&
                    hasPermission(permission.SALES_ORDER_READ) && (
                        <TopbarMenuItem
                            href="/sales"
                            title={t('SALES_ORDERS')}
                            icon="copy outline"
                        />
                    )}

                <TopbarMenuItem
                    href="/time"
                    title={t('TIME_REPORTING')}
                    icon="clock outline"
                />

                <TopbarMenuItem
                    href="/"
                    exact={true}
                    title={t('WORK_ORDERS')}
                    icon="tasks"
                />

                {hasPermission(permission.REPORTS_READ) && (
                    <TopbarMenuItem
                        href="/reports"
                        title={t('STATISTICS')}
                        icon="chart line"
                    />
                )}

                <TopbarMenuItem
                    href="/inventory"
                    title={t('INVENTORY')}
                    icon="file alternate outline"
                />

                {hasAppModule(modules.DASHBOARD) &&
                    hasPermission(permission.DASHBOARD_READ) &&
                    (hasDashboardAccess || userType === 'super') && (
                        <TopbarMenuItem
                            href="/dashboard"
                            exact={true}
                            title={t('DASHBOARD')}
                            icon="th large"
                        />
                    )}

                <TopbarUserDropdown
                    t={t}
                    logout={logout}
                    user={user}
                    departments={departments}
                    hasPermission={hasPermission}
                    hasAppModule={hasAppModule}
                    supplierInvoiceCount={this.props.supplierInvoiceCount}
                />
            </Menu>
        );
    }
}

Topbar.propTypes = {
    user: PropTypes.object.isRequired,
    notifications: PropTypes.array.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    sidebarOpen: PropTypes.bool.isRequired,
    instanceName: PropTypes.string.isRequired,
    instanceLogoUrl: PropTypes.string.isRequired,
    logout: PropTypes.func.isRequired,
    departments: PropTypes.array,
};

Topbar.defaultProps = {
    departments: [],
};

const mapStateToProps = state => ({
    hasDashboardAccess: state.ui.hasDashboardAccess,
    userType: state.auth.userType,
    appModules: state.auth.appModules,
    supplierInvoiceCount: state.supplierinvoices.pendingPaymentCount
});

export default withTranslation('ui')(
    withInstanceData(
        withPermissions(withRouter(connect(mapStateToProps)(Topbar)))
    )
);
