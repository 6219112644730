import PropTypes from 'prop-types';
import React from 'react';
import './EditableText.css';

class EditableText extends React.Component {
    state = {
        isEditing: false,
        value: this.props.value,
    };


    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ value: newProps.value });
    }

    UNSAFE_componentWillMount() {
        document.addEventListener('mousedown', this.handleClickEvent, false);
        document.addEventListener('keydown', this.handleKeyboard, false);

        if (this.props.defaultOpen) {
            this.handleStartEditing();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent, false);
        document.removeEventListener('keydown', this.handleKeyboard, false);
    }

    handleClickEvent = e => {
        if (this.node && this.node.contains(e.target)) {
            return;
        }

        if (this.state.isEditing) {
            if (this.props.onClose) {
                this.props.onClose(this.state.value);
            }
        }

        this.setState({
            isEditing: false,
        });
    };

    handleKeyboard = e => {
        if (this.state.isEditing) {
            if (e.keyCode === 13) {
                this.setState(
                    {
                        isEditing: false,
                    },
                    () => this.props.onSave(this.state.value)
                );
            }

            if (e.keyCode === 27) {
                this.setState(
                    {
                        isEditing: false,
                    },
                    () => this.props.onSave(this.state.value)
                );
            }

            if (this.props.closeOnTab) {
                if (e.keyCode === 9) {
                    // tab
                    this.setState(
                        {
                            isEditing: false,
                        },
                        () => this.props.onSave(this.state.value)
                    );
                }
            }
        }
    };

    handleFocus = () => {
        this.handleStartEditing();
    };

    handleBlur = () => {
        this.setState(
            {
                isEditing: false,
            },
            () => this.props.onSave(this.state.value)
        );
    };

    handleChange = e => {
        this.setState({ value: e.target.value });
        this.props.onValueChange(this.props.name, e.target.value);
    };

    handleStartEditing = () => {
        if (this.props.onClick) {
            this.props.onClick();
            return;
        }

        this.setState(
            {
                isEditing: true,
            },
            () => this.input.focus()
        );
    };

    render() {
        if (!this.props.isEditable) {
            return <span>
                {this.props.text ? this.props.text : this.props.value}

                {this.props.postfix}
            </span>
        }

        let isEmpty =
            !(this.props.value && this.props.value.toString().trim()) &&
            !this.props.text;

        if (this.props.value === 0) {
            isEmpty = false;
        }

        const inputSize = isEmpty
            ? this.props.placeholder.length
            : this.props.value.length;

        if (this.state.isEditing && this.props.isEditable) {
            return (
                <div
                    ref={node => (this.node = node)}
                    className="man-editabletext-input">
                    <div className={`ui input man-flex-row`}>
                        <input
                            type={this.props.type || 'text'}
                            onBlur={this.handleBlur}
                            size={inputSize}
                            ref={input => (this.input = input)}
                            placeholder={this.props.placeholder}
                            value={this.state.value}
                            onChange={this.handleChange}
                            max={this.props.max}
                            min={this.props.min}
                        />

                        <span
                            style={{
                                wordBreak: 'keep-all',
                                flex: 1,
                            }}>
                            {this.props.postfix}
                        </span>
                    </div>
                </div>
            );
        }

        return (
            <a
                onClick={this.handleStartEditing}
                tabIndex={0}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                className={`${isEmpty
                        ? 'man-editabletext man-editabletext-empty'
                        : 'man-editabletext'
                    }`}
                style={{ width: this.props.fluid ? '100%' : 'auto' }}>
                {isEmpty && this.props.placeholder}

                {this.props.text ? this.props.text : this.props.value}

                {this.props.postfix}
            </a>
        );
    }
}

EditableText.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    text: PropTypes.string,
    onValueChange: PropTypes.func,
    placeholder: PropTypes.string,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    fluid: PropTypes.bool,
    postfix: PropTypes.string,
    onClick: PropTypes.func,
    isEditable: PropTypes.bool
};

EditableText.defaultProps = {
    placeholder: '____________',
    onSave: () => { },
    onClose: () => { },
    fluid: false,
    value: '',
    postfix: '',
    isEditable: true
};

export default EditableText;
