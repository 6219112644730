import { useConfirm } from 'components/common/use-confirm';
import { useInstanceData } from 'components/common/use-instance-data';
import { useNotifications } from 'components/common/use-notifications';
import { usePermissions } from 'components/common/use-permissions';
import articleService from 'data/services/article/articleService';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { appModules } from 'utils/constants/appModules';
import { permission } from 'utils/constants/permissions';


export const useArticleGroups = () => {

    const [loading, setLoading] = useState(false);
    const [articleGroups, setArticleGroups] = useState([]);
    const [editingGroup, setEditingGroup] = useState(null);
    const [tableState, setTableState] = useState(undefined);
    const { t } = useTranslation(['ui', 'form']);

    const { hasPermission } = usePermissions();
    const { hasAppModule } = useInstanceData();
    const { showErrorNotification, showSuccessNotification } = useNotifications();
    const { confirm } = useConfirm();

    useEffect(() => {
        if (hasPermission(permission.ARTICLES_READ) && hasAppModule(appModules.ARTICLE_GROUPS)) {
            fetchGroups();
        }
    }, [])

    const fetchGroups = async () => {
        try {
            setLoading(true);

            const groups = await articleService.fetchGroups();

            setArticleGroups(groups);
        } catch {
            showErrorNotification('FAILED_TO_FETCH');
        } finally {
            setLoading(false);
        }
    }

    const handleNewGroup = () => {
        setEditingGroup({
            id: 'new',
            title: '',
            displayName: '',
            articleCount: 0
        })
    }

    const handleEditGroup = articleGroup => {
        setEditingGroup(articleGroup);
    }

    const handleCancelEditing = () => {
        setEditingGroup(null);
    }

    const handleSaveGroup = async articleGroup => {
        try {
            setLoading(true);

            if (articleGroup.id === 'new') {
                const id = await articleService.createGroup(articleGroup.title, articleGroup.displayName);

                setArticleGroups([...articleGroups, {
                    ...articleGroup,
                    id
                }]);

            } else {
                await articleService.updateGroup(articleGroup);

                const newGroups = [...articleGroups];
                const idx = newGroups.findIndex(g => g.id === articleGroup.id);

                newGroups[idx] = articleGroup;

                setArticleGroups(newGroups);
            }

            showSuccessNotification('SUCCESSFULLY_SAVED');
            handleCancelEditing();
        } catch (err) {
            if (err.code === 409) {
                showErrorNotification('ARTICLE_GROUP_EXISTS');
            } else {
                showErrorNotification('FAILED_TO_SAVE');
            }
        } finally {
            setLoading(false);
        }
    }

    const handleDeleteGroup = async () => {
        confirm(null, async () => {
            try {
                const articleGroup = editingGroup;

                setLoading(true);

                await articleService.deleteGroup(articleGroup.id);

                const newGroups = [...articleGroups];
                const idx = newGroups.findIndex(g => g.id === articleGroup.id);

                newGroups.splice(idx, 1);

                setArticleGroups(newGroups);

                showSuccessNotification('SUCCESSFULLY_DELETED');
                handleCancelEditing();
            } catch (err) {
                if (err.code === 409) {
                    showErrorNotification('ARTICLE_GROUP_CANNOT_DELETE');
                } else {
                    showErrorNotification('FAILED_TO_DELETE');
                }
            } finally {
                setLoading(false);
            }
        });
    }

    return {
        t,
        tableState,
        onTableStateChange: setTableState,
        onNewGroup: handleNewGroup,
        onEditGroup: handleEditGroup,
        onSaveGroup: handleSaveGroup,
        onDeleteGroup: handleDeleteGroup,
        onCancelEditing: handleCancelEditing,
        fetchArticleGroups: fetchGroups,
        articleGroups,
        isFetching: loading,
        editingArticleGroup: editingGroup
    }
}