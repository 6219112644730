import {
    SHOW_CONFIRM
} from '../../actions/common/confirmActions';

export const confirm = (
    state = {
        props: null
    },
    action
) => {
    switch (action.type) {
        case SHOW_CONFIRM: return ({ ...state, props: action.props })
        default: {
            return state;
        }
    }
}