import { SUPPLIER_INVENTORY_FILTER_CHANGE } from 'data/actions/supplierinventory';

const defaultState = {
    searchFilters: {
        supplierId: null,
    },
};

export const supplierInventory = (state = defaultState, action) => {
    switch (action.type) {
        case SUPPLIER_INVENTORY_FILTER_CHANGE: {
            const filters = {
                ...state.searchFilters,
                [action.filterName]: action.value,
            };

            return {
                ...state,
                searchFilters: filters,
            };
        }
        default: {
            return state;
        }
    }
};
