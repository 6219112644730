export const ee = {
    ui: {
        BTN_NEW_WORKORDER: 'Uus töö',
        DEPARTMENTS: 'Osakonnad',
        DEPARTMENT: 'Osakond',
        PRIORITY: 'Prioriteet',
        REPORTS: 'Raportid',
        REPORTS_HIGH_PRIORITY: 'Kõrge prioriteet',
        REPORTS_CREATED_BY_ME: 'Minu poolt loodud',
        REPORTS_ASSIGNED_TO_ME: 'Minule määratud',
        REPORTS_RECENTLY_COMPLETED: 'Hiljuti lõpetatud',
        REPORTS_RECENTLY_DELETED: 'Hiljuti kustutatud',
        STATISTICS: 'Statistika',
        CUSTOM_REPORTS: 'Kohandatud raportid',
        RECYCLE_BIN: 'Prügikast',
        RECENTLY_CLOSED: 'Hiljuti suletud',
        QUICK_ACCESS: 'Kiirviited',
        FILTER_WORKORDER_PLACEHOLDER: 'Filtreeri töid...',
        PROFILE: 'Profiil',
        EDIT_PROFILE: 'Muuda profiili',
        LOG_OUT: 'Logi välja',
        SETTINGS: 'Seaded',
        INVENTORY: 'Inventuur',
        MAP: 'Kaart',
        DASHBOARD: 'Kodu',
        STATISTICS_DASHBOARD: 'Raportid',
        CALENDAR: 'Kalender',
        CALENDAR_PERSPECTIVE: 'Kalendri vaade',
        WORK_ORDERS: 'Tööd',
        CLOSED_WORK_ORDERS: 'Suletud tööd',
        OPEN_WORK_ORDERS: 'Käimasolevad tööd',
        EXTRA_WORK_WORK_ORDERS: 'Lisa tööd',
        AGREEMENT_INVOICES: 'Lepingulised arved',
        TIME_REPORTING: 'Aja raportid',
        TIME_REPORTING_STATISTIC_HEADER: 'Aja raportid',
        LIST: 'List',
        SCHEDULED: 'Planeeritud',
        AGREEMENT_INVOICE_SCHEDULED_AT: 'Planeeritud',
        NO_WORKS_PLANNED: 'Planeeritud tööd puuduvad',
        SETTINGS_GENERAL: 'Üldine',
        SETTINGS_USERS: 'Kasutajad',
        SETTINGS_ACLS: 'Õigused',
        SETTINGS_WORKORDER: 'Töö',
        SETTINGS_BUSINESS_OBJECT: 'Tööobjekt',
        SETTINGS_PROPOSAL: 'Pakkumus',
        SETTINGS_SALES_ORDER: 'Müügiarve',
        SETTINGS_ARTICLES: 'Artiklid',
        SETTINGS_ARTICLES_PRICE_LISTS: 'Hinnad',
        SETTINGS_ARTICLES_GROUPS: 'Artiklite grupid',
        SETTINGS_ARTICLES_SUPPLIER_ARTICLES: 'Tarnijate artiklid',
        SETTINGS_ARTICLES_SBSC_PRODUCTS: 'SBSC articles', // missing
        SETTINGS_DEVIATIONS: 'Deviations', // missing
        SETTINGS_PROJECT_RESOURCE_PLANNING: 'Project resources', // missing
        SETTINGS_IMPORT_EXPORT: 'Import & Export', // missing
        SETTINGS_SUPPLIERS: 'Tarnijad',
        USERPICKER_ADD_PEOPLE: 'Lisa töötajaid',
        TAGPICKER_ADD_TAG: 'Lisa silte',
        CUSTOMERPICKER_SELECT: 'Vali klient',
        MODELPICKER_SELECT: 'Vali mudel',
        WORKORDERPICKER_SELECT: 'Otsi tööd',
        SUPPLIERPICKER_SELECT: 'Otsi tarnijat',
        ARTICLEPICKER_SELECT: 'Otsi artiklit',
        PROJECTPICKER_SELECT: 'Otsi projekti',
        BOPICKER_SELECT: 'Otsi...',
        BOPICKER_ADD: 'Lisa',
        LOCATIONPICKER_SEARCH: 'Otsi asukohta',
        CUSTOMERS: 'Kliendid',
        MODELS: 'Mudelid',
        SUPPLIERS: 'Tarnijad',
        AGREEMENTS: 'Lepingud',
        CUSTOMER: 'Klient',
        CUSTOMER_PRICE_LIST: 'Kliendihinnad',
        PRICE_LIST: 'Erihinnad',
        GENERAL: 'Üldine',
        AGREEMENT: 'Leping',
        HISTORY: 'Ajalugu',
        SPECIFICATION: 'Lisainfo',
        OBJECTS: 'Tööobjektid',
        ARTICLES: 'Artiklid',
        CUSTOMER_EDIT_DIALOG_TITLE: 'Muuda klienti',
        NEW_CUSTOMER_DIALOG_TITLE: 'Uus klient',
        USER_INVITE_DIALOG_TITLE: 'Registreeri uus kasutaja',
        NEW_BUSINESS_OBJECT_MODAL_TITLE: 'Uus objekt',
        ARTICLE_EDIT_DIALOG_TITLE: 'Muuda artiklit',
        ACL_EDIT_DIALOG_TITLE: 'Muuda õigusi',
        DEPARTMENT_EDIT_TITLE: 'Muuda osakonda',
        USER_EDIT_DIALOG_TITLE: 'Muuda kasutajat',
        MODEL_EDIT_DIALOG_TITLE: 'Muuda mudelit',
        NEW_AGREEMENT_MODAL_TITLE: 'Uus leping',
        STATUS_ACTIVE: 'Aktiivne',
        STATUS_INACTIVE: 'Mitte aktiivne',
        STATUS_REGISTERED: 'Ootab registreerimist',
        SEARCH: 'Otsi',
        FIND_ANYTHING: 'Otsi kõike...',
        RELOAD: 'Uuenda',
        BACK: 'Tagasi',
        SORT: 'Sorteeri',
        VIEW: 'Vaata',
        STATUS: 'Staatus',
        VIEW_ALL: 'Vaata kõiki',
        EDIT: 'Muuda',
        EDIT_INTERNAL_INFO: 'Muuda infot',
        ADD: 'Lisa uus',
        SAVE: 'Salvesta',
        SAVE_AND_CLOSE: 'Salvesta',
        RESEND_REGISTRATION_INVITATION: 'Saada kutse uuesti',
        MORE_ACTIONS: 'Rohkem valikuid',
        PLEASE_CONFIRM_ACTION: 'Palun kinnita',
        ACTION_CONFIRM: 'Kinnita',
        ACTION_CANCEL: 'Tagasi',
        USER: 'Kasutaja',
        INVITE_USER: 'Kutsu uus kasutaja',
        REGISTER_NEW_USER: 'Registeeri uus kasutaja',
        REMOVE: 'Eemalda',
        REMOVE_ROW: 'Eemalda rida',
        DOWNLOAD_ALL: 'Lae kõik failid alla',
        DOWNLOAD: 'Lae alla',
        SELECT: 'Vali',
        FILTER: 'Filtreeri',
        RESET_FILTERS: 'Puhasta filtrid',
        FILTER_BY_CUSTOMER: 'Kliendi järgi',
        FILTER_BY_DEPARTMENT: 'Osakonna järgi',
        FILTER_BY_USER: 'Kasutaja järgi',
        FILTER_BY_PRIORITY: 'Prioriteedi järgi',
        FILTER_BY_ASSIGNEE: 'Töötaja',
        SELECT_ASSIGNEE: 'Vali töötaja',
        INCLUDE_SCHEDULED_WORK_ORDERS: 'Näita planeeritud töid',
        PIECE_SHORT: 'tk',
        PIECES_SHORT: 'tk',
        UNASSIGNED: 'Määramata',
        PLAN_WORK_ORDER: 'Planeeri töö',
        ON_HOLD: 'Ootel',
        DATE: 'Kuupäev',
        USERS_VIEW: 'Töötajate vaade',
        LIST_VIEW: 'Listi vaade',
        CALENDAR_VIEW: 'Kalendri vaade',
        ADD_NOTE: 'Lisa märkus',
        ADD_NEW_ROW: 'Lisa rida',
        NO_RESULTS_FOUND: 'Vasteid ei leitud',
        SEARCHING: 'Otsin...',
        CREATE_NEW_TAG: 'Loo uus silt',
        DELETE: 'Kustuta',
        CLOSE: 'Sulge',
        VIEW_HISTORY: 'Ajalugu',
        SEND_EMAIL: 'Saada e-mail',
        SEND_EMAIL_TO_ME: 'Saada endale',
        PRINT: 'Prindi',
        CREATE_WORKORDER: 'Loo uus töö',
        CLOSE_WORKORDER: 'Sulge töö',
        ENABLED: 'Sees',
        DISABLED: 'Väljas',
        TOTAL: 'Kokku',
        N_MORE: '{{ count }} veel...',
        SUBTOTAL: 'Vahesumma',
        START: 'Algus',
        END: 'Lõpp',
        WORKORDERS_NOT_FOUND: 'Töid ei leitud',
        LOAD_MORE: 'Lae juurde',
        PROCESSING: 'Protsessin...',
        INTAKE_DATE: 'Loodud',
        UPDATE_DATE: 'Uuendatud',
        NEWER_FIRST: 'uuemad enne',
        OLDER_FIRST: 'vanemad enne',
        SCHEDULED_TO_DESC: 'planeeritud viimasena',
        SCHEDULED_TO_ASC: 'planeeritud järgmisena',
        UPDATED: 'Uuendatud',
        CREATED: 'Loodud',
        CREATED_AT: 'Loodud {{datetime}}',
        CREATED_AT_BY_USER: 'Loodud {{datetime}} {{user}} poolt',
        CREATED_BY: 'Loodud',
        CLOSED: 'Suletud',
        MANAGE_OBJECTS: 'Vaata/muuda objekte',
        MANAGE_CUSTOMERS: 'Vaata/muuda kliente',
        MANAGE_MODELS: 'Vaata/muuda mudeleid',
        MANAGE_ARTICLES: 'Vaata/muuda artikleid',
        MANAGE_AGREEMENTS: 'Vaata/muuda lepinguid',
        MANAGE_SUPPLIERS: 'Vaata/muuda tarnijaid',
        CREATE_NEW_WORKTYPE: 'Lisa uus töötüüp',
        CREATE_NEW_STATUS: 'Lisa uus staatus',
        DEFAULT_TYPE: 'Vaike tüüp',
        DEFAULT_TAG: 'Vaike silt',
        DEFAULT_STATUS: 'Vaike staatus',
        DEFAULT_LANGUAGE: 'Vaike keel',
        CLOSING: 'Sulgev',
        SELECT_DEFAULT: 'Vali vaike tüüp',
        SELECT_CLOSING: 'Vali sulgev',
        SELECT_DATE: 'Vali kuupäev',
        SHOW_TODAY: 'Näita täna',
        CLOSED_AT: 'Suletud',
        RECIPIENT: 'Tellija',
        INVOICE: 'Arve',
        INVOICES: 'Arved',
        INVOICE_NR: 'Arve nr',
        YES: 'Jah',
        NO: 'Ei',
        ACTIVATED: 'AKTIVEERITUD',
        INACTIVATED: 'DESAKTIVEERITUD',
        VAT: 'Käibemaks',
        PDF: 'PDF',
        LIMIT_NONE_BTN: 'KÕIK',
        LIMIT_USER_BTN: 'KASUTAJALE MÄÄRATUD',
        LIMIT_CREATED_BY_USER_BTN: 'KASUTAJA LOODUD',
        DISCOUNT_PLACEHOLDER: 'Allahindlus % või ',
        TIME_REPORTING_PERIODS: 'Aja raport - perioodid',
        TIME_REPORTING_PERIODS_WEEKEND: 'Aja raport - nädalavahetuse perioodid',
        TIME_REPORTS_ON_WORK_ORDERS: 'Töödel',
        TIME_REPORTS_ON_WORK_ORDERS_WEEKEND: 'Töödel - nädalavahetus',
        TIME_REPORTS_OTHER: 'Muu',
        TIME_REPORTS_OTHER_ATTENDING: 'Muu - tööaeg',
        TIME_REPORTS_OTHER_ABSENCE: 'Muu - puudumine',
        TIME_REPORTS_DISTANCE: 'KM',
        TIME_REPORT_PERIOD_DESCRIPTION: 'Kirjeldus',
        TIME_REPORTING_LOCKING: 'Aja raportite lukustamine',
        TIME_REPORTS_GET_LOCKED_ON_DAY_OF_NEXT_MONTH:
            'Raportid lukustatakse järgneva kuu {{day}}. päeval',
        TIME_HOURS: '{{count}}t',
        TIME_MINUTES: '{{count}}min',
        LOCK: 'Lukusta',
        UNLOCK: 'Ava',
        LOCKED: 'Lukustatud',
        TIMEREPORT_FOR_USER: 'Aja raport - {{name}}',
        WORK_ORDER: 'Töö',
        AGREEMENT_INVOICE: 'Lepinguline arve',
        EXPORT_TO_EXCEL: 'Ekspordi Excelisse',
        UPLOADING_FILE: 'Laen faili {{fileName}}',
        NO_PREVIEW_AVAILABLE: 'Eelvaade puudub',
        SAVING: 'Salvestan...',
        ALL_CHANGES_SAVED: 'Salvestatud',
        ARTICLE_STOCK: 'Laoseis',
        ARTICLES_IN_STOCK: 'Laoseis',
        NEW_SUPPLIER_MODAL_TITLE: 'Uus tarnija',
        NEW_DOORAUTOMATIC_MODAL_TITLE: 'New doorautomatic', // missing
        SHOW_ON_MAP: 'Näita kaardil',
        OPEN_DIRECTIONS_IN_GOOGLE_MAPS: 'Ava juhised Google Mapsis',
        COUNT_OF_MORE_EVENTS: '{{count}} veel...',
        INTERFACE: 'Kasutajaliides',
        INTERFACE_WORKORDER_LIST_TYPE: 'Tööde list',
        INTERFACE_WORKORDER_LIST_TYPE_SINGLE: 'Üks veerg',
        INTERFACE_WORKORDER_LIST_TYPE_SPLIT: 'Kaks veergu',
        MARGIN_PERCENTAGE: '{{margin}}% marginaal',
        MARGIN_PERCENTAGE_SHORT: 'M: {{margin}}%',
        MANAGE_STOCK: 'Muuda ladu',
        DELIVER_IN_UNITS: 'Kogus',
        ADD_TO_STOCK: 'Lisa lattu',
        ARTICLE_ACTIVATE_IN_DELIVERY: 'Aktiveeri ladu',
        ARTICLE_SHOW_SPSC: 'Näita sertifikaati',
        ARTICLE_STOCK_HISTORY_LOGS: 'Logid',
        STOCK_RESERVED: 'Reserveeritud töödel',
        STOCK_INVOICED: 'Viimased arved',
        STOCK_TRANSACTIONS: 'Laoliikumised',
        CONFIGURE: 'Seadista',
        PREVIEW: 'Eelvaade',
        PROJECT: 'Projekt',
        PROJECTS: 'Projektid',
        ADD_NEW_PROJECT: 'Uus projekt',
        PROJECT_TASK: 'Ülesanne',
        SALES_ORDERS: 'Müük',
        PROPOSALS: 'Pakkumused',
        SELECT_ARTICLE: 'Vali artikkel',
        SELECT_ARTICLE_GROUPS: 'Vali artikli grupid',
        SELECT_ARTICLE_MORE_OPTIONS_BACK: 'Tagasi...',
        SELECT_ARTICLE_MORE_OPTIONS: 'Veel...',
        SELECT_ARTICLE_FILTERING_IS_ACTIVE: 'Grupi filter on sees!',
        ADD_COMMENT: 'Lisa kommentaar',
        ADD_COMMENT_ROW: 'Lisa kommentaari rida',
        ADD_ARTICLE_ROW: 'Lisa artikli rida',
        ADD_SUMMARY_ROW: 'Lisa vahesumma rida',
        FILTER_SALES_ORDERS: 'Filtreeri',
        FILTER_PROPOSALS: 'Filtreeri',
        DELETE_PROJECT: 'Kustuta projekt',
        CLOSE_PROJECT: 'Sulge projekt',
        REOPEN_PROJECT: 'Ava projekt',
        PROPOSAL_STATUS_ACTIVE: 'Aktiivne',
        PROPOSAL_STATUS_ACCEPTED: 'Aksepteeritud',
        PROPOSAL_STATUS_DECLINED: 'Keeldutud',
        PROPOSAL_ACCEPT: 'Aktsepteeri ja loo müügiarve',
        PROPOSAL_ACCEPT_AND_CREATE_WORKORDER: 'Aktsepteeri ja loo töö',
        PROPOSAL_DECLINE: 'Keeldu ja peida pakkumus',
        PROPOSAL_IS_ACCEPTED: 'Aktsepteeritud',
        PROPOSAL_IS_ACCEPTED_AT_BY_USER:
            'Aksepteeritud {{datetime}} ({{user}})',
        PROPOSAL_IS_DECLINED: 'Keeldutud',
        PROPOSAL_IS_DECLINED_AT_BY_USER: 'Keeldutud {{datetime}} ({{user}})',
        PROPOSAL_CREATE_NEW: 'Uus pakkumus',
        FILTER_DEVIATIONS: 'Filtreeri',
        DEVIATION_CREATE_NEW: 'Uus hälve',
        PROPOSAL_CLEAR_CUSTOMER: 'Muuda klienti',
        PROPOSAL_MAKE_COPY: 'Tee koopia',
        WORK_ORDER_MAKE_COPY: 'Tee koopia',
        MAKING_COPY_PLEASE_WAIT: 'Palun oota...',
        SET_AS_PRIMARY_ADDRESS: 'Määra primaarseks aadressiks',
        ADD_NEW_POTENTIAL_CUSTOMER: 'Lisa uus (pot.)',
        SHOW_REGULAR_CUSTOMERS: 'Kliendid',
        SHOW_POTENTIAL_CUSTOMERS: 'Potensiaalsed kliendid',
        ADD_CUSTOMER_NR: 'Lisa kliendinumber',
        NEW_CUSTOMER_NUMBER_DIALOG_TITLE: 'Lisa kliendinumber',
        WORD_EXPORT: 'Loo wordi fail',
        MODIFY_ARTICLE_DESCRIPTION: 'Muuda kirjeldust',
        CREATE_INVOICE: 'Loo arve',
        VIEW_INVOICE: 'Vaata arvet: {{invoiceNr}}',
        UPDATE_ADDRESS_FROM_CUSTOMER_ADDRESS:
            'Uuenda aadress kliendi aadressiga',
        ADD_SIGNATURE: 'Lisa allkiri',
        CLEAR_SIGNATURE: 'Tühjenda',
        SERVICE_REPORT_SAVE_AND_GENERATE_DOCUMENT:
            'Salvesta ja genereeri dokument',
        SERVICE_REPORT_CLOSED_AT_BY_USER: 'Suletud {{datetime}} ({{user}})',
        ABSENCE_INFO: 'Puudumised',
        MY_TIME_REPORTS: 'Minu aja raportid',
        TIME_REPORTED_FOR_DAY: '{{time}} raporteeritud',
        ALL_ATTEND_TIME_REPORTS: 'Aja raportid',
        ALL_ATTEND_TIME_REPORTED_FOR_DAY:
            '{{reported}}/{{expected}} raporteeritud',
        INVOICE_MARKING_OPTION_CONTACT_COST_CENTER: 'Kontakt - {{value}}',
        INVOICE_MARKING_OPTION_CUSTOMER: 'Klient - {{value}}',
        INVOICE_MARKING_OPTION_OBJECT: 'Leping - {{value}}',
        DEFAULT: 'Vaikimisi',
        INTERNAL_MESSAGES: 'Sõnumid',
        MY_WORKORDERS: 'Minu tulevased tööd',
        START_TIMER: 'START',
        STOP_TIMER: 'STOP',
        TIME_REPORTS_WAITING: 'Ootel...',
        TIME_REPORTS_DISTANCE_KM: 'KM',
        TIME_REPORTS_REST_TIME: 'Puhkeaeg',
        TIME_REPORTS_TRAVEL_TIME: 'Reisiaeg',
        TIME_REPORTS_REPORTED_DISTANCE_KM: '{{total}} KM',
        TIME_REPORTS_REPORTED_TRAVEL_TIME: '{{time}} reis',
        CUSTOMER_OBJECT_CONTACT_COUNT:
            'Kliendil on lisaks {{count}} konkakt tööobjektidel.',
        CUSTOMER_OBJECT_CONTACT_COUNT_plural:
            'Kliendil on lisaks {{count}} konkakti tööobjektidel.',
        PERCENTAGE_OF_ESTIMATE: '{{percentage}} ligikaudsest',
        PROJECT_TIME_REPORTED_ON_WORK_ORDERS: '{{time}} töödel',
        PROJECT_TIME_REPORTED_ON_OPEN_WORK_ORDERS: '{{time}} avatud töödel',
        PROJECT_TIME_REPORTED_ON_CLOSED_WORK_ORDERS: '{{time}} suletud töödel',
        PROJECT_COST_PRICE_ON_WORK_ORDERS: '{{value}} töödel',
        PROJECT_COST_PRICE_ON_OPEN_WORK_ORDERS: '{{value}} avatud töödel',
        PROJECT_COST_PRICE_ON_CLOSED_WORK_ORDERS: '{{value}} suletud töödel',
        PROJECT_ONGOING_COST: 'Kogukulu',
        TIME_REPORTING_CONFIRM_FINISHED_WITH_WORKORDER: 'Olen lõpetanud',
        TIME_REPORTING_USERS_FINISHED: '{{initials}} lõpetanud',
        TIME_REPORTING_USER_FINISHED: 'OK',
        SCHEDULE: 'Planeeri aeg',
        SCHEDULING_ADD_TIME: 'Lisa aeg',
        SCHEDULING_ADD_HOURS: 'Lisa tunnid',
        SCHEDULED_TO: 'Planeeritud',
        SCHEDULED_ON_N_DAYS: 'Planeeritud {{count}} päevale',
        SCHEDULED_ON_N_DAYS_plural: 'Planeeritud {{count}} päevale',
        PROJECT_WORK_ORDER_MARK_INCLUDED: 'Korras',
        PROJECT_WORK_ORDER_MARK_INVOICED: 'Lisa arveldusse',
        PROJECT_INVOICING_BACKLOG: 'Arveldamiseks',
        PROJECT_NEW_INVOICE: 'Uus arve',
        PROJECT_INVOICE_NR: 'Arve #{{number}}',
        PROJECT_WORK_ORDER_ARTICLE_MARK_INVOICED: 'Lisa arvele',
        PROJECT_WORK_ORDER_ARTICLE_MARK_INCLUDED: 'Korras',
        PROJECT_DELETE_INVOICE: 'Kustuta arve',
        PROJECT_FINALIZE_INVOICE: 'Lõpeta arve',
        PROJECT_BACKLOG_SHOW_ALL: 'Näita kõiki',
        PROJECT_BACKLOG_SHOW_OPEN: 'Näita märkimata',
        PROJECT_ARTICLE_INVOICED: 'Arveldatud',
        PROJECT_ARTICLE_INCLUDED: 'Korras',
        WORKORDER_INCLUDE_IN_PROJECT: 'Lisa projekti',
        WORKORDER_CREATE_DEVIATION: 'Lisa hälve',
        PROJECT_TYPE_CLOSED: 'Kinni',
        PROJECT_TYPE_ONGOING: 'Aktiivsed',
        PROJECT_ONGOING_COST_REPORT_NEW: '> Raporteeri',
        PROJECT_ONGOING_COST_REPORT: 'Raport',
        PROJECT_ONGOING_COST_REPORT_LAST_SUBMITTED: '{{price}} ({{date}})',
        WORK_ORDER_ARTICLE_FROM_SUPPLIER_SHORT: '',
        SERVICE_REPORT_WILL_BE_EMAILED_TO: 'Email saadetakse',
        SERVICE_REPORT_WAS_EMAILED_TO: 'Email saadetud',
        COLLAPSE_CUSTOMER_ADDRESSES_LIST: 'Peida kõik',
        EXTEND_CUSTOMER_ADDRESSES_LIST: 'Näita kõiki',
        EDIT_ARTICLE_GROUP: 'Muuda gruppi',
        EDIT_PRICE_LIST: 'Muuda hinnastamist',
        PRICING_TYPE: 'Tüüp',
        PRICING_TYPE_ENABLED_ON_ARTICLE_GROUPS: 'Artikli grupid',
        PRICING_TYPE_DISCOUNT: 'Allahindlus',
        PRICING_TYPE_ADDITION_ON_PURCHASE_PRICE: 'Juurdehindlus ostuhinnale',
        PRICING_TYPE_ADDITION_ON_SALES_PRICE: 'Juurdehindlus müügihinnale',
        NEW_PRICE_LIST_PRICING_TYPE: 'Uus hinnastamine',
        OPEN_BUSINESS_OBJECT_SERVICE_LOG: 'Logiraamat',
        CUSTOMER_ZONE_AUTH_CODE_SHOW: 'Näita',
        CUSTOMER_ZONE_AUTH_CODE_HIDE: 'Peida',
        SALES_ORDER_VIEW_AND_CREATE_PDF_RECEIPT: 'Koosta makstud arve',
        SALES_ORDER_VIEW_PDF_RECEIPT: 'Vaata makstud arvet',
        RENAME: 'Nimeta ümber',
        PUBLISH_FILE: 'Avalda fail',
        UNPUBLISH_FILE: 'Tühista avaldamine',
        INCLUDING_VAT: 'koos KM',
        EXCLUDING_VAT: 'ilma KM',
        INTERFACE_START_PAGE: 'Avavaade',
        INTERFACE_START_PAGE_WORKORDERS: 'Tööd',
        INTERFACE_START_PAGE_DASHBOARD: 'Avaleht',
        WORK_ORDER_INVOICE_INCLUDED_IN_PROJECT: 'Arveldatud projektis',
        UPDATE_SUPPLIER_ARTICLES: 'Lae uued hinnad',
        SELECT_DEPARTMENT: 'Vali osakond',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_HEADER: 'Avatud tööd osakonna kaupa',
        AGREEMENT_INVOICING_KPI_HEADER: 'Lepinguliste tööde ülevaade',
        OPEN_PROJECTS_KPI_HEADER: 'Avatud projektide ülevaade',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_TO_INVOICE_ON_PURCHASE_PRICE:
            'Materjal',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_TO_INVOICE_ON_TIME: 'Ajad',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_DEBIT_FACTOR_ON_PURCHASE_PRICE:
            'Kasumi faktor materjalil',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_HOUR_COST: 'Arvestuslik tunnitasu',
        OPEN_WORK_ORDERS_BY_DEPARTMENT_KPI_DISTANCE_COST: 'Arvestuslik KM tasu',
        DEPARTMENT_NONE: 'Valimata',
        WORK_ORDER_INVOICE_MARKING_PLACEHOLDER: 'Arve viitenr.',
        CALENDAR_SHOW_ONLY_MY_CALENDAR: 'Näita ainult minu kalendrit',
        UPLOADING: 'Üleslaadimine...',
        ON: 'Sees',
        OFF: 'Väljas',
        CLOSE_ALL_TABS: 'Sulge kõik aknad',
        CUSTOMER_TYPE: 'Tüüp',
        CUSTOMER_TYPE_ALL: 'Kõik',
        CUSTOMER_PRIVATE: 'Eraisik',
        CUSTOMER_COMPANY: 'Ettevõte',
        CUSTOMER_PRIVATE_PERSON_ID_PLACEHOLDER: 'Isikukood',
        CUSTOMER_PRIVATE_PERSON_NAME_PLACEHOLDER: 'Nimi',
        TAX_REDUCTION_MARK_NONE: 'None', // missing
        TAX_REDUCTION_MARK_ROT: 'ROT', // missing
        TAX_REDUCTION_MARK_RUT: 'RUT', // missing
        TAX_REDUCTION_MARK_GREEN: 'Green', // missing
        WORKORDER_TIMEREPORTS_N_ATTACHED: '{{selected}}/{{total}} valitud',
        WORKORDER_TIMEREPORTS_ATTACH: 'Lisa raporteid arvele',
        WORKORDER_TIMEREPORTS_ATTACH_ALL: 'Vali kõik',
        WORKORDER_TIMEREPORTS_ATTACHED: 'Valitud',
        WORKORDER_TIMEREPORTS_NOT_ATTACHED: 'Mitte valitud',
        WORK_ORDER_SCHEDULING_STATUS: 'Töö planeerimise staatus',
        WORK_ORDER_SCHEDULE_STATUS_BOTH: 'Kõik',
        WORK_ORDER_SCHEDULE_STATUS_NOT_SCHEDULED: 'Ainult mitte-planeeritud',
        WORK_ORDER_SCHEDULE_STATUS_SCHEDULED: 'Ainult planeeritud',
        ADD_WORK_ORDER_INTERNAL_INFO: 'Lisa siseinfo',
        DEVIATIONS: 'Deviations', // missing
        DEVIATION_VIEW_LOG: 'View log', // missing
        DEVIATION_CHANGE_STATE_STARTED: 'Started', // missing
        DEVIATION_CHANGE_STATE_DEFINED: 'Defined', // missing
        DEVIATION_CHANGE_STATE_NEEDS_WORK: 'Needs improvements', // missing
        DEVIATION_CHANGE_STATE_APPROVED: 'Approved', // missing
        DEVIATION_STATUS_OPEN: 'Open', // missing
        DEVIATION_STATUS_CLOSED: 'Closed', // missing
        DEVIATION_STATUS_ALL: 'All', // missing
        CLOSE_DEVIATION: 'Close deviation', // missing
        ARTICLE: 'Artikkel',
        OBJECT: 'Installatsioon',
        SUPPLIER: 'Tarnija',
        DEVIATION_DASHBOARD_GO_TO_LIST: 'View all deviations in list', // missing
        DEVIATION_DASHBOARD_BY_CATEGORY: 'Deviations by category', // missing
        DEVIATION_DASHBOARD_BY_RELATION: 'Most frequent deviations', // missing
        DEVIATION_DASHBOARD_LATEST: 'Latest deviations', // missing
        ADD_WORK_ORDER_ON_SITE_CONTACT: 'Lisa kontakt',
        PROJECT_TIME_REPORTS_EXPORT_EXCEL: 'Ekspordi aja raportid',
        PROJECT_TIME_REPORTS_DOWNLOAD_EXCEL: 'Lae alla',
        PROJECT_TIME_REPORTS_EXCEL_FILENAME: 'Raport',
        OPEN_PROJECTS_SUMMARY_DOWNLOAD_EXCEL: 'Lae alla raport',
        OPEN_PROJECT_SUMMARY_EXCEL_FILENAME: 'Projektid',
        ARTICLE_PRODUCT_TYPE_SERVICE: 'Teenus',
        ARTICLE_PRODUCT_TYPE_STOCK: 'Laoartikkel',
        ARTICLE_PRODUCT_TYPE_NON_STOCK: 'Mitte-laoartikkel',
        WORKORDER_TIMEREPORTS_SHOWING_ONLY_MINE: 'Näitan ainult minu',
        WORKORDER_TIMEREPORTS_SHOWING_ALL: 'Näitan kõiki',
        WORKORDER_TIMEREPORTS_THIS_WEEK: 'Praegune nädal',
        WORKORDER_TIMEREPORTS_PREVIOUS: 'Varasem',
        WORKORDER_TIMEREPORTS_PREVIOUS_SUMMARY: 'Varasemad kanded',
        GO_TO_PROPOSAL: 'Mine pakkumise juurde',
        PROJECT_SORT_BY_TIME: 'Käimasolevad',
        PROJECT_SORT_BY_NAME: 'Nimi',
        PROJECT_SORT_BY_RESPONSIBLE_USER: 'Vastutav isik',
        WORKORDER_PLANNED: 'Planeeritud',
        WORKORDER_CREATED_TIME: 'Loodud {{time}}',
        ADD_ACTIVITY: 'Lisa aktiviteet',
        ADD_SECTION: 'Lisa sektsioon',
        ADD_TABLE: 'Lisa tabel',
        ADD_OPTION: 'Lisa valik',
        WORK_ORDER_WAS_CLOSED: 'Töö suletud: {{time}}',
        SELECT_TIME_RANGE: 'Vali aja vahemik',
        EXPORT_WORKORDER_TIMEREPORTS: 'Expordi aja raportid',
        BUSINESS_OBJECT_DETAILED_SEARCH_ENABLED: 'Detailne otsing',
        BUSINESS_OBJECT_DETAILED_SEARCH_IS_ENABLED_MESSAGE:
            'Detailne otsing on aktiivne',
        WORK_ORDER_ORDER_CONFIRMATION: 'Confirmation email', // missing
        WORK_ORDER_ORDER_CONFIRMATION_SEND: 'Send now', // missing
        WORK_ORDER_ORDER_CONFIRMATION_IGNORE: 'Do not send', // missing
        WORK_ORDER_ORDER_CONFIRMATION_SENT_TO:
            'Confirmation email sent to {{to}}, {{time}}', // missing
        WORK_ORDER_ORDER_CONFIRMATION_NOT_SENT: 'Confirmation email not sent', // missing
        WORK_ORDER_ORDER_FINISH_CONFIRMATION:
            'Send order confirmation to customer about finished work?', // missing
        WORK_ORDER_ORDER_FINISH_CONFIRMATION_RECIPIENT: 'Recipient', // missing
        PROJECTS_PLANNING_PERSPECTIVE: 'Planeerimine',
        PROJECTS_PLANNING_PERSPECTIVE_PROJECT: 'Projektid',
        PROJECTS_PLANNING_PERSPECTIVE_RESOURCES: 'Töötajad',
        COMPENSATION_REPORTING_SETTINGS_LABEL: 'Kompensatsioon',
        TIME_REPORTS_COMPENSATION_REPORTS: 'Kompensatsioon',
        COMPANY_HOLIDAYS_SETTINGS_LABEL: 'Lühendatud tööpäevad',
        OPEN_WORK_ORDER_COUNT_ON_OBJECT_SHORT: '{{count}} open work order', // missing
        OPEN_WORK_ORDER_COUNT_ON_OBJECT_SHORT_plural:
            '{{count}} avatud töid',
        OPEN_WORK_ORDER_COUNT_ON_OBJECT:
            '<0>{{count}}</0> avatud töö',
        OPEN_WORK_ORDER_COUNT_ON_OBJECT_plural:
            '<0>{{count}}</0> avatud tööd',
        WORK_ORDER_ARTICLE_REPORT_USER_DISTRIBUTION_SPLIT: 'SPLIT', // missing
        WORK_ORDER_USER_DISTRIBUTION_SPLIT_HEADER: 'TB - split', // missing
        WORKORDER_HISTORY_EXCEL_FILENAME: 'tööd',
        WORK_ORDER_USER_DISTRIBUTION_KPI_TITLE: 'Margin distribution', // missing
        WORK_ORDER_USER_DISTRIBUTION_ON_OPEN_WORKORDERS_KPI_TITLE:
            'Margin distribution on open work orders', // missing
        WORK_ORDER_USER_DISTRIBUTION_KPI_FOR_USER:
            '{{month}}: Articles for {{name}}, total margin: {{totalMargin}}', // missing
        REOPEN_PROPOSAL: 'Taas-ava pakkumine',
        REOPEN_WORKORDER: 'Taas-ava töö',
        OR: 'VÕI',
        ANLAGGARINTYG_PDF_EXPORT_ADD_PAGES: 'Append pages', // missing
        ANLAGGARINTYG_PDF_EXPORT_ADD_SIGNATURE: 'Add signature', // missing
        PROJECT_EXTRA_WORK_STATUS_PENDING: 'Pending', // missing
        PROJECT_EXTRA_WORK_STATUS_ACCEPTED: 'Accepted', // missing
        PROJECT_EXTRA_WORK_STATUS_DECLINED: 'Declined', // missing
        PROJECT_EXTRA_WORK_STATUS_EDITOR_HEADER: 'Accept or decline ÄTA?', // missing
        PROJECT_EXTRA_WORK_ACCEPT: 'Accept ÄTA', // missing
        PROJECT_EXTRA_WORK_DECLINE: 'Decline ÄTA', // missing
        PROJECT_WORK_ORDER_CREATE_INVOICE: 'Loo arve', 
        PROJECT_INVOICE_ROW_IS_IN_SCOPE: 'In scope', // missing
        PROJECT_INVOICE_ROW_IS_OUT_OF_SCOPE: 'Out of scope', // missing
        PROJECT_WORK_ORDERS_SHOW_LESS: 'Näita vähem',
        PROJECT_WORK_ORDERS_SHOW_MORE: 'Näita veel {{count}} tööd',
        PROJECT_OUTCOME_REGULAR_SCOPE: 'Projekti skoobis', 
        PROJECT_OUTCOME_EXTRA_SCOPE: 'Lisa', 
        PROJECT_OUTCOME_EXTRA_TOTAL_INVOICED: '{{value}} arveldatud',
        SERVICE_DISTRIBUTION_SCHEDULE_SERVICE_WORK_ORDERS_NOW:
            'Planeeri kohe?',
        SERVICE_DISTRIBUTION_SCHEDULE_SERVICE_WORK_ORDERS_NOW_plural:
            'Planeeri {{count}} tööd kohe?', 
        SERVICE_DISTRIBUTION_SCHEDULE_SERVICE_WORK_ORDERS_CONFIRM: 'Kinnita',
        PROJECT_OUTCOME_SCOPE_ALL: 'Project summary including ÄTA',
        WORK_ORDER_PER_TAG_KPI_TITLE: 'Tööd sildi kohta',
        START_DATE: 'Algus kuupäev',
        END_DATE: 'Lõpp kuupäev',
        SUPPLIER_TAG_EDITED_BY: 'Set by: {{username}} ({{datetime}})', // missing
        SELECT_PERIOD: 'Vali periood',
        UNTIL: 'Kuni',
        DISPLAY: 'Näita',
        HIDE: 'Peida',
        TIME_REPORTS_SCHEDULE_SINGLE_DAY: '<< Planeeri üks päev',
        TIME_REPORTS_SCHEDULE_MANY_DAYS: 'Planeeri mitu päeva >>',
        DOORAUTOMATICS: 'Doorautomatics', // missing
        MANAGE_DOORAUTOMATICS: 'Manage doorautomatic', // missing
        WORKORDER_ATTACHED_DOORAUTOMATICS: 'Attached doorautomatics',
        WORKORDER_CREATE_REMINDER: 'Lisa meeldetuletus',
        WORK_ORDER_REMINDER_MODAL_HEADER: 'Meeldetuletus',
        WORKORDER_REMINDER_SCHEDULED_AT: 'Meeldetuletuse aeg',
        TOPBAR_SUPPLIER_INVOICES: 'Approve costs', // missing
        SUPPLIER_INVOICES: 'Supplier invoices', // missing
        SUPPLIER_INVOICE_STATUS_PENDING: 'To approve', // missing
        SUPPLIER_INVOICE_STATUS_APPROVED: 'Approved', // missing
        SUPPLIER_INVOICE_STATUS_DECLINED: 'Declined', // missing
        SUPPLIER_INVOICE_STATUS_ALL: 'All', // missing
        REOPEN_SERVICE_REPORT: 'Open form', // missing
        SUPPLIER_INVOICE_APPROVE_CONTENT: 'Approve', // missing
        SUPPLIER_INVOICE_APPROVE_PAYMENT: 'Approve', // missing
        SUPPLIER_INVOICE_DELETE: 'Delete', // missing
        WORKORDER_CLOSE_CHANGE_ERP_INVOICE_DATE: 'Adjust invoice date sent to ERP', // missing
        WORKORDER_CLOSE_CHANGE_ERP_DELIVERY_DATE: 'Adjust delivery date sent to ERP', // missing
        WORKORDER_CLOSE_INVOICE_WITH_REVERSE_VAT: 'Invoice will be created with reverse VAT', // missing
        WORKORDER_CLOSE_INVOICE_ADD_INVOICE_FEE: 'Add invoice fee', // missing
        ON_CALL: 'Valve',
        CLEAR_ON_CALL_DAY: 'Tühjenda',
        CLEAR_ON_CALL_WEEK: 'Tühjenda nädal',
        SELECT_ON_CALL_FOR_WEEK: 'Vali nädalaks',
        ON_CALL_ON_N_DAYS: '{{count}} päev',
        ON_CALL_ON_N_DAYS_plural: '{{count}} päeva',
        WORK_ORDER_CHANGE_CUSTOMER: 'Muuda klienti',
        WORK_ORDER_CHANGE_OBJECT: 'Muuda objekti',
        WORK_ORDER_CHANGE_CUSTOMER_CURRENT: 'Praegune klient',
        WORK_ORDER_CHANGE_CUSTOMER_SELECT_NEW: 'Vali uus klient töökäsule',
        WORK_ORDER_CHANGE_CUSTOMER_NEW: 'Valitud klient',
        WORK_ORDER_CHANGE_CUSTOMER_SAVE: 'Kinnita',
        WORK_ORDER_CHANGE_OBJECT_CURRENT: 'Praegune objekt',
        WORK_ORDER_CHANGE_OBJECT_SELECT_NEW: 'Vali uus object töökäsule',
        WORK_ORDER_CHANGE_OBJECT_NEW: 'Valitud objekt',
        WORK_ORDER_CHANGE_OBJECT_SAVE: 'Kinnita',
        PROJECT_PLANNING: 'Plaan',
        PROJECT_ACTIVITY_TITLE: 'Activity description', // missing
        OPEN_PROJECTS_KPI_OTHER_COSTS_IN_PERCENTAGE: 'Project extra costs in %', // missing
        OPTION_DEFAULT: 'Vaike',
        OPTION_OPTIONAL: 'Valikuline',
        OPTION_TEXT_INPUT: 'Tekst',
        OPTION_YES_NO: 'Jah / Ei',
        OPTION_SELECTION: 'Valik',
        PROJECT_EXTERNAL_URL: 'Väline URL',
        PROJECT_EDIT_EXTERNAL_URL: 'Muuta välist URLi',
        CUSTOMER_CONTACT_PRIORITIZED: 'Protocol', // missing
        CUSTOMER_CONTACT_PRIORITIZED_TOOLTIP: 'Contact will receive protocol emails', // missing
        CUSTOMER_HAS_OPEN_PROPOSALS: '{{count}} avatud pakkumust', 
        CUSTOMERPICKER_CREATE_NEW_CUSTOMER: 'Ei leidnud klienti? Loo uus klient siin',
        MARKUP_PERCENTAGE: 'Juurdehindlus (%)',
        MARKUP_PERCENTAGE_SHORT: '{{value}}%',
        TOTAL_MARKUP: 'Juurdehindlus',
        BUSINESS_OBJECT_CODES_ADD_CODE: 'Add code', // missing
        BUSINESS_OBJECT_CODES_ADD_CODE_GROUP: 'Add group', // missing
        BUSINESS_OBJECT_CODES_LOGS_HEADER: 'Access logs', // missing
        BUSINESS_OBJECT_CODES_VIEW_LOGS: 'View log', // missing
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_ADD_GROUP: '{{userName}} created group {{groupName}} on {{businessObjectId}}', // missing
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_EDIT_GROUP: '{{userName}} changed group name from {{fromGroupName}} to {{toGroupName}} on {{businessObjectId}}', // missing
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_DELETE_GROUP: '{{userName}} deleted group {{groupName}} on {{businessObjectId}}', // missing
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_ADD_CODE: '{{userName}} created secret code {{codeName}} for group {{groupName}} on {{businessObjectId}}', // missing
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_EDIT_CODE: '{{userName}} changed secret code name from {{fromCodeName}} to {{toCodeName}} for group {{groupName}} on {{businessObjectId}}', // missing
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_EDIT_CODE_VALUE: '{{userName}} edited secret code {{codeName}} for group {{groupName}} on {{businessObjectId}}', // missing
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_VIEW_CODE: '{{userName}} viewed secret code {{codeName}} for group {{groupName}} on {{businessObjectId}}', // missing
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_COPY_CODE: '{{userName}} copied secret code {{codeName}} for group {{groupName}} on {{businessObjectId}}', // missing
        BUSINESS_OBJECT_SECURITY_GROUP_LOG_TYPE_DELETE_CODE: '{{userName}} deleted code {{codeName}} for group {{groupName}} on {{businessObjectId}}', // missing,
        AUTHENTICATE_AZURE_EMAIL: 'Azure E-Mail ühendamine',
        WORK_ORDER_HISTORY_FILTER_BY_ID_OVERRIDES_OTHER_FILTERS: 'Teisi filtreid ignoreeritakse',
    },
    form: {
        ID: 'ID',
        WORK_ORDER_ID: 'ID',
        ARTICLE_ID: 'Artiklinumber',
        NAME: 'Nimi',
        SHORT_NAME: 'Lühike nimi',
        EMAIL: 'E-mail',
        CUSTOMER_EMAIL: 'E-mail',
        PASSWORD: 'Parool',
        AVATAR: 'Avatar',
        DEPARTMENT: 'Osakond',
        DEPARTMENT_ID: 'ID',
        PHONE: 'Telefon',
        LANGUAGE: 'Keel',
        INVOICE_LANGUAGE: 'Arve keel',
        OUR_REFERENCE_ON_AGREEMENT_INVOICES: 'Referents lepingulistel arvetel',
        STATUS: 'Staatus',
        NOTIFICATIONS: 'Teated',
        PUSH_NOTIFICATIONS: 'Push teated',
        COMPANY_NAME: 'Ettevõtte nimi',
        LOGO: 'Logo',
        INVOICE_DETAILS: 'Arve detailid',
        ORGANIZATIONAL_NAME: 'Ettevõtte juriidiline nimetus',
        ORGANIZATIONAL_NUMBER: 'Registrikood',
        VAT_REGISTRATION_NUMBER: 'Käibemaksukohuslase number',
        VAT_PERCENTAGE: 'Käibemaksu protsent (%)',
        PLAN: 'Plaan',
        TEXT: 'Tekst',
        DATE_FORMAT: 'Kuupäeva formaat',
        CURRENCY: 'Valuuta',
        NOTES: 'Märkused',
        NOTE: 'Märkus',
        OBJECT: 'Tööobjekt',
        TIME_FORMAT: 'Aja formaat',
        SHOW_WEEKEND: 'Näita nädalavahetust',
        AUTHENTICATION: 'Autentimine',
        CURRENT_PASSWORD: 'Praegune parool',
        NEW_PASSWORD: 'Uus parool',
        CONFIRM_NEW_PASSWORD: 'Kinnita uus parool',
        UPDATE_PASSWORD: 'Uuenda parooli',
        PASSWORD_REPEAT: 'Palun korda parooli',
        CUSTOMER: 'Klient',
        CUSTOMER_NUMBER: 'Kliendinumber',
        TAGS: 'Sildid',
        TAG: 'Silt',
        TIME_REPORT_SELECT_TAG: 'Vali silt',
        TIME_REPORT: 'Aja raport',
        MATERIAL_REPORT: 'Materjali raport',
        INVOICING: 'Arveldus',
        SCHEDULING: 'Planeerimine',
        PLANNED_START_TIME: 'Planeeritud algus',
        PLANNED_START_TIME_DATE: 'Planeeritud algus',
        PLANNED_START_TIME_TIME: 'Planeeritud algus aeg',
        PLANNED_END_TIME: 'Planeeritud lõppaeg',
        PLANNED_END_TIME_DATE: 'Planeeritud lõpp',
        PLANNED_END_TIME_TIME: 'Planeeritud lõpp aeg',
        CONTACT: 'Kontakt',
        CONTACTS: 'Kontaktid',
        OBJECT_CONTACTS: 'Kontaktid',
        ON_SITE_CONTACT: 'Kontaktisik',
        ON_SITE_CONTACT_NAME_PLACEHOLDER: 'Nimi',
        ON_SITE_CONTACT_EMAIL_PLACEHOLDER: 'Email',
        ON_SITE_CONTACT_PHONE_PLACEHOLDER: 'Tel',
        INTERNAL_RESPONSIBLE_PERSONS: 'Vastutavad isikud',
        CUSTOMER_ORGANIZATIONAL_NUMBER: 'Reg nr',
        CUSTOMER_ADDITIONAL_INFO: 'Lisainfo',
        CUSTOMER_MARKINGS: 'Arve märkused',
        PAYMENT_TERMS: 'Maksetingimused',
        PAYMENT_DUE_DATE: 'Maksetähtpäev',
        REVERSE_VAT: 'Käibemaksu pöördmaksustamine',
        PAYMENT_TERM_IN_DAYS: 'Maksetähtaeg päevades',
        BG_ACCOUNT: 'BG number',
        CONTACT_NAME_PLACEHOLDER: 'Nimi',
        CONTACT_EMAIL_PLACEHOLDER: 'E-mail',
        CONTACT_PHONE_PLACEHOLDER: 'Telefon',
        CONTACT_ROLE_PLACEHOLDER: 'Roll',
        CONTACT_INVOICE_COST_CENTER_PLACEHOLDER: 'Viite nr.',
        OBJECT_LOCATION_PLACEHOLDER: 'linn',
        OBJECT_LOCATION_2_PLACEHOLDER: 'tänav, maja',
        OBJECT_LOCATION_3_PLACEHOLDER: 'Postiindeks',
        OBJECT_AGREEMENT_TYPE_PLACEHOLDER: 'Lepingu tüüp',
        CITY: 'Linn',
        OBJECT_COUNT: 'Objektid',
        DATE: 'Kuupäev',
        VALID_FROM: 'Algkuupäev',
        VALID_TO: 'Lõppkuupäev',
        NEXT_INVOICE_SCHEDULED_TO: 'Järgmine arve luuakse',
        NEXT_INVOICE_PERIOD_FROM: 'Järgmise arve perioodi algus',
        NEXT_INVOICE_PERIOD_TO: 'Järgmise arve perioodi lõpp',
        AGREEMENT_INVOICE_FREQUENCY: 'Sagedus (kuu)',
        AGREEMENT_INVOICE_PRINTED_PERIOD:
            'Periood, mis läheb järgmisele lepingulisele arvele',
        NEXT_SERVICE_SCHEDULED_TO: 'Järgmine töö luuakse',
        INVOICE_REGARDING_PERIOD: 'Periood',
        ARTICLES: 'Artiklid',
        PRIORITY: 'Prioriteet',
        PRIORITY_HIGH: 'Kõrge',
        PRIORITY_MEDIUM: 'Keskmine',
        PRIORITY_LOW: 'Madal',
        ASSIGNEES: 'Töötajad',
        ASSIGNEE: 'Töötaja',
        LOCATION: 'Linn',
        LOCATION_2: 'Aadress',
        CUSTOMER_LOCATION_2: 'Aadress',
        LOCATION_3: 'Postiindeks',
        CREATE_DATE: 'Kuupäev',
        LOCATION_DESCRIPTION: 'Asukoht',
        LOCATION_STATE: 'Maakond',
        LOCATION_STATE_PLACEHOLDER: 'Maakond',
        LOCATION_COUNTRY: 'Riik',
        LOCATION_COUNTRY_PLACEHOLDER: 'Riik',
        AGREEMENT_TYPE: 'Lepingu tüüp',
        AGREEMENT_TYPES: 'Lepingu tüübid',
        DESCRIPTION: 'Kirjeldus',
        ARTICLE_DESCRIPTION: 'Kirjeldus',
        ACCESS_LEVEL_DESCRIPTION: 'Kirjeldus',
        ACCESS_LEVEL_DASHBOARD: 'Avaleht',
        WORK_ORDER_DESCRIPTION: 'Kirjeldus',
        WORK_ORDER_INTERNAL_INFO: 'Siseinfo',
        SUMMARY: 'Kokkuvõte',
        TITLE: 'Pealkiri',
        FIELDS: 'Väljad',
        SERIAL: 'Seerianumber',
        MODEL: 'Mudel',
        CODE: 'Kood',
        COLOR: 'Värvus',
        PLAN_GOLD: 'Kuld',
        USER_COUNT: 'Kasutajaid',
        ACCESS_LEVELS: 'Õigused',
        UNIT: 'Ühik',
        PRICE: 'Hind',
        PURCHASE_PRICE: 'Ostuhind',
        REGULAR_PRICE: 'Tavahind',
        SALES_PRICE: 'Müügihind',
        RECOMMENDED_SALES_PRICE: 'Soovituslik müügihind',
        TOTAL_PRICE: 'Kokku',
        USER: 'Kasutaja',
        PERMISSIONS: 'Õigused',
        LIMITED_TO: 'Limiteeritud',
        INVOICE_CREATION: 'Arve',
        WORKORDER_CREATION: 'Töö',
        AGREEMENT_WORKORDER_GENERATION_DAY: 'Töö loomise päev',
        AGREEMENT_WORKORDER_GENERATION_INTERVAL: 'Tööde interval',
        AGREEMENT_INVOICE_GENERATION_DAY: 'Arve loomise päev',
        AGREEMENT_INVOICE_GENERATION_INTERVAL: 'Arve interval',
        AGREEMENT_WORKORDER_GENERATION_INCLUDE_ARTICLES: 'Lisa artiklid tööle',
        AGREEMENT_WORKORDER_GENERATION_INCLUDE_DESCRIPTION:
            'Lisa lepingu kirjeldus tööle',
        WORK_TYPE: 'Tüüp',
        WORK_TYPES: 'Töö tüübid',
        STATUSES: 'Staatused',
        MULTIPLE_TECHNICIANS: 'Luba mitu tehnikut',
        MULTIPLE_DATES: 'Luba mitu kuupäeva',
        EXTRA_FIELDS: 'Ekstra väljad',
        PRIORITIES_ENABLED: 'Prioriteedid',
        PREFIX: 'Eesliide',
        MIN_LENGTH: 'Min. id pikkus',
        TOTAL_TIME: 'Aeg',
        TRAVEL_TIME: 'Sõit',
        WORK_TIME: 'Töö',
        PRICE_PER_HOUR: 'Hind',
        PRICE_TOTAL: 'Hind kokku',
        REPORTED_HOURS: 'Raporteeritud tunnid',
        ARTICLE: 'Artikkel',
        AMOUNT: 'Kogus',
        PRICE_PER_UNIT: 'Hind',
        WORK_ORDER_DETAILS: 'Üldinfo',
        INVALID_EMAIL_OR_PASSWORD: 'Vale kasutajanimi või parool.',
        SERVER_ERROR: 'Ühendus serveriga ebaõnnestus.',
        IP_BLOCKED_TRY_AGAIN_LATER:
            'Liiga palju ebaõnnestunud päringuid teie aadressilt. Palun proovi hiljem uuesti või võta ühendust meie kasutajatoega.',
        TOKEN_EXPIRED: 'Kasutaja kinnitamine aegus.',
        CANT_LOG_IN: 'Vajad abi sisselogimisega?',
        LOG_IN: 'LOGI SISSE',
        ALREADY_HAVE_USER_LOG_IN: 'Juba on kasutaja? Logi sisse',
        LOG_IN_INSTEAD: 'Logi sisse',
        CONFIRM: 'Kinnita',
        WELCOME_TO_TRUKKS_TEXT: `Tere tulemast! <br>Palun lõpeta oma kasutaja loomine.`,
        CLOSED_TIME: 'Sulgemise aeg',
        WORK_ORDER_NUMBER: 'Töö nr',
        INVOICE: 'Arve',
        LIMITED_TO_DESCRIPTION: 'Juurdepääs töödele',
        ARTICLE_TEXT_IS_EDITABLE: 'Artikli tekst on muudetav',
        ARTICLE_PRODUCT_TYPE: 'Toote tüüp',
        ARTICLE_ENABLE_SUPPLIERS: 'Tarnijad avatud',
        ADDRESS: 'Aadress',
        WORK_ORDER_ADDRESS: 'Aadress',
        CUSTOMER_VAT_REGISTRATION_NUMBER:
            'Kliendi käibemaksukohustuslase number',
        DISCOUNT: 'Allahindlus',
        DISCOUNT_TYPE: 'Allahindluse tüüp',
        CUSTOMER_ORDER_NUMBER: 'Kliendi tellimuse nr',
        RESPONSIBLE_TECHNICIAN: 'Vastutav tehnik',
        AUTO_DISPATCH_NEW_WO: 'Automaatselt määra uutele töödele',
        DEFAULT_REQUIRED_FIELDS: 'Nõutud väljad',
        REQUIRED_FIELD: 'Nõutud väli',
        TAG_REQUIRED: 'Vähemalt üks silt on nõutud',
        ON_SITE_CONTACT_NAME_REQUIRED: 'On site contact is required', // missing
        CONTACT_REQUIRED: 'Kontakt peab olema määratud',
        EMAIL_REQUIRED: 'Email peab olema määratud',
        PHONE_REQUIRED: 'Telefon peab olema määratud',
        DESCRIPTION_REQUIRED: 'Kirjeldus peab olema määratud',
        INVOICE_MARKING_REQUIRED: 'Viitenumber peab olema määratud',
        CUSTOMER_ORDER_NUMBER_REQUIRED:
            'Kliendi tellimuse number peab olema määratud',
        LABEL_IS_REQUIRED: 'Kirjeldus peab olema määratud',
        LABEL_DUPLICATE: 'Kirjeldus peab olema unikaalne',
        PERIOD_TIMES_HAVE_OVERLAPS: 'Perioodide aegades on kattuvus',
        INTERNAL_MESSAGE: 'Lisainfo tööl (sisemine)',
        TOTAL_INVOICE_PRICE_EXCLUDING_VAT: 'Kogu hind',
        TOTAL_REPORTED_TIME: 'Raporteeritud aeg kokku',
        INVOICE_MARKING: 'Arve viitenumber',
        ORDERED_BY: 'Tellija',
        ORDERED_DATE: 'Tellimuse kuupäev',
        FILES: 'Failid',
        UPLOAD_FILES_PLACEHOLDER: 'Lohista fail siia või kliki',
        SUPPLIER_NUMBER: 'Tarnija number',
        SUPPLIER_NAME: 'Tarnija nimi',
        CHECKLIST: 'Nimekiri',
        CHECKLISTS: 'Töö nimekirjad',
        CHECKLIST_TITLE: 'Nimi',
        CHECKLIST_ENABLED_ON_WORK_TYPES: 'Töö tüübid',
        CHECKLIST_ITEMS: 'Kirjed',
        INVOICE_OPTIONS: 'Arve detailid',
        ALTERNATIVE_INVOICE_RECEIVER: 'Arve saaja',
        ALTERNATIVE_INVOICE_ADDRESS: 'Arve aadress',
        OBJECT_AGREEMENT_INVOICE_RECEIVER: 'Arve detailid',
        PROJECT_MENU_GENERAL: 'Üldinfo',
        PROJECT_MENU_INVOICING: 'Arveldus',
        PROJECT_MENU_COSTS: 'Kulud',
        PROJECT_MENU_WORK_ORDERS: 'Tööd',
        PROJECT_MENU_FILES: 'Dokumendid',
        PROJECT_MENU_STATISTICS: 'Statistika',
        PROJECT_MENU_SETTINGS: 'Seaded',
        PROJECT_MENU_PLANNING: 'Planeerimine',
        PROJECT: 'Projekt',
        PROJECT_ECONOMY: 'Arveldus',
        PROJECT_TIMELINE: 'Ajaplaan',
        PROJECT_TITLE: 'ID',
        PROJECT_NAME: 'Nimi',
        PROJECT_STATUS: 'Staatus',
        PROJECT_START_DATE: 'Algus',
        PROJECT_END_DATE: 'Lõpp',
        PROJECT_OUTCOME: 'Tulemus',
        PROJECT_ESTIMATIONS: 'Ligikaudne hinnang',
        PROJECT_ESTIMATED_HOURS: 'Tunnid',
        PROJECT_ESTIMATED_COST: 'Maksumus',
        PROJECT_INCOME: 'Sissetulek',
        PROJECT_INCOME_INVOICED: 'Arved kokku',
        PROJECT_INCOME_LEFT_TO_INVOICE: 'Veel arveldada',
        PROJECT_OBJECTS_INVOLVED: 'Seotud objektid',
        PROJECT_CUSTOMERS_INVOLVED: 'Kliendid',
        PROJECT_SUPPLIERS_INVOLVED: 'Seotud tarnijad',
        PROJECT_WORK_ORDERS_INVOLVED: 'Seotud tööd',
        PROJECT_SALES_ORDERS_INVOLVED: 'Seotud arved',
        PROJECT_RESPONSIBLE_USER: 'Vastutav isik',
        PROJECT_CONTACT_NAME: 'Tellija',
        PROJECT_DESCRIPTION: 'Kirjeldus',
        SALES_ORDER: 'Müügiarve',
        SALES_ORDER_TITLE: 'Arve number',
        PROPOSAL: 'Pakkumus',
        PROPOSAL_TITLE: 'Pakkumuse number',
        PROPOSAL_VALID_TO: 'Kehtib kuni',
        PROPOSAL_CREATED_WORK_ORDER: 'Loodi töö',
        PROPOSAL_CREATED_SALES_ORDER: 'Loodi arve',
        PROPOSAL_STANDARD_TEXTS: 'Standard tekstid',
        PROPOSAL_STANDARD_TEXTS_EXCEPTIONS: 'Standard tekstid - Erisused',
        PROPOSAL_STANDARD_TEXTS_INCLUSIONS: 'Standard tekstid - Kaasaarvatud',
        PROPOSAL_STANDARD_TEXTS_PAYMENT_TERMS: 'Standard tektid - Makstetingimused',
        PROPOSAL_STANDARD_TEXTS_GENERAL_TERMS: 'Standard tekstid - Tingimused',
        PROPOSAL_TEMPLATES: 'PDF Vormid',
        PROPOSAL_FOLLOW_UP_DATE: 'Järgmine tegevus',
        PROPOSAL_CLOSE_DATE: 'Kuupäev',
        PROPOSAL_INTRO: 'Tiitel',
        PROPOSAL_INTRO_TEXT: 'Sissejuhatus',
        PROPOSAL_DELIVERY_ADDRESS: 'Aadress',
        PROPOSAL_DESCRIPTION_OF_EXCEPTIONS: 'Erisused',
        PROPOSAL_GENERAL_TERMS: 'Üldtingimused',
        PROPOSAL_PAYMENT_TERMS: 'Maksetingimused',
        DELIVERY_ADDRESS: 'Aadress',
        ARTICLE_E_NUMBER: 'E-number',
        OBJECT_AGREEMENT_INVOICE_MARKING: 'Arve viitenumber',
        OBJECT_AGREEMENT_INVOICE_REFERENCE: 'Arve viide',
        SALES_ORDER_CREATED_FROM_PROPOSAL: 'Pakkumuse nr',
        CUSTOMER_DEFAULT_DISCOUNT_PERCENTAGE:
            'Allahindluse protsent kõikidele artiklitele',
        ERP_SETTINGS: 'ERP',
        MAIL_SETTINGS: 'E-maili seaded',
        COMMENT: 'Kommentaar',
        PAYMENT_ACCOUNT: 'Arveldusarve nr',
        ERP_ARTICLE_ACCOUNT: 'Tüüp',
        ERP_ARTICLE_ACCOUNT_GOODS: 'Kaubad',
        ERP_ARTICLE_ACCOUNT_SERVICE: 'Teenus',
        ARTICLE_SUPPLIER: 'Tarnija',
        ARTICLE_SUPPLIER_ARTICLE_NR: 'Tarnija artiklinr',
        REFERENCE_CONTACT: 'Kontakt',
        SERVICE_REPORTS: 'Töö raportid',
        SERVICE_REPORT: 'Raport',
        SERVICE_REPORT_TITLE: 'Pealkiri',
        SERVICE_REPORT_TEMPLATE_TYPE: 'Mall',
        SERVICE_REPORT_ENABLED_ON_WORK_TYPES: 'Aktiivne töö tüüpidel',
        SERVICE_REPORT_EMAIL_TO: 'Saada raport meilile',
        SERVICE_REPORT_EMAIL_TO_SELF: 'Kasutaja',
        ID06_CODE: 'ID06 Kood',
        EXPORT_ID06: 'Ekspordi ID06',
        PERSONAL_IDENTITY_NUMBER: 'Isikukood',
        TIME_REPORT_TAG_TYPE_ATTEND: 'Töö',
        TIME_REPORT_TAG_TYPE_ABSENCE: 'Puudumine',
        COMPENSATION_REPORT_TAG_TYPE_DISTANCE: 'KM',
        COMPENSATION_REPORT_TAG_TYPE_TRAVEL: 'Reis',
        COMPENSATION_REPORT_TAG_TYPE_EXPENSE: 'Kulu',
        TYPE: 'Tüüp',
        LABEL: 'Nimi',
        INVOICE_RULES: 'Automaatsed reeglid',
        INVOICE_RULE_AUTO_TIME_INVOICING: 'Automaatne aja arveldus',
        INVOICE_RULE_AUTO_TRAVEL_TIME_INVOICING: 'Automaatne sõiduaja arvestus',
        PROJECT_INVOICE_MATERIAL_AND_INVOICING: 'Viitenumber',
        OBJECT_SERIAL: 'ID',
        OBJECT_MODEL: 'Mudel',
        AGREEMENT_NAME: 'Leping',
        AGREEMENT_SERVICE_MONTH: 'Planeeritud',
        WEBSITE: 'Veebileht',
        POST_GIRO_ACCOUNT: 'Arveldusarve nr (2)', // missing
        ARTICLE_GROUP_ID: 'Grupi ID',
        ARTICLE_GROUP_NAME: 'Grupi nimi',
        ARTICLE_GROUP: 'Artikli grupp',
        PRICE_LIST_ID: 'Hinnastamise ID',
        CUSTOMER_PRICE_LIST: 'Aktiivne hinnastamine',
        OBJECT_PRICE_LIST: 'Aktiivne hinnastamine',
        PRICE_LIST_CONFIGURATION: 'Seadistus',
        CONTINUE: 'Edasi',
        MFA_LOGIN_TITLE: 'Kahe astmeline autentimine',
        MFA_LOGIN_DESCRIPTION:
            'Sisesta 6-kohaline kood {{serviceProvider}} rakendusest',
        MFA_LOGIN_CODE_PLACEHOLDER: 'Kood',
        MFA_CHALLENGE_FAILED: 'Sisestatud kood on vale',
        MFA_AUTHENTICATION_FAILED: 'Kaheastmeline autentimine ebaõnnestus',
        ACTIVATE: 'Aktiveeri',
        DEACTIVATE: 'Desaktiveeri',
        SALES_ORDER_STATUS: 'Staatus',
        SALES_ORDER_STATUS_OPEN: 'Aktiivne',
        SALES_ORDER_STATUS_CLOSED: 'Arveldatud',
        CUSTOMER_ZONE: 'Kliendiportaal',
        CUSTOMER_ZONE_AUTH_CODES: 'Autentimiskoodid',
        TIME_REPORT_TAG_DEFAULT_TIME: 'Vaikimisi aeg',
        MODEL_SPECIFICATIONS: 'Lisaväljad',
        CUSTOMER_CREATE_INVOICES_IN_ERP: 'Saada arved raamatupidamisse',
        CUSTOMER_PERSON_NAME: 'Person',
        DISTANCE_REPORTS_LAST_READING: 'Viimane näit',
        DISTANCE_REPORTS_ACTUAL_READING: 'Aktuaalne näit',
        DISTANCE_REPORTS_ACTUAL_KM: 'Aktuaalne KM',
        SETTINGS_SALES_ORDER_FIXED_VAT: 'Fikseeritud käibemaks müügiorderitel',
        SETTINGS_NOTIFICATIONS_WORK_ORDER_SCHEDULED_EVENT:
            'Saada email, kui lisatakse tööle',
        SETTINGS_NOTIFICATIONS_WORK_ORDER_CREATED_EVENT:
            'Saada email, kui töö luuakse',
        SETTINGS_NOTIFICATIONS_EVENT_ENABLED_FOR_TYPES: 'Filtreeri töödele',
        DEVIATION_CATEGORIES: 'Kategooriad',
        DEVIATION_OCCURED_DATE: 'Kuupäev',
        DEVIATION_CATEGORY: 'Kategooria',
        DEVIATION_ID: 'ID', // missing
        DEVIATION_RELATIONS: 'Related to', // missing
        DEVIATION_SHORT_TERM_SOLUTION: 'Short-term solution', // missing
        DEVIATION_LONG_TERM_SOLUTION: 'Long-term solution', // missing
        DEVIATION_IS_CHANGE_NEEDED: 'Change', // missing
        DEVIATION_IS_CHANGE_NEEDED_YES: 'Needed!', // missing
        DEVIATION_IS_CHANGE_NEEDED_NO: 'Not needed!', // missing
        DEVIATION_CHANGE_DUE_DATE: 'Due date', // missing
        DEVIATION_CHANGE_LAST_EDITED_BY: 'Last edited by', // missing
        DEVIATION_CHANGE_APPROVED_AT: 'Approved', // missing
        DEVIATION_CHANGE_APPROVED_BY: 'Approved by', // missing
        WORK_ORDER_CALCULATED_COST_ON_TIME: 'Sisemine töötunni hind',
        WORK_ORDER_CALCULATED_COST_ON_DISTANCE: 'Sisemine km hind',
        AGREEMENT_DISCOUNT_PERCENTAGE_ON_PRODUCTS: 'Allahindlus toodetele',
        AGREEMENT_DISCOUNT_PERCENTAGE_ON_SERVICES: 'Allahindlus teenustele',
        CUSTOMER_PRICE_DISCOUNT_ON_ARTICLE_GROUPS:
            'Allahindlus artikligruppidele',
        BUSINESS_OBJECT_DEFAULT_AGREEMENT: 'Vaike lepingu tüüp',
        PROJECT_SETTINGS_DEFAULT_INVOICE_MARKING: 'Vaike arve viitenumber',
        WORK_ORDER_DESCRIPTION_STANDARD_TEXTS: 'Töö kirjelduse tekstid',
        WORK_ORDER_SUMMARY_STANDARD_TEXTS: 'Töö kokkuvõtte tekstid',
        COMPENSATION_REPORT: 'Compensation', // missing
        COMPENSATION_REPORTS_TYPE: 'Type', // missing
        COMPENSATION_REPORTS_KM: 'KM', // missing
        COMPENSATION_REPORTS_EXPENSE_TOTAL_VALUE: 'Total', // missing
        COMPENSATION_REPORTS_EXPENSE_TOTAL_VALUE_VAT: 'VAT', // missing
        COMPENSATION_REPORTS_EXPENSE_CATEGORY: 'Category', // missing
        PROJECT_RESOURCE_PLANNING_TASKS: 'Tasks', // missing
        PROJECT_RESOURCE_PLANNING_TASK: 'Task', // missing
        MICROSOFT_AUTH_LOGIN: 'Logi sisse Microsoft',
        LINK: 'Link',
        ADD_LINK: 'Lisa link',
        ADD_LINK_ACTION: 'Lisa link',
        EDIT_LINK: 'Muuda linki',
        CUSTOMER_BROADCAST_EMAIL: 'Auto email address for new work orders', // missing
        HOURS_OF_WORKING: 'Töö tunnid',
        SUPPLIER_TAGS: 'Staatused',
        SUPPLIER_TAG: 'Staatus',
        IBAN: 'IBAN',
        SALES_ORDER_REFERENCE_USER: 'Meie viide',
        DOORAUTOMATIC_TITLE: 'ID', // missing
        DOORAUTOMATIC_SERIAL_CODE: 'Serial code', // missing
        DOORAUTOMATIC_BRAND: 'Brand', // missing
        DOORAUTOMATIC_MODEL: 'Model', // missing
        DOORAUTOMATIC_LOCATION: 'Location', // missing
        DOORAUTOMATIC_WARRANTY_UNTIL: 'Warranty until', // missing
        DOORAUTOMATIC_SENSOR: 'Sensor', // missing
        DOORAUTOMATIC_NOTES: 'Notes', // missing
        DOORAUTOMATIC_IMAGE: 'Image', // missing
        WORK_ORDER_REMINDER_MESSAGE: 'Sõnum',
        WORK_ORDER_REMINDER_DATE: 'Kuupäev',
        WORK_ORDER_REMINDER_TIME: 'Aeg',
        WORK_ORDER_REMINDER_USERS: 'Kasutajad',
        SUPPLIER_INVOICE_CREATE_TIME: 'Arrived', // missing
        SUPPLIER_INVOICE_SUPPLIER_NAME: 'Supplier', // missing
        SUPPLIER_INVOICE_PROJECT: 'Project', // missing
        SUPPLIER_INVOICE_ORDER_NUMBER: 'Order number', // missing
        SUPPLIER_INVOICE_TOTAL_COST: 'Total cost', // missing
        SUPPLIER_INVOICE_EXPIRE_TIME: 'Expire', // missing
        SUPPLIER_INVOICE_RESPONSIBLE_USER: 'User', // missing
        SUPPLIER_INVOICE_COMMENTS: 'Comments', // missing
        SUPPLIER_INVOICE_APPROVE: 'Approve', // missing
        SUPPLIER_INVOICE_IMAGE: 'Image', // missing
        SUPPLIER_INVOICE_CONTENT_STATUS: 'Approve content', // missing
        SUPPLIER_INVOICE_PAYMENT_STATUS: 'Approve payment', // missing
        SUPPLIER_INVOICE_INVOICE_NUMBER: 'Supplier invoice number', // missing
        SUPPLIER_INVOICE_EXTERNAL_REFERENCE: 'Supplier ref.', // missing
        SUPPLIER_INVOICE_INTERNAL_REFERENCE: 'Our ref.', // missing
        SUPPLIER_INVOICE_ARTICLES: 'Articles', // missing
        SUPPLIER_INVOICE_ARTICLE_NAME: 'Article', // missing
        SUPPLIER_INVOICE_ARTICLE_AMOUNT: 'Amount', // missing
        SUPPLIER_INVOICE_ARTICLE_PURCHASE_PRICE: 'Purchase price', // missing
        SUPPLIER_INVOICE_ARTICLE_ROW_PRICE: 'Row price', // missing
        SUPPLIER_INVOICE_ARTICLE_VAT: 'VAT', // missing
        SUPPLIER_INVOICE_ARTICLES_TOTAL_PRICE: 'Total price', // missing
        SUPPLIER_INVOICE_ARTICLES_TOTAL_VAT: 'Total VAT', // missing
        DELIVERY_DATE: 'Delivery date', // missing
        DELIVERY_PARTY: 'Delivery party', // missing
        SETTINGS_WORK_ORDER_DISPATCH_RULES: 'Dispatch rules', // missing
        WORK_ORDER_DISPATCH_RULE_DAY_OF_WEEK: 'Day', // missing
        WORK_ORDER_DISPATCH_RULE_START_TIME: 'Start time', // missing
        WORK_ORDER_DISPATCH_RULE_END_TIME: 'End time', // missing
        WORK_ORDER_DISPATCH_RULE_DISPATCH_TO: 'Dispatch to', // missing
        WORK_ORDER_DISPATCH_AUTO_TO_CREATE_USER: 'Auto-dispatch to creating user', // missing
        UPLOAD_SUPPLIER_INVENTORY_DISCOUNT_FILE_PLACEHOLDER: 'Upload discount file', // missing
        USER_FORTNOX_EMPLOYEE_ID: 'FortNox employee ID', // missing
        DOORAUTOMATIC_COUNT: 'Doorautomatics', // missing
        ARTICLE_IS_SECURITAS_DIRECT_ARTICLE: 'Securitas Direct article', // missing
        ARTICLE_SECURITAS_MONTHLY_COMPENSATION: 'Monthly compensation', // missing
        ARTICLE_SECURITAS_INSTALLATION_COMPENSATION: 'Installation compensation', // missing
        ARTICLE_SECURITAS_SALES_COMPENSATION: 'Sales compensation', // missing
        ARTICLE_IS_SECURITY_ARTICLE: 'Security article', // missing
        BUSINESS_OBJECT_DISPLAY_NAME: 'Nimi',
        REGIONS: 'Regioonid',
        PROJECT_REGION: 'Regioon',
        PROJECT_SALES_USER: 'Vastutav müügiisik',
        COST_CENTER: 'CC', // missing
        STOCK_POINT: 'SP', // missing
        STOCK_LOCATION: 'Asukoht laos',
        USER_DISTRIBUTION: 'Person', // missing
        PROPOSAL_MONTHLY_PRICE: 'Monthly price', // missing
        BUSINESS_OBJECT_CODES: 'Access codes' // missing
    },
    security: {
        CAPACITY: 'Capacity', // missing
        CURRENT_AT_REST: 'Current at rest', // missing
        CURRENT_AT_ALARM: 'Current at alarm', // missing
        SPECIFICATION: 'Anläggarintyg',
        TYPE: 'Tüüp', // missing
        MANUFACTURER: 'Manufacturer', // missing
        ALARM_CLASS: 'Alarm class', // missing
        SPSC: 'Certifierad', // missing
        SPSC_VALID_TO: 'Certifierad Until', // missing
        SBSC_ID: 'SBSC ID',
        MODEL_SYSTEM_TYPES: 'Tüüp', // missing
        MODEL_SYSTEM_TYPE_BURGLAR: 'Burglary', // missing
        MODEL_SYSTEM_TYPE_PASSAGE: 'Passage', // missing
        MODEL_SYSTEM_TYPE_CAMERA: 'Camera/CCTV', // missing
        MODEL_SYSTEM_TYPE_FIREALARM: 'Fire alarm', // missing
        MODEL_SYSTEM_TYPE_ASSAULT: 'Assault', // missing

        OBJECT_PASSAGE_TYPE_OF_SYSTEM: 'Type of system', // missing
        OBJECT_PASSAGE_NUMBER_OF_SUBCENTRALS: 'Number of sub-centrals', // missing
        OBJECT_PASSAGE_DOOR_LEAF_READER: 'Door leaf reader', // missing
        OBJECT_PASSAGE_CARD_READER: 'Card reader', // missing
        OBJECT_PASSAGE_ACCESS_POINT: 'Access point', // missing
        OBJECT_PASSAGE_POWER_SUPPLY: 'Power supply', // missing
        OBJECT_PASSAGE_TYPE_OF_READER: 'Type of reader', // missing
        OBJECT_PASSAGE_COMMENTS: 'Comments', // missing

        OBJECT_CAMERA_NUMBER_OF_CAMERAS: 'Number of cameras', // missing
        OBJECT_CAMERA_NUMBER_OF_PTZ: 'Number of PTZ', // missing
        OBJECT_CAMERA_NUMBER_OF_HEAT_CAMERAS: 'Number of heat cameras', // missing
        OBJECT_CAMERA_TYPE_OF_SOFTWARE: 'Type of software', // missing
        OBJECT_CAMERA_NUMBER_OF_CHANNELS_IN_NVR: 'Number of channels in NVR', // missing
        OBJECT_CAMERA_TYPE_OF_READER: 'Type of reader', // missing
        OBJECT_CAMERA_COMMENTS: 'Comments', // missing

        OBJECT_FIREALARM_FIRE_ALARM_CENTRAL: 'Fire alarm central', // missing
        OBJECT_FIREALARM_TYPE_OF_BATTERY: 'Type of battery', // missing
        OBJECT_FIREALARM_NUMBER_OF_SMOKE_DETECTORS: 'Number of smoke detectors', // missing
        OBJECT_FIREALARM_NUMBER_OF_HEAT_DETECTORS: 'Number of heat detectors', // missing
        OBJECT_FIREALARM_NUMBER_OF_ALARM_BUTTONS: 'Number of alarm buttoms', // missing
        OBJECT_FIREALARM_NUMBER_OF_ALARM_DEVICES: 'Number of alarm devices', // missing
        OBJECT_FIREALARM_COMMENTS: 'Comments', // missing

        OBJECT_ASSAULT_FABRIC: 'Fabric', // missing
        OBJECT_ASSAULT_NUMBER_OF_SUBCENTRALS: 'Number of sub-centrals', // missing
        OBJECT_ASSAULT_NUMBER_OF_DISPLAYS: 'Number of map/text displays', // missing
        OBJECT_ASSAULT_NUMBER_OF_RECEIVERS: 'Number of receivers', // missing
        OBJECT_ASSAULT_COLOR_OF_FIXED_BUTTONS: 'Color of fixed buttons', // missing
        OBJECT_ASSAULT_NUMBER_OF_FIXED_BUTTONS: 'Number of fixed buttons', // missing
        OBJECT_ASSAULT_COLOR_OF_WIRELESS_BUTTONS: 'Color of wireless buttons', // missing
        OBJECT_ASSAULT_NUMBER_OF_WIRELESS_BUTTONS: 'Number of wireless buttons', // missing
        OBJECT_ASSAULT_COLOR_OF_FLASHLIGHTS: 'Color of flashlights', // missing
        OBJECT_ASSAULT_NUMBER_OF_FLASHLIGHTS: 'Number of flashlights', // missing
        OBJECT_ASSAULT_COMMENTS: 'Comments', // missing
    },
    copy: {
        ARTICLE_SUFFICIENT_FOR: 'Sufficent for', // missing
        ARTICLE_SUFFICIENT_FOR_PRINTOUTS: '{{count}} print-outs', // missing
        AGREEMENT_CLICK_REGISTRATION: 'Click registration', // missing
    },
    calendar: {
        TODAY: 'Täna',
        TOMORROW: 'Homme',
        YESTERDAY: 'Eile',
        THIS_WEEK: 'See nädal',
        THIS_MONTH: 'See kuu',
        LAST_MONTH: 'Eelmine kuu',
        THIS_YEAR: 'See aasta',
        MONDAY: 'Esmaspäev',
        TUESDAY: 'Teisipäev',
        WEDNESDAY: 'Kolmapäev',
        THURSDAY: 'Neljapäev',
        FRIDAY: 'Reede',
        SATURDAY: 'Laupäev',
        SUNDAY: 'Pühapäev',
        JANUARY: 'Jaanuar',
        FEBRUARY: 'Veebruar',
        MARCH: 'Märts',
        APRIL: 'Aprill',
        MAY: 'Mai',
        JUNE: 'Juuni',
        JULY: 'Juuli',
        AUGUST: 'August',
        SEPTEMBER: 'September',
        OCTOBER: 'Oktoober',
        NOVEMBER: 'November',
        DECEMBER: 'Detsember',
        JANUARY_SHORT: 'Jan',
        FEBRUARY_SHORT: 'Veeb',
        MARCH_SHORT: 'Mär',
        APRIL_SHORT: 'Apr',
        MAY_SHORT: 'Mai',
        JUNE_SHORT: 'Jun',
        JULY_SHORT: 'Jul',
        AUGUST_SHORT: 'Aug',
        SEPTEMBER_SHORT: 'Sept',
        OCTOBER_SHORT: 'Okt',
        NOVEMBER_SHORT: 'Nov',
        DECEMBER_SHORT: 'Dets',
        MONTH: 'Kuu',
        YEAR: 'Aasta',
        WEEK: 'Nädal',
        DAY: 'Päev',
        QUARTER: 'Kvartal',
        HALF_YEAR: '6 kuud',
        LAST_DAY_OF_MONTH: 'Kuu viimane päev',
        ONE_MONTH: 'üks kuu',
        TWO_MONTHS: 'kaks kuud',
        THREE_MONTHS: 'kolm kuud',
        FOUR_MONTHS: 'neli kuud',
        FIVE_MONTHS: 'viis kuud',
        SIX_MONTHS: 'kuus kuud',
        EIGHTEEN_MONTHS: '18 kuud',
        ONE_YEAR: 'üks aasta',
        TWO_YEARS: 'kaks aastat',
        THREE_YEARS: 'kolm aastat',
        FOUR_YEARS: 'neli aastat',
        TIME_TO: 'Kuni',
        TIME_FROM: 'Alates',
        PERSPECTIVE_SCHEDULE: 'Tehnik',
        PERSPECTIVE_PROJECT: 'Projekt',
        DAYS: 'days', // päeva
    },
    datatable: {
        NO_ROWS: 'Andmeid pole',
        RELOAD: 'Uuenda',
        SEARCH: 'Otsi',
        ROWS: '{{count}} rida',
        ROWS_plural: '{{count}} rida',
        ZERO_ROWS_FILTERED:
            '{{count}} rida (filtreeritud {{filteredFromCount}} reast)',
        SHOWING_ROWS: '{{startCount}} kuni {{endCount}} rida {{count}} reast',
        SHOWING_ROWS_FILTERED:
            '{{startCount}} kuni {{endCount}} rida {{count}} reast (filtreeritud {{fromCount}} reast)',
    },
    messages: {
        SUCCESSFULLY_SAVED: 'Salvestatud',
        SUCCESSFULLY_DELETED: 'Kustutatud',
        SUCCESSFULLY_CLOSED_WORKORDER: 'Töö suletud.',
        SUCCESSFULLY_CLOSED_PROJECT: 'Projekt suletud.',
        SUCCESSFULLY_REOPENED_PROJECT: 'Projekt taasavatud.',
        FAILED_TO_SAVE: 'Salvestamine ebaõnnestus',
        FAILED_TO_SEND_EMAIL: 'Maili saatmine ebaõnnestus!',
        FAILED_TO_DELETE: 'Kustutamine ebaõnnestus',
        FAILED_TO_FETCH: 'Andmete laadimine ebaõnnestus',
        FAILED_TO_UPLOAD_FILE: 'Faili laadimine ebaõnnestus',
        FAILED_TO_DOWNLOAD_FILE: 'Faili laadimine ebaõnnestus',
        FILE_NOT_FOUND: 'Faili ei leitud',
        FAILED_TO_DOWNLOAD_FILE_ID06_CODE_NOT_FOUND: 'ID06 Koodi ei leitud',
        CUSTOMER_FAILED_TO_GET_INFO: 'Ebaõnnestus kliendi andmete laadimine',
        FAILED_TO_GET_HISTORY: 'Ajaloo laadimine ebaõnnestus',
        FAILED_TO_INVITE: 'Uue kasutaja kutsumine ebaõnnestus',
        INVITE_SENT: 'Kutse saadetud',
        WRONG_CURRENT_PASSWORD: 'Vale praegune parool',
        PASSWORD_DONT_MATCH: 'Paroolid ei kattu',
        PASSWORD_MIN_CHARACTERS: 'Parool peab olema vähemalt 8 tähemärki.',
        ERROR_DEPARTMENT_HAS_USERS: 'Ei saa kustutada. Osakonnas on kasutajad.',
        USER_REGISTERED:
            'Parool loodud. <a href="/">Vajuta siia</a>, et sisse logida.',
        INVALID_SESSION:
            'Session on aegunud. Palun logi uuesti sisse, et jätkata.',
        ERROR_MESSAGE_TITLE: 'Viga!',
        INFO_MESSAGE_TITLE: 'Teade!',
        SUCCESS_MESSAGE_TITLE: 'Edukas toiming!',
        WARNING_MESSAGE_TITLE: 'Hoiatus!',
        WARNING_PERMISSION_DENIED:
            'Sul puuduvad õigused selleks toiminguks. Konsulteeri oma administraatoriga.',
        BUSINESS_OBJECT_EXISTS: 'Objekt sama seerianumbriga juba eksisteerib!',
        CUSTOMER_EXISTS: 'Klient sama ID-ga juba eksisteerib!',
        ARTICLE_EXISTS: 'Artikkel sama ID-ga juba eksisteerib!',
        ARTICLE_E_NUMBER_EXISTS: 'Artikkel sama E-Numbriga juba eksisteerib!',
        ARTICLE_GROUP_EXISTS: 'Artiklite grupp sama ID-ga juba eksisteerib!',
        PRICE_LIST_EXISTS: 'Hinnastamine sama ID-ga juba eksisteerib!',
        AGREEMENT_EXISTS: 'Leping sama tüübiga juba eksisteerib!',
        MODEL_EXISTS: 'Mudel sama koodiga juba eksisteerib!',
        SUPPLIER_EXISTS: 'Tarnija sama numbriga juba eksisteerib!',
        DOORAUTOMATIC_EXISTS: 'Doorautomatic with same ID already exists!', // missing
        CONFIRM_UNSAVED_CHANGES:
            'Sul on muudatusi, mis pole salvestatud. Oled kindel, et soovid akna kinni panna?',
        CONFIRM_CLOSE_WORKORDER: 'Töö suletakse. Palun kinnita.',
        MIN_TAG_LENGTH_2: 'Minimaalne sildi pikkus on 2.',
        MIN_WORKTYPE_LENGTH_2: 'Minimaalne töö tüübi pikkus on 2 tähemärki.',
        MAX_WORKTYPE_LENGTH_50: 'Maksimaalne töö tüübi pikkus on 50 tähemärki.',
        MIN_STATUS_LENGTH_2: 'Minimaalne staatuse pikkus on 2.',
        TAG_ALREADY_EXISTS: 'Silt juba eksisteerib.',
        WORKTYPE_ALREADY_EXISTS: 'Töö tüüp juba eksisteerib',
        STATUS_ALREADY_EXISTS: 'Staatus juba eksisteerib.',
        TAG_CONTAINS_SPECIAL_CHARACTERS:
            'Palun kasuta ainult tähti ja numbreid',
        STATUS_DEFAULT_ERROR: 'Üks vaike staatus peab olema määratud.',
        STATUS_NO_CLOSING_ERROR:
            'Vähemalt üks sulgev staatus peab olema määratud.',
        STATUS_CLOSING_DEFAULT_SAME:
            'Sulgev ja vaike staatus peavad olema erinevad',
        CONFIRM_DELETE_WORKORDER: 'Töö kustutatakse! Palun kinnita.',
        INVALID_PRICE: 'Hind on väär. Palun kasuta ainult numberid ja punkti.',
        PLEASE_SELECT_ARTICLE: 'Palun vali artikkel',
        CONFIRM_CUSTOMER_ARTICLE_PRICE_DELETE:
            'Oled kindel, et soovid kliendihinna kustutada?',
        CHANGE_PASSWORD_TEXT: 'Sisesta uus parool',
        PASSWORD_SUCCESSFULLY_CHANGED:
            'Parool edukalt uuendatud. <a href="/">Vajuta siia</a>, et sisse logida.',
        REQUEST_PASSWORD_CHANGE_TEXT:
            'Sisesta oma email, saadame sinna juhised kuidas oma parooli muuta',
        PASSWORD_CHANGE_SUCCESSFULLY_REQUESTED:
            'Sõnum koos juhistega on saadetud sinu e-mailile.',
        UNABLE_TO_DELETE:
            'Kustutamine pole võimalik. Soovitame muuta staatuse mitteaktiivseks või võtta ühendust meie kasutajatoega.',
        ERROR_REQUIRED_FIELD: 'Palun täida väli',
        ERROR_SPACE_NOT_ALLOWED: 'Tühik ei ole lubatud',
        ERROR_MAX_LENGTH: 'Maksimum pikkus ületatud: {{count}}',
        EMAIL_SENT: 'Email saadetud',
        EMAIL_FAILED: 'Emaili saatmine ebaõnnestus',
        ERROR_LOADING_INVOICE: 'Arve laadimine ebaõnnestus',
        ARE_YOU_SURE: 'Kas oled kindel?',
        EMAIL_SENT_TO: 'Email saadetud mailile: {{email}}',
        EMAIL_IS_INVALID: 'Vale email',
        ERROR_HANDLING_APPLY: 'Toiming ei õnnestunud',
        ERROR_DISCOUNT_PATTERN:
            'Allahindlus tohib olla kas number või protsent',
        TAG_IS_REQUIRED: 'Palun vali silt',
        TIME_REPORT_MAXIMUM_TIME_SPAN_60_DAYS: 'Maximum time span is 60 days', // missing
        SEARCH_FROM_SIDEBAR: 'Otsi kasutades külgmenüüd',
        AMOUNT_CANT_BE_NEGATIVE: 'Kogus ei tohi olla negatiivne',
        STOCK_SUCCESSFULLY_UPDATED: 'Laoseis uuendatud. Kogus: {{amount}}.',
        WARNING_NETWORK_ERROR_PLEASE_REFRESH:
            'Ühendus serveriga katkes. Proovi värskendada lehte.',
        USER_CREATED: 'Kasutaja loomine õnnestus',
        FAILED_TO_CREATE_USER: 'Kasutaja loomine ei õnnestunud',
        PROPOSAL_PLEASE_SELECT_CUSTOMER_BEFORE_ACCEPTING:
            'Palun vali klient enne aksepteerimist',
        PROPOSAL_SUCCESSFULLY_ACCEPTED: 'Loodud uus müügiarve',
        PROPOSAL_SUCCESSFULLY_ACCEPTED_WORKORDER_CREATED:
            'Loodud uus töö {{workOrderNr}}',
        PROPOSAL_SUCCESSFULLY_DELETED: 'Pakkumine kustutatud.',
        CONFIRM_SALES_ORDER_INVOICE: 'Luuakse arve. Palun kinnita.',
        SALES_ORDER_INVOICE_SUCCESSFULLY_CREATED: 'Arve loodud',
        SALES_ORDER_IS_INVOICED: 'Arve on loodud',
        OBJECT_IS_INACTIVATED_NO_AGREEMENT_INVOICES:
            'Lepingulisi arveid ei genereerita!',
        PERCENTAGE_CANNOT_BE_OVER_100: 'Protsent peab jääma alla 100',
        PERCENTAGE_CANNOT_BE_NEGATIVE: 'Protsent ei saa olla negatiivne',
        AGREEMENT_CONFIGURATION_WARNING_DOUBLE_INVOICING:
            'Palun kontrolli konfiguratsiooni, et vältida artiklite topelt arveldamist!',
        AGREEMENT_CONFIGURATION_VALID_FROM_MUST_BE_BEFORE_VALID_TO:
            'Lepingu algkuupäev peab olema enne lõppkuupäeva.',
        CANNOT_DELETE_WORKORDER_HAS_LOCKED_TIMEREPORTS:
            'Ei saa kustutada. Tööl on aja raporteid mis on juba lukus.',
        HELLO_USER: 'Tere {{name}}!',
        WELCOME_TO_YOUR_STARTPAGE: 'Teretulemast oma avalehele!',
        PROJECT_FAILED_TO_SET_INCLUDED_HAS_INVOICED_ARTICLES:
            'Ebaõnnestus. Osad artiklid on juba arveldatud.',
        PROJECT_FAILED_TO_SET_ARTICLE_INCLUDED_ALREADY_ON_INVOICE:
            'Rida on juba lisatud mõnele arvele.',
        CONFIRM_CLOSE_PROJECT_INVOICE: 'Luuakse arve. Palun kinnita.',
        PROJECT_INVOICE_SUCCESSFULLY_CREATED: 'Arve loodud.',
        FAILED_TO_HANDLE_SUPPLIER_INVOICE: 'Ebaõnnestus.',
        SUCCESSFULLY_HANDLED_SUPPLIER_INVOICE: 'Arve edukalt käsitletud.',
        ARTICLE_GROUP_CANNOT_DELETE: 'Ei saa kustutada, grupp on kasutuses.',
        PRICE_LIST_CANNOT_DELETE:
            'Ei saa kustutada, hinnastamine on kasutuses.',
        REMOVE_MFA_PROVIDER_CONFIRM:
            'Palun kinnita, et soovid eemaldada kahe astmelise autentimise {{serviceProvider}} abil',
        REGISTER_MFA_PROVIDER_CONFIRM:
            'Palun kinnita, et soovid lisada kahe astmelise autentimise {{serviceProvider}} abil. Veendu, et {{serviceProvider}} on installeeritud',
        REGISTERING_MFA_FAILED:
            'Kahe astmelise autentimise lisamine ebaõnnestus {{serviceProvider}} abil.',
        FAILED_TO_REMOVE_MFA_PROVIDER:
            '{{serviceProvider}} eemaldamine ebaõnnestus',
        SUCCESSFULLY_REMOVED_MFA_PROVIDER:
            '{{serviceProvider}} eemaldamine õnnestus',
        SUCCESSFULLY_REGISTERED_MFA:
            'Kahe astmelise autentimise lisamine õnnestus. Skaneerige allolev QR-kood {{serviceProvider}} mobiilirakenduses',
        CONFIRM_SALES_ORDER_MANUAL_RECEIPT_IS_OK:
            'Palun kinnita koostatud arve.',
        SALES_ORDER_MANUAL_RECEIPT_SUCCESSFUL: 'Makstud arve koostatud.',
        FILE_UPLOAD_SUCCESSFUL_PROCESSING_FILE:
            'Faili üles laadimine õnnestus. Faili protsessitakse.',
        SUPPLIER_INVENTORY_CONFIRM_FORMAT_OF_FIRST_10_ROWS:
            'Kinnita esimese 10 rea õigsust',
        FAILED_TO_MOVE_FILE: 'Faili liigutamine ebaõnnestus',
        CUSTOMER_INFO_INVOICES_WILL_NOT_BE_GENERATED:
            'Arveid ei saadeta raamatupidamisse!',
        WORK_ORDER_CONFIRM_CLOSE_CUSTOMER_IS_CONFIGURED_TO_NOT_SEND_TO_ERP:
            'Arvet ei saadeta raamatupidamisse (kliendi konfiguratsioon)!',
        SUPPLIER_INVENTORY_LAST_FILE_UPLOAD: 'Viimane uuendus: {{time}}',
        SUPPLIER_INVENTORY_LAST_FILE_UPLOAD_NOT_FOUND:
            'Viimast uuendust ei leitud',
        VALIDATION_DEVIATION_CATEGORY_NAME_BETWEEN_2_50_CHARS:
            'Name can be between 2-50 characters', // missing
        DEVIATION_SUCCESSFULLY_CLOSED: 'Deviation successfully closed', // missing
        PROJECT_RESOURCE_DATE_ALREADY_SELECTED: 'Dates already planned!', // missing
        OBJECT_AGREEMENT_VALID_FROM_DATE_NOT_SET:
            'Alguskuupäev peab olema määratud!',
        OBJECT_AGREEMENT_VALID_TO_DATE_SET:
            'Arveid ja töid peale määratud kuupäeva ei genereerita!',
        WORK_ORDER_USER_DISTRIBUTION_USER_ALREADY_ADDED: 'User already added!', // missing
        PROPOSAL_REOPEN_ARE_YOU_SURE: 'Oled kindel, et pakkumine taasavada?',
        PROPOSAL_REOPEN_WORKORDER_WILL_NOT_BE_DELETED:
            'Kui pakkumisest loodi töökäsk - seda ei kustutata!',
        PROPOSAL_REOPEN_SALESORDER_WILL_NOT_BE_DELETED:
            'Kui pakkumisest loodi müügiarve - seda ei kustutata!',
        WORK_ORDER_REOPEN_ARE_YOU_SURE: 'Oled kindel et töökäsk taasavada?', 
        WORK_ORDER_REOPEN_INVOICE_WILL_BE_DELETED:
            'Arve Trukksis kustutatakse.',
        WORK_ORDER_REOPEN_INVOICE_IN_ERP_WILL_NOT_BE_DELETED:
            'Arvet raamatupidamises ei kustutata!',
        WORKORDER_SERVICEREPORT_REOPEN_ARE_YOU_SURE: 'Oled kindel, et vorm uuesti avada?',
        WORKORDER_SERVICEREPORT_REOPEN_FILE_WILL_NOT_BE_DELETED:
            'Genereeritud faili ei kustutata!',
        ADD_LINK_REQUIRED_FIELDS: 'Link ja nimi on kohustuslikud',
        FAILED_TO_AUTHENTICATE_MS_AUTH: 'Microsofti autentimine ebaõnnestus',
        SUCCESSFULLY_SCHEDULED_WORK_ORDERS:
            'Successfully scheduled. Work orders will be created in few minutes!', // missing
        COMPANY_HOLIDAY_DATE_ALREADY_USED:
            'Lühendatud tööpäeva lisamine ebaõnnestus, antud kuupäev on juba kasutusel',
        WORK_ORDER_BY_TAGS_KPI_ERROR_MESSAGE:
            'Andmete laadimine ebaõnnestus. Palun proovige hiljem uuesti',
        WORK_ORDER_REOPEN_SUCCESSFUL: 'Taasavamine õnnestus', 
        CANNOT_CLOSE_PROJECT_INVOICE_CUSTOMER_IS_POTENTIAL_CUSTOMER:
            'Cannot close project invoice. Customer must have customer number.', // missing
        CANNOT_CLOSE_PROPOSAL_WORKORDER_CUSTOMER_NUMBER_MISSING:
            'Pakkumust ei saa kinnitada. Kliendil peab olema kliendinumber.',
        CANNOT_CLOSE_PROPOSAL_WORKORDER_OBJECT_IS_MISSING:
            'Pakkumust ei saa kinnitada. Palun vali kõigepeal objekt.', 
        CANNOT_CLOSE_PROPOSAL_SALES_ORDER_CUSTOMER_NUMBER_MISSING:
            'Pakkumust ei saa kinnitada. Kliendil peab olema kliendinumber.',
        CANNOT_CLOSE_WORK_ORDER_CUSTOMER_NUMBER_MISSING:
            'Töökäsku ei saa sulgeda. Kliendil peab olema kliendinumber.',
        CANNOT_CLOSE_SALES_ORDER_CUSTOMER_NUMBER_MISSING:
            'Müügiarvet ei saa sulgeda. Kliendil peab olema kliendinumber.',
        CANNOT_CLOSE_SALES_ORDER_REFERENCE_USER_REQUIRED: 'Cannot close sales order. Please set reference user', // missing
        CONFIRM_SUPPLIER_INVOICE_APPROVE_PAYMENT: 'Are you sure to approve payment?', // missing
        USER_REQUIRED: 'Palun vali isik',
        DAY_OF_WEEK_REQUIRED: 'Palun vali päev',
        PROJECT_HAS_OPEN_WORK_ORDERS: 'Can not close project. Project has open work orders', // missing
        WORK_ORDER_CHANGE_CUSTOMER_INFO: 'Artiklite hinndu töökäsul ei uuendata!',
        WORK_ORDER_CHANGE_OBJECT_INFO: 'Artiklite hinndu töökäsul ei uuendata!',
        WORK_ORDER_ASSIGNEE_REQUIRED: 'One assigned user is required!', // missing
        WORK_ORDER_CAN_NOT_REMOVE_SCHEDULED_USER: 'Can not remove assignee. Please add anothed assigned user before removing.', // missing
        COPIED_TO_CLIPBOARD: 'Copied to clipboard', // missing
        SERVICE_REPORT_ALL_GENERATED_PDFS_WILL_BE_PUBLISHED: 'All generated PDFs will be automatically published to customer zone!', // missing
    },
    helpers: {
        AGREEMENT_WORKORDER_GENERATION_DAY_HELPER:
            'Vali päev, millal töö luuakse',
        AGREEMENT_WORKORDER_GENERATION_INTERVAL_HELPER:
            'Vali interval, kui tihti töö luuakse',
        AGREEMENT_INVOICE_GENERATION_DAY_HELPER:
            'Vali päev, millal arve luuakse',
        AGREEMENT_INVOICE_GENERATION_INTERVAL_HELPER:
            'Vali interval, kui tihiti arve luuakse',
        AGREEMENT_ARTICLES_ARE_ADDED_TO_WORKORDER:
            'Lepingu artiklid lisatakse tööle',
        INVOICE_MIN_ID_LEN_VALIDATION: 'Palun sisesta positiivne number',
        CUSTOMER_EMAIL_HELP_TEXT: 'Emaili kasutatakse arvete saatmiseks',
        CUSTOMER_INVOICE_MARKING_HELP_TEXT:
            'Automaatne viitenumber tööde loomisel',
        PRIMARY_CUSTOMER_ADDRESS: 'Primaarne aadress',
        PRIMARY_CUSTOMER_ADDRESS_FOR_ERP: 'Kasutatakse arvetel',
        OBJECT_AGREEMENT_INVOICE_MARKING_HELPTEXT:
            'Viitenumber lisatakse lepingulistele arvetele ning automaatselt seotud töödele.',
        REFERENCE_CONTACT_FOR_ERP: 'Saadetakse raamatupidamisse',
        FIELD_WILL_BE_DISPLAYED_ON_WORKORDER_IF_FILLED_IN: 'Kuvatakse tööle',
        WORKORDER_THIS_ARTICLE_IS_INVOICED_AUTOMATICALLY:
            'Automaatne arvestus seoses aja raportitega',
        PROJECT_SETTINGS_DEFAULT_INVOICE_MARKING_HELPTEXT:
            'Vaike viitenumber selle projekti arvetele.',
        CUSTOMER_BROADCAST_EMAIL_HELP_TEXT:
            'Seda meili kasutatakse teavituste saatmiseks',
        PROJECT_INVOICING_STATUS_ONLY_FOR_IN_SCOPE:
            'Only for in-scope articles. ÄTA is excluded.', // missing
        PROJECT_OUTCOME_EXTRA_SCOPE_ONLY_APPROVED:
            'Only approved work orders are included', // missing
        PROJECT_OUTCOME_EXTRA_SCOPE_ALL: 'Accepted, pending, declined work orders are included', // missing
    },
    invoice: {
        INVOICE_INFORMATION: 'Arve seoses',
        INVOICE_DESCRIPTION: 'Kirjeldus',
        ISSUED_WITH_REVERSE_VAT: 'Käibemaks pöördmaksustatud',
    },
    acl: {
        PERMISSION_GROUP_WORK_ORDER: 'Töö',
        PERMISSION_GROUP_INVENTORY: 'Inventuur',
        PERMISSION_GROUP_OTHER: 'Muu',
        PERMISSION_SETTINGS_READ: 'Näha seadeid',
        PERMISSION_USERS_READ: 'Näha kasutajaid',
        PERMISSION_SETTINGS_IMPORT_EXPORT: 'Export & Import',
        PERMISSION_USERS_EDIT: 'Muuta kasutajaid',
        PERMISSION_INSTANCE_READ: 'Näha ettevõtte detaile',
        PERMISSION_INSTANCE_EDIT: 'Muuta ettevõtte detaile',
        PERMISSION_CUSTOMERS_READ: 'Näha kliente',
        PERMISSION_CUSTOMERS_CREATE: 'Loo kliente',
        PERMISSION_CUSTOMERS_CREATE_POTENTIAL: 'Loo potensiaalseid kliente',
        PERMISSION_CUSTOMERS_EDIT: 'Muuta kliente',
        PERMISSION_CUSTOMERS_DELETE: 'Kustutada kliente',
        PERMISSION_CUSTOMERS_FILES: 'Manageeri klientide faile',
        PERMISSION_CUSTOMERS_FILES_READ: 'Näha klientide faile', 
        PERMISSION_BUSINESS_OBJECTS_READ: 'Näha tööobjekte',
        PERMISSION_BUSINESS_OBJECTS_EDIT: 'Muuta tööobjekte',
        PERMISSION_BUSINESS_OBJECTS_DELETE: 'Kustutada tööobjekte',
        PERMISSION_BUSINESS_OBJECT_SERVICE_LOG: 'Logiraamatu haldamine',
        PERMISSION_BUSINESS_OBJECT_FILES: 'Manageeri objektide faile',
        PERMISSION_BUSINESS_OBJECT_FILES_READ: 'Näha objektide faile',
        PERMISSION_BUSINESS_OBJECT_AGREEMENT_EDIT: 'Muuda objektide lepinguid',
        PERMISSION_BUSINESS_OBJECTS_CODES_VIEW: 'See object codes', // missing
        PERMISSION_BUSINESS_OBJECTS_CODES_EDIT: 'Edit object codes', // missing
        PERMISSION_BUSINESS_OBJECTS_CODES_VIEW_LOG: 'See logs for object codes', // missing
        PERMISSION_ARTICLES_CREATE: 'Luua uusi artikleid',
        PERMISSION_ARTICLES_READ: 'Näha artikleid',
        PERMISSION_ARTICLES_PRICE: 'Näha artiklite hindu',
        PERMISSION_ARTICLES_EDIT: 'Muuta arikleid',
        PERMISSION_ARTICLES_DELETE: 'Kustutada arikleid',
        PERMISSION_ARTICLES_SETTINGS: 'Hallata artiklite seadeid',
        PERMISSION_ARTICLE_FILES_READ: 'Näha artiklite faile',
        PERMISSION_ARTICLE_FILES_MANAGE: 'Muuta artiklite faile',
        PERMISSION_ACL_READ: 'Näha õiguseid',
        PERMISSION_ACL_EDIT: 'Muuta õiguseid',
        PERMISSION_WORKORDERS_READ: 'Näha töid',
        PERMISSION_WORKORDERS_EDIT: 'Muuta töid',
        PERMISSION_WORKORDERS_EDIT_INTERNAL_INFO: 'Muuta töid (loomise aega)',
        PERMISSION_WORKORDERS_CREATE: 'Luua uusi töid',
        PERMISSION_WORKORDERS_CREATE_PROJECT_EXTRA_WORK:
            'Create ÄTA work orders', // missing
        PERMISSION_WORKORDERS_ACCEPT_PROJECT_EXTRA_WORK:
            'Accept or decline ÄTA work orders', // missing
        PERMISSION_WORKORDERS_CLOSE: 'Sulgeda töid',
        PERMISSION_WORKORDERS_REOPEN: 'Avada suletud töid',
        PERMISSION_WORKORDERS_COPY: 'Kopeerida töid',
        PERMISSION_WORKORDERS_DELETE: 'Kustutada töid',
        PERMISSION_WORKORDERS_REPORT:
            'Raporteerida töödes (kokkuvõte, ajaraportid)',
        PERMISSION_WORKORDERS_REPORT_ARTICLES:
            'Raporteerida tööde artikleid (annab juurdepääsu artiklitele)',
        PERMISSION_WORKORDERS_EDIT_ARTICLE_DISTRIBUTIONS:
            'Edit article distributions on work order', // missing
        PERMISSION_WORKORDERS_LOCK: 'Lukustada töid',
        PERMISSION_WORKORDERS_FILES: 'Lisada faile töödele',
        PERMISSION_WORKORDERS_TIMEREPORTS_SELECT:
            'Lisa tööaja raporteid arvetele',
        PERMISSION_WORKORDERS_READ_COST: 'Näha töökäskude kasumimarginaali',
        PERMISSION_MODELS_READ: 'Näha mudeleid',
        PERMISSION_MODELS_EDIT: 'Muuta mudeleid',
        PERMISSION_MODELS_DELETE: 'Kustutada mudeleid',
        PERMISSION_MODEL_FILES_READ: 'Näha mudelite faile',
        PERMISSION_MODEL_FILES_MANAGE: 'Lisada ja muuta mudelite faile',
        PERMISSION_AGREEMENTS_READ: 'Näha lepinguid',
        PERMISSION_AGREEMENTS_EDIT: 'Muuta lepinguid',
        PERMISSION_AGREEMENTS_DELETE: 'Kustutada lepinguid',
        PERMISSION_SUPPLIERS_READ: 'Näha tarnijaid',
        PERMISSION_SUPPLIERS_EDIT: 'Muuta tarnijaid',
        PERMISSION_SUPPLIERS_DELETE: 'Kustutada tarnijaid',
        PERMISSION_SUPPLIER_ORDERS_EDIT: 'Manageerida tarnijate arveid',
        PERMISSION_INVOICES_READ: 'Näha arveid',
        PERMISSION_REPORTS_READ: 'Näha raporteid',
        PERMISSION_TIMEREPORTS_READ: 'Näha kõigi kasutajate aja raporteid',
        PERMISSION_TIMEREPORTS_EDIT: 'Muuta kõigi kasutajate aja raporteid',
        PERMISSION_ON_CALL_REPORTS_EDIT: 'Muuta valvegraafikut',
        PERMISSION_DISTANCEREPORTS_READ: 'Näha kõigi kasutajate sõiduraporteid',
        PERMISSION_COMPENSATIONREPORTS_READ:
            'Näha kõigi kasutajate kompensatsiooniraporteid',
        PERMISSION_PROJECTS_READ: 'Näha projekte',
        PERMISSION_PROJECTS_EDIT: 'Luua ja muuta projekte',
        PERMISSION_PROJECTS_DELETE: 'Kustutada projekte',
        PERMISSION_PROJECTS_FILES: 'Manageeri projektide faile',
        PERMISSION_PROJECTS_CLOSE_NEGATIVE_INVOICE:
            'Luua negatiivne projektiarve',
        PERMISSION_PROJECTS_REOPEN: 'Taasava projekte',
        PERMISSION_SALES_ORDER_READ: 'Näha müügiarveid',
        PERMISSION_SALES_ORDER_EDIT: 'Luua ja muuta müügiarveid',
        PERMISSION_SALES_ORDER_DELETE: 'Kustutada müügiarveid',
        PERMISSION_PROPOSAL_READ: 'Näha pakkumusi',
        PERMISSION_PROPOSAL_EDIT: 'Luua ja muuta pakkumusi',
        PERMISSION_PROPOSAL_DELETE: 'Kustutada pakkumusi',
        PERMISSION_PROPOSAL_FILES: 'Manageeri pakkumuste faile',
        PERMISSION_PROPOSAL_REOPEN: 'Taasava pakkumusi',
        PERMISSION_SECURITY_BO_DOCUMENT_READ: 'Näha anläggarintyg', // missing
        PERMISSION_SECURITY_BO_DOCUMENT_EDIT: 'Muuta anläggarintyg', // missing
        PERMISSION_WORKORDERS_EDIT_ID06_CODE: 'Muuta ID06 Code töödel',
        PERMISSION_MESSAGE_BOARD_POST_EDIT: 'Muuta sisemisi sõnumeid',
        PERMISSION_DASHBOARD_READ: 'Näha avalehte',
        PERMISSION_DASHBOARD_OPEN_WORKORDERS_KPI_EDIT:
            'Muuta avalehe avatud tööde hinnafaktoreid',
        PERMISSION_DEVIATIONS_READ: 'View deviations', // missing
        PERMISSION_DEVIATIONS_EDIT: 'Edit deviations', // missing
        PERMISSION_DEVIATIONS_CREATE: 'Create deviations', // missing
        PERMISSION_DEVIATIONS_CLOSE: 'Close deviations', // missing
        PERMISSION_DEVIATIONS_DELETE: 'Delete deviations', // missing
        PERMISSION_DOORAUTOMATICS_READ: 'View doorautomatics', // missing
        PERMISSION_DOORAUTOMATICS_EDIT: 'Edit doorautomatics', // missing
        PERMISSION_DOORAUTOMATICS_DELETE: 'Delete doorautomatics', // missing
        PERMISSION_DOORAUTOMATICS_FILES: 'Edit doorautomatic files', // missing
        PERMISSION_DOORAUTOMATICS_FILES_READ: 'Read doorautomatic files', // missing
        PERMISSION_WORKORDER_DOORAUTOMATICS_EDIT: 'Edit doorautomatics on work order', // missing
        PERMISSION_CUSTOMER_ZONE_EDIT_USERS: 'Edit users for customer zone', // missing
        PERMISSION_CUSTOMER_ZONE_PUBLISH_FILES: 'Publish and unpublish files for customer zone', // missing
        PERMISSION_SUPPLIER_INVOICES_READ: 'Read assigned supplier invoices', // missing
        PERMISSION_SUPPLIER_INVOICES_EDIT: 'Manage supplier invoices', // missing
        PERMISSION_SUPPLIER_INVOICES_READ_ALL: 'Read all supplier invoices', // missing
        PERMISSION_WORKORDERS_SERVICEREPORT_REOPEN: 'Taasava tööde vorme',
    },
    customerSpecifications: {
        invoiceSubjectTemplate: 'Arve emaili subjekt',
    },
    workorderhistory: {
        close: 'Sulges',
        edit: 'Muutis',
        'edit.internal': 'Muutis',
        'edit.customer': 'Muutis klienti',
        'edit.object': 'Muutis objekti',
        create: 'Avas uue töö',
        delete: 'Kustutas',
        'agreement.create': 'Loodud automaatselt',
        lock: 'Lukustas',
        unlock: 'Avas',
        add_articles: 'Lisatud artiklid',
    },
    invoicehistory: {
        create: 'Loodud',
        email: 'Saadetud meilile',
        'erp.created.visma': 'Visma',
        'erp.created.wint': 'Wint',
        'erp.created.magenta': 'Magenta',
        'erp.created.visma-e': 'Visma eAccounting',
        'erp.created.fortnox': 'Fortnox',
        'erp.created.quickbooks': 'QuickBooks',
        visma: 'Visma',
        wint: 'Wint',
        magenta: 'Magenta',
        'visma-e': 'Visma eAccounting',
        quickbooks: 'QuickBooks',
    },
    stats: {
        NO_DATA_FOUND: 'Andmeid ei leitud',
        BAR_CHART: 'Veerud',
        LINE_CHART: 'Jooned',

        SERVICE_DISTRIBUTION: 'Hoolduste hajusus',
        SERVICE_DISTRIBUTION_EXCEL_EXPORT_FILENAME: 'Planeeritud hooldused',
        PLANNED_SERVICES: '{{count}} planeeritud hooldus',
        PLANNED_SERVICES_plural: '{{count}} planeeritud hooldust',
        CLICK_TO_VIEW_DETAILS: 'Kliki detailide jaoks',

        INVOICING: 'Sissetulek',
        INVOICING_LAST_12_MONTHS: 'Viimase 12 kuu andmed',
        INVOICING_THIS_YEAR: 'See aasta',
        INVOICING_WORK_ORDERS: 'Tööd',
        INVOICING_AGREEMENTS: 'Lepingud',
        INVOICING_ALL: 'Kõik',
        INVOICING_SUMMED: 'Kokku',

        ACTIVE_WORK_ORDERS: 'Aktiivsed tööd',
        ACTIVE_WORK_ORDERS_BY_STATUS: 'Staatuse järgi',
        NUMBER_OF_WORKORDERS: '{{count}} tööd',

        LARMKLASS: 'Alarm class distribution of objects', // missing

        OPEN_WORKORDERS_TO_INVOICE: 'Open workorders to invoice', // missing

        OPEN_WORKORDERS_ON_SERVICE: 'On service', // missing
        OPEN_WORKORDERS_TO_INVOICE_TOTAL_PRICE: '{{price}} sales value', // missing
        OPEN_WORKORDERS_TO_INVOICE_TOTAL_COUNT: '{{count}} open work orders', // missing
        OPEN_WORKORDERS_TO_INVOICE_TOTAL_TIME: '{{time}} reported hours', // missing

        OPEN_WORKORDERS_TO_INVOICE_WORKORDER_COST: 'Total cost: {{price}}', // missing
        OPEN_WORKORDERS_TO_INVOICE_PRESENTING_DAYS: 'Presenting days', // missing
        OPEN_WORKORDERS_TO_INVOICE_PRESENTING_DAYS_TO: 'to', // missing
        OPEN_WORKORDERS_TO_INVOICE_PRESENTING_DAYS_TOTAL: '(of {{count}} days)', // missing
        OPEN_WORKORDERS_ON_PROJECT: 'On projects', // missing
        OPEN_WORKORDERS_TO_INVOICE_TOTAL_PROJECT_WORK_ORDERS_COUNT:
            '{{count}} open work orders', // missing

        OPEN_WORKORDERS_TO_INVOICE_TOTAL_COST:
            'Total cost to invoice: {{price}}', //missing
        OPEN_WORKORDERS_TO_INVOICE_WORKORDER_PRICE: 'Sales price: {{price}}', // missing
        OPEN_WORKORDERS_TO_INVOICE_WORKORDER_TIME: 'Time spent: {{time}}', // missing

        NON_MATCHING_SUPPLIER_ORDERS: 'Non-matching supplier orders', // missing
        NON_MATCHING_SUPPLIER_ORDERS_PAST_2_MONTHS: 'Past 2 months', // missing
        NON_MATCHING_SUPPLIER_ORDERS_SUPPLIER_NAME: 'Supplier', // missing
        NON_MATCHING_SUPPLIER_ORDERS_ARRIVED_TO_TRUKKS: 'Arrived to Trukks', // missing
        NON_MATCHING_SUPPLIER_ORDERS_SUPPLIER_ORDER_ID: 'Supplier Order ID', // missing
        NON_MATCHING_SUPPLIER_ORDERS_ORDER_REFERENCE: 'Order Reference', // missing
        NON_MATCHING_SUPPLIER_ORDERS_NO_ORDERS: 'No non-matching orders found', // missing
        NON_MATCHING_SUPPLIER_ORDERS_ADJUST_ORDER: 'Adjust reference', // missing
        NON_MATCHING_SUPPLIER_ORDERS_ORDER_WILL_BE_REPROCESSED:
            'Order will be reprocessed with new reference', // missing
        NON_MATCHING_SUPPLIER_ORDERS_DISCARD_ORDER: 'Discard order', // missing
        NON_MATCHING_SUPPLIER_ORDERS_TOTAL_COST: 'Summa',
        NON_MATCHING_SUPPLIER_ORDERS_LIST_OPTION: 'Order reference & value', // missing
        NON_MATCHING_SUPPLIER_ORDERS_DETAILS_OPTION: 'Order details', // missing
        OPEN_PROJECTS_KPI_TOTAL_CONTRACTS_LABEL: 'Total contracts', // missing
        OPEN_PROJECTS_KPI_TOTAL_CONTRACTS:
            '{{price}} on {{total}} open projects', // missing
        OPEN_PROJECTS_KPI_TOTAL_INVOICED_LABEL: 'Total invoiced', // missing
        OPEN_PROJECTS_KPI_TOTAL_INVOICED:
            '{{price}} ({{percent}} of total contracts)', // missing
        OPEN_PROJECTS_KPI_TOTAL_LEFT_TO_INVOICE_LABEL: 'Total left to invoice', // missing
        OPEN_PROJECTS_KPI_TOTAL_LEFT_TO_INVOICE:
            '{{price}} ({{percent}} of total contracts)', // missing
        OPEN_PROJECTS_KPI_ONGOING_COST_LABEL: 'Ongoing cost to invoice', // missing
        OPEN_PROJECTS_KPI_ONGOING_COST_X_NOT_REPORTED: '{{count}} missing', // missing
        OPEN_PROJECTS_KPI_ONGOING_COST_NOT_INVOICED_LABEL: 'Non-invoiced cost', // missing
        OPEN_PROJECTS_KPI_ONGOING_COST_NOT_INVOICED: '{{price}}', // missing
        OPEN_PROJECTS_KPI_ONGOING_COST: '{{price}}', // missing
        OPEN_PROJECTS_KPI_BIGGEST_CONTRACT_LABEL: 'Biggest contract', // missing
        OPEN_PROJECTS_KPI_BIGGEST_CONTRACT: '{{price}} ({{projectName}})', // missing
        OPEN_PROJECTS_KPI_AVERAGE_CONTRACT_LABEL: 'Average contract', // missing
        OPEN_PROJECTS_KPI_AVERAGE_CONTRACT: '{{price}}', // missing
        OPEN_PROJECTS_KPI_RESET_DAY_OF_MONTH:
            'Day of month for resetting costs', // missing
        OPEN_PROJECTS_INCOME_KPI_HEADER: 'Project income', // missing
        OPEN_PROJECTS_INCOME_KPI_INVOICED: 'Invoiced', // missing
        OPEN_PROJECTS_INCOME_KPI_ONGOING_COST: 'Total cost', // missing
        OPEN_PROJECTS_INCOME_KPI_WEEKS_OPEN: 'Weeks open', // missing

        KPI_CLOSED_WORKORDERS_BY_TYPE_HEADER: 'Closed work orders by type', // missing
        KPI_CLOSED_WORKORDERS_BY_TYPE_COUNT_OF_WORKORDERS: 'Count', // missing
        KPI_ONBOARDED_AND_LOST_OBJECTS_TITLE: 'Onboarded and lost objects', // missing
        ONBOARDED_OBJECTS: 'Onboarded', // missing
        LOST_OBJECTS: 'Lost', // missing
        STATS_ACCRUED_INCOME: 'Accrued income', // missing
        STATS_ACCRUED_INCOME_DISPLAYED_UNTIL: 'Until {{date}}', // missing
        STATS_ACCRUED_INCOME_FILE_NAME: 'Accrued income', // missing
        STATS_ERP_SUPPLIER_INVOICES_CARD_HEADER: 'R.p. arved',

        WORK_ORDER_LEAD_TIME_PERIOD: 'Presented period', // missing
        WORK_ORDER_LEAD_TIME_WORK_TYPE: 'Work order type', // missing
        WORK_ORDER_LEAD_TIME_KPI_HEADER: 'Lead time', // missing
        WORK_ORDER_LEAD_TIME_DAYS_OPEN: 'Days open', // missing
        WORK_ORDER_LEAD_TIME_COUNT_OF_ORDERS: 'Count', // missing
        WORK_ORDER_LEAD_TIME_PERIOD_TIME_SPAN_VALIDATION_MESSAGE: 'Specified period must be between 10 days and 1 year', // missing

        CLOSED_SALES_ORDER_PRICING_WEEKLY_HEADER: 'Sales order: Summary of sales & margin', // missing
        CLOSED_WORK_ORDER_PRICING_WEEKLY_HEADER: 'Work order: Summary of sales & margin', // missing
        TOTAL_SALES_PRICE: 'Kokku',
        TOTAL_MARGIN: 'Marginaal',
    },
    suppliers: {
        elektroskandia: 'Elektroskandia',
        solar: 'Solar',
        copiax: 'Copiax',
        ahlsell: 'Ahlsell',
        axsnordic: 'AXS Nordic',
        rexel: 'Rexel',
        dafo: 'Dafo',
        extronic: 'Extronic',
        teletec: 'Teletec',
    },
    erp: {
        INVOICE_NR_HANDLED_BY: '{{name}}',
        INVOICE_WAS_NOT_CREATED_IN_ERP: 'Arvet ei loodud süsteemis {{name}}!',
        INVOICE_STATUS_IN_ERP_UNKNOWN: 'Arve staatus ({{erpName}}): teadmata',
        INVOICE_CREATED_IN_ERP: 'Arve loogud {{erpName}}',
        INVOICE_CREATED_IN_ERP_WITH_ID: 'Arve ({{erpName}}): {{id}}',
        INVOICE_WAS_NOT_CREATED_IN_ERP_ZERO_SUM:
            'Arvet ei loodud {{erpName}} - (0 summa)',
        INVOICE_WAS_NOT_CREATED_IN_ERP_CUSTOMER_CONF:
            'Arvet ei loodud {{erpName}} (kliendi konf.)',
        visma: 'Visma',
        fortnox: 'Fortnox',
        magenta: 'Magenta',
        'visma-e': 'Visma e-economy',
        wint: 'Wint',
        quickbooks: 'QuickBooks',
    },
    files: {
        ROOT_FOLDER: 'Failidesse',
        BACK_TO: 'Tagasi',
    },
    sbsc: {
        CERTIFICATE_NUMBER: 'SBSC Certificate', // missing
        VALID_TO: 'Valid to', // missing
        ARTICLE_GROUP: 'Article group', // missing
        ARTICLE_DESCRIPTION: 'Description', // missing
        MANUFACTURER_NAME: 'Manufacturer', // missing
        MANUFACTURER_MOBILE: 'Mobile', // missing
        MANUFACTURER_PHONE: 'Phone', // missing
        MANUFACTURER_EMAIL: 'Email', // missing
        ALARM_CLASS: 'Alarm class', // missing
        E_NUMBER: 'E-number', // missing
        ARTICLE_REGULATION: 'Regulations', // missing
        CURRENT_AT_REST: 'Current at rest', // missing
        CURRENT_AT_ALARM: 'Current at alarm', // missing
        ENVIRONMENT_CLASS: 'Environment', // missing
        CERTIFICATE_SEQUENCE: 'Certificate sequence', // missing
        MESSAGE_NO_PRODUCT_IMAGE: 'No product image.', // missing
        USING_PROLONGED_VALID_TO_DATE:
            'The product has a prolonged certification date', // missing
        VIEW_ON_SBSC_WEBSITE: 'View on SBSC website', // missing
    },
    importexport: {
        FILE_NAME: 'Failinimi',
        ARTICLE_EXPORT_NAME: 'Artiklid',
        CUSTOMER_EXPORT_NAME: 'Kliendid',
        OBJECT_EXPORT_NAME: 'Objektid',
        UPCOMING_AGREEMENT_INVOICES_EXPORT_NAME: 'Planeeritud lepingulised arved',
    },
    countries: {
        AF: 'Afghanistan',
        AX: 'Åland Islands',
        AL: 'Albania',
        DZ: 'Algeria',
        AS: 'American Samoa',
        AD: 'Andorra',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarctica',
        AG: 'Antigua and Barbuda',
        AR: 'Argentina',
        AM: 'Armenia',
        AW: 'Aruba',
        AU: 'Australia',
        AT: 'Austria',
        AZ: 'Azerbaijan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Belarus',
        BE: 'Belgium',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Bolivia (Plurinational State of)',
        BQ: 'Bonaire, Sint Eustatius and Saba',
        BA: 'Bosnia and Herzegovina',
        BW: 'Botswana',
        BV: 'Bouvet Island',
        BR: 'Brazil',
        IO: 'British Indian Ocean Territory',
        BN: 'Brunei Darussalam',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        CV: 'Cabo Verde',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        KY: 'Cayman Islands',
        CF: 'Central African Republic',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CX: 'Christmas Island',
        CC: 'Cocos (Keeling) Islands',
        CO: 'Colombia',
        KM: 'Comoros',
        CG: 'Congo',
        CD: 'Congo, Democratic Republic of the',
        CK: 'Cook Islands',
        CR: 'Costa Rica',
        CI: 'Côte d\'Ivoire',
        HR: 'Croatia',
        CU: 'Cuba',
        CW: 'Curaçao',
        CY: 'Cyprus',
        CZ: 'Czechia',
        DK: 'Denmark',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        EC: 'Ecuador',
        EG: 'Egypt',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        SZ: 'Eswatini',
        ET: 'Ethiopia',
        FK: 'Falkland Islands (Malvinas)',
        FO: 'Faroe Islands',
        FJ: 'Fiji',
        FI: 'Finland',
        FR: 'France',
        GF: 'French Guiana',
        PF: 'French Polynesia',
        TF: 'French Southern Territories',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgia',
        DE: 'Germany',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GR: 'Greece',
        GL: 'Greenland',
        GD: 'Grenada',
        GP: 'Guadeloupe',
        GU: 'Guam',
        GT: 'Guatemala',
        GG: 'Guernsey',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        HM: 'Heard Island and McDonald Islands',
        VA: 'Holy See',
        HN: 'Honduras',
        HK: 'Hong Kong',
        HU: 'Hungary',
        IS: 'Iceland',
        IN: 'India',
        ID: 'Indonesia',
        IR: 'Iran (Islamic Republic of)',
        IQ: 'Iraq',
        IE: 'Ireland',
        IM: 'Isle of Man',
        IL: 'Israel',
        IT: 'Italy',
        JM: 'Jamaica',
        JP: 'Japan',
        JE: 'Jersey',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KP: 'Korea (Democratic Peoples Republic of) ',
        KR: 'Korea, Republic of',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: 'Lao Peoples Democratic Republic',
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libya',
        LI: 'Liechtenstein',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        MO: 'Macao',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MU: 'Mauritius',
        YT: 'Mayotte',
        MX: 'Mexico',
        FM: 'Micronesia (Federated States of)',
        MD: 'Moldova, Republic of',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MS: 'Montserrat',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Netherlands',
        NC: 'New Caledonia',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        NF: 'Norfolk Island',
        MK: 'North Macedonia',
        MP: 'Northern Mariana Islands',
        NO: 'Norway',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PS: 'Palestine, State of',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines',
        PN: 'Pitcairn',
        PL: 'Poland',
        PT: 'Portugal',
        PR: 'Puerto Rico',
        QA: 'Qatar',
        RE: 'Réunion',
        RO: 'Romania',
        RU: 'Russian Federation',
        RW: 'Rwanda',
        BL: 'Saint Barthélemy',
        SH: 'Saint Helena, Ascension and Tristan da Cunha',
        KN: 'Saint Kitts and Nevis',
        LC: 'Saint Lucia',
        MF: 'Saint Martin (French part)',
        PM: 'Saint Pierre and Miquelon',
        VC: 'Saint Vincent and the Grenadines',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'Sao Tome and Principe',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SX: 'Sint Maarten (Dutch part)',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        GS: 'South Georgia and the South Sandwich Islands',
        SS: 'South Sudan',
        ES: 'Spain',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SJ: 'Svalbard and Jan Mayen',
        SE: 'Sweden',
        CH: 'Switzerland',
        SY: 'Syrian Arab Republic',
        TW: 'Taiwan, Province of China',
        TJ: 'Tajikistan',
        TZ: 'Tanzania, United Republic of',
        TH: 'Thailand',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad and Tobago',
        TN: 'Tunisia',
        TR: 'Turkey',
        TM: 'Turkmenistan',
        TC: 'Turks and Caicos Islands',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates',
        GB: 'United Kingdom',
        US: 'United States of America',
        UM: 'United States Minor Outlying Islands',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela (Bolivarian Republic of)',
        VN: 'Viet Nam',
        VG: 'Virgin Islands (British)',
        VI: 'Virgin Islands (U.S.)',
        WF: 'Wallis and Futuna',
        EH: 'Western Sahara',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe',
    }
};
