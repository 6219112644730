import { SUPPLIER_INVOICE_STATUS_PENDING } from 'utils/constants';
import {
    FETCH_SUPPLIERINVOICES,
    FETCH_SUPPLIERINVOICES_FULFILLED,
    FETCH_SUPPLIERINVOICES_REJECTED,
    SUPPLIERINVOICE_CHANGED,
    SUPPLIERINVOICES_TABLE_STATE_CHANGE,
    SUPPLIERINVOICES_FILTERS_CHANGE
} from '../../actions/supplierinvoices';

const defaultState = {
    isFetching: false,
    invoices: [],
    tableState: undefined,
    pendingPaymentCount: 0,
    filters: {
        status: SUPPLIER_INVOICE_STATUS_PENDING
    },
    requireRefresh: true
}

export const supplierinvoices = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case SUPPLIERINVOICES_TABLE_STATE_CHANGE: return ({
            ...state,
            tableState: action.state
        })
        case SUPPLIERINVOICES_FILTERS_CHANGE: return ({
            ...state,
            filters: action.filters
        })
        case FETCH_SUPPLIERINVOICES: {
            return {
                ...state,
                isFetching: true,
                requireRefresh: false
            }
        }
        case FETCH_SUPPLIERINVOICES_FULFILLED: {

            return {
                ...state,
                isFetching: false,
                invoices: [...action.invoices],
                pendingPaymentCount: action.paymentPendingCount
            }
        }
        case FETCH_SUPPLIERINVOICES_REJECTED: {
            return {
                ...state,
                isFetching: false
            }
        }
        case SUPPLIERINVOICE_CHANGED: {
            const invoices = [...state.invoices];
            const idx = invoices.findIndex(m => m.id === action.invoice.id);

            if (idx > -1) {
                invoices[idx] = action.invoice;
            }

            return {
                ...state,
                invoices
            }
        }
        default: {
            return state;
        }
    }
};