import moment from 'moment';
import {
    CALENDAR_CHANGE_ACTIVE_DATE,
    CALENDAR_CHANGE_ACTIVE_PERIOD,
    CALENDAR_FETCH_EVENTS,
    CALENDAR_FETCH_MONTHLY_EVENTS_FULFILLED,
    CALENDAR_FETCH_MONTHLY_EVENTS_REJECTED,
    CALENDAR_FETCH_WEEKLY_EVENTS_FULFILLED,
    CALENDAR_FETCH_WEEKLY_EVENTS_REJECTED
} from '../../actions/calendar';
import {
    LOGIN_FULFILLED
} from '../../actions/common/authActions';
import { calendarPeriod } from '../../../utils/CalendarUtils';
import CacheManager from '../../../utils/cache';
import CacheManagerProps from '../../../utils/cache/CacheManagerProps';

export const calendar = (
    state = {
        uid: '',
        activeDate: moment(),
        activePeriod: calendarPeriod.MONTH,
        isLoading: false,
        monthlyEvents: {},
        monthlyTimeReports: {},
        weeklyEvents: {},
        weeklyTimeReports: {}
    },
    action
) => {
    switch (action.type) {
        case LOGIN_FULFILLED: {
            return {
                ...state,
                uid: action.user.id
            }
        }

        case CALENDAR_CHANGE_ACTIVE_DATE: return ({
            ...state,
            activeDate: action.date
        })

        case CALENDAR_CHANGE_ACTIVE_PERIOD: {
            CacheManager.put(state.uid, CacheManagerProps.CALENDAR_PERIOD, action.period);

            return {
                ...state,
                activePeriod: action.period
            }
        }

        case CALENDAR_FETCH_EVENTS: return ({ ...state, isLoading: true })

        case CALENDAR_FETCH_MONTHLY_EVENTS_FULFILLED: return ({ 
            ...state, 
            isLoading: false, 
            monthlyEvents: action.events,
            monthlyTimeReports: action.timeReports
        })

        case CALENDAR_FETCH_MONTHLY_EVENTS_REJECTED: return ({ ...state, isLoading: false })

        case CALENDAR_FETCH_WEEKLY_EVENTS_FULFILLED: return ({ ...state, 
            isLoading: false, 
            weeklyEvents: action.events,
            weeklyTimeReports: action.timeReports
        })

        case CALENDAR_FETCH_WEEKLY_EVENTS_REJECTED: return ({ ...state, isLoading: false })

        default: {
            return state;
        }
    }
};

