

export const DASHBOARD_TAB_CLOSED = 'DASHBOARD_TAB_CLOSED';
export const DASHBOARD_TAB_CHANGED = 'DASHBOARD_TAB_CHANGED';
export const DASHBOARD_FILTERS_CHANGE = 'DASHBOARD_FILTERS_CHANGE';

export const dashboardTabClosed = id => ({
    type: DASHBOARD_TAB_CLOSED,
    id
});

export const dashboardTabChanged = (id, values) => ({
    type: DASHBOARD_TAB_CHANGED,
    id,
    values
});

export const dashboardFiltersChange = filters => ({
    type: DASHBOARD_FILTERS_CHANGE,
    filters,
});
