import { deletee, get, post, put } from '../../../utils/http/HttpClient';
import CustomerInvoiceListGetResponse from '../customer/api/CustomerInvoiceListGetResponse';

export default {
    fetch: async businessObjectId => {
        const { doorautomatics } = await get(`/doorautomatics?${businessObjectId ? `businessObjectId=${businessObjectId}` : ''}`);

        return doorautomatics;
    },
    fetchDetails: async id => {
        const { doorautomatic } = await get(`/doorautomatics/${id}`);

        return doorautomatic;
    },
    create: async (title, businessObjectId) => {
        const { id } = await post('/doorautomatics', {
            title,
            businessObjectId
        });

        return id;
    },
    update: async doorautomatic => {
        await put('/doorautomatics', doorautomatic);
    },
    delete: async id => {
        await deletee(`/doorautomatics/${id}`);
    },
    fetchHistory: async (id, from, pageSize) => {
        const response = await get(
            `/doorautomatics/${id}/history?from=${from}&size=${pageSize}`
        );

        return new CustomerInvoiceListGetResponse(response);
    },
}