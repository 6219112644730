import {
    CHANGE_LANGUAGE,
    CHANGE_UI,
    EDIT_COMPENSATION_REPORT_TAGS,
    EDIT_PAGE_DETAILS,
    EDIT_TIME_REPORT_TAGS,
    INIT_DASHBOARD_FILTERS,
    INIT_SORT,
    RESET_DASHBOARD_FILTERS,
    SHOW_MODAL,
    SHOW_PRINT,
    TOGGLE_DASHBOARD_FILTER,
    TOGGLE_SIDEBAR,
    TOGGLE_SORT,
    EDIT_TIMEREPORTING_FILTERS
} from 'data/actions/common/uiActions';

import {
    DASHBOARD_FILTERS_CHANGE
} from 'data/actions/dashboard/dashboardActions';



import {
    UPDATE_WO_CONFIGURATION
} from 'data/actions/instance';

import {
    LOGIN_FULFILLED,
    LOGIN_REJECTED,
    LOGOUT,
    REQUEST_VALIDATE_SESSION
} from 'data/actions/common/authActions';

import {
    HIDE_NOTIFICATION,
    SHOW_NOTIFICATION
} from 'data/actions/common/notificationActions';

import {
    MODEL_CHANGED
} from 'data/actions/models/modelActions';


import { isMobile } from 'react-device-detect';
import { normalize } from 'utils/ArrayUtils';
import { isMediumScreen, isSmallScreen } from 'utils/ScreenUtils';
import { getTimeReportsLockedSinceDateExclusive } from 'utils/TimeUtils';
import CacheManager from 'utils/cache';
import CacheManagerProps from 'utils/cache/CacheManagerProps';
import { schedulingTypes, timereportTypes } from 'utils/constants';
import { appModules as modules } from 'utils/constants/appModules';
import { userConfiguration } from 'utils/constants/userConfiguration';
import { newDate } from 'utils/DateUtils';

export const ui = (
    state = {
        uid: '',
        sidebarOpen: false && !isSmallScreen(),
        smallScreen: isSmallScreen(),
        mediumScreen: isMediumScreen(),
        isMobile: isMobile,
        isSessionValidated: false,
        notifications: [],
        dashboardFilters: {},
        printContent: null,
        sort: 'intake',
        pageDetails: {},
        language: 'en',
        modal: null,
        ui: userConfiguration.UI.SINGLE,
        timereporting: timereportTypes.DEFAULT,
        timereportingLockedSince: null,
        timereportingConf: {},
        timereportingFilters: {
            activeUserId: null,
            activeDate: newDate()
        },
        scheduling: schedulingTypes.DEFAULT,
        hasDashboardAccess: false,
        accessibleDashboards: [],
        timeReportTags: [],
        timeReportTagsNormalized: {},
        workOrderArticleDateEnabled: false,
        dashboardFilters: {
            
        }
    },
    action
) => {
    switch (action.type) {
        case REQUEST_VALIDATE_SESSION: {
            return {
                ...state,
                isSessionValidated: false
            }
        }
        case MODEL_CHANGED: {
            return {
                ...state,
                pageDetails: {
                    ...state.pageDetails,
                    ['businessObjectDetails']: {}
                }
            }
        }
        case EDIT_PAGE_DETAILS: {

            return {
                ...state,
                pageDetails: {
                    ...state.pageDetails,
                    [action.pageName]: {
                        ...state.pageDetails[action.pageName],
                        ...action.payload
                    }
                }
            }
        }
        case SHOW_MODAL: {
            return {
                ...state,
                modal: {
                    modalType: action.modalType,
                    header: action.header || '',
                    props: action.props
                }
            }
        }
        case TOGGLE_SIDEBAR: {
            CacheManager.put(state.uid, CacheManagerProps.SIDEBAR_OPEN, !state.sidebarOpen);

            return {
                ...state,
                sidebarOpen: !state.sidebarOpen
            }
        }
        case CHANGE_LANGUAGE: {
            const userLanguage = action.language;

            const appLanguage = state.languageKey ?
                `${userLanguage}_${state.languageKey}` : userLanguage;

            return {
                ...state,
                language: appLanguage,
                userLanguage
            }
        }
        case LOGIN_FULFILLED: {
            const userLanguage = action.user.configuration.language;

            const appLanguage = action.instance.configuration.languageKey ?
                `${userLanguage}_${action.instance.configuration.languageKey}` : userLanguage;

            const ui = action.user.configuration.ui || userConfiguration.UI.SINGLE;

            const { appModules } = action;

            const timereporting = appModules[modules.UI_SETTINGS]
            const reportingType = timereporting?.custom?.timereports?.type || timereportTypes.DEFAULT;

            const timereportingLockedSince = timereporting && timereporting.custom && timereporting.custom.timereports
                && getTimeReportsLockedSinceDateExclusive(timereporting.custom.timereports.lockingDayOfMonth) || null;

            const hasDashboardAccess = action.aclIds.some(aclId => appModules[modules.DASHBOARD]?.dynamic?.accessLevelConfiguration[aclId])

            const accessLevelConfiguration = appModules[modules.DASHBOARD]?.dynamic?.accessLevelConfiguration || {};

            const { enabledDashboards, enabledDashboardsNormalized } = parseEnabledDashboards(appModules[modules.DASHBOARD]?.custom?.enabledDashboards || []);

            const accessibleDashboards = Object.keys(accessLevelConfiguration)
                .filter(aclId => action.aclIds.includes(aclId))
                .map(id => accessLevelConfiguration[id]);

            const { timeReportTags, timeReportTagsNormalized } = parseTimeReportTags(action.timeReportTags);
            const compensationReportTags = parseTimeReportTags(action.compensationReportTags);

            return {
                ...state,
                uid: action.user.id,
                isSessionValidated: true,
                languageKey: action.instance.configuration.languageKey,
                language: appLanguage,
                userLanguage,
                ui,
                timereporting: reportingType,
                timereportingLockedSince,
                timereportingConf: timereporting?.custom?.timereports,
                timereportingFilters: {
                    activeUserId: action.user.id,
                    activeDate: newDate()
                },
                scheduling: appModules[modules.UI_SETTINGS]?.custom?.workorders?.scheduling || schedulingTypes.DEFAULT,
                hasDashboardAccess,
                accessibleDashboards,
                timeReportTags,
                timeReportTagsNormalized,
                compensationReportTags: compensationReportTags.timeReportTags,
                compensationReportTagsNormalized: compensationReportTags.timeReportTagsNormalized,
                enabledDashboards,
                enabledDashboardsNormalized,
                workOrderArticleDateEnabled: appModules[modules.UI_SETTINGS]?.custom?.workorders?.articleDateEnabled || false,
                textRowsEnabled: appModules[modules.UI_SETTINGS]?.custom?.workorders?.textRowsEnabled || false
            }
        }
        case LOGIN_REJECTED: {

            return {
                ...state,
                isSessionValidated: true
            }
        }
        case LOGOUT: {
            return {
                ...state,
                isSessionValidated: true
            }
        }
        case TOGGLE_DASHBOARD_FILTER: {
            const filters = { ...state.dashboardFilters };

            filters[action.name] = action.value;

            CacheManager.put(state.uid, CacheManagerProps.FILTERS, filters);

            return {
                ...state,
                dashboardFilters: filters
            }
        }
        case RESET_DASHBOARD_FILTERS: {
            const filters = {};

            CacheManager.put(state.uid, CacheManagerProps.FILTERS, filters);

            return {
                ...state,
                dashboardFilters: filters
            }
        }
        case INIT_DASHBOARD_FILTERS: {
            return {
                ...state,
                dashboardFilters: action.filters
            }
        }
        case TOGGLE_SORT: {
            CacheManager.put(state.uid, CacheManagerProps.SORT, action.value);

            return {
                ...state,
                sort: action.value
            }
        }
        case INIT_SORT: {
            return {
                ...state,
                sort: action.value
            }
        }
        case SHOW_PRINT: return ({ ...state, printContent: action.content })
        case SHOW_NOTIFICATION: {
            let notifications = state.notifications.slice();
            notifications.push(action.notification);

            return {
                ...state,
                notifications
            }
        }
        case HIDE_NOTIFICATION: {
            let notifications = state.notifications.slice();
            let indexToRemove = notifications.findIndex((n) => n.id === action.id);
            if (indexToRemove > -1) {
                notifications.splice(indexToRemove, 1);
            }
            return {
                ...state,
                notifications
            };
        }
        case UPDATE_WO_CONFIGURATION: {

            CacheManager.remove(state.uid, CacheManagerProps.FILTERS);

            return {
                ...state,
                dashboardFilters: {}
            }
        }
        case CHANGE_UI: {
            const { ui } = action;

            return {
                ...state,
                ui
            }
        }
        case EDIT_TIME_REPORT_TAGS: {
            const { tags } = action;

            const { timeReportTags, timeReportTagsNormalized } = parseTimeReportTags(tags);

            return {
                ...state,
                timeReportTags,
                timeReportTagsNormalized
            }
        }
        case EDIT_COMPENSATION_REPORT_TAGS: {
            const { tags } = action;

            const { timeReportTags, timeReportTagsNormalized } = parseTimeReportTags(tags);

            return {
                ...state,
                compensationReportTags: timeReportTags,
                compensationReportTagsNormalized: timeReportTagsNormalized
            }
        }
        case DASHBOARD_FILTERS_CHANGE: return ({
            ...state,
            dashboardFilters: action.filters
        })
        case EDIT_TIMEREPORTING_FILTERS: {
            const { name, value } = action;

            return {
                ...state,
                timereportingFilters: {
                    ...state.timereportingFilters,
                    [name]: value
                }
            }
        }
        default: {
            return state;
        }
    }
};


const parseTimeReportTags = tags => ({
    timeReportTags: tags,
    timeReportTagsNormalized: normalize(tags, 'id')
});

const parseEnabledDashboards = enabledDashboards => ({
    enabledDashboards,
    enabledDashboardsNormalized: normalize(enabledDashboards, 'id')
});