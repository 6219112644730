import React, { Component } from 'react';
import Sidebar from './Sidebar';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchUsers } from '../../../data/actions/users';
import withInstanceData from 'components/common/with-instancedata';
import { fetchSupplierInvoices } from 'data/actions/supplierinvoices';
import { appModules } from 'utils/constants/appModules';
import { SUPPLIER_INVOICE_STATUS_PENDING } from 'utils/constants';

class SidebarContainer extends Component {

    componentDidMount() {
        const { users, fetchUsers, hasAppModule, fetchSupplierInvoiceCount } = this.props;

        users.length === 0 && fetchUsers();

        hasAppModule(appModules.SUPPLIER_INVOICES) && fetchSupplierInvoiceCount();
    }

    render() {
        return (
            <Sidebar
                isCalendarOpen={this.props.activeTab === 'calendar'}
                {...this.props} />
        )
    }
}

const mapStateToProps = state => ({
    activeTab: state.workOrders.activeTab,
    users: state.users.users
});

const mapDispatchToProps = dispatch => ({
    fetchUsers: () => dispatch(fetchUsers()),
    fetchSupplierInvoiceCount: () => dispatch(fetchSupplierInvoices({ status: SUPPLIER_INVOICE_STATUS_PENDING }))
  });

export default withInstanceData(withRouter(connect(mapStateToProps, mapDispatchToProps)(SidebarContainer)));

