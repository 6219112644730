import dayjs from 'dayjs';

export const today = dayjs();

export const toDate = (dateString: string | number) => dayjs(dateString);

export const formatDateTimeVerbose = (str: string | number): string => {
    if (!str) {
        return '';
    }

    const d = toDate(str);

    if (!d.isValid()) {
        return '';
    }

    if (d.year() === today.year()) {
        return d.format(`DD MMM HH:mm`);
    }

    return d.format(`DD MMM YYYY, HH:mm`);
};
