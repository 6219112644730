export const ee_elevator = {
    ui: {
        SETTINGS_BUSINESS_OBJECT: 'Liftid',
        OBJECTS: 'Liftid',
        MANAGE_OBJECTS: 'Vaata/muuda lifte',
    },
    form: {
        OBJECT: 'Lift',
    },
    acl: {
        PERMISSION_BUSINESS_OBJECTS_READ: 'Näha lifte',
        PERMISSION_BUSINESS_OBJECTS_EDIT: 'Muuta lifte',
    }
}