import { LOGIN_FULFILLED } from 'data/actions/common/authActions';
import AnalyticsManager from 'utils/analytics'

export const analytics = (
    state = {

    },
    action
) => {
    switch (action.type) {
        case LOGIN_FULFILLED: {
            if (action.userType !== 'super') {
                AnalyticsManager.publish(`${action.instance.displayName}-LOGIN`, 'LOGIN', 'OK');
            }
        }
        default: {
            return state;
        }
    }
}