import {
    UPDATE_APP_MODULE_PROPERTY,
    UPDATE_APP_MODULE_STATE
} from 'data/actions/appmodules';

import { appModules } from '../../../utils/constants/appModules';

const defaultState = {
    [appModules.MAPS]: {

    }
}

export const appmodules = (
    state = defaultState,
    action
) => {
    switch (action.type) {

        case UPDATE_APP_MODULE_PROPERTY: {

            return {
                ...state,
                [action.module]: {
                    ...state[action.module],
                    [action.name]: action.value
                }
            }
        }

        case UPDATE_APP_MODULE_STATE: {

            return {
                ...state,
                [action.module]: {
                    ...action.state
                }
            }
        }

        default: {
            return state;
        }
    }
};