export const en_elevator = {
    ui: {
        SETTINGS_BUSINESS_OBJECT: 'Elevator',
        OBJECTS: 'Elevators',
        MANAGE_OBJECTS: 'Edit elevators',
    },
    form: {
        OBJECT: 'Elevator',
        CONTACT: 'Ordered by',
    },
    acl: {
        PERMISSION_BUSINESS_OBJECTS_READ: 'View elevators',
        PERMISSION_BUSINESS_OBJECTS_EDIT: 'Edit elevators',
    }
}