import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

const LocalizedDropdown = ({ t, children, selectOnNavigation, selectOnBlur, ...props }) => {

    const [search, setSearch] = useState('');

    let options = props.options;

    if (props.options.length > 200 && props.search && search.length < 2) {
        options = options.slice(0, 200);
        props.minCharacters = 2;
    }

    return (
        <Dropdown
            {...props}
            selectOnBlur={selectOnBlur}
            selectOnNavigation={selectOnNavigation}
            noResultsMessage={t('NO_RESULTS_FOUND')}
            additionLabel={`${t('ADD')} `}
            onSearchChange={e => setSearch(e.target.value)}
            options={options}>
            {children}
        </Dropdown>
    );
}

LocalizedDropdown.propTypes = {
    t: PropTypes.func.isRequired,
    children: PropTypes.any,
    selectOnBlur: PropTypes.bool,
    selectOnNavigation: PropTypes.bool
}

LocalizedDropdown.defaultProps = {
    selectOnBlur: false,
    selectOnNavigation: false
}

export default withTranslation('ui')(LocalizedDropdown);