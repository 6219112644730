import LocalizedDropdown from "components/common/localized-dropdown";
import { useInstanceData } from "components/common/use-instance-data";
import { useState } from "react";
import { appModules as modules } from "utils/constants/appModules";
import './ErpReferencePicker.css';

const StockPointPicker = ({ value, onChange, search, selection, fluid, wrapAsColumn }) => {

    const [open, setOpen] = useState(false);

    const { appModules } = useInstanceData();

    const stockPoints = appModules[modules.ERP]?.dynamic.stockPoints || [];

    const options = stockPoints.map(cc => ({
        key: cc.value,
        text: `${cc.value} ${cc.name}`,
        value: cc.value
    }));

    const dd = (
        <LocalizedDropdown
            open={wrapAsColumn ? open : undefined}
            onClick={() => setOpen(o => !o)}
            className={`man-erp-reference-picker${value || wrapAsColumn ? '' : ' man-required'}`}
            fluid={fluid}
            search={search}
            selection={selection}
            options={options}
            value={value}
            text={value}
            onChange={(e, { value }) => {
                onChange(value);
                setOpen(false);
            }} />)

    if (wrapAsColumn) {
        return (
            <td onClick={() => setOpen(o => !o)}
                style={{
                    background: value ? 'initial' : '#ee5855b3'
                }}>
                {dd}
            </td>
        )
    }

    return dd;
}

export default StockPointPicker;