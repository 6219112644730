import { useDispatch } from 'react-redux';
import {
    showErrorNotification,
    showSuccessNotification,
    showInfoNotification
} from '../../../data/actions/common/notificationActions';

export const useNotifications = () => {
    const dispatch = useDispatch();

    return {
        showErrorNotification: message => dispatch(showErrorNotification(message)),
        showSuccessNotification: (message, params) => dispatch(showSuccessNotification(message, params)),
        showInfoNotification: message => dispatch(showInfoNotification(message))
    }
}