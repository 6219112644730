import i18n from 'i18next';
import {
    ee,
    ee_copy,
    ee_elevator,
    ee_machine,
    ee_security,
    ee_gates,
} from './translations/ee';
import {
    en,
    en_copy,
    en_elevator,
    en_machine,
    en_security,
    en_gates,
} from './translations/en';
import {
    se_baxec,
    se_cfs,
    se_doorway,
    se_elte,
    se_eyesec,
    se_fclarmautomatik,
    se_grevejakob,
    se_orebro,
    se_passera,
    se_reflora,
    se_putsvackfonsterputs,
    se_professionell_sakerhet,
    se_soderlarm,
    se_veosol, 
    se_atlantic,
    se_dalalas,
    se_primohiss,
    se_locksafe,
    se_directlarm,
    se_sensec,
    se_eliotprotect,
    se_sakerhetsgruppen,
    se_wermsec,
    se_jtsecurity,
    se_totrust,
    se_spangalas,
    se_larmkraft,
    se_albasec,
    se_trukks,
    en_trukks,
    se_orebrolarm,
    se_portproffsen,
    se_procontrol,
    en_au_elevator,
} from './translations/instance';
import {
    se,
    se_copy,
    se_elevator,
    se_machine,
    se_security,
    se_gates,
} from './translations/se';
import {
    lv
} from './translations/lv';

i18n.init({
    resources: {
        en,
        en_copy,
        en_machine,
        en_elevator,
        en_security,
        en_gates,
        ee,
        ee_copy,
        ee_machine,
        ee_elevator,
        ee_security,
        ee_gates,
        se,
        se_copy,
        se_machine,
        se_elevator,
        se_security,
        se_gates,
        se_grevejakob,
        se_elte,
        se_orebro,
        se_baxec,
        se_professionell_sakerhet,
        se_fclarmautomatik,
        se_passera,
        se_reflora,
        se_putsvackfonsterputs,
        se_soderlarm,
        se_veosol,
        se_eyesec,
        se_doorway,
        se_cfs,
        se_atlantic,
        se_dalalas,
        se_primohiss,
        se_locksafe,
        se_directlarm,
        se_sensec,
        se_eliotprotect,
        se_sakerhetsgruppen,
        se_wermsec,
        se_jtsecurity,
        se_totrust,
        se_spangalas,
        se_larmkraft,
        se_albasec,
        se_trukks,
        en_trukks,
        se_orebrolarm,
        se_portproffsen,
        se_procontrol,
        en_au_elevator,
        lv
    },
    fallbackLng: {
        en_copy: ['en'],
        en_machine: ['en'],
        en_elevator: ['en'],
        en_security: ['en'],
        en_gates: ['en'],

        ee_copy: ['ee'],
        ee_machine: ['ee'],
        ee_elevator: ['ee'],
        ee_security: ['ee'],
        ee_gates: ['ee'],

        se_copy: ['se'],
        se_machine: ['se'],
        se_elevator: ['se'],
        se_security: ['se'],
        se_gates: ['se'],

        se_grevejakob: ['se_elevator', 'se'],
        ee_grevejakob: ['ee_elevator', 'ee'],
        en_grevejakob: ['en_elevator', 'en'],
        lv_grevejakob: ['lv'],

        se_elte: ['se_security', 'se'],
        ee_elte: ['ee_security', 'ee'],
        en_elte: ['en_security', 'en'],
        lv_elte: ['lv'],

        se_orebro: ['se_elevator', 'se'],
        ee_orebro: ['ee_elevator', 'ee'],
        en_orebro: ['en_elevator', 'en'],
        lv_orebro: ['lv'],

        se_baxec: ['se_security', 'se'],
        ee_baxec: ['ee_security', 'ee'],
        en_baxec: ['en_security', 'en'],
        lv_baxec: ['lv'],

        se_professionell_sakerhet: ['se_security', 'se'],
        ee_professionell_sakerhet: ['ee_security', 'ee'],
        en_professionell_sakerhet: ['en_security', 'en'],
        lv_professionell_sakerhet: ['lv'],

        se_fclarmautomatik: ['se_security', 'se'],
        ee_fclarmautomatik: ['ee_security', 'ee'],
        en_fclarmautomatik: ['se_security', 'en'],
        lv_fclarmautomatik: ['lv'],

        se_passera: ['se_security', 'se'],
        ee_passera: ['ee_security', 'ee'],
        en_passera: ['se_security', 'en'],
        lv_passera: ['lv'],

        se_soderlarm: ['se_security', 'se'],
        ee_soderlarm: ['ee_security', 'ee'],
        en_soderlarm: ['se_security', 'en'],
        lv_soderlarm: ['lv'],

        se_veosol: ['se_security', 'se'],
        ee_veosol: ['ee_security', 'ee'],
        en_veosol: ['se_security', 'en'],
        lv_veosol: ['lv'],

        se_eyesec: ['se_security', 'se'],
        ee_eyesec: ['ee_security', 'ee'],
        en_eyesec: ['se_security', 'en'],
        lv_eyesec: ['lv'],

        se_doorway: ['se_security', 'se'],
        ee_doorway: ['ee_security', 'ee'],
        en_doorway: ['se_security', 'en'],
        lv_doorway: ['lv'],

        se_cfs: ['se_security', 'se'],
        ee_cfs: ['ee_security', 'ee'],
        en_cfs: ['se_security', 'en'],
        lv_cfs: ['lv'],
        
        se_atlantic: ['se_security', 'se'],
        ee_atlantic: ['ee_security', 'ee'],
        en_atlantic: ['se_security', 'en'],
        lv_atlantic: ['lv'],

        se_dalalas: ['se_security', 'se'],
        ee_dalalas: ['ee_security', 'ee'],
        en_dalalas: ['se_security', 'en'],
        lv_dalalas: ['lv'],
        
        se_primohiss: ['se_elevator', 'se'],
        ee_primohiss: ['ee_elevator', 'ee'],
        en_primohiss: ['en_elevator', 'en'],
        lv_primohiss: ['lv'],
        
        se_locksafe: ['se_security', 'se'],
        ee_locksafe: ['ee_security', 'ee'],
        en_locksafe: ['en_security', 'en'],
        lv_locksafe: ['lv'],

        se_directlarm: ['se_security', 'se'],
        ee_directlarm: ['ee_security', 'ee'],
        en_directlarm: ['en_security', 'en'],
        lv_directlarm: ['lv'],

        se_sensec: ['se_security', 'se'],
        ee_sensec: ['ee_security', 'ee'],
        en_sensec: ['en_security', 'en'],
        lv_sensec: ['lv'],

        se_eliotprotect: ['se_security', 'se'],
        ee_eliotprotect: ['ee_security', 'ee'],
        en_eliotprotect: ['en_security', 'en'],
        lv_eliotprotect: ['lv'],

        se_sakerhetsgruppen: ['se_security', 'se'],
        ee_sakerhetsgruppen: ['ee_security', 'ee'],
        en_sakerhetsgruppen: ['en_security', 'en'],
        lv_sakerhetsgruppen: ['lv'],

        se_wermsec: ['se_security', 'se'],
        ee_wermsec: ['ee_security', 'ee'],
        en_wermsec: ['en_security', 'en'],
        lv_wermsec: ['lv'],

        se_jtsecurity: ['se_security', 'se'],
        ee_jtsecurity: ['ee_security', 'ee'],
        en_jtsecurity: ['en_security', 'en'],
        lv_jtsecurity: ['lv'],
        
        se_totrust: ['se_security', 'se'],
        ee_totrust: ['ee_security', 'ee'],
        en_totrust: ['en_security', 'en'],
        lv_totrust: ['lv'],
        
        se_spangalas: ['se_security', 'se'],
        ee_spangalas: ['ee_security', 'ee'],
        en_spangalas: ['en_security', 'en'],
        lv_spangalas: ['lv'],
        
        se_larmkraft: ['se_security', 'se'],
        ee_larmkraft: ['ee_security', 'ee'],
        en_larmkraft: ['en_security', 'en'],
        lv_larmkraft: ['lv'],

        se_albasec: ['se_security', 'se'],
        ee_albasec: ['ee_security', 'ee'],
        en_albasec: ['en_security', 'en'],
        lv_albasec: ['lv'],
        
        se_portproffsen: ['se_gates', 'se'],
        ee_portproffsen: ['ee_gates', 'ee'],
        en_portproffsen: ['en_gates', 'en'],
        lv_portproffsen: ['lv'],

        se_orebrolarm: ['se_security', 'se'],
        ee_orebrolarm: ['ee_security', 'ee'],
        en_orebrolarm: ['en_security', 'en'],
        lv_orebrolarm: ['lv'],

        se_procontrol: ['se'],
        ee_procontrol: ['ee'],
        en_procontrol: ['en'],
        lv_procontrol: ['lv'],

        se_reflora: ['se'],
        ee_reflora: ['ee'],
        en_reflora: ['en'],
        lv_reflora: ['lv'],
        
        se_putsvackfonsterputs: ['se'],
        ee_putsvackfonsterputs: ['ee'],
        en_putsvackfonsterputs: ['en'],
        lv_putsvackfonsterputs: ['lv'],

        se_trukks: ['se'],
        ee_trukks: ['ee'],
        en_trukks: ['en'],
        lv_trukks: ['lv'],

        lv_copy: ['lv'],
        lv_machine: ['lv'],
        lv_elevator: ['lv'],
        lv_security: ['lv'],

        se_au_elevator: ['se_elevator', 'se'],
        ee_au_elevator: ['ee_elevator', 'ee'],
        en_au_elevator: ['en_elevator', 'en'],
        lv_au_elevator: ['lv'],

        default: ['se'],
    },
    debug: process.env.NODE_ENV === 'development' && false,

    ns: ['ui'],
    defaultNS: 'ui',

    keySeparator: false,

    interpolation: {
        escapeValue: false,
        formatSeparator: ',',
    },

    react: {
        wait: true,
    },
});

export const ns = {
    UI: 'ui',
    MESSAGES: 'messages',
    CALENDAR: 'calendar',
};

export default i18n;
