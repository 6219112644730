import qs from 'query-string';
import { STATUS_ACTIVE } from '../../../utils/constants';
import { deletee, get, post, put } from '../../../utils/http/HttpClient';
import instanceService from '../instance/instanceService';

export default {
    fetch: async () => {
        const start = new Date().getTime();

        const { articles } = await get('/articles');

        const end = new Date().getTime();
        const time = end - start;

        instanceService.postMetric('articles', time);

        return articles;
    },
    query: async q => {
        const { articles } = await get(`/articles/q?q=${q}`);

        return articles;
    },
    fetchInactive: async () => {

        const { articles } = await get('/articles/inactive');

        return articles;
    },
    fetchDetails: async id => {
        const { article } = await get(`/articles/details/${id}`);

        return article;
    },
    create: async article => {
        article.status = STATUS_ACTIVE;
        const { id } = await post('/articles', { article });

        return id;
    },
    fetchProposedId: async length => {
        const { id } = await get(`/articles/id/propose?length=${length}`);

        return id;
    },
    fetchPrices: async (id, customerId, businessObjectId) => {
        const query = {
            customerId,
            businessObjectId
        }

        const response = await get(`/articles/${id}?${qs.stringify(query)}`);

        return response;
    },
    update: async article => {
        await put('/articles', { article });
    },
    delete: async id => {
        await deletee(`/articles/${id}`);
    },
    fetchSuppliers: async articleId => {
        const { suppliers } = await get(`/articles/${articleId}/suppliers`);

        return suppliers;
    },
    updateSuppliers: async (articleId, suppliers) => {
        await put(`/articles/${articleId}/suppliers`, {
            suppliers
        });
    },
    fetchStock: async articleId => {
        const { amount } = await get(`/articles/${articleId}/stock`);

        return amount;
    },
    addToStock: async (articleId, amount, price) => {
        const response = await post(`/articles/${articleId}/stock`, {
            amount,
            price,
            type: 'add'
        });

        return response.amount;
    },
    removeFromStock: async (articleId, amount, price) => {
        const response = await post(`/articles/${articleId}/stock`, {
            amount,
            price,
            type: 'remove'
        });

        return response.amount;
    },
    fetchStockHistory: async articleId => {
        const response = await get(`/articles/${articleId}/stock/history`);

        return response;
    },
    fetchGroups: async () => {
        const { articleGroups } = await get(`/articles/groups`);

        return articleGroups;
    },
    createGroup: async (title, displayName) => {
        const { id } = await post(`/articles/groups`, {
            title,
            displayName
        });

        return id;
    },
    updateGroup: async articleGroup => {
        await put(`/articles/groups/${articleGroup.id}`, {
            ...articleGroup
        });
    },
    deleteGroup: async id => {
        await deletee(`/articles/groups/${id}`);
    },
    fetchPriceLists: async () => {
        const { priceLists } = await get(`/articles/pricelists`);

        return priceLists;
    },
    createPriceList: async title => {
        const { id } = await post(`/articles/pricelists`, {
            title
        });

        return id;
    },
    updatePriceList: async priceList => {
        await put(`/articles/pricelists/${priceList.id}`, {
            priceList
        });
    },
    deletePriceList: async id => {
        await deletee(`/articles/pricelists/${id}`);
    },
    fetchPriceListDetails: async priceListId => {
        const { pricingTypes } = await get(`/articles/pricelists/${priceListId}/details`);

        return {
            pricingTypes
        }
    },
    createPriceListDetails: async (priceListId, pricingType) => {
        const { id } = await post(`/articles/pricelists/${priceListId}/details`, {
            pricingType
        });

        return id;
    },
    updatePriceListDetails: async (priceListId, pricingType) => {
        await put(`/articles/pricelists/${priceListId}/details`, {
            pricingType
        });
    },
    deletePriceListDetails: async (priceListId, pricingTypeId) => {
        await deletee(`/articles/pricelists/${priceListId}/details/${pricingTypeId}`);
    },
    fetchSBSCProducts: async () => {
        const { products } = await get('/sbsc/products');

        return products;
    }
}