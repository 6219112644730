import withInstanceData from 'components/common/with-instancedata';
import { withPermissions } from 'components/common/with-permissions/WithPermissions';
import { openWorkOrderTab } from 'data/actions/workorders';
import * as qs from 'query-string';
import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import AnalyticsManager from '../../utils/analytics';
import { appModules } from '../../utils/constants/appModules';
import { permission } from '../../utils/constants/permissions';
import ConfirmModalContainer from '../common/confirm';
import GlobalLoader from '../common/global-loader';
import Loader from '../common/loader';
import ModalContainer from '../common/modal/ModalContainer';
import NotificationsContainer from '../common/notifications';
import { requireAppModule } from '../common/require-app-module/RequireAppModule';
import { NotPermitted, requirePermission } from '../common/require-permission';
import './Main.css';
import SidebarContainer from './sidebar/SidebarContainer';
import TopbarContainer from './topbar';

const Dashboard = Loadable({
    loader: () => import('./dashboard'),
    loading: Loader,
});

const Profile = Loadable({
    loader: () => import('./profile'),
    loading: Loader,
});

const Settings = Loadable({
    loader: () => import('./settings'),
    loading: Loader,
});

const Inventory = Loadable({
    loader: () => import('./inventory'),
    loading: Loader,
});

const Reports = Loadable({
    loader: () => import('./reports'),
    loading: Loader,
});

const TimeReporting = Loadable({
    loader: () => import('./time-reporting'),
    loading: Loader,
});

const Sales = Loadable({
    loader: () => import('./sales'),
    loading: Loader,
});

const Projects = Loadable({
    loader: () => import('./projects'),
    loading: Loader,
});

const Proposals = Loadable({
    loader: () => import('./proposals'),
    loading: Loader,
});

const MyDashboard = Loadable({
    loader: () => import('./my-dashboard'),
    loading: Loader,
});

const Deviations = Loadable({
    loader: () => import('./deviations'),
    loading: Loader,
});

const SupplierInvoices = Loadable({
    loader: () => import('./supplierinvoices'),
    loading: Loader,
})

const MainWrapper = props => (
    <div
        className={`${
            props.sidebarOpen ? 'man-sidebar-open' : 'man-sidebar-closed'
        }`}>
        <Main {...props} />
    </div>
);

class Main extends Component {
    componentDidMount() {
        const {
            history,
            location,
            hasDashboardAccess,
            userConfiguration,
            hasAppModule,
            hasPermission,
            openWorkOrderTab,
        } = this.props;

        const { workOrderId, workOrderTitle, customerName } = qs.parse(
            location.search,
            { decode: false }
        );

        if (workOrderId && workOrderTitle) {
            openWorkOrderTab(workOrderId, {
                title: workOrderTitle,
                customerName,
            });

            history.push('/');

            return;
        }

        if (
            hasAppModule(appModules.DASHBOARD) &&
            hasPermission(permission.DASHBOARD_READ) &&
            userConfiguration?.startPage === 'dashboard' &&
            history.location?.state?.from === '/login' &&
            (location.pathname === '/' ||
                location.pathname === '/index.html') &&
            hasDashboardAccess
        ) {
            history.push('/dashboard');
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.location.pathname === this.props.location.pathname) {
            return;
        }

        AnalyticsManager.pageview(newProps.location.pathname);
    }

    shouldComponentUpdate(newProps) {
        if (newProps.sidebarOpen === this.props.sidebarOpen) {
            return newProps.location.pathname !== this.props.location.pathname;
        }

        return false;
    }

    render() {
        return (
            <React.Fragment>
                <GlobalLoader />

                <TopbarContainer />

                <NotificationsContainer />

                <ConfirmModalContainer />

                <ModalContainer />

                <div className="man-content">
                    <div className="man-sider">
                        <SidebarContainer />
                    </div>

                    <div>
                        <div className="man-main-container">
                            <Switch>
                                <Route exact path="/" component={Dashboard} />
                                <Route path="/profile" component={Profile} />
                                <Route
                                    path="/settings"
                                    component={requirePermission(
                                        Settings,
                                        permission.SETTINGS_READ,
                                        NotPermitted
                                    )}
                                />
                                <Route
                                    path="/inventory"
                                    component={Inventory}
                                />
                                <Route
                                    path="/reports"
                                    component={requirePermission(
                                        Reports,
                                        permission.REPORTS_READ,
                                        NotPermitted
                                    )}
                                />
                                <Route path="/time" component={TimeReporting} />
                                <Route
                                    path="/sales"
                                    component={requireAppModule(
                                        requirePermission(
                                            Sales,
                                            permission.SALES_ORDER_READ,
                                            NotPermitted
                                        ),
                                        appModules.CRM
                                    )}
                                />
                                <Route
                                    path="/projects"
                                    component={requireAppModule(
                                        requirePermission(
                                            Projects,
                                            permission.PROJECTS_READ,
                                            NotPermitted
                                        ),
                                        appModules.PROJECTS
                                    )}
                                />
                                <Route
                                    path="/proposals"
                                    component={requireAppModule(
                                        requirePermission(
                                            Proposals,
                                            permission.PROPOSAL_READ,
                                            NotPermitted
                                        ),
                                        appModules.CRM
                                    )}
                                />
                                <Route
                                    path="/dashboard"
                                    component={requireAppModule(
                                        requirePermission(
                                            MyDashboard,
                                            permission.DASHBOARD_READ,
                                            NotPermitted
                                        ),
                                        appModules.DASHBOARD
                                    )}
                                />
                                <Route
                                    path="/deviations"
                                    component={requireAppModule(
                                        requirePermission(
                                            Deviations,
                                            permission.DEVIATIONS_READ,
                                            NotPermitted
                                        ),
                                        appModules.DEVIATIONS
                                    )}
                                />

                                <Route
                                    path="/supplierinvoices"
                                    component={requireAppModule(
                                        requirePermission(
                                            SupplierInvoices,
                                            permission.SUPPLIER_INVOICES_READ,
                                            NotPermitted
                                        ),
                                        appModules.SUPPLIER_INVOICES
                                    )}
                                />

                                <Route path="*" component={Dashboard} />
                                {/* TODO NOT FOUND? */}
                            </Switch>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    hasDashboardAccess: state.ui.hasDashboardAccess,
    userConfiguration: state.auth.user.configuration,
});

const mapDispatchToProps = dispatch => ({
    openWorkOrderTab: (id, props) => dispatch(openWorkOrderTab(id, props)),
});

export default withRouter(
    withPermissions(
        withInstanceData(
            connect(mapStateToProps, mapDispatchToProps)(MainWrapper)
        )
    )
);
