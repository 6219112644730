import {
    FETCH_ACLS,
    FETCH_ACLS_FULFILLED,
    FETCH_ACLS_REJECTED,
    ACL_CHANGED,
    ACL_DELETED,
    FETCH_PERMISSIONS,
    FETCH_PERMISSIONS_FULFILLED,
    FETCH_PERMISSIONS_REJECTED
} from '../../actions/acls';

const defaultState = {
    isFetching: false,
    isFetchingPermissions: false,
    acls: [],
    permissions: []
}

export const acls = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case FETCH_ACLS: {
            return {
                ...state,
                isFetching: true
            }
        }
        case FETCH_ACLS_FULFILLED: {
            return {
                ...state,
                isFetching: false,
                acls: action.acls
            }
        }
        case FETCH_ACLS_REJECTED: {
            return {
                ...state,
                isFetching: false
            }
        }
        case ACL_CHANGED: {
            const acls = [...state.acls];
            const idx = acls.findIndex(a => a.id === action.acl.id);

            if (idx < 0) {
                acls.push(action.acl);
            } else {
                acls[idx] = action.acl;
            }

            return {
                ...state,
                acls
            }
        }
        case ACL_DELETED: {
            const acls = [...state.acls];
            const idx = acls.findIndex(a => a.id === action.id);

            if (idx > -1) {
                acls.splice(idx, 1);
            }

            return {
                ...state,
                acls
            }
        }
        case FETCH_PERMISSIONS: {
            return {
                ...state,
                isFetchingPermissions: true
            }
        }
        case FETCH_PERMISSIONS_FULFILLED: {
            return {
                ...state,
                isFetchingPermissions: false,
                permissions: action.permissions
            }
        }
        case FETCH_PERMISSIONS_REJECTED: {
            return {
                ...state,
                isFetchingPermissions: false
            }
        }
        default: {
            return state;
        }
    }
};