import {
    FETCH_SALES_ORDERS,
    FETCH_SALES_ORDERS_FULFILLED,
    FETCH_SALES_ORDERS_REJECTED,
    SALES_ORDER_CREATED,
    SALES_ORDER_CHANGED,
    SALES_ORDER_DELETED,
    SALES_ORDER_TABLE_STATE_CHANGE,
    SALES_ORDER_FILTERS_CHANGE
} from '../../actions/salesorders';

const defaultState = {
    isFetching: false,
    orders: [],
    tableState: undefined,
    filters: {
        status: 'open'
    },
    requireRefresh: true
}

export const salesorders = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case SALES_ORDER_TABLE_STATE_CHANGE: return ({
            ...state,
            tableState: action.state
        })
        case SALES_ORDER_FILTERS_CHANGE: return ({
            ...state,
            filters: action.filters
        })
        case FETCH_SALES_ORDERS: {
            return {
                ...state,
                isFetching: true,
                requireRefresh: false
            }
        }
        case FETCH_SALES_ORDERS_FULFILLED: {
            return {
                ...state,
                isFetching: false,
                orders: action.orders
            }
        }
        case FETCH_SALES_ORDERS_REJECTED: {
            return {
                ...state,
                isFetching: false
            }
        }
        case SALES_ORDER_CREATED: {
            return {
                ...state,
                requireRefresh: true
            }
        }
        case SALES_ORDER_CHANGED: {
            const orders = [...state.orders];
            const idx = orders.findIndex(m => m.id === action.order.id);

            if (idx < 0) {
                orders.push(action.order);
            } else {
                orders[idx] = action.order;
            }

            return {
                ...state,
                orders
            }
        }
        case SALES_ORDER_DELETED: {
            const orders = [...state.orders];
            const idx = orders.findIndex(m => m.id === action.id);

            orders.splice(idx, 1);

            return {
                ...state,
                orders
            }
        }
        default: {
            return state;
        }
    }
};