export const fieldTypes = {
    TEXT: 'text',
    TEXTAREA: 'textarea',
    DATE: 'date',
    ROW: 'row',
    NUMBER: 'number',
    BLOCK: 'block',
    SELECTION: 'selection',
    DROPDOWN: 'dropdown',
    ARTICLE: 'article',
    CHECKBOX: 'checkbox',
    GROUP: 'group',
    LASTEDIT: 'lastedit'
}