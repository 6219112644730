import React from 'react';
import { Form, Input, Button, Divider } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Alert from '../../common/alert';
import { post } from '../../../utils/http/HttpClient';
import Loader from '../../common/loader';

class PasswordRecoveryRequestFormContainer extends React.Component {

    state = {
        email: '',
        isLoading: false,
        error: null,
        done: false
    }

    handleSubmit = async () => {

        if (!this.state.email) {
            return;
        }

        this.setState({
            isLoading: true
        });

        try {
            await post('/pw', {
                email: this.state.email,
                hostname: window.location.hostname
            });

            this.setState({
                isLoading: false,
                done: true
            })

        } catch (error) {
            this.setState({
                error,
                isLoading: false
            })
        }
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        const { t } = this.props;

        if (this.state.done) {
            return (
                <div style={{ textAlign: 'center', justifyContent: 'center' }}>
                    <p dangerouslySetInnerHTML={{ __html: t('messages:PASSWORD_CHANGE_SUCCESSFULLY_REQUESTED') }} />
                    <a href="/" style={{ margin: '10px 0', textAlign: 'right' }}>{t('LOG_IN_INSTEAD')}</a>
                </div>
            )
        }

        return (
            <div style={{ opacity: this.state.isLoading ? 0.5 : 1 }}>
                {this.state.isLoading && <Loader />}

                <p dangerouslySetInnerHTML={{ __html: t('messages:REQUEST_PASSWORD_CHANGE_TEXT') }} />

                {this.state.error ?
                    <Alert type="error" message={t('SERVER_ERROR')} />
                    :
                    <Form style={{ opacity: this.props.isLoading ? 0.5 : 1 }}>

                        <Form.Field>
                            <label>{t('EMAIL')}</label>
                            <Input
                                name="email"
                                type="text"
                                isRequired={true}
                                required="required"
                                placeholder="user@company.com"
                                value={this.state.email}
                                onChange={this.handleInputChange} />
                        </Form.Field>

                        <Divider />
                        <Button
                            className="Login-Form-Button man-button-primary"
                            loading={this.state.isLoading}
                            onClick={this.handleSubmit}>
                            {t('CONFIRM')}
                        </Button>
                    </Form>
                }
                <a href="/" style={{ margin: '10px 0', textAlign: 'right' }}>{t('LOG_IN_INSTEAD')}</a>
            </div>
        )
    }
}

PasswordRecoveryRequestFormContainer.propTypes = {
    t: PropTypes.func.isRequired
}

export default PasswordRecoveryRequestFormContainer;