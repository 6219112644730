import { patch } from '../../../utils/http/HttpClient';
import { setUserUiSetting } from '../../../utils/UiUtils';
import { fetchWorkOrders } from '../workorders';

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_UI = 'CHANGE_UI';
export const CHANGE_START_PAGE = 'CHANGE_START_PAGE';
export const TOGGLE_DASHBOARD_FILTER = 'TOGGLE_DASHBOARD_FILTER';
export const RESET_DASHBOARD_FILTERS = 'RESET_DASHBOARD_FILTERS';
export const INIT_DASHBOARD_FILTERS = 'INIT_DASHBOARD_FILTERS';
export const SHOW_PRINT = 'SHOW_PRINT';
export const SHOW_PRINT_FULFILLED = 'SHOW_PRINT_FULFILLED';
export const TOGGLE_SORT = 'TOGGLE_SORT';
export const INIT_SORT = 'INIT_SORT';
export const EDIT_PAGE_DETAILS = 'EDIT_PAGE_DETAILS';
export const SHOW_MODAL = 'SHOW_MODAL';
export const SHOW_BOTTOM_SHEET = 'SHOW_BOTTOM_SHEET';
export const EDIT_TIME_REPORT_TAGS = 'EDIT_TIME_REPORT_TAGS';
export const EDIT_COMPENSATION_REPORT_TAGS = 'EDIT_COMPENSATION_REPORT_TAGS';
export const EDIT_TIMEREPORTING_FILTERS = 'EDIT_TIMEREPORTING_FILTERS';

const toggleFilter = (name, value) => ({
    type: TOGGLE_DASHBOARD_FILTER,
    name,
    value,
});
const resetFilters = () => ({ type: RESET_DASHBOARD_FILTERS });
const toggleSort = value => ({ type: TOGGLE_SORT, value });
const showPrint = content => ({ type: SHOW_PRINT, content });
const showPrintFulfilled = () => ({ type: SHOW_PRINT_FULFILLED });

export const showModal = (modalType, props, header) => ({
    type: SHOW_MODAL,
    modalType,
    props,
    header
});
export const hideModal = () => showModal(null, null);

export const toggleSidebar = () => ({ type: TOGGLE_SIDEBAR });
export const toggleLanguage = language => ({ type: CHANGE_LANGUAGE, language });
export const toggleStartPage = startPage => ({ type: CHANGE_START_PAGE, startPage });
const toggleUI = ui => ({ type: CHANGE_UI, ui });

export const editPageDetails = (pageName, payload) => ({
    type: EDIT_PAGE_DETAILS,
    pageName,
    payload,
});

export const editTimereportingFilters = (name, value) => ({
    type: EDIT_TIMEREPORTING_FILTERS,
    name,
    value
});

export const resetDashboardFilters = () => async dispatch => {
    dispatch(resetFilters());
    dispatch(fetchWorkOrders());
};

export const toggleDashboardFilter = (name, value) => async dispatch => {
    dispatch(toggleFilter(name, value));
    dispatch(fetchWorkOrders());
};

export const toggleDashboardSort = value => async dispatch => {
    dispatch(toggleSort(value));
    dispatch(fetchWorkOrders());
};

export const initDashboardFilters = filters => ({
    type: INIT_DASHBOARD_FILTERS,
    filters,
});

export const initDashboardSort = value => ({
    type: INIT_SORT,
    value,
});

export const editTimeReportTags = tags => ({
    type: EDIT_TIME_REPORT_TAGS,
    tags,
});

export const editCompensationReportTags = tags => ({
    type: EDIT_COMPENSATION_REPORT_TAGS,
    tags,
});

export const changeUI = ui => {
    return async dispatch => {
        try {
            dispatch(toggleUI(ui));
            await patch('/users/conf', { ui });
        } catch (err) {
            //TODO show erorr
            console.error('Failed to toggle ui');
            console.error(err);
        }
    };
};


export const changeLanguage = language => {
    return async dispatch => {
        try {
            dispatch(toggleLanguage(language));
            await patch('/users/conf', { language });
            setUserUiSetting('lang', language);
        } catch (err) {
            //TODO show erorr
            console.error('Failed to change language');
            console.error(err);
        }
    };
};

export const changeStartPage = startPage => {
    return async dispatch => {
        try {
            await patch('/users/conf', { startPage });
            dispatch(toggleStartPage(startPage));
        } catch (err) {
            //TODO show erorr
            console.error('Failed to change start page');
            console.error(err);
        }
    };
};

export const openPrint = content => async dispatch => {
    dispatch(showPrint(content));

    setTimeout(() => {
        dispatch(showPrintFulfilled());
        window.print();
    }, 500);
};
