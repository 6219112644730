import BottomSheet from 'components/common/bottom-sheet';
import EditableDropdown from 'components/common/editable-dropdown';
import SearchableDropdown from 'components/common/searchable-dropdown';
import { useInstanceData } from 'components/common/use-instance-data';
import customerService from 'data/services/customer/customerService';
import salesOrderService from 'data/services/salesorder/salesOrderService';
import workOrderService from 'data/services/workorder/workOrderService';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { Form, Popup } from 'semantic-ui-react';
import { mapCustomerContactToOption, mapSimpleValueToOption } from 'utils/DropdownMappers';
import { fieldTypes } from 'utils/constants/fieldTypes';
import { showConfirm } from '../../../../data/actions/common/confirmActions';
import {
    showErrorNotification,
    showSuccessNotification
} from '../../../../data/actions/common/notificationActions';
import {
    proposalChanged,
    proposalClosed,
    proposalCreated,
    proposalDeleted
} from '../../../../data/actions/proposals';
import {
    openWorkOrderTab,
    requestCreateWorkOrderFulfilled
} from '../../../../data/actions/workorders';
import proposalService from '../../../../data/services/proposal/proposalService';
import {
    formatDateTimeVerbose,
    formatDaysTo
} from '../../../../utils/DateUtils';
import { calculateDiscountedPrice } from '../../../../utils/MathUtils';
import {
    PROPOSAL_STATUS_ACCEPTED,
    PROPOSAL_STATUS_ACCEPTED_WORK_ORDER,
    PROPOSAL_STATUS_ACTIVE,
    PROPOSAL_STATUS_DECLINED
} from '../../../../utils/constants';
import { appModules } from '../../../../utils/constants/appModules';
import { permission } from '../../../../utils/constants/permissions';
import Alert from '../../../common/alert';
import Container from '../../../common/container';
import DatePicker from '../../../common/date-picker';
import Divider from '../../../common/divider';
import EditableText from '../../../common/editable-text';
import FlatButton from '../../../common/flat-button';
import Icon from '../../../common/icon';
import LineLoader from '../../../common/line-loader';
import Loader from '../../../common/loader';
import Padding from '../../../common/padding';
import SecondaryMenuContainer from '../../../common/secondary-menu-container';
import TagPicker from '../../../common/tag-picker/TagPicker';
import {
    Col,
    Label,
    Row,
    Section,
    SectionBody,
    SectionHeader,
    SectionValueContainer,
    Value
} from '../../../common/ui';
import { withPermissions } from '../../../common/with-permissions/WithPermissions';
import CustomerPicker from '../../common/CustomerPicker';
import Breadcrumbs, { Breadcrumb } from '../../inventory/breadcrumbs';
import BoPicker from '../../workorder/form/BoPicker';
import './ProposalDetailsContainer.css';
import ProposalActions from './actions';
import ProposalArticlesContainer from './articles';
import ProposalDetailsCustomBSP from './custom/ProposalDetailsCustomBSP';
import ProposalDetailsCustomEdsvikenslarm from './custom/ProposalDetailsCustomEdsvikenslarm';
import ProposalDetailsCustomFCLarm from './custom/ProposalDetailsCustomFCLarm';
import ProposalFiles from './files';
import ProposalDetailsCustomLansalarm from './custom/ProposalDetailsCustomLansalarm';

const breadcrumbs = (t, nr) => [
    new Breadcrumb(t('PROPOSALS'), '/proposals'),
    new Breadcrumb(nr, null),
];

const StandardTextsSelection = ({ standardTexts, onSelect }) => (
    <div className="ui relaxed selection list man-proposal-texts-selection">
        {standardTexts &&
            standardTexts.map((text, idx) => (
                <div
                    key={`item-${idx}`}
                    className="item man-ui-wrap-newline"
                    onClick={() => onSelect(text)}>
                    {text}
                </div>
            ))}
    </div>
);

const StandardTexts = ({
    t,
    label,
    open,
    onOpen,
    onClose,
    onSelect,
    editable,
    standardTexts,
    isSmallScreen,
    key,
}) => {
    if (!editable) {
        return <div>{label}</div>;
    }

    if (isSmallScreen) {
        return (
            <>
                <a onClick={onOpen}>
                    {label} <Icon name="chevron down" />
                </a>
                <BottomSheet key={key} open={open} onChange={onClose}>
                    <StandardTextsSelection
                        standardTexts={standardTexts}
                        onSelect={text => onSelect(text)}
                    />
                </BottomSheet>
            </>
        );
    }

    return (
        <Popup
            className="man-full-popup"
            open={open}
            on="click"
            trigger={
                <a onClick={onOpen}>
                    {label} <Icon name="chevron down" />
                </a>
            }>
            <StandardTextsSelection
                standardTexts={standardTexts}
                onSelect={text => onSelect(text)}
            />

            <FlatButton
                onClick={e => {
                    e.stopPropagation();
                    onClose();
                }}
                className="man-noprint"
                position="top right"
                icon="remove">
                {t('CLOSE')}
            </FlatButton>
        </Popup>
    );
};

const ProposalForm = ({
    t,
    tags,
    configuration,
    standardTexts,
    standardTextsInclusions,
    standardTextsExceptions,
    standardTextsPaymentTerms,
    standardTextsGeneralTerms,
    usersById,
    hasPermission,
    loading,
    applying,
    copying,
    error,
    proposal,
    workOrder,
    salesOrder,
    saveState,
    handleClearCustomer,
    handlePdf,
    handleMakeCopy,
    handleDecline,
    handleCustomerChange,
    handleAccept,
    handleWordExport,
    handleValueChange,
    handleDelete,
    handleOpenWorkOrder,
    handleOpenSalesOrder,
    handleReopen,
    closed,
    navigateTo,
    handleBusinessObjectChange,
    standardTextsOpen,
    setStandardTextsOpen,
    closing,
    handleAcceptWorkOrder,
    onRowsChange,
    onContactChange,
    contacts,
    filesEnabled,
    smallScreen,
    summaryRowsEnabled,
    monthlyPriceEnabled,
}) => {

    const customFieldsData = proposal?.customFields || {};

    const onCustomFieldsChange = (name, value) => {
        const newData = {
            ...customFieldsData,
            [name]: value,
        };

        handleValueChange('customFields', newData);
    };

    const renderField = f => {
        console.log(f)

        if (!closed) {
            if (f.type === fieldTypes.DROPDOWN) {
                return (
                    <EditableDropdown
                        selection
                        fluid
                        text={customFieldsData[f.name] || f.placeholder || '...'}
                        options={f.options.map(mapSimpleValueToOption)}
                        value={customFieldsData[f.name] || ''}
                        name={f.name}
                        onSave={(n, value) => onCustomFieldsChange(f.name, value)}
                    />
                )
            }

            if (f.type === fieldTypes.TEXTAREA) {
                return (
                    <TextareaAutosize
                        name={f.name}
                        value={
                            customFieldsData[f.name] || ''
                        }
                        onChange={e => onCustomFieldsChange(e.target.name, e.target.value)}
                    />
                );
            }

            return (
                <EditableText
                    fluid
                    name={
                        f.name
                    }
                    value={
                        customFieldsData[
                        f
                            .name
                        ]
                    }
                    onValueChange={
                        onCustomFieldsChange
                    }
                />
            );
        }

        return `${customFieldsData[f.name] ? customFieldsData[f.name] : '-'}`;
    };

    return (
        <Form
            className="man-proposal-container"
            style={{
                opacity: loading || applying || copying ? 0.5 : 1,
            }}>
            {copying && <Loader text={t('ui:MAKING_COPY_PLEASE_WAIT')} />}
            {applying && <Loader />}
            {error ? (
                <div className="man-error-container">
                    <div className="man-error">{t('form:SERVER_ERROR')}</div>
                </div>
            ) : (
                <React.Fragment>
                    <div>
                        <ProposalActions
                            proposal={proposal}
                            saveState={saveState}
                            closed={closed}
                            onClearCustomer={handleClearCustomer}
                            onPdf={handlePdf}
                            onWordExport={handleWordExport}
                            onMakeCopy={handleMakeCopy}
                            onDelete={handleDelete}
                            onReopen={handleReopen}
                        />
                    </div>
                    <Section>
                        <SectionHeader>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}>
                                <div>
                                    {t('form:PROPOSAL')}
                                    {!closed && (
                                        <span
                                            className="man-secondary-text"
                                            style={{
                                                marginLeft: 20,
                                            }}>
                                            {saveState === 'ok' &&
                                                t('ui:ALL_CHANGES_SAVED')}
                                            {saveState === 'saving' &&
                                                t('ui:SAVING')}
                                        </span>
                                    )}
                                </div>

                                {proposal && (
                                    <div className="man-secondary-text">
                                        {usersById[proposal.createUserId] ? (
                                            <Popup
                                                trigger={
                                                    <span>
                                                        {t(
                                                            'ui:CREATED_AT_BY_USER',
                                                            {
                                                                datetime:
                                                                    formatDateTimeVerbose(
                                                                        proposal.createTs
                                                                    ),
                                                                user:
                                                                    usersById[
                                                                    proposal
                                                                        .createUserId
                                                                    ] &&
                                                                    usersById[
                                                                        proposal
                                                                            .createUserId
                                                                    ].initials,
                                                            }
                                                        )}
                                                    </span>
                                                }>
                                                {usersById[
                                                    proposal.createUserId
                                                ] &&
                                                    usersById[
                                                        proposal.createUserId
                                                    ].fullName}
                                            </Popup>
                                        ) : (
                                            <span>
                                                {t('ui:CREATED_AT', {
                                                    datetime:
                                                        formatDateTimeVerbose(
                                                            proposal.createTs
                                                        ),
                                                })}
                                            </span>
                                        )}
                                    </div>
                                )}
                            </div>
                        </SectionHeader>
                        <SectionBody>
                            <Row>
                                <Col>
                                    <SectionValueContainer>
                                        <Label>
                                            {t('form:PROPOSAL_TITLE')}
                                        </Label>
                                        <Value>
                                            {loading ? (
                                                <LineLoader />
                                            ) : (
                                                proposal.title
                                            )}
                                        </Value>
                                    </SectionValueContainer>
                                    {workOrder && (
                                        <SectionValueContainer>
                                            <Label>
                                                {t(
                                                    'form:PROPOSAL_CREATED_WORK_ORDER'
                                                )}
                                            </Label>
                                            <Value>
                                                {loading ? (
                                                    <LineLoader />
                                                ) : (
                                                    <a
                                                        className="man-internal-link"
                                                        onClick={
                                                            handleOpenWorkOrder
                                                        }>
                                                        {workOrder.title}
                                                    </a>
                                                )}
                                            </Value>
                                        </SectionValueContainer>
                                    )}
                                    {salesOrder && (
                                        <SectionValueContainer>
                                            <Label>
                                                {t(
                                                    'form:PROPOSAL_CREATED_SALES_ORDER'
                                                )}
                                            </Label>
                                            <Value>
                                                {loading ? (
                                                    <LineLoader />
                                                ) : (
                                                    <a
                                                        className="man-internal-link"
                                                        onClick={
                                                            handleOpenSalesOrder
                                                        }>
                                                        {salesOrder.title}
                                                    </a>
                                                )}
                                            </Value>
                                        </SectionValueContainer>
                                    )}
                                </Col>
                                {configuration?.custom?.proposal?.validToEnabled && (
                                    <Col>
                                        {!closed && (
                                            <SectionValueContainer>
                                                <Label>
                                                    {t(
                                                        'form:PROPOSAL_VALID_TO'
                                                    )}
                                                </Label>
                                                <Value>
                                                    {loading ? (
                                                        <LineLoader />
                                                    ) : (
                                                        <DatePicker
                                                            placeholder={t(
                                                                'ui:SELECT_DATE'
                                                            )}
                                                            name="date"
                                                            onDatesChange={(
                                                                name,
                                                                value
                                                            ) =>
                                                                handleValueChange(
                                                                    'validTo',
                                                                    value
                                                                )
                                                            }
                                                            value={[
                                                                proposal.validTo,
                                                            ]}
                                                        />
                                                    )}
                                                </Value>
                                            </SectionValueContainer>
                                        )}
                                    </Col>
                                )}

                                <Col>
                                    {!closed && (
                                        <SectionValueContainer>
                                            <Label>
                                                {t(
                                                    'form:PROPOSAL_FOLLOW_UP_DATE'
                                                )}
                                            </Label>
                                            <Value>
                                                {loading ? (
                                                    <LineLoader />
                                                ) : (
                                                    <React.Fragment>
                                                        <DatePicker
                                                            placeholder={t(
                                                                'ui:SELECT_DATE'
                                                            )}
                                                            name="date"
                                                            onDatesChange={(
                                                                name,
                                                                value
                                                            ) =>
                                                                handleValueChange(
                                                                    'nextActionDate',
                                                                    value
                                                                )
                                                            }
                                                            value={[
                                                                proposal.nextActionDate,
                                                            ]}
                                                        />
                                                        {proposal.nextActionDate &&
                                                            formatDaysTo(
                                                                proposal.nextActionDate,
                                                                t,
                                                                true
                                                            )}
                                                    </React.Fragment>
                                                )}
                                            </Value>
                                        </SectionValueContainer>
                                    )}
                                </Col>
                                <Col>
                                    <SectionValueContainer>
                                        <Label>{t('form:TAGS')}</Label>
                                        <Value>
                                            {loading ? (
                                                <LineLoader />
                                            ) : (
                                                <TagPicker
                                                    tags={tags}
                                                    onTagsChange={value =>
                                                        handleValueChange(
                                                            'tags',
                                                            value
                                                        )
                                                    }
                                                    isEditable={!closed}
                                                    value={proposal.tags || []}
                                                />
                                            )}
                                        </Value>
                                    </SectionValueContainer>
                                </Col>
                                {!configuration?.custom?.proposal?.validToEnabled &&
                                    <Col></Col>
                                }
                            </Row>

                            <Divider />

                            <Row>
                                <Col>
                                    <SectionValueContainer>
                                        <Label>{t('form:CUSTOMER')}</Label>
                                        <Value>
                                            {loading ? (
                                                <LineLoader />
                                            ) : (
                                                <React.Fragment>
                                                    <a
                                                        className="man-internal-link"
                                                        onClick={() =>
                                                            navigateTo(
                                                                `/inventory/customers/${proposal.customerId}`
                                                            )
                                                        }>
                                                        {`${[
                                                            proposal.customerName,
                                                            proposal.customerNumber,
                                                        ]
                                                            .filter(v => v)
                                                            .join(',')}`}
                                                    </a>

                                                    {!proposal.customerId &&
                                                        !closed && (
                                                            <CustomerPicker
                                                                onCustomerChange={
                                                                    handleCustomerChange
                                                                }
                                                            />
                                                        )}
                                                </React.Fragment>
                                            )}
                                        </Value>
                                    </SectionValueContainer>
                                    {configuration?.custom?.proposal?.customFields
                                        ?.filter(
                                            f =>
                                                f.sortOrder >= 10 &&
                                                f.sortOrder < 13
                                        )
                                        .map(f => (
                                            <SectionValueContainer>
                                                <Label>{f.label}</Label>
                                                <Value>
                                                    {loading ? (
                                                        <LineLoader />
                                                    ) : (
                                                        <React.Fragment>
                                                            {renderField(f)}
                                                        </React.Fragment>
                                                    )}
                                                </Value>
                                            </SectionValueContainer>
                                        ))}
                                </Col>

                                <Col>
                                    <SectionValueContainer>
                                        <Label>
                                            {!closed &&
                                                contacts &&
                                                contacts.length > 0 ? (
                                                <SearchableDropdown
                                                    searchEnabled={
                                                        contacts ||
                                                        [].length > 10
                                                    }
                                                    selectOnBlur={false}
                                                    selectOnNavigation={false}
                                                    name="contactName"
                                                    text={`${t(
                                                        'form:CONTACT'
                                                    )}:`}
                                                    options={
                                                        contacts
                                                            ? contacts.map(
                                                                mapCustomerContactToOption
                                                            )
                                                            : []
                                                    }
                                                    value={null}
                                                    onChange={value =>
                                                        onContactChange(value)
                                                    }
                                                />
                                            ) : (
                                                `${t('form:CONTACT')}:`
                                            )}
                                        </Label>
                                        <Value>
                                            {loading ? (
                                                <LineLoader />
                                            ) : (
                                                <div>
                                                    <div>
                                                        {closed ? (
                                                            proposal.contactName
                                                        ) : (
                                                            <EditableText
                                                                fluid
                                                                name="contactName"
                                                                value={
                                                                    proposal.contactName
                                                                }
                                                                onValueChange={
                                                                    handleValueChange
                                                                }
                                                                placeholder={t(
                                                                    'form:CONTACT_NAME_PLACEHOLDER'
                                                                )}
                                                            />
                                                        )}
                                                    </div>

                                                    <div>
                                                        {closed ? (
                                                            proposal.contactEmail
                                                        ) : (
                                                            <EditableText
                                                                fluid
                                                                name="contactEmail"
                                                                value={
                                                                    proposal.contactEmail
                                                                }
                                                                onValueChange={
                                                                    handleValueChange
                                                                }
                                                                placeholder={t(
                                                                    'form:CONTACT_EMAIL_PLACEHOLDER'
                                                                )}
                                                            />
                                                        )}
                                                    </div>

                                                    <div>
                                                        {closed ? (
                                                            proposal.contactPhone
                                                        ) : (
                                                            <EditableText
                                                                fluid
                                                                name="contactPhone"
                                                                value={
                                                                    proposal.contactPhone
                                                                }
                                                                onValueChange={
                                                                    handleValueChange
                                                                }
                                                                placeholder={t(
                                                                    'form:CONTACT_PHONE_PLACEHOLDER'
                                                                )}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </Value>
                                    </SectionValueContainer>
                                </Col>

                                {configuration?.custom?.proposal
                                    ?.deliveryAddressEnabled && (
                                        <Col>
                                            <SectionValueContainer>
                                                <Label>
                                                    {t(
                                                        'form:PROPOSAL_DELIVERY_ADDRESS'
                                                    )}
                                                </Label>
                                                <Value>
                                                    {loading ? (
                                                        <LineLoader />
                                                    ) : (
                                                        <div>
                                                            <div>
                                                                {closed ? (
                                                                    proposal.deliveryLocationDescription
                                                                ) : (
                                                                    <EditableText
                                                                        fluid
                                                                        name="deliveryLocationDescription"
                                                                        value={
                                                                            proposal.deliveryLocationDescription
                                                                        }
                                                                        onValueChange={
                                                                            handleValueChange
                                                                        }
                                                                        placeholder={t(
                                                                            'form:LOCATION_DESCRIPTION'
                                                                        )}
                                                                    />
                                                                )}
                                                            </div>

                                                            <div>
                                                                {closed ? (
                                                                    proposal.deliveryLocationText2
                                                                ) : (
                                                                    <EditableText
                                                                        fluid
                                                                        name="deliveryLocationText2"
                                                                        value={
                                                                            proposal.deliveryLocationText2
                                                                        }
                                                                        onValueChange={
                                                                            handleValueChange
                                                                        }
                                                                        placeholder={t(
                                                                            'form:LOCATION_2'
                                                                        )}
                                                                    />
                                                                )}
                                                            </div>

                                                            <div className="man-flex-row">
                                                                <div>
                                                                    {closed ? (
                                                                        proposal.deliveryLocationText3
                                                                    ) : (
                                                                        <EditableText
                                                                            fluid
                                                                            name="deliveryLocationText3"
                                                                            value={
                                                                                proposal.deliveryLocationText3
                                                                            }
                                                                            onValueChange={
                                                                                handleValueChange
                                                                            }
                                                                            placeholder={t(
                                                                                'form:LOCATION_3'
                                                                            )}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        marginLeft: 4,
                                                                    }}>
                                                                    {closed ? (
                                                                        proposal.deliveryLocationText
                                                                    ) : (
                                                                        <EditableText
                                                                            fluid
                                                                            name="deliveryLocationText"
                                                                            value={
                                                                                proposal.deliveryLocationText
                                                                            }
                                                                            onValueChange={
                                                                                handleValueChange
                                                                            }
                                                                            placeholder={t(
                                                                                'form:LOCATION'
                                                                            )}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Value>
                                            </SectionValueContainer>
                                        </Col>
                                    )}

                                <Col>
                                    {proposal && proposal.customerId && (
                                        <SectionValueContainer>
                                            <Label>{`${t(
                                                'form:OBJECT'
                                            )}:`}</Label>
                                            <Value>
                                                {proposal.businessObjectId ? (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            justifyContent:
                                                                'space-between',
                                                        }}>
                                                        <a
                                                            className="man-internal-link"
                                                            onClick={() =>
                                                                navigateTo(
                                                                    `/inventory/objects/${proposal.businessObjectId}`
                                                                )
                                                            }>
                                                            {
                                                                proposal.businessObjectSerialCode
                                                            }
                                                            ,{' '}
                                                            {
                                                                proposal.businessObjectModelName
                                                            }
                                                        </a>
                                                        {!closed && (
                                                            <FlatButton
                                                                icon="remove"
                                                                title={t(
                                                                    'ui:REMOVE'
                                                                )}
                                                                style={{
                                                                    padding: 4,
                                                                }}
                                                                onClick={() =>
                                                                    handleBusinessObjectChange(
                                                                        null
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                ) : (
                                                    <React.Fragment>
                                                        {!closed && (
                                                            <BoPicker
                                                                onObjectChange={
                                                                    handleBusinessObjectChange
                                                                }
                                                                customerId={
                                                                    proposal.customerId
                                                                }
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </Value>
                                        </SectionValueContainer>
                                    )}
                                </Col>

                                {!configuration?.custom?.proposal
                                    ?.deliveryAddressEnabled && <Col></Col>}
                            </Row>

                            <Divider />

                            <Row>
                                <Col>
                                    <SectionValueContainer>
                                        <Label>
                                            {t('form:PROPOSAL_INTRO')}
                                        </Label>
                                        <Value>
                                            {closed ? (
                                                <div className="man-ui-wrap-newline">
                                                    {proposal &&
                                                        proposal.description}
                                                </div>
                                            ) : (
                                                <TextareaAutosize
                                                    rows={1}
                                                    name="description"
                                                    value={
                                                        (proposal &&
                                                            proposal.description) ||
                                                        ''
                                                    }
                                                    onChange={e =>
                                                        handleValueChange(
                                                            e.target.name,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            )}
                                        </Value>
                                    </SectionValueContainer>
                                </Col>
                            </Row>


                            {configuration?.custom?.proposal?.introTextEnabled && (
                                <Row>
                                    <Col>
                                        <SectionValueContainer>
                                            <Label>
                                                {t('form:PROPOSAL_INTRO_TEXT')}
                                            </Label>
                                            <Value>
                                                {closed ? (
                                                    <div className="man-ui-wrap-newline">
                                                        {proposal &&
                                                            proposal.descriptionIntro}
                                                    </div>
                                                ) : (
                                                    <TextareaAutosize
                                                        name="descriptionIntro"
                                                        value={
                                                            (proposal &&
                                                                proposal.descriptionIntro) ||
                                                            ''
                                                        }
                                                        onChange={e =>
                                                            handleValueChange(
                                                                e.target.name,
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                )}
                                            </Value>
                                        </SectionValueContainer>
                                    </Col>
                                </Row>
                            )}

                            <Row>
                                <Col>
                                    <SectionValueContainer>
                                        <Label>
                                            <StandardTexts
                                                key={
                                                    'standardtexts-description'
                                                }
                                                editable={!closed}
                                                onOpen={() =>
                                                    setStandardTextsOpen('texts')
                                                }
                                                label={t('form:DESCRIPTION')}
                                                onClose={() =>
                                                    setStandardTextsOpen(false)
                                                }
                                                onSelect={t =>
                                                    handleValueChange(
                                                        'description2',
                                                        `${proposal.description2
                                                            ? `${proposal.description2}\n`
                                                            : ''
                                                        }${t}`
                                                    )
                                                }
                                                open={standardTextsOpen === 'texts'}
                                                standardTexts={standardTexts}
                                                t={t}
                                                isSmallScreen={smallScreen}
                                            />
                                        </Label>
                                        <Value>
                                            {closed ? (
                                                <div className="man-ui-wrap-newline">
                                                    {proposal &&
                                                        proposal.description2}
                                                </div>
                                            ) : (
                                                <TextareaAutosize
                                                    name="description2"
                                                    value={
                                                        (proposal &&
                                                            proposal.description2) ||
                                                        ''
                                                    }
                                                    onChange={e =>
                                                        handleValueChange(
                                                            e.target.name,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            )}
                                        </Value>
                                    </SectionValueContainer>
                                </Col>
                            </Row>

                            {configuration?.custom?.proposal?.customFields
                                ?.filter(
                                    f =>
                                        f.sortOrder >= 15 &&
                                        f.sortOrder < 18
                                )
                                .map(f => (
                                    <Row key={f.name}>
                                        <Col>
                                            <SectionValueContainer>
                                                <Label>
                                                    {/* TODO migrate to proposal.inclusions */}
                                                    {f.name === 'proposalInclusions' &&
                                                        <StandardTexts
                                                            key={
                                                                'standardtexts-inclusions'
                                                            }
                                                            editable={!closed}
                                                            onOpen={() =>
                                                                setStandardTextsOpen('textsInclusions')
                                                            }
                                                            label={f.label}
                                                            onClose={() =>
                                                                setStandardTextsOpen(false)
                                                            }
                                                            onSelect={t =>
                                                                onCustomFieldsChange(
                                                                    f.name,
                                                                    `${customFieldsData[f.name]
                                                                        ? `${customFieldsData[f.name]}\n`
                                                                        : ''
                                                                    }${t}`
                                                                )
                                                            }
                                                            open={standardTextsOpen === 'textsInclusions'}
                                                            standardTexts={standardTextsInclusions}
                                                            t={t}
                                                            isSmallScreen={smallScreen}
                                                        />
                                                    }

                                                    {f.name !== 'proposalInclusions' && f.label}

                                                </Label>
                                                <Value>
                                                    {loading ? (
                                                        <LineLoader />
                                                    ) : (
                                                        <React.Fragment>
                                                            {renderField(f)}
                                                        </React.Fragment>
                                                    )}
                                                </Value>
                                            </SectionValueContainer>
                                        </Col>
                                    </Row>
                                ))}

                            {configuration?.custom?.proposal
                                ?.hideDescriptionOfExceptions !== true && (
                                    <Row>
                                        <Col>
                                            <SectionValueContainer>
                                                <Label>
                                                    <StandardTexts
                                                        key={
                                                            'standardtexts-exceptions'
                                                        }
                                                        editable={!closed}
                                                        onOpen={() =>
                                                            setStandardTextsOpen('textsExceptions')
                                                        }
                                                        label={t('form:PROPOSAL_DESCRIPTION_OF_EXCEPTIONS')}
                                                        onClose={() =>
                                                            setStandardTextsOpen(false)
                                                        }
                                                        onSelect={t =>
                                                            handleValueChange(
                                                                'descriptionOfExceptions',
                                                                `${proposal.descriptionOfExceptions
                                                                    ? `${proposal.descriptionOfExceptions}\n`
                                                                    : ''
                                                                }${t}`
                                                            )
                                                        }
                                                        open={standardTextsOpen === 'textsExceptions'}
                                                        standardTexts={standardTextsExceptions}
                                                        t={t}
                                                        isSmallScreen={smallScreen}
                                                    />
                                                </Label>
                                                <Value>
                                                    {closed ? (
                                                        <div className="man-ui-wrap-newline">
                                                            {proposal &&
                                                                proposal.descriptionOfExceptions}
                                                        </div>
                                                    ) : (
                                                        <TextareaAutosize
                                                            name="descriptionOfExceptions"
                                                            value={
                                                                (proposal &&
                                                                    proposal.descriptionOfExceptions) ||
                                                                ''
                                                            }
                                                            onChange={e =>
                                                                handleValueChange(
                                                                    e.target.name,
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Value>
                                            </SectionValueContainer>
                                        </Col>
                                    </Row>
                                )}

                            {configuration?.custom?.proposal
                                ?.generalTermsEnabled && (
                                    <Row>
                                        <Col>
                                            <SectionValueContainer>
                                                <Label>
                                                    <StandardTexts
                                                        key={
                                                            'standardtexts-generalterms'
                                                        }
                                                        editable={!closed}
                                                        onOpen={() =>
                                                            setStandardTextsOpen('textsGeneralTerms')
                                                        }
                                                        label={t('form:PROPOSAL_GENERAL_TERMS')}
                                                        onClose={() =>
                                                            setStandardTextsOpen(false)
                                                        }
                                                        onSelect={t =>
                                                            handleValueChange(
                                                                'generalTerms',
                                                                `${proposal.generalTerms
                                                                    ? `${proposal.generalTerms}\n`
                                                                    : ''
                                                                }${t}`
                                                            )
                                                        }
                                                        open={standardTextsOpen === 'textsGeneralTerms'}
                                                        standardTexts={standardTextsGeneralTerms}
                                                        t={t}
                                                        isSmallScreen={smallScreen}
                                                    />
                                                </Label>
                                                <Value>
                                                    {/* <RichTextEditor value={proposal.generalTerms}
                                                editable={!closed}
                                                onChange={val => handleValueChange('generalTerms', val)} /> */}
                                                    {closed ? (
                                                        <div className="man-ui-wrap-newline">
                                                            {proposal &&
                                                                proposal.generalTerms}
                                                        </div>
                                                    ) : (
                                                        <TextareaAutosize
                                                            name="generalTerms"
                                                            value={
                                                                (proposal &&
                                                                    proposal.generalTerms) ||
                                                                ''
                                                            }
                                                            onChange={e =>
                                                                handleValueChange(
                                                                    e.target.name,
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Value>
                                            </SectionValueContainer>
                                        </Col>
                                    </Row>
                                )}

                            {configuration?.custom?.proposal
                                ?.paymentTermsEnabled && (
                                    <Row>
                                        <Col>
                                            <SectionValueContainer>
                                                <Label>
                                                    <StandardTexts
                                                        key={
                                                            'standardtexts-paymentterms'
                                                        }
                                                        editable={!closed}
                                                        onOpen={() =>
                                                            setStandardTextsOpen('textsPaymentTerms')
                                                        }
                                                        label={t('form:PROPOSAL_PAYMENT_TERMS')}
                                                        onClose={() =>
                                                            setStandardTextsOpen(false)
                                                        }
                                                        onSelect={t =>
                                                            handleValueChange(
                                                                'paymentTerms',
                                                                `${proposal.paymentTerms
                                                                    ? `${proposal.paymentTerms}\n`
                                                                    : ''
                                                                }${t}`
                                                            )
                                                        }
                                                        open={standardTextsOpen === 'textsPaymentTerms'}
                                                        standardTexts={standardTextsPaymentTerms}
                                                        t={t}
                                                        isSmallScreen={smallScreen}
                                                    />
                                                </Label>
                                                <Value>
                                                    {closed ? (
                                                        <div className="man-ui-wrap-newline">
                                                            {proposal &&
                                                                proposal.paymentTerms}
                                                        </div>
                                                    ) : (
                                                        <TextareaAutosize
                                                            rows={1}
                                                            name="paymentTerms"
                                                            value={
                                                                (proposal &&
                                                                    proposal.paymentTerms) ||
                                                                ''
                                                            }
                                                            onChange={e =>
                                                                handleValueChange(
                                                                    e.target.name,
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Value>
                                            </SectionValueContainer>
                                        </Col>
                                    </Row>
                                )}

                            {configuration?.custom?.proposal?.fields && (
                                <Row>
                                    <Col>
                                        <SectionValueContainer>
                                            {configuration?.custom?.proposal?.fields
                                                .filter(
                                                    f =>
                                                        f.sortOrder >= 20 &&
                                                        f.sortOrder < 30
                                                )
                                                .map(f => (
                                                    <React.Fragment
                                                        key={f.name}>
                                                        {f.labelPosition !==
                                                            'right' && (
                                                                <Label
                                                                    inline
                                                                    style={{
                                                                        fontWeight: 700,
                                                                    }}>
                                                                    {f.label}
                                                                </Label>
                                                            )}
                                                        <Value inline>
                                                            {loading ? (
                                                                <LineLoader />
                                                            ) : (
                                                                <div>
                                                                    {!closed ? (
                                                                        <EditableText
                                                                            fluid
                                                                            type={
                                                                                f.type
                                                                            }
                                                                            name={
                                                                                f.name
                                                                            }
                                                                            value={
                                                                                proposal[
                                                                                f
                                                                                    .name
                                                                                ] ||
                                                                                ''
                                                                            }
                                                                            onValueChange={
                                                                                handleValueChange
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        proposal[
                                                                        f
                                                                            .name
                                                                        ] || ''
                                                                    )}
                                                                </div>
                                                            )}
                                                        </Value>
                                                        {f.labelPosition ===
                                                            'right' && (
                                                                <Label
                                                                    inline
                                                                    style={{
                                                                        fontWeight: 700,
                                                                    }}>
                                                                    {f.label}
                                                                </Label>
                                                            )}
                                                    </React.Fragment>
                                                ))}
                                        </SectionValueContainer>
                                    </Col>
                                </Row>
                            )}

                            {configuration?.custom?.proposal?.customFields && (
                                <Row>
                                    {configuration?.custom?.proposal?.customFields
                                        .filter(f => f.sortOrder === 31)
                                        .map(f => (
                                            <Col key={f.name}>
                                                <SectionValueContainer>
                                                    <Label
                                                        inline
                                                        style={{
                                                            fontWeight: 700,
                                                        }}>
                                                        {f.label}
                                                    </Label>
                                                    <Value inline>
                                                        {loading ? (
                                                            <LineLoader />
                                                        ) : (
                                                            <div style={{
                                                                width: '100%'
                                                            }}>
                                                                {renderField(f)}
                                                            </div>
                                                        )}
                                                    </Value>
                                                </SectionValueContainer>
                                            </Col>
                                        ))}
                                </Row>
                            )}

                            {configuration?.custom?.proposal?.customFields && (
                                <Row>
                                    {configuration?.custom?.proposal?.customFields
                                        .filter(f => f.sortOrder === 30)
                                        .map(f => (
                                            <Col key={f.name}>
                                                <SectionValueContainer>
                                                    <Label
                                                        inline
                                                        style={{
                                                            fontWeight: 700,
                                                        }}>
                                                        {f.label}
                                                    </Label>
                                                    <Value inline>
                                                        {loading ? (
                                                            <LineLoader />
                                                        ) : (
                                                            <div>
                                                                {!closed ? (
                                                                    <EditableText
                                                                        type={
                                                                            f.type
                                                                        }
                                                                        name={
                                                                            f.name
                                                                        }
                                                                        value={
                                                                            customFieldsData[
                                                                            f
                                                                                .name
                                                                            ] ||
                                                                            ''
                                                                        }
                                                                        onValueChange={
                                                                            onCustomFieldsChange
                                                                        }
                                                                    />
                                                                ) : (
                                                                    proposal[
                                                                    f.name
                                                                    ] || ''
                                                                )}
                                                                {f.postFix ||
                                                                    ''}
                                                            </div>
                                                        )}
                                                    </Value>
                                                </SectionValueContainer>
                                            </Col>
                                        ))}
                                </Row>
                            )}



                            <Divider />

                            {configuration?.custom?.proposal?.customDataTemplate === 'fclarm' &&
                                <ProposalDetailsCustomFCLarm
                                    proposal={proposal}
                                    data={proposal?.customData || {}}
                                    onDataChange={data => handleValueChange('customData', data)}
                                    editable={!closed} />
                            }

                            {configuration?.custom?.proposal?.customDataTemplate === 'brandskyddstockholm' &&
                                <ProposalDetailsCustomBSP
                                    isSmallScreen={smallScreen}
                                    proposal={proposal}
                                    data={proposal?.customData || {}}
                                    onDataChange={data => handleValueChange('customData', data)}
                                    editable={!closed} />
                            }

                            {configuration?.custom?.proposal?.customDataTemplate === 'edsvikenslarm' &&
                                <ProposalDetailsCustomEdsvikenslarm
                                    t={t}
                                    isSmallScreen={smallScreen}
                                    proposal={proposal}
                                    data={proposal?.customData || {}}
                                    onDataChange={data => handleValueChange('customData', data)}
                                    editable={!closed} />
                            }
                            
                            {configuration?.custom?.proposal?.customDataTemplate === 'lansalarm' &&
                                <ProposalDetailsCustomLansalarm
                                    t={t}
                                    isSmallScreen={smallScreen}
                                    proposal={proposal}
                                    data={proposal?.customData || {}}
                                    onDataChange={data => handleValueChange('customData', data)}
                                    editable={!closed} />
                            }

                            {loading ? (
                                <LineLoader lines={6} />
                            ) : (
                                <ProposalArticlesContainer
                                    editable={!closed}
                                    onChange={onRowsChange}
                                    customerId={proposal.customerId}
                                    rows={proposal.rows || []}
                                    canShowSupplierInventoryArticles={true}
                                    summaryRowsEnabled={summaryRowsEnabled}
                                    monthlyPriceEnabled={monthlyPriceEnabled}
                                />
                            )}
                        </SectionBody>
                    </Section>

                    {filesEnabled && !loading && (
                        <Section>
                            <SectionHeader>{t('form:FILES')}</SectionHeader>
                            <SectionBody>
                                <ProposalFiles
                                    id={proposal.id}
                                    editable={true}
                                />
                            </SectionBody>
                        </Section>
                    )}

                    {proposal && proposal.status === PROPOSAL_STATUS_ACTIVE && (
                        <Row
                            style={{
                                marginTop: 20,
                            }}>
                            <Col>
                                <FlatButton
                                    className="man-negative man-bordered"
                                    onClick={handleDecline}
                                    loading={
                                        closing === PROPOSAL_STATUS_DECLINED
                                    }
                                    icon="cancel">
                                    {t('PROPOSAL_DECLINE')}
                                </FlatButton>
                            </Col>
                            <Col></Col>
                            <Col>
                                {hasPermission(permission.SALES_ORDER_EDIT) && (
                                    <FlatButton
                                        className="man-positive man-bordered"
                                        onClick={handleAccept}
                                        position="top left"
                                        title={
                                            saveState === 'saving'
                                                ? t('ui:SAVING')
                                                : (proposal.customerId && proposal.customerNumber)
                                                    ? null
                                                    : t(
                                                        'messages:PROPOSAL_PLEASE_SELECT_CUSTOMER_BEFORE_ACCEPTING'
                                                    )
                                        }
                                        loading={
                                            closing === PROPOSAL_STATUS_ACCEPTED
                                        }
                                        icon="check">
                                        {t('PROPOSAL_ACCEPT')}
                                    </FlatButton>
                                )}

                                <FlatButton
                                    className="man-positive man-bordered"
                                    style={{
                                        marginTop: 10,
                                    }}
                                    onClick={handleAcceptWorkOrder}
                                    position="top left"
                                    title={
                                        saveState === 'saving'
                                            ? t('ui:SAVING')
                                            : null
                                    }
                                    loading={
                                        closing ===
                                        PROPOSAL_STATUS_ACCEPTED_WORK_ORDER
                                    }
                                    icon="check">
                                    {t(
                                        'PROPOSAL_ACCEPT_AND_CREATE_WORKORDER'
                                    )}
                                </FlatButton>

                            </Col>
                        </Row>
                    )}
                </React.Fragment>
            )}
            <div className="man-proposal-status-alert">
                {proposal &&
                    (proposal.status === PROPOSAL_STATUS_ACCEPTED ||
                        proposal.status ===
                        PROPOSAL_STATUS_ACCEPTED_WORK_ORDER) && (
                        <Alert
                            type="success"
                            message={`${t('PROPOSAL_IS_ACCEPTED_AT_BY_USER', {
                                datetime: formatDateTimeVerbose(
                                    proposal.closeTs
                                ),
                                user:
                                    usersById[proposal.closeUserId] &&
                                    usersById[proposal.closeUserId].fullName,
                            })}`}
                        />
                    )}
                {proposal && proposal.status === PROPOSAL_STATUS_DECLINED && (
                    <Alert
                        type="error"
                        message={`${t('PROPOSAL_IS_DECLINED_AT_BY_USER', {
                            datetime: formatDateTimeVerbose(proposal.closeTs),
                            user:
                                usersById[proposal.closeUserId] &&
                                usersById[proposal.closeUserId].fullName,
                        })}`}
                    />
                )}
            </div>
        </Form>
    );
};

const ProposalDetailsContainer = ({
    t,
    proposalId,
    nowrap,
    tags,
    match,
    confirm,
    history,
    dateFormat,
    configuration,
    showSuccessMessage,
    showErrorMessage,
    proposalChanged,
    standardTexts,
    standardTextsExceptions,
    standardTextsInclusions,
    standardTextsPaymentTerms,
    standardTextsGeneralTerms,
    usersById,
    proposalClosed,
    proposalCreated,
    proposalDeleted,
    userId,
    workOrderCreated,
    hasPermission,
    openWorkOrder,
    isSmallScreen,
}) => {
    const [loading, setLoading] = useState(true);
    const [closing, setClosing] = useState(null);
    const [copying, setCopying] = useState(false);
    const [customer, setCustomer] = useState({});
    const [workOrder, setWorkOrder] = useState(null);
    const [salesOrder, setSalesOrder] = useState(null);
    const [applying, setApplying] = useState(false);
    const [saveState, setSaveState] = useState('ok');
    const [proposal, setProposal] = useState(null);
    const [error, setError] = useState(null);
    const [standardTextsOpen, setStandardTextsOpen] = useState(false);

    const { hasAppModule } = useInstanceData();

    useEffect(() => {
        const fetchDetails = async id => {
            try {
                setLoading(true);

                const details = await proposalService.fetchDetails(id);

                if (!details.rows) {
                    details.rows = [];
                }

                setProposal(details);

                if (details.customerId) {
                    const c = await customerService.fetchDetails(
                        details.customerId
                    );

                    setCustomer(c);
                }

                if (details.status === PROPOSAL_STATUS_ACCEPTED_WORK_ORDER) {
                    const wo = await workOrderService.fetchByProposalId(id);

                    if (wo) {
                        setWorkOrder(wo);
                    }
                }

                if (details.status === PROPOSAL_STATUS_ACCEPTED) {
                    const order = await salesOrderService.fetchByProposalId(id);

                    if (order) {
                        setSalesOrder(order);
                    }
                }
            } catch (err) {
                console.error(err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchDetails(proposalId || match.params.id);
    }, [match.params.id, proposalId]);

    const navigateTo = href => history.push(href);

    const onRowsChange = rows => {
        const p = {
            ...proposal,
            rows,
        };

        handleProposalChanged(p);
    };

    const saveDebounced = useRef(
        _.debounce(proposal => {
            handleSave(proposal);
        }, 2500)
    ).current;

    const handleSave = async proposal => {
        try {
            await proposalService.update(proposal);

            if (proposal.rows) {
                const totalPurchasePrice = proposal.rows
                    .filter(({ articleId }) => articleId)
                    .map(({ articlePurchasePrice, articleAmount }) =>
                        calculateDiscountedPrice(
                            articlePurchasePrice,
                            articleAmount
                        )
                    )
                    .reduce((a, b) => a + b, 0);

                const totalPrice = proposal.rows
                    .filter(({ articleId }) => articleId)
                    .map(
                        ({
                            articlePrice,
                            articleAmount,
                            articleDiscountType,
                            articleDiscountValue,
                        }) =>
                            calculateDiscountedPrice(
                                articlePrice,
                                articleAmount,
                                articleDiscountType,
                                articleDiscountValue
                            )
                    )
                    .reduce((a, b) => a + b, 0);

                proposal.totalPrice = totalPrice;
                proposal.totalPurchasePrice = totalPurchasePrice;
            }

            proposalChanged(proposal);

            setSaveState('ok');
        } catch (err) {
            showErrorMessage('FAILED_TO_SAVE');
            console.error(err);
        }
    };

    const handleProposalChanged = p => {
        setProposal(p);
        setSaveState('saving');
        saveDebounced(p);
    };

    const handleDelete = async () => {
        confirm(
            'messages:ARE_YOU_SURE',
            async () => {
                try {
                    setLoading(true);
                    await proposalService.delete(proposal.id);

                    proposalDeleted(proposal.id);

                    showSuccessMessage('PROPOSAL_SUCCESSFULLY_DELETED');

                    navigateTo(`/proposals`);
                } catch (err) {
                    showErrorMessage('FAILED_TO_DELETE');
                } finally {
                    setLoading(null);
                }
            },
            null,
            () => setLoading(null),
            () => setLoading(null)
        );
    };

    const handleAccept = async () => {
        if (saveState === 'saving') {
            return;
        }

        if (!proposal.customerId || !proposal.customerNumber) {
            showErrorMessage('messages:CANNOT_CLOSE_PROPOSAL_SALES_ORDER_CUSTOMER_NUMBER_MISSING');
            return;
        }

        confirm(
            'messages:ARE_YOU_SURE',
            async () => {
                try {
                    setClosing(PROPOSAL_STATUS_ACCEPTED);

                    const newProposal = {
                        ...proposal,
                        status: PROPOSAL_STATUS_ACCEPTED,
                        closeTs: new Date().getTime(),
                        closeUserId: userId,
                    };

                    const id = await proposalService.close(
                        proposal.id,
                        PROPOSAL_STATUS_ACCEPTED,
                        false
                    );

                    setProposal(newProposal);
                    proposalClosed(newProposal.id);

                    showSuccessMessage('PROPOSAL_SUCCESSFULLY_ACCEPTED');

                    navigateTo(`/sales/${id}`);
                } catch (err) {
                    showErrorMessage('FAILED_TO_SAVE');
                } finally {
                    setClosing(null);
                }
            },
            null,
            () => setClosing(null),
            () => setClosing(null)
        );
    };

    const handleReopen = async () => {
        const body = (
            <div>
                <h5>{t('messages:PROPOSAL_REOPEN_ARE_YOU_SURE')}</h5>
                <ul>
                    <li>
                        {t('messages:PROPOSAL_REOPEN_WORKORDER_WILL_NOT_BE_DELETED')}
                    </li>
                    <li>
                        {t('messages:PROPOSAL_REOPEN_SALESORDER_WILL_NOT_BE_DELETED')}
                    </li>
                </ul>
            </div>
        );

        confirm(
            null,
            async () => {
                try {
                    setLoading(true);

                    const newProposal = {
                        ...proposal,
                        status: PROPOSAL_STATUS_ACTIVE,
                        closeTs: null,
                        closeUserId: null,
                    };

                    await proposalService.reopen(proposal.id);

                    setProposal(newProposal);

                    showSuccessMessage('Opened successfully');
                } catch (err) {
                    console.error(err);

                    showErrorNotification('FAILED_TO_SAVE');
                } finally {
                    setLoading(false);
                }
            },
            null,
            body
        );
    };

    const handleAcceptWorkOrder = async () => {
        if (saveState === 'saving') {
            return;
        }

        if (!proposal.customerId || !proposal.customerNumber) {
            showErrorMessage('messages:CANNOT_CLOSE_PROPOSAL_SALES_ORDER_CUSTOMER_NUMBER_MISSING');
            return;
        }

        if (!proposal.businessObjectId) {
            showErrorMessage('messages:CANNOT_CLOSE_PROPOSAL_WORKORDER_OBJECT_IS_MISSING');
            return;
        }

        confirm(
            'messages:ARE_YOU_SURE',
            async () => {
                try {
                    setClosing(PROPOSAL_STATUS_ACCEPTED_WORK_ORDER);

                    const newProposal = {
                        ...proposal,
                        status: PROPOSAL_STATUS_ACCEPTED_WORK_ORDER,
                        closeTs: new Date().getTime(),
                        closeUserId: userId,
                    };

                    const workOrder = await proposalService.closeWorkOrder(
                        proposal.id,
                        PROPOSAL_STATUS_ACCEPTED_WORK_ORDER,
                        configuration?.custom?.proposal?.ignoreArticlesForWorkOrder || false
                    );

                    setProposal(newProposal);
                    setWorkOrder(workOrder || {});
                    proposalClosed(newProposal.id);

                    showSuccessMessage(
                        'PROPOSAL_SUCCESSFULLY_ACCEPTED_WORKORDER_CREATED',
                        {
                            workOrderNr: workOrder.title,
                        }
                    );

                    const totalPrice = (proposal.rows || [])
                        .filter(({ articleId }) => articleId)
                        .map(
                            ({
                                articlePrice,
                                articleAmount,
                                articleDiscountType,
                                articleDiscountValue,
                            }) =>
                                calculateDiscountedPrice(
                                    articlePrice,
                                    articleAmount,
                                    articleDiscountType,
                                    articleDiscountValue
                                )
                        )
                        .reduce((a, b) => a + b, 0);

                    workOrderCreated({
                        ...workOrder,
                        totalPrice,
                        contacts: [],
                    });

                    // navigateTo(`/sales/${id}`); TODO navigate to workorder?
                } catch (err) {
                    showErrorMessage('FAILED_TO_SAVE');
                } finally {
                    setClosing(null);
                }
            },
            null,
            () => setClosing(null),
            () => setClosing(null)
        );
    };

    const handleDecline = async () => {
        confirm('messages:ARE_YOU_SURE', async () => {
            try {
                setClosing(PROPOSAL_STATUS_DECLINED);

                const newProposal = {
                    ...proposal,
                    status: PROPOSAL_STATUS_DECLINED,
                    closeTs: new Date().getTime(),
                    closeUserId: userId,
                };

                await proposalService.close(
                    proposal.id,
                    PROPOSAL_STATUS_DECLINED
                );

                setProposal(newProposal);
                proposalClosed(newProposal.id);
            } catch (err) {
                showErrorMessage('FAILED_TO_SAVE');
            } finally {
                setClosing(null);
            }
        });
    };

    const handleBusinessObjectChange = bo => {
        if (bo == null) {
            handleProposalChanged({
                ...proposal,
                businessObjectId: null,
                businessObjectModelName: null,
                businessObjectSerialCode: null,
            });

            return;
        }

        handleProposalChanged({
            ...proposal,
            businessObjectId: bo.id,
            businessObjectModelName: bo.modelName,
            businessObjectSerialCode: bo.serialCode,
        });
    };

    const handleValueChange = (name, value) => {
        const p = {
            ...proposal,
            [name]: value,
        };

        handleProposalChanged(p);
    };

    const handleCustomerChange = customer => {
        const p = {
            ...proposal,
            customerName: customer.displayName,
            customerId: customer.id,
            customerNumber: customer.customerId,
        };

        setCustomer(customer);
        handleProposalChanged(p);
    };

    const handleClearCustomer = () => {
        const p = {
            ...proposal,
            customerName: '',
            customerId: '',
            customerNumber: '',
            businessObjectId: '',
            businessObjectModelName: '',
            businessObjectSerialCode: '',
        };

        setCustomer(null);
        handleProposalChanged(p);
    };

    const handleWordExport = async templateId => {
        try {
            setApplying(true);
            await proposalService.exportWord(proposal, templateId);
        } catch (err) {
            console.log(err);

            showErrorMessage('FAILED_TO_DOWNLOAD_FILE');
        } finally {
            setApplying(false);
        }
    };

    const handlePdf = async (templateId, fileName) => {
        try {
            setApplying(true);
            await proposalService.pdf(proposal, templateId, fileName);
        } catch (err) {
            console.log(err);

            showErrorMessage('FAILED_TO_DOWNLOAD_FILE');
        } finally {
            setApplying(false);
        }
    };

    const handleMakeCopy = async () => {
        try {
            setCopying(true);

            const pr = await proposalService.create(proposal.customerId);

            proposalCreated({
                ...proposal,
                id: pr.id,
                title: pr.title,
                sortOrder: pr.sortOrder,
                sequenceNumber: pr.sequenceNumber,
            });

            await proposalService.update({
                ...proposal,
                id: pr.id,
                title: pr.title,
                sortOrder: pr.sortOrder,
                sequenceNumber: pr.sequenceNumber,
            });

            navigateTo(`/proposals/${pr.id}`);
        } catch (err) {
            console.log(err);
            showErrorMessage('FAILED_TO_SAVE');
        } finally {
            setCopying(false);
        }
    };

    const handleCustomerContactChange = value => {
        const contact = customer?.contacts.find(cc => cc.name === value);

        if (contact) {
            handleProposalChanged({
                ...proposal,
                contactEmail: contact.email,
                contactName: value,
                contactPhone: contact.phone,
            });

            return;
        }
    };

    const handleOpenWorkOrder = () => {
        if (workOrder) {
            openWorkOrder(workOrder.id, workOrder);
            navigateTo('/');
        }
    };

    const handleOpenSalesOrder = () => {
        if (salesOrder) {
            navigateTo(`/sales/${salesOrder.id}`);
        }
    };

    const closed = proposal && proposal.status !== PROPOSAL_STATUS_ACTIVE;

    const summaryRowsEnabled = configuration?.custom?.proposal?.summaryRowsEnabled || false;
    const monthlyPriceEnabled = configuration?.custom?.proposal?.monthlyPriceEnabled || false;

    const filesEnabled =
        hasAppModule(appModules.FILES) &&
        configuration?.custom?.filesEnabled &&
        hasPermission(permission.PROPOSAL_FILES);

    if (nowrap) {
        return (
            <ProposalForm
                t={t}
                tags={tags}
                configuration={configuration}
                standardTexts={standardTexts}
                standardTextsExceptions={standardTextsExceptions}
                standardTextsInclusions={standardTextsInclusions}
                standardTextsPaymentTerms={standardTextsPaymentTerms}
                standardTextsGeneralTerms={standardTextsGeneralTerms}
                usersById={usersById}
                hasPermission={hasPermission}
                loading={loading}
                applying={applying}
                copying={copying}
                error={error}
                proposal={proposal}
                saveState={saveState}
                workOrder={workOrder}
                salesOrder={salesOrder}
                handleOpenWorkOrder={handleOpenWorkOrder}
                handleOpenSalesOrder={handleOpenSalesOrder}
                handleValueChange={handleValueChange}
                navigateTo={navigateTo}
                handleClearCustomer={handleClearCustomer}
                handlePdf={handlePdf}
                handleMakeCopy={handleMakeCopy}
                handleDecline={handleDecline}
                handleWordExport={handleWordExport}
                handleCustomerChange={handleCustomerChange}
                handleAccept={handleAccept}
                handleDelete={handleDelete}
                handleReopen={handleReopen}
                handleBusinessObjectChange={handleBusinessObjectChange}
                standardTextsOpen={standardTextsOpen}
                setStandardTextsOpen={setStandardTextsOpen}
                closing={closing}
                closed={closed}
                handleAcceptWorkOrder={handleAcceptWorkOrder}
                onRowsChange={onRowsChange}
                onContactChange={handleCustomerContactChange}
                contacts={customer?.contacts}
                filesEnabled={filesEnabled}
                smallScreen={isSmallScreen}
                summaryRowsEnabled={summaryRowsEnabled}
                monthlyPriceEnabled={monthlyPriceEnabled}
            />
        );
    }

    return (
        <React.Fragment>
            <SecondaryMenuContainer
                style={{
                    paddingBottom: 10,
                }}
                title={
                    <Breadcrumbs
                        items={breadcrumbs(t, proposal ? proposal.title : '')}
                    />
                }></SecondaryMenuContainer>

            <Padding>
                <Container>
                    <ProposalForm
                        t={t}
                        tags={tags}
                        configuration={configuration}
                        standardTexts={standardTexts}
                        standardTextsExceptions={standardTextsExceptions}
                        standardTextsInclusions={standardTextsInclusions}
                        standardTextsPaymentTerms={standardTextsPaymentTerms}
                        standardTextsGeneralTerms={standardTextsGeneralTerms}
                        usersById={usersById}
                        hasPermission={hasPermission}
                        loading={loading}
                        applying={applying}
                        copying={copying}
                        error={error}
                        proposal={proposal}
                        workOrder={workOrder}
                        salesOrder={salesOrder}
                        handleOpenWorkOrder={handleOpenWorkOrder}
                        handleOpenSalesOrder={handleOpenSalesOrder}
                        saveState={saveState}
                        handleValueChange={handleValueChange}
                        navigateTo={navigateTo}
                        handleClearCustomer={handleClearCustomer}
                        handlePdf={handlePdf}
                        handleMakeCopy={handleMakeCopy}
                        handleDecline={handleDecline}
                        handleWordExport={handleWordExport}
                        handleCustomerChange={handleCustomerChange}
                        handleAccept={handleAccept}
                        handleDelete={handleDelete}
                        handleReopen={handleReopen}
                        handleBusinessObjectChange={handleBusinessObjectChange}
                        standardTextsOpen={standardTextsOpen}
                        setStandardTextsOpen={setStandardTextsOpen}
                        closing={closing}
                        closed={closed}
                        handleAcceptWorkOrder={handleAcceptWorkOrder}
                        onRowsChange={onRowsChange}
                        onContactChange={handleCustomerContactChange}
                        contacts={customer?.contacts}
                        filesEnabled={filesEnabled}
                        smallScreen={isSmallScreen}
                        summaryRowsEnabled={summaryRowsEnabled}
                        monthlyPriceEnabled={monthlyPriceEnabled}
                    />
                </Container>
            </Padding>
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    usersById: state.users.usersById,
    userId: state.auth.user.id,
    currency: state.auth.instance.configuration.currency,
    dateFormat: state.auth.instance.configuration.dateFormat,
    tags: state.auth.instance.configuration?.proposalConfiguration?.tags || [],
    standardTexts: state.auth.instance.configuration?.proposalConfiguration?.standardTexts || [],
    standardTextsInclusions: state.auth.instance.configuration?.proposalConfiguration?.standardTextsInclusions || [],
    standardTextsExceptions: state.auth.instance.configuration?.proposalConfiguration?.standardTextsExceptions || [],
    standardTextsPaymentTerms: state.auth.instance.configuration?.proposalConfiguration?.standardTextsPaymentTerms || [],
    standardTextsGeneralTerms: state.auth.instance.configuration?.proposalConfiguration?.standardTextsGeneralTerms || [],
    configuration: state.auth.appModules[appModules.CRM],
    isSmallScreen: state.ui.smallScreen,
});

const mapDispatchToProps = dispatch => ({
    confirm: (body, onConfirm, header, rawBody) =>
        dispatch(showConfirm({ body, onConfirm, header, rawBody })),
    showSuccessMessage: (text, params) =>
        dispatch(showSuccessNotification(text, params)),
    showErrorMessage: text => dispatch(showErrorNotification(text)),
    proposalChanged: proposal => dispatch(proposalChanged(proposal)),
    proposalClosed: id => dispatch(proposalClosed(id)),
    proposalDeleted: id => dispatch(proposalDeleted(id)),
    proposalCreated: proposal => dispatch(proposalCreated(proposal)),
    workOrderCreated: workOrder =>
        dispatch(requestCreateWorkOrderFulfilled(workOrder)),
    openWorkOrder: (id, wo) => dispatch(openWorkOrderTab(id, wo)),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        withTranslation(['ui', 'form'])(
            withPermissions(ProposalDetailsContainer)
        )
    )
);
