export const UPDATE_INSTANCE_CONFIGURATION = 'UPDATE_INSTANCE_CONFIGURATION';
export const UPDATE_BO_CONFIGURATION = 'UPDATE_BO_CONFIGURATION';
export const UPDATE_WO_CONFIGURATION = 'UPDATE_WO_CONFIGURATION';
export const UPDATE_INSTANCE_PROPOSAL_CONFIGURATION =
    'UPDATE_INSTANCE_PROPOSAL_CONFIGURATION';

export const updateBusinessObjectConfiguration = data => ({
    type: UPDATE_BO_CONFIGURATION,
    data,
});

export const updateWorkOrderConfiguration = data => ({
    type: UPDATE_WO_CONFIGURATION,
    data,
});

export const updateInstanceConfiguration = (name, value) => ({
    type: UPDATE_INSTANCE_CONFIGURATION,
    name,
    value,
});

export const updateInstanceProposalConfiguration = (name, value) => ({
    type: UPDATE_INSTANCE_PROPOSAL_CONFIGURATION,
    name,
    value,
});