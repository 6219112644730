//https://stackoverflow.com/questions/486896/adding-a-parameter-to-the-url-with-javascript

export const reloadWithParam = (key, value) => {
    key = encodeURI(key);
    value = encodeURI(value);

    var kvp = document.location.search.substr(1).split('&');

    var i = kvp.length; var x; while (i--) {
        x = kvp[i].split('=');

        if (x[0] == key) {
            x[1] = value;
            kvp[i] = x.join('=');
            break;
        }
    }

    if (i < 0) { kvp[kvp.length] = [key, value].join('='); }

    document.location.search = kvp.join('&');
}

export const getExternalUrl = url => {
    if (!url) {
        return '';
    }

    return url.startsWith("http://") || url.startsWith("https://") ?
        url
        : 
        `//${url}`;
}