export const articleTypes = {
    REGULAR: 'regular',
    FREETEXT: 'freetext',
};

export const articleProductTypes = {
    SERVICE: 'service',
    STOCK: 'stock',
    NON_STOCK: 'non_stock',
};

export const getArticleProductType = (t, productType) => {
    if (productType === articleProductTypes.SERVICE) {

        return t('ui:ARTICLE_PRODUCT_TYPE_SERVICE');
    }

    if (productType === articleProductTypes.STOCK) {
        
        return t('ui:ARTICLE_PRODUCT_TYPE_STOCK');
    }
    
    if (productType === articleProductTypes.NON_STOCK) {
        
        return t('ui:ARTICLE_PRODUCT_TYPE_NON_STOCK');
    }
}