import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Topbar from './Topbar';
import { logout } from '../../../data/actions/common/authActions';
import { toggleSidebar } from '../../../data/actions/common/uiActions';


class TopbarContainer extends Component {

    render() {
        return (
            <Topbar
                user={this.props.user}
                notifications={this.props.notifications}
                selectedKeys={[this.props.location.pathname]}
                toggleSidebar={this.props.toggleSidebar}
                instanceName={this.props.userInstance.displayName}
                instanceLogoUrl={this.props.userInstance.configuration.logoUrl}
                sidebarOpen={this.props.sidebarOpen}
                logout={this.props.logout}
                departments={this.props.departments} />
        )
    }
}

TopbarContainer.propTypes = {

}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    departments: state.auth.departments,
    notifications: state.auth.notifications,
    sidebarOpen: state.ui.sidebarOpen,
    userInstance: state.auth.instance
})

const mapDispatchToProps = dispatch => ({
    toggleSidebar: () => dispatch(toggleSidebar()),
    logout: () => dispatch(logout())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopbarContainer));

