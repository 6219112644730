import { isTimeBefore } from 'components/main/time-reporting/timeReportingUtils';
import moment from 'moment';
import { newDate, toDate } from './DateUtils';

export const firstDayOfWeek = 1;

export const dayNames = [
    'calendar:MONDAY',
    'calendar:TUESDAY',
    'calendar:WEDNESDAY',
    'calendar:THURSDAY',
    'calendar:FRIDAY',
    'calendar:SATURDAY',
    'calendar:SUNDAY',
];

export const monthNames = [
    'calendar:JANUARY',
    'calendar:FEBRUARY',
    'calendar:MARCH',
    'calendar:APRIL',
    'calendar:MAY',
    'calendar:JUNE',
    'calendar:JULY',
    'calendar:AUGUST',
    'calendar:SEPTEMBER',
    'calendar:OCTOBER',
    'calendar:NOVEMBER',
    'calendar:DECEMBER',
];

export const calendarPerspective = {
    SCHEDULE: 'schedule',
};

export const calendarPeriod = {
    MONTH: 'month',
    WEEK: 'week',
    DAY: 'day',
    QUARTER: 'quarter',
    HALF_YEAR: 'half_year',
    YEAR: 'year',
};

export const quarterNameForMonth = {
    0: 'Q1',
    1: 'Q1',
    2: 'Q1',
    3: 'Q2',
    4: 'Q2',
    5: 'Q2',
    6: 'Q3',
    7: 'Q3',
    8: 'Q3',
    9: 'Q4',
    10: 'Q4',
    11: 'Q4',
};

export const getStartEndDateForMonth = (year, month) => {
    const startDate = newDate().year(year).month(month).startOf('month');
    const endDate = newDate().year(year).month(month).endOf('month');

    return {
        startDate,
        endDate,
    };
};

export const getStartEndDateForQuarter = (year, q) => {
    if (q === 1) {
        const startDate = newDate().year(year).month(0).startOf('month');
        const endDate = newDate().year(year).month(2).endOf('month');

        return {
            startDate,
            endDate,
        };
    } else if (q === 2) {
        const startDate = newDate().year(year).month(3).startOf('month');
        const endDate = newDate().year(year).month(5).endOf('month');

        return {
            startDate,
            endDate,
        };
    } else if (q === 3) {
        const startDate = newDate().year(year).month(6).startOf('month');
        const endDate = newDate().year(year).month(8).endOf('month');

        return {
            startDate,
            endDate,
        };
    } else {
        const startDate = newDate().year(year).month(9).startOf('month');
        const endDate = newDate().year(year).month(11).endOf('month');

        return {
            startDate,
            endDate,
        };
    }
};

export const getQuarterMonthsForDate = date => {
    const m = date.month();

    if (m < 3) {
        return [0, 1, 2];
    } else if (m < 6) {
        return [3, 4, 5];
    } else if (m < 9) {
        return [6, 7, 8];
    } else {
        return [9, 10, 11];
    }
};

export const getQuarterMonthsForQuarter = q => {

    if (q === 1) {
        return [0, 1, 2];
    } else if (q === 2) {
        return [3, 4, 5];
    } else if (q === 3) {
        return [6, 7, 8];
    } else {
        return [9, 10, 11];
    }
};

export const getHalfYearMonthsForDate = date => {
    const m = date.month();

    if (m < 6) {
        return [0, 1, 2, 3, 4, 5];
    } else {
        return [6, 7, 8, 9, 10, 11];
    }
};

export const getMonths = () => {
    return monthNames;
};

export const getYears = () => {
    const currentYear = new Date().getFullYear();

    const years = [currentYear];
    let i = 0;

    while (i < 20) {
        years.push(currentYear - ++i);
    }

    return years;
};

const weekStartDate = date => {
    let startDate = new Date(date.getTime());
    while (startDate.getDay() !== firstDayOfWeek) {
        startDate.setDate(startDate.getDate() - 1);
    }
    return startDate;
};

export const getMonthDates = (
    year,
    month,
    dayFormatter = null,
    weekFormatter = null
) => {
    let weeks = [],
        week = [],
        i = 0,
        date = weekStartDate(new Date(year, month, 1));
    do {
        for (i = 0; i < 7; i++) {
            week.push(dayFormatter ? dayFormatter(date) : date);
            date = new Date(date.getTime());
            date.setDate(date.getDate() + 1);
        }
        weeks.push(weekFormatter ? weekFormatter(week) : week);
        week = [];
    } while (date.getMonth() <= month && date.getFullYear() === year);
    return weeks;
};

export const getWeekDays = (date, includeWeekends = false) => {
    let day = date.getDay();

    if (day === 0) {
        day = 7;
    }

    let dates = [];

    for (let i = 1; i <= day; i++) {
        dates.push(
            new Date(date.getTime() - (day - i) * (24 * 60 * 60 * 1000))
        );
    }

    for (let i = day + 1; i <= 7; i++) {
        dates.push(
            new Date(date.getTime() + (i - day) * (24 * 60 * 60 * 1000))
        );
    }

    return includeWeekends
        ? dates
        : dates.filter(d => d.getDay() !== 6 && d.getDay() !== 0);
};

export const getMonthName = nr => monthNames[nr];

export const getMonthNameShort = nr => `${getMonthName(nr)}_SHORT`;

export const getDayName = nr => dayNames[nr];

const weeksInYear = year => {
    return Math.max(
        moment(new Date(year, 11, 31)).isoWeek(),
        moment(new Date(year, 11, 31 - 7)).isoWeek()
    );
};

export const getWeeksBetween = (startTs, endTs) => {
    if (!startTs || !endTs || startTs > endTs) {
        return [];
    }

    const res = [];

    let year = toDate(startTs).endOf('isoWeek').year();
    let startWeek = toDate(startTs).endOf('isoWeek').isoWeek();
    const endYear = toDate(endTs).endOf('isoWeek').year();
    const endWeek = toDate(endTs).endOf('isoWeek').isoWeek();

    console.log(year, startWeek, endYear, endWeek)

    res.push({
        key: `${year}-${startWeek}`,
        year,
        week: startWeek,
    });

    let blocker = 0;

    while (!(startWeek === endWeek && year === endYear) && blocker < 55) {
        startWeek++;

        if (weeksInYear(year) > 52) {
            if (startWeek === 54) {
                startWeek = 1;
                year++;
            }
        } else {
            if (startWeek === 53) {
                startWeek = 1;
                year++;
            }
        }

        res.push({
            key: `${year}-${startWeek}`,
            year,
            week: startWeek,
        });

        blocker++;
    }

    return res;
};

export const calendarEventSort = (e1, e2) => {
    const v1 = e1.type === 'timereport' ? e1.startTime : e1.startTs;
    const v2 = e2.type === 'timereport' ? e2.startTime : e2.startTs;

    return isTimeBefore(v1, v2) ? -1 : 1;
};
