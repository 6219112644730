import {
    LOGIN_FULFILLED
} from '../../actions/common/authActions';

import {
    REPORTS_TIMEREPORT_FILTERS_CHANGE,
    FETCH_REPORTS_TIMEREPORT,
    FETCH_REPORTS_TIMEREPORT_FULFILLED,
    FETCH_REPORTS_TIMEREPORT_REJECTED
} from '../../actions/reports';
import { getThisMonthPeriod } from '../../../utils/DateUtils';

const defaultState = {
    isLoading: false,
    tableState: undefined,
    filters: getThisMonthPeriod(),
    searchFilters: {

    },
    reports: {

    }
}

export const timeReports = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case REPORTS_TIMEREPORT_FILTERS_CHANGE: {

            return {
                ...state,
                filters: action.filters
            }
        }
        case LOGIN_FULFILLED: {

            return {
                ...state,
                filters: {
                    ...state.filters,
                    userId: state.filters.userId || action.user.id
                }
            }
        }
        case FETCH_REPORTS_TIMEREPORT: {

            return {
                ...state,
                isLoading: true,
                searchFilters: { ...state.filters }
            }
        }
        case FETCH_REPORTS_TIMEREPORT_REJECTED: {

            return {
                ...state,
                isLoading: false
            }
        }
        case FETCH_REPORTS_TIMEREPORT_FULFILLED: {

            return {
                ...state,
                isLoading: false,
                reports: action.reports
            }
        }
        default: {
            return state;
        }
    }
};