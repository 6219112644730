import Alert from 'components/common/alert';
import Loader from 'components/common/loader';
import { useInstanceData } from 'components/common/use-instance-data';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Loadable from 'react-loadable';
import { Divider, Table } from 'semantic-ui-react';
import { invoiceTemplates } from 'utils/constants';
import { appModules as modules } from 'utils/constants/appModules';
import { defaultSort } from '../../../utils/ArrayUtils';
import { getInvoiceAddress } from '../../../utils/CustomerUtils';
import { formatDate } from '../../../utils/DateUtils';
import {
    getDiscountText,
    shouldShowDiscountColumn,
} from '../../../utils/ReportRowUtils';
import { formatPrice } from '../../../utils/StringUtils';
import './Invoice.css';
import InvoiceExtraActions from './InvoiceExtraActions';

const GenericInvoiceTemplate = Loadable({
    loader: () => import('./templates/generic/invoice.generic'),
    loading: Loader,
});

const OrebrolarmInvoiceTemplate = Loadable({
    loader: () => import('./templates/generic/invoice.orebrolarm'),
    loading: Loader,
});


const Invoice = props => {
    const {
        invoice,
        t,
        instanceConfiguration,
        onApply,
        onPrint,
        useExternalId,
        externalId,
        erpId,
    } = props;

    const { appModules } = useInstanceData();

    const templateId =
        appModules[modules.UI_SETTINGS]?.custom?.invoice?.templateId;

    if (templateId === invoiceTemplates.LARMKRAFT) {
        return <GenericInvoiceTemplate {...props} />;
    }

    if (templateId === invoiceTemplates.R_SEC) {
        return <GenericInvoiceTemplate {...props} />;
    }

    if (templateId === invoiceTemplates.OREBROLARM) {
        return <OrebrolarmInvoiceTemplate {...props} />;
    }

    const { instance, customer, workOrder, businessObject, agreement } =
        invoice.data;
    const showDiscountColumn = shouldShowDiscountColumn(
        workOrder && workOrder.articleReports
    );
    const address = getInvoiceAddress(invoice.customerAddressId, customer);

    const wasErpIgnored = customer?.configuration?.ignoreErpInvoices === true;

    return (
        <div className="man-invoice-container">
            <div className="man-invoice-row align-right man-invoice-extra-actions man-noprint">
                <InvoiceExtraActions onPrint={onPrint} onApply={onApply} />
            </div>
            <div className="man-invoice-row">
                <div className="man-col-align-left man-invoice-logo-container">
                    <div
                        className="man-invoice-logo"
                        style={{
                            backgroundImage: `url(${instanceConfiguration.logoUrl})`,
                        }}></div>
                </div>
                <div className="man-invoice-text-col man-col-align-right">
                    {instance && (
                        <React.Fragment>
                            {instance.companyName && (
                                <div>{instance.companyName}</div>
                            )}
                            {instance.companyNr && (
                                <div>{instance.companyNr}</div>
                            )}
                            {instance.address && <div>{instance.address}</div>}
                            {instance.email && <div>{instance.email}</div>}
                        </React.Fragment>
                    )}
                </div>
            </div>
            <div className="man-invoice-row">
                <div className="man-invoice-text-col man-col-align-left">
                    <h3>{t('ui:RECIPIENT')}</h3>
                    {customer && (
                        <React.Fragment>
                            <div>{customer.customerId}</div>
                            <div>{customer.displayName}</div>
                            <div>{address.street}</div>
                            <div>{customer.contactEmail}</div>
                            <div>{customer.contactTel}</div>
                            {customer.configuration &&
                                customer.configuration.invoice &&
                                customer.configuration.invoice.reverseVat && (
                                    <div className="man-invoice-text-row">
                                        <div>
                                            {t('form:VAT_REGISTRATION_NUMBER')}
                                            &nbsp;
                                        </div>
                                        <div>
                                            {
                                                customer.configuration.invoice
                                                    .customerVatNumber
                                            }
                                        </div>
                                    </div>
                                )}
                        </React.Fragment>
                    )}
                </div>
                <div className="man-invoice-text-col man-col-align-right">
                    <div>{t('ui:INVOICE_NR')}</div>
                    <h2 className="man-invoice-nr">
                        {useExternalId
                            ? externalId
                                ? externalId
                                : '-'
                            : invoice.invoiceNr}
                    </h2>
                    {useExternalId && erpId && (
                        <div className="trks-secondary-text">
                            {t('erp:INVOICE_NR_HANDLED_BY', {
                                name: t(`erp:${erpId}`),
                            })}
                        </div>
                    )}
                    {wasErpIgnored && (
                        <Alert
                            type="negative"
                            message={t('erp:INVOICE_WAS_NOT_CREATED_IN_ERP', {
                                name: t(`erp:${erpId}`),
                            })}
                        />
                    )}
                    <h4>
                        {formatDate(
                            invoice.date,
                            instanceConfiguration.dateFormat
                        )}
                    </h4>
                    {(invoice.marking ||
                        (workOrder && workOrder.invoiceExtra)) && (
                            <div>
                                {t('form:INVOICE_MARKING')}:{' '}
                                <h4 style={{ display: 'inline' }}>
                                    {invoice.marking || workOrder.invoiceExtra}
                                </h4>
                            </div>
                        )}
                </div>
            </div>
            {workOrder && (
                <React.Fragment>
                    <Divider />
                    <div className="man-invoice-text-col">
                        {`${t('ui:WORK_ORDER')}: ${workOrder.title}`}
                        <div className="man-invoice-mb20">
                            {`${t('invoice:INVOICE_INFORMATION')} ${businessObject ? businessObject.serialCode : ''
                                } 
                                ${(workOrder.locationText &&
                                    `, ${workOrder.locationText}`) ||
                                ''
                                } 
                                ${(workOrder.locationText2 &&
                                    `, ${workOrder.locationText2}`) ||
                                ''
                                }
                                ${(workOrder.locationDescription &&
                                    `, ${workOrder.locationDescription}`) ||
                                ''
                                }`}
                        </div>
                        {(workOrder.summary || workOrder.description) && (
                            <React.Fragment>
                                <div className="man-font-bold">
                                    <u>{t('invoice:INVOICE_DESCRIPTION')}</u>
                                </div>
                                {workOrder.description && (
                                    <div className="man-ui-wrap-newline">
                                        {workOrder.description}
                                    </div>
                                )}
                                {workOrder.summary && (
                                    <div
                                        className="man-ui-wrap-newline"
                                        style={{
                                            marginTop: workOrder.description
                                                ? 10
                                                : 0,
                                        }}>
                                        {workOrder.summary}
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </React.Fragment>
            )}

            {!workOrder && businessObject && (
                <React.Fragment>
                    <Divider />
                    <div className="man-invoice-text-col">
                        <div className="man-invoice-mb20">
                            {`${t('invoice:INVOICE_INFORMATION')} ${businessObject.serialCode
                                } 
                            ${(businessObject.locationText &&
                                    `, ${businessObject.locationText}`) ||
                                ''
                                } 
                            ${(businessObject.locationText2 &&
                                    `, ${businessObject.locationText2}`) ||
                                ''
                                }
                            ${(businessObject.locationDescription &&
                                    `, ${businessObject.locationDescription}`) ||
                                ''
                                }`}
                        </div>
                    </div>
                </React.Fragment>
            )}

            {agreement && agreement.description && (
                <React.Fragment>
                    <div className="man-invoice-text-col">
                        <div
                            className="man-invoice-mb20 man-ui-wrap-newline">
                            {agreement.description}
                        </div>
                    </div>
                </React.Fragment>
            )}

            {invoice.periodFrom && invoice.periodTo && (
                <React.Fragment>
                    <div className="man-invoice-text-col">
                        <div className="man-invoice-mb20">
                            {`${t(
                                'form:INVOICE_REGARDING_PERIOD'
                            )}: ${formatDate(
                                invoice.periodFrom,
                                instanceConfiguration.dateFormat
                            )} - ${formatDate(
                                invoice.periodTo,
                                instanceConfiguration.dateFormat
                            )}`}
                        </div>
                    </div>
                </React.Fragment>
            )}

            {workOrder && workOrder.contactName && (
                <React.Fragment>
                    <div className="man-invoice-text-col">
                        <div className="man-invoice-mb20">
                            {`${t('form:ORDERED_BY')}: ${workOrder.contactName
                                }`}
                        </div>
                    </div>
                </React.Fragment>
            )}

            {invoice.description && (
                <React.Fragment>
                    <div className="man-font-bold">
                        <u>{t('invoice:INVOICE_DESCRIPTION')}</u>
                    </div>
                    <div className="man-ui-wrap-newline">
                        {invoice.description}
                    </div>
                </React.Fragment>
            )}

            <Divider />
            <Table basic="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('form:ARTICLE')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('form:PRICE')}</Table.HeaderCell>
                        {showDiscountColumn && (
                            <Table.HeaderCell>
                                {t('form:DISCOUNT')}
                            </Table.HeaderCell>
                        )}
                        <Table.HeaderCell>{t('form:AMOUNT')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('ui:TOTAL')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {invoice.articles &&
                        invoice.articles.sort(defaultSort).map(a => {
                            if (a.isText) {
                                return (
                                    <Table.Row key={a.id}>
                                        <Table.Cell
                                            className="man-font-bold man-ui-wrap-newline"
                                            colSpan="5">
                                            {a.comment || ''}
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            }

                            let title = a.articleTitle;
                            if (a.articleDescription != null) {
                                title = `${a.articleTitle} - ${a.articleDescription}`;
                            }
                            return (
                                <Table.Row key={a.id}>
                                    <Table.Cell>{title}</Table.Cell>
                                    <Table.Cell>{`${formatPrice(
                                        a.price,
                                        instanceConfiguration.currency.symbol
                                    )} / ${a.unit} ${a.discountValue
                                            ? getDiscountText(
                                                a.discountType,
                                                a.discountValue,
                                                instanceConfiguration.currency
                                                    .symbol
                                            )
                                            : ''
                                        }`}</Table.Cell>
                                    {showDiscountColumn && (
                                        <Table.Cell>
                                            {getDiscountText(
                                                a.discountType,
                                                a.discountValue,
                                                instanceConfiguration.currency
                                                    .symbol
                                            )}
                                        </Table.Cell>
                                    )}
                                    <Table.Cell>{a.amount}</Table.Cell>
                                    <Table.Cell>{`${formatPrice(
                                        a.totalPrice,
                                        instanceConfiguration.currency.symbol
                                    )}`}</Table.Cell>
                                </Table.Row>
                            );
                        })}
                    <Table.Row>
                        <Table.Cell></Table.Cell>
                        {showDiscountColumn && <Table.Cell></Table.Cell>}
                        <Table.Cell className="man-font-bold">
                            {t('ui:SUBTOTAL')}
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell className="man-font-bold">{`${formatPrice(
                            invoice.totalPrice,
                            instanceConfiguration.currency.symbol
                        )}`}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell></Table.Cell>
                        {showDiscountColumn && <Table.Cell></Table.Cell>}
                        <Table.Cell>{t('ui:VAT')}</Table.Cell>
                        <Table.Cell>{invoice.vatPercentage} %</Table.Cell>
                        <Table.Cell>{`${formatPrice(
                            invoice.totalPriceVat - invoice.totalPrice,
                            instanceConfiguration.currency.symbol
                        )}`}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell></Table.Cell>
                        {showDiscountColumn && <Table.Cell></Table.Cell>}
                        <Table.Cell className="man-font-bold">
                            {t('ui:TOTAL')}
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell className="man-font-bold">{`${formatPrice(
                            invoice.totalPriceVat,
                            instanceConfiguration.currency.symbol
                        )}`}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <div className="man-invoice-text-row man-invoice-mt40">
                <div className="man-invoice-text-col man-font-bold">
                    {workOrder?.invoiceDetails?.ignoreReverseVAT != true && customer.configuration &&
                        customer.configuration.invoice &&
                        customer.configuration.invoice.reverseVat &&
                        t('invoice:ISSUED_WITH_REVERSE_VAT')}
                </div>
                {instance.bgAccount && (
                    <div className="man-invoice-text-col man-font-bold">
                        <div>{t('form:BG_ACCOUNT')}</div>
                        <div>{instance.bgAccount}</div>
                    </div>
                )}
                <div className="man-invoice-text-col man-font-bold">
                    <div>{t('form:PAYMENT_DUE_DATE')}</div>
                    {invoice.paymentDueDate && (
                        <div>
                            {formatDate(
                                invoice.paymentDueDate,
                                instanceConfiguration.dateFormat
                            )}
                        </div>
                    )}
                </div>
                {instance.vatRegistrationNr && (
                    <div className="man-invoice-text-col man-font-bold">
                        <div>{t('form:VAT_REGISTRATION_NUMBER')}</div>
                        <div>{instance.vatRegistrationNr}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

Invoice.protoTypes = {
    invoice: PropTypes.object.isRequired,
    instanceConfiguration: PropTypes.object.isRequired,
    onApply: PropTypes.func.isRequired,
    useExternalId: PropTypes.bool.isRequired,
    externalId: PropTypes.string,
    erpId: PropTypes.string,
};

export default withTranslation(['ui', 'form', 'erp'])(Invoice);
