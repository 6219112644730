import { get, put, post, deletee } from 'utils/http/HttpClient';
import qs from 'query-string';

export default {
    queryArticles: async (supplierId, queryString, page, pageSize) => {
        const query = qs.stringify({
            supplierId,
            q: queryString,
            page,
            pageSize
        });

        const { articles, totalCount } = await get(`/supplierinventory/query/articles?${query}`);

        return {
            articles,
            totalCount
        };
    }
}