
export default {
    WORK_TYPES: 'workTypes',
    TAGS: 'tags',
    SEARCH: 'search',
    UNASSIGNED: 'unassigned',
    DEPARTMENTS: 'departments',
    IGNORED_USERS: 'ignoredUsers',
    ONLY_MINE: 'onlyMine',
    ASSIGNEES: 'assignees',
    INCLUDE_SCHEDULED: 'includeScheduled',
    SCHEDULE_STATUS: 'scheduleStatus',
    STATUS: 'status',
    SHOW_IN_DELIVERY: 'showInDelivery',
    CUSTOMER_SHOW_REGULAR: 'showRegular',
    CUSTOMER_SHOW_POTENTIAL: 'showPotential',
    CUSTOMER_TYPE: 'customerType',
    PROJECT: 'project',
    SECURITY_ARTICLE_SHOW_SPSC: 'showSPSC',
}