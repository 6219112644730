export const formatBytes = (
    bytes: number | null,
    decimals: number = 2
): string | null => {
    if (!bytes) return null;

    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const numericValue = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

    return `${numericValue} ${sizes[i]}`;
};

export const generateIndexBetween = (
    lower: number | null,
    upper: number | null
): number => {
    if (!lower && !upper) {
        return 100;
    }

    if (!lower) {
        return Math.floor(upper! - 1);
    }

    if (!upper) {
        return Math.ceil(lower! + 1);
    }

    return (upper! - lower!) / 2 + lower!;
};
