import {
    FETCH_ARTICLES,
    FETCH_ARTICLES_FULFILLED,
    FETCH_ARTICLES_REJECTED,
    ARTICLE_CHANGED,
    ARTICLE_DELETED,
    ARTICLE_TABLE_STATE_CHANGE,
    ARTICLE_FILTER_CHANGE,
    FETCH_INACTIVE_ARTICLES,
    FETCH_INACTIVE_ARTICLES_FULFILLED
} from 'data/actions/articles';
import { STATUS_ACTIVE } from '../../../utils/constants';

const defaultState = {
    isFetching: false,
    articles: [],
    inactiveArticles: [],
    tableState: undefined,
    searchFilters: {
        status: STATUS_ACTIVE
    }
}

export const articles = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case ARTICLE_TABLE_STATE_CHANGE: return ({ ...state, tableState: action.state })
        case ARTICLE_FILTER_CHANGE: {
            const filters = { ...state.searchFilters };

            filters[action.filterName] = action.value;

            return {
                ...state,
                searchFilters: filters
            }
        }
        case FETCH_ARTICLES: {
            return {
                ...state,
                isFetching: true
            }
        }
        case FETCH_ARTICLES_FULFILLED: {
            return {
                ...state,
                isFetching: false,
                articles: action.articles
            }
        }
        case FETCH_ARTICLES_REJECTED: {
            return {
                ...state,
                isFetching: false
            }
        }
        case FETCH_INACTIVE_ARTICLES_FULFILLED: {
            return {
                ...state,
                inactiveArticles: action.articles
            }
        }
        case ARTICLE_CHANGED: {
            const articles = [...state.articles];
            const idx = articles.findIndex(a => a.id === action.article.id);

            if (idx < 0) {
                articles.push(action.article);
            } else {
                articles[idx] = action.article;
            }

            return {
                ...state,
                articles
            }
        }
        case ARTICLE_DELETED: {
            const articles = [...state.articles];
            const idx = articles.findIndex(a => a.id === action.id);

            articles.splice(idx, 1);

            return {
                ...state,
                articles
            }
        }
        default: {
            return state;
        }
    }
};