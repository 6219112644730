import qs from 'query-string';
import { SUPPLIER_INVOICE_STATUS_ALL } from 'utils/constants';
import { deletee, get, patch, post } from 'utils/http/HttpClient';

export default {
    fetch: async (paymentStatus) => {
        const query = qs.stringify({
            status: paymentStatus === SUPPLIER_INVOICE_STATUS_ALL ? undefined : paymentStatus
        });

        const { invoices, paymentPendingCount } = await get(`/supplierinvoices?${query}`);

        return {
            invoices,
            paymentPendingCount
        };
    },
    fetchDetails: async id => {
        const { invoice } = await get(`/supplierinvoices/${id}`);

        return invoice
    },
    patch: async invoice => {
        await patch(`/supplierinvoices`, invoice);
    },
    approveContent: async id => {
        await post(`/supplierinvoices/${id}/content/approve`);
    },
    approvePayment: async id => {
        await post(`/supplierinvoices/${id}/payment/approve`);
    },
    delete: async id => {
        await deletee(`/supplierinvoices/${id}`);
    },
    createComment: async (id, comment) => {
        await post(`/supplierinvoices/${id}/comments`, comment);
    },
    fetchComments: async id => {
        const { comments } = await get(`/supplierinvoices/${id}/comments`);

        return comments;
    }
};
