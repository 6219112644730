import {
    FETCH_MODELS,
    FETCH_MODELS_FULFILLED,
    FETCH_MODELS_REJECTED,
    MODEL_CHANGED,
    MODEL_DELETED,
    MODEL_TABLE_STATE_CHANGE
} from '../../actions/models';

const defaultState = {
    isFetching: false,
    models: [],
    tableState: undefined
}

export const models = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case MODEL_TABLE_STATE_CHANGE: return ({ ...state, tableState: action.state })
        case FETCH_MODELS: {
            return {
                ...state,
                isFetching: true
            }
        }
        case FETCH_MODELS_FULFILLED: {
            return {
                ...state,
                isFetching: false,
                models: action.models
            }
        }
        case FETCH_MODELS_REJECTED: {
            return {
                ...state,
                isFetching: false
            }
        }
        case MODEL_CHANGED: {
            const models = [...state.models];
            const idx = models.findIndex(m => m.id === action.model.id);

            if (idx < 0) {
                models.push(action.model);
            } else {
                models[idx] = {
                    ...action.model,
                    objectCount: models[idx]?.objectCount
                };
            }

            return {
                ...state,
                models
            }
        }
        case MODEL_DELETED: {
            const models = [...state.models];
            const idx = models.findIndex(m => m.id === action.id);

            models.splice(idx, 1);

            return {
                ...state,
                models
            }
        }
        default: {
            return state;
        }
    }
};