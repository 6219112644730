import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { get } from '../../../../utils/http/HttpClient';
import LocalizedSearch from '../../../common/localized-search';

const BoSearchOption = ({
    modelName,
    serialCode,
    customerName,
    displayCustomer,
    locationDescription
}) => {
    return (
        <div className="ui feed" style={{ margin: '0.4em' }}>
            <div className="event">
                <div className="content">
                    <div className="summary">
                        <a className="user">
                            {modelName} ({serialCode})
                        </a>
                        <div className="trks-secondary-text">
                            {locationDescription}
                        </div>
                    </div>
                    {displayCustomer && (
                        <div className="meta" style={{ margin: 0 }}>
                            <a className="like">{customerName}</a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

class BoPicker extends Component {
    state = {
        isEditing: false,
        isLoading: false,
        searchValue: '',
        searchResults: [],
    };

    UNSAFE_componentWillMount() {
        document.addEventListener('mousedown', this.handleClickEvent, false);
        document.addEventListener('keydown', this.handleEnterClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent, false);
        document.removeEventListener('keydown', this.handleEnterClick, false);
    }

    handleChange = (e, { value }) => {
        this.handleAutoComplete(value);
    };

    handleAutoComplete = async value => {
        this.setState({
            isLoading: true,
            searchValue: value,
        });

        this.queryObjects(value);
    };

    queryObjects = _.debounce(async value => {
        const { customerId } = this.props;

        try {
            const { bos } = await get(
                `/bo?q=${value}${customerId ? `&customerId=${customerId}` : ''}`
            );

            this.setState({
                isLoading: false,
                searchResults: bos,
            });
        } catch (err) {
            this.setState({
                isLoading: false,
            });
        }
    }, 350);

    handleSelect = (e, { result }) => {
        this.props.onObjectChange(result);

        this.setState({
            searchResults: [],
            isEditing: false,
        });
    };

    handleStartEditing = () => {
        this.setState(
            {
                isEditing: true,
            },
            () => {
                if (this.props.customerId) {
                    this.handleAutoComplete('');
                }

                setTimeout(() => {
                    const ref = this.searchInputRef.querySelector(
                        '.ui.search .ui.input input'
                    );

                    if (ref) {
                        ref.focus();
                    }
                }, 100);
            }
        );
    };

    renderSearchResult = bo => (
        <BoSearchOption
            key={bo.id}
            displayCustomer={!this.props.customerId}
            {...bo}
        />
    );

    handleEnterClick = e => {
        if (e.keyCode === 27) {
            this.setState({
                isEditing: false,
            });
        }
    };

    handleBlur = () => {
        setTimeout(() => {
            this.setState({
                isEditing: false,
            });
        }, 200);
    };

    handleClickEvent = e => {
        if (this.searchInputRef && this.searchInputRef.contains(e.target)) {
            return;
        }

        this.setState({
            isEditing: false,
        });
    };

    render() {
        const { t, alwaysActive, fluid } = this.props;

        return (
            <div className="man-bopicker" style={{ display: 'inline' }}>
                {this.state.isEditing || alwaysActive ? (
                    <div ref={node => (this.searchInputRef = node)}>
                        <LocalizedSearch
                            fluid={fluid}
                            minCharacters={0}
                            onBlur={this.handleBlur}
                            placeholder={t('BOPICKER_SELECT')}
                            results={this.state.searchResults}
                            loading={this.state.isLoading}
                            onResultSelect={this.handleSelect}
                            onSearchChange={this.handleChange}
                            value={this.props.value}
                            resultRenderer={this.renderSearchResult}
                            isFetching={this.state.isLoading}
                            noResultsDescription={
                                this.state.isLoading ? t('SEARCHING') : null
                            }
                        />
                    </div>
                ) : (
                    <a
                        className="man-edit-link"
                        tabIndex={0}
                        onBlur={this.handleBlur}
                        onFocus={this.handleStartEditing}
                        onClick={this.handleStartEditing}>
                        {this.props.value
                            ? this.props.value
                            : t('BOPICKER_SELECT')}
                    </a>
                )}
            </div>
        );
    }
}

BoPicker.propTypes = {
    onObjectChange: PropTypes.func.isRequired,
    customerId: PropTypes.string,
    alwaysActive: PropTypes.bool,
    fluid: PropTypes.bool,
};

BoPicker.defaultProps = {
    alwaysActive: false,
    fluid: false,
};

export default withTranslation('ui')(BoPicker);
