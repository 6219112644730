export const chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size)
    );


export const defaultSort = (r1, r2) => {
    if (!r1.sortOrder && !r2.sortOrder) {
        return 0;
    }

    return r1.sortOrder < r2.sortOrder ? -1 : 1;
}

export const groupBy = (array, key) =>
    array.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});

export const normalize = (array, key) => {
    let normalized = {};

    (array || []).forEach(val => {
        normalized[val[key]] = val;
    });

    return normalized;
}


export const sortBy = key => (r1, r2) => {
    if (r1[key] === r2[key]) {
        return 0;
    }

    return r1[key] < r2[key] ? -1 : 1;
} 

export const pushNullsDown = (arr, key) => {
    const nonNulls = [];
    const nulls = [];

    arr.forEach(v => {
        if (v[key]) {
            nonNulls.push(v);
        } else {
            nulls.push(v);
        }
    })

    return [...nonNulls, nulls];
}

export const move = (arr, fromIndex, toIndex) => {
    arr.splice(toIndex, 0, arr.splice(fromIndex, 1)[0]);

    return arr;
};
