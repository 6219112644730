import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { showErrorNotification } from '../../../../data/actions/common/notificationActions';
import { hideModal } from '../../../../data/actions/common/uiActions';
import { SettingsRow, SettingsGrid } from '../../../common/settings-grid';
import { formatDateTimeVerbose } from '../../../../utils/DateUtils';
import HistoryListLoader from '../history-list-loader';
import invoiceService from '../../../../data/services/invoice/invoiceService';
import { Item } from 'trukks-lib';

class InvoiceHistoryContainer extends Component {
    state = {
        isLoading: true,
        rows: []
    }

    componentDidMount() {
        const { id } = this.props;
        this.fetchHistory(id);
    }

    fetchHistory = async id => {
        const { t, closeModal, showError } = this.props;

        this.setState({
            isLoading: true
        }, async () => {
            try {
                const rows = await invoiceService.fetchHistory(id);

                this.setState({
                    isLoading: false,
                    rows
                })
            } catch (err) {
                this.setState({
                    isLoading: false
                })

                closeModal();
                showError(t('messages:FAILED_TO_FETCH'));
            }
        })
    }

    render() {
        const { t } = this.props;

        const { isLoading, rows } = this.state;

        if (isLoading) {
            return (
                <HistoryListLoader />
            )
        }

        return (
            <SettingsGrid>

                {rows
                    .sort((r1, r2) => r1.timestamp > r2.timestamp ? -1 : 1)
                    .map(({ userName, action, timestamp, data }, idx) => {
                        if (idx != 0) {
                            const prevRow = rows[idx - 1];

                            if (prevRow.action === action && prevRow.timestamp - timestamp < 60000) { // 1 min
                                return null;
                            }
                        }

                        let extra = '';

                        try {
                            if (action === 'email') {
                                const { recipients } = JSON.parse(data);
                                extra = recipients;
                            }
                        } catch {

                        }

                        return (
                            <SettingsRow key={timestamp} label={
                                <label>{formatDateTimeVerbose(timestamp)}</label>
                            }>
                                <Item>
                                    {extra}
                                    <div className="man-secondary-text">
                                        {t(`invoicehistory:${action}`)}
                                    </div>
                                </Item>
                            </SettingsRow>
                        )
                    })}

            </SettingsGrid>
        )
    }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({
    showError: text => dispatch(showErrorNotification(text)),
    closeModal: () => dispatch(hideModal())
})

export default withTranslation(['ui', 'messages', 'invoicehistory'])(connect(mapStateToProps, mapDispatchToProps)(InvoiceHistoryContainer));