export const FileTypes = {
    FOLDER: 'folder',
    FILE: 'file',
    BACK_FOLDER: 'back',
    LINK: 'link',
};

export const FileExtensions = {
    JPG: 'jpg',
    JPEG: 'jpeg',
    PNG: 'png',
    DOCX: 'docx',
    ODT: 'odt',
    XLS: 'xls',
    XLSX: 'xlsx',
    ODS: 'ods',
    CSV: 'csv',
    PDF: 'pdf',
    PPTX: 'pptx',
    PPT: 'ppt',
    ODP: 'odp',
};

export const FileMIMETypes = {
    APNG: 'image/apng',
    AVIF: 'image/avif',
    GIF: 'image/gif',
    JPEG: 'image/jpeg',
    PNG: 'image/png',
    SVG: 'image/svg+xml',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ODT: 'application/vnd.oasis.opendocument.text',
    WEBP: 'image/webp',
    XLS: 'application/vnd.ms-excel',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ODS: 'application/vnd.oasis.opendocument.spreadsheet',
    CSV: 'text/csv',
    PDF: 'application/pdf',
    PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    PPT: 'application/vnd.ms-powerpoint',
};

export const ImageFileTypes = [
    FileMIMETypes.APNG,
    FileMIMETypes.AVIF,
    FileMIMETypes.GIF,
    FileMIMETypes.JPEG,
    FileMIMETypes.PNG,
    FileMIMETypes.SVG,
    FileMIMETypes.WEBP,
];

export const VideoFileType = 'video';
export const AudioFileType = 'audio';
