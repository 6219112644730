import qs from 'query-string';
import {
    PROPOSAL_STATUS_ACCEPTED,
    PROPOSAL_STATUS_ACCEPTED_WORK_ORDER,
} from '../../../utils/constants';
import {
    deletee,
    get,
    getFile,
    patch,
    post,
    put,
} from '../../../utils/http/HttpClient';

export default {
    fetch: async (status, startTime, endTime, tags, userId) => {
        const query = qs.stringify({
            status:
                status === PROPOSAL_STATUS_ACCEPTED
                    ? `${PROPOSAL_STATUS_ACCEPTED},${PROPOSAL_STATUS_ACCEPTED_WORK_ORDER}`
                    : status,
            startTime: status === 'active' ? null : startTime,
            endTime: status === 'active' ? null : endTime,
            tags: tags && tags.length > 0 ? tags.join(',') : null,
            userId
        });

        const { proposals } = await get(`/proposals?${query}`);

        return proposals;
    },
    fetchDetails: async id => {
        const { proposal } = await get(`/proposals/${id}`);

        return proposal;
    },
    create: async customerId => {
        const { id } = await post('/proposals', {
            customerId
        });

        const { proposal } = await get(`/proposals/${id}`);

        return proposal;
    },
    update: async proposal => {
        await put('/proposals', proposal);
    },
    updateState: async (proposalId, state, sortOrder) => {
        await patch(`/proposals/${proposalId}`, {
            state,
            sortOrder,
        });
    },
    delete: async id => {
        await deletee(`/proposals/${id}`);
    },
    close: async (proposalId, status) => {
        const { id } = await post(`/proposals/close`, {
            proposalId,
            status
        });

        return id;
    },
    reopen: async proposalId => {
        await post(`/proposals/${proposalId}/reopen`, {});
    },
    closeWorkOrder: async (proposalId, status, ignoreArticlesForWorkOrder) => {
        const { workOrder } = await post(`/proposals/close`, {
            proposalId,
            status,
            ignoreArticlesForWorkOrder
        });

        return workOrder;
    },
    exportWord: async (proposal, templateId, fileName) => {
        try {
            await getFile(
                '/proposals/word',
                {
                    proposal,
                    templateId,
                },
                fileName ? fileName.replace('{{title}}', proposal.title) : proposal.title,
                '.docx',
                'proposal'
            );
        } catch (err) {
            console.error(err);
        }
    },
    pdf: async (proposal, templateId, fileName) => {
        try {
            await getFile(
                '/proposals/pdf',
                {
                    proposal,
                    templateId,
                },
                fileName ? fileName.replace('{{title}}', proposal.title) : proposal.title,
                'pdf',
                'pdf',
                !!fileName
            );
        } catch (err) {
            console.error(err);
        }
    },
};
