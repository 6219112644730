import {
    FETCH_PROPOSALS,
    FETCH_PROPOSALS_FULFILLED,
    FETCH_PROPOSALS_REJECTED,
    PROPOSAL_CHANGED,
    PROPOSAL_CREATED,
    PROPOSAL_DELETED,
    PROPOSAL_TABLE_STATE_CHANGE,
    PROPOSAL_FILTERS_CHANGE,
    PROPOSAL_CLOSED
} from '../../actions/proposals';
import { getSinceLastMonthPeriod } from '../../../utils/DateUtils';

const defaultState = {
    isFetching: false,
    proposals: [],
    tableState: undefined,
    filters: {
        ...getSinceLastMonthPeriod(),
        status: 'active',
        tags: []
    },
    activeStatus: 'active',
    requireRefresh: true
}

export const proposals = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case PROPOSAL_TABLE_STATE_CHANGE: return ({
            ...state,
            tableState: action.state
        })
        case PROPOSAL_FILTERS_CHANGE: return ({
            ...state,
            filters: action.filters,
            isFetching: true
        })
        case FETCH_PROPOSALS: {
            return {
                ...state,
                isFetching: true,
                requireRefresh: false
            }
        }
        case FETCH_PROPOSALS_FULFILLED: {
            return {
                ...state,
                isFetching: false,
                proposals: [...action.proposals],
                activeStatus: action.status
            }
        }
        case FETCH_PROPOSALS_REJECTED: {
            return {
                ...state,
                isFetching: false
            }
        }
        case PROPOSAL_CREATED: {
            return {
                ...state,
                proposals: [...state.proposals, { ...action.proposal }],
                requireRefresh: true
            }
        }
        case PROPOSAL_CLOSED: {
            const proposals = [...state.proposals];
            const idx = proposals.findIndex(m => m.id === action.id);

            if (idx === -1) {
                return state;
            }

            proposals.splice(idx, 1);

            return {
                ...state,
                proposals,
                requireRefresh: true
            }
        }
        case PROPOSAL_CHANGED: {
            if (state.filters.status !== 'active') {
                return state;
            }

            const proposals = [...state.proposals];
            const idx = proposals.findIndex(m => m.id === action.proposal.id);

            if (idx < 0) {
                proposals.push(action.proposal);
            } else {
                proposals[idx] = action.proposal;
            }

            return {
                ...state,
                proposals
            }
        }
        case PROPOSAL_DELETED: {
            const proposals = [...state.proposals];
            const idx = proposals.findIndex(m => m.id === action.id);

            if (idx === -1) {
                return state;
            }

            proposals.splice(idx, 1);

            return {
                ...state,
                proposals
            }
        }
        default: {
            return state;
        }
    }
};