import { Popup } from 'semantic-ui-react';

const articleTags = {
    CUSTOMER: 'customer',
    OBJECT: 'object',
    STANDARD: 'standard',
    PRICE_LIST: 'price_list',
    AGREEMENT: 'agreement',
    CUSTOMER_GROUP_DISCOUNT: 'customer_group_discount'
}

const WorkOrderArticleTag = ({ tag }) => {
    if (!tag) {
        return (
            <div className="ui tiny circular green label" style={{ margin: '0 10px', opacity: 0 }}>-</div>
        )
    }

    if (tag === articleTags.CUSTOMER) {
        return (
            <div className="ui tiny circular green label" style={{ margin: '0 10px' }}>A</div>
        )
    }

    if (tag === articleTags.OBJECT) {
        return (
            <div className="ui tiny circular green label" style={{ margin: '0 10px' }}>A</div>
        )
    }

    if (tag === articleTags.AGREEMENT) {
        return (
            <div className="ui tiny circular green label" style={{ margin: '0 10px' }}>A</div>
        )
    }

    if (tag === articleTags.CUSTOMER_GROUP_DISCOUNT) {
        return (
            <div className="ui tiny circular green label" style={{ margin: '0 10px' }}>A</div>
        )
    }

    if (tag.startsWith(articleTags.PRICE_LIST)) {
        const [_, ...rest] = tag.split(',');
        const title = rest.join(',');

        return (
            <Popup trigger={
                <div className="ui tiny circular label"
                    style={{
                        margin: '0 10px',
                        backgroundColor: 'var(--color-accent)'
                    }}>
                    P
                </div>
            }>
                {title}
            </Popup>
        )
    }



    return (
        <div className="ui tiny circular green label" style={{ margin: '0 10px', opacity: 0 }}>-</div>
    );
}

export default WorkOrderArticleTag;