import React from 'react';
import Main from './components/main';
import Login from './components/login';
import { connect } from 'react-redux';
import { withRouter, Switch, Route } from 'react-router-dom';
import { fetchUsers } from 'data/actions/users';
import { validateSession } from 'data/actions/common/authActions';
import Loader from './components/common/loader';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import './utils/moment';
import { mapLanguageToMomentLocale } from './utils/moment';
import { setUserUiSetting, UI_PROP_LANG } from './utils/UiUtils';
import './print.css';
import './App.css';
import GoogleSuccessLogin from './components/login/google';
import MicrosoftSuccessLogin from 'components/login/microsoft';

class App extends React.Component {

  componentDidMount() {
    const { validateSession, language } = this.props;

    validateSession();

    this.toggleLanguage(language);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { language, userLanguage } = newProps;
    if (language !== this.props.language) {
      this.toggleLanguage(language);
    }

    if (userLanguage !== this.props.userLanguage) {
      moment.locale(mapLanguageToMomentLocale(userLanguage));
    }
  }

  toggleLanguage = lang => {
    const { userLanguage } = this.props;
    this.props.i18n.changeLanguage(lang);

    if (userLanguage) {
      setUserUiSetting(UI_PROP_LANG, userLanguage);
    }

  }

  render() {

    if (!this.props.isSessionValidated) {
      return (
        <div style={{
          width: '100%',
          height: '100%'
        }}>
          <img src={'https://d3vp3ww4kx514t.cloudfront.net/assets/trukks/Trukks_Logo_Master-1.svg'}
            alt="Trukks"
            style={{ maxWidth: 200, display: 'block', margin: '200px auto auto auto' }} />
          <Loader text="Loading Trukks..." />
        </div>
      )
    }

    return (
      <React.Fragment>
        <div className="man-print-content">
          {this.props.printContent}
        </div>

        <div className="App">

          {this.props.instance && this.props.instance.displayName &&
            <Helmet>
              <title>{`Trukks | ${this.props.instance.displayName}`}</title>
            </Helmet>
          }

          {!this.props.isLoggedIn ?
            <Switch>
              <Route exact path="/google/success"
                render={() => <GoogleSuccessLogin />} />
              <Route exact path="/microsoft/success"
                render={() => <MicrosoftSuccessLogin />} />
              <Route path="*"
                render={() => <Login toggleLanguage={this.toggleLanguage} />} />
            </Switch>
            :
            <Main sidebarOpen={this.props.sidebarOpen} />
          }
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isSessionValidated: state.ui.isSessionValidated,
  isLoggedIn: !!state.auth.user,
  sidebarOpen: state.ui.sidebarOpen,
  language: state.ui.language,
  userLanguage: state.ui.userLanguage,
  instance: state.auth.instance,
  printContent: state.ui.printContent,
  users: state.users.users
});

const mapDispatchToProps = dispatch => ({
  validateSession: () => dispatch(validateSession()),
  fetchUsers: () => dispatch(fetchUsers())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
