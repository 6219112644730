export const se_orebrolarm = {
    ui: {
        SETTINGS_DEVIATIONS: 'Avvikelser/Reklamationer',
         FILTER_DEVIATIONS: 'Filter för Avvikelser/Reklamationer',
         DEVIATION_CREATE_NEW: 'Ny Avvikelse/Reklamation',
         WORKORDER_CREATE_DEVIATION: 'Skapa Avvikelse/Reklamation',
          DEVIATIONS: 'Avvikelse/Reklamation',
           CLOSE_DEVIATION: 'Stäng Avvikelse/Reklamation',
           DEVIATION_DASHBOARD_GO_TO_LIST: 'Visa lista på alla Avvikelser/Reklamationer',
           DEVIATION_DASHBOARD_BY_CATEGORY: 'Avvikelse/Reklamation per kategori',
           DEVIATION_DASHBOARD_BY_RELATION: 'Mest frekventa Avvikelse/Reklamation',
           DEVIATION_DASHBOARD_LATEST: 'Senaste Avvikelse/Reklamation',        
    },

    messages: {
        DEVIATION_SUCCESSFULLY_CLOSED: 'Avvikelse/Reklamation stängdes korrekt',
    },

    acl: {
    PERMISSION_DEVIATIONS_READ: 'Se Avvikelse/Reklamation',
    PERMISSION_DEVIATIONS_EDIT: 'Ändra Avvikelse/Reklamation',
        PERMISSION_DEVIATIONS_CREATE: 'Skapa Avvikelse/Reklamation',
        PERMISSION_DEVIATIONS_CLOSE: 'Stänga Avvikelse/Reklamation',
        PERMISSION_DEVIATIONS_DELETE: 'Radera Avvikelse/Reklamation',
    },

    form: { 

    },


}