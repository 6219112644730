export const UPDATE_APP_MODULE_PROPERTY = 'UPDATE_APP_MODULE_PROPERTY';
export const UPDATE_APP_MODULE_STATE = 'UPDATE_APP_MODULE_STATE';
export const UPDATE_INSTANCE_APP_MODULE_DYNAMIC_CONF =
    'UPDATE_INSTANCE_APP_MODULE_DYNAMIC_CONF';


export const updateAppModuleProperty = (module, name, value) => ({
    type: UPDATE_APP_MODULE_PROPERTY,
    module,
    name,
    value
});

export const updateAppModuleState = (module, state) => ({
    type: UPDATE_APP_MODULE_STATE,
    module,
    state
});

export const updateInstanceAppModuleDynamicConf = (module, conf) => ({
    type: UPDATE_INSTANCE_APP_MODULE_DYNAMIC_CONF,
    module,
    conf,
});

