import React, { useState } from 'react';
import { Button, Icon, Input, Popup } from 'semantic-ui-react';
import FlatButton from '../flat-button/FlatButton';
import './AddLinkPopup.css';

export interface AddLinkPopupTranslations {
    name: string;
    link: string;
    cancel: string;
    add: string;
    addLink: string;
    requiredFields: string;
}

export interface LinkProps {
    name?: string;
    link?: string;
}

interface Props {
    trigger?: any;
    name?: string;
    link?: string;
    defaultOpen?: boolean
    onSave: (props: LinkProps) => Promise<void>;
    translations: Record<keyof AddLinkPopupTranslations, string>;
}

const AddLinkPopup = (props: Props) => {
    const [linkProps, setLinkProps] = useState<LinkProps>({
        name: props.name,
        link: props.link,
    });
    const [error, setError] = useState<string | undefined>(undefined);
    const [open, setOpen] = useState<boolean>(props.defaultOpen || false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setLinkProps(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleNewLink = async () => {
        if (!linkProps.link || !linkProps.name) {
            setError(props.translations.requiredFields);
        }
        await props.onSave(linkProps);
        setOpen(!open);
    };

    const PopupTrigger = () => (
        <FlatButton
            title={props.translations.addLink}
            icon="external alternate"
            style={{
                textDecoration: 'none',
                fontSize: '1.2rem',
            }}
            onClick={() => setOpen(!open)}>
            <Icon name="plus" />
        </FlatButton>
    );

    return (
        <Popup open={open} trigger={props.trigger || <PopupTrigger />}>
            <div className="man-add-link-popup-container">
                <Input
                    name="name"
                    value={linkProps.name}
                    placeholder={props.translations.name}
                    onChange={handleChange}
                />
                <Input
                    name="link"
                    value={linkProps.link}
                    placeholder={props.translations.link}
                    onChange={handleChange}
                />
                {error && (
                    <p className="man-add-link-error">
                        {props.translations.requiredFields}
                    </p>
                )}
                <div className="man-add-link-popup-actions">
                    <Button onClick={() => setOpen(!open)}>
                        {props.translations.cancel}
                    </Button>
                    <Button
                        className="man-button-primary"
                        onClick={handleNewLink}>
                        {props.translations.add}
                    </Button>
                </div>
            </div>
        </Popup>
    );
};

export default AddLinkPopup;
