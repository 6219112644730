import Alert from 'components/common/alert';
import LocalizedDropdown from 'components/common/localized-dropdown';
import { useInstanceData } from 'components/common/use-instance-data';
import WorkOrderArticleTag from 'components/main/common/workorder-article-tag';
import { useArticleGroups } from 'components/main/settings/tabs/articles/article-groups/useArticleGroups';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Form, Popup } from 'semantic-ui-react';
import {
    mapArticleGroupToOption,
    mapArticleToOption,
} from 'utils/DropdownMappers';
import { appModules as modules } from 'utils/constants/appModules';
import { fetchArticles } from '../../../../../data/actions/articles';
import articleService from '../../../../../data/services/article/articleService';
import { defaultSort } from '../../../../../utils/ArrayUtils';
import {
    calculateDiscountedPrice,
    calculateMargin,
    calculateTotalPriceVat,
} from '../../../../../utils/MathUtils';
import {
    getDiscountText,
    parseDiscount,
    validateDiscount,
} from '../../../../../utils/ReportRowUtils';
import {
    formatPercent,
    formatPrice,
    formatPriceSum,
    generateId,
    generateMaxIndex,
} from '../../../../../utils/StringUtils';
import { STATUS_ACTIVE, articleTypes } from '../../../../../utils/constants';
import { discountTypes } from '../../../../../utils/constants/discountTypes';
import FlatButton from '../../../../common/flat-button';
import Icon from '../../../../common/icon';
import PopupInput from '../../../../common/popup-input/PopupInput';
import { Col, Row } from '../../../../common/ui';
import ValidatableInput from '../../../../common/validatable-input/ValidatableInput';
import './ProposalArticles.css';
import ProposalArticlesContainerMobile from './ProposalArticlesContainerMobile';
import CostCenterPicker from './erp-reference-picker/CostCenterPicker';
import StockPointPicker from './erp-reference-picker/StockPointPicker';
import ProjectInScopeEditor from './scope-editor';
import ProjectInScopeLabel from './scope-editor/ProjectInScopeLabel';

const ArticleSelector = ({
    t,
    articleGroupsEnabled,
    articleId,
    onArticleChange,
    articles,
}) => {
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [selectedArticleGroups, setSelectedArticleGroups] = useState([]);
    const { articleGroups, isFetching } = useArticleGroups();

    const filterArticleGroupSelected = article => {
        if (!selectedArticleGroups || selectedArticleGroups.length === 0) {
            return true;
        }

        const found = selectedArticleGroups.findIndex(
            g => article.articleGroupId === g
        );

        return found > -1;
    };

    return (
        <Form
            style={{
                width: 400,
                maxWidth: 400,
            }}>
            <Form.Field>
                <div className="man-flex-row man-flex-row-sb">
                    <label
                        style={{
                            fontWeight: '700',
                        }}>
                        {optionsOpen
                            ? t('ui:SELECT_ARTICLE_GROUPS')
                            : t('ui:SELECT_ARTICLE')}
                    </label>
                    {articleGroupsEnabled && (
                        <div
                            style={{
                                marginLeft: 20,
                            }}>
                            {optionsOpen ? (
                                <a
                                    onClick={() => setOptionsOpen(false)}
                                    style={{
                                        fontWeight: 700,
                                    }}>
                                    {t('ui:SELECT_ARTICLE_MORE_OPTIONS_BACK')}
                                    <Icon name="angle double left" />
                                </a>
                            ) : (
                                <a
                                    onClick={() => setOptionsOpen(true)}
                                    style={{
                                        fontWeight: 700,
                                    }}>
                                    {t('ui:SELECT_ARTICLE_MORE_OPTIONS')}
                                    <Icon name="angle double right" />
                                </a>
                            )}
                        </div>
                    )}
                </div>
                {optionsOpen ? (
                    <LocalizedDropdown
                        fluid
                        selection
                        search
                        multiple
                        defaultOpen={true}
                        value={selectedArticleGroups}
                        loading={isFetching}
                        onChange={(e, { value }) =>
                            setSelectedArticleGroups(value)
                        }
                        options={articleGroups.map(mapArticleGroupToOption)}
                    />
                ) : (
                    <div>
                        <LocalizedDropdown
                            fluid
                            selection
                            search
                            defaultOpen={true}
                            value={articleId}
                            onChange={(e, { value }) => onArticleChange(value)}
                            options={articles
                                .filter(a => a.status === STATUS_ACTIVE)
                                .filter(filterArticleGroupSelected)
                                .map(mapArticleToOption)}
                        />
                        {selectedArticleGroups.length > 0 && (
                            <Alert
                                style={{
                                    display: 'block',
                                }}
                                type="warning"
                                message={t(
                                    'SELECT_ARTICLE_FILTERING_IS_ACTIVE'
                                )}
                            />
                        )}
                    </div>
                )}
            </Form.Field>
        </Form>
    );
};

const ProposalRow = React.memo(
    ({
        t,
        idx,
        totalRows,
        customerId,
        articles,
        row,
        allRows,
        onChange,
        onDelete,
        currency,
        editable,
        allowNegativePrices,
        articleGroupsEnabled,
        includeSupplierInventoryArticles,
        isProjectScopeEnabled,
        purchasePriceEditingDisabled,
        stockPointEnabled,
        costCenterEnabled,
        monthlyPriceEnabled,
    }) => {
        return (
            <Draggable
                key={`${idx}-${row.id}`}
                draggableId={`${idx}-${row.id}`}
                index={idx}>
                {(provided, snapshot) => (
                    <tr
                        className={
                            row.isSummaryRow
                                ? 'man-proposal-articles-summary-row'
                                : ''
                        }
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                            background: snapshot.isDragging
                                ? 'var(--color-active)'
                                : 'initial',
                            boxShadow: snapshot.isDragging
                                ? 'var(--shadow-default)'
                                : 'initial',
                            ...provided.draggableProps.style,
                        }}>
                        <td className="man-proposal-drag-handle-container-col">
                            <div
                                {...provided.dragHandleProps}
                                className={`man-proposal-drag-handle-container${editable ? '' : ' man-disabled'
                                    }`}>
                                <Icon name="sort"></Icon>
                            </div>
                        </td>
                        {!(row.comment === null || row.comment === undefined) ? (
                            <CommentRow
                                row={row}
                                t={t}
                                allRows={allRows}
                                onChange={onChange}
                                editable={editable}
                                currency={currency}
                                isProjectScopeEnabled={isProjectScopeEnabled}
                                stockPointEnabled={stockPointEnabled}
                                costCenterEnabled={costCenterEnabled}
                                monthlyPriceEnabled={monthlyPriceEnabled}
                            />
                        ) : (
                            <ArticleRow
                                row={row}
                                t={t}
                                customerId={customerId}
                                articles={articles}
                                onChange={onChange}
                                currency={currency}
                                editable={editable}
                                allowNegativePrices={allowNegativePrices}
                                articleGroupsEnabled={articleGroupsEnabled}
                                includeSupplierInventoryArticles={
                                    includeSupplierInventoryArticles
                                }
                                isProjectScopeEnabled={isProjectScopeEnabled}
                                stockPointEnabled={stockPointEnabled}
                                costCenterEnabled={costCenterEnabled}
                                purchasePriceEditingDisabled={purchasePriceEditingDisabled}
                                monthlyPriceEnabled={monthlyPriceEnabled}
                            />
                        )}
                        <td className="man-proposal-row-basic">
                            {editable && (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: 'row',
                                    }}>
                                    <FlatButton
                                        icon="remove"
                                        className="man-negative"
                                        title={t('ui:REMOVE_ROW')}
                                        onClick={onDelete}
                                    />
                                </div>
                            )}
                        </td>
                    </tr>
                )}
            </Draggable>
        );
    }
);

const ArticleRow = ({
    t,
    row,
    onChange,
    articles,
    currency,
    customerId,
    editable,
    allowNegativePrices,
    includeSupplierInventoryArticles,
    isProjectScopeEnabled,
    articleGroupsEnabled,
    purchasePriceEditingDisabled,
    stockPointEnabled,
    costCenterEnabled,
    monthlyPriceEnabled,
}) => {
    const [loading, setLoading] = useState(true);

    const handleArticleChange = async article => {
        try {
            setLoading(true);

            const { salesPrice, priceType, discountValue, discountType } =
                await articleService.fetchPrices(article.id, customerId);

            let articleMonthlyPrice = null;

            if (article.securitasArticle && article.securitasMonthlyCompensation) {
                articleMonthlyPrice = article.securitasMonthlyCompensation;
            }

            const newRow = {
                ...row,
                articleId: article.id,
                articleTitle: article.title,
                articleDescription: article.description,
                articlePurchasePrice: article.purchasePrice,
                articlePrice: salesPrice,
                articleMonthlyPrice,
                articleTotalPrice: 0,
                articleAmount: 1,
                articleUnit: article.unit,
                articlePriceTag: priceType,
                ref: article.ref
            };

            if (discountValue && discountValue > 0) {
                newRow.articleDiscountType = discountType;
                newRow.articleDiscountValue = discountValue;
            }

            onChange(newRow);
        } catch {
            onChange({
                ...row,
                articleId: article.id,
                articleTitle: article.title,
                articleDescription: article.description,
                articlePurchasePrice: article.purchasePrice,
                articlePrice: article.price,
                articleTotalPrice: 0,
                articleAmount: 1,
                articleUnit: article.unit,
                ref: article.ref
            });
        } finally {
            setLoading(false);
        }
    };

    const handleDiscountChange = value => {
        const discount = parseDiscount(value);

        onChange({
            ...row,
            articleDiscountValue: discount.discountValue,
            articleDiscountType: discount.discountType,
        });
    };

    const handleMonthlyDiscountChange = value => {
        const discount = parseDiscount(value);

        onChange({
            ...row,
            articleMonthlyDiscountValue: discount.discountValue,
            articleMonthlyDiscountType: discount.discountType,
        });
    };

    const handleDescriptionChange = value => {
        onChange({
            ...row,
            articleDescription: value,
        });
    };

    const isArticleFreetext = id => {
        if (articles.length === 0 || id == null) {
            return false;
        }

        const a = articles.find(a => a.id === id);

        return a && a.type === articleTypes.FREETEXT;
    };

    return (
        <React.Fragment>
            {editable ? (
                <React.Fragment>
                    {row.articleTitle ? (
                        <td colSpan={2} className="man-proposal-row-basic">
                            {isArticleFreetext(row.articleId) && (
                                <PopupInput
                                    trigger={
                                        <FlatButton
                                            className="man-noprint"
                                            icon="pencil"
                                            title={t(
                                                'ui:MODIFY_ARTICLE_DESCRIPTION'
                                            )}
                                            onClick={e => e.stopPropagation()}
                                            style={{
                                                padding: 4,
                                            }}
                                        />
                                    }
                                    value={row.articleDescription}
                                    name="title"
                                    type="text"
                                    onSave={(name, value) =>
                                        handleDescriptionChange(value)
                                    }></PopupInput>
                            )}
                            {`${row.articleTitle} - ${row.articleDescription}`}
                            {row.ref?.type === 'supplier-inventory' && (
                                <Popup
                                    trigger={
                                        <div
                                            style={{
                                                float: 'right',
                                            }}
                                            className="ui tiny circular label yellow">
                                            B
                                        </div>
                                    }>
                                    {row.ref?.supplierId &&
                                        t(`suppliers:${row.ref?.supplierId}`)}
                                </Popup>
                            )}
                            {row.ref?.name === 'supplier.upstream' && (
                                <Popup
                                    trigger={
                                        <div
                                            className={`ui circular tiny label trks-bg-accent trks-hoverable`}>
                                            {`${t(
                                                'ui:WORK_ORDER_ARTICLE_FROM_SUPPLIER_SHORT'
                                            )}: ${t(
                                                `suppliers:${row.ref?.upstreamRef?.name}`
                                            )}`}
                                        </div>
                                    }>
                                    {`${t('form:ID')}: ${row.ref?.upstreamRef?.orderId
                                        }`}
                                </Popup>
                            )}
                        </td>
                    ) : (
                        <Popup
                            on="click"
                            positionFixed={true}
                            defaultOpen={row.isNew}
                            trigger={
                                <td colSpan={2}>
                                    <div>{`${t('ui:SELECT_ARTICLE')}`}</div>
                                </td>
                            }>
                            <ArticleSelector
                                t={t}
                                articleGroupsEnabled={articleGroupsEnabled}
                                articleId={row.articleId}
                                onArticleChange={handleArticleChange}
                                articles={articles}
                            />
                        </Popup>
                    )}
                </React.Fragment>
            ) : (
                <td colSpan={2}>
                    {`${row.articleTitle} - ${row.articleDescription}`}
                </td>
            )}
            {row.articleId ? (
                <React.Fragment>
                    {editable ? (
                        <PopupInput
                            name="articleAmount"
                            type="number"
                            value={row.articleAmount || 0}
                            onSave={(name, value) =>
                                onChange({ ...row, articleAmount: value })
                            }
                            validate={value => {
                                if (value < 0) {
                                    return 'messages:AMOUNT_CANT_BE_NEGATIVE';
                                }

                                return null;
                            }}
                            trigger={
                                <td>
                                    {`${row.articleAmount || 0} ${row.articleUnit
                                        ? `(${row.articleUnit})`
                                        : ''
                                        }`}
                                </td>
                            }></PopupInput>
                    ) : (
                        <td>
                            {`${row.articleAmount || 0} ${row.articleUnit ? `(${row.articleUnit})` : ''
                                }`}
                        </td>
                    )}
                </React.Fragment>
            ) : (
                <td className="man-proposal-row-basic"></td>
            )}
            {costCenterEnabled && <>
                {row.articleId ? (
                    <React.Fragment>
                        {editable ? (
                            <CostCenterPicker
                                search
                                wrapAsColumn
                                onChange={val => onChange({
                                    ...row, erpRef: {
                                        ...(row.erpRef || {}),
                                        costCenter: { value: val }
                                    }
                                })}
                                value={row.erpRef?.costCenter?.value} />
                        ) : (
                            <td>
                                {row.erpRef?.costCenter?.value || ''}
                            </td>
                        )}
                    </React.Fragment>
                ) : (
                    <td className="man-proposal-row-basic"></td>
                )}
            </>}
            {stockPointEnabled && <>
                {row.articleId ? (
                    <React.Fragment>
                        {editable ? (
                            <StockPointPicker
                                wrapAsColumn
                                onChange={val => onChange({
                                    ...row, erpRef: {
                                        ...(row.erpRef || {}),
                                        stockPoint: { value: val }
                                    }
                                })}
                                value={row.erpRef?.stockPoint?.value} />
                        ) : (
                            <td>
                                {row.erpRef?.stockPoint?.value || ''}
                            </td>
                        )}
                    </React.Fragment>
                ) : (
                    <td className="man-proposal-row-basic"></td>
                )}
            </>}
            {row.articleId ? (
                <React.Fragment>
                    {editable && !purchasePriceEditingDisabled ? (
                        <PopupInput
                            name="articlePurchasePrice"
                            type="number"
                            value={row.articlePurchasePrice || 0}
                            onSave={(name, value) =>
                                onChange({
                                    ...row,
                                    articlePurchasePrice: value,
                                })
                            }
                            validate={value => {
                                if (value < 0) {
                                    return 'messages:AMOUNT_CANT_BE_NEGATIVE';
                                }

                                return null;
                            }}
                            trigger={
                                <td>
                                    {`${formatPrice(
                                        row.articlePurchasePrice || 0
                                    )}`}
                                </td>
                            }></PopupInput>
                    ) : (
                        <td>
                            {`${formatPrice(row.articlePurchasePrice || 0)}`}
                        </td>
                    )}
                </React.Fragment>
            ) : (
                <td className="man-proposal-row-basic"></td>
            )}
            {row.articleId ? (
                <React.Fragment>
                    {editable ? (
                        <PopupInput
                            name="articlePrice"
                            type="number"
                            value={row.articlePrice || 0}
                            onSave={(name, value) =>
                                onChange({ ...row, articlePrice: value })
                            }
                            validate={value => {
                                if (value < 0 && !allowNegativePrices) {
                                    return 'messages:AMOUNT_CANT_BE_NEGATIVE';
                                }

                                return null;
                            }}
                            trigger={
                                <td>
                                    {formatPrice(
                                        calculateDiscountedPrice(
                                            row.articlePrice,
                                            row.articleAmount,
                                            row.articleDiscountType,
                                            row.articleDiscountValue
                                        ),
                                        currency.symbol
                                    )}

                                    <WorkOrderArticleTag
                                        tag={row.articlePriceTag}
                                    />

                                    <div
                                        style={{
                                            fontSize: '0.88em',
                                            opacity: 0.7,
                                        }}>
                                        {getDiscountText(
                                            row.articleDiscountType,
                                            row.articleDiscountValue,
                                            currency.symbol
                                        )}
                                    </div>
                                </td>
                            }>
                            <ValidatableInput
                                onChange={(name, value) =>
                                    handleDiscountChange(value)
                                }
                                placeholder={`${t('ui:DISCOUNT_PLACEHOLDER')} ${currency.symbol
                                    }`}
                                validate={validateDiscount}
                                value={`${row.articleDiscountValue
                                    ? row.articleDiscountValue
                                    : ''
                                    }${row.articleDiscountType &&
                                        row.articleDiscountType ===
                                        discountTypes.PERCENTAGE
                                        ? '%'
                                        : ''
                                    }`}
                            />
                        </PopupInput>
                    ) : (
                        <td>
                            {formatPrice(
                                calculateDiscountedPrice(
                                    row.articlePrice,
                                    row.articleAmount,
                                    row.articleDiscountType,
                                    row.articleDiscountValue
                                ),
                                currency.symbol
                            )}

                            <WorkOrderArticleTag tag={row.articlePriceTag} />

                            <div
                                style={{
                                    fontSize: '0.88em',
                                    opacity: 0.7,
                                }}>
                                {getDiscountText(
                                    row.articleDiscountType,
                                    row.articleDiscountValue,
                                    currency.symbol
                                )}
                            </div>
                        </td>
                    )}
                </React.Fragment>
            ) : (
                <td className="man-proposal-row-basic"></td>
            )}

            {monthlyPriceEnabled &&

                <>
                    {row.articleId ? (
                        <React.Fragment>
                            {editable ? (
                                <PopupInput
                                    name="articleMonthlyPrice"
                                    type="number"
                                    value={row.articleMonthlyPrice || 0}
                                    onSave={(name, value) =>
                                        onChange({ ...row, articleMonthlyPrice: value })
                                    }
                                    validate={value => {
                                        if (value < 0 && !allowNegativePrices) {
                                            return 'messages:AMOUNT_CANT_BE_NEGATIVE';
                                        }

                                        return null;
                                    }}
                                    trigger={
                                        <td>
                                            {formatPrice(
                                                calculateDiscountedPrice(
                                                    row.articleMonthlyPrice,
                                                    row.articleAmount,
                                                    row.articleMonthlyDiscountType,
                                                    row.articleMonthlyDiscountValue
                                                ),
                                                currency.symbol
                                            )}

                                            <div
                                                style={{
                                                    fontSize: '0.88em',
                                                    opacity: 0.7,
                                                }}>
                                                {getDiscountText(
                                                    row.articleMonthlyDiscountType,
                                                    row.articleMonthlyDiscountValue,
                                                    currency.symbol
                                                )}
                                            </div>
                                        </td>
                                    }>
                                    <ValidatableInput
                                        onChange={(name, value) =>
                                            handleMonthlyDiscountChange(value)
                                        }
                                        placeholder={`${t('ui:DISCOUNT_PLACEHOLDER')} ${currency.symbol
                                            }`}
                                        validate={validateDiscount}
                                        value={`${row.articleMonthlyDiscountValue
                                            ? row.articleMonthlyDiscountValue
                                            : ''
                                            }${row.articleMonthlyDiscountType &&
                                                row.articleMonthlyDiscountType ===
                                                discountTypes.PERCENTAGE
                                                ? '%'
                                                : ''
                                            }`}
                                    />
                                </PopupInput>
                            ) : (
                                <td>
                                    {formatPrice(
                                        calculateDiscountedPrice(
                                            row.articleMonthlyPrice,
                                            row.articleAmount,
                                            row.articleMonthlyDiscountType,
                                            row.articleMonthlyDiscountValue
                                        ),
                                        currency.symbol
                                    )}

                                    <div
                                        style={{
                                            fontSize: '0.88em',
                                            opacity: 0.7,
                                        }}>
                                        {getDiscountText(
                                            row.articleMonthlyDiscountType,
                                            row.articleMonthlyDiscountValue,
                                            currency.symbol
                                        )}
                                    </div>
                                </td>
                            )}
                        </React.Fragment>
                    ) : (
                        <td className="man-proposal-row-basic"></td>
                    )}
                </>

            }
            {(isProjectScopeEnabled && row.articleId) && (
                <React.Fragment>
                    {editable ? (
                        <Popup
                            on='click'
                            trigger={
                                <td>
                                    <ProjectInScopeLabel
                                        value={row.isInProjectScope}
                                    />
                                </td>
                            }>
                            <ProjectInScopeEditor
                                value={row.isInProjectScope}
                                onChange={value =>
                                    onChange({
                                        ...row,
                                        isInProjectScope: value,
                                    })
                                }
                            />
                        </Popup>
                    ) : (
                        <td>
                            <ProjectInScopeLabel value={row.isInProjectScope} />
                        </td>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const CommentRow = ({ t, row, onChange, editable, currency, isProjectScopeEnabled, stockPointEnabled, costCenterEnabled, monthlyPriceEnabled }) => {
    let cols = 5;
    isProjectScopeEnabled && cols++;
    costCenterEnabled && cols++;
    stockPointEnabled && cols++;
    monthlyPriceEnabled && cols++;

    if (!editable) {

        return (
            <td colSpan={cols} className="man-ui-wrap-newline">
                <b>{`${row.comment}`}</b>
            </td>
        );
    }

    return (
        <>
            <PopupInput
                name="comment"
                type="textarea"
                defaultOpen={row.isNew}
                value={row.comment === t('ui:ADD_COMMENT') ? '' : row.comment}
                onSave={(name, value) => onChange({ ...row, comment: value })}
                trigger={
                    <td
                        colSpan={row.isSummaryRow ? 2 : cols}
                        className="man-ui-wrap-newline">
                        <b>{`${row.comment}`}</b>
                    </td>
                }></PopupInput>

            {row.isSummaryRow && (
                <>
                    <td></td>
                    {costCenterEnabled && <td></td>}
                    {stockPointEnabled && <td></td>}
                    <td>
                        <b>
                            {formatPriceSum(
                                row.summaryPurchasePrice,
                                currency.symbol
                            )}
                        </b>
                    </td>
                    {isProjectScopeEnabled && <td></td>}
                    <td>
                        <b>
                            {formatPriceSum(
                                row.summarySalesPrice,
                                currency.symbol
                            )}
                        </b>
                    </td>

                    {monthlyPriceEnabled && <td></td>}
                </>
            )}
        </>
    );
};

const ProposalArticlesContainer = ({
    t,
    customerId,
    articles,
    fetchArticles,
    currency,
    rows,
    onChange,
    editable,
    isSmallScreen,
    showTotalPriceIncludingVat,
    vatPercentage,
    allowNegativePrices,
    canShowSupplierInventoryArticles,
    isProjectScopeEnabled,
    summaryRowsEnabled,
    purchasePriceEditingDisabled,
    stockPointEnabled,
    costCenterEnabled,
    monthlyPriceEnabled,
}) => {
    const { appModules, hasAppModule } = useInstanceData();

    const includeSupplierInventoryArticles =
        (appModules[modules.CRM]?.custom?.proposal
            ?.includeSupplierInventoryArticles ||
            false) &&
        canShowSupplierInventoryArticles;

    const articleGroupsEnabled = hasAppModule(modules.ARTICLE_GROUPS);

    useEffect(() => {
        articles.length === 0 && fetchArticles();
    }, []);

    const setRows = onChange;

    const handleAddComment = () => {
        const row = {
            id: generateId(rows.map(r => r.id)),
            comment: t('ui:ADD_COMMENT'),
            sortOrder: generateMaxIndex(rows.map(r => r.sortOrder)),
            isNew: true,
        };

        const newRows = [...rows, row];

        setRows(newRows);

        return {
            row,
            idx: newRows.length - 1,
        };
    };

    const handleAddSummaryRow = () => {
        const row = {
            id: generateId(rows.map(r => r.id)),
            comment: t('ui:ADD_COMMENT'),
            isSummaryRow: true,
            sortOrder: generateMaxIndex(rows.map(r => r.sortOrder)),
            isNew: true,
        };

        const newRows = [...rows, row];

        setRows(newRows);

        return {
            row,
            idx: newRows.length - 1,
        };
    };

    const handleAddArticle = () => {
        const row = {
            id: generateId(rows.map(r => r.id)),
            sortOrder: generateMaxIndex(rows.map(r => r.sortOrder)),
            isNew: true,
            isInProjectScope: 1,
        };

        const newRows = [...rows, row];

        setRows(newRows);

        return {
            row,
            idx: newRows.length - 1,

        };
    };

    const handleRowChange = row => {
        const newRows = [...rows];

        const idx = newRows.findIndex(r => r.id === row.id);

        newRows[idx] = row;

        setRows(newRows);
    };

    const handleRowDelete = id => {
        const newRows = [...rows];

        const idx = newRows.findIndex(r => r.id === id);

        newRows.splice(idx, 1);

        setRows(newRows.map(r => ({ ...r, isNew: false })));
    };

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const newRows = reorder(
            rows,
            result.source.index,
            result.destination.index
        );

        setRows(newRows.map(r => ({ ...r, isNew: false })));
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        result.map((article, idx) => (article.sortOrder = idx + 1));
        return result;
    };

    const totalRows = rows.length;

    const totalPurchasePrice = rows
        .filter(({ articleId }) => articleId)
        .map(({ articlePurchasePrice, articleAmount }) =>
            calculateDiscountedPrice(articlePurchasePrice, articleAmount)
        )
        .reduce((a, b) => a + b, 0);

    const totalPrice = rows
        .filter(({ articleId }) => articleId)
        .map(
            ({
                articlePrice,
                articleAmount,
                articleDiscountType,
                articleDiscountValue,
            }) =>
                calculateDiscountedPrice(
                    articlePrice,
                    articleAmount,
                    articleDiscountType,
                    articleDiscountValue
                )
        )
        .reduce((a, b) => a + b, 0);


    const totalMonthlyPrice = rows
        .filter(({ articleId }) => articleId)
        .map(
            ({
                articleMonthlyPrice,
                articleAmount,
                articleMonthlyDiscountType,
                articleMonthlyDiscountValue,
            }) =>
                calculateDiscountedPrice(
                    articleMonthlyPrice,
                    articleAmount,
                    articleMonthlyDiscountType,
                    articleMonthlyDiscountValue,
                )
        )
        .reduce((a, b) => a + b, 0);

    const totalPriceIncludingVat = calculateTotalPriceVat(
        totalPrice,
        vatPercentage
    );

    const margin = calculateMargin(totalPurchasePrice, totalPrice);

    let summaryPurchasePrice = 0;
    let summarySalesPrice = 0;

    const mappedRows = rows.sort(defaultSort).map(row => {
        summaryPurchasePrice +=
            (row.articleAmount || 0) * (row.articlePurchasePrice || 0);
        summarySalesPrice += calculateDiscountedPrice(
            row.articlePrice,
            row.articleAmount,
            row.articleDiscountType,
            row.articleDiscountValue
        );

        const newRow = {
            ...row,
            summaryPurchasePrice,
            summarySalesPrice,
        };

        if (row.isSummaryRow) {
            summaryPurchasePrice = 0;
            summarySalesPrice = 0;
        }

        return newRow;
    });

    if (isSmallScreen) {
        return (
            <>
                <ProposalArticlesContainerMobile
                    editable={editable}
                    t={t}
                    onRowDelete={handleRowDelete}
                    onRowChange={handleRowChange}
                    onDragEnd={onDragEnd}
                    onAddArticleRow={handleAddArticle}
                    onAddCommentRow={handleAddComment}
                    onAddSummaryRow={handleAddSummaryRow}
                    rows={mappedRows}
                    articles={articles}
                    totalPurchasePrice={totalPurchasePrice}
                    totalPrice={totalPrice}
                    totalPriceIncludingVat={totalPriceIncludingVat}
                    showTotalPriceIncludingVat={showTotalPriceIncludingVat}
                    allowNegativePrices={allowNegativePrices}
                    articleGroupsEnabled={articleGroupsEnabled}
                    includeSupplierInventoryArticles={
                        includeSupplierInventoryArticles
                    }
                    summaryRowsEnabled={summaryRowsEnabled}
                    totalRows={totalRows}
                    margin={margin}
                    currency={currency}
                    customerId={customerId}
                    isProjectScopeEnabled={isProjectScopeEnabled}
                    purchasePriceEditingDisabled={purchasePriceEditingDisabled}
                    stockPointEnabled={stockPointEnabled}
                    costCenterEnabled={costCenterEnabled}
                    monthlyPriceEnabled={monthlyPriceEnabled}
                />
            </>
        );
    }

    return (
        <Row
            style={{
                width: '100%',
            }}>
            <Col>
                <div
                    className={`man-proposal-article-table-wrapper${editable ? '' : ' man-proposal-article-table-closed'
                        }`}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {provided => (
                                <table
                                    className="man-proposal-article-table ui table very basic celled compact unstackable small"
                                    ref={provided.innerRef}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th colSpan={2}>
                                                {t('form:ARTICLE')}
                                            </th>
                                            <th>{t('form:AMOUNT')}</th>
                                            {costCenterEnabled &&
                                                <th>{t('form:COST_CENTER')}</th>
                                            }
                                            {stockPointEnabled &&
                                                <th>{t('form:STOCK_POINT')}</th>
                                            }
                                            <th>{t('form:PURCHASE_PRICE')}</th>
                                            <th>{t('form:SALES_PRICE')}</th>
                                            {monthlyPriceEnabled &&
                                                <th>
                                                    {t('form:PROPOSAL_MONTHLY_PRICE')}
                                                </th>
                                            }
                                            {isProjectScopeEnabled && (
                                                <th>
                                                    <Icon
                                                        name="chart line"
                                                        style={{
                                                            fontSize: 24,
                                                        }}
                                                    />
                                                </th>
                                            )}
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mappedRows.map((r, idx) => (
                                            <ProposalRow
                                                t={t}
                                                key={`${r.id}-${r.sortOrder}-${idx}`}
                                                idx={idx}
                                                totalRows={totalRows}
                                                row={r}
                                                allRows={rows}
                                                articles={articles}
                                                customerId={customerId}
                                                currency={currency}
                                                editable={editable}
                                                onChange={handleRowChange}
                                                onDelete={() =>
                                                    handleRowDelete(r.id)
                                                }
                                                articleGroupsEnabled={
                                                    articleGroupsEnabled
                                                }
                                                allowNegativePrices={
                                                    allowNegativePrices
                                                }
                                                includeSupplierInventoryArticles={
                                                    includeSupplierInventoryArticles
                                                }
                                                isProjectScopeEnabled={
                                                    isProjectScopeEnabled
                                                }
                                                stockPointEnabled={stockPointEnabled}
                                                costCenterEnabled={costCenterEnabled}
                                                purchasePriceEditingDisabled={purchasePriceEditingDisabled}
                                                monthlyPriceEnabled={monthlyPriceEnabled}
                                            />
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={3}></td>
                                            <td></td>
                                            {costCenterEnabled && <td></td>}
                                            {stockPointEnabled && <td></td>}
                                            <td>
                                                {formatPriceSum(
                                                    totalPurchasePrice,
                                                    currency.symbol
                                                )}
                                            </td>
                                            {isProjectScopeEnabled && <td></td>}
                                            <td
                                                className={
                                                    totalPrice < 0
                                                        ? 'negative'
                                                        : ''
                                                }>
                                                {formatPriceSum(
                                                    totalPrice,
                                                    currency.symbol
                                                )}
                                                {showTotalPriceIncludingVat && (
                                                    <span className="trks-secondary-text">
                                                        {`(${t(
                                                            'ui:EXCLUDING_VAT'
                                                        )})`}
                                                    </span>
                                                )}
                                                <div className="trks-secondary-text">
                                                    {margin
                                                        ? ` ${t(
                                                            'ui:MARGIN_PERCENTAGE',
                                                            {
                                                                margin: formatPercent(
                                                                    margin
                                                                ),
                                                            }
                                                        )}`
                                                        : ''}
                                                </div>

                                                {showTotalPriceIncludingVat && (
                                                    <div
                                                        style={{
                                                            marginTop: 10,
                                                        }}>
                                                        {formatPriceSum(
                                                            totalPriceIncludingVat,
                                                            currency.symbol
                                                        )}
                                                        <span className="trks-secondary-text">
                                                            {`(${t(
                                                                'ui:INCLUDING_VAT'
                                                            )})`}
                                                        </span>
                                                    </div>
                                                )}
                                            </td>
                                            {monthlyPriceEnabled &&
                                                <td>
                                                    {formatPriceSum(totalMonthlyPrice, currency.symbol)}
                                                </td>
                                            }
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}>
                    {editable && summaryRowsEnabled && (
                        <a
                            style={{
                                padding: '6px',
                            }}
                            onClick={handleAddSummaryRow}>
                            {t('ui:ADD_SUMMARY_ROW')}
                        </a>
                    )}
                    {editable && (
                        <a
                            style={{
                                padding: '6px',
                            }}
                            onClick={handleAddComment}>
                            {t('ui:ADD_COMMENT_ROW')}
                        </a>
                    )}
                    {editable && (
                        <a
                            style={{
                                padding: '6px',
                            }}
                            onClick={() => handleAddArticle(generateId())}>
                            {t('ui:ADD_ARTICLE_ROW')}
                        </a>
                    )}
                </div>
            </Col>
        </Row>
    );
};

ProposalArticlesContainer.propTypes = {
    onChange: PropTypes.func.isRequired,
    rows: PropTypes.array,
    editable: PropTypes.bool,
    allowNegativePrices: PropTypes.bool,
    showTotalPriceIncludingVat: PropTypes.bool,
    vatPercentage: PropTypes.number,
    isProjectScopeEnabled: PropTypes.bool,
    purchasePriceEditingDisabled: PropTypes.bool,
    monthlyPriceEnabled: PropTypes.bool
};

ProposalArticlesContainer.defaultProps = {
    rows: [],
    editable: true,
    allowNegativePrices: false,
    showTotalPriceIncludingVat: false,
    vatPercentage: 25,
    isProjectScopeEnabled: false,
    purchasePriceEditingDisabled: false,
    monthlyPriceEnabled: false
};

const mapStateToProps = state => ({
    articles: state.articles.articles,
    currency: state.auth.instance.configuration.currency,
    isSmallScreen: state.ui.smallScreen,
});

const mapDispatchToProps = {
    fetchArticles,
};

export default withTranslation(['ui', 'suppliers'])(
    connect(mapStateToProps, mapDispatchToProps)(ProposalArticlesContainer)
);
