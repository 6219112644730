import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import Icon from '../../common/icon';
import { withPermissions } from '../../common/with-permissions/WithPermissions';
import { invoiceType } from '../../../utils/constants';
import { appModules as modules } from 'utils/constants/appModules';
import { useInstanceData } from 'components/common/use-instance-data';

const WorkOrderDataHeaderButton = ({ icon, onClick, text, className }) => (
    <div className={`ui item ${className ? ` ${className}` : ''}`}
        size="medium"
        onClick={onClick}>
        <Icon name={icon}></Icon>{text}
    </div>
)

const InvoiceExtraActions = ({ t, onPrint, extra, onApply }) => {

    const { appModules } = useInstanceData();

    const hasPdf = appModules[modules.UI_SETTINGS]?.custom?.invoice?.templateId || false

    return (
        <div className="man-workorder-extra-actions-container">

            <div>
                {extra && extra}
            </div>
            <div className="man-workorder-extra-actions-actions-container">
                {onPrint &&
                    <Dropdown
                        trigger={<Icon name="ellipsis vertical"></Icon>}
                        className="man-extra-actions-toggle"
                        item
                        simple
                        direction="left">
                        <Dropdown.Menu>
                            {onPrint &&
                                <WorkOrderDataHeaderButton
                                    icon="print"
                                    text={t('ui:PRINT')}
                                    onClick={onPrint} />}

                            {hasPdf &&
                                <WorkOrderDataHeaderButton
                                    icon="file alternate outline"
                                    text={t('ui:PDF')}
                                    onClick={() => onApply(invoiceType.PDF)} />
                            }
                        </Dropdown.Menu>
                    </Dropdown>}
            </div>
        </div>
    )
}

InvoiceExtraActions.propTypes = {
    onPrint: PropTypes.func,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    onInvoice: PropTypes.func,
    extra: PropTypes.any
}

export default withPermissions(withTranslation(['ui'])(InvoiceExtraActions));
