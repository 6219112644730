import React from 'react';
import LoginForm from './LoginForm';
import RegistrationFormContainer from './RegistrationFormContainer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { login } from '../../data/actions/common/authActions';
import './Login.css';
import * as qs from 'query-string';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import PasswordRecoveryFormContainer from './pw-recovery/PasswordRecoveryFormContainer';
import PasswordRecoveryRequestFormContainer from './pw-recovery/PasswordRecoveryRequestFormContainer';
import { getUserUiSetting, UI_PROP_LANG } from '../../utils/UiUtils';
import Loader from 'components/common/loader';
import Loadable from 'react-loadable';
import { MAP_AUTH_ERROR_TO_MESSAGE } from 'utils/constants/auth';

const MultiFactorCodeForm = Loadable({
    loader: () => import('./mfa'),
    loading: () => Loader,
});

const getErrorMessage = error => {
    const message = MAP_AUTH_ERROR_TO_MESSAGE[error];

    return message ?? 'messages:ERROR_MESSAGE_TITLE';
};

class Login extends React.Component {
    state = {
        type: 'default',
        rt: null,
        message: null,
    };

    componentDidMount() {
        const { lang, msg, rt, t, error } = qs.parse(
            this.props.location.search,
            { decode: false }
        );

        const s = this.props.location.search;

        let token;

        if (rt && lang) {
            token = decodeURI(
                s.substring(s.indexOf('?rt=') + 4, s.indexOf('&lang='))
            );
        }

        if (token) {
            this.setState({
                type: 'registration',
                rt: decodeURI(token),
            });
        } else {
            if (t && lang) {
                token = decodeURI(
                    s.substring(s.indexOf('?t=') + 3, s.indexOf('&lang='))
                );
            }

            if (token) {
                this.setState({
                    type: 'pw-recovery',
                    rt: decodeURI(token),
                });
            }
        }

        if (lang) {
            this.props.toggleLanguage(lang);
            if (msg) {
                this.setState({
                    message: this.props.t(`messages:${msg}`),
                });
            }
        } else {
            this.initLanguageFromCache(msg);
        }

        if (error) {
            this.setState({
                message: this.props.t(getErrorMessage(error)),
            });
        }
    }

    initLanguageFromCache = async msg => {
        const lang = await getUserUiSetting(UI_PROP_LANG, 'se');

        if (lang) {
            this.props.toggleLanguage(lang);
        }

        if (msg) {
            this.setState({
                message: this.props.t(`messages:${msg}`),
            });
        }
    };

    handleLogin = (email, password) => {
        this.setState({
            message: null,
            error: null,
        });

        const { push, location } = this.props.history;

        push({
            location,
            search: location.search || '',
            state: { from: '/login' },
        });

        this.props.login(email, password);
    };

    renderForm = () => {
        const {
            isLoading,
            error,
            t,
            mfa: { token, provider },
        } = this.props;

        if (this.state.type === 'registration') {
            return <RegistrationFormContainer rt={this.state.rt} t={t} />;
        }

        if (this.state.type === 'pw-recovery') {
            return <PasswordRecoveryFormContainer rt={this.state.rt} t={t} />;
        }

        if (this.state.type === 'pw-recovery-request') {
            return <PasswordRecoveryRequestFormContainer t={t} />;
        }

        if (token) {
            return (
                <MultiFactorCodeForm
                    t={t}
                    token={token}
                    error={error}
                    provider={provider}
                />
            );
        }

        return (
            <React.Fragment>
                <LoginForm
                    onSubmit={this.handleLogin}
                    isLoading={isLoading}
                    error={error}
                    message={this.state.message}
                    t={t}
                />

                <a
                    onClick={() =>
                        this.setState({ type: 'pw-recovery-request' })
                    }
                    style={{ margin: '10px 0', textAlign: 'right' }}>
                    {t('CANT_LOG_IN')}
                </a>
            </React.Fragment>
        );
    };

    render() {
        return (
            <div className="Login-Container">
                <div className="Login-Form-Container man-shadow">
                    <div className="Form-Container">
                        <div className="Logo-Container">
                            <img
                                src={
                                    'https://d3vp3ww4kx514t.cloudfront.net/assets/trukks/Trukks_Logo_Master-1.svg'
                                }
                                alt="Trukks"
                                style={{
                                    width: '45%',
                                    display: 'block',
                                    margin: 'auto',
                                    paddingBottom: '40px',
                                }}
                            />
                        </div>

                        {this.renderForm()}
                    </div>
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    toggleLanguage: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.auth.isLoading,
    error: state.auth.error,
    message: state.auth.message,
    mfa: state.auth.mfa,
});

const mapDispatchToProps = dispatch => ({
    login: (email, password) => dispatch(login(email, password)),
});

export default withTranslation(['form', 'messages', 'ui'])(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
);
