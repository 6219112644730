import customerService from '../../services/customer/customerService';

export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_CUSTOMERS_FULFILLED = 'FETCH_CUSTOMERS_FULFILLED';
export const FETCH_CUSTOMERS_REJECTED = 'FETCH_CUSTOMERS_REJECTED';
export const CUSTOMER_CHANGED = 'CUSTOMER_CHANGED';
export const CUSTOMER_TABLE_STATE_CHANGE = 'CUSTOMER_TABLE_STATE_CHANGE';
export const CUSTOMER_FILTER_CHANGE = 'CUSTOMER_FILTER_CHANGE';

const requestCustomers = () => ({ type: FETCH_CUSTOMERS });
const requestCustomersFulfilled = (customers) => ({ type: FETCH_CUSTOMERS_FULFILLED, customers });
const requestCustomersRejected = (error) => ({ type: FETCH_CUSTOMERS_REJECTED, error });

export const customerFilterChange = (filterName, value) => ({ type: CUSTOMER_FILTER_CHANGE, filterName, value })

export const customerTableStateChange = state => ({ type: CUSTOMER_TABLE_STATE_CHANGE, state });

export const customerChanged = (customer) => ({
    type: CUSTOMER_CHANGED,
    customer
});

export const fetchCustomers = () => async dispatch => {

    dispatch(requestCustomers());

    try {
        const customers = await customerService.fetch();
        dispatch(requestCustomersFulfilled(customers));
    } catch (err) {
        console.error(err);
        dispatch(requestCustomersRejected(err));
    }
};