export default {
    AUTH_DATA: 'authData',
    CACHE_UI_PROP: 'application',
    ACTIVE_TAB: 'activeTab',
    TABS: 'tabs',
    FILTERS: 'filters',
    SORT: 'sort',
    SIDEBAR_OPEN: 'sidebarOpen',
    CALENDAR_PERIOD: 'calendarPeriod'
}
