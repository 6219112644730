export const aclLimit = {
    NONE: 'none',
    USER: 'user',
    USER_CREATED: 'user.created'
}

const limits = [
    {
        key: aclLimit.NONE,
        value: aclLimit.NONE,
        t_text: 'LIMIT_NONE_BTN'
    },
    // {
    //     key: 'department',
    //     value: 'department',
    //     t_text: 'LIMIT_DEPARTMENT'
    // },
    {
        key: aclLimit.USER,
        value: aclLimit.USER,
        t_text: 'LIMIT_USER_BTN'
    },
    {
        key: aclLimit.USER_CREATED,
        value: aclLimit.USER_CREATED,
        t_text: 'LIMIT_CREATED_BY_USER_BTN'
    }
]

export const getAclLimits = t => limits.map(limit => {
    limit.text = t(limit.t_text);
    return limit;
})