import instanceService from '../../services/instance/instanceService';

export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';
export const FETCH_PERMISSIONS_FULFILLED = 'FETCH_PERMISSIONS_FULFILLED';
export const FETCH_PERMISSIONS_REJECTED = 'FETCH_PERMISSIONS_REJECTED';

const requestPermissions = () => ({ type: FETCH_PERMISSIONS });
const requestPermissionsFulfilled = permissions => ({ type: FETCH_PERMISSIONS_FULFILLED, permissions });
const requestPermissionsRejected = error => ({ type: FETCH_PERMISSIONS_REJECTED, error });

export const fetchPermissions = () => async dispatch => {

    dispatch(requestPermissions());

    try {
        const permissions = await instanceService.fetchPermissions();
        dispatch(requestPermissionsFulfilled(permissions));
    } catch (err) {
        console.error(err);
        dispatch(requestPermissionsRejected(err));
    }
}