import {
    FETCH_DEVIATIONS,
    FETCH_DEVIATIONS_FULFILLED,
    FETCH_DEVIATIONS_REJECTED,
    DEVIATION_CHANGED,
    DEVIATION_CREATED,
    DEVIATION_DELETED,
    DEVIATIONS_TABLE_STATE_CHANGE,
    DEVIATIONS_FILTERS_CHANGE,
    DEVIATION_CLOSED
} from '../../actions/deviations';

const defaultState = {
    isFetching: false,
    deviations: [],
    tableState: undefined,
    filters: {
    },
    requireRefresh: true
}

export const deviations = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case DEVIATIONS_TABLE_STATE_CHANGE: return ({
            ...state,
            tableState: action.state
        })
        case DEVIATIONS_FILTERS_CHANGE: return ({
            ...state,
            filters: action.filters
        })
        case FETCH_DEVIATIONS: {
            return {
                ...state,
                isFetching: true,
                requireRefresh: false
            }
        }
        case FETCH_DEVIATIONS_FULFILLED: {
            return {
                ...state,
                isFetching: false,
                deviations: [...action.deviations]
            }
        }
        case FETCH_DEVIATIONS_REJECTED: {
            return {
                ...state,
                isFetching: false
            }
        }
        case DEVIATION_CREATED: {
            return {
                ...state,
                requireRefresh: true
            }
        }
        case DEVIATION_CLOSED: {
            const deviations = [...state.deviations];
            const idx = deviations.findIndex(m => m.id === action.id);

            if (idx === -1) {
                return state;
            }

            deviations.splice(idx, 1);

            return {
                ...state,
                deviations,
                requireRefresh: true
            }
        }
        case DEVIATION_CHANGED: {
            const deviations = [...state.deviations];
            const idx = deviations.findIndex(m => m.id === action.deviation.id);

            if (idx > -1) {
                deviations[idx] = action.deviation;
            }

            return {
                ...state,
                deviations
            }
        }
        case DEVIATION_DELETED: {
            const deviations = [...state.deviations];
            const idx = deviations.findIndex(m => m.id === action.id);

            if (idx === -1) {
                return state;
            }

            deviations.splice(idx, 1);

            return {
                ...state,
                deviations
            }
        }
        default: {
            return state;
        }
    }
};