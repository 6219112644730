import React from 'react';
import Notification from './Notification';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { closeNotification } from '../../../data/actions/common/notificationActions';

import './Notifications.css';
import { withTranslation } from 'react-i18next';

class NotificationContainer extends React.Component {

    removeNotification = id => {
        const { dispatch } = this.props;
        dispatch(closeNotification(id));
    }

    render() {
        const { notifications, t } = this.props;

        return (
            <div className="man-notifications-container">
                {notifications.map((n, index) => (
                    <Notification
                        key={n.id}
                        title={t(n.title)}
                        text={t(n.text, n.params)}
                        className={n.className}
                        iconClass={n.icon}
                        onCloseClick={() => this.removeNotification(n.id)}>
                    </Notification>
                ))}
            </div>
        )
    }
}

NotificationContainer.propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.object)
}

const mapStateToProps = (state) => ({
    notifications: state.ui.notifications
})

export default withTranslation('messages')(connect(mapStateToProps)(NotificationContainer));
