import { storeHolder } from '../../stores/storeHolder.js';
import _ from 'lodash';
import { calendarPeriod } from '../../../utils/CalendarUtils.js';
import { get } from '../../../utils/http/HttpClient.js';

export const CALENDAR_CHANGE_ACTIVE_DATE = 'CALENDAR_CHANGE_ACTIVE_DATE';
export const CALENDAR_CHANGE_ACTIVE_PERIOD = 'CALENDAR_CHANGE_ACTIVE_PERIOD';
export const CALENDAR_FETCH_EVENTS = 'CALENDAR_FETCH_EVENTS';
export const CALENDAR_FETCH_MONTHLY_EVENTS_FULFILLED = 'CALENDAR_FETCH_MONTHLY_EVENTS_FULFILLED';
export const CALENDAR_FETCH_MONTHLY_EVENTS_REJECTED = 'CALENDAR_FETCH_MONTHLY_EVENTS_REJECTED';
export const CALENDAR_FETCH_WEEKLY_EVENTS_FULFILLED = 'CALENDAR_FETCH_WEEKLY_EVENTS_FULFILLED';
export const CALENDAR_FETCH_WEEKLY_EVENTS_REJECTED = 'CALENDAR_FETCH_WEEKLY_EVENTS_REJECTED';

const calendarChangeDate = date => ({ type: CALENDAR_CHANGE_ACTIVE_DATE, date });

const calendarChangePeriod = period => ({ type: CALENDAR_CHANGE_ACTIVE_PERIOD, period });

const startFetchEvents = () => ({ type: CALENDAR_FETCH_EVENTS });

const fetchMonthlyEventsFulfilled = (events, timeReports) => ({ type: CALENDAR_FETCH_MONTHLY_EVENTS_FULFILLED, events, timeReports });

const fetchMonthlyEventsRejected = err => ({ type: CALENDAR_FETCH_MONTHLY_EVENTS_REJECTED, err });

const fetchWeeklyEventsFulfilled = (events, timeReports) => ({ type: CALENDAR_FETCH_WEEKLY_EVENTS_FULFILLED, events, timeReports });

const fetchWeeklyEventsRejected = err => ({ type: CALENDAR_FETCH_WEEKLY_EVENTS_REJECTED, err });


export const calendarSetView = (date, period) => dispatch => {
    dispatch(calendarChangePeriod(period));
    dispatch(calendarChangeDate(date));
    fetchEvents(dispatch);
}

export const calendarChangeActivePeriod = period => dispatch => {

    dispatch(calendarChangePeriod(period));
    fetchEvents(dispatch);
}

export const toggleActivePeriodFromCache = period => dispatch => {
    dispatch(calendarChangePeriod(period));
}

export const calendarChangeActiveDate = date => dispatch => {

    dispatch(calendarChangeDate(date));
    fetchEvents(dispatch);
}

export const calendarNextPeriod = () => dispatch => {
    const { activeDate, activePeriod } = storeHolder.getStore().getState().calendar;

    const date = activeDate.clone().add(1, activePeriod);
    dispatch(calendarChangeDate(date));

    if (date.isoWeek() !== activeDate.isoWeek()) {
        fetchEvents(dispatch);
    }

}

export const calendarPreviousPeriod = () => dispatch => {
    const { activeDate, activePeriod } = storeHolder.getStore().getState().calendar;

    const date = activeDate.clone().subtract(1, activePeriod);
    dispatch(calendarChangeDate(date));

    if (date.isoWeek() !== activeDate.isoWeek()) {
        fetchEvents(dispatch);
    }
}

export const reloadEvents = () => dispatch => {
    fetchEvents(dispatch);
}

const fetchEvents = dispatch => {
    dispatch(startFetchEvents());
    fetchEventsDebounced(dispatch);
}

const fetchEventsDebounced = _.debounce(async dispatch => {

    const { activeDate, activePeriod } = storeHolder.getStore().getState().calendar;

    switch (activePeriod) {
        case (calendarPeriod.MONTH): {
            try {
                const { workOrdersForUser, timeReportsForUser } = await get(`/wo/month/${activeDate.year()}/${activeDate.month() + 1}`);

                dispatch(fetchMonthlyEventsFulfilled(workOrdersForUser, timeReportsForUser));
            } catch (err) {
                console.error(err);
                dispatch(fetchMonthlyEventsRejected(err));
            }

            break;
        }
        case (calendarPeriod.WEEK): {
            try {
                const week = activeDate.clone().startOf('isoWeek').isoWeek();
                let year = activeDate.clone().startOf('isoWeek').year();

                const endYear = activeDate.clone().endOf('isoWeek').year();

                if (week === 1 && year !== endYear) {
                    year = endYear;
                }

                const { workOrdersForUser, timeReportsForUser } = await get(`/wo/week/${year}/${week}`);

                dispatch(fetchWeeklyEventsFulfilled(workOrdersForUser, timeReportsForUser));
            } catch (err) {
                console.error(err);
                dispatch(fetchWeeklyEventsRejected(err));
            }

            break;
        }
        case (calendarPeriod.DAY): {
            try {
                const { workOrdersForUser, timeReportsForUser } = await get(`/wo/week/${activeDate.year()}/${activeDate.isoWeek()}`);

                dispatch(fetchWeeklyEventsFulfilled(workOrdersForUser, timeReportsForUser));
            } catch (err) {
                console.error(err);
                dispatch(fetchWeeklyEventsRejected(err));
            }

            break;
        }
    }

}, 250)