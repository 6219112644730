import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class ValidatableInput extends Component {

    state = {
        value: this.props.value,
        error: ''
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.props.value !== this.state.value) {
            return;
        }

        this.setState({
            value: newProps.value
        })
    }

    handleChange = (e) => {
        if (this.props.validate) {
            let validationResult = this.props.validate(e.target.value);
            if (validationResult) {
                this.setState({ error: validationResult })
                return;
            } else {
                this.setState({ error: '' })
            }
        }
        this.props.onChange(e.target.name, e.target.value);
    }

    render() {

        const { placeholder, style } = this.props;

        return (
            <React.Fragment>
                <div className={`ui input${this.state.error ? ' error' : ''}`}
                    style={{ ...style }}>
                    <input
                        style={{ minWidth: 120 }}
                        name="value"
                        type="text"
                        value={this.state.value}
                        placeholder={placeholder}
                        onChange={this.handleChange}>
                    </input>
                </div>
                {this.state.error &&
                    <div style={{ color: '#9f3a38', marginTop: 5 }}>
                        {this.props.t(this.state.error)}
                    </div>}
            </React.Fragment>
        )
    }
}

ValidatableInput.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    validate: PropTypes.func
}

export default withTranslation(['messages', 'ui'])(ValidatableInput);
