export const se_gates = {
    ui: {

         SETTINGS_BUSINESS_OBJECT: 'Port',
         OBJECTS: 'Portar',
         INVOICE_MARKING_OPTION_OBJECT: 'Portens märkning - {{value}}',
         CUSTOMER_OBJECT_CONTACT_COUNT:
            'Det finns {{count}} kontakt på någon av kundens portar',
            CUSTOMER_OBJECT_CONTACT_COUNT_plural:
            'Det finns {{count}} kontakter på någon av kundens portar',
            OBJECT: 'Port', 
    },

    form: {

        OBJECT: 'Port',
        OBJECT_COUNT: 'Portar',
        SERIAL: 'Port ID',
        PROJECT_OBJECTS_INVOLVED: 'Involverade portar',
        BUSINESS_OBJECT_DISPLAY_NAME: 'Kundens portbeteckning',

        
    },

    messages: {
        BUSINESS_OBJECT_EXISTS: 'Port-ID finns redan!',
         CANNOT_CLOSE_PROPOSAL_WORKORDER_OBJECT_IS_MISSING:
            'Ej tillåtet, välj port först.',

    },

 acl: {
    PERMISSION_BUSINESS_OBJECTS_READ: 'Se portar',
    PERMISSION_BUSINESS_OBJECTS_EDIT: 'Ändra portar',
    PERMISSION_BUSINESS_OBJECTS_DELETE: 'Radera portar',
    PERMISSION_BUSINESS_OBJECT_FILES: 'Hantera dokument under portar',
    PERMISSION_MODELS_DELETE: 'Radera port-typ',

 },

}