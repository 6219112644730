import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { Input } from 'semantic-ui-react';
import { defaultFilesSort } from '../../utils/ArrayUtils';
import { generateIndexBetween } from '../../utils/MathUtils';
import FlatButton from '../flat-button/FlatButton';
import Icon from '../icon/Icon';
import Loader from '../loader/Loader';
import { SettingsRow } from '../settings-grid/index';
import AddLinkPopup, {
    AddLinkPopupTranslations,
    LinkProps,
} from './AddLinkPopup';
import File from './File';
import { FileTypes } from './FileConstants';
import './Files.css';
import { EditTranslations, FileTranslations, FileType } from './FileType';

interface FilesTranslations {
    actionConfirm: string;
    uploadPlaceholder: string;
    uploadingFile: string;
    loadMore: string;
    total: string;
    addNewFolder: string;
    rootFolder: string;
    backToFolder: string;
    editTranslations: EditTranslations;
    linkTranslations: AddLinkPopupTranslations;
    fileTranslations: FileTranslations;
}

interface NewLinkProps {
    id: string;
    linkProps: LinkProps;
    sortOrder?: number;
    folderId?: string;
}

interface Props {
    id: string;
    showConfirm: Function;
    onFetchFailed: Function;
    onUploadFailed: Function;
    onDeleteFailed: Function;
    onMoveFailed: Function;
    uploadFunc: Function;
    fetchFunc: Function;
    deleteFunc: Function;
    detailsFunc: Function;
    onPreviewFile: Function;
    onPublishFile: Function | undefined;
    onUnpublishFile: Function | undefined;
    onMoveToFolder: Function;
    onFileReposition: Function;
    onNameChange: (fileId: string, newName: string) => void;
    onLinkChange: (fileId: string, linkProps: LinkProps) => void;
    onNewFolder: Function;
    translations: FilesTranslations;
    foldersEnabled: boolean;
    editable: boolean;
    linksEnabled: boolean;
    publishEnabled: boolean;
    compact?: boolean;
    onNewLink: (props: NewLinkProps) => Promise<FileType>;
}

const FilesContainer: React.FC<Props> = ({
    id,
    showConfirm,
    onFetchFailed,
    onUploadFailed,
    onDeleteFailed,
    onMoveFailed,
    uploadFunc,
    fetchFunc,
    deleteFunc,
    detailsFunc,
    onPreviewFile,
    onPublishFile,
    onUnpublishFile,
    onMoveToFolder,
    onFileReposition,
    onNameChange,
    onLinkChange,
    onNewFolder,
    translations,
    foldersEnabled,
    editable,
    compact,
    onNewLink,
    linksEnabled,
    publishEnabled,
}) => {
    const PAGE_SIZE = 2000;

    const [loading, setLoading] = useState<boolean>(true);
    const [uploading, setUploading] = useState<boolean>(false);
    const [filesData, setFilesData] = useState<FileType[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [folderHistory, setFolderHistory] = useState<
        Record<string, string>[]
    >([]);
    const [isCreatingNewFolder, setIsCreatingNewFolder] =
        useState<boolean>(false);
    const [newFolderName, setNewFolderName] = useState<string>('');
    const folderNameInputRef = useRef<any>(null);

    useEffect(() => {
        fetchFiles();
    }, [folderHistory]);

    useEffect(() => {
        if (isCreatingNewFolder) {
            folderNameInputRef.current!.select();
        }
    }, [isCreatingNewFolder]);

    const backFolder = (folderId: string | null) => {
        const folderName =
            folderHistory.length === 1
                ? translations.rootFolder
                : folderHistory[folderHistory.length - 2].name;
        return {
            file: {
                id: folderId,
                type: FileTypes.BACK_FOLDER,
                originalFileName: `${translations.backToFolder} ${folderName}`,
                sizeBytes: 0,
                sortOrder: null,
            },
        };
    };

    const fetchFiles = async () => {
        try {
            setLoading(true);
            const folderId =
                folderHistory.length > 0    
                    ? folderHistory[folderHistory.length - 1].id
                    : null;
            const fetchedData = await fetchFunc(id, folderId, 0, PAGE_SIZE);
            setTotalCount(fetchedData.totalCount);

            const links = fetchedData.files
                .filter((file: FileType) => file.file.type === FileTypes.LINK)
                .sort(defaultFilesSort);
            const folders = fetchedData.files
                .filter((file: FileType) => file.file.type === FileTypes.FOLDER)
                .sort(defaultFilesSort);
            const files = fetchedData.files
                .filter((file: FileType) => file.file.type !== FileTypes.FOLDER && file.file.type !== FileTypes.LINK)
                .sort(defaultFilesSort);

            const back = folderId
                ? backFolder(
                    folderHistory.length === 1
                        ? null
                        : folderHistory[folderHistory.length - 2].id
                )
                : null;
            setFilesData([back, ...links, ...folders, ...files].filter(Boolean));
        } catch (err) {
            console.error(`Error fetching files: ${err}`)
            onFetchFailed(err);
        } finally {
            setLoading(false);
        }
    };

    const fetchMoreFiles = async (fetchFrom: number) => {
        try {
            setLoading(true);
            const folderId =
                folderHistory.length > 0
                    ? folderHistory[folderHistory.length - 1].id
                    : null;
            const fetchedData = await fetchFunc(
                id,
                folderId,
                fetchFrom,
                PAGE_SIZE
            );

            const sortedFiles = fetchedData.files.sort(defaultFilesSort);
            setFilesData([...filesData, ...sortedFiles]);
        } catch (err) {
            onFetchFailed();
        } finally {
            setLoading(false);
        }
    };

    const onDrop = async (acceptedFiles: FileWithPath[]) =>
        handleSave(acceptedFiles);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    const fetchPreviewUrl = async (file: FileType) => {
        try {
            const fileData = await detailsFunc(file.file.id);

            if (fileData.url) {
                setFilesData(state =>
                    state.map(file => {
                        if (file.file.id === fileData.file.id) {
                            return fileData;
                        }

                        return file;
                    })
                );
            } else {
                setTimeout(() => {
                    fetchPreviewUrl(file);
                }, 800);
            }
        } catch (error) {
            console.error("Couldn't fetch file data", error);
        }
    };

    const handleFileUpload = async (data: FormData) => {
        try {
            setUploading(true);
            const newFile = await uploadFunc(id, data);

            if (!newFile || !newFile?.file?.id) {
                throw Error();
            }

            setFilesData(files => {
                const linksInData = files.filter(
                    file => file?.file?.type === FileTypes.LINK
                );

                const foldersInData = files.filter(
                    file => file?.file?.type === FileTypes.FOLDER
                );

                const filesInData = files.filter(
                    file =>
                        file?.file?.type !== FileTypes.FOLDER &&
                        file?.file?.type !== FileTypes.BACK_FOLDER &&
                        file?.file?.type !== FileTypes.LINK
                );

                const backFolder = files.filter(
                    file => file?.file?.type === FileTypes.BACK_FOLDER
                );

                return [
                    ...backFolder,
                    ...linksInData,
                    ...foldersInData,
                    newFile,
                    ...filesInData,
                ];
            });
            fetchPreviewUrl(newFile);
            setTotalCount(count => count + 1);
        } catch (err) {
            onUploadFailed();
        } finally {
            setUploading(false);
        }
    };

    const handleSave = async (files: FileWithPath[]) => {
        if (files.length < 1) {
            return;
        }

        for (let i = 0; i < files.length; i++) {
            const fileData = files[i];

            const firstFileIndex = filesData.findIndex(
                file => file?.file?.type !== FileTypes.FOLDER
            );

            const sortOrder = (
                calculateNewSortOrder(firstFileIndex) + i
            ).toString();
            const folderId =
                folderHistory.length > 0
                    ? folderHistory[folderHistory.length - 1].id
                    : null;

            const data = new FormData();
            data.append('files[]', fileData);
            data.append('sortOrder', sortOrder);
            if (folderId) {
                data.append('parentId', folderId);
            }

            await handleFileUpload(data);
        }
    };

    const handleDelete = async (fileId: string) => {
        showConfirm({
            body: translations.actionConfirm,
            onConfirm: async () => {
                try {
                    setLoading(true);

                    await deleteFunc(id, fileId);

                    handleFileDeleted(fileId);
                } catch {
                    onDeleteFailed();
                } finally {
                    setLoading(false);
                }
            },
        });
    };

    const handleFolderClick = (id: string, name: string) => {
        setLoading(true);

        const folderHistoryIds = folderHistory.map(folder => folder.id);
        if (folderHistoryIds.includes(id)) {
            folderHistory.length === 1
                ? setFolderHistory([])
                : setFolderHistory(
                    folderHistory.filter(
                        (_, idx) => idx < folderHistory.length - 1
                    )
                );

            return;
        }

        setFolderHistory(state => [...state, { id, name }]);
    };

    const handleClick = (fileId: string) => {
        onPreviewFile(fileId);
    };

    const handleFileDeleted = (id: string) => {
        const newFiles = filesData.filter(fileData => fileData.file.id !== id);
        setFilesData(newFiles);
        setTotalCount(totalCount - 1);
    };

    const handleFileMove = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragFile = filesData[dragIndex];
            const copyFiles = JSON.parse(JSON.stringify(filesData));
            copyFiles.splice(dragIndex, 1);
            copyFiles.splice(hoverIndex, 0, dragFile);

            setFilesData(copyFiles);
        },
        [filesData]
    );

    const handleMoveToFolder = async (fileId: string, folderId: string) => {
        try {
            await onMoveToFolder(fileId, folderId);
            handleFileDeleted(fileId);
        } catch (error) {
            onMoveFailed();
        }
    };

    const handlePublishFile = async (fileId: string) => {
        const fileIdx = filesData.findIndex(file => file.file.id === fileId);
        if (fileIdx === -1) return;

        try {
            onPublishFile && await onPublishFile(fileId);

            const fileToChange = {
                ...filesData[fileIdx],
                file: {
                    ...filesData[fileIdx].file,
                    published: true,
                    publishTs: new Date().getTime()
                },
            };
            const newFilesData = [...filesData];
            newFilesData[fileIdx] = fileToChange;

            setFilesData(newFilesData);
        } catch (err) {
            console.log(`FilesContainer error: ${err}`)
        }
    };

    const handleUnpublishFile = async (fileId: string) => {
        const fileIdx = filesData.findIndex(file => file.file.id === fileId);
        if (fileIdx === -1) return;

        
        try {
            onUnpublishFile && await onUnpublishFile(fileId);

            const fileToChange = {
                ...filesData[fileIdx],
                file: {
                    ...filesData[fileIdx].file,
                    published: false,
                    publishTs: null
                },
            };
            const newFilesData = [...filesData];
            newFilesData[fileIdx] = fileToChange;

            setFilesData(newFilesData);
        } catch (err) {
            console.log(`FilesContainer error: ${err}`)
        }
    };

    const handleFileReordering = (fileId: string, newOrderIndex: number) => {
        const sortOrder = calculateNewSortOrder(newOrderIndex);
        const files = filesData.map(file => {
            if (file.file.id === fileId) {
                file.file.sortOrder = sortOrder;
            }

            return file;
        });

        setFilesData(files);
        onFileReposition(fileId, sortOrder);
    };

    const calculateNewSortOrder = (newOrderIndex: number): number => {
        if (newOrderIndex === -1) {
            return 100;
        }

        if (newOrderIndex === 0 && filesData.length === 1) {
            return generateIndexBetween(null, filesData[0]?.file?.sortOrder);
        }

        if (
            newOrderIndex === 0 ||
            filesData[newOrderIndex - 1].file.type === FileTypes.FOLDER
        ) {
            return generateIndexBetween(
                null,
                filesData[newOrderIndex + 1]?.file?.sortOrder
            );
        } else if (newOrderIndex === filesData.length - 1) {
            return generateIndexBetween(
                filesData[newOrderIndex - 1]?.file?.sortOrder,
                null
            );
        } else {
            return generateIndexBetween(
                filesData[newOrderIndex - 1]?.file?.sortOrder,
                filesData[newOrderIndex + 1]?.file?.sortOrder
            );
        }
    };

    const handleFileNameChange = (fileId: string, newName: string) => {
        const fileIdx = filesData.findIndex(file => file.file.id === fileId);
        if (fileIdx === -1) return;

        const fileToChange = {
            ...filesData[fileIdx],
            file: {
                ...filesData[fileIdx].file,
                originalFileName: newName,
            },
        };
        const newFilesData = [...filesData];
        newFilesData[fileIdx] = fileToChange;

        setFilesData(newFilesData);
        onNameChange(fileId, newName);
    };

    const handleLinkChange = (fileId: string, linkProps: LinkProps) => {
        const fileIdx = filesData.findIndex(file => file.file.id === fileId);
        if (fileIdx === -1) return;

        const fileToChange = {
            ...filesData[fileIdx],
            url: linkProps.link || '',
            file: {
                ...filesData[fileIdx].file,
                originalFileName: linkProps.name || '',
            },
        };
        const newFilesData = [...filesData];
        newFilesData[fileIdx] = fileToChange;

        setFilesData(newFilesData);
        onLinkChange(fileId, linkProps);
    };

    const handleFolderNameChange = (event: any) => {
        const isBlurEvent = event.type === 'blur';

        if (event.key === 'Enter' || isBlurEvent) {
            if (event.target.value === '') {
                setIsCreatingNewFolder(false);
                return;
            }

            handleFolderCreation(event.target.value);
            setNewFolderName('');
            return;
        }

        if (event.keyCode === 27) {
            setNewFolderName('');
            setIsCreatingNewFolder(false);
            return;
        }
    };

    const handleFolderCreation = async (folderName: string) => {
        try {
            const folderId =
                folderHistory.length > 0
                    ? folderHistory[folderHistory.length - 1].id
                    : null;
            const folder = await onNewFolder(
                id,
                folderName,
                calculateNewSortOrder(-1),
                folderId
            );

            setFilesData(state => [folder, ...state]);
        } catch (error) {
            onUploadFailed();
        }
    };

    const handleLinkCreation = async (linkProps: LinkProps) => {
        try {
            const sortOrder = calculateNewSortOrder(-1);
            const folderId =
                folderHistory.length > 0
                    ? folderHistory[folderHistory.length - 1].id
                    : undefined;

            const link = await onNewLink({
                id,
                linkProps,
                sortOrder,
                folderId,
            });

            if (!link) {
                throw new Error('Could not create link');
            }

            setFilesData(files => {
                const linksData = files.filter(
                    file => file?.file?.type === FileTypes.LINK
                );

                const foldersInData = files.filter(
                    file => file?.file?.type === FileTypes.FOLDER
                );

                const filesInData = files.filter(
                    file =>
                        file?.file?.type !== FileTypes.FOLDER &&
                        file?.file?.type !== FileTypes.BACK_FOLDER &&
                        file?.file?.type !== FileTypes.LINK
                );

                const backFolder = files.filter(
                    file => file?.file?.type === FileTypes.BACK_FOLDER
                );

                return [...backFolder, link, ...linksData, ...foldersInData, ...filesInData];
            });
            setTotalCount(count => count + 1);
        } catch (error) {
            onUploadFailed();
        }
    };

    if (compact) {
        return (
            <div className="man-files-container-compact">
                <div className="man-files-outer-container-compact">
                    {editable && (
                        <div
                            {...getRootProps()}
                            className={'man-file-upload-container-compact'}>
                            <input {...getInputProps()} />
                            {uploading && (
                                <Loader text={translations.uploadingFile} />
                            )}
                            <div className="man-file-upload-info-container">
                                {uploading ? (
                                    ''
                                ) : (
                                    <div
                                        className={
                                            'man-file-upload-icon-compact'
                                        }>
                                        <Icon name="cloud upload" />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <DndProvider
                        backend={isMobile ? TouchBackend : HTML5Backend}
                        options={isMobile ? { delayTouchStart: 200 } : {}}>
                        {filesData.map((fileData, idx) => (
                            <File
                                compact={compact}
                                key={fileData.file.id}
                                index={idx}
                                loading={loading}
                                fileData={fileData}
                                moveFile={handleFileMove}
                                moveToFolder={handleMoveToFolder}
                                reorderFileToPosition={handleFileReordering}
                                onDelete={handleDelete}
                                onFolderClick={handleFolderClick}
                                onClick={handleClick}
                                onNameChange={handleFileNameChange}
                                onLinkChange={handleLinkChange}
                                onPublish={onPublishFile ? handlePublishFile : undefined}
                                onUnpublish={onUnpublishFile ? handleUnpublishFile : undefined}
                                editable={editable}
                                isTouchScreen={isMobile}
                                publishEnabled={publishEnabled}
                                editTranslations={translations.editTranslations}
                                linkTranslations={translations.linkTranslations}
                                fileTranslations={translations.fileTranslations}
                            />
                        ))}
                    </DndProvider>
                </div>
                {totalCount > filesData.length && (
                    <SettingsRow>
                        <a onClick={() => fetchMoreFiles(filesData.length)}>
                            {translations.loadMore}
                        </a>
                    </SettingsRow>
                )}
                <div style={{ marginTop: '1rem' }}>
                    <SettingsRow>{`${translations.total}: ${totalCount}`}</SettingsRow>
                </div>
            </div>
        );
    }

    return (
        <div className="man-files-outer-container">
            <div className="man-files-container-row">
                {editable && (
                    <div
                        {...getRootProps()}
                        className="man-file-container man-file-upload-container">
                        <input {...getInputProps()} />
                        {uploading && (
                            <Loader text={translations.uploadingFile} />
                        )}
                        <div className="man-file-upload-info-container">
                            {uploading ? (
                                ''
                            ) : (
                                <div className="trks-secondary-text">
                                    <div className="man-file-upload-icon">
                                        <Icon name="cloud upload" />
                                    </div>
                                    {translations.uploadPlaceholder}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {editable && (
                <div className="actions-row">
                    {linksEnabled && (
                        <AddLinkPopup
                            onSave={handleLinkCreation}
                            translations={translations.linkTranslations}
                        />
                    )}
                    {foldersEnabled && (
                        <FlatButton
                            title={translations.addNewFolder}
                            icon="folder outline"
                            onClick={() => setIsCreatingNewFolder(true)}
                            style={{
                                textDecoration: 'none',
                                fontSize: '1.2rem',
                            }}>
                            <Icon name="plus" />
                        </FlatButton>
                    )}
                </div>
            )}
            <div
                className="man-files-container"
                style={{
                    opacity: loading ? 0.5 : 1,
                }}>
                {loading && <Loader />}
                {isCreatingNewFolder && (
                    <div className="folder-create-row">
                        <Icon
                            style={{ fontSize: '1.3rem' }}
                            name="folder outline"
                        />
                        <Input
                            ref={folderNameInputRef}
                            value={newFolderName}
                            onChange={e => setNewFolderName(e.target.value)}
                            onKeyDown={handleFolderNameChange}
                            onBlur={handleFolderNameChange}
                        />
                    </div>
                )}
                <DndProvider
                    backend={isMobile ? TouchBackend : HTML5Backend}
                    options={isMobile ? { delayTouchStart: 200 } : {}}>
                    {filesData.map((fileData, idx) => (
                        <File
                            compact={compact}
                            key={fileData.file.id}
                            index={idx}
                            loading={loading}
                            fileData={fileData}
                            moveFile={handleFileMove}
                            moveToFolder={handleMoveToFolder}
                            reorderFileToPosition={handleFileReordering}
                            onDelete={handleDelete}
                            onFolderClick={handleFolderClick}
                            onClick={handleClick}
                            onNameChange={handleFileNameChange}
                            onLinkChange={handleLinkChange}
                            editable={editable}
                            isTouchScreen={isMobile}
                            editTranslations={translations.editTranslations}
                            linkTranslations={translations.linkTranslations}
                            fileTranslations={translations.fileTranslations}
                            onPublish={onPublishFile ? handlePublishFile : undefined}
                            onUnpublish={onUnpublishFile ? handleUnpublishFile : undefined}
                            publishEnabled={publishEnabled}
                        />
                    ))}
                </DndProvider>
                {totalCount > filesData.length && (
                    <SettingsRow>
                        <a onClick={() => fetchMoreFiles(filesData.length)}>
                            {translations.loadMore}
                        </a>
                    </SettingsRow>
                )}
                <div style={{ marginTop: '1rem' }}>
                    <SettingsRow>{`${translations.total}: ${totalCount}`}</SettingsRow>
                </div>
            </div>
        </div>
    );
};

export default FilesContainer;
