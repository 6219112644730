import 'moment/locale/sv';
import 'moment/locale/et';
import 'moment/locale/en-gb';

export const mapLanguageToMomentLocale = language => {
    switch (language) {
        case 'se': return 'sv';
        case 'ee': return 'et';
        default: return 'en-gb';
    }
}