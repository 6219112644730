import Alert from 'components/common/alert';
import DatePicker from 'components/common/date-picker';
import EditableText from 'components/common/editable-text';
import ToggleButtonGroup from 'components/common/toggle-btn-group';
import { useInstanceData } from 'components/common/use-instance-data';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { SettingsGrid, SettingsRow } from 'trukks-lib';
import { appModules as modules } from 'utils/constants/appModules';
import './WorkOrderCloseModal.css';

const WorkOrderCloseModal = ({ workOrder, customer, onHandled, onClose }) => {
    const [loading, setLoading] = useState(null);
    const [enableInvoiceFee, setEnableInvoiceFee] = useState(true);
    const { t } = useTranslation(['ui']);
    const { hasAppModule, appModules, currency } = useInstanceData();

    const {
        deliveryDateEnabled,
        invoiceDateEnabled,
        ignoreReverseVATEnabled,
        invoiceFeeEnabled,
        defaultInvoiceFee
    } = appModules[modules.WORKORDER_INVOICE_DETAILS]?.custom || {};

    const [invoiceDetails, setInvoiceDetails] = useState({
        invoiceDate: new Date().getTime(),
        deliveryDate: new Date().getTime(),
        invoiceFee: defaultInvoiceFee || 40
    });

    const handleSubmit = async details => {
        if (details && !enableInvoiceFee || !invoiceFeeEnabled) {
            details.invoiceFee = null;
        }

        onHandled(hasAppModule(modules.WORKORDER_INVOICE_DETAILS) ? details : null);
    }

    const totalPrice = (workOrder.articleReports || [])
        .filter(({ articleId }) => articleId)
        .map(({ totalPrice }) => totalPrice)
        .reduce((a, b) => a + b, 0);


    return (
        <React.Fragment>
            <div className="content man-workorder-close-modal">
                <div className="description">

                    {hasAppModule(modules.WORKORDER_INVOICE_DETAILS) &&
                        <SettingsGrid>
                            {invoiceDateEnabled &&
                                <SettingsRow label={t('ui:WORKORDER_CLOSE_CHANGE_ERP_INVOICE_DATE')}>
                                    <div className="man-ui-value">

                                        <DatePicker
                                            value={[invoiceDetails.invoiceDate]}
                                            name="invoiceDate"
                                            onDatesChange={(
                                                name,
                                                value
                                            ) =>
                                                setInvoiceDetails({ ...invoiceDetails, invoiceDate: value })
                                            } />
                                    </div>
                                </SettingsRow>
                            }
                            {deliveryDateEnabled &&
                                <SettingsRow label={t('ui:WORKORDER_CLOSE_CHANGE_ERP_DELIVERY_DATE')}>
                                    <DatePicker
                                        value={[invoiceDetails.deliveryDate]}
                                        name="deliveryDate"
                                        onDatesChange={(
                                            name,
                                            value
                                        ) =>
                                            setInvoiceDetails({ ...invoiceDetails, deliveryDate: value })
                                        }
                                    />
                                </SettingsRow>
                            }

                            {ignoreReverseVATEnabled && workOrder.customerConfiguration?.invoice?.reverseVat &&
                                <SettingsRow label={t('ui:WORKORDER_CLOSE_INVOICE_WITH_REVERSE_VAT')}>
                                    <ToggleButtonGroup
                                        toggleButtons={[
                                            {
                                                text: t(
                                                    'ui:YES'
                                                ),
                                                key: t(
                                                    'ui:YES'
                                                ),
                                                value: false,
                                            },
                                            {
                                                text: t(
                                                    'ui:NO'
                                                ),
                                                key: t(
                                                    'ui:NO'
                                                ),
                                                value: true,
                                            },
                                        ]}
                                        toggeled={invoiceDetails.ignoreReverseVAT || false}
                                        onClick={value => setInvoiceDetails({ ...invoiceDetails, ignoreReverseVAT: value })}
                                        size="mini"
                                    />
                                </SettingsRow>
                            }

                            {invoiceFeeEnabled && totalPrice > 0 &&
                                <SettingsRow label={t('ui:WORKORDER_CLOSE_INVOICE_ADD_INVOICE_FEE')}>

                                    <ToggleButtonGroup
                                        toggleButtons={[
                                            {
                                                text: t(
                                                    'ui:YES'
                                                ),
                                                key: t(
                                                    'ui:YES'
                                                ),
                                                value: true,
                                            },
                                            {
                                                text: t(
                                                    'ui:NO'
                                                ),
                                                key: t(
                                                    'ui:NO'
                                                ),
                                                value: false,
                                            },
                                        ]}
                                        toggeled={enableInvoiceFee || false}
                                        onClick={value => setEnableInvoiceFee(value)}
                                        size="mini"
                                    />


                                    <span style={{
                                        marginLeft: 20,
                                        minWidth: 160
                                    }}>
                                        <EditableText
                                            min="0"
                                            value={invoiceDetails.invoiceFee}
                                            type="number"
                                            onSave={value => setInvoiceDetails({ ...invoiceDetails, invoiceFee: value })}
                                            onValueChange={(name, value) => setInvoiceDetails({ ...invoiceDetails, invoiceFee: value })}
                                            postfix={currency} />
                                    </span>
                                </SettingsRow>
                            }
                        </SettingsGrid>

                    }

                    {workOrder?.customerConfiguration?.ignoreErpInvoices === true && (
                        <div
                            style={{
                                marginTop: 20,
                            }}>
                            <Alert
                                type="negative"
                                message={t(
                                    'messages:WORK_ORDER_CONFIRM_CLOSE_CUSTOMER_IS_CONFIGURED_TO_NOT_SEND_TO_ERP'
                                )}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div
                className="actions"
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    marginTop: 20,
                }}>
                <Button
                    onClick={e => {
                        e.stopPropagation();
                        onClose();
                    }}
                    style={{
                        width: '200px',
                        marginRight: 20
                    }}>
                    {t('ui:CLOSE')}
                </Button>
                <Button
                    onClick={async e => {
                        setLoading(true);
                        e.stopPropagation();
                        await handleSubmit(invoiceDetails);
                        onClose();
                    }}
                    loading={loading}
                    style={{
                        width: '200px'
                    }}
                    className="man-button-primary">
                    {t('ui:ACTION_CONFIRM')}
                </Button>
            </div>
        </React.Fragment>
    );
};

export default WorkOrderCloseModal;
