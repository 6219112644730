import { discountTypes } from 'utils/constants/discountTypes';
import { deletee, get, post, put } from '../../../utils/http/HttpClient';
import CustomerInvoiceListGetResponse from '../customer/api/CustomerInvoiceListGetResponse';
import instanceService from '../instance/instanceService';

export default {
    query: async query => {
        const { customers } = await get(`/customers?q=${query}`);

        return customers;
    },
    fetch: async () => {
        const start = new Date().getTime();
        const { customers } = await get('/customers');


        const end = new Date().getTime();
        const time = end - start;

        instanceService.postMetric('customers', time);
        return customers;
    },
    fetchDetails: async id => {
        const { customer } = await get(`/customers/${id}`);

        return customer;
    },
    create: async customer => {
        const { id } = await post('/customers', { customer });

        return id;
    },
    update: async customer => {
        await put('/customers', { customer });
    },
    updateCustomerId: async (id, customerId) => {
        await put(`/customers/${id}/id`, {
            customerId,
        });
    },
    delete: async id => {
        await deletee(`/customers/${id}`);
    },
    fetchProposedId: async length => {
        const { id } = await get(`/customers/id/propose?length=${length}`);

        return id;
    },
    fetchArticles: async id => {
        const { articles } = await get(`/customers/${id}/articles`);

        return articles;
    },
    createArticle: async ({ customerId, price, articleId }) => {
        const { id } = await post('/customers/articles', {
            customerId,
            price,
            articleId,
        });

        return id;
    },
    updateArticle: async ({ customerId, price, articleId }) => {
        await put('/customers/articles', {
            customerId,
            price,
            articleId,
        });
    },
    deleteArticle: async ({ customerId, articleId }) => {
        await deletee(
            `/customers/${customerId}/articles?articleId=${articleId}`
        );
    },
    fetchHistory: async (id, from, pageSize) => {
        const response = await get(
            `/customers/${id}/history?from=${from}&size=${pageSize}`
        );

        return new CustomerInvoiceListGetResponse(response);
    },
    updateCustomerConfiguration: async (id, configuration) => {
        await put(`/customers/${id}/conf`, {
            configuration,
        });
    },
    updateCustomerContacts: async (id, contacts) => {
        await put(`/customers/${id}/contacts`, {
            contacts,
        });
    },
    fetchCustomerZoneAuthUsers: async customerId => {
        const { users } = await get(`/customers/${customerId}/zone/auth`);

        return users;
    },
    createCustomerZoneAuthUser: async (customerId, email, name) => {
        await post(`/customers/${customerId}/zone/auth`, {
            customerId,
            email,
            name,
            status: 'STATUS_REGISTERED'
        });
    },
    resendCustomerZoneAuthUser: async (customerId, email, name) => {
        await post(`/customers/${customerId}/zone/auth?resend=1`, {
            customerId,
            email,
            name,
            status: 'STATUS_REGISTERED'
        });
    },
    updateCustomerZoneAuthUser: async (customerId, user) => {
        await put(`/customers/${customerId}/zone/auth`, {
            customerId,
            ...user
        });
    },
    deleteCustomerZoneAuthUser: async (customerId, email) => {
        await deletee(`/customers/${customerId}/zone/auth/${email}`);
    },
    fetchArticleGroupDiscounts: async customerId => {
        const { articleGroupDiscounts } = await get(
            `/customers/articlegroupdiscounts?customerId=${customerId}`
        );

        return articleGroupDiscounts;
    },
    createArticleGroupDiscount: async (customerId, articleGroupId) => {
        await post(`/customers/articlegroupdiscounts`, {
            customerId,
            articleGroupId,
        });
    },
    updateArticleGroupDiscount: async (
        customerId,
        articleGroupId,
        discountValue
    ) => {
        await put(`/customers/articlegroupdiscounts`, {
            discount: {
                customerId,
                articleGroupId,
                discountValue,
                discountType: discountTypes.PERCENTAGE,
            },
        });
    },
    deleteArticleGroupDiscount: async (customerId, articleGroupId) => {
        await deletee(`/customers/articlegroupdiscounts?customerId=${customerId}&articleGroupId=${articleGroupId}`);
    }
};
