import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dimmer } from 'semantic-ui-react';
import Loader from '../loader';

class GlobalLoader extends React.Component {
    render() {
        const { isLoading, t } = this.props;

        return (
            <Dimmer active={isLoading} inverted>
                <Loader text={t('PROCESSING')} />
            </Dimmer>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.loader.isLoading,
});

export default withTranslation('ui')(connect(mapStateToProps)(GlobalLoader));
