import React from 'react';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import PropTypes from 'prop-types';
import './BottomSheet.css';

const BottomSheet = ({ children, zIndex, ...props }) => {

    return (
        <SwipeableBottomSheet
            overflowHeight={0}
            style={{
                zIndex: 100 + zIndex
            }}
            {...props}
        >
            <div className="man-bottom-sheet-content-container">
                {children}
            </div>
        </SwipeableBottomSheet>
    )
}

BottomSheet.propTypes = {
    zIndex: PropTypes.number
}

BottomSheet.defaultProps = {
    zIndex: 0
}

export default BottomSheet;