
import articleService from '../../services/article/articleService';

export const FETCH_ARTICLES = 'FETCH_ARTICLES';
export const FETCH_ARTICLES_FULFILLED = 'FETCH_ARTICLES_FULFILLED';
export const FETCH_ARTICLES_REJECTED = 'FETCH_ARTICLES_REJECTED';

export const FETCH_INACTIVE_ARTICLES = 'FETCH_INACTIVE_ARTICLES';
export const FETCH_INACTIVE_ARTICLES_FULFILLED = 'FETCH_INACTIVE_ARTICLES_FULFILLED';
export const FETCH_INACTIVE_ARTICLES_REJECTED = 'FETCH_INACTIVE_ARTICLES_REJECTED';

export const ARTICLE_CHANGED = 'ARTICLE_CHANGED';
export const ARTICLE_DELETED = 'ARTICLE_DELETED';

export const ARTICLE_TABLE_STATE_CHANGE = 'ARTICLE_TABLE_STATE_CHANGE';
export const ARTICLE_FILTER_CHANGE = 'ARTICLE_FILTER_CHANGE';

const requestArticles = () => ({ type: FETCH_ARTICLES });
const requestArticlesFulfilled = articles => ({ type: FETCH_ARTICLES_FULFILLED, articles });
const requestArticlesRejected = error => ({ type: FETCH_ARTICLES_REJECTED, error });

const requestInactiveArticles = () => ({ type: FETCH_INACTIVE_ARTICLES });
const requestInactiveArticlesFulfilled = articles => ({ type: FETCH_INACTIVE_ARTICLES_FULFILLED, articles });
const requestInactiveArticlesRejected = error => ({ type: FETCH_INACTIVE_ARTICLES_REJECTED, error });

export const articleTableStateChange = state => ({ type: ARTICLE_TABLE_STATE_CHANGE, state });

export const articleFilterChange = (filterName, value) => ({ type: ARTICLE_FILTER_CHANGE, filterName, value })

export const articleChanged = (article) => ({
    type: ARTICLE_CHANGED,
    article
});

export const articleDeleted = id => ({
    type: ARTICLE_DELETED,
    id
})

export const fetchArticles = () => async dispatch => {

    dispatch(requestArticles());

    try {
        const articles = await articleService.fetch();
        dispatch(requestArticlesFulfilled(articles));
    } catch (err) {
        console.error(err);
        dispatch(requestArticlesRejected(err));
    }
};

export const fetchInactiveArticles = () => async dispatch => {

    dispatch(requestInactiveArticles());

    try {
        const articles = await articleService.fetchInactive();
        dispatch(requestInactiveArticlesFulfilled(articles));
    } catch (err) {
        console.error(err);
        dispatch(requestInactiveArticlesRejected(err));
    }
};