import Loader from 'components/common/loader';
import { validateSession } from 'data/actions/common/authActions';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import CacheManager from 'utils/cache';
import CacheManagerProps from 'utils/cache/CacheManagerProps';

const MicrosoftSuccessLogin = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const search = useLocation().search;

    useEffect(() => {
        const setSession = async () => {
            const token = new URLSearchParams(search).get('token');
            await CacheManager.putGlobal(CacheManagerProps.AUTH_DATA, token);

            dispatch(validateSession());
            history.push('/');
        };
        setSession();
    }, []);

    return <Loader />;
};

export default MicrosoftSuccessLogin;
