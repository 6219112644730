import { compensationreportTagTypes } from './timereportTagTypes';

export const erp = {
    FORTNOX: 'fortnox',
    VISMA: 'visma',
    VISMA_E: 'visma-e',
    WINT: 'wint',
    MAGENTA: 'magenta',
    QUICKBOOKS: 'quickbooks',
};

export const erpInvoiceStatus = {
    UNKNOWN: 'unknown',
    SUCCESS: 'success',
    NOT_SENT_ZERO_PRICE: 'not_sent.zero_price',
};

export const fortnoxConfiguration = {
    absenceCodes: [
        {
            key: 'ASK',
            value: 'ASK',
            text: 'ASK – Arbetsskada',
        },
        {
            key: 'ATF',
            value: 'ATF',
            text: 'ATF - Arbetstidsförkortning',
        },
        {
            key: 'FPE',
            value: 'FPE',
            text: 'FPE – Föräldraledig',
        },
        {
            key: 'FRA',
            value: 'FRA',
            text: 'FRA - Frånvaro övrigt',
        },
        {
            key: 'HAV',
            value: 'HAV',
            text: 'HAV – Graviditetspenning',
        },
        {
            key: 'KOM',
            value: 'KOM',
            text: 'KOM – Kompledig',
        },
        {
            key: 'MIL',
            value: 'MIL',
            text: 'MIL - Militärtjänst (max 60 dagar)',
        },
        {
            key: 'NAR',
            value: 'NAR',
            text: 'Närståendevård',
        },
        {
            key: 'OS1',
            value: 'OS1',
            text: 'OS1 – Sjuk-OB 1',
        },
        {
            key: 'OS2',
            value: 'OS2',
            text: 'OS2 – Sjuk-OB 2',
        },
        {
            key: 'OS3',
            value: 'OS3',
            text: 'OS3 – Sjuk-OB 3',
        },
        {
            key: 'OS4',
            value: 'OS4',
            text: 'OS4 – Sjuk-OB 4',
        },
        {
            key: 'OS5',
            value: 'OS5',
            text: 'OS5 – Sjuk-OB 5',
        },
        {
            key: 'PAP',
            value: 'PAP',
            text: 'PAP – Pappadagar',
        },
        {
            key: 'PEM',
            value: 'PEM',
            text: 'PEM – Permission',
        },
        {
            key: 'PER',
            value: 'PER',
            text: 'PER – Permitterad',
        },
        {
            key: 'SEM',
            value: 'SEM',
            text: 'SEM – Semester',
        },
        {
            key: 'SJK',
            value: 'SJK',
            text: 'SJK – Sjukfrånvaro',
        },
        {
            key: 'SMB',
            value: 'SMB',
            text: 'SMB – Smittbärare',
        },
        {
            key: 'SVE',
            value: 'SVE',
            text: 'SVE – Svenska för invandrare',
        },
        {
            key: 'TJL',
            value: 'TJL',
            text: 'TJL – Tjänstledig',
        },
        {
            key: 'UTB',
            value: 'UTB',
            text: 'UTB - Facklig utbildning',
        },
        {
            key: 'VAB',
            value: 'VAB',
            text: 'VAB – Vård av barn',
        },
    ],
};

export const fortnoxCompensationReportTypes = {
    [compensationreportTagTypes.DISTANCE]: [
        {
            key: '821',
            value: '821',
            text: '821 - Milersättning privat bil, skattefri',
        },
        {
            key: '822',
            value: '822',
            text: '822 - Milersättning privat bil, skattepliktig',
        },
        {
            key: '831',
            value: '831',
            text: '831 - Milersättning tjänstebil, skt.fri',
        },
        {
            key: '8310',
            value: '8310',
            text: '8310 - Milersättning tjänstebil, skt.pli',
        },
        {
            key: '832',
            value: '832',
            text: '832 - Milersättning tjänstebil diesel, skt.fri',
        },
        {
            key: '8320',
            value: '8320',
            text: '8320 - Milersättning tjänstebil diesel, skt.pli',
        },
    ],
    [compensationreportTagTypes.TRAVEL]: [
        {
            key: '811',
            value: '811',
            text: '811 - Traktamente inrikes, skattefri.',
        },
        {
            key: '8113',
            value: '8113',
            text: '8113 - TTraktamente inrikes, halvdag skattefri.',
        },
        {
            key: '8118',
            value: '8118',
            text: '8118 - Traktamente inrikes, natt skattefri.',
        },
    ],
    [compensationreportTagTypes.EXPENSE]: [
        {
            key: '892',
            value: '892',
            text: '892 - Utlägg',
        },
    ],
};
