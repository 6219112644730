import { get } from '../../../utils/http/HttpClient';

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_FULFILLED = 'FETCH_USERS_FULFILLED';
export const FETCH_USERS_REJECTED = 'FETCH_USERS_REJECTED';
export const USER_CHANGED = 'USER_CHANGED';
export const USER_TABLE_STATE_CHANGE = 'USER_TABLE_STATE_CHANGE';
export const USER_FILTER_CHANGE = 'USER_FILTER_CHANGE';

const requestUsers = () => ({ type: FETCH_USERS });
const requestUsersFulfilled = (users) => ({ type: FETCH_USERS_FULFILLED, users });
const requestUsersRejected = (error) => ({ type: FETCH_USERS_REJECTED, error });

export const userChanged = user => ({ type: USER_CHANGED, user })

export const userTableStateChange = state => ({ type: USER_TABLE_STATE_CHANGE, state });

export const userFilterChange = (filterName, value) => ({ type: USER_FILTER_CHANGE, filterName, value })

export const fetchUsers = () => async dispatch => {

    dispatch(requestUsers());

    try {
        const resp = await get('/users');
        dispatch(requestUsersFulfilled(resp.users));
    } catch (err) {
        console.error(err);
        dispatch(requestUsersRejected(err));
    }
}