import * as qs from 'query-string';
import { deletee, get, post, put } from 'utils/http/HttpClient';

export default {
    fetchWeeklyTimeReports: async (
        year,
        week,
        userId,
        hasDistanceReports,
        hasCompensationReports
    ) => {
        const [other, workOrder, distance, compensation] = await Promise.all([
            get(
                `/timereports/week/${year}/${week}?${qs.stringify({ userId })}`
            ),
            get(
                `/timereports/week/wo/${year}/${week}?${qs.stringify({
                    userId,
                })}`
            ),
            !hasDistanceReports
                ? Promise.resolve({})
                : get(
                      `/timereports/distance/week/${year}/${week}?${qs.stringify(
                          { userId }
                      )}`
                  ),
            !hasCompensationReports
                ? Promise.resolve({})
                : get(
                      `/timereports/week/compensation/${year}/${week}?${qs.stringify(
                          { userId }
                      )}`
                  ),
        ]);

        return {
            other,
            workOrder,
            distance,
            compensation,
        };
    },
    fetchMonthlyTimeReports: async (
        year,
        month,
        userId,
        hasDistanceReports,
        hasCompensationReports
    ) => {
        const [other, workOrder, distance, compensation] = await Promise.all([
            get(
                `/timereports/month/${year}/${month}?${qs.stringify({
                    userId,
                })}`
            ),
            get(
                `/timereports/month/wo/${year}/${month}?${qs.stringify({
                    userId,
                })}`
            ),
            !hasDistanceReports
                ? Promise.resolve({})
                : get(
                      `/timereports/distance/month/${year}/${month}?${qs.stringify(
                          { userId }
                      )}`
                  ),
            !hasCompensationReports
                ? Promise.resolve({})
                : get(
                      `/timereports/month/compensation/${year}/${month}?${qs.stringify(
                          { userId }
                      )}`
                  ),
        ]);

        return {
            other,
            workOrder,
            distance,
            compensation,
        };
    },
    fetchWeeklyAbsenceTimeReports: async (year, week) => {
        const reports = await get(`/timereports/week/absence/${year}/${week}`);

        return reports;
    },
    fetchWeeklyAttendTimeReports: async (year, week) => {
        const [other, workOrder] = await Promise.all([
            get(`/timereports/week/attend/${year}/${week}`),
            get(`/timereports/week/wo/all/${year}/${week}`),
        ]);

        return {
            other,
            workOrder,
        };
    },
    fetchTimeReports: async (
        startTime,
        endTime,
        userId,
        hasDistanceReports
    ) => {
        const other = await get(
            `/timereports?${qs.stringify({ userId, startTime, endTime })}`
        );
        const workOrder = await get(
            `/timereports/wo?${qs.stringify({ userId, startTime, endTime })}`
        );

        let distance = {};

        if (hasDistanceReports) {
            distance = await get(
                `/timereports/distance?${qs.stringify({
                    userId,
                    startTime,
                    endTime,
                })}`
            );
        }

        return {
            other,
            workOrder,
            distance,
        };
    },
    createTimeReport: async ({
        tagId,
        userId,
        date,
        comment,
        startTime,
        endTime,
        reportedHours,
        reportedMinutes,
        reportedDistanceKm,
    }) => {
        const { id } = await post('/timereports', {
            id: 'new',
            tagId,
            userId,
            date,
            startTime,
            endTime,
            comment,
            reportedHours,
            reportedMinutes,
            reportedDistanceKm,
        });

        return id;
    },
    updateTimeReport: async ({
        id,
        tagId,
        userId,
        date,
        comment,
        startTime,
        endTime,
        reportedHours,
        reportedMinutes,
        reportedDistanceKm,
    }) => {
        return await put('/timereports', {
            id,
            tagId,
            userId,
            date,
            startTime,
            endTime,
            comment,
            reportedHours,
            reportedMinutes,
            reportedDistanceKm,
        });
    },
    deleteTimeReport: async id => {
        return await deletee(`/timereports/${id}`);
    },
    fetchLastDistanceReadingReport: async ({
        id,
        userId,
        date,
        readingKm,
        reportedDistanceKm,
    }) => {
        return await post('/timereports/distance/last', {
            id,
            readingKm,
            reportedDistanceKm,
            date,
            userId,
        });
    },
    createDistanceReport: async ({
        userId,
        date,
        readingKm,
        reportedDistanceKm,
    }) => {
        const { id } = await post('/timereports/distance', {
            id: 'new',
            readingKm,
            reportedDistanceKm,
            date,
            userId,
        });

        return id;
    },
    updateDistanceReport: async ({
        id,
        userId,
        date,
        readingKm,
        reportedDistanceKm,
    }) => {
        return await put('/timereports/distance', {
            id,
            readingKm,
            reportedDistanceKm,
            date,
            userId,
        });
    },
    deleteDistanceReport: async id => {
        return await deletee(`/timereports/distance/${id}`);
    },
    createCompensationReport: async ({
        userId,
        date,
        tagId,
        comment,
        data,
    }) => {
        const { id } = await post('/timereports/compensation', {
            id: 'new',
            tagId,
            comment,
            data,
            date,
            userId,
        });

        return id;
    },
    updateCompensationReport: async ({
        id,
        userId,
        date,
        tagId,
        comment,
        data,
    }) => {
        return await put('/timereports/compensation', {
            id,
            tagId,
            comment,
            data,
            date,
            userId,
        });
    },
    deleteCompensationReport: async id => {
        return await deletee(`/timereports/compensation/${id}`);
    },
    fetchCompanyHolidays: async () => {
        const { holidays } = await get('/timereports/conf/holidays');

        return holidays;
    },
    createCompanyHoliday: async data => {
        const { id } = await post('/timereports/conf/holidays', data);

        return id;
    },
    updateCompanyHoliday: async data => {
        return await put('/timereports/conf/holidays', data);
    },
    deleteCompanyHoliday: async id => {
        return await deletee(`/timereports/conf/holidays/${id}`);
    },
    fetchParentTimeReport: async id => {
        const report = await get(`/timereports/parent/${id}`);

        return report;
    },
    createParentReport: async report => {
        const { id } = await post('/timereports/parent', report);

        return id;
    },
    updateParentReport: async report => {
        return await put('/timereports/parent', report);
    },
    deleteParentReport: async id => {
        return await deletee(`/timereports/parent/${id}`);
    },
};
