import localforage from 'localforage';

export default {

    putGlobal: (key, data) => localforage.setItem(key, data),

    put: (uid, key, data) => localforage.setItem(`${uid}/${key}`, data),

    getGlobal: key => localforage.getItem(key),

    get: (uid, key) => localforage.getItem(`${uid}/${key}`),

    getOrDefault: (uid, key, def) => localforage.getItem(`${uid}/${key}`) || def,

    remove: (uid, key) => localforage.removeItem(`${uid}/${key}`),

    removeGlobal: key => localforage.removeItem(key),

    clear: () => localforage.clear(),
}