import { LOGOUT } from 'data/actions/common/authActions';
import { combineReducers } from 'redux';
import { acls } from './acls';
import { agreements } from './agreements';
import { appmodules } from './appmodules';
import { articles } from './articles';
import { bos } from './business-objects';
import { calendar } from './calendar';
import { analytics, auth, confirm, ui } from './common';
import { customers } from './customers';
import { deviations } from './deviations';
import { supplierinvoices } from './supplierinvoices';
import { loader } from './global-loader';
import { models } from './models';
import { projects } from './projects';
import { proposals } from './proposals';
import { reports } from './reports';
import { salesorders } from './salesorders';
import { supplierInventory } from './supplierinventory';
import { suppliers } from './suppliers';
import { users } from './users';
import { workOrders } from './workorders';
import { doorautomatics } from './doorautomatics';

const appReducer = combineReducers({
    analytics,
    auth,
    ui,
    users,
    workOrders,
    customers,
    bos,
    articles,
    agreements,
    models,
    calendar,
    acls,
    confirm,
    loader,
    reports,
    suppliers,
    appmodules,
    salesorders,
    projects,
    proposals,
    supplierInventory,
    deviations,
    doorautomatics,
    supplierinvoices
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
