export const se_veosol = {
    ui: {
        SETTINGS_ARTICLES_GROUPS: 'Materialgrupper',
        EDIT_ARTICLE_GROUP: 'Ändra materialgrupp',
        TIME_REPORTING: 'Tidsedel',
        CLOSED_WORK_ORDERS: 'Fakturerade arbetsordrar',
    },
    form: {
        ARTICLE_GROUP: 'Materialgrupp',
        WORK_ORDER_DESCRIPTION: 'Ordertext (intern)',
        SUMMARY: 'Fakturatext',
    },
    messages: {
        ARTICLE_GROUP_EXISTS: 'Materialgruppen finns redan!',
        ARTICLE_GROUP_CANNOT_DELETE:
            'Ej tillåtet att radera materialgruppen! Det finns artiklar knutna till materialgruppen eller en prislista.',
    },
};
