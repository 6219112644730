import {
    FETCH_DOORAUTOMATICS,
    FETCH_DOORAUTOMATICS_FULFILLED,
    FETCH_DOORAUTOMATICS_REJECTED,
    DOORAUTOMATICS_CHANGED,
    DOORAUTOMATICS_DELETED,
    DOORAUTOMATICS_TABLE_STATE_CHANGE,
    DOORAUTOMATICS_FILTERS_CHANGE
} from '../../actions/doorautomatics';

const defaultState = {
    isFetching: false,
    doorautomatics: [],
    tableState: undefined,
    filters: {
        customer: null,
        bo: null
    },
}

export const doorautomatics = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case DOORAUTOMATICS_TABLE_STATE_CHANGE: return ({ ...state, tableState: action.state })
        case FETCH_DOORAUTOMATICS: {
            return {
                ...state,
                isFetching: true
            }
        }
        case DOORAUTOMATICS_FILTERS_CHANGE: return ({
            ...state,
            filters: {
                ...state.filters,
                [action.filterName]: action.value
            }
        })
        case FETCH_DOORAUTOMATICS_FULFILLED: {
            return {
                ...state,
                isFetching: false,
                doorautomatics: action.doorautomatics
            }
        }
        case FETCH_DOORAUTOMATICS_REJECTED: {
            return {
                ...state,
                isFetching: false
            }
        }
        case DOORAUTOMATICS_CHANGED: {
            const doorautomatics = [...state.doorautomatics];
            const idx = doorautomatics.findIndex(s => s.id === action.doorautomatic.id);

            if (idx < 0) {
                doorautomatics.push(action.doorautomatic);
            } else {
                doorautomatics[idx] = action.doorautomatic;
            }

            return {
                ...state,
                doorautomatics
            }
        }
        case DOORAUTOMATICS_DELETED: {
            const doorautomatics = [...state.doorautomatics];
            const idx = doorautomatics.findIndex(s => s.id === action.id);

            doorautomatics.splice(idx, 1);

            return {
                ...state,
                doorautomatics
            }
        }
        default: {
            return state;
        }
    }
};