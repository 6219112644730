export const se_wermsec = {
    ui: {
        
        SETTINGS_DEVIATIONS: 'Förbättringsåtgärder',
        FILTER_DEVIATIONS: 'Filter förbättringar',
        DEVIATION_CREATE_NEW: 'Ny Förbättringsåtgärd',
        WORKORDER_CREATE_DEVIATION: 'Skapa förbättring',
        DEVIATIONS: 'Förbättringsåtgärder',
        CLOSE_DEVIATION: 'Stäng förbättring',
        DEVIATION_DASHBOARD_GO_TO_LIST: 'Visa lista på alla förbättringar',
        DEVIATION_DASHBOARD_BY_CATEGORY: 'Förbättring per kategori',
        DEVIATION_DASHBOARD_BY_RELATION: 'Mest frekventa förbättringen',
        DEVIATION_DASHBOARD_LATEST: 'Senaste förbättringen',
    },

    form: {
    },

    messages: {
        DEVIATION_SUCCESSFULLY_CLOSED: 'Förbättring stängdes korrekt',
    },

    acl: {
        PERMISSION_DEVIATIONS_READ: 'Se Förbättringar',
        PERMISSION_DEVIATIONS_EDIT: 'Ändra förbättringar',
        PERMISSION_DEVIATIONS_CREATE: 'Skapa Förbättring',
        PERMISSION_DEVIATIONS_CLOSE: 'Stänga Förbättring',
        PERMISSION_DEVIATIONS_DELETE: 'Radera Förbättring',
    },

};