import React, { Component } from 'react';
import { withInstanceData } from '../with-instancedata/WithInstanceData';
import { withPermissions } from '../with-permissions/WithPermissions';

export const requireAppModule = (ComposedComponent, requiredModule) => {
    class RequireAppModule extends Component {

        render() {
            if (!requiredModule) {
                return <ComposedComponent {...this.props} />
            }

            const { hasAppModule } = this.props;

            if (hasAppModule(requiredModule) || this.props.isSuperUser) {
                return <ComposedComponent {...this.props} />
            }

            return null;
        }
    }

    return withPermissions(withInstanceData(RequireAppModule));
}