import { discountTypes } from './constants/discountTypes';

export const calculateMargin = (purchasePrice, sellPrice) => {
    if (!purchasePrice || !sellPrice) {
        return null;
    }

    return ((sellPrice - purchasePrice) / sellPrice) * 100;
};

export const calculateDiscountedPrice = (
    price,
    amount,
    discountType,
    discountValue
) => {
    if (!amount) {
        return 0;
    }

    const totalBeforeDiscount = (price || 0) * amount;

    if (discountValue == null) {
        return totalBeforeDiscount;
    }

    if (discountType === discountTypes.NONE) {
        return totalBeforeDiscount;
    }

    if (discountType === discountTypes.REGULAR) {
        return totalBeforeDiscount - discountValue * amount;
    }

    if (discountType === discountTypes.PERCENTAGE) {
        const percentage = discountValue / 100;
        return totalBeforeDiscount * (1 - percentage);
    }

    return totalBeforeDiscount;
};

export const calculateVatAmount = (price, vatPercentage) => {
    if (price === 0 || vatPercentage === 0) {
        return 0;
    }

    const totalWithVAT = calculateTotalPriceVat(price, vatPercentage);

    return totalWithVAT - price;
};

export const calculateTotalPriceVat = (price, vatPercentage) => {
    if (price === 0) {
        return 0;
    }

    if (vatPercentage === 0) {
        return price;
    }

    const p = 1 + vatPercentage / 100;

    return price * p;
};

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

// https://stackoverflow.com/questions/39776819/function-to-normalize-any-number-from-0-1
export const normalize = (val, max, min) => {
    return (val - min) / (max - min);
};

export const calculateMarkup = (purchasePrice, sellPrice) => {
    if (!purchasePrice || !sellPrice) {
        return null;
    }
    
    return parseFloat((((sellPrice - purchasePrice) / purchasePrice) * 100).toFixed(2));
}
export const calculateSalesPriceBasedOnMarkup = (purchasePrice, markup, discountType, discountValue) => {
    if (!purchasePrice || !markup) {
        return 0;
    }

    let salesPrice = purchasePrice * (1 + (markup / 100));

    if (discountValue) {
        if (discountType == discountTypes.REGULAR) {
            salesPrice = salesPrice + discountValue;
        } else if (discountType == discountTypes.PERCENTAGE) {
            salesPrice = salesPrice * (1 + (discountValue / 100));
        }
    }

    return parseFloat(salesPrice.toFixed(0));
    // return parseFloat(salesPrice.toFixed(0)); 
}