import EditableDropdown from "components/common/editable-dropdown";
import EditableText from "components/common/editable-text";
import ToggleButtonGroup from "components/common/toggle-btn-group";
import { Checkbox } from "semantic-ui-react";
import { mapSimpleValueToOption } from "utils/DropdownMappers";


const monthOptions = [
    'Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti',
    'September', 'Oktober', 'November', 'December'
].map(mapSimpleValueToOption);

const ProposalDetailsCustomBSP = ({ isSmallScreen, proposal, editable, data, onDataChange }) => {

    if (!data || !proposal) {
        return null;
    }

    if (!data?.initialized) {
        onDataChange({
            ...data,
            initialized: true,
            row2_value: '1',
            row3_value: '4300',
            row4_value: '750',
            year: new Date().getFullYear(),
            notification: 'JA'
        });

        return null;
    }

    const handleValueChange = (name, value) => {
        onDataChange({
            ...data,
            [name]: value
        })
    }

    const handleValuesChange = values => {
        onDataChange({
            ...data,
            ...values
        })
    }

    const enabled = data?.serviceAgreementEnabled === 'JA';

    return (
        <div style={{
            marginTop: 20,
            marginBottom: 20
        }}>
            <div className="man-flex-row"
                style={{
                    marginBottom: 10
                }}>
                <h4 style={{
                    margin: '0 8px 0 0',
                    padding: 0
                }}>
                    Serviceavtal:
                </h4>
                <ToggleButtonGroup
                    toggleButtons={['JA', 'NEJ'].map(mapSimpleValueToOption)}
                    toggeled={data?.serviceAgreementEnabled}
                    onClick={value =>
                        editable && handleValueChange('serviceAgreementEnabled', value)
                    }
                    size="mini"
                />
            </div>

            {enabled &&
                <div>
                    <div className="man-flex-row">
                        <div style={{
                            width: isSmallScreen ? 200 : 260
                        }}>
                            <Checkbox label="Brand kvartalsprov"
                                checked={data?.row1_cb || false}
                                onChange={() => handleValueChange('row1_cb', data?.row1_cb == true ? false : true)} />
                        </div>
                        <div style={{
                            marginRight: 20
                        }}>
                            <EditableText
                                isEditable={editable}
                                name="row1_value"
                                value={data?.row1_value}
                                onSave={value => handleValueChange('row1_value', value)}
                                onValueChange={(name, value) => handleValueChange('row1_value', value)} />

                            ggr / år
                        </div>
                        <div>
                            <EditableText
                                isEditable={editable}
                                name="row1_value2"
                                value={data?.row1_value2}
                                onSave={value => handleValueChange('row1_value2', value)}
                                onValueChange={(name, value) => handleValueChange('row1_value2', value)} />
                            kr / tillfälle
                        </div>
                    </div>
                    <div className="man-flex-row" style={{
                        marginTop: 8
                    }}>
                        <div style={{
                            width: isSmallScreen ? 200 : 260
                        }}>
                            <Checkbox label="Brand årlig kontroll"
                                checked={data?.row2_cb || false}
                                onChange={() => handleValueChange('row2_cb', data?.row2_cb == true ? false : true)} />
                        </div>
                        <div style={{
                            marginRight: 20
                        }}>
                            <EditableText
                                isEditable={false}
                                name="row2_value"
                                value={data?.row2_value}
                                onSave={value => handleValueChange('row2_value', value)}
                                onValueChange={(name, value) => handleValueChange('row2_value', value)} />


                            <span style={{
                                marginLeft: 4
                            }}>
                                ggr / år
                            </span>
                        </div>
                        <div>
                            <EditableText
                                isEditable={editable}
                                name="row2_value2"
                                value={data?.row2_value2}
                                onSave={value => handleValueChange('row2_value2', value)}
                                onValueChange={(name, value) => handleValueChange('row2_value2', value)} />
                            kr / tillfälle
                        </div>
                    </div>
                    <div  style={{
                        marginTop: 8
                    }}>
                        <div className="man-flex-row">
                            <div style={{
                                marginRight: 10
                            }}>
                                Ordinarie servicemånad:
                            </div>
                            <EditableDropdown
                                isEditable={editable}
                                name="month"
                                options={monthOptions}
                                value={data?.month}
                                text={data?.month || 'Välj..'}
                                onSave={(name, value) => handleValueChange('month', value)} />

                        </div>
                    </div>
                    <div className="man-flex-row"
                        style={{
                            marginTop: 8
                        }}>
                        <div style={{
                            marginRight: 10
                        }}>
                            Önskas avisering:
                        </div>
                        <ToggleButtonGroup
                            toggleButtons={['JA', 'NEJ'].map(mapSimpleValueToOption)}
                            toggeled={data?.notification}
                            onClick={value =>
                                editable && handleValueChange('notification', value)
                            }
                            size="mini"
                        />
                    </div>
                </div>
            }

            {enabled &&
                <div>
                    <h4 style={{
                        paddingTop: 20,
                        paddingBottom: 4,
                        marginBottom: 0
                    }}>
                        Webbaserad SBA
                    </h4>
                    <div className="man-flex-row">
                        <div style={{
                            width: isSmallScreen ? 200 : 260
                        }}>
                            <Checkbox label="1-5 användare kostnad"
                                checked={data?.row3_cb || false}
                                onChange={() => handleValueChange('row3_cb', data?.row3_cb == true ? false : true)} />
                        </div>
                        <div style={{
                            marginRight: 20
                        }}>
                            <EditableText
                                isEditable={editable}
                                name="row3_value"
                                value={data?.row3_value}
                                onSave={value => handleValueChange('row3_value', value)}
                                onValueChange={(name, value) => handleValueChange('row3_value', value)} />

                            kr / år
                        </div>
                    </div>
                    <div className="man-flex-row" style={{
                        marginTop: 8
                    }}>
                        <div style={{
                            width: isSmallScreen ? 200 : 260
                        }}>
                            <Checkbox label="Antal extra licenser à"
                                checked={data?.row4_cb || false}
                                onChange={() => handleValueChange('row4_cb', data?.row4_cb == true ? false : true)} />
                        </div>
                        <div style={{
                            marginRight: 20
                        }}>
                            <EditableText
                                isEditable={editable}
                                name="row4_value"
                                value={data?.row4_value}
                                onSave={value => handleValueChange('row4_value', value)}
                                onValueChange={(name, value) => handleValueChange('row4_value', value)} />

                            kr / år
                        </div>
                    </div>
                </div>

            }

            {enabled &&
                <div>
                    <h4 style={{
                        paddingTop: 20,
                        paddingBottom: 4,
                        marginBottom: 0
                    }}>
                        Avgifter
                    </h4>
                    <div className="man-flex-row">
                        <div style={{
                            width: isSmallScreen ? 200 : 260
                        }}>
                            <Checkbox label="Kostnad per enhet"
                                checked={data?.row5_cb || false}
                                onChange={() => handleValueChange('row5_cb', data?.row5_cb == true ? false : true)} />
                        </div>
                    </div>
                    <div className="man-flex-row" style={{
                        marginTop: 8
                    }}>
                        <div style={{
                            width: isSmallScreen ? 200 : 260
                        }}>
                            <Checkbox label="Kostnad vid avtalets tecknande"
                                checked={data?.row6_cb || false}
                                onChange={() => handleValueChange('row6_cb', data?.row6_cb == true ? false : true)} />
                        </div>
                        <div style={{
                            marginRight: 20
                        }}>
                            <EditableText
                                isEditable={editable}
                                name="row6_value"
                                value={data?.row6_value}
                                onSave={value => handleValueChange('row6_value', value)}
                                onValueChange={(name, value) => handleValueChange('row6_value', value)} />

                            kr
                        </div>
                    </div>
                    <div className="man-flex-row" 
                        style={{
                            marginTop: 8
                        }}>
                        <div>
                            <Checkbox label="Löpande debitering av arbetstimmar och resekostnad enligt separat prislista"
                                checked={data?.row7_cb || false}
                                onChange={() => handleValueChange('row7_cb', data?.row7_cb == true ? false : true)} />
                        </div>
                    </div>
                </div>

            }

        </div>
    )

}

export default ProposalDetailsCustomBSP;