export default class HistoryRow {

    userName;
    action;
    data;
    timestamp;

    constructor({ userName, timestamp, data, action }) {
        this.userName = userName;
        this.timestamp = timestamp;
        this.action = action;
        this.data = data;
    }
}