export const se_portproffsen = {
    ui: {
        
    },
    form: {
        
    },
    messages: {
        
    }
}