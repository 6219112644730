import classnames from 'classnames';
import React, { FC } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { Icon } from '..';
import styles from './FlatButton.module.css';

interface Props {
    title?: string,
    icon?: string,
    onClick?: () => void,
    active?: boolean,
    disabled?: boolean,
    children?: any,
    style?: object,
    position?: | 'top left'
    | 'top right'
    | 'bottom right'
    | 'bottom left'
    | 'right center'
    | 'left center'
    | 'top center'
    | 'bottom center',
    loading?: boolean,
    bordered?: boolean,
    white?: boolean,
    type?: 'positive' | 'negative';
}

const FlatButton: FC<Props> = ({ title, icon, type, onClick, active, disabled, children, style, position, loading, white, bordered }) => {

    const cName = classnames(
        'man-flatbtn',
        styles.flatButton,
        { 'white': white },
        { 'man-bordered': bordered },
        { [styles.positive]: type === 'positive' },
        { [styles.negative]: type === 'negative' }
    )

    if (!title) {
        return (
            <Button className={cName} icon basic onClick={onClick} active={active} disabled={disabled} style={{ ...style }} loading={loading}>
                {children} {icon && <Icon style={{ verticalAlign: 'baseline' }} name={icon} />}
            </Button>
        )
    }

    return (
        <Popup position={position || 'bottom right'}
            inverted
            content={title}
            trigger={
                <Button className={cName} icon basic onClick={onClick} active={active} disabled={disabled} style={{ ...style }} loading={loading}>
                    {children} {icon && <Icon style={{ verticalAlign: 'baseline' }} name={icon} />}
                </Button>
            } />
    )
}

export default FlatButton;