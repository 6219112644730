export const getColor = (name, tags) => {
    if (!name || !name.toLowerCase) return '';
    
    const t = tags.find(t => t.name && t.name.toLowerCase() === name.toLowerCase());

    if (t && t.color) {
        return t.color;
    }

    return '';
}