import React, { FC } from 'react';
import { Header3 } from '..';
import styles from './Card.module.css';

interface Props {
    children: any,
    header?: string,
    subheader?: string
}

const Card: FC<Props> = ({ header, subheader, children }) => {

    return (
        <div className={styles.container}>
            {header &&
                <div>
                    <Header3 text={header} />
                </div>
            }
            {subheader &&
                <div className={styles.meta}>
                    {subheader}
                </div>
            }

            {children}
        </div>
    )
}

export default Card;