export const se_baxec = {
    ui: {
        SETTINGS_BUSINESS_OBJECT: 'System',
        OBJECTS: 'System',
        MANAGE_OBJECTS: 'Hantera system',
        MANAGE_MODELS: 'Hantera systemtyper',
        MODELS: 'Systemtyp',
        NEW_BUSINESS_OBJECT_MODAL_TITLE: 'Nytt system',
        MODEL_EDIT_DIALOG_TITLE: 'Ändra systemtyp',
        MODELPICKER_SELECT: 'Välj systemstyp',
        ADD_WORK_ORDER_ON_SITE_CONTACT: 'Lägg till kundreferens',
    },

    form: {
        LOCATION_DESCRIPTION: 'System-namn',
        WORK_ORDER_DESCRIPTION: 'Beställning enligt kund',
        OBJECT_COUNT: 'System',
        OBJECT: 'System',
        MODEL: 'Systemtyp',
        CODE: 'System-kod',
        SERIAL: 'System ID',
        ON_SITE_CONTACT_NAME_REQUIRED: 'Kundreferens är obligatorisk!',
        ON_SITE_CONTACT: 'Kundreferens',

    },

    acl: {
        PERMISSION_BUSINESS_OBJECTS_READ: 'Se system',
        PERMISSION_BUSINESS_OBJECTS_EDIT: 'Ändra system',
        PERMISSION_MODELS_READ: 'Se systemtyp',
        PERMISSION_MODELS_EDIT: 'Ändra systemtyp',
    },
}